import React from "react";

import { Typography, Chip, Box } from "@material-ui/core";
import { UserService, Failure } from "telescan-core";
import uuid from 'uuid';


interface IPasswordStrengthProps {
    password: string;
    dispatch_failure: (id: string, message: Failure) => void;
    setStrength: (strength: "weak" | "good" | "strong") => void;
    context: "LOGIN" | "ZERTIFIKAT"
}

interface IPasswordStrengthState {
    passwordStrength: string; 
}

export class PasswordStrengthComponent extends React.Component<IPasswordStrengthProps, IPasswordStrengthState> {
    constructor(props: IPasswordStrengthProps) {
        super(props);
        this.state = {
            passwordStrength: "",
        };

        this.estimatePassStrength = this.estimatePassStrength.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<IPasswordStrengthProps>) {
        if (prevProps.password !== this.props.password && this.props.password !== ""){
            this.estimatePassStrength(this.props.password);
        }
    }

    estimatePassStrength(password: string) {
        const loadInstanceIdArzt = uuid.v4();
        const userService = new UserService();
        if (this.props.context === "LOGIN") {
            userService.evaluatePasswordStrengthLogin(password).then(() => {
                this.setState({
                    passwordStrength: "strong"
                })
                this.props.setStrength("strong")
            }).catch((failure: Failure) => {
                this.props.dispatch_failure(loadInstanceIdArzt, failure);
                this.setState({
                    passwordStrength: "weak"
                })
                this.props.setStrength("weak")
                // if (error.passwortstarke === "GUT") {
                //     this.setState({
                //         passwordStrength: "good"
                //     })
                //     this.props.setStrength("good")
                // } else if (error.passwortstarke === "SCHWACH") {
                //     this.setState({
                //         passwordStrength: "weak"
                //     })
                //     this.props.setStrength("weak")
                // } else {
                //     this.props.dispatch_failure(loadInstanceIdArzt, error)
                // }
            })
        } else {
            userService.evaluatePasswordStrengthZertifikat(password).then(() => {
                this.setState({
                    passwordStrength: "strong"
                })
                this.props.setStrength("strong")
            }).catch((failure: Failure) => {
                this.props.dispatch_failure(loadInstanceIdArzt, failure);
                this.setState({
                    passwordStrength: "weak"
                })
                this.props.setStrength("weak")
                // if (error.passwortstarke === "GUT") {
                //     this.setState({
                //         passwordStrength: "good"
                //     })
                //     this.props.setStrength("good")
                // } else if (error.passwortstarke === "SCHWACH") {
                //     this.setState({
                //         passwordStrength: "weak"
                //     })
                //     this.props.setStrength("weak")
                // } else {
                //     this.props.dispatch_failure(loadInstanceIdArzt, error)
                // }
            })
        }
    }


    render() {
        if (this.props.context === "LOGIN")
            return (
                <Box className="pw-check">
                    <Typography>Passwortstärke:&nbsp; </Typography>
                    {this.state.passwordStrength === "strong" &&
                        <Chip className="pw-strong" size="small" label="Stark" />
                    }
                    {this.state.passwordStrength === "good" &&
                        <Chip className="pw-good" size="small" label="Ausreichend" />
                    }
                    {this.state.passwordStrength === "weak" &&
                        <Chip className="pw-weak" size="small" label="Schwach" />
                    }
                </Box>
            )
        else if (this.props.context === "ZERTIFIKAT")
            return (
                <Typography className="pw-check">Passwortgültigkeit:&nbsp;
                    {this.state.passwordStrength === "strong" &&
                        <Chip className="pw-strong" size="small" label="Gültig" />
                    }
                    {(this.state.passwordStrength === "good" || this.state.passwordStrength === "weak") &&
                        <Chip className="pw-weak" size="small" label="Ungültig" />
                    }
                </Typography>
            )
        else
            return(<></>)
    }
}
