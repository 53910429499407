import { Popover, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Button } from "@material-ui/core";
import React from "react";
import { Patient, RoleTypes } from "telescan-core";
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { Add } from "@material-ui/icons";

interface IProps {
    anchorEl: HTMLButtonElement | null;
    onClose: () => void;
    gdtPatients: Patient[];
    dispatch_acknowledgeGdtPatient: (id: string) => void;
    dispatch_acknowledgeAllGdtPatients: () => void;
    addKonsilForGdtPatient: (gdtPatient: Patient) => void;
    userRole: keyof typeof RoleTypes;
}
export default class GdtPatientsPopover extends React.Component<IProps> {

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.gdtPatients !== this.props.gdtPatients && this.props.gdtPatients.length === 0) {
            this.props.onClose();
        }
    }

    render() {
        return (
            <Popover
                open = {Boolean(this.props.anchorEl) && this.props.gdtPatients.length !== 0}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                className = 'pad-l'
                anchorEl = {this.props.anchorEl}
                onClose = {this.props.onClose}
            >
                <h2 className = "pad">Importierten Patienten</h2>
                <TableContainer className="zertifikate-table selectable-rows">
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Geschlecht</TableCell>     
                                    <TableCell>Geburtsdatum</TableCell>     
                                    <TableCell align = 'right'>
                                        <Tooltip title = "Alle Patienten als gesehen markieren">
                                            <IconButton
                                                color = "primary"
                                                onClick = {this.props.dispatch_acknowledgeAllGdtPatients}
                                            >
                                                <DoneAllIcon/>
                                            </IconButton>   
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.gdtPatients.map(rowData => {
                                    return (
                                        <TableRow
                                            //hover
                                            //onClick = {this.handleOnTableRowClick}
                                            key = {rowData.id || ""}
                                        >
                                            <TableCell>{`${rowData.title || ""} ${rowData.vorname || ""} ${rowData.nachname || ""}`}</TableCell>
                                            <TableCell>{rowData.geschlecht || "Unbekannt"}</TableCell>    
                                            <TableCell>{rowData.geburtsdatum ? new Date(rowData.geburtsdatum).toLocaleDateString() : "Unbekannt"}</TableCell>                            
                                            <TableCell align = 'right'>
                                                {(this.props.userRole === "HAUSARZT" || this.props.userRole === "BETRIEBSSTAETTE_HAUSARZT") &&
                                                     <Tooltip title = "Neuen Konsilauftrag anlegen">
                                                        <Button
                                                            className = "add-konsil slim"
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"                                                       
                                                            onClick = {() => this.props.addKonsilForGdtPatient(rowData)}
                                                        >
                                                            <Add/>
                                                        </Button>
                                                    </Tooltip>
                                                }
                                               
                                                <Tooltip title = "Als gesehen markieren">
                                                    <IconButton
                                                        color = "primary"
                                                        onClick = {() => this.props.dispatch_acknowledgeGdtPatient(rowData.id || "")}
                                                    >
                                                        <DoneIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>                   
                                        </TableRow>
                                    )
                                })}

                            </TableBody>
                        </Table>
                </TableContainer> 
            </Popover>
        )
    }
}