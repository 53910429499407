import { Icd10Info, Icd10InstallDeleteEventData, ICDService, KatalogStatusType } from "telescan-core";
import * as Icd10Types from '../types/icd10_types';
import uuid from 'uuid';
import { RootState } from "../reducers";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { failure, setSuccessMessage } from ".";

export function setIcd10Info(icd10Info: Icd10Info[]): Icd10Types.ISetIcd10Info {
    return {
        type: Icd10Types.EIcd10Actions.SET_ICD10_INFO,
        icd10Info: icd10Info,
    }
}

export function setIcd10InstallationStatus(identifier: string, installationStatus: keyof typeof KatalogStatusType): Icd10Types.ISetIcd10InstallationStatus {
    return {
        type: Icd10Types.EIcd10Actions.SET_ICD10_INSTALLATION_STATUS,
        identifier: identifier,
        installationStatus: installationStatus
    }
}

export function getIcd10Info(): ThunkAction<Promise<any>, RootState, unknown, Action<string>> {
    return dispatch => {
        const loadInstanceId: string = uuid.v4();
        const icdService = new ICDService();
        return new Promise((resolve, reject) => {
            icdService.getIcdInfo()
            .then(response => {
                dispatch(setIcd10Info(response))
                if (response.some(item => (item.status === "WIRDINSTALLIERT" || item.status === "WIRDDEINSTALLIERT")))
                    resolve(response);
                })
            .catch((error) => {
                dispatch(failure(loadInstanceId, error));
            }) 
        })
        }
}

export function handleInstallIcd10(data: Icd10InstallDeleteEventData): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const loadInstanceId: string = uuid.v4();
        if (data.status === "INSTALL") {
            data.id && dispatch(setIcd10InstallationStatus(data.id, "WIRDINSTALLIERT"));
            return;
        }
        if (data.status === "SUCCESS") {
            data.id && dispatch(setIcd10InstallationStatus(data.id, "INSTALLIERT"));
            if (data.initiator === getState().webSocket.clientId)
                dispatch(setSuccessMessage(uuid.v4(), "Installation des ICD10-Katalogs erfolgreich."));

        } else {
            data.id && dispatch(setIcd10InstallationStatus(data.id, "NICHTINSTALLIERT"));
            if (data.initiator === getState().webSocket.clientId)
                dispatch(failure(loadInstanceId, data.error))
        }
    }
}

export function handleDeleteIcd10(data: Icd10InstallDeleteEventData): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const loadInstanceId: string = uuid.v4();
        if (data.status === "DELETE") {
            data.id && dispatch(setIcd10InstallationStatus(data.id, "WIRDDEINSTALLIERT"));
            return;
        }
        if (data.status === "SUCCESS") {
            data.id && dispatch(setIcd10InstallationStatus(data.id, "NICHTINSTALLIERT"));
            if (data.initiator === getState().webSocket.clientId)
                dispatch(setSuccessMessage(uuid.v4(), "Deinstallation des ICD10-Katalogs erfolgreich."));

        } else {
            data.id && dispatch(setIcd10InstallationStatus(data.id, "INSTALLIERT"));
            if (data.initiator === getState().webSocket.clientId)
                dispatch(failure(loadInstanceId, data.error))
        }
    }
}