import React from 'react';
import {Box,} from '@material-ui/core';
import {RootState} from "../../../redux/reducers";
import {connect, ConnectedProps} from "react-redux";
import ViewKonsiliarberichtContainer from './view_konsiliarbericht';
import {downloadAttachment} from '../../../redux/actions';

import ViewAnamneseContentContainer from './view_anamnesecontent';

const mapStateToProps = (state: RootState) => ({
    currentKonsil: state.konsile.current_konsil,
    currentKonsilPatient: state.konsile.current_konsil?.konsilPatient,
    role: state.user.role,
    attachments: state.image_attachment.attachments,
})

const mapDispatchToProps = {
    downloadAttachment: (attachment_id: string) => downloadAttachment(attachment_id),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

interface IState {
    //shouldShowSide: boolean;
}

export class ViewAnamnese extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            //shouldShowSide: false
        };

        // this.showSideClick = this.showSideClick.bind(this);
        // this.closeSideClick = this.closeSideClick.bind(this);
    }

    /* showSideClick() { // not really used
        this.setState({
            shouldShowSide: true
        });
    };
    closeSideClick() {
        this.setState({
            shouldShowSide: false
        });
    }; */


    render() {
        return (
            <Box className="splittscreen">
                <Box className="anamnese-data-container">
                    <ViewAnamneseContentContainer/>
                </Box>
                <Box className="split-konsil">
                    <ViewKonsiliarberichtContainer hasSideView={true}/>
                </Box>
            </Box>
        );
    }
}

export const ViewAnamneseContainer = connector(ViewAnamnese);
export default ViewAnamneseContainer;
