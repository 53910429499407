import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import React from 'react';

interface IProps {
    isNoNewBillsDialogOpened: boolean;
    handleSubmitAction: (id: string) => void;
    handleCloseDialog: () => void;
    dialogText: string;  
    currentQuartalsAbrechnungId: string;
}

class DialogNoNewBills extends React.Component<IProps> {
    render() {
        return (
            <Dialog open = {this.props.isNoNewBillsDialogOpened}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.props.dialogText}
                    </DialogContentText>
                    </DialogContent>
                <DialogActions>
                    <Button
                        variant = 'contained'
                        color = 'primary'
                        onClick = {this.props.handleCloseDialog}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        variant = 'contained'
                        color = 'primary'
                        onClick = {() => {
                            this.props.handleCloseDialog();
                            this.props.handleSubmitAction(this.props.currentQuartalsAbrechnungId);
                        }}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default DialogNoNewBills;