import React from 'react';
import {connect, ConnectedProps} from 'react-redux';
import uuid from 'uuid';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, IconButton} from '@material-ui/core';
import { Replay, Delete } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import { ZertifikatInfoModal } from './arztinfo_table';
import { Betriebsstaette, ZertifikatMetainformation, ZertifikatService, ZertifikatStatus, Failure } from 'telescan-core';
import { RootState } from '../../redux/reducers';
import UpdateZertifikatPopupComponent from './updatecertificate_popup';
import { loading, loadSuccess, loadZertifikate, failure } from '../../redux/actions';
import BSInfoModalComponent from './bsinfo_popup';
import CreateZertifikatPopupComponent from './createcertificate_popup';
import { needsUpdate } from '../../utils/utils';


export interface BSInfoRow {
    id: string;
    bsnr: string;
    name: string;
    betriebsstaette: Betriebsstaette;
    zertifikat: ZertifikatMetainformation;
}

interface TableState {
    zertifikatInfo: ZertifikatMetainformation | undefined;
    isOpen: boolean;
}

interface TableProps {
    data: BSInfoRow[],
    toggleBSModal: () => void;
    isOpenBS: boolean,
    betriebsstaette: Betriebsstaette,
}

const mapStateToProps = (state: RootState, ownProps: TableProps) => ({
    role: state.user.role,
    loading: state.general.loading,
    initialisationStatus: state.user.initialisationStatus,
    ...ownProps
})

const mapDispatchToProps = {
    dispatch_loading: (id: string) => loading(id),
    dispatch_loadSuccess: (id: string) => loadSuccess(id),
    dispatch_failure: (id: string, message: Failure) => failure(id, message),
    dispatch_loadZertifikate: () => loadZertifikate(),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class BSInfoTable extends React.Component<TPropsFromRedux, TableState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            zertifikatInfo: undefined,
            isOpen: false,
        };

        this.loadInstanceId = uuid.v4();

        this.refreshZertifikat = this.refreshZertifikat.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.openZertifikatInfoModal = this.openZertifikatInfoModal.bind(this);
        this.createBSCertificate = this.createBSCertificate.bind(this);
    }

    private loadInstanceId: string;

    toggleModal() {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    openZertifikatInfoModal(data: ZertifikatMetainformation) {
        this.setState({zertifikatInfo: data});
        this.toggleModal();
    }

    refreshZertifikat(password: string, setZertifikat: Function, toggleModal: Function) {
        this.loadInstanceId = uuid.v4();
        this.props.dispatch_loading(this.loadInstanceId);
        const zertifikatService = new ZertifikatService();
        zertifikatService.updateBetriebsstaettenZertifikat(password).then((metaInfo) => {
            this.props.dispatch_loadSuccess(this.loadInstanceId);
            setZertifikat(metaInfo);
            this.props.dispatch_loadZertifikate();
            toggleModal();
        }).catch((error) => {
            this.props.dispatch_failure(this.loadInstanceId, error);
        })
    }

    createBSCertificate(identifikationsCode: string, setZertifikat: Function, toggleModal: Function) {
        const zertifikatService = new ZertifikatService();
        this.props.dispatch_loading(this.loadInstanceId);
        zertifikatService.createBetriebsstaettenZertifikat(identifikationsCode).then((metaInfo) => {
            this.props.dispatch_loadSuccess(this.loadInstanceId);
            setZertifikat(metaInfo);
            this.props.dispatch_loadZertifikate();
            toggleModal();
        }).catch((error) => {
            this.props.dispatch_failure(this.loadInstanceId, error);
        })
    }


    render() {
        return (
            <>
                <TableContainer className="zertifikate-table selectable-rows">
                    <Table className="table-connector" stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>BSNR</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Zertifikat gültig bis</TableCell>
                                <TableCell>Zertifikat-Status</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.data.map(rowData => (
                            <BSRowComponent key={rowData.id} initialisationStatus={this.props.initialisationStatus} createBSCertificate={this.createBSCertificate}
                                    openZertifikatInfoModal={this.openZertifikatInfoModal} refreshZertifikat={this.refreshZertifikat}
                                    rowData={rowData} toggleBSModal={this.props.toggleBSModal}/>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <ZertifikatInfoModal zertifikat={this.state.zertifikatInfo} toggleModal={this.toggleModal} isOpen={this.state.isOpen}/>
                <BSInfoModalComponent isOpen={this.props.isOpenBS} toggleModal={this.props.toggleBSModal} betriebsstaette={this.props.betriebsstaette}  />
            </>
        );
    }
}

export const BSInfoTableContainer = connector(BSInfoTable);
export default BSInfoTableContainer;



interface RowState {
    rowData: BSInfoRow;
    isOpenCreateZertifikat: boolean;
    isOpenUpdateZertifikat: boolean;
}

interface RowProps {
    rowData: BSInfoRow;
    initialisationStatus: any;
    openZertifikatInfoModal: (data: ZertifikatMetainformation) => void;
    toggleBSModal: (betriebsstaette: Betriebsstaette) => void;
    createBSCertificate: (identifikationsCode: string, setZertifikat: Function, toggleModal: Function) => void;
    refreshZertifikat: (password: string, setZertifikat: Function, toggleModal: Function) => void;
}

export class BSRowComponent extends React.Component<RowProps, RowState> {
    constructor(props: RowProps) {
        super(props);
        this.state = {
            rowData: this.props.rowData,
            isOpenCreateZertifikat: false,
            isOpenUpdateZertifikat: false,
        };

        this.toggleCreateZertifikatModal = this.toggleCreateZertifikatModal.bind(this);
        this.toggleUpdateZertifikatModal = this.toggleUpdateZertifikatModal.bind(this);
        this.setZertifikat = this.setZertifikat.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<RowProps>, prevState: Readonly<RowState>) {
        if (prevProps.rowData !== this.props.rowData) {
            this.setState({
                rowData: this.props.rowData
            });
        }
    }

    toggleCreateZertifikatModal() {
        this.setState({
            isOpenCreateZertifikat: !this.state.isOpenCreateZertifikat
        })
    }

    toggleUpdateZertifikatModal() {
        this.setState({
            isOpenUpdateZertifikat: !this.state.isOpenUpdateZertifikat
        })
    }

    setZertifikat(metaInfo: ZertifikatMetainformation) {
        this.setState({
            rowData: {
                ...this.state.rowData,
                zertifikat: metaInfo
            }
        })
    }


    render() {
        return (
            <>
            <TableRow hover key={this.state.rowData.id} onClick={() => this.props.toggleBSModal(this.state.rowData.betriebsstaette)}>
                <TableCell>{this.state.rowData.bsnr}</TableCell>
                <TableCell>{this.state.rowData.name}</TableCell>
                <TableCell >
                    <span className={(this.state.rowData.zertifikat?.gueltigBis && needsUpdate(new Date(this.state.rowData.zertifikat?.gueltigBis), 3))? "failure": ""}>
                        {(this.state.rowData.zertifikat?.bsnr)? (this.state.rowData.zertifikat?.gueltigBis)? new Date(this.state.rowData.zertifikat?.gueltigBis).toLocaleDateString(): "keine Angabe": "kein Zertifikat vorhanden"}
                    </span>
                </TableCell>
                <TableCell>
                    <span className={(this.state.rowData.zertifikat?.status && ZertifikatStatus[this.state.rowData.zertifikat?.status] !== ZertifikatStatus.AKTIV)? "failure": ""}>
                        {(this.state.rowData.zertifikat?.status)? ZertifikatStatus[this.state.rowData.zertifikat?.status] : "keine Angabe"}
                    </span>
                </TableCell>
                <TableCell align="right">
                    {this.props.initialisationStatus.standalone &&
                    <>
                    {/* 
                    <Tooltip title="Betriebsstättenzertifikat erzeugen" placement="top">
                        <IconButton
                            type="submit"
                            size="small"
                            className=""
                            aria-label="Betriebsstättenzertifikat erzeugen"
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                this.toggleCreateZertifikatModal();
                            }}>
                            <Add/>
                        </IconButton>
                    </Tooltip>
                    */}
                    <Tooltip title="Betriebsstättenzertifikat aktualisieren" placement="top">
                        <IconButton
                            type="submit"
                            size="small"
                            className=""
                            aria-label="Betriebsstättenzertifikat aktualisieren"
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                this.toggleUpdateZertifikatModal();
                            }}>
                            <Replay/>
                        </IconButton>
                    </Tooltip>
                    
                    <Tooltip title="Zertifikatinfos anzeigen" placement="top">
                        <IconButton
                            type="submit"
                            size="small"
                            className=""
                            aria-label="Zertifikatinfos anzeigen"
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                this.props.openZertifikatInfoModal(this.state.rowData.zertifikat);}}>
                            <InfoIcon/>
                        </IconButton>
                    </Tooltip>
                        <IconButton
                            disabled
                            type="submit"
                            size="small"
                            className=""
                            aria-label="Betriebsstätte löschen"
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                            }}>
                            <Delete/>
                        </IconButton>
                    </>
                    }
                </TableCell>
            </TableRow>
            <CreateZertifikatPopupComponent type="Betriebsstaette" 
                createZertifikat={(identificationCode) => this.props.createBSCertificate(identificationCode, this.setZertifikat, this.toggleCreateZertifikatModal)} 
                toggleModal={this.toggleCreateZertifikatModal} 
                isOpen={this.state.isOpenCreateZertifikat}
            />
            <UpdateZertifikatPopupComponent type="Betriebsstaette" 
                refreshZertifikat={(password: string) => this.props.refreshZertifikat(password, this.setZertifikat, this.toggleUpdateZertifikatModal)} 
                toggleModal={this.toggleUpdateZertifikatModal} 
                isOpen={this.state.isOpenUpdateZertifikat}
            />
            </>
        );
    }
}

