import React from 'react';
import { ViewText } from './view_text';
import { Box, Divider, Paper, Typography } from '@material-ui/core'
import { RootState } from "../../../redux/reducers";
import { connect, ConnectedProps } from "react-redux";
import {
    DMPZuordnung,
    Geschlecht,
    Krankheit,
    Labor,
    Medikation,
    Patient,
    Personengruppe,
    RoleTypes,
    VersichertenStatus
} from 'telescan-core';
import { KrankheitHausarztViewComponent, LaborViewComponent, MedikationViewComponent } from '..'
import { assembleAdresse } from '../../../utils/utils';
import { KrankheitHausarztComponent } from '../createAndSend/edit_patient_data/disease_hausarzt';
import { LaborComponent } from '../createAndSend/edit_patient_data/labor';
import { MedikationComponent } from '../createAndSend/edit_patient_data/medication';


interface IState {
    diagnose: Krankheit | null,
    labor: Labor | null,
    medikation: Medikation | null,
    isViewLabor: boolean,
    isViewDiagnose: boolean,
    isViewMeds: boolean
}

const mapStateToProps = (state: RootState) => ({
    currentKonsil: state.konsile.current_konsil,
    currentKonsilPatient: state.konsile.current_konsil?.konsilPatient,
    role: state.user.role
})

const mapDispatchToProps = {}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>


export class ViewPersonendatenContent extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            diagnose: null,
            labor: null,
            medikation: null,
            isViewLabor: false,
            isViewDiagnose: false,
            isViewMeds: false
        };

        this.closeMeds = this.closeMeds.bind(this);
        this.closeLabor = this.closeLabor.bind(this);
        this.closeDiagnose = this.closeDiagnose.bind(this);
    }

    closeMeds() {
        this.setState({
            isViewMeds: false
        });
    }

    closeLabor() {
        this.setState({
            isViewLabor: false
        });
    }

    closeDiagnose() {
        this.setState({
            isViewDiagnose: false
        });
    }


    render() {
        return (
            <Box className="scroll-box">
                <Box className="data-content">
                    <Box className="txt-grid">
                        <Typography className="pad-bottom bold-label txt-header" variant="subtitle1"
                            color="inherit">
                            Patient
                        </Typography>
                        <ViewPatientDetailComponent patient={this.props.currentKonsilPatient} role={this.props.role} />
                        <ViewText
                            labelVariant='body1'
                            label='Berufliche Tätigkeit:'
                            text={(this.props.currentKonsilPatient?.risikofaktoren?.beruflicheTaetigkeiten && this.props.currentKonsilPatient?.risikofaktoren?.beruflicheTaetigkeiten !== "") ? this.props.currentKonsilPatient?.risikofaktoren?.beruflicheTaetigkeiten : "-"}
                        />
                        <ViewText
                            labelVariant='body1'
                            label='Außentätigkeit:'
                            text={this.props.currentKonsilPatient?.risikofaktoren?.aussentaetigkeit != null ? this.props.currentKonsilPatient?.risikofaktoren?.aussentaetigkeit === true ? "JA" : "NEIN" : "-"}
                        />
                    </Box>
                    <Divider />
                    <Box className="txt-grid">
                        <Typography className="pad-bottom bold-label txt-header" variant="subtitle1"
                            color="inherit">
                            Beauftragender Arzt
                        </Typography>
                        <Box>
                            <ViewText
                                labelVariant='body1'
                                label='Name:'
                                text={(this.props.currentKonsil?.beauftragender_arzt) ? [this.props.currentKonsil?.beauftragender_arzt?.titel, this.props.currentKonsil?.beauftragender_arzt?.vorname, this.props.currentKonsil?.beauftragender_arzt?.nachname].join(" ") : "-"}
                            />
                            <ViewText
                                labelVariant='body1'
                                label='BSNR:'
                                text={this.props.currentKonsil.beauftragender_arzt?.bsnr || "-"}
                            />
                            <ViewText
                                labelVariant='body1'
                                label='LANR:'
                                text={this.props.currentKonsil.beauftragender_arzt?.lanr || "-"}
                            />
                        </Box>
                        <Box>
                            <ViewText
                                labelVariant='body1'
                                label='Telefon:'
                                text={this.props.currentKonsil.beauftragender_arzt?.telefon || "-"}
                            />
                            <ViewText
                                labelVariant='body1'
                                label='Fax:'
                                text={this.props.currentKonsil.beauftragender_arzt?.fax || "-"}
                            />
                        </Box>
                    </Box>
                </Box>
                <Paper className="side-view" elevation={0} square>
                    <div>
                        <Typography variant="subtitle1" color="inherit">
                            Diagnosen
                        </Typography>
                        {this.props.currentKonsilPatient?.krankheiten?.map(diagnose =>
                            <KrankheitHausarztComponent
                                key={"diagnose_component_" + diagnose.id}
                                diagnose={diagnose}
                                onViewClick={() => this.setState({
                                    isViewDiagnose: true,
                                    diagnose: diagnose
                                })}
                                delete={(k) => {
                                }}
                                hasError={false}
                                isViewOnly={true}
                                role={this.props.role}
                            />
                        )}
                        <KrankheitHausarztViewComponent handleClose={this.closeDiagnose} openView={this.state.isViewDiagnose}
                            krankheit={this.state.diagnose || new Krankheit()} />
                    </div>
                    <div className = 'overflow-auto'>
                        <Typography variant="subtitle1" color="inherit">
                            Laborwerte
                        </Typography>
                        {this.props.currentKonsilPatient?.laborwerte?.map(labor =>
                            <LaborComponent key={"labor_component_" + labor.id}
                                labor={labor}
                                onViewClick={() => this.setState({
                                    isViewLabor: true,
                                    labor: labor
                                })}
                                delete={(k) => {
                                }}
                                error={false}
                                isViewOnly={true}
                            />
                        )}
                        <LaborViewComponent
                            handleClose={this.closeLabor}
                            openView={this.state.isViewLabor}
                            labor={this.state.labor || new Labor()}
                        />
                        {this.props.currentKonsilPatient?.laborSdTextHtml && this.props.currentKonsilPatient?.laborSdTextHtml !== "" &&
                            <Paper className="derma-diagnose-container">
                                {/* <Typography variant="subtitle2" color="inherit">
                                    Unstrukturierte Laborwerte
                                </Typography> */}
                                <div dangerouslySetInnerHTML={{ __html: this.props.currentKonsilPatient.laborSdTextHtml }} />
                            </Paper>}
                    </div>
                    <div>
                        <Typography variant="subtitle1" color="inherit">
                            Medikation
                        </Typography>
                        {this.props.currentKonsilPatient?.medikationen?.map(medikation =>
                            <MedikationComponent key={"medikation_component_" + medikation.id}
                                medikation={medikation}
                                onViewClick={() => this.setState({
                                    isViewMeds: true,
                                    medikation: medikation
                                })}
                                delete={(k) => {
                                }}
                                error={false}
                                isViewOnly={true}
                            />
                        )}
                        <MedikationViewComponent handleClose={this.closeMeds} isOpen={this.state.isViewMeds}
                            medikation={this.state.medikation || new Medikation()} />
                    </div>
                </Paper>
            </Box>
        );
    }
}

export const ViewPersonendatenContentContainer = connector(ViewPersonendatenContent);
export default ViewPersonendatenContentContainer;


interface IViewPatientDetailProps {
    patient: Patient;
    role: keyof typeof RoleTypes;
}

export class ViewPatientDetailComponent extends React.Component<IViewPatientDetailProps> {
    render() {
        return (
            <>
                <Box className="left-side">
                    <ViewText
                        labelVariant='body1'
                        label='Name:'
                        text={`${this.props.patient?.title || ""} ${this.props.patient?.vorname} ${this.props.patient?.namenszusatz || ""} ${this.props.patient?.vorsatzwort || ""} ${this.props.patient?.nachname} ${this.props.patient?.suffix || ""}`}
                        hasError={this.props.patient.vorname == null || this.props.patient.vorname === "" || this.props.patient.nachname == null || this.props.patient.nachname === ""}
                    />
                    <ViewText
                        labelVariant='body1'
                        label='Geschlecht:'
                        text={Geschlecht[this.props.patient?.geschlecht || 'UNBEKANNT'] || "unbekannt"}
                        hasError={this.props.patient.geschlecht == null || Object.keys(Geschlecht).indexOf(this.props.patient.geschlecht) === -1}
                    />
                    <ViewText
                        labelVariant='body1'
                        label='Geburtstag:'
                        text={(this.props.patient?.geburtsdatum) ? new Date(this.props.patient?.geburtsdatum).toLocaleDateString() : "-"}
                        hasError={this.props.patient.geburtsdatum == null || new Date(this.props.patient.geburtsdatum).toLocaleDateString() === "" || isNaN(this.props.patient.geburtsdatum) === true}
                    />
                    <ViewText
                        labelVariant='body1'
                        label='Adresse:'
                        text={assembleAdresse(this.props.patient?.strasseHausnummer,
                            this.props.patient?.plz, this.props.patient?.wohnort)
                        }
                    />
                </Box>
                <Box className="right-side">
                    <ViewText
                        labelVariant='body1'
                        label='Krankenkasse:'
                        text={this.props.patient?.krankenkasse?.name || "-"}
                        hasError={!this.props.patient?.krankenkasse || (this.props.patient?.krankenkasse && (this.props.patient?.krankenkasse.name == null || this.props.patient?.krankenkasse.name === ""))}
                    />
                    <ViewText
                        labelVariant='body1'
                        label='Kassen-IKNr:'
                        text={this.props.patient?.krankenkasse?.iknr || "-"}
                        hasError={!this.props.patient?.krankenkasse || (this.props.patient?.krankenkasse && (this.props.patient?.krankenkasse.iknr == null || this.props.patient?.krankenkasse.iknr === ""))}
                    />
                    <ViewText
                        labelVariant='body1'
                        label='eGK-Versichertenummer:'
                        text={(this.props.patient?.egkNummer) ? this.props.patient.egkNummer : "-"}
                        hasError={this.props.patient.egkNummer == null || this.props.patient.egkNummer === ""}
                    />
                    <ViewText
                        labelVariant='body1'
                        label='Versichertenstatus:'
                        text={(this.props.patient?.versichertenStatus) ? VersichertenStatus[this.props.patient.versichertenStatus] : "-"}
                        hasError={this.props.patient.versichertenStatus == null || Object.keys(VersichertenStatus).indexOf(this.props.patient.versichertenStatus) === -1}
                    />
                    <ViewText
                        labelVariant='body1'
                        label='Personengruppe:'
                        text={(this.props.patient?.personengruppe) ? Personengruppe[this.props.patient.personengruppe] : "-"}
                        hasError={this.props.patient.personengruppe == null || Object.keys(Personengruppe).indexOf(this.props.patient.personengruppe) === -1}
                    />
                    <ViewText
                        labelVariant='body1'
                        label='DMP-Zuordnung:'
                        text={(this.props.patient?.dmpZuordnung) ? DMPZuordnung[this.props.patient.dmpZuordnung] : "-"}
                        hasError={this.props.patient.dmpZuordnung == null || Object.keys(DMPZuordnung).indexOf(this.props.patient.dmpZuordnung) === -1}
                    />
                    {(this.props.role === "HAUSARZT" || this.props.role === "BETRIEBSSTAETTE_HAUSARZT") &&
                        <ViewText
                            labelVariant='body1'
                            label='Patientennummer:'
                            text={(this.props.patient?.externePatientenNummer) ? this.props.patient.externePatientenNummer : "-"}
                        />
                    }
                </Box>
            </>
        )
    }
}
