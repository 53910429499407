import { Box, IconProps, Tooltip, Typography } from "@material-ui/core";
import React from "react";

interface IProps {
    icon: React.ReactElement<IconProps>
    labelText: string
    tooltipText: string
}
export const TabLabel = (props: IProps) => {
    return (
        <Tooltip
            title = {props.tooltipText}
            placement = "top"
        >
            <Box className = 'flex-column-align-center'>           
                {props.icon}
                <Typography variant = 'caption'>{props.labelText}</Typography>           
            </Box>
        </Tooltip>
    )
}