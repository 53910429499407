import React from 'react';
import uuid from 'uuid';
// material components
import {Box, Button, Paper, Typography} from '@material-ui/core'
import {RootState} from '../../redux/reducers';
import {connect, ConnectedProps} from 'react-redux';
import {loading, loadSuccess} from '../../redux/actions';
import NewPasswordContainer from '../elements/new_password';
import {withNewPasswordMethods, WithNewPasswordMethodsProps} from '../../utils';
import SimpleModalContainer from '../elements/simple_modal';
import ZertifikatPasswortComponent from '../elements/zertifikat_passwort';


interface IState {
    altesPasswort: string;
}

interface IProps {
    isOpen: boolean;
    toggleModal: () => void;
    refreshZertifikat: Function;
    type: "Betriebsstaette" | "Arzt";
}

const mapStateToProps = (state: RootState, ownProps: IProps) => ({
    loading: state.general.loading,
    ...ownProps
})

const mapDispatchToProps = {
    dispatch_loading: (id: string) => loading(id),
    dispatch_loadSuccess: (id: string) => loadSuccess(id),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>
type Props = TPropsFromRedux & WithNewPasswordMethodsProps

export class UpdateZertifikatPopup extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            altesPasswort: "",
        };

        this.loadInstanceId = uuid.v4();

        this.handleAltesPasswordChange = this.handleAltesPasswordChange.bind(this);
    }

    private loadInstanceId: string;

    handleAltesPasswordChange(e) {
        this.setState({altesPasswort: e.target.value});
    }


    render() {
        const title: string = (this.props.type === "Arzt") ? "Arzt-Zertifikat erneuern" : "Betriebsstätten-Zertifikat erneuern"
        const disabled: boolean = (this.props.type === "Arzt") ?
            (this.state.altesPasswort == null || this.state.altesPasswort === "" ||
                this.props.newPassword == null || this.props.newPassword === "" ||
                this.props.newRepeatedPassword == null || this.props.newRepeatedPassword === "" ||
                this.props.newPassword !== this.props.newRepeatedPassword || this.props.loading[0] === this.loadInstanceId ||
                this.props.passwordStrength !== "strong")
            :
            (this.state.altesPasswort == null || this.state.altesPasswort === "")

        return (
            <SimpleModalContainer isOpen={this.props.isOpen}>
                <Paper className="modal-wrapper konsil-question-pop">
                    <Box className="modal-content renew-arzt-zertifikat" style={{maxWidth: "60vw"}}>
                        <h2>{title}</h2>
                        {this.props.type === "Betriebsstaette" &&
                        <Typography variant="caption" color="inherit" style={{maxWidth: "60vw"}}>
                            Zum Aktualisieren des Betriebsstättenzertifikats wird ein gültiges Arztzertifikat benötigt,
                            bitte geben Sie dafür Ihr Zertifikat-Passwort ein.
                        </Typography>
                        }
                        <ZertifikatPasswortComponent password={this.state.altesPasswort}
                                                     handlePasswordChange={this.handleAltesPasswordChange}/>

                        {/* <TextField id="pw-textarea" placeholder="Altes Passwort" value={this.state.altesPasswort}
                                onChange={(event) => this.handleAltesPasswordChange(event)} type="password"/> */}
                        {this.props.type === "Arzt" &&
                        <NewPasswordContainer setStrength={this.props.setStrength} newPassword={this.props.newPassword}
                                              context="ZERTIFIKAT"
                                              newRepeatedPassword={this.props.newRepeatedPassword}
                                              handleNewPasswordChange={this.props.handleNewPasswordChange}
                                              handleNewRepeatedPasswordChange={this.props.handleNewRepeatedPasswordChange}/>
                        }
                    </Box>
                    <Box className="modal-footer">
                        <Button variant="text" onClick={() => this.props.toggleModal()}>
                            Zurück
                        </Button>
                        <Button disabled={disabled}
                                variant="contained" color="primary"
                                onClick={() => {
                                    this.props.refreshZertifikat(this.state.altesPasswort, this.props.newPassword, this.loadInstanceId)
                                }}>
                            Zertifikat erneuern
                        </Button>
                    </Box>
                </Paper>
            </SimpleModalContainer>
        );
    }
}

export const UpdateZertifikatPopupComponent = connector(withNewPasswordMethods(UpdateZertifikatPopup));
export default UpdateZertifikatPopupComponent;
