import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../reducers';
import  * as PdfTypes from '../types/pdf_types';
import { KonsilService, KonsilStatusType, Failure, PdfService } from 'telescan-core';
import uuid from 'uuid';
import { failure } from './general_actions';

// 1. Pure redux actions

export function setPdfString(pdfString: string): PdfTypes.ISetPdfStringAction {
    return {
        type: PdfTypes.EPdfActions.SET_PDF_STRING,
        pdfString: pdfString
    }
}

export function clearPdfString(): PdfTypes.IClearPdfStringAction {
    return {
        type: PdfTypes.EPdfActions.CLEAR_PDF_STRING,
    }
}

export function setIsPdfLoading(isPdfLoading: boolean): PdfTypes.ISetIsPdfLoading {
    return {
        type: PdfTypes.EPdfActions.SET_IS_PDF_LOADING,
        isPdfLoading: isPdfLoading,
    }
}

// 2. Thunk middleware functions

// deprecated
export function getPdfStringOld(konsilId: string, konsilStatus: keyof typeof KonsilStatusType): ThunkAction<any, RootState, unknown, Action<string>> {
    return dispatch => {
        dispatch(setIsPdfLoading(true));
        const konsilService = new KonsilService();
        konsilService.getPdfPreview(konsilId || "", konsilStatus)
        .then((pdfString: string) => {
            dispatch(setPdfString(pdfString));
            dispatch(setIsPdfLoading(false));
        })
        .catch((failureObj: Failure) => {
            dispatch(setIsPdfLoading(false));
            const failureId: string = uuid.v4();
            dispatch(failure(failureId, failureObj));
        })
    }
}
////

export function getPdfString(konsilId: string, konsilStatus: keyof typeof KonsilStatusType): ThunkAction<any, RootState, unknown, Action<string>> {
    return dispatch => {
        dispatch(setIsPdfLoading(true));
        const pdfService = new PdfService();
        pdfService.getPdfPreview(konsilId || "", konsilStatus)
        .then((pdfString: string) => {
            dispatch(setPdfString(pdfString));
            dispatch(setIsPdfLoading(false));
        })
        .catch((failureObj: Failure) => {
            dispatch(setIsPdfLoading(false));
            const failureId: string = uuid.v4();
            dispatch(failure(failureId, failureObj));
        })
    }
}