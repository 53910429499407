import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { BearbeitungsStatusType, DermaDirectoryService, DermaRegistrationStatus, DermatologistInfo, Failure, KIMGeneral, KimService, KIMSettingsDTO, KimTimeouts, KIMUser, KimUserValidation, KonsilMetaInformation, KonsilStatusType, TIConnectorEvent, TIConnectorService } from 'telescan-core';
import { checkKimSettings, failure } from '.';
import { RootState } from '../reducers';
import  * as KimTypes from '../types/kim_types';
import uuid from 'uuid';
import { setSuccessMessage } from './general_actions';
import { KimClearInboxProgress } from '../types/kim_types';

// 1. Pure redux actions
export function setKimGeneral(kimGeneral: KIMGeneral): KimTypes.ISetKimGeneral {
    return {
        type: KimTypes.EKimActions.SET_KIM_GENERAL,
        kimGeneral: kimGeneral,
    }
}

export function setKimUser(kimUser: KIMUser): KimTypes.ISetKimUser {
    return {
        type: KimTypes.EKimActions.SET_KIM_USER,
        kimUser: kimUser,
    }
}

export function setDermatologists(dermatologists: DermatologistInfo[]): KimTypes.ISetDermatologists {
    return {
        type: KimTypes.EKimActions.SET_DERMATOLOGISTS,
        dermatologists: dermatologists,
    }
}

export function setKimMetadaten(kimMetadaten: KonsilMetaInformation[]): KimTypes.ISetKimMetadaten {
    return {
        type: KimTypes.EKimActions.SET_KIM_METADATEN,
        kimMetadaten: kimMetadaten
    }
}

export function setKimUserValidation(kimUserValidation: KimUserValidation): KimTypes.ISetKimUserValidation {
    return {
        type: KimTypes.EKimActions.SET_KIM_USER_VALIDATION,
        kimUserValidation: kimUserValidation
    }
}


export function setKimMetadatenKonsilBearbeitungsStatus(vorgangsId: string, bearbeitungsStatus: keyof typeof BearbeitungsStatusType): KimTypes.ISetKimMetadatenKonsilBearbeitungsstatus {
    return {
        type: KimTypes.EKimActions.SET_KIM_METADATEN_KONSIL_BEARBEITUNGSSTATUS,
        vorgangsId: vorgangsId,
        bearbeitungsStatus: bearbeitungsStatus,
    }
}


export function removeKimMetadatenKonsil(vorgangsId: string): KimTypes.IRemoveKimMetadatenKonsil {
    return {
        type: KimTypes.EKimActions.REMOVE_KIM_METADATEN_KONSIL,
        vorgangsId: vorgangsId,
    }
}

export function setTiConnectorEvent(tiConnectorEvent: TIConnectorEvent): KimTypes.ISetTIConnectorEvent {
    return {
        type: KimTypes.EKimActions.SET_TICONNECTOR_EVENT,
        tiConnectorEvent: tiConnectorEvent
    }
}


export function setIsKonsilSigned(isKonsilSigned: boolean): KimTypes.ISetIsKonsilSigned {
    return {
        type: KimTypes.EKimActions.SET_IS_KONSIL_SIGNED,
        isKonsilSigned: isKonsilSigned
    }
}

export function setDermaRegistration(dermatologistRegistration: DermaRegistrationStatus): KimTypes.ISetDermatologistRegistration {
    return {
        type: KimTypes.EKimActions.SET_DERMATOLOGIST_REGISTRATION,
        dermatologistRegistration: dermatologistRegistration
    }
}

export function setIsTiConnected(isConnected: boolean): KimTypes.ISetIsTiConnected {
    return {
        type: KimTypes.EKimActions.SET_IS_TI_CONNECTED,
        isTiConnected: isConnected
    }
}

export function setIsTiStatusLoading(isTiStatusLoading: boolean): KimTypes.ISetIsTiStatusLoading {
    return {
        type: KimTypes.EKimActions.SET_IS_TI_STATUS_LOADING,
        isTiStatusLoading: isTiStatusLoading
    }
}

export function setIsDataLoading(isDataLoading: boolean): KimTypes.ISetIsDataLoading {
    return {
        type: KimTypes.EKimActions.SET_IS_DATA_LOADING,
        isDataLoading: isDataLoading
    }
}

export function setIsDermaListLoading(isDermaListLoading: boolean): KimTypes.ISetIsDermaListLoading {
    return {
        type: KimTypes.EKimActions.SET_IS_DERMA_LIST_LOADING,
        isDermaListLoading: isDermaListLoading
    }
}

export function setIsTestTiConnectionClicked(isTestTiConnectionClicked: boolean): KimTypes.ISetIsTestTiConnectionClicked {
    return {
        type: KimTypes.EKimActions.SET_IS_TEST_TI_CONNECTION_CLICKED,
        isTestTiConnectionClicked: isTestTiConnectionClicked
    }
}

export function setKimDeleteMailProgress(kimClearInboxProgress?: KimClearInboxProgress): KimTypes.ISetClearInboxProgress {
    return {
        type: KimTypes.EKimActions.SET_CLEAR_INBOX_PROGRESS,
        progress: kimClearInboxProgress
    }
}


// 2. Thunk middleware functions

export function getTiConnectionStatus(): ThunkAction<Promise<any>, RootState, unknown, Action<string>> {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setIsTiStatusLoading(true));
            new KimService().getTiConnectionStatus()
            .then(() => {
                resolve("ok");
                dispatch(setIsTiConnected(true));
                dispatch(setIsTiStatusLoading(false));
            })
            .catch((failureObj: any) => {
                dispatch(failure(uuid.v4(), failureObj))
                dispatch(setIsTiConnected(false));
                dispatch(setIsTiStatusLoading(false));
            })
        })
    }
}

// export function getSmtpAndPop3ConnectionStatus(): ThunkAction<Promise<any>, RootState, unknown, Action<string>> {
//     return (dispatch) => {
//         return new Promise((resolve, reject) => {
//             dispatch(setIsSmtpStatusLoading(true));
//             new KimService().getSmtpAndPop3ConnectionStatus()
//             .then(() => {
//                 resolve("ok");
//                 dispatch(setIsSmtpConnected(true));
//                 dispatch(setIsSmtpStatusLoading(false));
//             })
//             .catch((failureObj: any) => {
//                 dispatch(failure(uuid.v4(), failureObj));
//                 dispatch(setIsSmtpConnected(false));
//                 dispatch(setIsSmtpStatusLoading(false));
//             })
//         })
//     }
// }

// export function getTiConnectionStatus(): ThunkAction<Promise<any>, RootState, unknown, Action<string>> {
//     return (dispatch) => {
//         return new Promise((resolve, reject) => {
//             dispatch(setIsTiStatusLoading(true));
//             new TIConnectorService().getTiConnectorStatus()
//             .then(() => {
//                 resolve("ok");
//                 dispatch(setIsTiConnected(true));
//                 dispatch(setIsTiStatusLoading(false));
//             })
//             .catch((failureObj: any) => {
//                 dispatch(failure(uuid.v4(), failureObj))
//                 dispatch(setIsTiConnected(false));
//                 dispatch(setIsTiStatusLoading(false));
//             })
//         })
//     }
// }

export function getKimSettings(): ThunkAction<any, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {

        new KimService().getKIMSettings()
        .then((response: KIMSettingsDTO) => {
            if (response.kimGeneral && response.kimUser) {
                dispatch(setKimGeneral(response.kimGeneral));
                dispatch(setKimUser(response.kimUser));
                dispatch(checkKimSettings(response.kimGeneral, response.kimUser));
            }
        })
        .catch((failureObj: Failure) => {
            dispatch(failure(uuid.v4(), failureObj));
        });
    }
}

export function sendKimSettings(kimGeneral: KIMGeneral, kimUser: KIMUser): ThunkAction<any, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(checkKimSettings(kimGeneral, kimUser));
            new KimService().sendKIMSettings(kimGeneral, kimUser)
            .then((response: KIMSettingsDTO) => {
                resolve(response);
                response.kimGeneral && dispatch(setKimGeneral(response.kimGeneral));
                response.kimUser && dispatch(setKimUser(response.kimUser));
                //dispatch(getInitialisedServices());
            })
            .catch((failureObj: Failure) => {
                dispatch(failure(uuid.v4(), failureObj));
            });
        })

    }
}

export function getAllDermatologists(): ThunkAction<any, RootState, unknown, Action<string>> {
    return (dispatch) => {
        dispatch(setIsDermaListLoading(true));
        new DermaDirectoryService().getAllDermatologists()
        .then((response: DermatologistInfo[]) => {
            dispatch(setIsDermaListLoading(false));
            dispatch(setDermatologists(response));
        })
        .catch((failureObj: Failure) => {
            dispatch(setIsDermaListLoading(false));
            dispatch(failure(uuid.v4(), failureObj));
        });
    }
}

export function getDermatologistRegistrationStatus(): ThunkAction<any, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        new DermaDirectoryService().getDermatologistRegistrationStatus()
        .then((response: DermaRegistrationStatus) => {
            dispatch(setDermaRegistration(response));
            if (getState().router.location.pathname === "/") {
                if (!response.active) {
                    const failureObj = new Failure();
                    failureObj.error = `Sie sind im TeleScan-Verzeichnisdienst nicht registriert. Beachten Sie, dass Ihre LANR, BSNR und private E-Mail Adresse erst über zollsoft oder Managementgesellschaft hinterlegt werden mussen.
                        Danach können Sie ihren TeleScan Account im Verzeichnisdienst freischalten. Erst nach erfolgreicher Freischaltung können Hausärzte dermatologische Telekonsile an Sie versenden.`
                    dispatch(failure(uuid.v4(), failureObj));
                }
                if (response.active === "INACTIVE") {
                    const failureObj = new Failure();
                    failureObj.error = `Bitte schalten Sie ihren TeleScan Account im Verzeichnisdienst frei. Erst nach erfolgreicher Freischaltung können Hausärzte dermatologische Telekonsile an Sie versenden.
                        Diese Angaben können jederzeit in den Einstellungen überprüft oder bearbeitet werden.`
                    dispatch(failure(uuid.v4(), failureObj));
                }
            }
        })
        .catch((failureObj: Failure) => {
            dispatch(setDermaRegistration(new DermaRegistrationStatus()));
            //dispatch(failure(uuid.v4(), failureObj));
        });
    }
}

export function registerDermatologistInDirectory(dermatologistMetadata: DermatologistInfo): ThunkAction<any, RootState, unknown, Action<string>> {
    return (dispatch) => {
        new DermaDirectoryService().registerDermatologist(dermatologistMetadata)
        .then(() => {
            dispatch(getDermatologistRegistrationStatus());
            dispatch(setSuccessMessage(uuid.v4(), "Eine E-Mail mit einem Aktivierungscode wurde versendet. Geben Sie es in das entsprechende Feld ein, um Ihr Konto zu aktivieren"))
        })
        .catch((failureObj: Failure) => {
            dispatch(failure(uuid.v4(), failureObj));
        });
    }
}

export function activateDermatologistInDirectory(activationKey: string): ThunkAction<any, RootState, unknown, Action<string>> {
    return (dispatch) => {
        new DermaDirectoryService().activateDermatologist(activationKey)
        .then(() => {
            dispatch(getDermatologistRegistrationStatus());
            dispatch(setSuccessMessage(uuid.v4(), "Ihr Account im TeleScan-Verzeichnisdienst wurde erfolgreich aktiviert"))
        })
        .catch((failureObj: Failure) => {
            dispatch(failure(uuid.v4(), failureObj));
        });
    }
}


export function updateCDAsKIM(): ThunkAction<any, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        //if (!getState().kim.isDataLoading) {
            dispatch(setIsDataLoading(true));
            const timeoutId = window.setTimeout(() => dispatch(setIsDataLoading(false)), KimTimeouts.DATA_LOADING);
            new KimService().updateCDAforHausarzt(getState().webSocket.clientId)
            .catch((failureObj: Failure) => {
                dispatch(failure(uuid.v4(), failureObj));
                dispatch(setIsDataLoading(false));
                window.clearTimeout(timeoutId);
            });
        //}
    }
}

export function uploadCertificates(certList: Blob[], connectorId: string, certPassword: string): ThunkAction<any, RootState, unknown, Action<string>> {
    return (dispatch) => {
        dispatch(setIsTestTiConnectionClicked(false));
        const tiConnectorService = new TIConnectorService();
        certList.forEach(cert => {
            tiConnectorService.uploadCertificates(cert, connectorId, certPassword)
            .then((response: KIMSettingsDTO) => {
                if (response.kimGeneral && response.kimUser) {
                    dispatch(checkKimSettings(response.kimGeneral, response.kimUser));
                    dispatch(setKimGeneral(response.kimGeneral));
                    dispatch(setKimUser(response.kimUser));
                }
                if (cert.type.includes("x-pkcs12")) {
                    dispatch(setSuccessMessage(uuid.v4(), "Das Client-Zertifikat des TI-Konnektors wurden erfolgreich hochgeladen"));
                } else if (cert.type.includes("x-x509-ca-cert")) {
                    dispatch(setSuccessMessage(uuid.v4(), "Das Server-Zertifikat des TI-Konnektors wurden erfolgreich hochgeladen"));
                }
                //dispatch(getInitialisedServices());
            })
            .catch((failureObj: Failure) => {
                dispatch(failure(uuid.v4(), failureObj));
            });
        });
        //new TIConnectorService().uploadCertificates(cert[0])

    }
}

export function registerEHBA(): ThunkAction<any, RootState, unknown, Action<string>> {
    return (dispatch) => {
        new TIConnectorService().registerEHBA()
        .then(() => {
            dispatch(getKimUserValidation())
        })
        .catch((failureObj: Failure) => {
            dispatch(failure(uuid.v4(), failureObj));
        });
    }
}

export function getKimUserValidation(): ThunkAction<any, RootState, unknown, Action<string>> {
    return (dispatch) => {
        new KimService().getKIMValidation()
        .then((data) => {
            dispatch(setKimUserValidation(data))
        })
        .catch((failureObj: Failure) => {
            dispatch(failure(uuid.v4(), failureObj));
        });
    }
}

export function signKonsil(konsilId: string, followingStatus: keyof typeof KonsilStatusType): ThunkAction<any, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        new KimService().signKonsil(konsilId, followingStatus, getState().webSocket.clientId)
        .catch((failureObj: Failure) => {
            dispatch(failure(uuid.v4(), failureObj));
        });
    }
}

export function clearKimInbox(deleteAll: boolean): ThunkAction<any, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        new KimService().clearKimInbox(deleteAll)
        .then((response) => {
            console.log(response)
            if (response.body > 0) {
                dispatch(setSuccessMessage(uuid.v4(), `Erfolgreich ${response.body} Nachrichten gelöscht.`))
            } else {
                dispatch(failure(uuid.v4(), "Es konnten keine Nachrichten gelöscht werden."))
            }
        })
        .catch((failureObj: Failure) => {
            dispatch(failure(uuid.v4(), failureObj));
        });
    }
}
