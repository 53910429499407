import {TSendDownloadKonsilActionTypes, ESendDownloadKonsilActions} from '../types/upload_download_konsil_types';

export interface ISendDownloadKonsilState {
    isSendingKonsil: boolean;
    eventText: string;
} 

const initialState: ISendDownloadKonsilState = {
    isSendingKonsil: false,
    eventText: "",
}
export function send_download_konsil_reducer(state = initialState, action: TSendDownloadKonsilActionTypes): ISendDownloadKonsilState {
    switch (action.type) {
        case ESendDownloadKonsilActions.SET_IS_SENDING_KONSIL:
            return {
                ...state,
                isSendingKonsil: action.isSendingKonsil
            }
        case ESendDownloadKonsilActions.SET_EVENT_TEXT:
            return {
                ...state,
                eventText: action.eventText
            }
        default:
            return state
    }
}