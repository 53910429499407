import { EEvaluationActions, TEvaluationActionTypes} from '../types/evaluation_types';

export interface IEvaluationState {
    tabStatus: {konsilID: string, statusMap: Map<number, Record<string, boolean>>}[];
    konsilAnamneseErrors: {konsilID: string, errors: Map<string, string>}[]; // <Feldname, Fehlermeldung>
    konsilPatientErrors: {konsilID: string, errors: Map<string, string>}[]; // <Feldname, Fehlermeldung>
    locationErrors: {konsilID: string, errors: Map<string, string>}[]; // <Feldname, Fehlermeldung>
    konsilFrageErrors: {konsilID: string, errors: Map<string, string>}[]; // <Feldname, Fehlermeldung>
    einwilligungErrors: {konsilID: string, errors: Map<string, string>}[];
    konsilFeedbackErrors: {KonsilabschlussID: string, errors: Map<string, string>}[];
    konsiliarberichtErrors: Map<string, string>; // <Feldname, Fehlermeldung>
    konsiliarFeedbackErrors: Map<string, string>; // <Feldname, Fehlermeldung>
    kimSettingsError: boolean;
}

const initialState: IEvaluationState = {
    tabStatus: [],
    konsilAnamneseErrors: [],
    konsilPatientErrors: [],
    locationErrors: [],
    konsilFrageErrors: [],
    einwilligungErrors:[],
    konsilFeedbackErrors: [],
    konsiliarberichtErrors: new Map<string, string>(),
    konsiliarFeedbackErrors: new Map<string, string>(),
    kimSettingsError: true,
}

export function evaluation_reducer(state = initialState, action: TEvaluationActionTypes): IEvaluationState {
    switch (action.type) {
        case EEvaluationActions.TAB_STATUS_CHANGED:
            const tabStatusIndex = state.tabStatus.findIndex(item => item.konsilID === action.konsilID)
            const tabStatusNewArray = [...state.tabStatus];
            tabStatusNewArray[tabStatusIndex].statusMap = action.statusMap
            return {
                ...state,
                tabStatus: tabStatusNewArray
            }
        case EEvaluationActions.ADD_TAB_STATUS:
            return {
                ...state,
                tabStatus: [
                    ...state.tabStatus,
                    {konsilID: action.konsilID, statusMap: action.statusMap}
                ]

            }
        case EEvaluationActions.DELETE_TAB_STATUS:
            const filteredTabStatus = state.tabStatus.filter(item => item.konsilID !== action.konsilID)
            return {
                ...state,
                tabStatus: filteredTabStatus
            }
        case EEvaluationActions.CHANGE_KONSILANAMNESE_ERRORS:
            const anamneseErrorIndex = state.konsilAnamneseErrors.findIndex(item => item.konsilID === action.konsilID)
            const anamneseErrorNewArray = [...state.konsilAnamneseErrors];
            anamneseErrorNewArray[anamneseErrorIndex].errors = action.fieldErrorMap
            return {
                ...state,
                konsilAnamneseErrors: anamneseErrorNewArray
            }
        case EEvaluationActions.ADD_KONSILANAMNESE_ERRORS:
            return {
                ...state,
                konsilAnamneseErrors: [
                    ...state.konsilAnamneseErrors,
                    {konsilID: action.konsilID, errors: action.fieldErrorMap}
                ]
            }
        case EEvaluationActions.DELETE_KONSILANAMNESE_ERRORS:
            const anamneseErrorsFiltered = state.konsilAnamneseErrors.filter(item => item.konsilID !== action.konsilID)
            return {
                ...state,
                konsilAnamneseErrors: anamneseErrorsFiltered
            }
        case EEvaluationActions.CHANGE_KONSILPATIENT_ERRORS:
            const patientErrorIndex = state.konsilPatientErrors.findIndex(item => item.konsilID === action.konsilID)
            const patientErrorNewArray = [...state.konsilPatientErrors];
            patientErrorNewArray[patientErrorIndex].errors = action.fieldErrorMap
            return {
                ...state,
                konsilPatientErrors: patientErrorNewArray
            }
        case EEvaluationActions.ADD_KONSILPATIENT_ERRORS:
            return {
                ...state,
                konsilPatientErrors: [
                    ...state.konsilPatientErrors,
                    {konsilID: action.konsilID, errors: action.fieldErrorMap}
                ]
            }
        case EEvaluationActions.DELETE_KONSILPATIENT_ERRORS:
            const patientErrorsFiltered = state.konsilPatientErrors.filter(item => item.konsilID !== action.konsilID)
            return {
                ...state,
                konsilPatientErrors: patientErrorsFiltered
            }
        case EEvaluationActions.CHANGE_LOCATION_ERRORS:
            const locationErrorIndex = state.locationErrors.findIndex(item => item.konsilID === action.konsilID)
            const locationErrorNewArray = [...state.locationErrors];
            locationErrorNewArray[locationErrorIndex].errors = action.fieldErrorMap
            return {
                ...state,
                locationErrors: locationErrorNewArray
            }
        case EEvaluationActions.ADD_LOCATION_ERRORS:
            return {
                ...state,
                locationErrors: [
                    ...state.locationErrors,
                    {konsilID: action.konsilID, errors: action.fieldErrorMap}
                ]
            }
        case EEvaluationActions.DELETE_LOCATION_ERRORS:
            const locationErrorsFiltered = state.locationErrors.filter(item => item.konsilID !== action.konsilID)
            return {
                ...state,
                locationErrors: locationErrorsFiltered
            }
        case EEvaluationActions.CHANGE_KONSILFRAGE_ERRORS:
            const frageErrorIndex = state.konsilFrageErrors.findIndex(item => item.konsilID === action.konsilID)
            const frageErrorNewArray = [...state.konsilFrageErrors];
            frageErrorNewArray[frageErrorIndex].errors = action.fieldErrorMap
            return {
                ...state,
                konsilFrageErrors: frageErrorNewArray
            }
        case EEvaluationActions.ADD_KONSILFRAGE_ERRORS:
            return {
                ...state,
                konsilFrageErrors: [
                    ...state.konsilFrageErrors,
                    {konsilID: action.konsilID, errors: action.fieldErrorMap}
                ]
            }

        case EEvaluationActions.DELETE_KONSILFRAGE_ERRORS:
            const frageErrorsFiltered = state.konsilFrageErrors.filter(item => item.konsilID !== action.konsilID)
            return {
                ...state,
                konsilFrageErrors: frageErrorsFiltered
            }

        case EEvaluationActions.CHANGE_EINWILLIGUNG_ERRORS:
            const einwilligungErrorIndex = state.einwilligungErrors.findIndex(item => item.konsilID === action.konsilID)
            const einwilligungErrorNewArray = [...state.einwilligungErrors];
            einwilligungErrorNewArray[einwilligungErrorIndex].errors = action.fieldErrorMap
            return {
                ...state,
                einwilligungErrors: einwilligungErrorNewArray
            }

        case EEvaluationActions.ADD_EINWILLIGUNG_ERRORS:
            return {
                ...state,
                einwilligungErrors: [
                    ...state.einwilligungErrors,
                    {konsilID: action.konsilID, errors: action.fieldErrorMap}
                ]
            }

        case EEvaluationActions.DELETE_EINWILLIGUNG_ERRORS:
            const einwilligungsErrorsFiltered = state.einwilligungErrors.filter(item => item.konsilID !== action.konsilID)
            return {
                ...state,
                einwilligungErrors: einwilligungsErrorsFiltered
            }

        case EEvaluationActions.CHANGE_KONSILFEEDBACK_ERRORS:
            const feedbackErrorIndex = state.konsilFeedbackErrors.findIndex(item => item.KonsilabschlussID === action.konsilabschlussID)
            const feedbackErrorNewArray = [...state.konsilFeedbackErrors];
            feedbackErrorNewArray[feedbackErrorIndex].errors = action.fieldErrorMap
            return {
                ...state,
                konsilFeedbackErrors: feedbackErrorNewArray
            }

        case EEvaluationActions.ADD_KONSILFEEDBACK_ERRORS:
            return {
                ...state,
                konsilFeedbackErrors: [
                    ...state.konsilFeedbackErrors,
                    {KonsilabschlussID: action.konsilabschlussID, errors: action.fieldErrorMap}
                ]
            }

        case EEvaluationActions.DELETE_KONSILFEEDBACK_ERRORS:
            const feedbackErrorsFiltered = state.konsilFeedbackErrors.filter(item => item.KonsilabschlussID !== action.konsilabschlussID)
            return {
                ...state,
                konsilFeedbackErrors: feedbackErrorsFiltered
            }

        case EEvaluationActions.KONSILIARBERICHT_ERRORS:
            return {
                ...state,
                konsiliarberichtErrors: action.fieldErrorMap
            }

        case EEvaluationActions.KONSILIARFEEDBACK_ERRORS:
            return {
                ...state,
                konsiliarFeedbackErrors: action.fieldErrorMap
            }

        case EEvaluationActions.SET_KIM_SETTINGS_ERROR:
            return {
                ...state,
                kimSettingsError: action.hasError
            }

        default:
            return state
    }
}
