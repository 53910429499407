import React from 'react';

import {Box} from '@material-ui/core';
import {RootState} from "../../../redux/reducers";
import {connect, ConnectedProps} from "react-redux";
import {ViewKonsiliarberichtContainer} from './view_konsiliarbericht';
import ViewPersonendatenContentContainer from './view_personendatencontent';


const mapStateToProps = (state: RootState) => ({
    role: state.user.role
})

const mapDispatchToProps = {}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>


export class ViewPersonendaten extends React.Component<TPropsFromRedux> {

    render() {
        return (
            <Box className="splittscreen">
                <Box className="person-data-container">
                    <ViewPersonendatenContentContainer/>
                </Box>
                <Box className="split-konsil">
                    <ViewKonsiliarberichtContainer hasSideView={true}/>
                </Box>
            </Box>
        );
    }
}

export const ViewPersonendatenContainer = connector(ViewPersonendaten);
export default ViewPersonendatenContainer;


