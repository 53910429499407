import React, { createRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    IconButton,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Add, AttachFile, Close, Delete, InsertDriveFile } from "@material-ui/icons";
import {
    Allergien,
    Anamnese,
    Atopie,
    AttachmentService,
    Befund,
    BeschwerdeDauer,
    BeschwerdeType,
    FamilienAnamnese,
    Konsil,
    Failure,
} from 'telescan-core';
import { RootState } from "../../../redux/reducers";
import {
    checkAnamneseStatus,
    deleteAttachment,
    downloadAttachment,
    failure,
    loading,
    loadSuccess,
    setSuccessMessage,
    updateKonsil,
    uploadAttachment
} from "../../../redux/actions";
//import * as _ from 'lodash';
import { AttachmentState } from "../../../redux/reducers/image_attachment_reducer";
import {
    displayAttachment,
    withFileUpload,
    WithFileUploadProps,
    withPdfCreation,
    WithPdfCreationProps
} from '../../../utils';
import {
    withLoadKonsileAsAttachments,
    WithLoadKonsileAsAttachmentsProps
} from '../../../utils/with_load_konsile_attachments';
import { OldKonsil } from '../../elements/old_konsil';
import PdfInline from '../../elements/pdf_inline';

enum BeschwerdeDauerAbbrv {
    D10 = "-1W",
    D20 = "1-4W",
    D30 = "+4W",
    D90 = "sonstige"
}

const mapStateToProps = (state: RootState) => ({
    isDesktop: state.general.isDesktop,
    currentKonsil: state.konsile.current_konsil,
    attachments: state.image_attachment.attachments,
    konsilAnamneseErrors: state.evaluation.konsilAnamneseErrors,
    failures: state.general.failures,
})

const mapDispatchToProps = {
    updateKonsil: (konsil: Konsil, previousKonsil: Konsil) => updateKonsil(konsil, previousKonsil),
    dispatch_loading: (id: string) => loading(id),
    dispatch_loadSuccess: (id: string) => loadSuccess(id),
    dispatch_failure: (id: string, message: Failure) => failure(id, message),
    uploadAttachment: (file: File, loadInstanceId: string, konsil_id: string) => uploadAttachment(konsil_id, file, loadInstanceId),
    deleteAttachment: (attachment_id: string) => deleteAttachment(attachment_id),
    downloadAttachment: (attachment_id: string) => downloadAttachment(attachment_id),
    dispatch_checkAnamneseStatus: (konsil: Konsil) => checkAnamneseStatus(konsil),
    dispatch_setSuccessMessage: (id: string, message: string) => setSuccessMessage(id, message),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>
type Props = TPropsFromRedux & WithPdfCreationProps & WithFileUploadProps & WithLoadKonsileAsAttachmentsProps

interface IState {
    showSide: boolean;
    konsil: Konsil;
    previousKonsil: Konsil;
    //beschwerden: string[];
    beschwerden: boolean[];
    keineBeschwerden: boolean;
    pdfData: any;
    isShowAttachment: boolean;
}

export class EditAmanese extends React.Component<Props, IState> {

    private input = createRef<HTMLInputElement>();

    constructor(props: Props) {
        super(props);
        const konsil = props.currentKonsil || new Konsil();
        if (!konsil.befund)
            konsil.befund = new Befund();
        if (!konsil.anamnese)
            konsil.anamnese = new Anamnese();
        this.state = {
            showSide: false,
            konsil: props.currentKonsil || new Konsil(),
            previousKonsil: new Konsil(),
            beschwerden: this.processBeschwerden(props.currentKonsil?.anamnese),
            pdfData: undefined,
            isShowAttachment: false,
            keineBeschwerden: props.currentKonsil.anamnese.noComplaint,
        };

        this.processBeschwerden = this.processBeschwerden.bind(this);
        this.updateBeschwerden = this.updateBeschwerden.bind(this);
        this.updateKonsil = this.updateKonsil.bind(this);
        this.updateKonsilDirect = this.updateKonsilDirect.bind(this);
        this.getPdfData = this.getPdfData.bind(this);
        this.onAttachmentClose = this.onAttachmentClose.bind(this);
        this.handleKeineBeschwerdenCheckbox = this.handleKeineBeschwerdenCheckbox.bind(this);
    }

    private processBeschwerden(anamnese: Anamnese | undefined): boolean[] {
        let bool: boolean[] = [];
        if (anamnese) {
            bool = [anamnese.jucken, anamnese.schmerzen, anamnese.brennen, anamnese.naessen, anamnese.bluten];
        }
        return bool;
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.currentKonsil !== this.props.currentKonsil) {
            this.setState({
                konsil: this.props.currentKonsil || new Konsil(),
                beschwerden: this.processBeschwerden(this.props.currentKonsil?.anamnese),
                keineBeschwerden: this.props.currentKonsil.anamnese.noComplaint,
            });
        }
    }

    componentDidMount() { // assign  beschwerdeSonstigeDauer to local state variable
        this.saveBeschwerdeSonstigeDauer = this.state.konsil.anamnese.beschwerdeSonstigeDauer;
    }

    getPdfData(attachment_id: string) {
        this.setState({
            isShowAttachment: true,
        })
        const attachmentService: AttachmentService = new AttachmentService();
        attachmentService.getAttachment(attachment_id)
            .then(attachment => {
                this.setState({
                    pdfData: attachment,
                })
            })
            .catch(error => console.log(error))
    }

    onAttachmentClose() {
        this.setState({
            isShowAttachment: false,
        })
    }

    private updateKonsil() {
        this.updateKonsilDirect(this.state.konsil, this.state.beschwerden);
    }

    private updateKonsilDirect(konsil: Konsil, beschwerden: boolean[]) {
        const updatedKonsil: Konsil = {
            ...konsil,
            anamnese: {
                ...konsil.anamnese,
                jucken: beschwerden[0],
                schmerzen: beschwerden[1],
                brennen: beschwerden[2],
                naessen: beschwerden[3],
                bluten: beschwerden[4],
            }
        }
        this.props.updateKonsil(updatedKonsil, this.state.previousKonsil);
        this.props.dispatch_checkAnamneseStatus(updatedKonsil)
    }

    private updateBeschwerden(checked: boolean, value: number) {
        this.setState({previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))});
        let values: boolean[] = this.state.beschwerden;
        if (checked) values[value] = true
        else values[value] = false
        this.setState(
            {
                beschwerden: values,
                konsil: {
                    ...this.state.konsil,
                    anamnese: {
                        ...this.state.konsil.anamnese,
                        noComplaint: false
                    }
                }
            },
            () => this.updateKonsilDirect(this.state.konsil, values)
        );
    }

    private handleKeineBeschwerdenCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            keineBeschwerden: event.target.checked,
            previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil)),
        });
        if (event.target.checked) {
            this.saveBeschwerden = this.state.beschwerden;
            this.saveBeschwerdeFreitext = this.state.konsil.anamnese.beschwerdeFreitext;
            this.saveBeschwerdeDauer = this.state.konsil.anamnese.beschwerdeDauer;
            this.saveBeschwerdeSonstigeDauer = this.state.konsil.anamnese.beschwerdeSonstigeDauer;
            this.saveVeraenderungAuessereFaktoren = this.state.konsil.anamnese.veraenderungAuessereFaktoren;
            this.saveVerlaufRezidivierend = this.state.konsil.anamnese.verlaufRezidivierend
            this.saveVerlaufUnveraendert = this.state.konsil.anamnese.verlaufUnveraendert
            this.saveVerlaufZunehmend = this.state.konsil.anamnese.verlaufZunehmend
            this.saveVeraenderungAuessereFaktorenFreitext = this.state.konsil.anamnese.veraenderungAuessereFaktorenFreitext
            this.setState(
                {
                    beschwerden: new Array(this.state.beschwerden.length).fill(false),
                    konsil: {
                        ...this.state.konsil,
                        anamnese: {
                            ...this.state.konsil.anamnese,
                            noComplaint: true,
                            beschwerdeFreitext: "",
                            beschwerdeDauer: "D90",
                            beschwerdeSonstigeDauer: "",
                            veraenderungAuessereFaktoren: false,
                            verlaufRezidivierend: false,
                            verlaufUnveraendert: false,
                            verlaufZunehmend: false,
                            veraenderungAuessereFaktorenFreitext: ""
                        }
                        
                    }
                },
                this.updateKonsil
            );
        }
        else {
            this.setState(
                {
                    beschwerden: this.saveBeschwerden,
                    konsil: {
                        ...this.state.konsil,
                        anamnese: {
                            ...this.state.konsil.anamnese,
                            noComplaint: false,
                            beschwerdeFreitext: this.saveBeschwerdeFreitext,
                            beschwerdeDauer: this.saveBeschwerdeDauer,
                            beschwerdeSonstigeDauer: this.saveBeschwerdeSonstigeDauer,
                            veraenderungAuessereFaktoren: this.saveVeraenderungAuessereFaktoren,
                            verlaufRezidivierend: this.saveVerlaufRezidivierend,
                            verlaufUnveraendert: this.saveVerlaufUnveraendert,
                            verlaufZunehmend: this.saveVerlaufZunehmend,
                            veraenderungAuessereFaktorenFreitext: this.saveVeraenderungAuessereFaktorenFreitext,
                        }
                        
                    }
                },
                this.updateKonsil
            );
        }
    }
    private saveBeschwerden: boolean[] = [];
    private saveBeschwerdeFreitext: string | undefined = "";
    private saveBeschwerdeDauer: keyof typeof BeschwerdeDauerAbbrv = "D90";
    private saveVeraenderungAuessereFaktoren: boolean = false;
    private saveVerlaufRezidivierend: boolean = false;
    private saveVerlaufUnveraendert: boolean = false;
    private saveVerlaufZunehmend: boolean = false;
    private saveBeschwerdeSonstigeDauer: string | undefined = ""
    private saveVeraenderungAuessereFaktorenFreitext: string | undefined = ""

    private saveFamilienAngaben: FamilienAnamnese = new FamilienAnamnese()
    private saveVerlauf: Anamnese = new Anamnese()

    render() {
        const anamneseError: Map<string, string> = this.props.konsilAnamneseErrors.find(item => item.konsilID === this.props.currentKonsil.id)?.errors || new Map<string, string>();
        return (
            <Box className="anamnese-data-container"
                onDragEnter={this.props.onDragEnter}>
                <div className={"drag-drop-container" + (this.props.isActiveDrag ? " active" : "")}
                    onDragEnter={this.props.onDragEnter}
                    onDragOver={this.props.onDragOver}
                    onDragLeave={this.props.onDragLeave}
                    onDrop={(event) => this.props.onDrop(
                        event,
                        this.props.uploadAttachment,
                        [500, 500, Infinity, Infinity],
                        this.props.currentKonsil?.id || "Unknown konsilID"
                    )}>
                    <div className="border-container">
                        <div className="elements">
                            <AttachFile className="attachment-icon"></AttachFile>
                            <p>Anhänge hier ablegen</p>
                        </div>
                    </div>
                </div>
                <input accept="image/png, image/jpeg, application/pdf" className="upload-input" id="upload-attachment"
                    multiple={true}
                    type="file" ref={this.input}
                    onChange={(event) => this.props.onInputChanged(
                        event,
                        this.props.uploadAttachment,
                        [500, 500, Infinity, Infinity],
                        this.props.currentKonsil?.id || "Unknown konsilID")
                    }
                />
                <Box className="scroll-box">
                    <form className="form-content" noValidate autoComplete="off">
                        <FormControl className="verdacht-malignitaet" component="fieldset" required
                            error={anamneseError.has("konsil.malignitaet")}
                        >
                            <FormLabel className="bold-label float-left" component="legend">Verdacht auf Malignität</FormLabel>
                            <RadioGroup
                                row
                                aria-label="verdacht-malignitaet"
                                value={(this.state.konsil.befund?.malignitaet != null) ? this.state.konsil.befund?.malignitaet?.toString() : ""}
                                name="verdacht-malignitaet"
                                onChange={(event) => {
                                    this.setState({previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))});
                                    const befund: Befund = this.state.konsil.befund || new Befund();
                                    befund.malignitaet = event.target.value === "true" ? true : false;
                                    let updatedKonsil = {
                                        ...this.state.konsil,
                                        befund: befund
                                    }
                                    this.setState({
                                        konsil: updatedKonsil
                                    }, this.updateKonsil);
                                }}
                            >
                                <FormControlLabel value="true" control={<Radio />} label="ja" />
                                <FormControlLabel value="false" control={<Radio />} label="nein" />
                            </RadioGroup>
                        </FormControl>
                        <FormControl
                            className="veraenderung-malignitaet"
                            component="fieldset"
                            required
                            error={anamneseError.has("konsil.malignitaet")}
                        >
                            <FormLabel className="float-left" component="legend">Veränderungen</FormLabel>
                            <FormGroup row className="">
                                {[{ prop: "aenderungGroesse", label: "Größe" }, {
                                    prop: "aenderungForm",
                                    label: "Form"
                                }, { prop: "aenderungFarbe", label: "Farbe" }].map((item) =>
                                    <FormControlLabel
                                        key={item.prop}
                                        control={<Checkbox name="size"
                                            checked={this.state.konsil.befund[item.prop] || false}
                                            onChange={(event) => {
                                                this.setState({previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))});
                                                const befund: Befund = this.state.konsil.befund || new Befund();
                                                befund[item.prop] = event.target.checked;
                                                let updatedKonsil = {
                                                    ...this.state.konsil,
                                                    befund: befund
                                                }
                                                this.setState({
                                                    konsil: updatedKonsil
                                                }, this.updateKonsil);
                                            }}
                                        />}
                                        label={item.label}
                                    />
                                )}
                            </FormGroup>
                            <TextField
                                id="veraenderung-malignitaet-textarea"
                                placeholder="Änderungen am Aussehen"
                                multiline
                                variant="filled"
                                value={this.state.konsil.befund.aenderungAussehen || ""}
                                onChange={(event) => {
                                    const befund: Befund = this.state.konsil.befund || new Befund();
                                    befund.aenderungAussehen = event.target.value;
                                    let updatedKonsil = {
                                        ...this.state.konsil,
                                        befund: befund
                                    }
                                    this.setState({
                                        konsil: updatedKonsil
                                    });
                                }}
                                onFocus = {() => this.setState({previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))})}
                                onBlur={this.updateKonsil}
                            />
                        </FormControl>

                        <Divider />
                        <FormControl className="beschwerden" component="fieldset" required
                            error={anamneseError.has("konsil.beschwerde")}>
                            <FormLabel className="bold-label" component="legend">Beschwerden</FormLabel>

                            <FormGroup row className="">
                                {Object.keys(BeschwerdeType).map((key, index) =>
                                    <FormControlLabel
                                        key={key}
                                        control = {
                                            <Checkbox
                                                name={key}
                                                checked={this.state.beschwerden[index]}
                                                onChange={(event) => {
                                                    this.updateBeschwerden(event.target.checked, index);
                                                    this.setState({keineBeschwerden: false});
                                                }}
                                            />
                                        }
                                        label={BeschwerdeType[key]}
                                    />
                                )}
                                    <FormControlLabel
                                        key={"keine beschwerden"}
                                        control = {
                                            <Checkbox
                                                name = "keine beschwerden"
                                                checked={this.state.keineBeschwerden ? this.state.keineBeschwerden : false}
                                                onChange={this.handleKeineBeschwerdenCheckbox}
                                            />
                                        }
                                        label = "Keine Beschwerden"
                                    />
                            </FormGroup>
                            <TextField
                                className = {this.state.keineBeschwerden ? 'inactive' : ""}
                                id="beschwerden-textarea"
                                placeholder="Sonstige Anmerkungen"
                                multiline
                                variant="filled"
                                value={this.state.konsil.anamnese?.beschwerdeFreitext || ""}
                                onClick = {() => this.setState({keineBeschwerden: false})}
                                onChange={(event) => {
                                    const anamnese = this.state.konsil.anamnese || new Anamnese();
                                    anamnese.beschwerdeFreitext = event.target.value;
                                    anamnese.noComplaint = false;
                                    let updatedKonsil = { ...this.state.konsil, anamnese: anamnese }
                                    this.setState({ konsil: updatedKonsil });
                                }}
                                onFocus = {() => this.setState({previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))})}
                                onBlur={this.updateKonsil}
                            />
                        </FormControl>
                        <FormControl
                            required
                            error={anamneseError.has("konsil.beschwerdeDauer")}
                            className = {this.state.keineBeschwerden ? 'inactive beschwerdedauer' : "beschwerdedauer"}
                            component="fieldset"
                        >
                            <FormLabel component="legend">Beschwerdedauer</FormLabel>
                            <Box className="flex-row">
                                <ToggleButtonGroup
                                    color="primary"
                                    className="beschwerde-selection pad-top pad-right"
                                    aria-label="outlined primary button group"
                                    exclusive
                                    onChange={(event, value) => {
                                        this.setState({previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))});
                                        const anamnese = this.state.konsil.anamnese || new Anamnese();
                                        if (value === "D90") {
                                            anamnese.beschwerdeDauer = "D90";
                                            anamnese.beschwerdeSonstigeDauer = this.saveBeschwerdeSonstigeDauer;
                                            anamnese.noComplaint = false;
                                        } else {
                                            this.saveBeschwerdeSonstigeDauer = anamnese.beschwerdeSonstigeDauer;
                                            anamnese.beschwerdeDauer = value as "D10" | "D20" | "D30";
                                            anamnese.beschwerdeSonstigeDauer = "";
                                            anamnese.noComplaint = false;
                                        }
                                        let updatedKonsil = { ...this.state.konsil, anamnese: anamnese };
                                        this.setState(
                                            {konsil: updatedKonsil},
                                            this.updateKonsil
                                        );
                                    }}
                                >
                                    {Object.keys(BeschwerdeDauer).filter(key => key !== "D90").map((key) =>
                                        <Tooltip key={key} title={BeschwerdeDauer[key]} placement="top">
                                            <ToggleButton
                                                value={key}
                                                className={(this.state.konsil.anamnese.beschwerdeDauer === key ? 'active' : '')}
                                            >
                                                {BeschwerdeDauerAbbrv[key]}
                                            </ToggleButton>
                                        </Tooltip>
                                    )}
                                    <ToggleButton
                                        value="D90"
                                        className={(this.state.konsil.anamnese.beschwerdeDauer === "D90" ? 'active' : '')}
                                    >
                                        sonstige
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                <TextField
                                    error={anamneseError.has("konsil.beschwerdeDauer")}
                                    id="beschwerdedauer-textarea"
                                    className={"beschwerdedauer-textarea pad-top " + (this.state.konsil.anamnese.beschwerdeDauer === "D90" ? '' : 'inactive')}
                                    placeholder="Sonstige Dauer"
                                    multiline
                                    variant="filled"
                                    disabled={this.state.konsil.anamnese.beschwerdeDauer !== "D90"}
                                    onClick={() => {
                                        this.setState({previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))});
                                        this.setState({keineBeschwerden: false});
                                        if (this.state.konsil.anamnese.beschwerdeDauer !== "D90") {
                                            const anamnese: Anamnese = this.state.konsil.anamnese || new Anamnese();
                                            anamnese.beschwerdeDauer = "D90";
                                            anamnese.beschwerdeSonstigeDauer = this.saveBeschwerdeSonstigeDauer;
                                            this.setState({
                                                konsil: {
                                                    ...this.state.konsil,
                                                    anamnese: anamnese
                                                }
                                            }, this.updateKonsil)
                                        }
                                    }}
                                    value={this.state.konsil.anamnese.beschwerdeSonstigeDauer || ""}
                                    onChange={(event) => {
                                        this.saveBeschwerdeSonstigeDauer = event.target.value;
                                        this.setState({
                                            konsil: {
                                                ...this.state.konsil,
                                                anamnese: {
                                                    ...this.state.konsil.anamnese,
                                                    beschwerdeSonstigeDauer: event.target.value,
                                                    noComplaint: false,
                                                }
                                            }
                                        });
                                    }}
                                    onBlur={this.updateKonsil} />
                            </Box>

                        </FormControl>
                        <FormGroup className = {this.state.keineBeschwerden ? 'inactive beschwerdeverlauf' : "beschwerdeverlauf"}>
                            <FormLabel
                                className="full-span"
                                component="legend"
                                required
                                error={anamneseError.has("konsil.beschwerdeVerlauf")}
                            >
                                Beschwerdeverlauf
                            </FormLabel>
                            <FormControlLabel
                                className="verlauf-auslassen justify-start"
                                control={
                                    <Checkbox
                                        checked={this.state.konsil.anamnese.verlaufUnveraendert}
                                        onChange={(event) => {
                                            this.setState({previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))});
                                            const anamnese = this.state.konsil.anamnese;
                                            if (event.target.checked) {
                                                this.saveVerlauf = { ...anamnese }
                                                anamnese.verlaufUnveraendert = true;
                                                anamnese.verlaufRezidivierend = false;
                                                anamnese.verlaufZunehmend = false;
                                                anamnese.veraenderungAuessereFaktoren = false;
                                                anamnese.veraenderungAuessereFaktorenFreitext = "";
                                                anamnese.noComplaint = false;
                                            } else {
                                                anamnese.verlaufUnveraendert = false;
                                                anamnese.verlaufRezidivierend = this.saveVerlauf.verlaufRezidivierend;
                                                anamnese.verlaufZunehmend = this.saveVerlauf.verlaufZunehmend;
                                                anamnese.veraenderungAuessereFaktoren = this.saveVerlauf.veraenderungAuessereFaktoren;
                                                anamnese.veraenderungAuessereFaktorenFreitext = this.saveVerlauf.veraenderungAuessereFaktorenFreitext;
                                            }
                                            this.setState({
                                                konsil: {
                                                    ...this.state.konsil,
                                                    anamnese: anamnese
                                                }
                                            }, this.updateKonsil);
                                        }}
                                        name="verlauf-rezidivierend"
                                    />
                                }
                                label="Unverändert"
                            />
                            {[{ prop: "verlaufRezidivierend", label: "Rezidivierend" }, {
                                prop: "verlaufZunehmend",
                                label: "Zunehmend"
                            }]
                                .map((item) =>
                                    <FormControlLabel
                                        key={item.prop}
                                        className="verlauf-rezidivierend justify-start"
                                        control={
                                            <Checkbox
                                                checked={this.state.konsil.anamnese[item.prop]}
                                                onChange={(event) => {
                                                    this.setState({previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))});
                                                    const anamnese = this.state.konsil.anamnese;
                                                    if (event.target.checked) {
                                                        anamnese[item.prop] = true;
                                                        anamnese.verlaufUnveraendert = false;
                                                        anamnese.noComplaint = false;
                                                    } else {
                                                        anamnese[item.prop] = false
                                                    }
                                                    let updatedKonsil = { ...this.state.konsil, anamnese: anamnese }
                                                    this.setState({ konsil: updatedKonsil }, this.updateKonsil);
                                                }}
                                                name={item.prop}
                                            />
                                        }
                                        label={item.label}
                                    />
                                )}


                            <FormControlLabel
                                className="externe-faktoren-check"
                                control={
                                    <Checkbox
                                        className="justify-start"
                                        checked={this.state.konsil.anamnese.veraenderungAuessereFaktoren}
                                        onChange={(event) => {
                                            this.setState({previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))});
                                            const anamnese: Anamnese = this.state.konsil.anamnese || new Anamnese();
                                            if (event.target.checked) {
                                                anamnese.veraenderungAuessereFaktorenFreitext = this.saveVeraenderungAuessereFaktorenFreitext;
                                                anamnese.veraenderungAuessereFaktoren = true;
                                                anamnese.verlaufUnveraendert = false;
                                                anamnese.noComplaint = false;
                                            } else {
                                                this.saveVeraenderungAuessereFaktorenFreitext = anamnese.veraenderungAuessereFaktorenFreitext;
                                                anamnese.veraenderungAuessereFaktorenFreitext = "";
                                                anamnese.veraenderungAuessereFaktoren = false;
                                            }
                                            this.setState({
                                                konsil: {
                                                    ...this.state.konsil,
                                                    anamnese: anamnese
                                                }
                                            }, this.updateKonsil)
                                        }}
                                        name="verlauf-rezidivierend"
                                    />
                                }
                                label="Veränderung durch externe Faktoren"
                            />
                            <FormControl
                                className={"externe-faktoren full-span " + (!this.state.konsil.anamnese.veraenderungAuessereFaktoren ? 'inactive' : '')}
                                component="fieldset"
                            >
                                <TextField
                                    id="externe-faktoren-textarea"
                                    className="full-span pad-top"
                                    placeholder="Faktoren: z.B. Sonne, Medikamente, Nahrungsmittel"
                                    multiline
                                    variant="filled"
                                    maxRows={2}
                                    disabled={!this.state.konsil.anamnese.veraenderungAuessereFaktoren}
                                    onClick={() => {
                                        this.setState({previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))});
                                        this.setState({keineBeschwerden: false});
                                        if (!this.state.konsil.anamnese.veraenderungAuessereFaktoren) {
                                            const anamnese: Anamnese = this.state.konsil.anamnese || new Anamnese();
                                            anamnese.veraenderungAuessereFaktorenFreitext = this.saveVeraenderungAuessereFaktorenFreitext;
                                            anamnese.veraenderungAuessereFaktoren = true;
                                            anamnese.verlaufUnveraendert = false;
                                            anamnese.noComplaint = false;
                                            this.setState({
                                                konsil: {
                                                    ...this.state.konsil,
                                                    anamnese: anamnese
                                                }
                                            }, this.updateKonsil)
                                        }
                                    }}
                                    value={this.state.konsil.anamnese?.veraenderungAuessereFaktorenFreitext || ""}
                                    onChange={(event) => {
                                        const anamnese = this.state.konsil.anamnese || new Anamnese();
                                        anamnese.veraenderungAuessereFaktorenFreitext = event.target.value;
                                        let updatedKonsil = { ...this.state.konsil, anamnese: anamnese }
                                        this.setState({ konsil: updatedKonsil });
                                    }}
                                    onBlur={this.updateKonsil}
                                    error={anamneseError.has("konsil.veraenderungAuessereFaktorenFreitext")}
                                />
                            </FormControl>
                        </FormGroup>
                        <Divider />
                        <FormGroup className="allergien">
                            <FormControl
                                className="full-span"
                                component="fieldset"
                            >
                                <FormLabel className="bold-label float-left" component="legend">Allergien</FormLabel>
                            </FormControl>
                            {[{ prop: "sofortReaktionen", label: "Sofortreaktionen" }, {
                                prop: "spaetReaktionen",
                                label: "Spätreaktionen"
                            }].map((item) =>
                                <FormControl className="sofortreaktionen" component="fieldset" key={item.prop}>
                                    <FormLabel component="legend">{item.label}</FormLabel>
                                    <TextField
                                        id={item.prop + "-textarea"}
                                        className="pad-top"
                                        placeholder="Keine Angabe"
                                        multiline
                                        variant="filled"
                                        minRows = "2"
                                        maxRows={2}
                                        value={(this.state.konsil.konsilPatient.allergien) ? this.state.konsil.konsilPatient.allergien[item.prop] : ""}
                                        onChange={(event) => {
                                            const allergien = this.state.konsil.konsilPatient?.allergien || new Allergien();
                                            allergien[item.prop] = event.target.value;
                                            let updatedKonsil = {
                                                ...this.state.konsil,
                                                konsilPatient: {
                                                    ...this.state.konsil.konsilPatient,
                                                    allergien: allergien
                                                }
                                            }
                                            this.setState({
                                                konsil: updatedKonsil
                                            });
                                        }}
                                        onFocus = {() => this.setState({previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))})}
                                        onBlur={this.updateKonsil}
                                    />
                                </FormControl>
                            )}
                        </FormGroup>
                        <Divider />

                        <FormGroup className="allergien">
                            <FormControl
                                className="full-span familie"
                                component="fieldset" required
                                error={anamneseError.has("konsil.familienanamnese")}
                            >
                                <FormLabel
                                    className="bold-label"
                                    component="legend"
                                >
                                    Familien- und Eigenanamnese
                                </FormLabel>
                            </FormControl>
                            <Box className="familien-erkrankungen">
                                <FormControlLabel
                                    className="familie-auslassen"
                                    control={
                                        <Checkbox
                                            checked={this.state.konsil.konsilPatient?.familienanamnese?.keineErkrankungen ? this.state.konsil.konsilPatient?.familienanamnese?.keineErkrankungen : false}
                                            onChange={(event) => {
                                                this.setState({previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))});
                                                let familien_anamnese = this.state.konsil.konsilPatient?.familienanamnese
                                                if (event.target.checked) {

                                                    this.saveFamilienAngaben = { ...familien_anamnese || new FamilienAnamnese() }
                                                    let familienAngabenKeineErkrankungen = new FamilienAnamnese();
                                                    familienAngabenKeineErkrankungen.keineErkrankungen = true;
                                                    this.setState({
                                                        konsil: {
                                                            ...this.state.konsil,
                                                            konsilPatient: {
                                                                ...this.state.konsil.konsilPatient,
                                                                familienanamnese: familienAngabenKeineErkrankungen
                                                            }
                                                        }
                                                    }, this.updateKonsil);
                                                } else {
                                                    if (familien_anamnese) {
                                                        familien_anamnese = this.saveFamilienAngaben
                                                    }
                                                    this.setState({
                                                        konsil: {
                                                            ...this.state.konsil,
                                                            konsilPatient: {
                                                                ...this.state.konsil.konsilPatient,
                                                                familienanamnese: familien_anamnese
                                                            }
                                                        }
                                                    }, this.updateKonsil);
                                                }
                                            }}
                                            name="verlauf-rezidivierend"
                                        />
                                    }
                                    label="keine bekannten Familienerkrankungen"
                                />
                                {[{
                                    prop: "hautmelanom",
                                    label: "Maligne Hauttumoren",
                                    className: "krebserkrankung"
                                }, { prop: "psoriasis", label: "Psoriasis", className: "psoriasis" }]
                                    .map((item) =>
                                        <FormControl key={item.prop} className={item.className}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={(this.state.konsil.konsilPatient?.familienanamnese) ? this.state.konsil.konsilPatient?.familienanamnese[item.prop] : false}
                                                        onChange={(event) => {
                                                            this.setState({previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))});
                                                            const anamnese = this.state.konsil.konsilPatient?.familienanamnese || new FamilienAnamnese();
                                                            if (event.target.checked && anamnese) {
                                                                anamnese[item.prop] = true;
                                                                anamnese.keineErkrankungen = false
                                                            } else if (!event.target.checked && anamnese) {
                                                                anamnese[item.prop] = false
                                                            }
                                                            let updatedKonsil = {
                                                                ...this.state.konsil,
                                                                konsilPatient: {
                                                                    ...this.state.konsil.konsilPatient,
                                                                    familienanamnese: anamnese
                                                                }
                                                            }
                                                            this.setState({
                                                                konsil: updatedKonsil
                                                            }, this.updateKonsil);
                                                        }}
                                                        name="verlauf-rezidivierend"
                                                    />
                                                }
                                                label={item.label}
                                            />
                                        </FormControl>
                                    )}
                            </Box>

                            <FormControl className="atopie" component="fieldset">
                                {Object.keys(Atopie).map((key) => {
                                    const property: string = (key === "NEURODERMITIS") ? "neurodermites" : key.toLocaleLowerCase();
                                    return (
                                        <FormControlLabel
                                            key={key}
                                            control={<Checkbox name={key}
                                                checked={(this.state.konsil.konsilPatient.familienanamnese) ? this.state.konsil.konsilPatient.familienanamnese[property] : false}
                                                onChange={(event) => {
                                                    this.setState({previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))});
                                                    const familien_anamnese = this.state.konsil.konsilPatient?.familienanamnese || new FamilienAnamnese();
                                                    familien_anamnese[property] = event.target.checked;
                                                    familien_anamnese.keineErkrankungen = false
                                                    let updatedKonsil = {
                                                        ...this.state.konsil,
                                                        konsilPatient: {
                                                            ...this.state.konsil.konsilPatient,
                                                            familienanamnese: familien_anamnese
                                                        }
                                                    }
                                                    this.setState({
                                                        konsil: updatedKonsil
                                                    }, this.updateKonsil);
                                                }} />}
                                            label={Atopie[key]}
                                        />
                                    )
                                })}
                            </FormControl>
                            {/* </Box> */}
                        </FormGroup>
                        <FormControl 
                            className="weitere-erkrankungen"
                            component="fieldset"
                            required
                        >
                            <FormLabel className="label float-left" component="legend">
                                Weitere relevante Familien- oder Eigenerkrankungen
                            </FormLabel>

                            <TextField
                                id="weitere-angaben-textarea"
                                className="pad-top"
                                multiline
                                variant="filled"
                                placeholder="Keine Angabe"
                                value={this.state.konsil.konsilPatient.familienanamnese?.weitereFamilienerkrankungen || ""}
                                onChange={(event) => {
                                    const familien_anamnese = this.state.konsil.konsilPatient?.familienanamnese || new FamilienAnamnese();
                                    familien_anamnese.weitereFamilienerkrankungen = event.target.value;
                                    familien_anamnese.keineErkrankungen = false;
                                    let updatedKonsil = {
                                        ...this.state.konsil,
                                        konsilPatient: {
                                            ...this.state.konsil.konsilPatient,
                                            familienanamnese: familien_anamnese
                                        }
                                    }
                                    this.setState({
                                        konsil: updatedKonsil
                                    });
                                }}
                                onFocus = {() => this.setState({previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))})}
                                onBlur={this.updateKonsil}
                            />
                        </FormControl>

                    </form>
                    <Dialog
                        open={this.state.isShowAttachment}
                        maxWidth='md'
                        onClose={this.onAttachmentClose}
                    >
                        <DialogTitle
                            id="customized-dialog-title"
                        >
                            <Button className="slim mobile-close"
                                onClick={() =>
                                    this.setState({
                                        isShowAttachment: false,
                                    })}
                            >
                                <Close />
                            </Button>
                        </DialogTitle>
                        <DialogContent>
                            <Box className="pdf-preview-container">
                                {(this.state.pdfData !== undefined && this.state.pdfData !== "") &&
                                    <>
                                        <PdfInline
                                            pdfData={this.state.pdfData}
                                        />
                                    </>
                                }
                                {(this.state.pdfData === undefined) &&
                                    <Box className="pdf-load">
                                        <Typography>
                                            PDF in Vorbereitung...
                                    </Typography>
                                        <CircularProgress size={50} />
                                    </Box>

                                }
                                {(this.state.pdfData === "") &&
                                    <span className="pdf-preview-load failure"> Pdf-Erstellung fehlgeschlagen</span>
                                }
                            </Box>
                        </DialogContent>
                    </Dialog>
                    {!this.props.isDesktop &&
                        <Button className="slim btn-anamnese-attachment" variant="contained" color="primary"
                            onClick={() => this.setState({
                                showSide: true
                            })}>
                            <AttachFile />
                        </Button>
                    }
                </Box>
                <Box className={"scroll-box-side " + (this.state.showSide ? 'active' : '')}>
                    <Box className="side-view">
                        {!this.props.isDesktop &&
                            <Button className="slim mobile-close" onClick={() => this.setState({
                                showSide: false
                            })}>
                                <Close />
                            </Button>
                        }
                        <Box className="attachment-header">
                            <Typography className="txt-header" variant="subtitle1" color="inherit">
                                Anhänge
                            </Typography>
                            <Button variant="contained" className="slim" color="primary"
                                aria-controls="upload attachments"
                                onClick={() => this.input?.current?.click()}>
                                <Add />
                            </Button>
                        </Box>
                        <Typography variant="body2" color="inherit">
                            {"Sie können maximal drei Anhänge (pdf, jpg, png) hinzufügen. Bilder müssen mindestens eine Auflösung von 2000\u00d71500 px haben."}
                        </Typography>
                        {Object.keys(this.props.attachments).map((attachment_id) => {
                            const attachmentState: AttachmentState = this.props.attachments[attachment_id];
                            return (!attachmentState.loading &&
                                <Box className="attachment_file" key={"attachment_" + attachment_id}>
                                    <Paper className="attachment pad"
                                        onClick={() => {                                         
                                            if (attachmentState.originalFilename.includes(".pdf") || attachmentState.originalFilename.includes("Altes Konsil"))
                                                this.getPdfData(attachment_id);
                                            else {                                               
                                                displayAttachment(attachment_id,
                                                    this.props.attachments,
                                                    this.props.downloadAttachment,
                                                    this.props.dispatch_failure,
                                                    this.props.dispatch_setSuccessMessage);
                                            }
                                        }}
                                    >
                                        <InsertDriveFile />
                                        <Typography variant="body1" color="inherit">
                                            {attachmentState.originalFilename}
                                        </Typography>
                                        <IconButton className="delete-attachment" size="small"
                                            aria-label="delete-attachment"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                this.props.deleteAttachment(attachment_id);
                                            }}>
                                            <Delete />
                                        </IconButton>
                                    </Paper>
                                </Box>);
                        })}
                        {this.props.oldKonsileAsAttachments.length > 0 &&
                            <Box className="attachment-header" style={{ marginTop: "64px" }}>
                                <Typography className="txt-header" variant="subtitle1" color="inherit">
                                    Alte Konsile anhängen
                            </Typography>
                            </Box>
                        }
                        {this.props.oldKonsileAsAttachments.length > 0 && this.props.oldKonsileAsAttachments.map((konsilMetaInfo) => {
                            const filteredKonsile = Object.keys(this.props.attachments).filter((attachment_id) => (this.props.attachments[attachment_id].originalFilename.substring(24) === konsilMetaInfo.id))
                            return (
                                <OldKonsil
                                    key={konsilMetaInfo.id}
                                    selected={(filteredKonsile.length > 0) ? filteredKonsile[0] : undefined}
                                    konsilMetaInfo={konsilMetaInfo}
                                    selectAsAttachment={(event, attachmentKonsilId) => {
                                        this.props.createPdf(attachmentKonsilId, this.props.uploadAttachment, this.props.currentKonsil?.id || "Unknown konsilID");
                                    }}
                                    deleteAttachment={this.props.deleteAttachment}
                                    dispatch_successMessage = {this.props.dispatch_setSuccessMessage}
                                />
                            )
                        })}
                    </Box>
                </Box>
            </Box>
        );
    }
}

export const EditAnamneseContainer = connector(withFileUpload(withPdfCreation(withLoadKonsileAsAttachments(EditAmanese))));
export default EditAnamneseContainer;
