import React from 'react';
import {connect, ConnectedProps} from "react-redux";

// material components
import {Box, Button, IconButton, Paper, Typography,} from '@material-ui/core/';

// Icons
import {Check, Close, Warning} from '@material-ui/icons';
import {RootState} from '../../redux/reducers';
import SimpleModalContainer from '../elements/simple_modal';
import { Failure } from 'telescan-core';


interface IFailureState {
    anchorElPopOver: HTMLInputElement | null,
}

interface IFailureProps {
    isOpen: boolean,
    closeFailurePopup: () => void,
    toggleReceiverModal: () => void,
    error: Failure,
    konsilID: string
}

export class FailurePopup extends React.Component<IFailureProps, IFailureState> {
    constructor(props: IFailureProps) {
        super(props);
        this.state = {
            anchorElPopOver: null,
        };

        this.handleOpenPopOver = this.handleOpenPopOver.bind(this);
        this.handleClosePopOver = this.handleClosePopOver.bind(this);
    }

    handleOpenPopOver(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
        this.setState({
            anchorElPopOver: event.target as HTMLInputElement
        });
    };

    handleClosePopOver() {
        this.setState({
            anchorElPopOver: null
        });
    }

    render() {
        const failure: Failure = this.props.error ? this.props.error: new Failure();
        return (
            <SimpleModalContainer isOpen={this.props.isOpen}
                                  onClose={this.props.closeFailurePopup} 
            >
                <Paper className="modal-wrapper">
                    <Box className="modal-content konsil-submit-feedback">
                        <Warning className="konsil-submit-alert"/>
                        <Box>
                            {/*
                            <Typography variant="body1" color="inherit">
                                Versenden des Konsils mit der
                                ID {this.props.konsilID} fehlgeschlagen.
                                <Tooltip title="Error informationen" placement="top">
                                    <IconButton onClick={this.handleOpenPopOver} aria-label="info"
                                                className="konsil-submit-feedback-info" size="small">
                                        <Info fontSize="inherit"/>
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                             */}
                           
                            <Typography variant="body1" color="inherit">
                                {`${failure.code}: ${failure.error}`}
                            </Typography>

                            <Typography variant="body1" color="inherit">
                                {failure.messages && failure.messages.length>0 &&
                                    failure.messages.map((subitem: string) => {
                                        return (
                                            <li key = {subitem}>
                                                {subitem}
                                            </li>
                                        )
                                    })}
                            </Typography>
                        </Box>

                        <Button className="send-again" variant="contained" color="primary" onClick={() => {
                            this.props.toggleReceiverModal();
                            this.props.closeFailurePopup();
                        }}>
                            Erneut Versenden
                        </Button>
                        <IconButton onClick={this.props.closeFailurePopup} aria-label="close"
                                    className="konsil-submit-feedback-close" size="small">
                            <Close fontSize="inherit"/>
                        </IconButton>
                    </Box>
                </Paper>
            </SimpleModalContainer>
        )
    }
}

interface IStartSendState {
    anchorElPopOver: HTMLInputElement | null,
}

interface IStartSendProps {
    open: boolean;
    closeStartSendPopup: () => void;
}

export class StartSendPopup extends React.Component<IStartSendProps, IStartSendState> {
    constructor(props: IStartSendProps) {
        super(props);
        this.state = {
            anchorElPopOver: null,
        };
    }

    render() {
        return (
            <SimpleModalContainer isOpen={this.props.open}
                                  onClose={this.props.closeStartSendPopup}
            >
                <Paper className="modal-wrapper">
                    <Box className="modal-content konsil-submit-feedback">
                        <Box>
                            <Typography variant="body1" color="inherit">Telekonsil wird versendet.
                            </Typography>
                            <br/>
                            <Typography variant="caption" color="inherit">Dies kann, je nach Verbindungsgeschwindigkeit,
                                mehrere Minuten dauern.
                                Sie können in der Zwischenzeit weiter arbeiten.
                            </Typography>
                        </Box>
                        <Button variant="contained" color="primary" onClick={this.props.closeStartSendPopup}>
                            OK
                        </Button>
                    </Box>
                </Paper>
            </SimpleModalContainer>
        )
    }
}


interface IServerAwayState {
    isClosedServerAway: boolean;
}

const mapStateToProps = (state: RootState) => ({
    hpmUpdateSteps: state.update.hpmUpdateSteps,
    teleScanUpdateSteps: state.update.teleScanUpdateSteps,
})

const mapDispatchToProps = {}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>


class ServerAwayPopup extends React.Component<TPropsFromRedux, IServerAwayState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            isClosedServerAway: false,
        };

        this.closeServerAwayPopup = this.closeServerAwayPopup.bind(this);
    }

    closeServerAwayPopup() {
        this.setState({
            isClosedServerAway: true
        })
    }

    render() {
        return (
            <SimpleModalContainer
                isOpen={this.state.isClosedServerAway && ((this.props.hpmUpdateSteps.find((item) => item.step === "Durchführung des Updates")?.status === "RUNNING") ? true : false)}
                onClose={this.closeServerAwayPopup}
            >
                <Paper className="modal-wrapper">
                    <Box className="modal-content konsil-submit-feedback">
                        {((this.props.hpmUpdateSteps.find((item) => item.step === "Durchführung des Updates")?.status === "RUNNING") ? true : false) ?
                            <>
                                <Warning className="konsil-submit-alert"/>
                                <Typography variant="body1" color="inherit">
                                    Der Server ist für einige Minuten nicht erreichbar. Ein Weiterarbeiten ist derzeit
                                    nicht möglich!
                                </Typography>
                            </>
                            :
                            <Box className="modal-content konsil-submit-feedback">
                                <Check className="konsil-submit-done"/>
                                <Typography variant="subtitle1" color="inherit">
                                    Der Server ist jetzt wieder verfügbar. Sie können wie gewohnt weiterarbeiten.
                                </Typography>
                            </Box>
                        }
                        <IconButton onClick={this.closeServerAwayPopup} aria-label="close"
                                    className="konsil-submit-feedback-close" size="small">
                            <Close fontSize="inherit"/>
                        </IconButton>
                    </Box>
                </Paper>
            </SimpleModalContainer>
        )
    }
}

export const ServerAwayPopupComponent = connector(ServerAwayPopup);


/*
interface ISuccessProps {
    open: boolean,
    closeSuccessPopup: Function,
}

export class SuccessPopup extends React.Component<ISuccessProps> {

    render() {
        return (
            <Modal
                open={this.props.open}
                onClose={() => this.props.closeSuccessPopup()}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className="modal"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 1000,
                }}
            >
                <Fade in={this.props.open}>
                    <Paper className="modal-wrapper">
                        <Box className="modal-content konsil-submit-feedback">
                            <Check className="konsil-submit-done"/>
                            <Typography variant="subtitle1" color="inherit">Versendet</Typography>
                        </Box>
                    </Paper>
                </Fade>
            </Modal>
        )
    }
} */
