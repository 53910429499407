import React from 'react';
import { Notification, NotificationActionType, NotificationType } from "telescan-core";
import { Box, IconButton, Popover, Typography } from '@material-ui/core';
import { HighlightOff, NavigateBefore, NavigateNext } from '@material-ui/icons';

interface IProps {
    isCriticalNotifications: boolean;
    isWarningNotifications: boolean;
    isNormalNotifications: boolean;
    normalNotifications: Notification[];
    dispatch_setIsNormalNotification: (isNormalNotification: boolean) => void;
    dispatch_sendNotification: (notificationId: string, notificationAction: NotificationActionType) => void;
    dispatch_removeNotification: (id: string) => void;
}

interface IState {
    notificationNumber: number;
    currentNotification: Notification;
}

export class NotificationPopover extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            notificationNumber: 0,
            currentNotification: new Notification(),
        }
    }



    componentDidMount() {
        this.setState({currentNotification: this.props.normalNotifications[this.state.notificationNumber]});
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (prevProps.normalNotifications !== this.props.normalNotifications) {
            this.setState({currentNotification: this.props.normalNotifications[this.state.notificationNumber]});
        }
    }

    onNextNotificationClick = () => {
        this.setState(prevState =>
            {
                return {
                    notificationNumber: prevState.notificationNumber + 1
                }
            },
            () => this.setState(
                {currentNotification: this.props.normalNotifications[this.state.notificationNumber]},
                () => !this.state.currentNotification.isNotificationDisplayed && this.props.dispatch_sendNotification(this.state.currentNotification.id || "", NotificationActionType.DISPLAYED)
            )
        )
    }

    onPreviousNotificationClick = () => {
        this.setState(prevState =>
            {
                return {
                    notificationNumber: prevState.notificationNumber - 1
                }
            },
            () => this.setState({currentNotification: this.props.normalNotifications[this.state.notificationNumber]})
        )
    }

    onPopoverOpened = () => {
        this.state.currentNotification && this.props.dispatch_sendNotification(this.state.currentNotification.id || "", NotificationActionType.DISPLAYED)
    }

    onClose = () => {
        this.props.normalNotifications.forEach((item: Notification) => {
            if (item.isNotificationDisplayed && item.id)
                this.props.dispatch_removeNotification(item.id);
        })
        this.props.dispatch_setIsNormalNotification(false);
    }

    render () {
        let isExist: boolean;
        if (this.state.currentNotification) isExist = true;
        else isExist = false;
        return (
            <Popover
                open = {this.props.isNormalNotifications && !this.props.isCriticalNotifications && !this.props.isWarningNotifications}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                TransitionProps = {{onEntered: this.onPopoverOpened}}
                className = 'pad-l'
            >
                <Box className = 'pad-l'>
                    <Box className = 'flex-row-center justify-content-space-between'>
                        {isExist &&
                            (this.state.currentNotification.notificationVersion && parseInt(this.state.currentNotification.notificationVersion) > 1 ?
                                <Typography variant = 'h5'>
                                    {`${this.state.currentNotification.notificationType && NotificationType[this.state.currentNotification.notificationType]} (Aktualisierung)`}
                                </Typography> :
                                <Typography variant = 'h5'>
                                    {this.state.currentNotification.notificationType && NotificationType[this.state.currentNotification.notificationType]}
                                </Typography>)
                        }
                        <IconButton onClick = {this.onClose}>
                            <HighlightOff/>
                        </IconButton>
                    </Box>

                    <Typography>{this.state.currentNotification && this.state.currentNotification.publisher && this.state.currentNotification.publisher}</Typography>
                    <Typography>{this.state.currentNotification && this.state.currentNotification.affectedTime && this.state.currentNotification.affectedTime}</Typography>
                    <Typography>{this.state.currentNotification && this.state.currentNotification.affectedVersionServerAsString && this.state.currentNotification.affectedVersionServerAsString}</Typography>
                    <iframe
                        className = "notification-iframe notification-popover"
                        title = "notification-popover"
                        srcDoc= {this.state.currentNotification && this.state.currentNotification.htmlAsString}
                        sandbox = "allow-scripts"
                        frameBorder = '0'/>
                    <Box className = 'flex-row-center justify-content-center'>
                        <IconButton
                            aria-label="previous"
                            className="img-prev"
                            disabled={this.state.notificationNumber <= 0}
                            onClick={this.onPreviousNotificationClick}
                            size = 'small'
                        >
                            <NavigateBefore fontSize="large" />
                        </IconButton>
                        <Typography>{`${this.state.notificationNumber + 1}/${this.props.normalNotifications.length}`}</Typography>
                        <IconButton
                            aria-label="previous"
                            className="img-prev"
                            disabled={this.state.notificationNumber >= this.props.normalNotifications.length - 1}
                            onClick={this.onNextNotificationClick}
                            size = 'small'
                        >
                            <NavigateNext fontSize="large" />
                        </IconButton>
                    </Box>
                </Box>
        </Popover>
        )
    }
}
