import React from "react";
import { Box, Paper, Typography } from "@material-ui/core";




export class Dataprotection extends React.Component {
    render() {
        return(
            <Box className="legal-content">
                <Paper className="dataprotection-container">
                    <Typography variant="h3" component="h1" gutterBottom>
                        Datenschutzhinweise TeleScan
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                    Wir, die zollsoft GmbH (im Weiteren auch nur „zollsoft“ oder der „Verantwortliche“ genannt) möchten Sie nachfolgend über die Verarbeitung personenbezogener Daten, im Sinne des Art. 4 Nr. 1 DSGVO, im Rahmen der Nutzung unseres Dienstes namens <strong>TeleScan und zugehöriger Webseite</strong> informieren. Sämtliche personenbezogenen Bezeichnungen sind geschlechtsneutral zu verstehen. Zollsoft versichert die gesetzlichen Bestimmungen des europäischen Datenschutzes (DSGVO) zu beachten und damit auch die Vertraulichkeit und Integrität der mit den verwendeten IT- Systemen verarbeiteten personenbezogenen Daten zu gewährleisten.
                    </Typography>
                    <Typography variant="h4" component="h2" gutterBottom>
                        VERANTWORTLICHER
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        zollsoft GmbH<br/>
                        Ernst-Haeckel-Platz 5/6<br/>
                        07745 Jena<br/>
                        Telefon: 03641 269 4162<br/>
                        E-Mail: <a href="mailto:info@zollsoft.de">info@zollsoft.de</a><br/>
                        Website: <a href="https://www.zollsoft.de">https://www.zollsoft.de</a>
                    </Typography>
                    <Typography variant="h4" component="h2" gutterBottom>
                        DATENSCHUTZBEAUFTRAGTER
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Julia Dewindenat<br/>
                        zollsoft GmbH <br/>
                        Ernst-Haeckel-Platz 5/6<br/>
                        07745 Jena <br/>
                        Telefon: +49 3641 - 316 12 04<br/>
                        E-Mail: <a href="mailto:datenschutz@zollsoft.de">datenschutz@zollsoft.de</a>
                    </Typography>

                    <Typography variant="h4" component="h2" gutterBottom>
                        ALLGEMEINES
                    </Typography>
                    <Typography variant="h5" component="h3" gutterBottom>
                        GELTUNGSBEREICH
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                    Diese Datenschutzerklärung gilt für das Produkt TeleScan, welches zur Bearbeitung und Verwaltung von teledermatologischen Konsilen dient. TeleScan wird über einen <strong>TeleScan-Server</strong> in Verbindung mit einem browserbasierten <strong>TeleScan-Client</strong>TeleScan-Client und bei Wunsch auch mit einer <strong>TeleScan-App</strong> von einem mobilen Endgerät aus betrieben. Im Folgenden wird Tele-Scan auch als „Angebot“ oder „Dienst“ bezeichnet. Darüber hinaus gilt die Datenschutzerklärung auch für die zugehörige TeleScan-Internetseite <a href="https://telescan-software.de/">https://telescan-software.de/</a>.
                    </Typography>
                    <Typography variant="h5" component="h3" gutterBottom>
                        ART DES ANGEBOTS
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        TeleScan dient im Rahmen medizinischer Zwecke zur Bearbeitung und Verwaltung von teledermatologischen Konsilen. Dazu wird bei dem TeleScan-Nutzer direkt (in der Praxis, bzw. auf bestehendem Server) zunächst ein TeleScan-Server eingerichtet. Dieser verarbeitet alle notwendigen Daten, die zur Konsilanfrage und dessen Befundung notwendig sind und gibt diese jeweils zur Verifizierung und Übermittlung an das HÄVG-Prüfmodul (HPM). Der browserbasierte TeleScan-Client dient als Eingabeoberfläche zur Formulierung der Konsilanfragen (auf Hausarztseite) und deren Beantwortung (auf Facharztseite). Die Hausärzte können sich zusätzlich noch der TeleScan-App bedienen, deren Schwerpunkt auf der Bildaufnahme liegt. Über die App kann der Nutzer aber auch auf einfache Art und Weise teledermatologische Konsile sichten, erstellen, um Informationen als auch Bilder ergänzen (aufnehmen, senden, sichten und archivieren: nachfolgend zusammengefasst als „Nutzung“) und diese mit dem eigenen TeleScan Server austauschen (nachfolgend „Datenaustausch“). Darüber hinaus betreibt zollsoft eine zugehörige Webseite, die zu Informations- und Marketingzwecken dient.
                    </Typography>

                    <Typography variant="h4" component="h2" gutterBottom>
                        DIE DATENVERARBEITUNG – ART, UMFANG UND ZWECK
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Bei der Verwendung der TeleScan App und Benutzung der Website werden Nutzer- und personenbezogene Daten verarbeitet. Die Art, der Umfang und der Zweck dieser Verarbeitung werden in den weiteren Absätzen erläutert. Die dabei erhobenen Daten werden mit wenigen Ausnahmen, die im Weiteren noch erläutert werden, ausschließlich lokal auf dem jeweiligen Eingabegerät und auf dem Kundeneigenen TeleScan Server gespeichert.
                    </Typography>
                    <Typography variant="h5" component="h3" gutterBottom>
                        DATENVERARBEITUNG AUF DER WEBSEITE
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Wenn Sie unsere Internetseiten besuchen, werden auch personenbezogene Daten verarbeitet. Damit die Seiten in Ihrem Browser dargestellt werden können, muss die IP-Adresse des von Ihnen verwendeten Endgeräts verarbeitet werden. Hinzu kommen weitere Informationen über den Browser Ihres Endgeräts.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Wir sind datenschutzrechtlich verpflichtet, die Vertraulichkeit und Integrität der mit unseren IT- Systemen verarbeiteten personenbezogenen Daten zu gewährleisten.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Für diesen Zweck werden nachfolgende Daten protokolliert:<br/>
                        <ul>
                            <li>IP-Adresse des aufrufenden Rechners (für maximal 7 Tage)</li>
                            <li>Betriebssystem des aufrufenden Rechners</li>
                            <li>Browser-Version des aufrufenden Rechners</li>
                            <li>Name der abgerufenen Datei</li>
                            <li>Datum und Uhrzeit des Abrufs</li>
                            <li>Übertragene Datenmenge</li>
                            <li>Verweisende URL</li>
                        </ul>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Die IP-Adresse wird nach spätestens 7 Tagen von allen Systemen, die im Zusammenhang mit dem Betrieb dieser Internetseiten verwendet werden, gelöscht. Einen Personenbezug können wir aus den verbleibenden Daten dann nicht mehr herstellen.
                        Die Daten werden ferner auch verwendet, um Fehler der Internetseite zu beheben.
                        Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Rechtsgrundlage für diese Datenverarbeitung ist Art. 6 Abs. 1 lit. f) DSGVO. Unser „Interesse“ i.S.d. Art. 6 Abs. 1 lit. f) ist der Betrieb dieser Internetseite, die Umsetzung der Schutzziele der Vertraulichkeit, Integrität und Verfügbarkeit der Daten.
                    </Typography>
                    <Typography variant="h6" component="h4" gutterBottom>
                        Kontaktformular und E-Mail-Kontakt
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                    Wir bieten auf unserer Internetseite ein Kontaktformular an, über das Sie Informationen zu unseren Produkten anfordern oder allgemein Kontakt aufnehmen können. Neben den freiwilligen Angaben und Ihrem
                    <br/>Nachrichteninhalt verlangen wir die Angabe folgender Informationen von Ihnen:
                    <ul>
                            <li>Name</li>
                            <li>E-Mail-Adresse</li>
                        </ul>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Wir benötigen diese Angaben, um Ihre Anfrage zu bearbeiten, Sie korrekt anzusprechen und Ihnen eine Antwort zukommen zu lassen.
                        Alternativ ist eine Kontaktaufnahme über die bereitgestellte E-Mail-Adresse möglich. In diesem Fall werden die mit der E-Mail übermittelten personenbezogenen Daten des Nutzers gespeichert.
                        Anfragen, die über das Kontaktformular unserer Internetseite eingehen, werden per E-Mail übermittelt und in der Regel in unserem CRM-System gespeichert. Das CRM-System wird regelmäßig dahingehend überprüft, ob Daten gelöscht werden können. Sollten Daten im Rahmen einer Kunden- oder Interessentenbeziehung nicht weiter erforderlich sein oder ein entgegenstehendes Interesse des Kunden oder Interessenten überwiegen, werden wir die betreffenden Daten löschen, sofern dem keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Rechtsgrundlage für diese Datenverarbeitung ist Art. 6 Abs. 1 lit. f) DSGVO. Unser „Interesse“ i.S.d. Art. 6 Abs. 1 lit. f) ist der Betrieb dieser Internetseite, die Umsetzung der Schutzziele der Vertraulichkeit, Integrität und Verfügbarkeit der Daten.
                    </Typography>
                    <Typography variant="h6" component="h4" gutterBottom>
                        Cookies
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Auf unseren Internetseiten kommen Cookies zum Einsatz. Cookies sind kleine Textinformationen, die über Ihren Browser in Ihrem Endgerät gespeichert werden. Wir nutzen dabei zum einen Session- Cookies, die unmittelbar nach Beendigung des Besuchs der Internetseiten automatisch von Ihrem Browser gelöscht werden. Im Bereich der Webanalyse setzen wir zum anderen aber auch persistente Cookies ein. Auch von unserer Webseite getätigte Server-Requests wie Bibliotheken und Plugins (insbesondere die Google- und Facebook-Services, auf die unten näher eingegangen wird), erzeugen Cookies.
                        Die Cookies sind erforderlich, um bestimmte Funktionen unserer Internetseiten zu ermöglichen, wie die Unterscheidung, ob ein Besucher zum ersten Mal auf unserer Seite ist, und die Auswertung der Nutzung der Internetseiten und die Zusammenstellung von Reports über Aktivitäten auf den Internetseiten. Auf Grundlage der Nutzung der Internetseiten und des Internets sollen dann weitere verbundene Dienstleistungen erbracht werden. Die Verarbeitung beruht auf dem berechtigten Interesse des Webseitenbetreibers.
                        Sie haben die Möglichkeit, das Setzen von Cookies durch entsprechende Einstellungen in Ihrem Browser zu unterbinden. Wir weisen jedoch darauf hin, dass die Nutzung unserer Internetseiten dann ggf. nur eingeschränkt möglich ist. Durch Cookies werden keine Programme oder sonstige Applikationen auf Ihrem Rechner installiert oder gestartet.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Rechtsgrundlage für diese Datenverarbeitung ist Art. 6 Abs. 1 lit. f) DSGVO. Unser „Interesse“ i.S.d. Art. 6 Abs. 1 lit. f) ist der Betrieb dieser Internetseite, die Umsetzung der Schutzziele der Vertraulichkeit, Integrität und Verfügbarkeit der Daten.
                    </Typography>
                    <Typography variant="h6" component="h4" gutterBottom>
                        Verwendung von Google Analytics
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Auf unseren Internetseiten nutzen wir Google Analytics, einen Webanalysedienst der Google Inc. (folgend: Google). Google Analytics verwendet sog. „Cookies“, also Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Webseite durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Webseite werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Aufgrund der Aktivierung der IP-Anonymisierung auf diesen Webseiten, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Internetseiten wird Google diese Informationen benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports über die Webseitenaktivitäten zusammenzustellen und um weitere mit der Internetnutzung verbundene Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
                        Die Zwecke der Datenverarbeitung liegen in der Auswertung der Nutzung der Internetseiten und in der Zusammenstellung von Reports über Aktivitäten auf den Internetseiten. Auf Grundlage der Nutzung der Internetseiten und des Internets sollen dann weitere verbundene Dienstleistungen erbracht werden. Die Verarbeitung beruht auf dem berechtigten Interesse des Webseitenbetreibers.
                        Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser- Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Internetseiten vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: Browser Add On zur Deaktivierung von Google Analytics.
                        Zusätzlich oder als Alternative zum Browser-Add-On können Sie das Tracking durch Google Analytics auf unseren Seiten unterbinden, indem Sie diesen Link „Google Analytics deaktivieren“ anklicken. Dabei wird ein Opt-Out-Cookie auf Ihrem Gerät installiert. Damit wird die Erfassung durch Google Analytics für diese Internetseiten und für diesen Browser zukünftig verhindert, so lange das Cookie in Ihrem Browser installiert bleibt. Löschen Sie Ihre Cookies, müssen Sie diesen Link erneut klicken.
                    </Typography>
                    <Typography variant="h6" component="h4" gutterBottom>
                        Verwendung Scriptbibliotheken (Google Webfonts)
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf diesen Internetseiten Scriptbibliotheken und Schriftbibliotheken wie z. B. Google Webfonts (<a href="https://fonts.google.com/">fonts.google.com/</a>). Google Webfonts werden zur Vermeidung mehrfachen Ladens in den Cache Ihres Browsers übertragen. Falls der Browser die Google Webfonts nicht unterstützt oder den Zugriff unterbindet, werden Inhalte in einer Standardschrift angezeigt.
                        Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken löst automatisch eine Verbindung zum Betreiber der Bibliothek aus. Dabei ist es theoretisch möglich – aktuell allerdings auch unklar ob und ggf. zu welchen Zwecken – dass Betreiber entsprechender Bibliotheken Daten erheben.
                        Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie hier: <a href="https://policies.google.com/privacy">policies.google.com/privacy</a>
                    </Typography>
                    <Typography variant="h6" component="h4" gutterBottom>
                        Einbindung der Dienste Dritter
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Es kann vorkommen, dass innerhalb unserer Internetseiten Inhalte Dritter, wie zum Beispiel RSS- Feeds oder Grafiken von anderen Webseiten eingebunden werden. Dies setzt immer voraus, dass die Anbieter dieser Inhalte (nachfolgend bezeichnet als "Dritt-Anbieter") die IP-Adresse der Nutzer wahrnehmen. Denn ohne die IP-Adresse, könnten sie die Inhalte nicht an den Browser des jeweiligen Nutzers senden. Die IP-Adresse ist damit für die Darstellung dieser Inhalte erforderlich. Wir bemühen uns nur solche Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Jedoch haben wir keinen Einfluss darauf, falls die Dritt-Anbieter die IP-Adresse z.B. für statistische Zwecke speichern. Soweit dies uns bekannt ist, klären wir die Nutzer darüber auf.
                        Stets achten wir darauf, dass der europäische Datenschutzstandard gewahrt bleibt, um Ihre Daten effektiv schützen zu können.
                    </Typography>
                    <Typography variant="h6" component="h4" gutterBottom>
                        Empfänger/ Weitergabe von Daten
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Daten, die Sie uns gegenüber angeben, werden grundsätzlich nicht an Dritte weitergegeben, es sei denn, dass dies zum Zwecke der Vertragsabwicklung oder Informationsbereitstellung erforderlich ist. So kann es beispielsweise erforderlich sein, dass wir bei Anfragen über unser Kontaktformular oder Buchung von Online-Präsentationen Ihre Daten an unsere lokalen Vertriebspartner weitergeben, um eine regionale Betreuung zu gewährleisten.
                        Insbesondere werden Ihre Daten nicht an Dritte für deren Werbezwecke weitergegeben.
                        Wir setzen jedoch ggf. Dienstleister und Auftragsverarbeiter für den Betrieb dieser Internetseiten oder für weitere Produkte von uns ein. Hier kann es vorkommen, dass ein Dienstleister Kenntnis von personenbezogenen Daten erhält. Wir wählen unsere Dienstleister sorgfältig aus – insbesondere im Hinblick auf Datenschutz und Datensicherheit – und treffen alle datenschutzrechtlich erforderlichen Maßnahmen für eine zulässige Datenverarbeitung.
                    </Typography>

                    <Typography variant="h5" component="h3" gutterBottom>
                        DATENVERARBEITUNG AUF DEM TELESCAN-SERVER
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Zur Verwendung des TeleScan-Dienstes muss ein TeleScan-Server eingerichtet und konfiguriert werden. Die Einrichtung wird vom jeweiligen AIS Anbieter oder durch zollsoft vorgenommen. Bei der dabei stattfindenden Ersteinrichtung werden folgende Daten erfasst und am TeleScan-Server hinterlegt:
                        <ul>
                            <li>Arztdaten (Name, BSNR, LANR, Fachrichtung) </li>
                            <li>betriebsstättenweites Passworts und Erhalt eines RecoveryCodes zum Zurücksetzen des Passworts</li>
                            <li>Basisdaten der Betriebsstätte (Betriebsstättenname, Adresse und mind. eine Kontaktmöglichkeit mit der Auswahl aus Telefon, Fax und Mobil)</li>
                            <li>Basisdaten der Ärzte (mind. ein Arzt muss hinterlegt werden) der Betriebsstätte (o.g. Arztdaten, HÄVG-ID der Hausärzte bzw. Medi-ID der Dermatologen, optional: abweichende Adresse und Kontaktdaten)</li>
                            <li>Berechtigung zum automatisierten Abruf von Daten über das HÄVG-Modul (zwingend zur Nutzung des TeleScan-Dienstes erforderlich)</li>
                            <li>Angabe des der von der Managementgesellschaft vergebene Identifikationscodes</li>
                            <li>Individuelles Arzt-Passwort und Arztzertifikat</li>
                        </ul>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Der TeleScan-Server tauscht über das gesicherte Praxisnetzwerk oder eine gesicherte VPN-Verbindung Daten mit dem TeleScan-Client, der TeleScan-App und dem HÄVG-Prüfmodul (HPM) aus.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Die Ersteinrichtung erfolgt dabei ebenfalls auf der Grundlage der Nutzer-Einwilligung, gem. Art. 6 Abs. 1 lit. a DSGVO. Rechtsgrundlage für diese Datenverarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO, die Erfüllung des Vertrags gegenüber den Nutzern.
                    </Typography>

                    <Typography variant="h5" component="h3" gutterBottom>
                        DATENVERARBEITUNG IM TELESCAN-CLIENT
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Der TeleScan-Client dient zur Erstellung sowie Verwaltung der Konsilanfragen als auch der Konsilbefundungen. Je nach Fachrichtung werden dabei unterschiedliche Informationen abgefragt. Auf Hausarztseite werden folgende Informationen abgefragt:
                        <ul>
                            <li>Patientenstammdaten (Name, Geburtsdatum, Geschlecht, Versichertennummer)</li>
                            <li>Medizinische Patientendaten (Diagnose, Laborwerte, Medikation, Lokalisation, Anamnese, Fragestellung an den Facharzt)</li>
                            <li>Patientenbilder (von betroffener Hautregion)</li>
                        </ul>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Diese Daten werden in einer Konsilanfrage zusammengefasst und über den TeleScan-Server im Austauschformat Clinical Document Architecture im Dateiformat XML (HL7.CDA) an das HÄVG-Prüfmodel (HPM) zur Zertifizierung und Weitergabe übergeben. Darüber hinaus ist die Weitergabe von TeleScan-Nutzer-Metadaten (Arztzertifikat, Konsil-ID) notwendig damit eine eindeutige Zuordnung und Weitergabe des Konsils zwischen den Ärzten möglich ist.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Auf Facharztseite können die Daten aus der Konsilanfrage gesichtet und befundet werden, darüber hinaus wird eine Bewertung der Konsilanfrage und der Inhalte verlangt. Dabei hat der Facharzt bei Unklarheiten die Möglichkeit einmal eine Rückfrage an den Hausarzt vor der endgültigen Befundung zu stellen. Sowohl die Rückfrage als auch die Befundung selbst wird im Dateiformat XML (HL7.CDA) exportiert und über das HPM weitergegeben.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Rechtsgrundlage für diese Datenverarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO, die Erfüllung des Vertrags gegenüber den Nutzern.
                    </Typography>

                    <Typography variant="h5" component="h3" gutterBottom>
                        DATENVERARBEITUNG IN DER TELESCAN-APP
                    </Typography>
                    <Typography variant="h6" component="h4" gutterBottom>
                        Anmeldung und Nutzung der App
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Die Voraussetzung für die Nutzung der TeleScan-App ist die vorherige Einrichtung eines TeleScan-Servers mit hinterlegten Nutzerdaten (siehe Abschnitt „Datenverarbeitung auf dem TeleScan-Server“) nötig.
                        Die TeleScan-App kann über den jeweiligen App-Store heruntergeladen und auf dem mobilen Endgerät installiert werden. Nach der Erstinstallation TeleScan-App auf dem Endgerät wird der Nutzer aufgefordert, sich anzumelden. Er wird aufgefordert, eine LANR sowie BSNR anzugeben sowie ein Passwort zu wählen.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Bei der Nutzung greift die TeleScan App auf folgende Daten des TeleScan-Servers zu:
                        <ul>
                            <li>sämtliche Konsile, welche noch nicht abgeschlossen oder abgebrochen wurden,</li>
                            <li>Anamnese, Lokalisation, dermatologische Aufnahmen, Fragestellung und Patientendaten der jeweiligen Konsile</li>
                            <li>Patientendaten umfassen Name, Geschlecht, Geburtstag, Anschrift, Krankenkasse, Kassen-IKNRr., eGK-Versichertennummer, Krankenkassenstatus, Personengruppe, DMP-Zuordnung, Patientennummer und berufliche Tätigkeit</li>
                            <li>Diagnosen, Medikamente und Laborwerte</li>
                            <li>Konsiliarberichte</li>
                            <li>Arztdaten (Name, BSRN und LANR)</li>
                        </ul>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Der Austausch zwischen der App mit dem Server erfolgt entweder über eine direkte Netzwerkverbindung oder über eine verschlüsselte VPN Verbindung, fern vom Zugriff durch zollsoft oder Dritten. Darüber hinaus werden sämtliche mit dem TeleScan-Server ausgetauschte Daten zum Zwecke der Offline-Verwendung verschlüsselt auf dem mobilen Endgerät gespeichert. Weiterhin werden die Anmeldedaten auf dem mobilen Endgerät gespeichert, sofern dies bei der Anmeldung aktiviert wurde. Aber da dies lokal geschieht, hat auch hier zollsoft oder irgendein anderer Dritter keinen Zugriff. Alle anderen Daten liegen auf dem TeleScan-Server und werden von dort nur temporär, zum Erfüllen der App-Funktionalitäten, abgerufen und werden mit Zeitpunkt der Abmeldung oder Löschung der App gelöscht.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Zu Testzwecken kann die App mit einem zollsoft eigenen DemoServer verbunden werden. Hier weist zollsoft ausdrücklich darauf hin, dass dieser mit den passenden Zugangsdaten allen Nutzern zugänglich ist und er daher nicht zu Behandlungszwecke gedacht ist.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Rechtsgrundlage für diese Datenverarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO, die Erfüllung des Vertrags gegenüber den Nutzern.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Zollsoft greift auf personenbezogene Daten einschließlich gesundheitsbezogener Informationen nur dann zu, sofern dies gesetzlich vorgeschrieben ist. Soweit möglich wird zollsoft die Daten unkenntlich machen, pseudonymisieren, zusammenfassen und/oder anonymisieren, um unseren gesetzlichen Verpflichtungen als Hersteller von Medizinprodukten nachzukommen.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Gemäß der gesetzlichen Vorgaben wird zollsoft diese Informationen verwenden:
                        <ul>
                            <li>um die laufende Sicherheit der App und künftiger Entwicklungen zu gewährleisten;</li>
                            <li>um die Qualität, Sicherheit und Wirksamkeit von Medizinprodukten und -systemen zu überwachen und zu verbessern;</li>
                            <li>um Upgrades zu validieren und die TeleScan-App sicher und geschützt zu halten;</li>
                            <li>um Medizinprodukte, einschließlich neuer und bestehender Funktionen und Funktionalitäten, zu erforschen, zu entwickeln und zu prüfen und um die TeleScan-App zu verbessern; und</li>
                            <li>sofern gesetzlich nichts anderes vorgeschrieben ist, um den zuständigen Regulierungs-, Strafverfolgungs- und Regierungsbehörden Rede und Antwort stehen zu können, um auf die nationale Sicherheit oder Epidemien, Gerichtsverfahren, Gerichtsbeschlüsse, behördliche Anfragen oder Rechtsverfahren, die uns zugestellt wurden, einzugehen, um die Sicherheit, die Rechte oder das Eigentum unserer Kunden, der Öffentlichkeit, von zollsoft oder anderen zu schützen, und um die gesetzlichen Rechte von zollsoft auszuüben, zu begründen oder zu verteidigen, wenn wir glauben, dass dies notwendig ist, um illegale Aktivitäten, Betrugsverdacht oder Situationen, die eine potenzielle Bedrohung der Sicherheit irgendeiner Person oder eine Verletzung dieser Datenschutzerklärung darstellen, zu untersuchen, oder wenn diese als Beweismittel in einem Rechtsstreit verwendet werden sollen, an dem wir beteiligt sind.</li>
                        </ul>
                    </Typography>
                    <Typography variant="h6" component="h4" gutterBottom>
                        Datenaustausch im TeleScan-Dienst und Verschlüsselung der Übertragung
                    </Typography>
                    <Typography variant="subtitle1" className="bold-label" component="h5" gutterBottom>
                        Datenaustausch
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Die Verwendung des Dienstes sieht vor, dass die TeleScan-Nutzer (Haus- und Fachärzte) sowohl untereinander Dateien im Rahmen eines Konsils austauschen als auch innerhalb des Dienstes zwischen TeleScan-Server, -App und -Client ein Datenaustausch stattfindet. Der Datenaustausch innerhalb des Dienstes findet dabei im gesicherten Netzwerk des TeleScan-Nutzers statt (für eine entsprechende Sicherung des Netzwerkes ist dieser angehalten) oder über eine entsprechend gesicherte VPN-Verbindung. Auch die Übertragung der schlussendlich generierten XML Dateien an das HPM erfolgt über das gesicherte Netzwerk des TeleScan-Nutzers.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Weitere Informationen zum Hausärzteverband-Prüfmodul finden Sie unter: <a href="https://www.haevg-rz.de/haevg-pruefmodul-hilfestellung/">https://www.haevg-rz.de/haevg-pruefmodul-hilfestellung/</a> und <a href="https://www.haevg-rz.de/">https://www.haevg-rz.de/</a>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Rechtsgrundlage für diese Datenverarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO, die Erfüllung des Vertrags gegenüber den Nutzern.
                    </Typography>
                    <Typography variant="subtitle1" className="bold-label" component="h5" gutterBottom>
                        Verschlüsselung der Übertragung
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Der Dienst wird ausschließlich in einem gesicherten Netzwerk verwendet – insofern der TeleScan-Nutzer dieses entsprechend sichert. Die Datenübertragung findet also in einer technischen Umgebung statt, die verhindert, dass Unbefugte Zugriff auf die übermittelten Daten erlangen.
                        Bitte beachten Sie, dass bei der Datenübertragung, die über dieses Angebot hinausgeht (z.B. E-Mail), die Verbindung unter Umstanden nur nach vorheriger Absicherung Ihrerseits abhörsicher gestaltet ist.
                    </Typography>
                    <Typography variant="subtitle1" className="bold-label" component="h5" gutterBottom>
                        Export-Datei erstellen
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Die jeweils erstellten XML Dateien einer Konsilanfrage und des Befunds werden zu dokumentatorischen Zwecken und zum Nachweis auf den TeleScan-Server der Nutzer abgespeichert und verbleiben dort dauerhaft bis zur Beendigung des TeleScan-Nutzungsvertrags.
                    </Typography>
                    <Typography variant="h6" component="h4" gutterBottom>
                        Support
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Für Fragen - insbesondere technischer Natur - haben Sie die Möglichkeit, den Support zu kontaktieren. Der Zweck besteht daher in der Bearbeitung Ihrer Anfragen. Im Rahmen des Supports werden insbesondere die folgenden Daten – soweit erforderlich – verarbeitet:
                        <ul>
                            <li>Name,</li>
                            <li>Telefonnummer,</li>
                            <li>E-Mail Adresse,</li>
                            <li>Anliegen bzw. Fehlerbeschreibung,</li>
                            <li>Sonstige notwendige Informationen, um Ihre Anfrage zu bearbeiten</li>
                        </ul>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Die Rechtsgrundlage für den Support bildet Ihre Einwilligung in die Datenverarbeitung (Art. 6 Abs. 1 lit. a DSGVO). Bitte beachten Sie, dass ohne konkrete Angaben kein Support geleitet werden kann.
                        Grundsätzlich werden die Daten nach Abschluss der Support Anfrage umgehend gelöscht, sofern Sie Ihre Einwilligung nicht früher widerrufen. Die Speicherdauer beträgt längstens 6 Monate. Ihre Daten werden nicht an Dritte weitergegeben.
                    </Typography>
                    <Typography variant="h6" component="h4" gutterBottom>
                        Cookies
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Beim Aufbau einer Smartphone-Session werden technisch notwendige "Session-Cookies" bzw. "Access Tokens" eingesetzt, die keine personenbezogenen Daten enthalten und mit dem Ablauf der Sitzung ihre Gültigkeit verlieren und unverzüglich gelöscht werden. Insoweit durch den Einsatz der genannten Cookies eine Verarbeitung personenbezogener Daten stattfindet, bildet die Rechtsgrundlage hierfür unser berechtigtes Interesse, den technischen Betrieb und die grundlegenden Funktionen App sicherzustellen (Art. 6 Abs. 1 lit. f DSGVO). Ihre Daten werden nicht an Dritte weitergegeben.
                    </Typography>
                    <Typography variant="h6" component="h4" gutterBottom>
                        Berechtigungen bei Nutzung der TeleScan-App
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                    Bei der Nutzung TeleScan -App sind grundsätzlich Berechtigungen zur Nutzung der Funktionen notwendig. Folgende zwingend notwendige Berechtigungen müssen Sie der App erteilen:
                        <ul>
                            <li><strong>Kamera</strong><br/>Die App benötigt Zugriff auf die Kamera, damit das TeleScan-Foto angefertigt werden kann.</li>
                            <li><strong>Fotogalerie/Bibliothek</strong><br/>Insofern auf bereits gefertigte Fotos zugegriffen werden soll benötigt die App dementsprechenden Zugriff auf die jeweilige Fotogalerie bzw. Bibliothek.</li>
                            <li><strong>Netzwerkzugriff und Netzwerkverbindungen</strong><br/>Der Netzwerk-Zugriff ist nur dann erforderlich, wenn die App die Daten synchronisiert sowie. zur Anmeldung</li>
                            <li><strong>Fehlerbericht</strong><br/>Betriebssystem (zum Beispiel 9.0.0)<br/>
                                Gerätetyp (zum Beispiel ANDROID_x86)<br/>
                                Installierte Version der App<br/>
                                Protokolldatei (z.B.letzte Datenübertragung und Fehlermeldungen)
                            </li>
                        </ul>
                    </Typography>
                    <Typography variant="h6" component="h4" gutterBottom>
                        Deinstallation und Sperrung
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Der Nutzer hat jederzeit die Möglichkeit die App zu deinstallieren, die App wird dann entfernt; dies gilt, soweit dies technisch möglich und für zollsoft umsetzbar ist. zollsoft weist den Nutzer zusätzlich daraufhin, dass evtl. Aufnahmen im aufnehmenden Gerät verbleiben, auf diesen Speicherort hat zollsoft über die App keinen Einfluss und kann demnach diese Daten nicht löschen.
                    </Typography>
                    <Typography variant="h6" component="h4" gutterBottom>
                        Haftung
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Der Nutzer hat während des Datenaustausches und auch bei Teilen der Nutzung für eine stabile Netzwerkverbindung zu sorgen; für etwaige Fehler und Schäden - wie z.B. einen Datenverlust - aufgrund einer unterbrochenen oder in sonstiger Art und Weise gestörten Netzwerkverbindung, die nicht in der Verantwortung von zollsoft liegt, kann zollsoft keine Haftung übernehmen.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Zollsoft legt die Daten unverschlüsselt auf dem jeweils hinterlegten Rechnerpfad bzw. auf dem hinterlegten Pfad des TeleScan-Servers ab, die Datensicherung und der Datenschutz obliegt dem Nutzer. Im Rahmen der Nutzung kann zollsoft deshalb außerhalb seines Verantwortungsbereichs bei mangelnden Möglichkeiten der Einflussnahme, keine Haftung für die Sicherheit der Daten und für einen möglichen Zugriff eines Außenstehenden übernehmen. Die Pflicht, eigene und ggf. Daten Dritter zu schützen, obliegt dem Nutzer.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Zu Testzwecken kann die App mit einem zollsoft eigenen DemoServer verbunden werden. Hier weist zollsoft ausdrücklich darauf hin, dass dieser allen Nutzern zugänglich ist und er daher nicht zu Behandlungszwecke gedacht ist.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Im Fall der Haftung von zollsoft, ist die Haftung auf Schadens- oder Aufwendungsersatz, gleich aus welchem Rechtsgrund, nach Maßgabe der nachfolgenden Bestimmungen beschränkt.
                        <br/>Dagegen haftet zollsoft unbeschränkt im Falle:
                        <ul>
                            <li>von Vorsatz</li>
                            <li>und grober Fahrlässigkeit</li>

                        </ul>
                    </Typography>

                    <Typography variant="h5" component="h3" gutterBottom>
                        AUFBEWAHRUNG UND LÖSCHUNG
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Zollsoft hat mit Ausnahme der oben beschriebenen Supportleistungen grundsätzlich keinen Zugriff auf die personenbezogenen Daten der in dem TeleScan-Dienst verwendeten Daten, explizit auf die Patientendaten. Eine Aufbewahrung von erhobenen Daten während einer Supportleistung erfolgt nur insofern und solange diese für die Behebung der Supportproblematik notwendig sind. Eine Löschung erfolgt unverzüglich im Anschluss. Darüber hinaus befinden sich keine Daten der TeleScan App auf Servern der zollsoft GmbH. Die Daten liegen grundsätzlich auf dem TeleScan-Server der Praxis.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Eine Löschung der Daten auf dem TeleScan-Server kann mit Hilfe des zollsoft-Supports eingeleitet werden oder durch eine datenschutzkonforme physische Zerstörung der Hardware erfolgen. Anderweitig verbleiben die TeleScan-Daten vorerst und auf Basis der medizinisches Aufbewahrungspflichten von mindestens 10 Jahren dauerhaft auf dem Nutzereigenen TeleScan-Server.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Zollsoft weist daher ausdrücklich darauf hin, dass die Aufbewahrung, Verwaltung und Löschung der im Dienst verwendeten Patientendaten eines TeleScan-Nutzers in dessen Verantwortungsbereich liegen und damit datenschutzrechtliche Maßnahmen durch ihn ergriffen werden müssen.
                    </Typography>

                    <Typography variant="h5" component="h3" gutterBottom>
                        EMPFÄNGER UND WEITERGABE VON DATEN
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Daten, die Telescan-Nutzer zollsoft gegenüber angeben (z.B. über das Kontaktformular, Kontakt-E-Mail-Adresse, telefonisch und/ oder im Rahmen einer Supportleistung, usw.), werden grundsätzlich nicht an Dritte weitergegeben, es sei denn, dass dies zum Zwecke der Vertragsabwicklung oder
                        Informationsbereitstellung erforderlich ist. Insbesondere werden jegliche Daten nicht an Dritte für deren Werbezwecke weitergegeben.
                    </Typography>

                    <Typography variant="h4" component="h2" gutterBottom>
                        DATENVERARBEITUNG AUSSERHALB DER EUROPÄISCHEN UNION
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                    Im Rahmen der Verwendung der TeleScan-App/ -Clients/ -Servers findet keine Verarbeitung außerhalb der europäischen Union statt.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Allerdings bedient sich die zollsoft GmbH für die TeleScan-Webseite (https://telescan-software.de/) zur Erbringung Ihrer Dienstleistungen und somit zur Verarbeitung personenbezogener Daten u.a. Auftragsverarbeiter (i.S. von IT-System- Anbietern) aus einem sogenannten Drittstaat (USA) außerhalb der Europäischen Union (G Suite von Google, Zendesk für Supportticket-Verwaltung, Mailchimp für Newsletter-Versand).
                        Bei der Auswahl der Dienstleister aus nichtsicheren Drittstaaten wird auf eine DSGVO konforme Anwendung in Hinblick auf Datenschutz und Datensicherheit geachtet. Es bestehen Auftragsverarbeitungsverträge mit den eingesetzten Dienstleistern.
                    </Typography>

                    <Typography variant="h4" component="h2" gutterBottom>
                        Automatische Entscheidungsfindung
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Es findet keine automatische Entscheidungsfindung inkl. Profiling im Rahmen der App/Client/Server Nutzung statt. Art. 13 Abs.2 lit f DSGVO.
                    </Typography>

                    <Typography variant="h4" component="h2" gutterBottom>
                        BETROFFENENRECHTE GEMÄß Art. 15-22 DSGVO
                    </Typography>
                    <Typography variant="h5" component="h3" gutterBottom>
                        Widerspruchsrecht 
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Bei Gründen, die sich aus einer besonderen Situation für Sie heraus ergeben, haben Sie außerdem das Recht jederzeit mit Wirkung für die Zukunft gegen die Verarbeitung Sie betreffender personenbezogener Daten, die gemäß Art. 6 Abs. 1 Buchst. e) oder f) DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Ausgenommen davon sind Daten, die aufgrund gesetzlicher Vorschriften aufbewahrt oder zur ordnungsgemäßen Geschäftsabwicklung benötigt werden.
                        Das Widerspruchsrecht können Sie kostenfrei ausüben.
                    </Typography>
                    <Typography variant="h5" component="h3" gutterBottom>
                        Auskunftsrecht 
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Sie haben das Recht, von uns unentgeltlich eine Bestätigung darüber zu verlangen, ob Sie betreffende personenbezogene Daten verarbeitet werden, sowie ggf. auf Auskunft über diese personenbezogenen Daten und die weiteren in Art. 15 DSGVO aufgeführten Informationen.
                        Da wir nur Daten erheben und in beschriebenen Umfang und Dauer speichern, wenn Sie entweder als Arzt registriert sind oder bereits als Patient das Angebot genutzt haben, ist es uns nur in diesem Fall möglich, eine Auskunft über die Daten zu geben.
                    </Typography>
                    <Typography variant="h5" component="h3" gutterBottom>
                        Berichtigungsrecht 
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Sie haben das Recht, von uns unverzüglich die Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu verlangen (Art. 16 DSGVO). Unter Berücksichtigung der Zwecke der Verarbeitung haben Sie das Recht, die Vervollständigung unvollständiger personenbezogener Daten – auch mittels einer ergänzenden Erklärung – zu verlangen.
                    </Typography>
                    <Typography variant="h5" component="h3" gutterBottom>
                        Recht auf Löschung ("Recht auf Vergessenwerden") 
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Trifft einer der in Art. 17 Abs. 1 DSGVO genannten Gründe zu und ist die Verarbeitung nicht für einen der in Art. 17 Abs. 3 DSGVO geregelten Zwecke erforderlich, steht Ihnen das Recht zu, von uns zu verlangen, dass Sie betreffende personenbezogene Daten unverzüglich gelöscht werden.
                    </Typography>
                    <Typography variant="h5" component="h3" gutterBottom>
                        Recht auf Einschränkung der Verarbeitung 
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Sie sind berechtigt, eine Einschränkung bei der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, wenn eine der in Art. 18 Abs. 1 Buchst. a) bis d) DSGVO geregelten Voraussetzungen gegeben ist.
                    </Typography>
                    <Typography variant="h5" component="h3" gutterBottom>
                        Recht auf Datenübertragbarkeit  
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Sie haben unter den in Art. 20 Abs. 1 DSGVO genannten Voraussetzungen das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten, und das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns zu übermitteln. Bei der Ausübung des Rechts auf Datenübertragbarkeit haben Sie das Recht, zu erwirken, dass die personenbezogenen Daten direkt von uns an eine andere verantwortliche Stelle übermittelt werden, soweit dies technisch machbar ist.
                    </Typography>
                    <Typography variant="h5" component="h3" gutterBottom>
                        Widerrufsrecht bei Einwilligung 
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Soweit die Verarbeitung auf Ihrer Einwilligung beruht, haben Sie das Recht, die Einwilligung jederzeit zu widerrufen. Die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung wird dadurch nicht berührt.
                    </Typography>
                    <Typography variant="h5" component="h3" gutterBottom>
                        Beschwerderecht bei einer Aufsichtsbehörde 
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Sie haben das Recht, sich über die Verarbeitung personenbezogener Daten durch uns bei einer Aufsichtsbehörde Ihrer Wahl für den Datenschutz zu beschweren.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Sie können dies z.B. bei der für die zollsoft GmbH zuständigen Aufsichtsbehörde tun:
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Thüringer Landesbeauftragter für den Datenschutz und die Informationsfreiheit<br/>
                        Postfach 900455<br/>
                        99107 Erfurt
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Internetseite mit weiteren Kontaktdaten: <a href="https://tlfdi.de/tlfdi">tlfdi.de/tlfdi</a>
                    </Typography>

                    <Typography variant="h4" component="h2" gutterBottom>
                        ÄNDERUNG DIESER DATENSCHUTZHINWEISE
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Zollsoft überarbeitet diese Datenschutzhinweise bei Änderungen an dieser App oder bei sonstigen Anlässen, die dies erforderlich machen. Die jeweils aktuelle Fassung finden Sie stets unter dem Reiter Datenschutz in dieser App sowie auf der Webseite unter „Datenschutzerklärung“.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Stand: 18.03.2021
                    </Typography>
                </Paper>
            </Box>

        )
    }
}

export const DataprotectionContainer = Dataprotection;
export default DataprotectionContainer;
