import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { DermaRegistrationStatus } from 'telescan-core';

interface IState {

}

interface IProps {
    hanldeOnRowClick: () => void;
    dermaRegistrationInfo: DermaRegistrationStatus;
    handleChangeEditFlag: (isEdit: boolean) => void;
}


export class DirectoryServiceTable extends React.Component<IProps, IState> {


    render() {
        let lanrBsnr: string = "";
        if (this.props.dermaRegistrationInfo.metadata?.lanr && this.props.dermaRegistrationInfo.metadata?.bsnr)
            lanrBsnr = `${this.props.dermaRegistrationInfo.metadata?.lanr} / ${this.props.dermaRegistrationInfo.metadata?.bsnr}`;
        else if (!this.props.dermaRegistrationInfo.metadata?.lanr && !this.props.dermaRegistrationInfo.metadata?.bsnr)
            lanrBsnr = "";
        else if (!this.props.dermaRegistrationInfo.metadata?.lanr && this.props.dermaRegistrationInfo.metadata?.bsnr)
            lanrBsnr = this.props.dermaRegistrationInfo.metadata?.bsnr;
        else if (!this.props.dermaRegistrationInfo.metadata?.bsnr && this.props.dermaRegistrationInfo.metadata?.lanr)
            lanrBsnr = this.props.dermaRegistrationInfo.metadata?.lanr;

        return (
            <TableContainer className="zertifikate-table selectable-rows">
                <Table className="table-connector" stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>LANR / BSNR</TableCell>
                            <TableCell>Arzt</TableCell>
                            <TableCell>KIM-Adresse</TableCell>
                            <TableCell>Aktivierungsstatus</TableCell>
                            {/* <TableCell align="right"></TableCell> */}
                        </TableRow>
                    </TableHead>
                        <TableBody>
                            <TableRow onClick = {this.props.hanldeOnRowClick} hover>
                                <TableCell>{lanrBsnr}</TableCell>
                                <TableCell>{`${this.props.dermaRegistrationInfo.metadata?.title || ""} ${this.props.dermaRegistrationInfo.metadata?.firstName || ""} ${this.props.dermaRegistrationInfo.metadata?.lastName || ""}`}</TableCell>
                                <TableCell>{this.props.dermaRegistrationInfo.metadata?.kimEmailAddress}</TableCell>
                                <TableCell> {this.props.dermaRegistrationInfo.active} </TableCell>
                                {/* <TableCell align="right">
                                    <IconButton size="small">
                                        <Delete/>
                                    </IconButton>
                                </TableCell> */}
                            </TableRow>
                        </TableBody>
                    

                </Table>
            </TableContainer>
        )
    }
}

