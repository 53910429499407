import { DermaRegistrationStatus, DermatologistInfo, KIMGeneral, KIMUser, KimUserValidation, KonsilMetaInformation, TIConnectorEvent } from 'telescan-core';
import { EKimActions, KimClearInboxProgress, TKimActionTypes } from "../types/kim_types";

export interface IKimState {
    kimGeneral: KIMGeneral;
    kimUser: KIMUser;
    dermatologists: DermatologistInfo[];
    kimMetadaten: KonsilMetaInformation[];
    tiKonnectorEvent: TIConnectorEvent;
    isKonsilSigned: boolean;
    dermatologistRegistration: DermaRegistrationStatus;
    isTiConnected: boolean;
    isTiStatusLoading: boolean;
    isDataLoading: boolean;
    isDermaListLoading: boolean;
    isTestTiConnectionClicked: boolean;
    kimUserValidation: KimUserValidation;
    kimDeleteProgress?: KimClearInboxProgress
}

const initialState: IKimState = {
    kimGeneral: new KIMGeneral(),
    kimUser: new KIMUser(),
    dermatologists: [],
    kimMetadaten: [],
    tiKonnectorEvent: new TIConnectorEvent(),
    isKonsilSigned: false,
    dermatologistRegistration: new DermaRegistrationStatus(),
    isTiConnected: false,
    isTiStatusLoading: false,
    isDataLoading: false,
    isDermaListLoading: false,
    isTestTiConnectionClicked: false,
    kimUserValidation: {},
    kimDeleteProgress: undefined
}

export function kim_reducer(state = initialState, action: TKimActionTypes): IKimState {
    switch (action.type) {
        case EKimActions.SET_KIM_GENERAL:
            return {
                ...state,
                kimGeneral: action.kimGeneral
            }

        case EKimActions.SET_KIM_USER:
            return {
                ...state,
                kimUser: action.kimUser
            }

        case EKimActions.SET_DERMATOLOGISTS:
            return {
                ...state,
                dermatologists: action.dermatologists
            }

        case EKimActions.SET_KIM_USER_VALIDATION:
            return {
                ...state,
                kimUserValidation: action.kimUserValidation
            }

        case EKimActions.SET_KIM_METADATEN:
            return {
                ...state,
                kimMetadaten: action.kimMetadaten
            }

        case EKimActions.SET_KIM_METADATEN_KONSIL_BEARBEITUNGSSTATUS:
            const konsilIndexAdd = state.kimMetadaten.findIndex(item => item.vorgangsId === action.vorgangsId);
            const kimMetadatenNewArray = [...state.kimMetadaten];
            kimMetadatenNewArray[konsilIndexAdd].bearbeitungsStatus = action.bearbeitungsStatus;
            return {
                ...state,
                kimMetadaten: kimMetadatenNewArray
            }

        case EKimActions.REMOVE_KIM_METADATEN_KONSIL:
            const kimMetadatenFiltered = state.kimMetadaten.filter(item => item.vorgangsId !== action.vorgangsId)
            return {
                ...state,
                kimMetadaten: kimMetadatenFiltered,
            }

        case EKimActions.SET_TICONNECTOR_EVENT:
            return {
                ...state,
                tiKonnectorEvent: action.tiConnectorEvent
            }

        case EKimActions.SET_IS_KONSIL_SIGNED:
            return {
                ...state,
                isKonsilSigned: action.isKonsilSigned
            }

        case EKimActions.SET_DERMATOLOGIST_REGISTRATION:
            return {
                ...state,
                dermatologistRegistration: action.dermatologistRegistration
            }

        case EKimActions.SET_IS_TI_CONNECTED:
            return {
                ...state,
                isTiConnected: action.isTiConnected
            }

        case EKimActions.SET_IS_TI_STATUS_LOADING:
            return {
                ...state,
                isTiStatusLoading: action.isTiStatusLoading
            }

        case EKimActions.SET_IS_DATA_LOADING:
            return {
                ...state,
                isDataLoading: action.isDataLoading
            }

        case EKimActions.SET_IS_DERMA_LIST_LOADING:
            return {
                ...state,
                isDermaListLoading: action.isDermaListLoading
            }

        case EKimActions.SET_IS_TEST_TI_CONNECTION_CLICKED:
            return {
                ...state,
                isTestTiConnectionClicked: action.isTestTiConnectionClicked
            }

        case EKimActions.SET_CLEAR_INBOX_PROGRESS:
            return {
                ...state,
                kimDeleteProgress: action.progress
            }


        default:
            return state;
    }

}
