import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../reducers';
import  * as TiConnectorTypes from '../types/ti_connector_types';
import uuid from 'uuid';
import { failure, setSuccessMessage } from './general_actions';
import { Failure, TIConnectorService } from 'telescan-core';

// 1. Pure redux actions

export function setIsKimMailInLdap(isKimMailInLdap: boolean): TiConnectorTypes.ISetIsKimMailInLdap {
    return {
        type: TiConnectorTypes.ETiConnectorActions.SET_IS_KIM_MAIL_IN_LDAP,
        isKimMailInLdap: isKimMailInLdap
    }
}

export function setIsLdapRequest(isLdapRequest: boolean): TiConnectorTypes.ISetIsLdapRequest {
    return {
        type: TiConnectorTypes.ETiConnectorActions.SET_IS_LDAP_REQUEST,
        isLdapRequest: isLdapRequest
    }
}

// 2. Thunk middleware functions

export function getKimMailInLdapStatus(kimMail: string): ThunkAction<Promise<any>, RootState, unknown, Action<string>> {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setIsLdapRequest(true));
            new TIConnectorService().checkKimEmailInLdap(kimMail)
            .then((response: boolean) => {
                dispatch(setIsLdapRequest(false));
                if (response) {
                    dispatch(setIsKimMailInLdap(true));
                    dispatch(setSuccessMessage(uuid.v4(), "Die angegebene KIM-Adresse wurde über den KIM-Verzeichnisdienst erfolgreich verifiziert."));
                }
                else {
                    dispatch(setIsKimMailInLdap(false));
                    const failureObj = new Failure();
                    failureObj.error = "Die angegebene KIM-Adresse konnte im KIM-Verzeichnisdienst nicht gefunden werden. Bitte überprüfen Sie Ihre Eingabe.";                   
                    dispatch(failure(uuid.v4(), failureObj));
                }
                resolve(response);
            })
            .catch((failureObj: any) => {
                dispatch(setIsLdapRequest(false));
                dispatch(failure(uuid.v4(), failureObj));
            })
        })
    }
}

export function cancelSignKonsil(): ThunkAction<any, RootState, unknown, Action<string>> {
    return (dispatch) => {

        new TIConnectorService().cancelSignKonsil()
        .catch((failureObj: any) => {
            dispatch(failure(uuid.v4(), failureObj));
        });
    }
}