import React from 'react';
// material components
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Paper,
    Tooltip,
    Typography
} from '@material-ui/core'
import {checkCdaSize} from '../../utils';
import SimpleModalContainer from '../elements/simple_modal';
import {CdaSizeComponent} from '../elements/cda_size';
import PdfInline from '../elements/pdf_inline';
import ZertifikatPasswortComponent from '../elements/zertifikat_passwort';
import {Failure, Konsil, KonsilLimitSizes, KonsilValidationBeforeSendStatusTypes, TIConnectorEvent, UploadPath} from 'telescan-core';
import { KonsilValidationBadge } from '../elements/konsil_validation_badge';
import ConnectionBadge from '../elements/connection_badge';
import { SignatureDialogContainer } from '../settings/kim_configuration/signature_dialog';

interface IProps {
    isOpen: boolean;
    handleVersenden: () => void;
    password: string;
    handlePasswordChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    pdfPreviewData: string | null;
    handleClearPassword: () => void;
    toggleReplyPopup: () => void;
    resetPdfPreview: () => void;
    currentKonsil: Konsil;
    cdaSize: number;
    dispatch_setCdaSize: (cdaSize: number) => void;
    isComfortVersand: boolean;
    comfortVersandPassword: string;
    dispatch_setIsComfortVersand: (isComfortVersand: boolean) => void;
    dispatch_setComfortVersandPassword: (comfortVersandPassword: string) => void;
    isPdfLoading: boolean;
    konsilValidationStatus: keyof typeof KonsilValidationBeforeSendStatusTypes | null;
    konsilValidationError: Failure;
    hasPasswordChanged: boolean;
    dispatch_getKimConnectionStatus: () => Promise<any>;
    isKimConnected: boolean;
    dispatch_setIsKimConnected: (isSmtpConnected: boolean) => void;
    isKonsilSigned: boolean;
    tiConnectorEvent: TIConnectorEvent;
    isSignatureDialogOpen: boolean;
    handleSignatureDialogOpen: () => void;
    handleSignatureDialogClose: () => void;
}

export default class ReplyPopup extends React.Component<IProps> {

    isSendButtonDisabled = (): boolean => {
        if (this.props.currentKonsil.uploadPath === UploadPath.HPM || !this.props.currentKonsil.uploadPath) {
            return !this.props.pdfPreviewData || this.props.pdfPreviewData === "" ||
            (this.props.password === "" && this.props.comfortVersandPassword === "") ||
            !checkCdaSize(this.props.cdaSize, this.props.currentKonsil.konsilStatus, UploadPath.HPM) || this.props.konsilValidationStatus !== "OK";
        } else if (this.props.currentKonsil.uploadPath === UploadPath.KIM) {
            return !this.props.pdfPreviewData || this.props.pdfPreviewData === "" ||
            !checkCdaSize(this.props.cdaSize, this.props.currentKonsil.konsilStatus, UploadPath.KIM) || this.props.konsilValidationStatus !== "OK" ||
            !this.props.isKimConnected;
        }
        return true;
    }
    render() {
        return (
            <SimpleModalContainer isOpen={this.props.isOpen}>
                <Paper className="modal-wrapper reciever-pop">
                    <Box className="reciever-split">
                        <form className="form-content" noValidate autoComplete="off">
                            <KonsilValidationBadge
                                konsilValidationStatus = {this.props.konsilValidationStatus || null}
                                konsilValidationError = {this.props.konsilValidationError}
                            />
                            <Box className="modal-header">
                                {this.props.currentKonsil.uploadPath === UploadPath.KIM &&
                                    <ConnectionBadge
                                        dispatch_checkConnection = {this.props.dispatch_getKimConnectionStatus}
                                        uploadPath = 'KIM'
                                        isConnected = {this.props.isKimConnected}
                                        dispatch_setIsConnected = {this.props.dispatch_setIsKimConnected}
                                    />
                                }                              
                                <h2>Rückfrage beantworten</h2>
                            </Box>                          
                            <Box className="modal-content">
                               
                                {(this.props.currentKonsil.uploadPath === UploadPath.HPM || !this.props.currentKonsil.uploadPath) &&
                                    <>
                                        <CdaSizeComponent size={this.props.cdaSize} konsilStatus={this.props.currentKonsil.konsilStatus} uploadPath = {UploadPath.HPM} limit = {KonsilLimitSizes.HPM_RUECKFRAGE}/>
                                        <Typography className="last-reciver-label pad-bottom" variant="body1" color="inherit">
                                            Eine Zusammenfassung des Konsilauftrags wurde erstellt. Bitte überprüfen
                                            Sie den Konsilauftrag (.pdf) auf Richtigkeit.
                                        </Typography>
                                        <ZertifikatPasswortComponent
                                            password={(this.props.isComfortVersand && this.props.comfortVersandPassword !== "" && !this.props.hasPasswordChanged) ? this.props.comfortVersandPassword : this.props.password}
                                            handlePasswordChange={this.props.handlePasswordChange}
                                        />
                                        <Tooltip
                                            title = "Passwort für die aktuelle Sitzung speichern"
                                            placement = 'top'
                                        >
                                            <FormControlLabel
                                                control =
                                                    {<Checkbox
                                                        name="comfort-versand-checkbox"
                                                        checked = {this.props.isComfortVersand}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            if (!event.target.checked)
                                                                this.props.dispatch_setComfortVersandPassword("");
                                                            this.props.dispatch_setIsComfortVersand(!this.props.isComfortVersand);
                                                        }}
                                                    />}
                                                label={<Typography variant="body2" color="inherit">Passwort merken</Typography>}
                                            />
                                        </Tooltip>
                                    </>
                                }
                              
                                {this.props.currentKonsil.uploadPath === UploadPath.KIM &&
                                    <>
                                        <Typography className="failure last-reciver-label" variant="body1">
                                            Bereiten Sie Ihr persönliches eHBA vor. Sie werden aufgefordert, den Konsilauftrag damit zu unterschreiben.
                                        </Typography>         
                                        <CdaSizeComponent size={this.props.cdaSize} konsilStatus={this.props.currentKonsil.konsilStatus} uploadPath = {UploadPath.KIM} limit = {KonsilLimitSizes.KIM_RUECKFRAGE}/>
                                        <Typography className="last-reciver-label pad-bottom" variant="body1" color="inherit">
                                            Eine Zusammenfassung des Konsilauftrags wurde erstellt. Bitte überprüfen
                                            Sie den Konsilauftrag (.pdf) auf Richtigkeit.
                                        </Typography>
                                    </>
                                                                    
                                }
                                 <SignatureDialogContainer
                                    isOpen = {this.props.isSignatureDialogOpen}
                                    handleClose = {this.props.handleSignatureDialogClose}
                                    tiConnectorEvent = {this.props.tiConnectorEvent}
                                    forSettings = {false}
                                    triggerSendOnSignSuccess = {this.props.handleVersenden}
                                />
                            </Box>
                            <Box className="modal-footer">
                                <Button
                                    variant="text"
                                    onClick={() => {
                                        this.props.dispatch_setCdaSize(0);
                                        this.props.toggleReplyPopup();
                                        this.props.resetPdfPreview();
                                    }}
                                >
                                    Zurück
                                </Button>
                                <Button variant="contained" color="primary"
                                        disabled = {this.isSendButtonDisabled()}
                                        onClick={() => {
                                            if (this.props.currentKonsil.uploadPath === UploadPath.KIM) {
                                                this.props.handleSignatureDialogOpen();
                                            }
                                            if (this.props.currentKonsil.uploadPath === UploadPath.HPM || !this.props.currentKonsil.uploadPath) {
                                                this.props.handleVersenden();
                                                this.props.handleClearPassword();
                                                if (this.props.isComfortVersand && (this.props.comfortVersandPassword === "" || this.props.hasPasswordChanged))
                                                    this.props.dispatch_setComfortVersandPassword(this.props.password);
                                            }
                                            // this.props.dispatch_setCdaSize(0);
                                            // this.props.resetPdfPreview();                                          
                                        }}>
                                    Absenden 
                                </Button>
                            </Box>
                        </form>
                        <PdfInline pdfData={this.props.pdfPreviewData} isPdfLoading = {this.props.isPdfLoading} header = "Zusammenfassung Konsilauftrag"/>
                    </Box>
                </Paper>
            </SimpleModalContainer>
        );
    }
}
