import React, {useState} from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, {TreeItemProps} from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import {AssignmentReturn, Folder, FolderOpen, More} from '@material-ui/icons';
import {Box, Divider, IconButton, Popover, Tooltip} from '@material-ui/core';

type StyledTreeItemProps = TreeItemProps & {
    labelText: string;
    showThesaurus: boolean;
    thesaurusText: string[];
};

function StyledTreeItem(props: StyledTreeItemProps) {
    const {labelText, showThesaurus, thesaurusText, ...other} = props;
    const [isPopoverOpened, setIsPopoverOpened] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    function onHandleItemClick(event) {
        event.preventDefault();
        event.stopPropagation();
        setIsPopoverOpened(true)
        setAnchorEl(event.currentTarget);
    }

    function onHandleItemClose(event) {
        event.preventDefault();
        event.stopPropagation();
        setIsPopoverOpened(false)
    }

    return (
        <TreeItem
            label={
                <div className="flex-row">
                    <Typography variant="body2" className="">
                        {labelText}
                    </Typography>
                    {
                        showThesaurus &&
                        <>
                            <Tooltip title="Thesaurus-Info" placement="right">
                                <IconButton
                                    aria-label="more"
                                    onClick={(event) => onHandleItemClick(event)}
                                    size="small"
                                    className="icd-more">
                                    <More/>
                                </IconButton>
                            </Tooltip>
                            <Popover
                                open={isPopoverOpened}
                                onClose={(event) => onHandleItemClose(event)}
                                anchorEl={anchorEl}
                                className="thesaurus-popover"
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Typography variant="subtitle2" className="thesaurus-title">Thesaurus</Typography>
                                <Divider/>
                                <ul className="thesaurus-list">
                                    {thesaurusText.map((item, idx) => {
                                        return (
                                            <li key={idx}>
                                                {item}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </Popover>
                        </>
                    }
                </div>
            }
            {...other}
        />
    );
}


type BuildTreeProps = {
    icd10Data: {
        kapitelData: string[],
        kapitelVersion: string,
        kapitelYear: string,
        oid: string,
        isIcd10Loading: boolean,
        loadingError: string,
        krankheitBeginnYear: string,
    };
    addDiagnose: (code: string, diagnose: string, oid: string) => void;
    diagnoseSearchRequest: string;
    icd10SearchResult: string[];
    isSearch: boolean;
    bottom: number;
};

function BuildTree(props: BuildTreeProps) {
    const {icd10Data, addDiagnose, diagnoseSearchRequest, icd10SearchResult, isSearch, bottom} = props;
    let showThesaurus: boolean = false;
    let thesaurusText: string[] = [];

    function onItemLabelClick(code: string, bezeichnung: string, handover: boolean) {
        if (handover) {
            addDiagnose(code, bezeichnung, icd10Data.oid);
        }
    }

    function renderTree(treeElement: any) {
        let nested: string[];
        if (treeElement.gruppen) {
            nested = treeElement.gruppen;
        } else {
            nested = treeElement.diagnosen;
        }

        let handover: boolean = false;
        if (treeElement.abrechenbar) {
            handover = true;
        }

        showThesaurus = false;
        thesaurusText = [];

        if ((treeElement.thesaurus) && (treeElement.thesaurus.length > 0)) {
            showThesaurus = true;
            thesaurusText = treeElement.thesaurus;
        }
        return (
            <StyledTreeItem
                nodeId={treeElement.code}
                labelText={
                    treeElement.nummer ? `${treeElement.nummer}. ${treeElement.code} \u2022 ${treeElement.bezeichnung}` :
                        `${treeElement.code} \u2022 ${treeElement.bezeichnung}`
                }
                key={treeElement.code}
                showThesaurus={showThesaurus}
                thesaurusText={thesaurusText}
                onLabelClick={() => onItemLabelClick(treeElement.code, treeElement.bezeichnung, handover)}
            >
                {
                    nested.length > 0 ?
                        nested.map(item => renderTree(item)) :
                        null
                }
            </StyledTreeItem>
        )
    }

    return (
        icd10Data.loadingError === "" ?
            !isSearch ?
                <TreeView
                    defaultExpanded={['1']}
                    className="icd10-tree"
                    defaultCollapseIcon={<Folder/>}
                    defaultExpandIcon={<FolderOpen/>}
                    defaultEndIcon={<AssignmentReturn/>}
                >
                    {
                        diagnoseSearchRequest === "" ?
                            icd10Data.kapitelData.map((item: string) => {
                                return (renderTree(item))
                            }) :
                            (icd10SearchResult.length > (bottom * 50) + 50) ? icd10SearchResult.slice(0, (bottom * 50) + 50).map((item: string) => {
                                    return (renderTree(item))
                                })
                                :
                                icd10SearchResult.map((item: string) => {
                                    return (renderTree(item))
                                })
                    }
                </TreeView>
                :
                <Box className="icd-load">
                    <Typography>
                        Die Suche läuft, bitte warten...
                    </Typography>
                    <CircularProgress size={50}/>
                </Box> :
            <Typography className="failure icd-faliure">{`${icd10Data.loadingError} Andernfalls ist das Anlegen einer Diagnose nicht möglich.`}</Typography>
    );
}

interface IState {
    icd10SearchResult: any[],
    bottom: number;
}

interface Icd10TreeViewProps {
    icd10Data: {
        kapitelData: string[],
        kapitelVersion: string,
        kapitelYear: string,
        oid: string,
        isIcd10Loading: boolean,
        loadingError: string,
        krankheitBeginnYear: string,
    };
    addDiagnose: (code: string, diagnose: string, oid: string) => void;
    diagnoseSearchRequest: string;
    isSearch: boolean;
    stopSearch: () => void;
    bottom: number;
}

export default class Icd10TreeView extends React.Component<Icd10TreeViewProps, IState> {
    constructor(props) {
        super(props)
        this.state = {
            icd10SearchResult: [],
            bottom: this.props.bottom,
        }
        this.getIcd10SearchResult = this.getIcd10SearchResult.bind(this)
        this.treeSearch = this.treeSearch.bind(this);

    }

    componentDidUpdate(prevProps: Readonly<Icd10TreeViewProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.diagnoseSearchRequest !== this.props.diagnoseSearchRequest) {
            //this.getIcd10SearchResult();
            this.setState(
                {
                    icd10SearchResult: [],
                    bottom: 0,
                },
                this.getIcd10SearchResult
            )
        }
        if (prevProps.bottom !== this.props.bottom) {
            this.setState({
                bottom: this.props.bottom,
            })
        }
    }

    getIcd10SearchResult() {
        this.props.icd10Data.kapitelData.forEach((item) => {
            this.treeSearch(item)
        })
        this.props.stopSearch();
    }

    private treeSearch(treeElement: any) {
        let nested: string[];
        if (treeElement.gruppen) {
            nested = treeElement.gruppen;
        } else {
            nested = treeElement.diagnosen;
        }
        let checkThesaurus: boolean = false;

        // check if search request is in thesaurus
        if (treeElement.thesaurus && treeElement.thesaurus.length > 0 && this.props.diagnoseSearchRequest.length > 0) {
            for (let item of treeElement.thesaurus) {
                if (item.toLowerCase().includes(this.props.diagnoseSearchRequest)) {
                    checkThesaurus = true;
                    break;
                }
            }
        }
        if (treeElement.code.toLowerCase().includes(this.props.diagnoseSearchRequest) ||
            (this.props.diagnoseSearchRequest.length > 0 && treeElement.bezeichnung.toLowerCase().includes(this.props.diagnoseSearchRequest)) ||
            (checkThesaurus)) {
            this.setState(
                state => ({
                    icd10SearchResult: state.icd10SearchResult.concat([treeElement])
                }))
        } else {
            nested.length > 0 && nested.map(item => this.treeSearch(item))
        }
    }

    render() {
        return (
            (!this.props.icd10Data.isIcd10Loading) ?
                <BuildTree
                    icd10Data={this.props.icd10Data}
                    addDiagnose={this.props.addDiagnose}
                    diagnoseSearchRequest={this.props.diagnoseSearchRequest}
                    icd10SearchResult={this.state.icd10SearchResult}
                    isSearch={this.props.isSearch}
                    bottom={this.state.bottom}
                /> :
                <Box className="icd-load">
                    <Typography>
                        {`ICD10 Katalog des Jahres ${this.props.icd10Data.krankheitBeginnYear} wird geladen`}
                    </Typography>
                    <CircularProgress size={50}/>
                </Box>
        )
    }
}
