import React from 'react';
import {connect, ConnectedProps} from 'react-redux';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, IconButton, Box, Button} from '@material-ui/core';
import { RootState } from '../../redux/reducers';
import { Add, CheckCircle, Replay } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import HPMInstallationComponent from '../initalisation/hpm_installation';
import TeleScanUpdateComponent from '../initalisation/telescan_update';
import { withHpmInstallMethods, WithHpmInstallMethodsProps } from '../../utils';
import { updateModul, setHpmUpdateSteps, setTeleScanUpdateSteps } from '../../redux/actions/update_actions';
import { failure } from '../../redux/actions';
import { Failure, UpdateStepStatusType, UploadPath } from 'telescan-core';


export interface UpdateRow {
    modul: string;
    installierteVersion: string;
    verfuegbareVersion: string;
    updateVerfuegbar: boolean | undefined;
    teleScanVerwalted: boolean;
}

interface IState {
    isUpdatingTeleScan: boolean;
}

interface IProps {
    data: UpdateRow[],
}

const mapStateToProps = (state: RootState, ownProps: IProps) => ({
    initialisationStatus: state.user.initialisationStatus,
    hpmUpdateSteps: state.update.hpmUpdateSteps,
    teleScanUpdateSteps: state.update.teleScanUpdateSteps,
    initialisedServices: state.general.initialisedServices,
    ...ownProps
})

const mapDispatchToProps = {
    dispatch_failure: (id: string, message: Failure) => failure(id, message),
    dispatch_updateModul: (modulName: string) => updateModul(modulName),
    dispatch_setHpmUpdateSteps: (hpmUpdateSteps: {step: string, status: keyof typeof UpdateStepStatusType, progress: string, message: string}[]) => setHpmUpdateSteps(hpmUpdateSteps),
    dispatch_setTelescanUpdateSteps: (teleScanpdateSteps: {step: string, status: keyof typeof UpdateStepStatusType, progress: string, message: string}[]) => setTeleScanUpdateSteps(teleScanpdateSteps),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>
type Props = TPropsFromRedux & WithHpmInstallMethodsProps

export class UpdateTable extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isUpdatingTeleScan: false,
        };

        this.handleClickUpdateTelescan = this.handleClickUpdateTelescan.bind(this);
    }

    private moduleNamesMap = new Map([
        ["teleScan", "TeleScan"],
        ["hpm", "HPM"]
    ])

    handleClickUpdateTelescan(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        event.stopPropagation();
        this.props.dispatch_updateModul("TELESCAN").then(() => 
            this.setState({
                isUpdatingTeleScan: true
            })
        );
    }

    render() {
        let updateTableRows: UpdateRow[];
        if (!this.props.initialisedServices.includes(UploadPath.HPM)) {
            updateTableRows = this.props.data.filter(item => item.modul !== "hpm");
        } else updateTableRows = this.props.data;
        return (
            <>
                <TableContainer className="zertifikate-table">
                    <Table className="table-connector" stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Software</TableCell>
                                <TableCell>Installiert</TableCell>
                                <TableCell>Verfügbar</TableCell>
                                <TableCell>TeleScan-Verwaltet</TableCell>
                                <TableCell align="right">Aktualisierung</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {updateTableRows.map(rowData => (
                            <TableRow key={rowData.modul}>
                                <TableCell>{this.moduleNamesMap.get(rowData.modul)}</TableCell>
                                <TableCell>{rowData.installierteVersion}</TableCell>
                                <TableCell>{rowData.verfuegbareVersion}</TableCell>
                                <TableCell>{(rowData.teleScanVerwalted)? "JA": "NEIN"}</TableCell>
                                <TableCell align="right">
                                    {((rowData.updateVerfuegbar === true && rowData.modul === "teleScan") || (rowData.updateVerfuegbar === true && rowData.modul === "hpm" && rowData.installierteVersion !== "unbekannt")) &&
                                    <Tooltip title="Update verfügbar" placement="top">
                                        <IconButton
                                            type="submit"
                                            size="small"
                                            className=""
                                            aria-label="Aktualisieren"
                                            onClick={(event) => {
                                                switch (rowData.modul) {
                                                    case ("teleScan"):
                                                        return this.handleClickUpdateTelescan(event)
                                                    case ("hpm"):
                                                        return this.props.handleClickUpdateHpm(event)
                                                    default:
                                                        return 
                                                }
                                            }}
                                        >
                                            <Replay/>
                                        </IconButton>
                                    </Tooltip>
                                    }
                                    {(rowData.updateVerfuegbar === false) && 
                                    <Tooltip title="Aktuell" placement="top">
                                        <span className="up-to-date">
                                            <CheckCircle/>
                                        </span>
                                    </Tooltip>
                                    }
                                    {(rowData.updateVerfuegbar !== false && rowData.updateVerfuegbar !== true) && 
                                    <Tooltip title="Bitte aktualisieren Sie das Modul in Ihrem PVS." placement="top">
                                        <span>
                                            <InfoIcon/>
                                        </span>
                                    </Tooltip>
                                    }
                                    {(rowData.modul === "hpm" && rowData.installierteVersion === "unbekannt" 
                                        && this.props.initialisationStatus.standalone === true) && 
                                    <Tooltip title="HPM installieren" placement="top">
                                         <IconButton
                                            type="submit"
                                            size="small"
                                            className=""
                                            aria-label="HPM installieren"
                                            onClick={this.props.handleClickInstallHpm}
                                        >
                                            <Add/>
                                        </IconButton>
                                    </Tooltip>
                                    }
                                </TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* {(this.props.isInstallingHpm || (this.props.hpmUpdateSteps.length > 0 && this.props.hpmUpdateSteps[this.props.hpmUpdateSteps.length-1].status !== "DONE")) && */}
                {((this.props.hpmUpdateSteps.length > 0 && this.props.hpmUpdateSteps[this.props.hpmUpdateSteps.length-1].status !== "DONE")) &&
                <Box className="hpm-installation-card pad-bottom">
                    <HPMInstallationComponent steps={this.props.hpmUpdateSteps} context="SETTINGS"/>
                    {this.props.hpmUpdateSteps.some((item, index) => item.status === "ERROR") &&
                        <Tooltip title="Ausblenden" placement="top">
                            <Button variant="contained" color="primary" size="small" className="btn-right"
                                onClick={() => {
                                    let newHpmUpdateSteps = [...this.props.hpmUpdateSteps];
                                    newHpmUpdateSteps[newHpmUpdateSteps.length-1].status = "DONE";
                                    this.props.dispatch_setHpmUpdateSteps(newHpmUpdateSteps);
                                    //this.props.setInstallingHpmFalse();
                                }}>
                                OK
                            </Button>
                        </Tooltip>
                    }
                </Box>
                }
                {/* {(this.state.isUpdatingTeleScan || (this.props.teleScanUpdateSteps.length > 0 && this.props.teleScanUpdateSteps[this.props.teleScanUpdateSteps.length-1].status !== "DONE")) && */}
                {((this.props.teleScanUpdateSteps.length > 0 && this.props.teleScanUpdateSteps[this.props.teleScanUpdateSteps.length-1].status !== "DONE")) &&
                <Box className="hpm-installation-card pad-bottom">
                    <TeleScanUpdateComponent steps={this.props.teleScanUpdateSteps}/>
                    {this.props.teleScanUpdateSteps.some((item, index) => item.status === "ERROR") &&
                        <Tooltip title="Ausblenden" placement="top">
                            <Button variant="contained" color="primary" size="small" className="btn-right"
                                onClick={() => {
                                    let newTeleScanUpdateSteps = [...this.props.teleScanUpdateSteps];
                                    newTeleScanUpdateSteps[newTeleScanUpdateSteps.length-1].status = "DONE";
                                    this.props.dispatch_setTelescanUpdateSteps(newTeleScanUpdateSteps);
                                    // this.setState({
                                    //     isUpdatingTeleScan: false
                                    // })
                                }}>
                                OK
                            </Button>
                        </Tooltip>
                    }
                </Box>
                }
            </>
        );
    }
}

export const UpdateTableContainer = connector(withHpmInstallMethods(UpdateTable));
export default UpdateTableContainer;

