import React from 'react';
import {ViewText, ViewTextHtml} from './view_text';
import {Box, Divider, Paper, Typography} from '@material-ui/core'
import {RootState} from "../../../redux/reducers";
import {connect, ConnectedProps} from "react-redux";
import {
    assembleAtopie,
    assembleBeschwerdeDauer,
    assembleBeschwerdeVerlauf,
    assembleMalignitaetText,
    displayAttachment,
    getBeschwerde
} from '../../../utils/utils';
import {InsertDriveFile} from '@material-ui/icons';
import {downloadAttachment, failure, setSuccessMessage} from '../../../redux/actions';
import { Failure } from 'telescan-core';


const mapStateToProps = (state: RootState) => ({
    currentKonsil: state.konsile.current_konsil,
    currentKonsilPatient: state.konsile.current_konsil?.konsilPatient,
    attachments: state.image_attachment.attachments,
})

const mapDispatchToProps = {
    downloadAttachment: (attachment_id: string) => downloadAttachment(attachment_id),
    dispatch_failure: (id: string, message: Failure) => failure(id, message),
    dispatch_setSuccessMessage: (id: string, message: string) => setSuccessMessage(id, message),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>


export class ViewAnamneseContent extends React.Component<TPropsFromRedux> {

    render() {
        return (
            <Box className="scroll-box">
                <Box className="data-content">
                    <ViewText
                        labelVariant='subtitle1'
                        label='Malignität Verdacht'
                        text={this.props.currentKonsil.befund.malignitaet ? "JA": "NEIN"}
                    />
                    {(this.props.currentKonsil.befund.aenderungForm || this.props.currentKonsil.befund.aenderungFarbe || this.props.currentKonsil.befund.aenderungGroesse) &&
                        <ViewText
                            labelVariant='subtitle1'
                            label=''
                            text={assembleMalignitaetText(this.props.currentKonsil.befund.aenderungForm, this.props.currentKonsil.befund.aenderungFarbe, this.props.currentKonsil.befund.aenderungGroesse, "")}
                        />
                    }
                    <ViewText
                        labelVariant='subtitle1'
                        label='Änderung des Aussehens'
                        text={this.props.currentKonsil.befund.aenderungAussehen !== "" ? this.props.currentKonsil.befund.aenderungAussehen : "Keine" }
                    />
                    <Divider/>
                    <ViewText
                        labelVariant='subtitle1'
                        label='Beschwerden'
                        text={getBeschwerde(this.props.currentKonsil.anamnese)}
                    />
                     <ViewText
                        labelVariant='body1'
                        label='Weitere Beschwerdeangaben'
                        text={(this.props.currentKonsil.anamnese.beschwerdeFreitext) ? this.props.currentKonsil.anamnese.beschwerdeFreitext : "-"}
                    />
                    <Divider/>
                    <ViewText
                        labelVariant='subtitle1'
                        label='Beschwerdedauer'
                        text={assembleBeschwerdeDauer(this.props.currentKonsil.anamnese.beschwerdeDauer, this.props.currentKonsil.anamnese.beschwerdeSonstigeDauer)}
                    />
                    <Divider/>
                    <ViewText
                        labelVariant='subtitle1'
                        label='Beschwerdeverlauf'
                        text={assembleBeschwerdeVerlauf(this.props.currentKonsil.anamnese.verlaufUnveraendert, this.props.currentKonsil.anamnese.verlaufZunehmend, this.props.currentKonsil.anamnese.verlaufRezidivierend)}
                    />
                    <ViewText
                        labelVariant='body1'
                        label='Veränderung durch externe Faktoren'
                        text={(this.props.currentKonsil.anamnese.veraenderungAuessereFaktorenFreitext) ? this.props.currentKonsil.anamnese.veraenderungAuessereFaktorenFreitext : "-"}
                    />
                    <Divider/>
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                        Allergien
                    </Typography>
                    {/*
                    <ViewText
                        labelVariant='body1'
                        label='Sofortreaktionen'
                        text={(this.props.currentKonsilPatient?.allergien?.sofortReaktionen) ? this.props.currentKonsilPatient?.allergien?.sofortReaktionen : "-"}
                    />
                    */}
                      <ViewTextHtml
                        labelVariant='subtitle1' 
                        label='Sofortreaktionen'
                        textHtml = {this.props.currentKonsilPatient?.allergien?.sofortReaktionenHtml ? this.props.currentKonsilPatient?.allergien?.sofortReaktionenHtml : "-"}
                    />
                    {/*
                    <ViewText
                        labelVariant='body1'
                        label='Spätreaktionen'
                        text={(this.props.currentKonsilPatient?.allergien?.spaetReaktionen) ? this.props.currentKonsilPatient?.allergien?.spaetReaktionen : "-"}
                    />
                    */}
                    <ViewTextHtml
                        labelVariant='subtitle1' 
                        label='Spätreaktionen'
                        textHtml = {this.props.currentKonsilPatient?.allergien?.spaetReaktionenHtml ? this.props.currentKonsilPatient?.allergien?.spaetReaktionenHtml: "-"}
                    />
                    <Divider/>
                    <ViewText
                        labelVariant='subtitle1'
                        label='Maligne Hauttumoren'
                        text={(this.props.currentKonsilPatient?.familienanamnese?.hautmelanom) ? "JA" : "NEIN"}
                    />
                    <Divider/>
                    <ViewText
                        labelVariant='subtitle1'
                        label='Atopie'
                        text={assembleAtopie(this.props.currentKonsilPatient?.familienanamnese?.heuschnupfen || false, this.props.currentKonsilPatient?.familienanamnese?.asthma || false, this.props.currentKonsilPatient?.familienanamnese?.neurodermites || false)}
                    />
                    <Divider/>
                    <ViewText
                        labelVariant='subtitle1'
                        label='Psoriasis'
                        text={(this.props.currentKonsilPatient?.familienanamnese?.psoriasis) ? "JA" : "NEIN"}
                    />
                    <Divider/>
                    <ViewText
                        labelVariant='subtitle1'
                        label='Weitere Erkrankungen'
                        text={(this.props.currentKonsilPatient.familienanamnese?.weitereFamilienerkrankungen) ? this.props.currentKonsilPatient.familienanamnese.weitereFamilienerkrankungen : "-"}
                    />
                    <Divider/>
                </Box>
                {/* <Tooltip title="Anhang" placement="right">
                    <Button className="desktop-hide slim btn-anamnese-attachment" variant="contained"
                            color="primary" // onClick={this.showSideClick}
                    >
                        <AttachFile />
                    </Button>
                </Tooltip> */}
                {/* </Box>
                <Box className={"scroll-box-side " + (this.state.shouldShowSide ? 'active' : '')}> */}
                <Box className="side-view">
                    {/* <Button className="desktop-hide slim mobile-close" //onClick={this.closeSideClick}
                    >
                        <Close />
                    </Button> */}
                    <Box className="attachment-header">
                        <Typography className="txt-header" variant="subtitle1" color="inherit">
                            Anhänge
                        </Typography>
                    </Box>
                    {this.props.currentKonsil?.attachments?.map(attachment =>
                        <Paper
                            key={attachment.id}
                            className="attachment pad"
                            onClick={() => {
                                displayAttachment(
                                    attachment.id || "",
                                    this.props.attachments,
                                    this.props.downloadAttachment,
                                    this.props.dispatch_failure,
                                    this.props.dispatch_setSuccessMessage
                                )
                            }}
                        >
                            <InsertDriveFile/>
                            <Typography variant="body1" color="inherit">
                                {attachment.originalFilename}
                            </Typography>
                        </Paper>
                    )}
                </Box>
            </Box>
        );
    }
}

export const ViewAnamneseContentContainer = connector(ViewAnamneseContent);
export default ViewAnamneseContentContainer;
