import {DiagnoseSicherheit, Failure, ICDService, Krankheit, Seitenlokation} from "telescan-core";
import React from "react";
import {ViewText} from '../../viewComponents/view_text';
import {DatePicker} from "../../../elements/date_picker";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
    Typography,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';
import {Delete, Edit} from "@material-ui/icons";
import SimpleModalContainer from "../../../elements/simple_modal";
import Icd10TreeView from '../../../elements/icd10_treeView';
import {RootState} from "../../../../redux/reducers";
import {connect, ConnectedProps} from "react-redux";
import { failure } from "../../../../redux/actions";
import uuid from 'uuid';
import { roundDateToDay } from "../../../../utils";

interface IKrankheitHausarztProps {
    diagnose: Krankheit,
    delete: (k) => void,
    hasError: boolean;
    isViewOnly: boolean;
    onViewClick?: (event) => void;
    onEditClick?: (event) => void;
    style?: any;
    role: string;
}


export class KrankheitHausarztComponent extends React.Component<IKrankheitHausarztProps> {
    render() {
        return (
            <>
                <Paper style={this.props.style}
                       className={"derma-diagnose-container" + (this.props.hasError ? " failure" : "") + (this.props.isViewOnly ? " view-only" : "")}
                       elevation={1}
                       onClick={(this.props.isViewOnly) ? this.props.onViewClick : () => {
                       }}
                >
                    <Box className="flex-row-center">
                        <Tooltip
                            title={this.props.diagnose.dauerdiagnose ? "Dauerdiagnose": "Diagnose"}
                            placement="top"
                        >
                            <Typography className={this.props.diagnose.dauerdiagnose ? "dauer-diagnose": "dauer-diagnose-replace-icon"} variant="body1">
                                {this.props.diagnose.dauerdiagnose ? "DD": "D"}
                            </Typography>
                        </Tooltip>
                        <Typography className="bold" variant="subtitle1">
                            {this.props.diagnose?.diagnosecode}
                        </Typography>
                        <Tooltip
                            title={DiagnoseSicherheit[this.props.diagnose.diagnosesicherheit || "UNK"]}
                            placement="top">
                            <Typography className="diagnose-rank" variant="body1">
                                {this.props.diagnose.diagnosesicherheit || "?"}
                            </Typography>
                        </Tooltip>
                        {(this.props.diagnose.seitenlokalisation != null && this.props.diagnose.seitenlokalisation !== "UNK") &&
                        <Tooltip title={Seitenlokation[this.props.diagnose.seitenlokalisation || "UNK"]}
                                 placement="top">
                            <Typography className="diagnose-location" variant="body1">
                                {this.props.diagnose.seitenlokalisation || "?"}
                            </Typography>
                        </Tooltip>
                        }
                        {!this.props.isViewOnly &&
                        <>
                            <Tooltip title="Bearbeiten" placement="top">
                                <IconButton className="edit-diagnose" aria-label="delete" size="small"
                                            onClick={this.props.onEditClick}>
                                    <Edit/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Löschen" placement="top">
                                <IconButton className="delete-diagnose" aria-label="delete"
                                            size="small" //onClick={this.props.delete}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                this.props.delete(this.props.diagnose);
                                            }}>
                                    <Delete/>
                                </IconButton>
                            </Tooltip>
                        </>
                        }
                    </Box>
                    <Typography className="diagnose-text" variant="body1">
                        {this.props.diagnose.diagnoseAlternativtext ?
                        this.props.diagnose.diagnoseAlternativtext :
                        this.props.diagnose.diagnosetext || "???" }
                    </Typography>
                </Paper>
            </>
        )
    }
}


const mapStateToProps = (state: RootState) => ({
    role: state.user.role,
})

const mapDispatchToProps = {
    dispatch_failure: (id: string, failureObject: Failure) => failure(id, failureObject)
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

interface IKrankheitHausarztEditProps {
    krankheit: Krankheit;
    open: boolean;
    add: boolean;
    callback: (krankheit: Krankheit | null, add: boolean) => void;
    icd10Data: {
        kapitelData: string[],
        kapitelVersion: string,
        kapitelYear: string,
        oid: string,
        isIcd10Loading: boolean,
        loadingError: string,
        krankheitBeginnYear: string,
    };
    getIcd10Katalog: (date: number) => void;
    clearIcd10KatalogLoadingError?: () => void;
}

interface IState {
    krankheit: Krankheit;
    diagnoseCodeIcd10: string;
    diagnoseTextIcd10: string;
    diagnoseSearchRequest: string;
    diagnoseCodeError: string;
    typingTimeout: any;
    diagnoseSearchChar: string;
    isSearch: boolean;
    bottom: number;
    isStartZeitpunktNullflavor: boolean;
    isEndZeitpunktNullflavor: boolean;
    medicalStartDateSaved: number | undefined;
    medicalEndDateSaved: number | undefined;
}

export class KrankheitHausarztEditComponent extends React.Component<IKrankheitHausarztEditProps & TPropsFromRedux, IState> {
    constructor(props: IKrankheitHausarztEditProps & TPropsFromRedux) {
        super(props);
        this.state = {
            krankheit: props.krankheit,
            diagnoseCodeIcd10: "",
            diagnoseTextIcd10: "",
            diagnoseSearchRequest: "",
            diagnoseCodeError: "",
            typingTimeout: 0,
            diagnoseSearchChar: "",
            isSearch: false,
            bottom: 0,
            isStartZeitpunktNullflavor: false,
            isEndZeitpunktNullflavor: false,
            medicalStartDateSaved: undefined,
            medicalEndDateSaved: undefined,
        };
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.diagnoseFromIcd10TreeView = this.diagnoseFromIcd10TreeView.bind(this);
        this.timeOutMethod = this.timeOutMethod.bind(this);
        this.timehandleTreeSearch = this.timehandleTreeSearch.bind(this);
        this.setDiagnoseSearchRequest = this.setDiagnoseSearchRequest.bind(this);
        this.setDiagnoseSearchChar = this.setDiagnoseSearchChar.bind(this);
        this.stopSearch = this.stopSearch.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.checkNullFlavor();
    }

    componentDidUpdate(prevProps: Readonly<IKrankheitHausarztEditProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.krankheit !== this.props.krankheit) {
            this.setState({
                krankheit: this.props.krankheit,
            });
            this.checkNullFlavor();
        }
    }

    checkNullFlavor = () => {
        if (this.props.krankheit.startZeitpunktNullflavor === "UNK")
            this.setState({isStartZeitpunktNullflavor: true})
        else
            this.setState({isStartZeitpunktNullflavor: false});

        if (this.props.krankheit.endZeitpunktNullflavor === "UNK")
            this.setState({isEndZeitpunktNullflavor: true})
        else
            this.setState({isEndZeitpunktNullflavor: false});
    }

    timeOutMethod(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                  getUserInput: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => string,
                  evaluateUserInput: (val: string) => void, duration: number): void {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }
        let value: string = getUserInput(e);

        this.setState({
            typingTimeout: setTimeout(() => {
                evaluateUserInput(value);
            }, duration)
        });
    }

    timehandleTreeSearch(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
        this.timeOutMethod(e, this.setDiagnoseSearchChar, this.setDiagnoseSearchRequest, 500)
    }

    stopSearch() {
        this.setState({
            isSearch: false,
        })
    }

    setDiagnoseSearchChar(event): string {
        this.setState({
            diagnoseSearchChar: event.target.value.toLowerCase(),
            isSearch: true,
        })
        return event.target.value.toLowerCase();
    }

    setDiagnoseSearchRequest(code: string) {
        this.setState({
            diagnoseSearchRequest: code,
        })
    }

    setKrankheitStartDate = (date: Date) => {
        if (date != null) {
            this.setState({
                krankheit: {
                    ...this.state.krankheit,
                    startZeitpunkt: date.getTime()
                },
            });
            
        }
    }

    setKrankheitEndDate = (date: Date) => {
        if (date != null) {
            this.setState({
                krankheit: {
                    ...this.state.krankheit,
                    endZeitpunkt: date.getTime()
                }
            })
        } else {
            this.setState({
                krankheit: {
                    ...this.state.krankheit,
                    endZeitpunkt: undefined,
                }
            })
        }
    }

    setDiagnosisDate = (date: Date) => {
        if (date != null) {
            this.setState({
                krankheit: {
                    ...this.state.krankheit,
                    datumDiagnosestellung: date.getTime()
                },
            });  
            this.evaluateDiagnoseCodeInput(date.getTime(), this.state.krankheit.diagnosecode);         
        } else {
            this.setState({
                krankheit: {
                    ...this.state.krankheit,
                    datumDiagnosestellung: new Date().getTime(),
                }
            })
        }
    }

    diagnoseFromIcd10TreeView(diagnoseCode: string, diagnoseBezeichnung: string, icd1Oid: string) {
        this.setState({
            krankheit: {
                ...this.state.krankheit,
                diagnosecode: diagnoseCode,
                diagnosetext: diagnoseBezeichnung,
                icdKatalog: icd1Oid,
            },
            diagnoseCodeError: "",
        });
    }

    evaluateDiagnoseCodeInput = (date: number, code: string) => new Promise ((resolve, reject) => 
    {
        const icdService = new ICDService();
        icdService.validateIcd10DiagnoseCode(date, code).then(responce => {
            resolve("ok");
            if (responce.icd10.isValid === "true") {
                this.setState({
                    krankheit: {
                        ...this.state.krankheit,
                        diagnosetext: responce.icd10.bezeichnung,
                        icdKatalog: responce.icd10.oid,
                    },
                    diagnoseCodeError: "",
                })
            } else {
                this.setState({
                    diagnoseCodeError: "Der Diagnosecode ist ungültig. Bitte überprüfen Sie Ihre Angabe"
                })
            }
        })
            .catch(error => {
                reject("error");
                this.setState({
                    diagnoseCodeError: error
                })
            })
        }
    )

    handleKeyDown(event: React.KeyboardEvent) {
        if (!(document?.activeElement === document?.getElementById("diag-code") || document?.activeElement === document?.getElementById("diag-text") || document?.activeElement === document?.getElementById("diag-search"))) {
            const upperKey: string = event.key.toUpperCase();
            if (upperKey === 'A' || upperKey === 'G' || upperKey === 'V' || upperKey === 'Z') {
                event.preventDefault();
                this.setState({
                    krankheit: {
                        ...this.state.krankheit,
                        diagnosesicherheit: upperKey as keyof typeof DiagnoseSicherheit
                    }
                });
                document?.getElementById("diagnoseSicherheit_" + upperKey)?.focus();
            } else if (upperKey === 'L' || upperKey === 'R' || upperKey === 'B' || event.key === '?') {
                event.preventDefault();
                this.setState({
                    krankheit: {
                        ...this.state.krankheit,
                        seitenlokalisation: (event.key === "?") ? "UNK" : upperKey as "L" | "R" | "B"
                    }
                })
                document?.getElementById((event.key === "?") ? "diagnoseLokalisation_UNK" : "diagnoseLokalisation_" + upperKey)?.focus();
            }
        }
    }

    handleScroll = (e) => {
        const prevBottom = this.state.bottom;
        if (e.target.scrollTop >= e.target.scrollHeight - e.target.clientHeight - Math.floor(e.target.scrollTop) * 0.01) {
            this.setState({
                bottom: prevBottom + 1
            })
        }
    }

    handleSubmit() {
        if (this.state.krankheit.diagnosecode === "" || this.state.krankheit.diagnosecode === undefined || this.state.diagnoseCodeError || (this.props.icd10Data.loadingError !== "")) { //if diagnosen code empty or not valid, through message to user, that add/save diagnose not possible
            const failure = new Failure();
            failure.error = "Bitte fügen Sie einen gültigen Diagnosecode hinzu."
            this.props.dispatch_failure(uuid.v4(), failure);
        }
        else if (!this.state.krankheit.diagnosesicherheit) {
            const failure = new Failure();
            failure.error = "Bitte wählen Sie eine Diagnosesicherheit aus."
            this.props.dispatch_failure(uuid.v4(), failure);
        }
        else if (this.state.krankheit.diagnosesicherheit !== "Z" && (!this.state.krankheit.startZeitpunkt && !this.state.isStartZeitpunktNullflavor)) {
            const failure = new Failure();
            failure.error = "Bitte wählen Sie ein medizinisches Datum der Diagnose aus."
            this.props.dispatch_failure(uuid.v4(), failure);
        }
        else if (this.state.krankheit.diagnosesicherheit === "Z" && ((!this.state.krankheit.startZeitpunkt && !this.state.isStartZeitpunktNullflavor) || (!this.state.krankheit.endZeitpunkt && !this.state.isEndZeitpunktNullflavor))) {
            const failure = new Failure();
            failure.error = "Bitte wählen Sie ein medizinisches Datum der Diagnose aus."
            this.props.dispatch_failure(uuid.v4(), failure); 
        }
        else if ((this.state.krankheit.startZeitpunkt && roundDateToDay(this.state.krankheit.startZeitpunkt) > roundDateToDay(new Date().getTime())) ||
            (this.state.krankheit.endZeitpunkt && roundDateToDay(this.state.krankheit.endZeitpunkt) > roundDateToDay(new Date().getTime())) ||
            (this.state.krankheit.datumDiagnosestellung && roundDateToDay(this.state.krankheit.datumDiagnosestellung) > roundDateToDay(new Date().getTime()))
        ) {
            const failure = new Failure();
            failure.error = "Das Datum darf nicht in der Zukunft liegen."
            this.props.dispatch_failure(uuid.v4(), failure);
        }
        else if (this.state.krankheit.startZeitpunkt && this.state.krankheit.datumDiagnosestellung && roundDateToDay(this.state.krankheit.startZeitpunkt) > roundDateToDay(this.state.krankheit.datumDiagnosestellung)) {
            const failure = new Failure();
            failure.error = "Das medizinische Datum darf nur vor oder am Feststellungsdatum liegen."
            this.props.dispatch_failure(uuid.v4(), failure);
        }
        else if (this.state.krankheit.endZeitpunkt && this.state.krankheit.datumDiagnosestellung && roundDateToDay(this.state.krankheit.endZeitpunkt) > roundDateToDay(this.state.krankheit.datumDiagnosestellung)) {
            const failure = new Failure();
            failure.error = "Das medizinische Datum darf nur vor oder am Feststellungsdatum liegen."
            this.props.dispatch_failure(uuid.v4(), failure);
        }
        else {
            if (!this.state.krankheit.datumDiagnosestellung) {
                this.evaluateDiagnoseCodeInput(new Date().getTime(), this.state.krankheit.diagnosecode)
                .then(() => {
                    this.setState(
                        {krankheit: {
                            ...this.state.krankheit,
                            datumDiagnosestellung: new Date().getTime(),
                        }},
                        () => {
                            this.props.callback(this.state.krankheit, this.props.add);
                        }
                    );
                })
            } else
                this.props.callback(this.state.krankheit, this.props.add);
            this.setState({
                diagnoseSearchRequest: "",
            })
        }
    }

    render() {
        return (
            <SimpleModalContainer isOpen={this.props.open} additionalClassname="modal-add-data">
                <Paper className="modal-wrapper diagnose-modal" onKeyDown={this.handleKeyDown}>
                    <h2 className="modal-header">{this.props.add ? "Diagnose hinzufügen" : "Diagnose bearbeiten"}</h2>
                    <Box className="modal-content">
                        <Box className="left-side">
                            <form className="add-form lab-form" noValidate autoComplete="off">
                                <TextField
                                    required
                                    error={this.state.krankheit.diagnosecode == null || this.state.krankheit.diagnosecode === "" || this.state.diagnoseCodeError !== ""}
                                    id="diag-code"
                                    label="Diagnosecode"
                                    multiline
                                    minRows = {1}
                                    maxRows={4}
                                    value={this.state.krankheit.diagnosecode || ""}
                                    onChange={(event) => {
                                        this.setState({
                                            krankheit: {
                                                ...this.state.krankheit,
                                                diagnosecode: event.target.value,
                                            }
                                        })
                                    }}
                                    onBlur={() => {
                                        if (this.state.krankheit.diagnosecode === "")
                                            this.setState({
                                                krankheit: {
                                                    ...this.state.krankheit,
                                                    diagnosetext: "",
                                                }
                                            })
                                        else
                                            this.evaluateDiagnoseCodeInput(this.state.krankheit.datumDiagnosestellung || new Date().getTime(), this.state.krankheit.diagnosecode);                                     
                                    }}
                                />
                                <ViewText
                                    labelVariant='caption'
                                    label='Diagnosetext'
                                    hasError={this.state.diagnoseCodeError !== ""}
                                    text={this.state.diagnoseCodeError === "" ? this.state.krankheit.diagnosetext || "" : this.state.diagnoseCodeError}
                                />
                                <FormControl>
                                    <FormLabel component="legend">Diagnosesicherheit *</FormLabel>
                                    <ToggleButtonGroup
                                        color="primary"
                                        className="btn-group-select pad-top"
                                        aria-label="outlined primary button group"
                                        exclusive
                                        onChange={(_event, value) => {
                                            this.setState({
                                                krankheit: {
                                                    ...this.state.krankheit,
                                                    diagnosesicherheit: value
                                                }
                                            }) 
                                        }}
                                    >
                                        {
                                            Object.keys(DiagnoseSicherheit).map(key => // Hausarzt view, diagnosesicherheits options A, G, V, Z
                                                <Tooltip
                                                    key={key}
                                                    title={DiagnoseSicherheit[key]}
                                                    placement="top"
                                                >
                                                    <ToggleButton
                                                        value={key}
                                                        id={"diagnoseSicherheit_" + key}
                                                        className={this.state.krankheit.diagnosesicherheit === key ? 'active' : ''}
                                                    >
                                                        {key}
                                                    </ToggleButton>
                                                </Tooltip>
                                            )
                                        }
                                    </ToggleButtonGroup>
                                </FormControl>
                                <FormControl>
                                    <FormLabel component="legend">Seitenlokalisation</FormLabel>
                                    <ToggleButtonGroup color="primary" className="btn-group-select pad-top"
                                                       aria-label="outlined primary button group"
                                                       exclusive
                                                       onChange={(event, value) => {
                                                           this.setState({
                                                               krankheit: {
                                                                   ...this.state.krankheit,
                                                                   seitenlokalisation: value as "L" | "R" | "B" | "UNK",
                                                               },
                                                           })
                                                       }}
                                    >
                                        {Object.keys(Seitenlokation).filter(item => (item !== "U" && item !== "A")).map(key =>
                                            <Tooltip key={key} title={Seitenlokation[key]} placement="top">
                                                <ToggleButton value={key} id={"diagnoseLokalisation_" + key}
                                                              className={(this.state.krankheit.seitenlokalisation === key ? 'active' : '')}>{(key !== "UNK") ? key : "?"}</ToggleButton>
                                            </Tooltip>
                                        )}
                                    </ToggleButtonGroup>
                                </FormControl>

                                <FormControl>
                                    <FormLabel component="legend" className = "pad-bottom">Medizinisches Datum</FormLabel>
                                    <Box className = "btn-group-select">
                                        <Box
                                            className = {this.state.isStartZeitpunktNullflavor ? "inactive": ""}
                                            onFocus = {() =>
                                                this.setState({
                                                    isStartZeitpunktNullflavor: false,
                                                    krankheit: {
                                                        ...this.state.krankheit,
                                                        startZeitpunktNullflavor: null,
                                                    }
                                                })
                                            }
                                        >
                                            <DatePicker
                                                isRequired = {true}
                                                label={this.state.krankheit.diagnosesicherheit !== "Z" ? this.state.krankheit.diagnosesicherheit === "G" || this.state.krankheit.diagnosesicherheit === "V" ? "seit": "am": "von"}
                                                id="krankheit_begin"
                                                inputLabelProps={{}}
                                                hasError={!this.state.isStartZeitpunktNullflavor}
                                                initialDate={this.state.krankheit.startZeitpunkt ? new Date(this.state.krankheit.startZeitpunkt) : null}
                                                onChange = {(date: Date) => this.setKrankheitStartDate(date)}
                                                isMaxDateNow = {true}
                                            />
                                        </Box>
                                        <FormControlLabel
                                            className = "diagnosedatum-toggle-button"
                                            control={
                                                <Checkbox
                                                    checked = {this.state.isStartZeitpunktNullflavor}
                                                    onChange = {() => {
                                                        this.setState(prevState => {
                                                            if (prevState.isStartZeitpunktNullflavor) {
                                                                return {
                                                                    krankheit: {
                                                                        ...this.state.krankheit,
                                                                        startZeitpunktNullflavor: undefined,
                                                                        startZeitpunkt: prevState.medicalStartDateSaved
                                                                    }
                                                                }
                                                            }
                                                            else {
                                                                this.setState({medicalStartDateSaved: prevState.krankheit.startZeitpunkt})
                                                                return {
                                                                    krankheit: {
                                                                        ...this.state.krankheit,
                                                                        startZeitpunktNullflavor: "UNK",
                                                                        startZeitpunkt: undefined,
                                                                    }                                                                  
                                                                }
                                                            }
                                                        })
                                                        this.setState({isStartZeitpunktNullflavor: !this.state.isStartZeitpunktNullflavor});
                                                    }}
                                                />
                                            }
                                            label="Datum unbekannt"
                                        />
                                    </Box>
                                    {this.state.krankheit.diagnosesicherheit === "Z" &&
                                        <Box className = "btn-group-select">
                                            <Box
                                                className = {this.state.isEndZeitpunktNullflavor ? "inactive": ""}
                                                onFocus = {() =>
                                                    this.setState({
                                                        isEndZeitpunktNullflavor: false,
                                                        krankheit: {
                                                            ...this.state.krankheit,
                                                            endZeitpunktNullflavor: null,
                                                        }
                                                    })
                                                }
                                            >
                                                <DatePicker
                                                    isRequired = {true}
                                                    label="bis"
                                                    id="krankheit_end"
                                                    inputLabelProps={{}}
                                                    hasError={!this.state.isEndZeitpunktNullflavor}
                                                    initialDate={this.state.krankheit.endZeitpunkt ? new Date(this.state.krankheit.endZeitpunkt) : null}
                                                    onChange={(date: Date) => this.setKrankheitEndDate(date)}
                                                    isMaxDateNow = {true}
                                                />
                                            </Box>
                                            <FormControlLabel
                                                className = "diagnosedatum-toggle-button"
                                                control={
                                                    <Checkbox
                                                        checked = {this.state.isEndZeitpunktNullflavor}
                                                        onChange = {() => {
                                                            this.setState(prevState => {
                                                                if (prevState.isEndZeitpunktNullflavor) {
                                                                    return {
                                                                        krankheit: {
                                                                            ...this.state.krankheit,
                                                                            endZeitpunktNullflavor: undefined,
                                                                            endZeitpunkt: prevState.medicalEndDateSaved
                                                                        }
                                                                    }
                                                                }
                                                                else {
                                                                    this.setState({medicalEndDateSaved: prevState.krankheit.endZeitpunkt})
                                                                    return {
                                                                        krankheit: {
                                                                            ...this.state.krankheit,
                                                                            endZeitpunktNullflavor: "UNK",
                                                                            endZeitpunkt: undefined,
                                                                        }
                                                                    }
                                                                }
                                                            })
                                                            this.setState({isEndZeitpunktNullflavor: !this.state.isEndZeitpunktNullflavor});
                                                        }}
                                                    />
                                                }
                                                label="Datum unbekannt"
                                            />
                                        </Box>
                                    }
                                    <FormLabel component="legend" className = "pad-bottom">Feststellungsdatum</FormLabel>
                                    <DatePicker
                                        isRequired = {true}
                                        label={"am"}
                                        id="diagnosis_date"
                                        inputLabelProps={{}}
                                        hasError = {true}
                                        initialDate={this.state.krankheit.datumDiagnosestellung ? new Date(this.state.krankheit.datumDiagnosestellung) : new Date()}
                                        onChange={(date: Date) => {
                                            this.props.getIcd10Katalog(date.getTime());
                                            this.setDiagnosisDate(date);
                                        }}
                                        isMaxDateNow = {true}
                                    />
                                </FormControl>
                                <FormControl className="diag-dauerdiagnose pad-top" component="fieldset">
                                    <FormLabel component="legend" className = "float-left">Dauerdiagnose *</FormLabel>
                                    <RadioGroup row aria-label="outdoor" name="outdoor"
                                                value={this.state.krankheit.dauerdiagnose ? "true" : "false"}
                                                onChange={event => 
                                                    this.setState({
                                                        krankheit: {
                                                            ...this.state.krankheit,
                                                            dauerdiagnose: event.target.value === "true"
                                                        }
                                                })}
                                    >
                                        <FormControlLabel value="true" control={<Radio/>} label="ja"/>
                                        <FormControlLabel value="false" control={<Radio/>} label="nein"/>
                                    </RadioGroup>
                                </FormControl>
                            </form>
                        </Box>
                        <Box className="right-side" onScroll={this.handleScroll}>
                            <Box className="icd-search-container">
                                {this.props.icd10Data.kapitelYear !== "" &&
                                    ((!this.state.krankheit.datumDiagnosestellung && new Date().getFullYear() > parseInt(this.props.icd10Data.kapitelYear)) ||
                                    (this.state.krankheit.datumDiagnosestellung && new Date(this.state.krankheit.datumDiagnosestellung).getFullYear() > parseInt(this.props.icd10Data.kapitelYear))) &&                                  
                                    <Typography className = 'failure' variant = 'body2' gutterBottom>
                                        Im ersten Quartal eines Jahres ist es möglich den ICD10 Katalog des Vorjahres zu nutzen. Bitte denken Sie an eine rechtzeitige Installation des neuen ICD10 Katalogs.
                                    </Typography> 
                                }                               
                                <TextField
                                    id="diag-search"
                                    onChange={this.timehandleTreeSearch}
                                    placeholder={`ICD10 ${this.props.icd10Data.kapitelYear === "" ? this.props.icd10Data.krankheitBeginnYear : this.props.icd10Data.kapitelYear} | Suche`}
                                    className="icd-search"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon/>
                                            </InputAdornment>
                                        )
                                    }}
                                >
                                </TextField>
                            </Box>
                            <Icd10TreeView /* Insert Icd10 catalog here */
                                icd10Data={this.props.icd10Data}
                                addDiagnose={this.diagnoseFromIcd10TreeView}
                                diagnoseSearchRequest={this.state.diagnoseSearchRequest}
                                isSearch={this.state.isSearch}
                                stopSearch={this.stopSearch}
                                bottom={this.state.bottom}
                            />
                            
                        </Box>
                    </Box>
                    <Box className="modal-footer">
                        <Button
                            variant="text"
                            color="primary"
                            onClick={() => {
                                this.setState({
                                    diagnoseCodeIcd10: "",
                                    diagnoseTextIcd10: "",
                                    diagnoseSearchChar: "",
                                    diagnoseSearchRequest: "",
                                    diagnoseCodeError: "",
                                });
                                this.props.clearIcd10KatalogLoadingError && this.props.clearIcd10KatalogLoadingError();
                                this.setState({ // set state back to previous if "Abbrechnen" is clicked
                                    krankheit: this.props.krankheit
                                });
                                this.checkNullFlavor();
                                this.props.callback(null, this.props.add);
                            }}
                        >
                            Abbrechen
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick = {this.handleSubmit}
                        >
                            {this.props.add ? "Hinzufügen" : "Speichern"}
                        </Button>
                    </Box>
                </Paper>
            </SimpleModalContainer>
        )
    }
}

export const KrankheitHausarztEditComponentContainer = connector(KrankheitHausarztEditComponent);

interface IKrankheitHausarztViewProps {
    krankheit: Krankheit;
    openView: boolean;
    handleClose: () => void;
}

export class KrankheitHausarztViewComponent extends React.Component<IKrankheitHausarztViewProps> {

    render() {
        let medizinischesDatumVon: string = "";
        let medizinischesDatumBis: string = "";
        if (this.props.krankheit.diagnosesicherheit) {
            const startZeitpunkt: string = this.props.krankheit.startZeitpunkt ? new Date(this.props.krankheit.startZeitpunkt).toLocaleString().split(',')[0]: "";
            if (this.props.krankheit.diagnosesicherheit === "G" || this.props.krankheit.diagnosesicherheit === "V") {
                medizinischesDatumVon = !this.props.krankheit.startZeitpunktNullflavor ? `seit: ${startZeitpunkt}`: "seit: unbekannt";
            }
            else if (this.props.krankheit.diagnosesicherheit === "A") {
                medizinischesDatumVon = !this.props.krankheit.startZeitpunktNullflavor ? `am: ${startZeitpunkt}`: "am: unbekannt";
            }
            else if (this.props.krankheit.diagnosesicherheit === "Z") {
                if (this.props.krankheit.endZeitpunkt && !this.props.krankheit.endZeitpunktNullflavor) {
                    const endZeitpunkt: string = new Date(this.props.krankheit.endZeitpunkt).toLocaleString().split(',')[0];
                    medizinischesDatumVon = !this.props.krankheit.startZeitpunktNullflavor ? `von: ${startZeitpunkt}`: "von: unbekannt";
                    medizinischesDatumBis = `bis: ${endZeitpunkt}`;
                }
                else {
                    medizinischesDatumVon = !this.props.krankheit.startZeitpunktNullflavor ? `von: ${startZeitpunkt}`: "von: unbekannt";
                    medizinischesDatumBis = "bis: unbekannt";
                }
            }
        }
        else {
            medizinischesDatumVon = "-";
        }
        return (
            <SimpleModalContainer isOpen={this.props.openView} additionalClassname="modal-add-data">
                <Paper className="modal-wrapper view-krankheit">
                    <h2 className="modal-header">Diagnose</h2>
                    <Box className="modal-content data-content">
                        <Box className="txt-grid">
                            <ViewText
                                labelVariant='caption'
                                label='Diagnosecode'
                                text={this.props.krankheit.diagnosecode || "-"}
                            />
                            {this.props.krankheit.diagnoseAlternativtext &&
                                <ViewText
                                    labelVariant='caption'
                                    label='Diagnosetext'
                                    text={this.props.krankheit.diagnoseAlternativtext}
                                />
                            }
                            <ViewText
                                labelVariant='caption'
                                label='Diagnosetext (ICD10)'
                                text={this.props.krankheit.diagnosetext || "-"}
                            />
                            <ViewText
                                labelVariant='caption'
                                label='Diagnosesicherheit'
                                text={this.props.krankheit.diagnosesicherheit ? DiagnoseSicherheit[this.props.krankheit.diagnosesicherheit] : "-"}
                            />
                            <ViewText
                                labelVariant='caption'
                                label='Seitenlokalisation'
                                text={this.props.krankheit.seitenlokalisation ? Seitenlokation[this.props.krankheit.seitenlokalisation] : "-"}
                            />
                            <ViewText
                                labelVariant='caption'
                                label='Dauerdiagnose'
                                text={(this.props.krankheit.dauerdiagnose) ? "JA" : "NEIN"}
                            />
                            <ViewText
                                labelVariant='caption'
                                label='Feststellungsdatum'
                                text={this.props.krankheit.datumDiagnosestellung ? new Date(this.props.krankheit.datumDiagnosestellung).toLocaleDateString().split(",")[0] : "-"}
                            />
                            <ViewText
                                labelVariant='caption'
                                label='Medizinisches Datum'
                                text={medizinischesDatumVon}
                            />
                            {medizinischesDatumBis !== "" &&
                                <ViewText
                                    labelVariant='caption'
                                    label=''
                                    text={medizinischesDatumBis}
                                />
                            }
                        </Box>
                    </Box>
                    <Box className="modal-footer">
                        <Button variant="text" color="primary" onClick={() => this.props.handleClose()}>
                            Schließen
                        </Button>
                    </Box>
                </Paper>
            </SimpleModalContainer>
        )
    }
}


