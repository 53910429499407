import React from 'react';

import { Tooltip, IconButton, Paper, Box, Button, TextField} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { DermatologistInfo, DermaRegistrationStatus, DermaActivationStatus, Arzt, } from 'telescan-core';
import SimpleModalContainer from '../../elements/simple_modal';
import { ViewText } from '../../konsil';
import { isEmptyObject } from '../../../utils';



interface IState {
    dermaRegistrationInfo: DermaRegistrationStatus;
    activationkey: string;
}

interface IProps {
    dermaRegistrationInfo: DermaRegistrationStatus;
    isDirectoryServiceDialogOpen: boolean;
    handleClose: () => void;
    handleRegister: (dermatologistMetadata: DermatologistInfo) => void;
    isEdit: boolean;
    handleChangeEditFlag: (isEdit: boolean) => void;
    dispatch_activateDermatologistInDirectory: (activationKey: string) => void;
    currentUser?: Arzt;
    kimEmailAddress?: string;
}


export default class DirectoryServiceEditPopup extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            dermaRegistrationInfo: new DermaRegistrationStatus(),
            activationkey: "",
        }
    }

    componentDidMount() {
        if (!isEmptyObject(this.props.dermaRegistrationInfo)) {
            this.setState({
                dermaRegistrationInfo: {
                    ...this.state.dermaRegistrationInfo,
                    metadata: {
                        ...this.state.dermaRegistrationInfo.metadata,
                        lanr: this.props.dermaRegistrationInfo.metadata?.lanr,
                        bsnr: this.props.dermaRegistrationInfo.metadata?.bsnr,
                        title: this.props.dermaRegistrationInfo.metadata?.title,
                        firstName: this.props.dermaRegistrationInfo.metadata?.firstName,
                        lastName: this.props.dermaRegistrationInfo.metadata?.lastName,
                        postalCode: this.props.dermaRegistrationInfo.metadata?.postalCode,
                        city: this.props.dermaRegistrationInfo.metadata?.city,
                        kimEmailAddress: this.props.kimEmailAddress
                    }                  
                }
            })
        } else {
            this.props.currentUser &&
            this.setState({
                dermaRegistrationInfo: {
                    ...this.state.dermaRegistrationInfo,
                    metadata: {
                        ...this.state.dermaRegistrationInfo.metadata,
                        lanr: this.props.currentUser.lanr,
                        bsnr: this.props.currentUser.bsnr,
                        title: this.props.currentUser.titel,
                        firstName: this.props.currentUser.vorname,
                        lastName: this.props.currentUser.nachname,
                        postalCode: this.props.currentUser.plz,
                        city: this.props.currentUser.ort,
                        kimEmailAddress: this.props.kimEmailAddress
                    }                  
                }
            })
        }     
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.dermaRegistrationInfo !== this.props.dermaRegistrationInfo) {
            this.setState({
                dermaRegistrationInfo: {
                    ...this.state.dermaRegistrationInfo,
                    metadata: {
                        ...this.state.dermaRegistrationInfo.metadata,
                        lanr: this.props.dermaRegistrationInfo.metadata?.lanr,
                        bsnr: this.props.dermaRegistrationInfo.metadata?.bsnr,
                        title: this.props.dermaRegistrationInfo.metadata?.title,
                        firstName: this.props.dermaRegistrationInfo.metadata?.firstName,
                        lastName: this.props.dermaRegistrationInfo.metadata?.lastName,
                        postalCode: this.props.dermaRegistrationInfo.metadata?.postalCode,
                        city: this.props.dermaRegistrationInfo.metadata?.city,
                        kimEmailAddress: this.props.kimEmailAddress
                    }                  
                }
            })
        }
        if (prevProps.currentUser !== this.props.currentUser && isEmptyObject(this.props.dermaRegistrationInfo)) {
            this.props.currentUser &&
            this.setState({
                dermaRegistrationInfo: {
                    ...this.state.dermaRegistrationInfo,
                    metadata: {
                        ...this.state.dermaRegistrationInfo.metadata,
                        lanr: this.props.currentUser.lanr,
                        bsnr: this.props.currentUser.bsnr,
                        title: this.props.currentUser.titel,
                        firstName: this.props.currentUser.vorname,
                        lastName: this.props.currentUser.nachname,
                        postalCode: this.props.currentUser.plz,
                        city: this.props.currentUser.ort,
                        kimEmailAddress: this.props.kimEmailAddress
                    }
                    
                }
            })
        }
    }

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {  
        this.setState({
            dermaRegistrationInfo: {
                ...this.state.dermaRegistrationInfo,
                metadata: {
                    ...this.state.dermaRegistrationInfo.metadata,
                    [event.target.name]: event.target.value
                }
            },
        })
    }

    handleInputActivationKey = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({activationkey: event.target.value});
    }

    handleRegisterButton = () => {
        if (this.state.dermaRegistrationInfo.metadata)
            this.props.handleRegister(this.state.dermaRegistrationInfo.metadata);
        this.props.handleChangeEditFlag(false);
    }

    render() {
        return (
            <SimpleModalContainer isOpen={this.props.isDirectoryServiceDialogOpen}>
                <Paper className="modal-wrapper settings-arzt-pop">
                    <h2 className="modal-header flex-row-center space-between">
                        Ihre Angaben
                        {(!this.props.isEdit && this.props.dermaRegistrationInfo.active === DermaActivationStatus.INACTIVE) &&
                            <Tooltip title="Basisdaten bearbeiten" placement="top">
                                <IconButton
                                    size="small"
                                    aria-label="delete"
                                    onClick={() => this.props.handleChangeEditFlag(true)}
                                >
                                    <Edit/>
                                </IconButton>
                            </Tooltip>
                        }
                    </h2>
                    <Box className="modal-content">
                        {(this.props.isEdit && (!this.props.dermaRegistrationInfo.active || this.props.dermaRegistrationInfo.active === DermaActivationStatus.INACTIVE)) ?
                            <Box className="initialisation-content popup-insert">
                                <Box className="bs-infos-card-3columns-mod">
                                    <TextField
                                        id = "title"
                                        label = "Titel"
                                        value = {this.state.dermaRegistrationInfo.metadata?.title}
                                        name = "title"
                                        autoComplete="off"
                                        onChange = {this.handleInputChange}
                                        // onBlur = {() => this.props.dispatch_sendKimSettings(this.state.kimGeneral, this.state.kimUser)}
                                        // error = {!this.state.kimGeneral.host || this.state.kimGeneral.host === ""}
                                    />
                                    <TextField
                                        id = "firstName"
                                        label = "Vorname"
                                        value = {this.state.dermaRegistrationInfo.metadata?.firstName}
                                        name = "firstName"
                                        autoComplete="off"
                                        onChange = {this.handleInputChange}
                                        // onBlur = {() => this.props.dispatch_sendKimSettings(this.state.kimGeneral, this.state.kimUser)}
                                        error = {!this.state.dermaRegistrationInfo.metadata?.firstName || this.state.dermaRegistrationInfo.metadata?.firstName === ""}
                                    />
                                    <TextField
                                        id = "lastName"
                                        label = "Nachname"
                                        value = {this.state.dermaRegistrationInfo.metadata?.lastName}
                                        autoComplete="off"
                                        name = "lastName"
                                        onChange = {this.handleInputChange}
                                        // onBlur = {() => this.props.dispatch_sendKimSettings(this.state.kimGeneral, this.state.kimUser)}
                                        error = {!this.state.dermaRegistrationInfo.metadata?.lastName || this.state.dermaRegistrationInfo.metadata?.lastName === ""}
                                    />
    
    
                                    <TextField
                                        id = "postalCode"
                                        label = "PLZ"
                                        value = {this.state.dermaRegistrationInfo.metadata?.postalCode}
                                        autoComplete="off"
                                        name = "postalCode"
                                        onChange = {this.handleInputChange}
                                        // onBlur = {() => this.props.dispatch_sendKimSettings(this.state.kimGeneral, this.state.kimUser)}
                                        error = {!this.state.dermaRegistrationInfo.metadata?.postalCode || this.state.dermaRegistrationInfo.metadata?.postalCode === ""}
                                    />
                                    <TextField
                                        id = "city"
                                        label = "Ort"
                                        value = {this.state.dermaRegistrationInfo.metadata?.city}
                                        autoComplete="off"
                                        name = "city"
                                        onChange = {this.handleInputChange}
                                        // onBlur = {() => this.props.dispatch_sendKimSettings(this.state.kimGeneral, this.state.kimUser)}
                                        error = {!this.state.dermaRegistrationInfo.metadata?.city || this.state.dermaRegistrationInfo.metadata?.city === ""}
                                    />
                                    <TextField
                                        id = "medicalOfficeName"
                                        label = "Praxisname"
                                        value = {this.state.dermaRegistrationInfo.metadata?.medicalOfficeName}
                                        autoComplete="off"
                                        name = "medicalOfficeName"
                                        onChange = {this.handleInputChange}
                                        // onBlur = {() => this.props.dispatch_sendKimSettings(this.state.kimGeneral, this.state.kimUser)}
                                        // error = {!this.state.kimGeneral.kimPop3Port || this.state.kimGeneral.kimPop3Port === ""}
                                    />
                                    
                                </Box>
                                <Box className="bs-infos-card-1column">
                                    <TextField
                                        id = "kimEmailAddress"
                                        label = "KIM-Email"
                                        value = {this.state.dermaRegistrationInfo.metadata?.kimEmailAddress}
                                        autoComplete="off"
                                        name = "kimEmailAddress"
                                        onChange = {this.handleInputChange}
                                        fullWidth
                                        error = {!this.state.dermaRegistrationInfo.metadata?.kimEmailAddress || this.state.dermaRegistrationInfo.metadata?.kimEmailAddress === ""}
                                    />
                                </Box>
                            </Box> :
                            <form className="form-content label-content-grid" noValidate autoComplete="off" style={{marginBottom: "8px"}}>
                                <ViewText
                                    labelVariant='caption'
                                    label='Titel'
                                    text={this.props.dermaRegistrationInfo.metadata?.title || ""}
                                />
                                <ViewText
                                    labelVariant='caption'
                                    label='Vorname'
                                    text={this.props.dermaRegistrationInfo.metadata?.firstName || ""}
                                />
                                <ViewText
                                    labelVariant='caption'
                                    label='Nachname'
                                    text={this.props.dermaRegistrationInfo.metadata?.lastName || ""}
                                />
                                <ViewText
                                    labelVariant='caption'
                                    label='PLZ'
                                    text={this.props.dermaRegistrationInfo.metadata?.postalCode || ""}
                                />
                                <ViewText
                                    labelVariant='caption'
                                    label='Ort'
                                    text={this.props.dermaRegistrationInfo.metadata?.city || ""}
                                />
                                <ViewText
                                    labelVariant='caption'
                                    label='Praxis'
                                    text={this.props.dermaRegistrationInfo.metadata?.medicalOfficeName || ""}
                                />
                                <ViewText
                                    labelVariant='caption'
                                    label='KIM-Adresse'
                                    text={this.props.dermaRegistrationInfo.metadata?.kimEmailAddress || ""}
                                />    
                                <br/>
                                {this.props.dermaRegistrationInfo.active === DermaActivationStatus.INACTIVE &&
                                    <TextField
                                        size = "small"
                                        variant = "outlined"
                                        label = "Aktivierungscode"
                                        value = {this.state.activationkey}
                                        autoComplete="off"
                                        onChange = {this.handleInputActivationKey}
                                        // onBlur = {() => this.props.dispatch_sendKimSettings(this.state.kimGeneral, this.state.kimUser)}
                                        // error = {!this.state.kimGeneral.kimPop3Port || this.state.kimGeneral.kimPop3Port === ""}
                                    />               
                                }
                                
                            </form>
                        }


                    </Box>
                    
                    <Box className="modal-footer">
                        {this.props.isEdit &&
                            <>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick = {this.handleRegisterButton}
                                >
                                    {isEmptyObject(this.props.dermaRegistrationInfo) ? "Registrieren" : "Aktualisieren"}                               
                                </Button>
                                <Button
                                    variant="text"
                                    //onClick={() => this.props.handleChangeEditFlag(false)}
                                    onClick={this.props.handleClose}
                                >
                                    Abbrechen
                                </Button>
                            </>
                            
                        }      
                        {!this.props.isEdit && this.props.dermaRegistrationInfo.active === DermaActivationStatus.INACTIVE &&
    
                             <Button
                                variant="contained"
                                color="primary"
                                onClick = {() => this.props.dispatch_activateDermatologistInDirectory(this.state.activationkey)}
                            >
                                Aktivieren                               
                            </Button>
                        }     
                        {!this.props.isEdit &&   
                            <Button
                            variant="text"
                            onClick={this.props.handleClose}
                            >
                                Schließen                               
                            </Button>
                        }                            
                                              
                    </Box>
                </Paper>

               
            </SimpleModalContainer>
        );
    }
}
