import React from 'react';
import {connect, ConnectedProps} from 'react-redux'
import {RootState} from '../../../redux/reducers';
import { push } from 'connected-react-router';
import { deleteBill, getBill, navigate, updateQuartalsBill } from '../../../redux/actions';
import QuartalBillsTableRender from './quartal_bills_table_render';

const mapStateToProps = (state: RootState) => ({
    user: state.user.user,
    role: state.user.role,
    router: state.router,
    quartalsabrechnungen: state.quartalsabrechnungenStore.quartalsabrechnungen,
    quartalsabrecnungenUpdate: state.quartalsabrechnungenStore.quartalsabrechnungUpdate,
    loading: state.general.loading,
})

const mapDispatchToProps = {
    push: (path: string) => push(path),
    dispatch_deleteBill: (id: string) => deleteBill(id),
    dispatch_getBill: (id: string) => getBill(id),
    dispatch_navigate: (url: string) => navigate(url),
    dispatch_updateQuartalsBill: (id: string) => updateQuartalsBill(id),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>


export class QuartalBillsTable extends React.Component<TPropsFromRedux> {

    navigateQartalsAbrechnung = (id: string) => {
        this.props.push(`${this.props.router.location.pathname}/${id}`);
    }
    
    render() {
        return( 
            <QuartalBillsTableRender
                quartalBillsTableData = {this.props.quartalsabrechnungen}
                quartalBillsUpdate = {this.props.quartalsabrecnungenUpdate}
                dispatch_getBill = {this.props.dispatch_getBill}
                dispatch_deleteBill = {this.props.dispatch_deleteBill}
                navigateQartalsAbrechnung = {this.navigateQartalsAbrechnung}
                dispatch_updateQuartalsBill = {this.props.dispatch_updateQuartalsBill}
            />
        )
    }
}

export const QuartalBillsTableContainer = connector(QuartalBillsTable)
export default QuartalBillsTableContainer;
