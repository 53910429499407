import {BearbeitungsStatusType, KonsilMetaInformation} from "telescan-core";

export enum EDermatologeActions {
    SET_METADATEN_ADRESSIERT = "SET_METADATEN_ADRESSIERT",
    SET_METADATEN_GERICHTET = "SET_METADATEN_GERICHTET",
    SET_ACTIVE_AREAS = "SET_ACTIVE_AREAS",
    SET_METADATEN_ADRESSIERT_KONSIL_BEARBEITUNGSSTATUS = "SET_METADATEN_ADRESSIERT_KONSIL_BEARBEITUNGSSTATUS",
    SET_METADATEN_GERICHTET_KONSIL_BEARBEITUNGSSTATUS = "SET_METADATEN_GERICHTET_KONSIL_BEARBEITUNGSSTATUS",
    REMOVE_METADATEN_ADRESSIERT_KONSIL = "REMOVE_METADATEN_ADRESSIERT_KONSIL",
    REMOVE_METADATEN_GERICHTET_KONSIL = "REMOVE_METADATEN_GERICHTET_KONSIL",
}

export interface ISetMetadatenAdressiertAction {
    readonly type: EDermatologeActions.SET_METADATEN_ADRESSIERT;
    readonly metadatenAdressiert: KonsilMetaInformation[];
}

export interface ISetMetadatenGerichtetAction {
    readonly type: EDermatologeActions.SET_METADATEN_GERICHTET;
    readonly metadatenGerichtet: KonsilMetaInformation[];
}

export interface ISetActiveAreasAction {
    readonly type: EDermatologeActions.SET_ACTIVE_AREAS;
    readonly areaIndex: number;
    readonly isActive: boolean;
}

export interface ISetMetadatenAdressiertKonsilBearbeitungsstatus {
    readonly type: EDermatologeActions.SET_METADATEN_ADRESSIERT_KONSIL_BEARBEITUNGSSTATUS;
    readonly vorgangsId: string;
    readonly bearbeitungsStatus: keyof typeof BearbeitungsStatusType;
}

export interface ISetMetadatenGerichtetKonsilBearbeitungsstatus {
    readonly type: EDermatologeActions.SET_METADATEN_GERICHTET_KONSIL_BEARBEITUNGSSTATUS;
    readonly vorgangsId: string;
    readonly bearbeitungsStatus: keyof typeof BearbeitungsStatusType;
}

export interface IRemoveMetadatenAdressiertKonsil {
    readonly type: EDermatologeActions.REMOVE_METADATEN_ADRESSIERT_KONSIL;
    readonly vorgangsId: string;
}

export interface IRemoveMetadatenGerichtetKonsil {
    readonly type: EDermatologeActions.REMOVE_METADATEN_GERICHTET_KONSIL;
    readonly vorgangsId: string;
}

export type TDermatologeActionTypes =
    ISetMetadatenAdressiertAction |
    ISetMetadatenGerichtetAction |
    ISetActiveAreasAction |
    ISetMetadatenAdressiertKonsilBearbeitungsstatus |
    ISetMetadatenGerichtetKonsilBearbeitungsstatus|
    IRemoveMetadatenAdressiertKonsil |
    IRemoveMetadatenGerichtetKonsil;
