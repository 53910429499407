import { Box, Button, Paper, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { DermatologistInfo, KIMGeneral, KIMUser, TIConnectorEvent, UploadPath } from "telescan-core";
import { getKimSettings, uploadCertificates, sendKimSettings, setTiConnectorEvent, registerDermatologistInDirectory, activateDermatologistInDirectory } from "../../../redux/actions/kim_actions";
import { RootState } from "../../../redux/reducers";
import { checkKimSettingsBE, getInitialisedServices } from "../../../redux/actions";
import { DirectoryServiceTable } from "./directory_service_table";
import DirectoryServiceEditPopup from "./directory_service_edit_popup";
import { Add } from "@material-ui/icons";

interface IState {
    isDirectoryServiceDialogOpen: boolean;
    isDirectoryServiceDialogEdit: boolean;
}


const mapStateToProps = (state: RootState) => ({
    dermaRegistrationInfo: state.kim.dermatologistRegistration,
    kimGeneral: state.kim.kimGeneral,
    initialisedServices: state.general.initialisedServices,
})

const mapDispatchToProps = {
    dispatch_getKimSettings: () => getKimSettings(),
    dispatch_sendKimSettings: (kimGeneral: KIMGeneral, kimUser: KIMUser) => sendKimSettings(kimGeneral, kimUser),
    dispatch_uploadCertificates: (certList: Blob[], connectorId: string, certPassword: string) => uploadCertificates(certList, connectorId, certPassword),
    dispatch_setTiConnectorEvent: (tiConnectorEvent: TIConnectorEvent) => setTiConnectorEvent(tiConnectorEvent),
    dispatch_checkKimSettingsBE: () => checkKimSettingsBE(),
    dispatch_getInitialisedServices: () => getInitialisedServices(),
    dispatch_registerDermatologistInDirectory: (dermatologistMetadata: DermatologistInfo) => registerDermatologistInDirectory(dermatologistMetadata),
    dispatch_activateDermatologistInDirectory: (activationKey: string) => activateDermatologistInDirectory(activationKey),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

class DirectoryService extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            isDirectoryServiceDialogOpen: false,
            isDirectoryServiceDialogEdit: false,
        }
    }

    handleOpenDirectoryServicePopup = () => {
        this.setState({isDirectoryServiceDialogOpen: true});
        this.handleChangeEditFlag(true);
    }

    handleOnTableRowClick = () => {
        this.setState({isDirectoryServiceDialogOpen: true});
        this.handleChangeEditFlag(false);
    }

    handleDirectoryServiceDialogClose = () => {
        this.setState({isDirectoryServiceDialogOpen: false});
    }

    handleRegister = (dermatologistMetadata: DermatologistInfo) => {
        this.props.dispatch_registerDermatologistInDirectory(dermatologistMetadata);
    }

    handleChangeEditFlag = (isEdit: boolean) => {
        this.setState({isDirectoryServiceDialogEdit: isEdit});
    }

    render() {
        return (
            <Box className="bsnr-info-container">
                 <Box className="arzt-info">
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                        TeleScan-Verzeichniesdienst
                    </Typography>
                    {(this.props.initialisedServices.includes(UploadPath.KIM) && this.props.dermaRegistrationInfo?.active && this.props.dermaRegistrationInfo?.active === "INACTIVE") &&
                        <Tooltip title="Beim Verzeichnisdienst anmelden">
                            <Button
                                className="slim"
                                variant="contained"
                                color="primary"
                                onClick = {this.handleOpenDirectoryServicePopup}
                            >
                                <Add/>
                            </Button>
                        </Tooltip>
                    }
                   
                </Box>
                <Paper elevation={0}>
                    {this.props.initialisedServices.includes(UploadPath.KIM) ?
                    <>
                        {!this.props.dermaRegistrationInfo?.active &&
                            <Typography className="bsnr-info" variant="caption" color="inherit" gutterBottom>
                                Sie sind im TeleScan-Verzeichnisdienst nicht registriert. Beachten Sie, dass Ihre LANR, BSNR und private E-Mail Adresse erst über zollsoft oder Managementgesellschaft hinterlegt werden mussen.
                                Danach können Sie ihren TeleScan Account im Verzeichnisdienst freischalten. Erst nach erfolgreicher Freischaltung können Hausärzte dermatologische Telekonsile an Sie versenden.
                            </Typography>
                        }   
                        {(this.props.dermaRegistrationInfo?.active && this.props.dermaRegistrationInfo?.active === "INACTIVE") &&
                            <Typography className="bsnr-info" variant="caption" color="inherit">
                                Bitte schalten Sie ihren TeleScan Account im Verzeichnisdienst frei. Erst nach erfolgreicher Freischaltung können Hausärzte dermatologische Telekonsile an Sie versenden.
                                Diese Angaben können jederzeit in den Einstellungen überprüft oder bearbeitet werden.
                            </Typography> 
                        }
                        {(this.props.dermaRegistrationInfo?.active && this.props.dermaRegistrationInfo?.active === "ACTIVE") &&
                            <>
                                <Typography className="bsnr-info" variant="caption" color="inherit">
                                    Hier können Sie Ihre Daten im TeleScan-Verzeichnisdienst überprufen.
                                </Typography>
                                <DirectoryServiceTable
                                    hanldeOnRowClick={this.handleOnTableRowClick}
                                    dermaRegistrationInfo = {this.props.dermaRegistrationInfo}
                                    handleChangeEditFlag = {this.handleChangeEditFlag}
                                />
                            </>
                        }
                    </> :
                    <Typography className="bsnr-info" variant="caption" color="inherit">
                        TeleScan-Verzeichnisdienst steht Ihnen zur Verfügung wenn KIM-Erweiterung vollständig eingerichtet ist.     
                    </Typography> 
                    }
                   
                   
                </Paper>
                <DirectoryServiceEditPopup
                    dermaRegistrationInfo = {this.props.dermaRegistrationInfo}
                    isDirectoryServiceDialogOpen = {this.state.isDirectoryServiceDialogOpen}
                    handleClose = {this.handleDirectoryServiceDialogClose}
                    handleRegister = {this.handleRegister}
                    isEdit = {this.state.isDirectoryServiceDialogEdit}
                    handleChangeEditFlag = {this.handleChangeEditFlag}
                    dispatch_activateDermatologistInDirectory = {this.props.dispatch_activateDermatologistInDirectory}
                    kimEmailAddress = {this.props.kimGeneral.kimEmailAddressBs}
                />
            </Box>

        );
    }
}

export const DirectoryServiceContainer = connector(DirectoryService);
export default DirectoryServiceContainer;
