import React from "react";
import {connect, ConnectedProps} from 'react-redux';

import { Box, Typography, Paper, FormControl, Button, FormControlLabel, Checkbox } from "@material-ui/core";
import { UserService, TelescanConfig, Failure } from "telescan-core";
import { RootState } from "../../redux/reducers";
import { loadFailure, setEinverstaendnis, getInitialisationStatus, failure } from "../../redux/actions";
import uuid from 'uuid';
import { RecoveryCodeComponent } from "./recovery_code";
import { NewPasswordContainer } from "../elements/new_password";
import { withNewPasswordMethods, WithNewPasswordMethodsProps, telescanFixable } from "../../utils";



interface IState {
    recoveryCode: string;
    isSubmitted: boolean;
}

const mapStateToProps = (state: RootState) => ({
    //basisdatenBetriebsstaette: state.user.betriebsstaette,
    initialisationStatus: state.user.initialisationStatus,
    einverstaendnis: state.user.einverstaendnis
})

const mapDispatchToProps = {
    dispatch_loadFailure: (id: string, message: Failure) => loadFailure(id, message),
    dispatch_failure: (id: string, message: Failure) => failure(id, message),
    dispatch_updateEinverstaendnis: (einverstaendnis: boolean) => setEinverstaendnis(einverstaendnis),
    dispatch_getInitialisationStatus: () => getInitialisationStatus(),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>
type Props = TPropsFromRedux & WithNewPasswordMethodsProps

export class Passwort extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            recoveryCode: "",
            isSubmitted: false,
        };

        this.handleSubmitClick = this.handleSubmitClick.bind(this);
    }


    handleSubmitClick(e) {
        const loadInstanceId: string = uuid.v4();
        e.preventDefault();
        // this.setState({ submitted: true });
        if (this.props.newPassword !== "" && this.props.newPassword===this.props.newRepeatedPassword) {
            this.changePassword(this.props.newPassword, loadInstanceId)
            this.props.clearFields();
        } else {
            let failure = new Failure();
            failure.error = "Passwortfestlegung fehlgeschlagen! Bitte geben Sie ein starkes Passwort ein und prüfen Sie dass es mit der wiederholten Passwort übereinstimmt.";
            this.props.dispatch_failure(loadInstanceId, failure);
        }
    }

    changePassword(password: string, loadInstanceId: string) {
        //const bsnr: string = this.props.basisdatenBetriebsstaette.bsnr || "";
        const bsnr: string | null = localStorage.getItem("BSNR");
        const userService = new UserService();
        if (bsnr && password) {
            userService.changePasswordRecoveryCode(bsnr, password, "recoveryCode").then((response) => {
                this.setState({
                    recoveryCode: response.recoveryCode,
                    isSubmitted: true
                })
                TelescanConfig.getInstance().setConstant("PASSWORD", password);
            }).catch((error) => { 
                this.props.dispatch_failure(loadInstanceId, error);
            })
        } else if (!bsnr){
            let failure = new Failure();
            failure.error = "Keine gültige BSNR." + telescanFixable;
            this.props.dispatch_failure(loadInstanceId, failure);
        } else {
            let failure = new Failure();
            failure.error = "Kein Passwort angegeben. Bitte geben Sie ein Passwort ein."
            this.props.dispatch_failure(loadInstanceId, failure);
        }
    }

    render() {
        return (
            <Box className="main-content initialisation-content">

                <form className="form-content" noValidate autoComplete="off">
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                        Betriebsstättenweites Passwort festlegen
                    </Typography>
                    <Box className="bs-doctors-description" style={{marginTop: "16px", marginBottom: "16px", display: "flex", flexDirection: "column"}}>
                        <Typography variant="caption" color="inherit" >
                            Legen Sie hier Ihr Login-Passwort fest. Dieses Passwort ist für alle Ärzte der Betriebsstätte gültig.
                        </Typography>
                        <Typography variant="caption" color="inherit" >
                            Sollten Sie die Ersteinrichtung vor der Passwortvergabe abbrechen, so können Sie jederzeit 
                            mit der Ersteinrichtung für die gewählte Betriebsstätte von Neuem beginnen.
                            Bei einem Abbruch in einem der späteren Schritte, können Sie sich mit dem hier vergebenen Passwort anmelden und die Ersteinrichtung fortführen.
                        </Typography>
                    </Box>
                    {!(this.props.initialisationStatus.recoveryCodeErzeugt || this.state.isSubmitted) &&
                    <Paper className="bs-infos-card" elevation={0}>
                        <Box className="bs-left">
                            <NewPasswordContainer setStrength={this.props.setStrength} newPassword={this.props.newPassword} 
                                newRepeatedPassword={this.props.newRepeatedPassword} handleNewPasswordChange={this.props.handleNewPasswordChange} 
                                handleNewRepeatedPasswordChange={this.props.handleNewRepeatedPasswordChange} context="LOGIN" />
                        </Box>
                        <Box className="passwort-button-container">
                            <Button variant="contained" color="primary" type="submit"
                                disabled={this.props.newPassword == null || this.props.newPassword === "" || 
                                        this.props.newPassword!==this.props.newRepeatedPassword || this.props.passwordStrength !== "strong"}
                                onClick={(event) => {
                                    this.handleSubmitClick(event)
                            }}>
                                Passwort festlegen
                            </Button>
                        </Box>
                    </Paper>
                    }
                    {(this.props.initialisationStatus.recoveryCodeErzeugt && !this.state.isSubmitted) &&
                    <Paper className="bs-infos-card" elevation={0}>
                        <Typography >Es wurde bereits ein Passwort erzeugt.</Typography>
                    </Paper>
                    }
                </form>
                {this.state.isSubmitted &&
                <form className="form-content" noValidate autoComplete="off">
                    <RecoveryCodeComponent isPasswordReset={true} checkboxComp={<CheckboxComponent/>} 
                        recoveryCode={this.state.recoveryCode}
                    />
                </form>}
        </Box>
        )
    }
}

export const PasswortComponent = connector(withNewPasswordMethods(Passwort));
export default PasswortComponent;



export class CheckboxEinverstaendnis extends React.Component<TPropsFromRedux> {

    componentDidMount() {
        this.props.dispatch_updateEinverstaendnis(false)
    }
    
    render() {
        return (
            <FormControl >
                <FormControlLabel
                    control={<Checkbox name="einverstaendnis"
                                        required
                                        checked={this.props.einverstaendnis || false}
                                        onChange={(event) => { this.props.dispatch_updateEinverstaendnis(event.target.checked);
                                            this.props.dispatch_getInitialisationStatus();}}
                                    />
                } label="Gelesen"
                />
            </FormControl>
        );
    }
}

export const CheckboxComponent = connector(CheckboxEinverstaendnis);