import { Box, Typography, IconButton, InputBase, Select, MenuItem, FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React from "react";
import { Arzt, EmpfaengerTyp } from "telescan-core";
import { RecieverCardComponent } from "./reciever_card";
import { RecieverPickerComponent } from "./reciever_picker";

interface IProps {
    typingReminder: string | undefined;
    empfaengerTyp: keyof typeof EmpfaengerTyp;
    isEmptySearch: boolean;
    filteredKonsiliare: Arzt[];
    anchorEl: HTMLInputElement | null;
    selectedReceiver: Arzt | null;
    checkbox?: boolean;
    timeHandleSearchChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    handleMenuOpen: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    handleChangeEmpfaengerTyp: (event: React.ChangeEvent<{ value: unknown }>) => void;
    handleMenuClose: () => void;
    handleMenuSelect: (arzt: Arzt) => void;
    handleConsentGroupDispatchCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export const HpmSingleTabPanel = (props: IProps) => {
    return (
        <Box>
            <Typography className="failure"
                variant="caption">{props.typingReminder}</Typography>
                {/* to cheat chrome which tries to fill some none password filled with credentials*/}
                <input className = "hidden" id="username"  type="text" name="fakeusernameremembered"/>
                <input className = "hidden" id="password"  type="password" name="fakepasswordremembered"/>
                {/* //////////////////////////////////////////////////////////////////////////////*/}
                <Box className="search-container empfaenger-search">
                    <IconButton
                        type="submit"
                        size="small"
                        className=""
                        aria-label="search"
                        onClick = {event => event.preventDefault()}
                    >
                        <Search/>
                    </IconButton>
                    <InputBase
                        name = "search1"
                        className="search-input"
                        placeholder="Empfängersuche"
                        inputProps={{"aria-label": "Suche"}}
                        onChange={props.timeHandleSearchChange}
                        onClick={props.handleMenuOpen}
                    />
                    <Select
                        className="search-select"
                        labelId="status-select-label"
                        value={props.empfaengerTyp}
                        onChange={props.handleChangeEmpfaengerTyp}
                        displayEmpty
                    >
                        {Object.keys(EmpfaengerTyp).map(key =>
                            <MenuItem key={key} value={key}>
                                {EmpfaengerTyp[key]}
                            </MenuItem>
                        )}
                    </Select>
                </Box>
                {!props.isEmptySearch &&
                < RecieverPickerComponent
                    context="INDIVIDUAL"
                    konsiliareList={props.filteredKonsiliare}
                    anchorEl={props.anchorEl}
                    handleMenuClose={props.handleMenuClose}
                    handleMenuSelect={props.handleMenuSelect}
                />}

                {props.selectedReceiver &&
                <RecieverCardComponent
                    title="Konsil wird an folgenden Empfänger gesendet:"
                    isSelected={true}
                    receiverHPM={props.selectedReceiver}
                />
                }
                <FormGroup className="form-grid-content full-span">
                    <FormControlLabel
                        control =
                            {<Checkbox
                                name="einwilligung-versand-gerichtet"
                                checked={!props.checkbox || false}
                                onChange={props.handleConsentGroupDispatchCheckbox}
                            />}
                        label={<Typography variant="subtitle1" className="bold-label">Widerspruch Konsilumwandlung</Typography>}
                    />
                    <Typography variant="caption" color="inherit">
                        Hiermit widerspreche ich der Umwandlung des adressierten Konsils in ein
                        gerichtetes Konsil. (Dies entspricht: unbedingtes adressiertes Konsil)
                    </Typography>
                </FormGroup>
        </Box>
    )
}