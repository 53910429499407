import React from 'react';
import { Box, Typography } from '@material-ui/core';
import alertIcon from '../../static/images/ic-hinweis.svg'

interface IProps {
    checkboxComp?: React.ReactNode;
    recoveryCode: string;
    isPasswordReset: boolean;
}

export class RecoveryCodeComponent extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);
        this.canvasRef = React.createRef();
    }

    private canvasRef;

    componentDidMount() {
        const canvas = this.canvasRef.current;
        if (canvas) {
            const context = canvas.getContext('2d');
            canvas.width = 1155;
            canvas.height = 50;
            const text: string = this.props.recoveryCode;
            context.font = '16px Arial';
            context.fillText(text, 10, 30);
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (prevProps.recoveryCode !== this.props.recoveryCode) {
            const canvas = this.canvasRef.current;
            if (canvas) {
                const context = canvas.getContext('2d');
                canvas.width = 1155;
                canvas.height = 50;
                const text: string = this.props.recoveryCode;
                context.font = '16px Arial';
                context.fillText(text, 10, 30);
            }
        }
    }

    render() {
        return (
            <>
                {/*<Typography className="txt-header" variant="subtitle1" color="inherit">
                    Recovery-Code
                </Typography>*/}
                <Box>
                    <img src = {alertIcon} className = 'pad-l float-left' alt = ""/>
                    <Typography variant="body1" className="recovery-code-text failure" id = "recovery-code-typography">
                        Bitte notieren Sie sich folgenden Recovery-Code und bewahren Sie ihn gut auf.
                        Der Recovery-Code ist für die Passwortwiederherstellung zwingend erforderlich.
                        Eine Passwortwiederherstellung ohne Recovery-Code ist nicht möglich und würde eine
                        Neuinstallation der TeleScan-Software notwendig machen. Dies kann den
                        Verlust Ihrer Daten zur Folge haben.
                    </Typography>
                </Box>
               
                {this.props.isPasswordReset &&
                <Typography variant="body1" className="recovery-code-text failure">
                    Bitte beachten Sie: Das betriebsstättenweite Passwort wurde für alle Nutzer der Betriebsstätte festgelegt!
                </Typography>
                }
                <>{this.props.checkboxComp}</>
                <canvas ref={this.canvasRef}/>
            </>
        );
    }
}


