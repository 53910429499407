import React from 'react';
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogTitle,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText
} from '@material-ui/core';
import {UserContentContainer} from '../header'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import {connect, ConnectedProps} from "react-redux";
import {RootState} from "../../redux/reducers";
import {login, logout, navigate, uploadAvatarImage, loadAvatarImage, failure} from "../../redux/actions";
import {Arzt, TelescanConfig, Failure, TestVersion} from 'telescan-core';

// Avatars
import AvatarMale from '../../static/images/ic-doctor-m.svg';
import AvatarFemale from '../../static/images/ic-doctor-w.svg';
import { WithFileUploadProps, withFileUpload } from '../../utils';
import uuid from 'uuid';

import CE from '../../static/images/ic_CE.svg'

interface IProps {
    userName: string;
    avatar: string;
    lanr: string;
    bsnr: string;
}

interface IState {
    avatar: string;
    //img: string;
    dialogOpen: boolean;
}

const mapStateToProps = (state: RootState, ownProps: IProps) => ({
    role: state.user.role,
    betriebsstaettenAerzte: state.user.betriebsstaettenAerzte,
    isDesktop: state.general.isDesktop,
    userDetails: state.user.user,
    currentKonsil: state.konsile.current_konsil,
    fachrichtung: state.user.fachrichtung,
    ...ownProps
})

const mapDispatchToProps = {
    dispatch_failure: (id: string, message: Failure) => failure(id, message),
    dispatch_navigate: (url: string) => navigate(url),
    dispatch_login: (bsnr: string, password: string, lanr: string | null) => login(bsnr, password, lanr),
    dispatch_logout: () => logout(),
    dispatch_uploadAvatarImage: (file: File, loadInstanceId: string) => uploadAvatarImage(file, loadInstanceId),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>
type Props = TPropsFromRedux & WithFileUploadProps


export class UserPopupContent extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            avatar: this.props.avatar,
            dialogOpen: false,
        };
        this.handleDialog = this.handleDialog.bind(this);
        this.renderAvatar = this.renderAvatar.bind(this);
    }


    toggleBSArzt(arzt: Arzt) {
        const password = TelescanConfig.getInstance().getConstant("PASSWORD");
        this.props.dispatch_logout();
        if (password && password !== "")
            this.props.dispatch_login(arzt.bsnr || "", password, arzt.lanr || "");
        else {
            const failureId: string = uuid.v4();
            const failureObj: Failure = new Failure();
            failureObj.error = "Sie wurden ausgeloggt, da Sie sich vermutlich ursprünglich über das PVS-System eingeloggt haben. Um den Benutzer zu wechseln, geben Sie bitte Ihr Telescan-Passwort ein."
            this.props.dispatch_failure(failureId, failureObj);
        }
    }

    handleDialog = () => {
        this.setState({
            dialogOpen: !this.state.dialogOpen
        });
    };



    renderAvatar(arzt: Arzt) {
        if (arzt.avatar != null)
            return (
                <Avatar alt={arzt.lanr} src={loadAvatarImage(arzt.avatar)}></Avatar>
            )
        else if (arzt.avatar == null && arzt.geschlecht ==="WEIBLICH")
            return (
                <Avatar alt={arzt.lanr} src={AvatarFemale}></Avatar>
            )
        else if (arzt.avatar == null && arzt.geschlecht ==="MAENNLICH")
            return (
                <Avatar alt={arzt.lanr} src={AvatarMale}></Avatar>
            )
        else
            return (
                <Avatar alt={arzt.lanr}></Avatar>
            )
    }

    render() {
        return (
            <Box className="user-drawer">
                <UserContentContainer userName={this.props.userName}
                    avatar={this.props.avatar}
                    lanr={this.props.lanr}
                    bsnr={this.props.bsnr}
                />
                <Divider/>
                <Box className="avatar-selection">
                    <span //onClick={(event) => this.setAvatar(event)}
                    >
                        <Avatar alt="weiblich" src={AvatarFemale}/>
                    </span>
                    <span // onClick={(event) => this.setAvatar(event)}
                    >
                        <Avatar alt="männlich" src={AvatarMale}/>
                    </span>
                    <Avatar className="upload-avatar">
                        <input accept="image/png" className="upload-input" id="icon-button-file" type="file"
                            onChange={(event) => this.props.onInputChanged(event, this.props.dispatch_uploadAvatarImage, [0, 0, 400, 400], [])}/>
                        <label htmlFor="icon-button-file">
                            <IconButton aria-label="upload picture" component="span">
                                <AddPhotoAlternateIcon/>
                            </IconButton>
                        </label>
                    </Avatar>
                </Box>
                <Divider/>

                <Button className="change-user-btn" onClick={this.handleDialog}>Nutzer&nbsp;wechseln</Button>
                <Divider/>
                <Button className="settings-btn"
                        onClick={() => this.props.dispatch_navigate("/settings")}>Einstellungen</Button>
                <Divider/>
                {this.props.role ==="DERMATOLOGE" && // if role is dermatologist, show "Abrechnung" button
                <>
                    <Button
                        className='billing-btn'
                        onClick={() => this.props.dispatch_navigate("/billing")} // go to billing components
                    >
                        Abrechnungen
                    </Button>
                    <Divider />
                </>
                }
                <Button className="help-btn"
                        onClick={() => this.props.dispatch_navigate("/hilfe")}>Hilfe</Button>
                <Divider/>
                <Button className="logout-btn" onClick={this.props.dispatch_logout}>Logout</Button>

                <Divider/>
                <Box className="flex-spacer"></Box>
                <Divider/>
                <Button className="imprint-btn"
                        onClick={() => this.props.dispatch_navigate("/impressum")}>Impressum</Button>
                <Divider/>
                <Button className="dataprotection-btn"
                        onClick={() => this.props.dispatch_navigate("/datenschutz")}>Datenschutz</Button>
                <Divider/>
                {!new TestVersion().getConstant() && (this.props.fachrichtung && this.props.fachrichtung === "DERMATOLOGE") &&
                    <img className="ce-icon" src={CE} alt = "CE icon"/>
                }
                <Dialog
                    className="change-user-dialog"
                    onClose={this.handleDialog}
                    aria-labelledby="change-user-dialog"
                    open={this.state.dialogOpen}>
                    <DialogTitle id="change-user-dialog-title">Nutzer wechseln</DialogTitle>
                    <Divider/>
                    <List>
                        {this.props.betriebsstaettenAerzte.map((arzt) =>
                            <ListItem button key={arzt.id} onClick={() => {
                                this.toggleBSArzt(arzt);
                                this.handleDialog()
                            }}>
                                <ListItemAvatar>
                                    {this.renderAvatar(arzt)}
                                </ListItemAvatar>
                                <Box className="flex-column change-user-dialog-content">
                                    <ListItemText primary={arzt.lanr}/>
                                    <ListItemText primary={[arzt.titel, arzt.vorname, arzt.nachname].join(" ")}/>
                                </Box>
                            </ListItem>
                        )}
                        {(!this.props.isDesktop) &&
                        <>
                            <Divider/>
                            <Button color="primary" onClick={this.handleDialog}>Abbrechen</Button>
                        </>
                        }
                    </List>
                </Dialog>
            </Box>
        );
    }
}

export const UserPopupContentContainer = connector(withFileUpload(UserPopupContent));
export default UserPopupContentContainer;
