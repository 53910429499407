import { Box, Button, Paper, TextField, Tooltip, Typography } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import React from 'react'
import SimpleModalContainer from '../../elements/simple_modal'

interface IProps {
    isAddNewBillOpen: boolean;
    bsnrToSend: string;
    lanrToSend: string;
    toSendQuartal: number;
    toSendYear: number;
    onBsnrChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onLanrChange: (lanr: string) => void;
    onYearChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onQuartalChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    validateBsnr: () => boolean;
    validateLanr: () => boolean;
    validateYear: () => boolean;
    validateQuartal: () => boolean;
    closeAddNewBillModal: () => void;
    dispatch_createBill: (bsnr: string, lanr: string, year: number, quartal: number) => void;
    lanrList: string[];
}

export default class AddNewBillRender extends React.Component<IProps> {
    render() {
        return (
            <SimpleModalContainer
                isOpen={this.props.isAddNewBillOpen}
                additionalClassname="modal-add-data"
            >
                <Paper className="modal-wrapper">
                    <h2 className="modal-header">Abrechnung hinzufügen</h2>
                     <Box className="modal-content">
                        <form className="add-form med-form" noValidate autoComplete="off">
                            <TextField
                                key = 'bsnr'
                                id = 'bsnr'
                                label = {
                                    <Box className = 'flex-row'>
                                        <Typography>BSNR * </Typography>
                                        <Tooltip title = "LANR muss aus 9 Ziffern bestehen" placement = 'top'>
                                            <HelpOutline className = 'margin-left-auto' fontSize = 'small'/>
                                        </Tooltip>
                                    </Box>
                                }
                                value = {this.props.bsnrToSend}
                                onChange={this.props.onBsnrChange}
                                error = {!this.props.validateBsnr()}
                            />
                            <Autocomplete
                                value={this.props.lanrToSend}
                                onChange={(event: any, newValue: string) => {
                                    this.props.onLanrChange(newValue);
                                }}
                                id="controllable-states-demo"
                                options={this.props.lanrList}
                                style={{ width: 300 }}
                                renderInput={(params) => 
                                    <TextField
                                        {...params}
                                        key = 'lanr'
                                        id = 'lanr'
                                        label = {
                                            <Box className = 'flex-row'>
                                                <Typography>LANR * </Typography>
                                                <Tooltip title = "LANR muss aus 9 Ziffern bestehen" placement = 'top'>
                                                    <HelpOutline className = 'margin-left-auto' fontSize = 'small'/>
                                                </Tooltip>
                                            </Box>
                                        }
                                        error = {!this.props.validateLanr()}
                                    />
                                }
                            />
                            <TextField
                                key = 'year'
                                id = 'year'
                                label = {
                                    <Box className = 'flex-row'>
                                        <Typography>Abrechnungsjahr * </Typography>
                                        <Tooltip title = "Laufendes Jahr nach 2020" placement = 'top'>
                                            <HelpOutline fontSize = 'small'/>
                                        </Tooltip>
                                    </Box>
                                }
                                value = {isNaN(this.props.toSendYear) ? "": this.props.toSendYear.toString()}
                                placeholder = "Abrechnungsjahr"
                                onChange={this.props.onYearChange}
                                error = {!this.props.validateYear()}
                            />
                            <TextField
                                key = 'quartal'
                                id = 'quartal'
                                label = {
                                    <Box className = 'flex-row'>
                                        <Typography>Abrechnungsquartal * </Typography>
                                        <Tooltip title = "Aktuelles oder vorherige Quartale des laufenden Jahres" placement = 'top'>
                                            <HelpOutline fontSize = 'small'/>
                                        </Tooltip>
                                    </Box>
                                }
                                    
                                value = {isNaN(this.props.toSendQuartal) ? "": this.props.toSendQuartal.toString()}
                                placeholder = "Abrechnungsquartal"
                                onChange={this.props.onQuartalChange}
                                error = {!this.props.validateQuartal()}
                            />
                        </form>
                    </Box>
                    <Box className="modal-footer">
                        <Button
                            variant="text"
                            color="primary"
                            onClick={() => {
                                this.props.closeAddNewBillModal();
                            }}
                        >
                            Abbrechen
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled = {!this.props.validateBsnr() || !this.props.validateLanr() || !this.props.validateYear() || !this.props.validateQuartal()}
                            onClick = {() => {
                                this.props.dispatch_createBill(this.props.bsnrToSend, this.props.lanrToSend, this.props.toSendYear, this.props.toSendQuartal);
                                this.props.closeAddNewBillModal();
                            }}
                        >
                            Hinzufügen
                        </Button>
                    </Box>
                </Paper>
             </SimpleModalContainer>
        )
    }
}