import {ThunkAction} from 'redux-thunk';
import {Action} from 'redux';
import uuid from 'uuid';
import {RootState} from '../reducers';
import {
    Arzt,
    TelescanConfig,
    UserService,
    UserTypes as LoginKontext,
    WebSocketService,
    Betriebsstaette,
    BetriebsstaettenService,
    RoleTypes,
    PatientNumber,
    Kennzeichnung,
    Initialisierung,
    Failure,
    EnableNotifications,
    UploadPath,
} from 'telescan-core';
import {
    connectWebSocketService,
    getInitialisedServices,
    loadSuccess,
    navigate,
    refreshFailure,
    refreshSuccess,
} from '.';
import * as UserTypes from '../types/user_types';
import { loading, removeLoadFailure, failure, setAccessTokenExpiration, setIsInitialisationStatusLoading } from './general_actions';
import { getZertifikatStatus, loadZertifikate } from './zertifikat_actions';
import { getUpdateInformation } from './update_actions';
import { changeKonsilTab, getAvailableInsuranceKim, loadArztKonsile, loadKonsil } from './konsil_actions'; 
import { getCurrentNotifications } from './notification_actions';
import { getAllGdtPatients } from './gdt_actions';


export function login(bsnr: string, password: string, lanr: string | null, id?: string): ThunkAction<Promise<any>, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {

        // clear possible authentication failures
        dispatch(refreshSuccess());

        const loadInstanceId: string = uuid.v4();

        const userService: UserService = new UserService();
        return new Promise<any>((resolve, reject) => { userService.login(bsnr, password, lanr) 
            .then(
                (tokens) => {
                    dispatch(loadSuccess(loadInstanceId));
                    const telescanConfig: TelescanConfig = TelescanConfig.getInstance();
                    (tokens.access_token) ? telescanConfig.setConstant("ACCESS_TOKEN", tokens.access_token) : telescanConfig.setConstant("ACCESS_TOKEN", "");
                    (tokens.refresh_token) ? telescanConfig.setConstant("REFRESH_TOKEN", tokens.refresh_token) : telescanConfig.setConstant("REFRESH_TOKEN", "");
                    (tokens.username) ? telescanConfig.setConstant("USERNAME", tokens.username) : telescanConfig.setConstant("USERNAME", "");
                    dispatch(setAccessTokenExpiration(Date.now()));
                    dispatch({
                        type: UserTypes.EUserActions.SET_ROLE,
                        role: (tokens.roles) ? tokens.roles[0] : "HAUSARZT"
                    })
                    if (tokens.roles && tokens.roles.length > 0) {
                        if (tokens.roles[0] === "HAUSARZT" || tokens.roles[0] === "DERMATOLOGE") {
                            dispatch(getUser(LoginKontext.ARZT, "/"))
                        } else if (tokens.roles[0] === "BETRIEBSSTAETTE_HAUSARZT" || tokens.roles[0] === "BETRIEBSSTAETTE_DERMATOLOGE")
                            dispatch(getUser(LoginKontext.BETRIEBSSTAETTE, "/"))
                    }
                    localStorage.setItem("BSNR", bsnr);
                    localStorage.setItem("LANR", lanr || "");
                    getState().general.failures.forEach((element) => dispatch(removeLoadFailure(element.id)));
                    if (WebSocketService.getInstance() === undefined)
                        console.log("WebSocketService is undefined...")
                    else {
                        dispatch(connectWebSocketService(telescanConfig.getConstant("SERVER_API_URL"), telescanConfig.getConstant("ACCESS_TOKEN"), WebSocketService.getInstance()));
                    }
                    resolve(tokens);

                    dispatch(getInitialisationStatus())
                    // .then(() => {
                    //     if (new EnableNotifications().getConstant() && getState().user.initialisationStatus.abgeschlossen)
                    //         dispatch(getCurrentNotifications());
                    // })
                    dispatch(getInitialisedServices());
                    dispatch(getAllGdtPatients());
                    dispatch(getAvailableInsuranceKim());
                }
            ).catch((error: any) => {
                dispatch(failure(loadInstanceId, error.response ? error.response.body : "Authentifizierung fehlgeschlagen! Bitte melden Sie sich erneut an.")); // failure to be displayed
                dispatch(refreshFailure(String(error))); // failure to deny acccess to private routes
                dispatch(getInfo())
            }
        )})
    };
}

export function refresh(refresh_token: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        const loadInstanceId: string = uuid.v4();
        const userService: UserService = new UserService();
        userService.refresh(refresh_token)
            .then(
                () => { // use refresh token to get access token
                    dispatch(refreshSuccess());
                    dispatch(setAccessTokenExpiration(Date.now()));
                })
            .catch( (response) => {
                dispatch(logout());
                dispatch(failure(loadInstanceId, response)); // failure to be displayed
                dispatch(refreshFailure(response)); // failure to deny acccess to private routes
            }
        );
    };
}


// Function used when sending request with refresh token bypassing login procedure, needs to get user details and then either list of all konsils, all konsile of certain patient or specific konsil
export function getUserWithToken(refresh_token: string, targetPath: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const loadInstanceId: string = uuid.v4();
        const userService: UserService = new UserService();
        userService.refresh(refresh_token)
        .then(response => {
            dispatch(refreshSuccess());
            const telescanConfig: TelescanConfig = TelescanConfig.getInstance();
            telescanConfig.setConstant("ACCESS_TOKEN", response);
            dispatch(connectWebSocketService(telescanConfig.getConstant("SERVER_API_URL"), response, WebSocketService.getInstance()));
            dispatch(getInitialisationStatus())
            // .then(() => {
            //     console.log(getState().user.initialisationStatus)
            //     if (new EnableNotifications().getConstant() && getState().user.initialisationStatus.abgeschlossen)
            //         dispatch(getCurrentNotifications());
            // })
            dispatch(getInitialisedServices());
            dispatch(getAvailableInsuranceKim());
            dispatch(getUser(LoginKontext.ARZT, targetPath));
            if (targetPath.includes("konsil")) {
                const konsilId: string = targetPath.split("/")[2];
                dispatch(loadKonsil(konsilId));
            }    
            else {
                dispatch(loadArztKonsile());
            }  
            dispatch(getAllGdtPatients());
        })
        .catch(response => {
            dispatch(logout());
            dispatch(failure(loadInstanceId, response)); // failure to be displayed
            dispatch(refreshFailure(String(response))); // failure to deny acccess to private routes
        })
    }
}

export function getUser(kontext: LoginKontext, targetPath: string): ThunkAction<void, RootState, unknown, Action<string>> {
    
    return (dispatch, getState) => {
        const loadInstanceId: string = uuid.v4();
        const userService: UserService = new UserService();
        const patientNumber: PatientNumber = PatientNumber.getInstance();
        userService.getUserDetails(kontext)
            .then(
                (user) => {          
                    if (user.initialisierungAbgeschlossen === false && kontext === LoginKontext.ARZT){            
                        dispatch(navigate("/initialisation"));
                        dispatch(getInitialisationStatus())
                        .then(() => {
                            if (new EnableNotifications().getConstant() && getState().user.initialisationStatus.abgeschlossen)
                                dispatch(getCurrentNotifications());
                        })
                        //dispatch(getInitialisationProgressStatus());
                    } else if (user.initialisierungAbgeschlossen === true) {
                        if (patientNumber.getConstant("PATIENT_NUMBER")==="") {
                            dispatch(navigate(targetPath));
                        }
                        if (getState().general.initialisedServices.includes(UploadPath.HPM)) {
                            dispatch(getZertifikatStatus());
                            dispatch(loadZertifikate());
                        }
                        dispatch(getUpdateInformation());
                    } else {
                        dispatch(logout());
                        const failureObj: Failure = new Failure();
                        failureObj.error = "Um die Ersteinrichtung abzuschließen, ist eine LANR nötig!";
                        dispatch(failure(loadInstanceId, failureObj)); // failure to be displayed
                        dispatch(refreshFailure(failureObj.error)); // failure to deny acccess to private routes
                        return null;
                    }
                    if (user.betriebsstaette && user.aerzte) {
                        if (kontext === LoginKontext.BETRIEBSSTAETTE)
                            dispatch(setBetriebsstaettenAerzte(user.aerzte))
                        else if (kontext === LoginKontext.ARZT && user.aerzte.length === 1){
                            
                            //const loggedInArzt: Arzt = user.aerzte[0];
                            dispatch(getBetriebsstaettenAerzte())
                            /* .then(
                                () => dispatch(updateArzt(loggedInArzt)) // seems to be unnecessary
                            )} */
                        }
                        
                        dispatch(setBetriebsstaette(user.betriebsstaette))
                        dispatch(setUser(user.aerzte))
                    }
                    
                    dispatch({
                        type: UserTypes.EUserActions.SET_ROLE,
                        role: (user.role && user.role?.length >= 1) ? user.role[0] : "HAUSARZT"
                    })
                    dispatch({
                        type: UserTypes.EUserActions.SET_ARZTZERTIFIKAT_ERFASST,
                        zertifikatArztVorhanden: user.zertifikatArztVorhanden
                    })
                    dispatch({
                        type: UserTypes.EUserActions.SET_BSZERTIFIKAT_ERFASST,
                        zertifikatBetriebsstaetteVorhanden: user.zertifikatBetriebsstaetteVorhanden
                    })
                }).catch((response) => {
                    dispatch(failure(loadInstanceId, response)); // failure to be displayed
                    dispatch(refreshFailure(String(response))); // failure to deny acccess to private routes
                }
        );
    };
}

// export function getInitialisationProgressStatus(): ThunkAction<void, RootState, unknown, Action<string>> {
//     return dispatch => {
//         dispatch(setIsInitialisationStatusLoading(true));
//         const loadInstanceId: string = uuid.v4();
//         const userService: UserService = new UserService();
//         userService.getInitialisationStatus()
//             .then(
//                 (response: Initialisierung) => {
//                     dispatch(setIsInitialisationStatusLoading(false));
//                     dispatch(changeKonsilTab(response.lastEditedTab));
//                     // if (response.passwortErfasst && !response.recoveryCodeErzeugt)
//                     //     dispatch(changeKonsilTab(0));
//                     // if (response.passwortErfasst && response.recoveryCodeErzeugt && !response.betriebsstaetteErfasst)
//                     //     dispatch(changeKonsilTab(1));
//                     // else if (response.betriebsstaetteErfasst && !response.arztErfasst)
//                     //     dispatch(changeKonsilTab(2));
//                     // else if (response.arztErfasst && !(response.hpmInstalliert && response.hpmApplied))
//                     //     dispatch(changeKonsilTab(3));
//                     // else if (response.hpmInstalliert && response.hpmApplied && response.betriebsstaetteErfasst && (!response.betriebsstaetteZertifikatErzeugt || !response.arztZertifikatErzeugt))
//                     //     dispatch(changeKonsilTab(4));
//                     // else if (response.betriebsstaetteZertifikatErzeugt && response.arztZertifikatErzeugt)
//                     //     dispatch(changeKonsilTab(5));
//                     // else
//                     //     dispatch(changeKonsilTab(0));                
//                 })
//                 .catch((error) => {
//                 dispatch(setIsInitialisationStatusLoading(false));
//                 dispatch(failure(loadInstanceId, error)); // failure to be displayed
//             }
//         );
//     }
// }

export function getInitialisationStatus(): ThunkAction<Promise<Initialisierung>, RootState, unknown, Action<string>> {
        return dispatch => {
            dispatch(setIsInitialisationStatusLoading(true));
            const loadInstanceId: string = uuid.v4();
            return new Promise<Initialisierung> (resolve => {
                new UserService().getInitialisationStatus()
                .then((response: Initialisierung) => {
                    resolve(response);
                    dispatch(setIsInitialisationStatusLoading(false));
                    dispatch(setInitialisationStatus(response));
                    if (!response.abgeschlossen)
                        dispatch(changeKonsilTab(response.lastEditedTab));
                })
                .catch((failureObj: Failure) => {
                    dispatch(setIsInitialisationStatusLoading(false));
                    dispatch(failure(loadInstanceId, failureObj)); // failure to be displayed
                    dispatch(refreshFailure(String(failureObj))); // failure to deny acccess to private routes
                });
            })
               
        };
}

export function setArztZertifikatErfasst(zertifikatArztVorhanden: boolean): UserTypes.ISetArztZertifikatErfasstAction {
    return {
        type: UserTypes.EUserActions.SET_ARZTZERTIFIKAT_ERFASST,
        zertifikatArztVorhanden: zertifikatArztVorhanden
    }
}

export function setBSZertifikatErfasst(zertifikatBetriebsstaetteVorhanden: boolean): UserTypes.ISetBSZertifikatErfasstAction {
    return {
        type: UserTypes.EUserActions.SET_BSZERTIFIKAT_ERFASST,
        zertifikatBetriebsstaetteVorhanden: zertifikatBetriebsstaetteVorhanden
    }
}

export function setInitialisationStatus(initialisationStatus: any): UserTypes.ISetInitialisationStatusAction {
    return {
        type: UserTypes.EUserActions.SET_INITIALISATION_STATUS,
        initialisationStatus: initialisationStatus
    }
};

export function getBetriebsstaettenAerzte(): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        const loadInstanceId: string = uuid.v4();
        const betriebsstaettenService: BetriebsstaettenService = new BetriebsstaettenService();
        betriebsstaettenService.getBetriebsstaettenAerzte() // hard coded context bsnr
            .then(
                (aerzte) => {
                    aerzte && dispatch(setBetriebsstaettenAerzte(aerzte))
                }).catch(
            (response) => {
                dispatch(failure(loadInstanceId, response));
            }
        );
    };
}

export function setServerInfo(kennzeichnung: Kennzeichnung): UserTypes.ISetKennzeichnung {
    return {
        type: UserTypes.EUserActions.SET_KENNZEICHNUNG,
        kennzeichnung: kennzeichnung
    }
}

export function setFachrichtung(fachrichtung: string): UserTypes.ISetFachrichtung {
    return {
        type: UserTypes.EUserActions.SET_FACHRICHTUNG,
        fachrichtung: fachrichtung
    }
}


export function changePassword(newPassword: string, id?: string): ThunkAction<Promise<any>, RootState, unknown, Action<string>> {

    return dispatch => {
        const loadInstanceId: string = id || uuid.v4();
        dispatch(loading(loadInstanceId)); // maybe remove

        const userService: UserService = new UserService();
        return new Promise((resolve, reject) => {
            userService.changePassword(newPassword)
                .then(
                    (response) => {
                        dispatch(loadSuccess(loadInstanceId));
                        resolve(response);
                    })
                .catch((error) => {
                    dispatch(failure(loadInstanceId, error));
                    //reject(error);
                }
            );
        });
    };
}

export function setUser(user: Arzt[]): UserTypes.ISetUserAction {
    return {
        type: UserTypes.EUserActions.SET_USER,
        user: user
    }
}

export function setBetriebsstaette(betriebsstaette: Betriebsstaette): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {

        dispatch(checkBasisdatenBSStatus(betriebsstaette));
        dispatch({
            type: UserTypes.EUserActions.SET_BETRIEBSSTAETTE,
            betriebsstaette: betriebsstaette
        })
    }
};

export function setBetriebsstaettenAerzte(aerzte: Arzt[]): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {

        dispatch(checkBasisdatenArztStatus(aerzte));
        dispatch ({
            type: UserTypes.EUserActions.SET_BETRIEBSSTAETTEN_AERZTE,
            betriebsstaettenAerzte: aerzte
        })
    }
}

export function setEinverstaendnis(einverstaendnis: boolean): UserTypes.ISetEinverstaendnisAction {
    return {
        type: UserTypes.EUserActions.SET_EINVERSTAENDNIS,
        einverstaendnis: einverstaendnis
    }
};

export function logout(): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        const userService: UserService = new UserService();
        userService.logout()
        // clear WebSocke-listeners, does not seem necessary -> closes and reconnects upon login anyways
        const webSocketService = WebSocketService.getInstance();
        if (webSocketService === undefined) {
            console.log("WebSocketService ist nicht gesetzt!")
        } else {
            webSocketService.clearListeners();
            webSocketService.closeOnLogout();  
        }
        
        dispatch({type: "RESET"})
        dispatch(navigate("/login")) 
    }
}

export function reset() {
    return {
        type: "RESET"
    }
};

export function getInfo(): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        const id = uuid.v4();
        const userService: UserService = new UserService();
        userService.getServerInfo().then(
            (info) => {
                dispatch(setServerInfo(info.kennzeichnung));
                dispatch(setFachrichtung(info.fachrichtung));
            }
        ).catch(
            (response) => {
                dispatch(failure(id, response));
            } 
        );
    }
}


const containsInvalidNameCharacter: (nameString: string) => boolean = (nameString) => !(/^([ \u00c0-\u01ffa-zA-Z0-9._' -]{1,})+$/).test(nameString);
const containsInvalidCityCharacter: (nameString: string) => boolean = (nameString) => !(/^([ \u00c0-\u01ffa-zA-Z.' -]{1,})+$/).test(nameString);

export function checkBasisdatenBSStatus(betriebsstaette: Betriebsstaette): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        const errors = new Map<string, string>()
        const re = /^[0-9+]+$/;
        if (betriebsstaette.name == null || betriebsstaette.name === "" || containsInvalidNameCharacter(betriebsstaette.name)){
            errors.set("betriebsstaette.name", "Angaben zum Namen der Betriebsstätte müssen gemacht werden! Der Name darf keine Sonderzeichen enthalten.");}
        if (betriebsstaette.strasseHausnummer == null || betriebsstaette.strasseHausnummer === "" || containsInvalidNameCharacter(betriebsstaette.strasseHausnummer)){
            errors.set("betriebsstaette.strasseHausnummer", "Angaben zur Adresse der Betriebsstätte müssen gemacht werden! Die Adresse darf keine Sonderzeichen enthalten.");}
        if (betriebsstaette.plz == null || betriebsstaette.plz === "" || !( /^[0-9]{5}$/).test(betriebsstaette.plz)){
            errors.set("betriebsstaette.plz", "Die PLZ der Betriebsstätte muss angegeben werden, sie muss numerisch und fünfstellig sein!");}
        if (betriebsstaette.ort == null || betriebsstaette.ort === "" || containsInvalidCityCharacter(betriebsstaette.ort)){
            errors.set("betriebsstaette.ort", "Angaben zur Adresse der Betriebsstätte müssen gemacht werden! Die Adresse darf keine Sonderzeichen enthalten.");}
        if ((betriebsstaette.telefon == null || betriebsstaette.telefon === "" || !re.test(betriebsstaette.telefon)) && (betriebsstaette.fax == null || betriebsstaette.fax === "" || !re.test(betriebsstaette.fax)) && (betriebsstaette.telefonMobil == null || betriebsstaette.telefonMobil === "" || !re.test(betriebsstaette.telefonMobil))){
            errors.set("betriebsstaette.kontakt", "Angaben zu Kontaktdaten der Betriebsstätte müssen gemacht werden!");}

        dispatch({
            type: UserTypes.EUserActions.BASISDATEN_BS_ERRORS,
            fieldErrorMap: errors
        });
    }
}

export function lanrIsValid(lanr: string): boolean {
    if (lanr.length === 9) {
        /* const lanrArray: number[] = lanr.split("").map((item) => parseInt(item));
        let pruefsumme: number;
        pruefsumme = (10 -(((lanrArray[0]+lanrArray[2]+lanrArray[4])*4 + (lanrArray[1]+lanrArray[3]+lanrArray[5])*9) % 10));

        return (pruefsumme == lanrArray[6]) */
        return true
    } else
        return false
}


export function getBasisdatenArztStatus(role: keyof typeof RoleTypes, arzt: Arzt, index: number, errors: Map<string, string>): Map<string, string> {

    const plzError: boolean = arzt.plz != null && !( /^[0-9]{5}$/).test(arzt.plz);
    const re = /^[0-9+]+$/;
    if (arzt.lanr == null || arzt.lanr === "" || !lanrIsValid(arzt.lanr)){
        errors.set(String(index) + ".arzt.lanr", "Die LANR des Arztes ist ungültig oder muss angegeben werden!");}
    if (arzt.vorname == null || arzt.vorname === "" || containsInvalidNameCharacter(arzt.vorname)){
        errors.set(String(index) + ".arzt.vorname", "Der Vorname des Arztes muss angegeben werden!");}
    if (arzt.nachname == null || arzt.nachname === "" || containsInvalidNameCharacter(arzt.nachname)){
        errors.set(String(index) + ".arzt.nachname", "Der Nachname des Arztes muss angegeben werden!");} 
    if ((role === "HAUSARZT" || role === "BETRIEBSSTAETTE_HAUSARZT") && (arzt.haevgMitgliedsNummer == null || arzt.haevgMitgliedsNummer === "" || !( /^[0-9]{1,7}$/).test(arzt.haevgMitgliedsNummer))){
        errors.set(String(index) + ".arzt.haevgMitgliedsNummer", "Die HAEVG-Mitgliedsnummer des Arztes muss angegeben werden, sie muss numerisch sein und darf maximal 7 Ziffern enthalten!");}
    if ((role === "DERMATOLOGE" || role === "BETRIEBSSTAETTE_DERMATOLOGE") && (arzt.mediId == null || arzt.mediId === "" || !( /^[0-9]{8}$/).test(arzt.mediId))){
            errors.set(String(index) + ".arzt.mediId", "Die Medi-ID des Arztes muss angegeben werden, sie muss numerisch und achtstellig sein!");}
    if ((arzt.telefon == null || arzt.telefon === "" || !re.test(arzt.telefon)) && (arzt.fax == null || arzt.fax === "" || !re.test(arzt.fax)) && (arzt.telefonMobil == null || arzt.telefonMobil === "" || !re.test(arzt.telefonMobil))){
        errors.set(String(index) + ".arzt.kontakt", "Angaben zu Kontaktdaten des Arztes müssen gemacht werden!");}
    if (((arzt.strasseHausnummer != null && arzt.strasseHausnummer !== "") && (arzt.plz == null || arzt.plz === "") && (arzt.ort == null || arzt.ort === "")) || 
        ((arzt.strasseHausnummer == null || arzt.strasseHausnummer === "") && !(arzt.plz == null || arzt.plz === "") && (arzt.ort == null || arzt.ort === "")) ||
        ((arzt.strasseHausnummer == null || arzt.strasseHausnummer === "") && (arzt.plz == null || arzt.plz === "") && (arzt.ort != null && arzt.ort !== "")) ||
        (plzError && arzt.plz != null && arzt.plz !== "")
        ){
        errors.set(String(index) + ".arzt.adresse", "Die abweichende Adresse des Arztes muss vollständig und korrekt eingegeben werden!");}
    if (plzError && arzt.plz != null && arzt.plz !== ""
        ){
        errors.set(String(index) + ".arzt.plz", "Die PLZ muss numerisch und fünfstellig sein!");}

    return errors
}

export function checkBasisdatenArztStatus(aerzte: Arzt[]): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getStatus) => {
        const role = getStatus().user.role;
        let errors = new Map<string, string>()
        aerzte.forEach((arzt, index) => {
            errors = getBasisdatenArztStatus(role, arzt, index, errors);
        })
        if (aerzte.length> 0 && aerzte.every(arzt => (arzt.abrufArzt === false || arzt.abrufArzt == null))){
            errors.set("arzt.abrufArzt", "Mindestens ein Arzt muss die Berechtigung erteilen!");}

        dispatch({
            type: UserTypes.EUserActions.BASISDATEN_ARZT_ERRORS,
            fieldErrorMap: errors
        });
    }
}

