import React from 'react';
import { Box, Checkbox, CircularProgress, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { NotificationSubscriptionType, Notification } from 'telescan-core';
import { AllNotificationsPopup } from './all_notifications_popup';

interface IProps {
    isNewsSubscribed: boolean | null,
    dispatch_subscribe: (type: NotificationSubscriptionType) => void,
    dispatch_unsubscribe: (type: NotificationSubscriptionType) => void,
    isSubscriptionLoading: boolean,
    dispatch_getAllCurrentNotifications: () => void,
    allNotifications: Notification[],
    isAllNotifications: boolean,
    dispatch_setIsAllNotifications: (isAllNotifications: boolean) => void,
    isNotificationsLoading: boolean,
}

export class NotificationControl extends React.Component<IProps> {

    onSubscribeUnsubscribeClick = () => {
        if (this.props.isNewsSubscribed)
            this.props.dispatch_unsubscribe(NotificationSubscriptionType.NEWS);
        else
            this.props.dispatch_subscribe(NotificationSubscriptionType.NEWS);
    }

    onEyeButtonClick = () => {
        this.props.dispatch_getAllCurrentNotifications();
        this.props.dispatch_setIsAllNotifications(true);
    }

    render() {
        return (
            <Box className="bsnr-info-container">
                <Box className="arzt-info">
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                        Benachrichtigungen 
                    </Typography>
                </Box>
                <Paper elevation={0} className="popup-insert">
                    <TableContainer className="icd10-table">
                    <Table stickyHeader aria-label="sticky table">

                        <TableBody>
                            <TableRow key = 'showAll'>
                                <TableCell>{"Alle aktuelle Benachrichtigungen anzeigen"}</TableCell>
                                <TableCell align = 'right'>
                                    <IconButton onClick = {this.onEyeButtonClick}>
                                        <VisibilityIcon/>
                                    </IconButton>  
                                </TableCell>                          
                            </TableRow>
                            <TableRow key = 'subscribe'>
                                <TableCell>{"Neuigkeiten nach dem Login anzeigen"}</TableCell>
                                <TableCell align = 'right'>
                                    {this.props.isSubscriptionLoading ?
                                        <CircularProgress size = {40}/> :
                                        <Checkbox
                                            checked = {this.props.isNewsSubscribed != null && this.props.isNewsSubscribed}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange = {this.onSubscribeUnsubscribeClick}
                                            className = 'bsnr-info'
                                        />       
                                    }         
                                </TableCell>                          
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>         
                </Paper>
                <AllNotificationsPopup
                    allNotifications = {this.props.allNotifications}
                    isAllNotifications = {this.props.isAllNotifications}
                    dispatch_setIsAllNotifications = {this.props.dispatch_setIsAllNotifications}
                    isNotificationsLoading = {this.props.isNotificationsLoading}
                />
            </Box>
        )
    }
}

