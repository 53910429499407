import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Typography, Collapse, TablePagination, Avatar } from '@material-ui/core';
import { push } from 'connected-react-router';
import { connect, ConnectedProps } from 'react-redux';
import { Arzt, KonsilStatusType, Patient, TelescanConfig, UploadPath } from 'telescan-core';
import { KonsilPatient, KonsilRowDerma, KonsilStatus } from '.';
import { RootState } from '../../redux/reducers';
import { IconButton, Tooltip } from '@material-ui/core';
import { GetApp, SortByAlpha, ArrowDropDown, ArrowDropUp, CloudDownload } from '@material-ui/icons';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { loadArztKonsile, downloadKonsil, loading, setSuccessMessage, refreshKonsilStatus, downloadKonsilCDA } from '../../redux/actions';

import { TableSort, TablePaginationActions } from './konsil_table';
import { TherapieDringlichkeit } from 'telescan-core/lib/entities/konsil_abschluss';
import SortTableComponent from '../elements/sort_table';
import { assembleArztName } from '../../utils/utils';
import RefreshIcon from '@material-ui/icons/Refresh';
import { gdtExport } from '../../redux/actions/gdt_actions';
import uuid from 'uuid';


interface DermaTableProps {
    data: KonsilRowDerma[];
    metadataAdressiert: KonsilRowDerma[];
    metadataGerichtet: KonsilRowDerma[];
    totalSize: number;
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

interface IDermaTableState {
    isCollapsed: boolean,
    propertyName: string;
    direction: "asc" | "desc" | "default";
}

const mapStateToProps = (state: RootState, ownProps: DermaTableProps) => ({
    loading: state.general.loading,
    router: state.router,
    currentKonsil: state.konsile.current_konsil,
    konsilSortSearchFilterParams: state.konsile.konsilSortSearchFilterParams,
    isSupportEnabled: state.general.isSupportEnabled,
    initialisedServices: state.general.initialisedServices,
    gdtConfig: state.gdt.gdtConfiguration,
    ...ownProps
})

const mapDispatchToProps = {
    dispatch_loadKonsile: () => loadArztKonsile(),
    push: (path: string) => push(path),
    dispatch_downloadKonsil: (dokumentenId: string, uploadPath: keyof typeof UploadPath) => downloadKonsil(dokumentenId, uploadPath),
    dispatch_loading: (id: string) => loading(id),
    dispatch_successMessage: (id: string, message: string) => setSuccessMessage(id, message),
    dispatch_refreshKonsilStatus: (konsil_id: string) => refreshKonsilStatus(konsil_id),
    dispatch_gdtExport: (konsilId: string) => gdtExport(konsilId),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class DermaKonsilTable extends React.Component<TPropsFromRedux, IDermaTableState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            isCollapsed: false,
            propertyName: "konsilStatus",
            direction: "default"
        };
        this.handleDermaCollapse = this.handleDermaCollapse.bind(this);
        this.setPropertyNameAndDirection = this.setPropertyNameAndDirection.bind(this);

        this.emptyPatient.vorname = "";
        this.emptyPatient.nachname = "";
        this.emptyPatient.geschlecht = "UNBEKANNT";
    }

    private konsil(vorgangsId: string, isMetaData: boolean, dokumentenId: string | undefined, uploadPath: keyof typeof UploadPath) {
        if (isMetaData && dokumentenId) {
            this.props.dispatch_downloadKonsil(dokumentenId, uploadPath);
        } else
            this.props.push(this.props.router.location.pathname + "konsil/" + vorgangsId);
    }

    private emptyPatient = new Patient();
    private emptyArzt = new Arzt();

    private stringToColor(str: string) { // function to conver full name of Arzt to background color of avatar such that each Arzt will get unique color
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + (hash << 5) - hash;
        }
        return `hsl(${hash % 360}, 30%, 80%)`;
    }

    private getMetadataArzt(senderArzt: string | undefined, senderLanr: string) {
        if ((senderArzt === undefined || senderArzt === "") && (!senderLanr || senderLanr === ""))
            return "Keine Angabe";
        else {
            const name: string = senderArzt ? senderArzt : "";
            const lanr: string = senderLanr ? `[${senderLanr}]` : "";
            return `${lanr} ${name}`;
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentKonsil !== this.props.currentKonsil) {
            //this.props.dispatch_updateDermaKonsileAndMetaData(this.state.propertyName, this.state.direction)
            //this.props.dispatch_loadDermaKonsile();
            //this.props.dispatch_updateMetadatenAdressiert();
            //this.props.dispatch_updateMetadatenGerichtet();
        }
    }

    handleDermaCollapse = () => {
        this.setState({
            isCollapsed: !this.state.isCollapsed
        });
    }

    getDate(rowData: KonsilRowDerma) {
        if (rowData.date_send)
            return new Date(rowData.date_send).toLocaleString();
        else if (!rowData.date_send && rowData.date_created)
            return new Date(rowData.date_created).toLocaleString();
        else {
            return "keine Angabe"
        }
    }

    setPropertyNameAndDirection(propertyName: string, direction: "asc" | "desc" | "default"): void {
        this.setState({
            propertyName: propertyName,
            direction: direction
        })
    }

    handleGdtExportButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
        event.stopPropagation();
        this.props.dispatch_gdtExport(id);
    }

    handleDownloadPDF = (event: React.MouseEvent, konsilId: string, konsilStatus: keyof typeof KonsilStatusType) => {
        const telescanConfig = TelescanConfig.getInstance();
        this.props.dispatch_successMessage(uuid.v4(), "Der Anhang wird in einem neuen Tab geöffnet");
        event.stopPropagation();

        if (konsilStatus === "ABGEBROCHEN")
            window.open(`${telescanConfig.getConstant("SERVER_API_URL")}konsil/v2/${konsilId}/pdf/latest?access_token=${telescanConfig.getConstant("ACCESS_TOKEN")}`, "_blank");
        else
            window.open(`${telescanConfig.getConstant("SERVER_API_URL")}konsil/v2/${konsilId}/pdf?access_token=${telescanConfig.getConstant("ACCESS_TOKEN")}`, "_blank");
    }

    handleDownloadCDA = (event: React.MouseEvent, konsilId: string, konsilStatus: keyof typeof KonsilStatusType) => {
        event.stopPropagation();
        downloadKonsilCDA(konsilId, konsilStatus)
    }

    render() {
        return (
            <>
                <TableContainer className="konsil-table derma-version">
                    <Box className="mobile-table-header">
                        <TableSort />
                        <IconButton aria-label="delete">
                            <SortByAlpha />
                        </IconButton>
                    </Box>
                    <Table stickyHeader aria-label="sticky table" className="table-connector">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: "3%" }}></TableCell>
                                <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "patient"} propertyName="konsilPatient.vorname" title="Patient " />
                                <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "konsilID"} propertyName="id" title="Konsil ID " />
                                <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "zeitpunkt"} propertyName="datecreated" title="Zeitpunkt " />
                                <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "konsilStatus"} propertyName="konsilStatus" title="Status " />
                                {this.props.initialisedServices.length > 1 &&
                                    <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "uploadPath"} propertyName="uploadPath" title="Versand über "/>
                                }
                                <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "adressat"} propertyName="konsiliar.nachname" title="LANR " />
                                <TableCell align="right"></TableCell>
                                </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.konsilSortSearchFilterParams.pageNb === 0 && this.props.metadataAdressiert.map(rowData => {
                                let className: string;
                                if (rowData.konsilStatus === "ABGEBROCHEN")
                                    className = rowData.konsil?.statusAenderung? "abgebrochen-konsil-highlight ": (rowData.konsil?.konsilStatus === "BEFUNDET" || rowData.konsil?.konsilStatus === "RUECKFRAGE")? "highlight": "";
                                else
                                    className = rowData.konsil?.statusAenderung? "bold-highlight ": (rowData.konsil?.konsilStatus === "BEFUNDET" || rowData.konsil?.konsilStatus === "RUECKFRAGE")? "highlight": "";
                                return (
                                    <TableRow
                                        className={"table-row " + className}
                                        key = {rowData.id}
                                        onClick={() => this.konsil(rowData.id, rowData.isMetaData, rowData.dokumentenId, rowData.uploadPath)}
                                    >
                                        <TableCell className="konsil-patient" style={{ width: "3%" }}/>
                                        <TableCell className="konsil-patient">
                                            <KonsilPatient patient={rowData.patient || new Patient()}/>
                                        </TableCell>
                                        <TableCell className="konsil-id" scope="row">
                                            {rowData.id && rowData.id.substring(0, 8)}
                                        </TableCell>
                                        <TableCell className="konsil-date">
                                            {rowData.letzterBearbeitungszeitpunkt && new Date(rowData.letzterBearbeitungszeitpunkt).toLocaleString()}
                                        </TableCell>
                                        <TableCell className="konsil-status">
                                            <KonsilStatus hasPvsPatient={true} bearbeitungsStatus={rowData.bearbeitungsStatus} status={rowData.konsilStatus} isMetaData={rowData.isMetaData} role="DERMATOLOGE"/>
                                        </TableCell>
                                        {this.props.initialisedServices.length > 1 &&
                                            <TableCell>{rowData.uploadPath ? rowData.uploadPath : UploadPath.HPM}</TableCell>
                                        }
                                        <TableCell className="konsil-receiver">
                                            {this.getMetadataArzt(rowData.senderArzt.nachname, rowData.senderLanr)}
                                        </TableCell>
                                        <TableCell align="right" className="konsil-actions"/>
                                    </TableRow>)}
                            )}
                            { (this.props.konsilSortSearchFilterParams.pageNb === 0 && this.props.metadataAdressiert.length > 0) &&
                                <TableRow style={{height: "8px"}}/>
                            }
                            {this.props.data.map(rowData => {

                                const className: string = rowData.konsil?.statusAenderung? "bold-highlight ": (rowData.konsil?.konsilStatus === "BEAUFTRAGT" || rowData.konsil?.konsilStatus === "BEANTWORTET")? "highlight": "";
                                const avatarName: string = rowData?.konsil?.konsiliar ?
                                `${rowData?.konsil?.konsiliar.vorname} ${rowData?.konsil?.konsiliar.nachname}` :
                                "";
                                const avatarLabel: string = (rowData?.konsil?.konsiliar && rowData?.konsil?.konsiliar.vorname && rowData?.konsil?.konsiliar.nachname) ?
                                rowData?.konsil?.konsiliar?.vorname[0] + rowData?.konsil?.konsiliar?.nachname[0] :
                                "";
                                let cssProperties = {};
                                cssProperties['--btn-bg-color'] = this.stringToColor(avatarName); // apply color generated from Arzt full name to css class
                                return (
                                    <TableRow
                                        className={"table-row " + className}
                                        key={rowData.id}
                                        onClick={() => this.konsil(rowData.id, rowData.isMetaData, rowData.dokumentenId, rowData.uploadPath)}
                                    >
                                        {rowData.konsil?.konsiliar ?
                                            <TableCell className="doctor-avatar" style={{ width: "3%" }}>
                                                <Tooltip placement = 'top' title = {avatarName}>
                                                    <Avatar className = "avatar" style = {cssProperties}>{avatarLabel}</Avatar>
                                                </Tooltip>
                                            </TableCell> :
                                            <TableCell className="konsil-patient" style={{ width: "3%" }}/>
                                        }
                                        <TableCell className="konsil-patient">
                                            <KonsilPatient patient={rowData.patient || new Patient()} />
                                        </TableCell>
                                        <TableCell className="konsil-id" scope="row">
                                            {rowData.id.substring(0, 8)}
                                        </TableCell>
                                        <TableCell className="konsil-date">{this.getDate(rowData)}</TableCell>
                                        <TableCell className="konsil-status">
                                            <KonsilStatus
                                                therapiedringlichkeit={(rowData?.konsil?.konsiliarbericht?.therapieDringlichkeit) ? TherapieDringlichkeit[rowData?.konsil?.konsiliarbericht?.therapieDringlichkeit]: undefined}
                                                hasPvsPatient={true}
                                                bearbeitungsStatus={rowData.bearbeitungsStatus}
                                                status={rowData.konsilStatus}
                                                isMetaData={rowData.isMetaData} role="DERMATOLOGE"
                                            />
                                        </TableCell>
                                        {this.props.initialisedServices.length > 1 &&
                                            <TableCell>{rowData.uploadPath ? rowData.uploadPath : UploadPath.HPM}</TableCell>
                                        }
                                        <TableCell className="konsil-receiver">{assembleArztName(rowData.senderArzt)}</TableCell>
                                        <TableCell align="right" className="konsil-actions">
                                            <Box className = 'flex-row-center justify-end'>
                                                {this.props.isSupportEnabled &&
                                                    <Tooltip title = "Bearbeitungsstatus aktualisieren">
                                                        <IconButton
                                                            onClick = {(event) => {
                                                                event.stopPropagation();
                                                                this.props.dispatch_refreshKonsilStatus(rowData.id);
                                                            }}
                                                        >
                                                            <RefreshIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                {(rowData.isMetaData === false && rowData.konsilStatus !== "IN_ARBEIT") &&
                                                    <Tooltip title="Download PDF" placement="top">
                                                        <IconButton
                                                            size="small"
                                                            aria-label="Download PDF"
                                                            onClick={(event) => this.handleDownloadPDF(event, rowData.id, rowData.konsilStatus)}
                                                        >
                                                            <GetApp />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                {rowData.konsilStatus !== "IN_ARBEIT" && this.props.isSupportEnabled &&
                                                    <Tooltip title="Download CDA" placement="top">
                                                        <IconButton
                                                            size="small"
                                                            aria-label="Download CDA"
                                                            onClick={(event) => this.handleDownloadCDA(event, rowData.id, rowData.konsilStatus)}
                                                        >
                                                            <CloudDownload />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                {this.props.gdtConfig.active && rowData.konsilStatus !== "IN_ARBEIT" &&
                                                    <Tooltip title="GDT export" placement="top">
                                                        <IconButton
                                                            size="small"
                                                            aria-label="GDT export"
                                                            onClick={(event) => this.handleGdtExportButton(event, rowData.id || "")}
                                                        >
                                                            <ImportExportIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                            }
                                            </Box>
                                        </TableCell>
                                    </TableRow>)}
                            )}
                            <TableRow className="table-footer-pagination">
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    colSpan={this.props.initialisedServices.length > 1 ? 8 : 7}
                                    count={(this.props.totalSize !== -1)? this.props.totalSize: 0}
                                    rowsPerPage={this.props.konsilSortSearchFilterParams.numberPerPage}
                                    page={this.props.konsilSortSearchFilterParams.pageNb}
                                    onPageChange={this.props.handleChangePage}
                                    onRowsPerPageChange={this.props.handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                {this.props.metadataGerichtet != null && this.props.metadataGerichtet.length !== 0 ?
                    <Box className="derma-table-container ">
                        <Typography
                            className="derma-header"
                            variant="subtitle1"
                            onClick={this.handleDermaCollapse}
                        >
                            {this.props.metadataGerichtet.length}
                            Extern verfügbare Konsile
                            {(this.state.isCollapsed)? <ArrowDropDown/>: <ArrowDropUp/>}
                        </Typography>
                        <Collapse in={this.state.isCollapsed} >
                            <Table className="derma-table table-connector">
                                <TableBody>
                                    {this.props.metadataGerichtet.map(rowData => {
                                        this.emptyArzt.nachname = rowData.senderArzt.nachname || "";
                                        return (
                                            <TableRow
                                                hover
                                                className="table-row"
                                                key={rowData.id}
                                                onClick={() => {
                                                    if (rowData.id) {
                                                        this.konsil(rowData.id, true, rowData.dokumentenId, rowData.uploadPath)
                                                    }
                                                }}
                                            >
                                                <TableCell className="konsil-patient">
                                                    <KonsilPatient patient={this.emptyPatient}/>
                                                </TableCell>
                                                <TableCell className="konsil-id" scope="row">
                                                    {rowData.id?.substring(0, 8)}
                                                </TableCell>
                                                <TableCell className="konsil-date">Keine Angabe</TableCell>
                                                {/* {this.props.loading.includes(rowData.vorgangsId || "") ? // show loading progress when external konsil clicked
                                                    <TableCell className="konsil-status"><KonsilStatus hasPvsPatient={true} bearbeitungsStatus={"DOWNLOAD"} status="VERFUEGBAR" isMetaData={true} role="DERMATOLOGE" /></TableCell>:
                                                    <TableCell className="konsil-status"><KonsilStatus hasPvsPatient={true} bearbeitungsStatus={"OK"} status="VERFUEGBAR" isMetaData={true} role="DERMATOLOGE" /></TableCell>
                                                } */}
                                                <TableCell className="konsil-status">
                                                    <KonsilStatus hasPvsPatient={true} bearbeitungsStatus={rowData.bearbeitungsStatus} status="VERFUEGBAR" isMetaData={true} role="DERMATOLOGE"/>
                                                </TableCell>
                                                    {/*<TableCell className="konsil-status"><KonsilStatus hasPvsPatient={true} bearbeitungsStatus={"OK"} status="VERFUEGBAR" isMetaData={true} role="DERMATOLOGE" /></TableCell>*/}
                                                <TableCell className="konsil-receiver">{assembleArztName(this.emptyArzt)}</TableCell>
                                                <TableCell align="right" className="konsil-actions"/>
                                            </TableRow>)}
                                        )}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </Box>
                    :
                    <Box className="derma-table-container ">
                        <Typography className="derma-header" variant="subtitle1" onClick={this.handleDermaCollapse}>
                            Extern verfügbare Konsile
                            {(this.state.isCollapsed)? <ArrowDropDown/>: <ArrowDropUp/>}
                        </Typography>
                        <Collapse in={this.state.isCollapsed}>
                            <Box className="derma-table table-connector pad-left pad-top pad-bottom full-span">
                                <Typography>Es sind keine gerichteten Konsile verfügbar.</Typography>
                            </Box>
                        </Collapse>
                    </Box>
                }
            </>
        );
    }
}

export const DermaKonsilTableContainer = connector(DermaKonsilTable);
