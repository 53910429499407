import React, {ChangeEvent} from 'react';
import {connect, ConnectedProps} from "react-redux";
import {Arzt, DermatologistInfo, EmpfaengerTyp, Failure, Konsil, KonsilEmpfaengerGruppe, KonsilLimitSizes, KonsilStatusType, TIConnectorEvent, UploadPath,} from 'telescan-core';
import {
    addSendFailure,
    deleteKonsilAnamneseErrors,
    deleteKonsilFrageErrors,
    deleteKonsilPatientErrors,
    deleteLocationErrors,
    deleteTabStatus,
    loadArztKonsile,
    loadKonsil,
    loadKonsiliare,
    navigate,
    setCdaSize,
    setSuccessMessage,
    updateKonsil,
    handleVersandKonsil,
    setIsSendingKonsil,
    setIsComfortVersand,
    setComfortVersandPassword,
    clearPdfString,
    getPdfString,
    getHPMInfo,
    setIsHpmAvailable,
    downloadKonsilCDA,
} from '../../../../redux/actions';

import {RootState} from "../../../../redux/reducers";
import uuid from 'uuid';

// material components
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Paper,
    Tab,
    Tabs,
    Tooltip,
    Typography,
} from '@material-ui/core/';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import PropTypes from 'prop-types';

// Icons
import {StartSendPopup} from '../../sending_results_popup';
import {checkCdaSize} from '../../../../utils/utils';
import SimpleModalContainer from '../../../elements/simple_modal';
import {CdaSizeComponent} from '../../../elements/cda_size';
import {withTypingTimeout, WithTypingTimeoutsProps} from '../../../../utils/with_typing_timeout';
import PdfInline from '../../../elements/pdf_inline';
import ZertifikatPasswortComponent from '../../../elements/zertifikat_passwort';
import { KonsilValidationBadge } from '../../../elements/konsil_validation_badge';
import { getAllDermatologists, getTiConnectionStatus, setIsTiConnected, setIsKonsilSigned, setTiConnectorEvent, signKonsil } from '../../../../redux/actions/kim_actions';
import ConnectionBadge from '../../../elements/connection_badge';
import { TabLabel } from './tab_label';
import { KimTabPanel } from './kim_tab_panel';
import { HpmSingleTabPanel } from './hpm_single_tab_panel';
import { HpmGroupTabPanel } from './hpm_group_tab_panel';
import { SignatureDialogContainer } from '../../../settings/kim_configuration/signature_dialog';
import { cancelSignKonsil } from '../../../../redux/actions/ti_connector_actions';


interface IState {
    konsil: Konsil,
    tabValue: React.SetStateAction<number>,
    filteredKonsiliare: Arzt[],
    anchorEl: HTMLInputElement | null,
    selectedReceiver: Arzt | null,
    konsiliare: Arzt[],
    empfaengerTyp: keyof typeof EmpfaengerTyp,
    selectedReceiverGroup: KonsilEmpfaengerGruppe,
    password: string,
    isSelectedReciever: boolean;
    typingReminder: string | undefined;
    isEmptySearch: boolean;
    isMouseMoving: boolean;
    hasPasswordChanged: boolean;
    isDermaSearchOpen: boolean;
    selectedKimReceiver: DermatologistInfo | null;
    isSignatureDialogOpen: boolean;
    isSignKonsilButtonDisabled: boolean;
}

interface IProps {
    isOpenReceiverModal: boolean,
    toggleReceiverModal: () => void,
    pdfPreviewData: any,
    createPdfPreview: (konsilId: string, konsilStatus: keyof typeof KonsilStatusType) => void,
    dispatch_failure: (id: string, failureObject: Failure) => void,
    isPdfLoading: boolean,
    receiverGroups: KonsilEmpfaengerGruppe[],
}

const mapStateToProps = (state: RootState, ownProps: IProps) => ({
    ...ownProps,
    isSupportEnabled: state.general.isSupportEnabled,
    userDetails: state.user.user,
    currentKonsil: state.konsile.current_konsil,
    role: state.user.role,
    location: state.router.location.pathname,
    cdaSize: state.konsile.cdaSize,
    isSendingKonsil: state.send_download_konsil.isSendingKonsil,
    konsilValidationStatus: state.konsile.konsilValidationStatus,
    konsilValidationError: state.konsile.konsilValidationError,
    isComfortVersand: state.general.isComfortVersand,
    comfortVersandPassword: state.general.comfortVersandPassword,
    isKimConnected: state.kim.isTiConnected,
    isHPMConnected: state.general.isHpmAvailable,
    initialisedServices: state.general.initialisedServices,
    availableDermatologists: state.kim.dermatologists,
    tiConnectorEvent: state.kim.tiKonnectorEvent,
    isKonsilSigned: state.kim.isKonsilSigned,
    useSignature: state.general.useSignature,
    isDermaListLoading: state.kim.isDermaListLoading,
    isKimEnabled: state.general.isKimEnabled,
})

const mapDispatchToProps = {
    updateKonsil: (konsil: Konsil, previousKonsil: Konsil) => updateKonsil(konsil, previousKonsil),
    dispatch_loadKonsiliare: (suchtext: string, empfaengerTyp: keyof typeof EmpfaengerTyp) => loadKonsiliare(suchtext, empfaengerTyp),
    dispatch_successMessage: (id: string, message: string) => setSuccessMessage(id, message),
    dispatch_addSendFailure: (konsilID: string, failure: Failure) => addSendFailure(konsilID, failure),
    dispatch_navigate: (url: string) => navigate(url),
    dispatch_loadArztKonsile: () => loadArztKonsile(),
    dispatch_loadKonsil: (id: string) => loadKonsil(id),
    dispatch_setCdaSize: (size: number) => setCdaSize(size),
    dispatch_deleteTabStatus: (konsilId: string) => deleteTabStatus(konsilId),
    dispatch_deleteKonsilAnamneseErrors: (konsilId: string) => deleteKonsilAnamneseErrors(konsilId),
    dispatch_deleteKonsilFrageErrors: (konsilId: string) => deleteKonsilFrageErrors(konsilId),
    dispatch_deleteKonsilPatientErrors: (konsilId: string) => deleteKonsilPatientErrors(konsilId),
    dispatch_deleteLocationErrors: (konsilId: string) => deleteLocationErrors(konsilId),
    dispatch_handleVersandKonsil: (uploadPath: keyof typeof UploadPath, konsilId: string, password: string, eventText: string, empfaengerGruppeId?: string, empfaengergruppeBezeichnung?: string) => handleVersandKonsil(uploadPath, konsilId, password, eventText, empfaengerGruppeId, empfaengergruppeBezeichnung),
    dispatch_setIsSendingKonsil: (isStartSendkonsilPopup: boolean) => setIsSendingKonsil(isStartSendkonsilPopup),
    dispatch_setIsComfortVersand: (isComfortVersand: boolean) => setIsComfortVersand(isComfortVersand),
    dispatch_setComfortVersandPassword: (comfortVersandPassword: string) => setComfortVersandPassword(comfortVersandPassword),
    dispatch_getPdfString: (konsilId: string, konsilStatus: keyof typeof KonsilStatusType) => getPdfString(konsilId, konsilStatus),
    dispatch_clearPdfString: () => clearPdfString(),
    dispatch_getKimConnectionStatus: () => getTiConnectionStatus(),
    dispatch_getHPMInfo: () => getHPMInfo(),
    dispatch_setIsKimConnected: (isConnected: boolean) => setIsTiConnected(isConnected),
    dispatch_setIsHpmAvailable: (isConnected: boolean) => setIsHpmAvailable(isConnected),
    dispatch_getAllDermatologists: () => getAllDermatologists(),
    dispatch_signKonsil: (konsilId: string, followingStatus: keyof typeof KonsilStatusType) => signKonsil(konsilId, followingStatus),
    dispatch_setTiConnectorEvent: (tiConnectorEvent: TIConnectorEvent) => setTiConnectorEvent(tiConnectorEvent),
    dispatch_setIsKonsilSigned: (isKonsilSigned: boolean) => setIsKonsilSigned(isKonsilSigned),
    dispatch_cancelSignKonsil: () => cancelSignKonsil(),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>
type Props = TPropsFromRedux & WithTypingTimeoutsProps


export class SendKonsilDialog extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            konsil: props.currentKonsil || new Konsil(),
            tabValue: 0,
            filteredKonsiliare: [],
            anchorEl: null,
            selectedReceiver: null,
            konsiliare: [],
            empfaengerTyp: 'NAME',
            selectedReceiverGroup: new KonsilEmpfaengerGruppe(),
            password: "",
            isSelectedReciever: false,
            typingReminder: undefined,
            isEmptySearch: true,
            isMouseMoving: false,
            hasPasswordChanged: false,
            isDermaSearchOpen: false,
            selectedKimReceiver: null,
            isSignatureDialogOpen: false,
            isSignKonsilButtonDisabled: true,
        };
    }

    componentDidMount() {
        this.setState({selectedReceiverGroup: this.props.receiverGroups[0]});
        this.props.comfortVersandPassword && this.props.comfortVersandPassword.length !== 0 &&
        this.setState({password: this.props.comfortVersandPassword});
        if (!this.props.initialisedServices.includes("HPM")) {
            this.setState({tabValue: 2});
        }
        if (!this.props.initialisedServices.includes("KIM")) {
            this.setState({tabValue: 0});
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.currentKonsil !== this.props.currentKonsil) {
            this.setState({konsil: this.props.currentKonsil || new Konsil()});
        }
        if (prevProps.receiverGroups !== this.props.receiverGroups) {
            this.setState({selectedReceiverGroup: this.props.receiverGroups[0]});
        }
    }

    private updateKonsil = (previousKonsil: Konsil): Promise<any> => {
        return new Promise((resolve) => {
            resolve(this.props.updateKonsil(this.state.konsil, previousKonsil));
        })
    }

    handleTabChange = (_event: ChangeEvent, newTabValue: React.SetStateAction<number>) => {
        const previousKonsil: Konsil = {...this.state.konsil};
        this.setState({
            konsil: {
                ...this.state.konsil,
                konsiliar: undefined,
            },
            selectedReceiver: null,
            selectedKimReceiver: null
        }, () => this.updateKonsil(previousKonsil));
        this.handleListReset();
        if ((newTabValue === 0 || newTabValue === 1) && this.props.initialisedServices.includes("HPM")) {
            this.setState({
                tabValue: newTabValue,
                anchorEl: null,
            });
            if (newTabValue === 0 && !this.state.konsil.patienteneinwilligung?.einwilligungVersandUmwandlung)
                this.changePatientConsent(true);
        }
        if ((newTabValue === 2) && this.props.initialisedServices.includes("KIM")) {
            this.setState({
                tabValue: newTabValue,
                anchorEl: null,
            });
        }

    };

    handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({hasPasswordChanged: true});
        this.setState({password: e.target.value});
    }

    setKonsiliare = (konsiliare: Arzt[], target: HTMLInputElement): Arzt[] => {
        let threshold: number;
        (this.state.empfaengerTyp === 'NAME') ? threshold = 3 : threshold = 1;

        if (target.value.length >= threshold) {
            this.setState({konsiliare: konsiliare});
            return konsiliare
        } else {
            return []
        }
    };

    search = (konsiliare: Arzt[], anchor: HTMLInputElement): Arzt[][] => {
        let value: string = anchor.value;
        let currentList: Arzt[] = konsiliare
        let newList = currentList.filter(item => {
            let arztInfo: string;
            if (EmpfaengerTyp[this.state.empfaengerTyp] === "Straße" || EmpfaengerTyp[this.state.empfaengerTyp] === "Postleitzahl" || EmpfaengerTyp[this.state.empfaengerTyp] === "Ort") {
                arztInfo = item.betriebsstaette?.strasseHausnummer + " " + item.betriebsstaette?.plz + " " + item.betriebsstaette?.ort || ""
            } else {
                arztInfo = item.vorname + " " + item.nachname
            }
            const lc = arztInfo.toLowerCase();
            // change search term to lowercase to deal with capitalization
            const lc_filter = value.toLowerCase();

            return lc.includes(lc_filter);
        });
        this.setState({
            filteredKonsiliare: newList,
            anchorEl: anchor
        });
        return [currentList, newList]
    };

    handleSearchChange = (e: ChangeEvent<HTMLInputElement>): string => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let currentList: Arzt[];
        let newList: Arzt[] = [];
        let anchor: HTMLInputElement | null = null;
        let threshold: number;

        (this.state.empfaengerTyp === 'NAME') ? threshold = 3 : threshold = 1;

        if (e.target.value.length === 0) {
            this.setState({
                isEmptySearch: true
            })
        } else
            this.setState({
                isEmptySearch: false
            })

        if (e.target.value.length < threshold) {
            //currentList = [];
        }

        if (e.target.value.length === threshold) {
            if (this.props.userDetails.length === 1) {
                let target = e.currentTarget;
                this.props.dispatch_loadKonsiliare(e.target.value, this.state.empfaengerTyp).then(
                    konsiliare => this.setKonsiliare(konsiliare, target)
                ).then(
                    konsiliare => this.search(konsiliare, target)
                )
            } else
                console.log("Bitte Arzt auswählen")
        }

        if (e.target.value !== "" && e.target.value.length > threshold) { //search bar not empty
            [ , newList] = this.search(this.state.konsiliare, e.currentTarget);
        } else { // search bar is empty
            newList = []
            this.setState({
                filteredKonsiliare: newList,
                anchorEl: anchor
            });
        }

        return e.target.value;
    }

    handleMenuOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        this.setState({
            anchorEl: event.target as HTMLInputElement
        });
    };

    handleMenuClose = () => {
        this.setState({anchorEl: null});
    };

    handleListReset = () => {
        this.setState({
            filteredKonsiliare: [],
            isSelectedReciever: false,
        });
    }

    handleMenuSelect = (arzt: Arzt) => {
        // select dermatologe to send konsil to
        // also close the menu
        this.setState({
            isSelectedReciever: false
        })

        const previousKonsil: Konsil = {...this.state.konsil};
        // set konsiliar
        this.setState({
            konsil: {
                ...this.state.konsil,
                konsiliar: arzt
            }
        }, () => {
            this.updateKonsil(previousKonsil)
        });
        this.setState({
            selectedReceiver: arzt,
            anchorEl: null
        });
    };

    handleChangeEmpfaengerTyp = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({empfaengerTyp: event.target.value as 'NAME' | 'STRASSE' | 'PLZ' | 'ORT'});
    }

    handleChangeHpmGroupAutocomplete = (_event: React.ChangeEvent<{ value: unknown }>, newValue: KonsilEmpfaengerGruppe | null) => {
        newValue && this.setState({selectedReceiverGroup: newValue})
    }

    handleVersendenKonsilauftragAdressiert = () => {
        if (this.props.currentKonsil.id) {
                this.props.toggleReceiverModal();
                if (this.props.isComfortVersand && this.props.comfortVersandPassword !== "" && !this.state.hasPasswordChanged)
                    this.props.dispatch_handleVersandKonsil(UploadPath.HPM, this.props.currentKonsil.id, this.props.comfortVersandPassword, "Versand des Konsils mit der ID");
                else
                    this.props.dispatch_handleVersandKonsil(UploadPath.HPM, this.props.currentKonsil.id, this.state.password, "Versand des Konsils mit der ID");
        } else {
                const failureObj: Failure = new Failure();
                failureObj.error = "Das Senden des Konsil hat fehlgeschlagen. Das Konsil hat keine id!";
                this.props.dispatch_failure(uuid.v4(), failureObj);
                this.props.toggleReceiverModal();
        }
    }

    handleVersendenKonsilauftragGerichtet = () => {
        let empfaengerGruppeId: string | undefined;
        let empfaengergruppeBezeichnung: string | undefined;

        empfaengerGruppeId = this.state.selectedReceiverGroup?.empfaengergruppeIdentifikator;
        empfaengergruppeBezeichnung = this.state.selectedReceiverGroup?.empfaengergruppeBezeichnung;

        if (this.props.currentKonsil.id) {
            this.props.toggleReceiverModal();
            if (this.props.isComfortVersand && this.props.comfortVersandPassword !== "")
                this.props.dispatch_handleVersandKonsil(UploadPath.HPM, this.props.currentKonsil.id, this.props.comfortVersandPassword, "Versand des Konsils mit der ID", empfaengerGruppeId || "", empfaengergruppeBezeichnung || "");
            else
                this.props.dispatch_handleVersandKonsil(UploadPath.HPM, this.props.currentKonsil.id, this.state.password, "Versand des Konsils mit der ID", empfaengerGruppeId || "", empfaengergruppeBezeichnung || "");
        }
        else {
            const failureObj: Failure = new Failure();
            failureObj.error = "Das Senden des Konsil hat fehlgeschlagen. Das Konsil hat keine id!";
            this.props.dispatch_failure(uuid.v4(), failureObj);
            this.props.toggleReceiverModal();
        }
    }

    closeStartSendPopup = () => {
        this.props.dispatch_setIsSendingKonsil(false);
        this.props.dispatch_navigate("/");
    }


    timeHandleSearchChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        this.props.timeOutMethod(e, this.handleSearchChange, this.setTypingReminder, 500)
    }

    setTypingReminder = (value: string): void => {
        if (value.length < 3 && this.state.empfaengerTyp === 'NAME')
            this.setState({
                typingReminder: "Bitte geben Sie mindestens 3 Zeichen ein, um die Suche auszuführen."
            })
        else
            this.setState({
                typingReminder: undefined
            })
    }

    handleConsentGroupDispatchCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        const previousKonsil: Konsil = {...this.state.konsil};

        this.state.konsil.patienteneinwilligung && this.setState({
                konsil: {
                    ...this.state.konsil,
                    patienteneinwilligung: {
                        ...this.state.konsil.patienteneinwilligung,
                        einwilligungVersandUmwandlung: !event.target.checked,
                        dateUmwandlungseinwilligung: Date.now()
                    }
                }
            }, //reload pdf preview
            () => {
                this.updateKonsil(previousKonsil)
                .then(() => {
                    this.props.dispatch_clearPdfString();
                    this.props.dispatch_getPdfString(this.props.currentKonsil.id || "", "BEAUFTRAGT");
                });
            })
    }

    changePatientConsent = (checked: boolean) => {

        const previousKonsil: Konsil = {...this.state.konsil};

        this.state.konsil.patienteneinwilligung && this.setState({
                konsil: {
                    ...this.state.konsil,
                    patienteneinwilligung: {
                        ...this.state.konsil.patienteneinwilligung,
                        einwilligungVersandUmwandlung: checked,
                        dateUmwandlungseinwilligung: Date.now()
                    }
                }
            }, //reload pdf preview
            () => {
                this.updateKonsil(previousKonsil)
                .then(() => {
                    this.props.dispatch_clearPdfString();
                    this.props.dispatch_getPdfString(this.props.currentKonsil.id || "", "BEAUFTRAGT");
                });
            })
    }

    isSendDisabled = () => {
        if (this.state.tabValue === 0) {
            return (!this.props.pdfPreviewData || this.props.pdfPreviewData === "" || this.props.isSendingKonsil  ||
            //!this.state.konsil.patienteneinwilligung?.einwilligungVersandGerichtet ||
            (this.state.password === "" && this.props.comfortVersandPassword === "") || this.props.konsilValidationStatus !== "OK" ||
            this.state.konsil.pvsPatient == null || !checkCdaSize(this.props.cdaSize, "IN_ARBEIT", UploadPath.HPM)) ||
            !this.props.isHPMConnected ?
            true :
            false
        } else if (this.state.tabValue === 1) {
            return (!this.props.pdfPreviewData || this.props.pdfPreviewData === "" || !this.state.selectedReceiver ||
            this.props.isSendingKonsil || (this.state.password === "" && this.props.comfortVersandPassword === "") || this.props.konsilValidationStatus !== "OK" ||
            this.state.konsil.pvsPatient == null || !checkCdaSize(this.props.cdaSize, "IN_ARBEIT", UploadPath.HPM)) ||
            !this.props.isHPMConnected ?
            true :
            false
        } else if (this.state.tabValue === 2) {
            return (!this.props.pdfPreviewData || this.props.pdfPreviewData === "" ||
            this.props.isSendingKonsil || this.props.konsilValidationStatus !== "OK" ||
            !this.state.selectedKimReceiver ||
            this.state.konsil.pvsPatient == null || !checkCdaSize(this.props.cdaSize, "IN_ARBEIT", UploadPath.KIM)) ||
            !this.props.isKimConnected ?
            true :
            false
        }
    }

    handleOpenCloseKimAutocomplete = (isDermaSearchOpen: boolean) => {
        this.setState({isDermaSearchOpen: isDermaSearchOpen});
        if (isDermaSearchOpen)
            this.props.dispatch_getAllDermatologists();
    }

    handleInputChangeKimAutocomplete = (reason: string) => {
        if (reason === "clear")
            this.setState({selectedKimReceiver: null})
    }

    handleChangeKimAutocomplete = (_event: React.ChangeEvent<{ value: unknown }>, newValue: DermatologistInfo | null) => {
        const previousKonsil: Konsil = {...this.state.konsil};
        if (newValue) {
            this.setState({selectedKimReceiver: newValue});
            const konsiliar = new Arzt();
            konsiliar.lanr = newValue.lanr;
            konsiliar.bsnr = newValue.bsnr;
            konsiliar.vorname = newValue.firstName;
            konsiliar.nachname = newValue.lastName;
            konsiliar.titel = newValue.title;
            konsiliar.plz = newValue.postalCode;
            konsiliar.ort = newValue.city;
            konsiliar.kimEmail = newValue.kimEmailAddress;
            this.setState({
                konsil: {
                    ...this.state.konsil,
                    konsiliar: konsiliar,
                    replyTo: newValue.kimEmailAddress
                }},
                () => this.updateKonsil(previousKonsil)
            )
        }
    }

    handleSignatureDialogOpen = () => {
        this.props.dispatch_setTiConnectorEvent(new TIConnectorEvent());
        this.setState({isSignatureDialogOpen: true});
        this.props.dispatch_signKonsil(this.props.currentKonsil.id || "", "BEAUFTRAGT");
    }

    handleSignatureDialogClose = () => {
        this.setState({isSignatureDialogOpen: false});
        //this.props.dispatch_setTiConnectorEvent(new TIConnectorEvent());
    }

    triggerSendKimKonsilOnSignSuccess = () => {
        if (this.props.currentKonsil.id) {
            this.props.toggleReceiverModal();
            this.props.dispatch_handleVersandKonsil(UploadPath.KIM, this.props.currentKonsil.id, "", "Versand des Konsils mit der ID");
       } else {
            const failureObj: Failure = new Failure();
            failureObj.error = "Das Senden des Konsil hat fehlgeschlagen. Das Konsil hat keine id!";
            this.props.dispatch_failure(uuid.v4(), failureObj);
            this.props.toggleReceiverModal();
       }
       this.props.dispatch_setIsKonsilSigned(false);
    }

    handleDownloadCdaDokument = () => {
        downloadKonsilCDA(this.props.currentKonsil.id || "", "BEAUFTRAGT")
    }

    handleClickVersenden = (): void => {
        if (this.props.isComfortVersand && (this.props.comfortVersandPassword === "" || this.state.hasPasswordChanged)) {
            this.props.dispatch_setComfortVersandPassword(this.state.password);
        }
        this.props.dispatch_deleteTabStatus(this.props.currentKonsil.id || "");
        const previousKonsil: Konsil = {...this.state.konsil};
        if (this.state.tabValue === 1)
            this.handleVersendenKonsilauftragAdressiert();
        else if (this.state.tabValue === 0) {
            this.setState({
                konsil: {
                    ...this.state.konsil,
                    konsiliar: undefined
                }
            }, () => {
                this.updateKonsil(previousKonsil)
                .then(() => this.handleVersendenKonsilauftragGerichtet());
            });
        } else if (this.state.tabValue === 2) {
            this.handleSignatureDialogOpen();
        }

        this.handleListReset();
    }

    render() {
        return (
            <>
                <SimpleModalContainer
                    isOpen={this.props.isOpenReceiverModal}
                    onClose={() => {
                        this.handleListReset();
                        this.props.toggleReceiverModal()
                    }}
                >
                    <Paper className="modal-wrapper reciever-pop">
                        <Box className="reciever-split">
                            <form className="form-content" noValidate autoComplete="off">
                                <Box className="modal-header">
                                    <KonsilValidationBadge
                                        konsilValidationStatus = {this.props.konsilValidationStatus}
                                        konsilValidationError = {this.props.konsilValidationError}
                                    />
                                    {this.state.tabValue !== 2 &&
                                         <ConnectionBadge
                                            dispatch_checkConnection = {this.props.dispatch_getHPMInfo}
                                            uploadPath = 'HPM'
                                            isConnected = {this.props.isHPMConnected}
                                            dispatch_setIsConnected = {this.props.dispatch_setIsHpmAvailable}
                                        />
                                    }
                                    {this.state.tabValue === 2 &&
                                        <ConnectionBadge
                                            dispatch_checkConnection = {this.props.dispatch_getKimConnectionStatus}
                                            uploadPath = 'KIM'
                                            isConnected = {this.props.isKimConnected}
                                            dispatch_setIsConnected = {this.props.dispatch_setIsKimConnected}
                                        />
                                    }
                                    <h2>Versandart auswählen</h2>
                                    <Tabs
                                        value={this.state.tabValue}
                                        className="headerTabs"
                                        onChange={this.handleTabChange}
                                        variant="scrollable"
                                        scrollButtons="off"
                                        indicatorColor="primary"
                                        textColor="primary"
                                        aria-label="scrollable force tabs example"
                                    >
                                        <Tab
                                            label = {
                                                <TabLabel
                                                    icon = {<GroupIcon/>}
                                                    labelText = "HPM"
                                                    tooltipText = {this.props.initialisedServices.includes("HPM") ? "Versand an eine Empfängersgruppe über HPM" : "HPM-Modul ist nicht eingerichtet"}
                                                />
                                            }
                                            {...a11yProps(1)}
                                            disableRipple = {!this.props.initialisedServices.includes("HPM")}
                                            className = {!this.props.initialisedServices.includes("HPM") ? "inactive" : ""}
                                        />
                                        <Tab
                                            label = {
                                                <TabLabel
                                                    icon = {<PersonIcon/>}
                                                    labelText = "HPM"
                                                    tooltipText = {this.props.initialisedServices.includes("HPM") ? "Versand an einen Einzelnperson über HPM" : "HPM-Modul ist nicht eingerichtet"}
                                                />
                                            }
                                            {...a11yProps(0)}
                                            disableRipple = {!this.props.initialisedServices.includes("HPM")}
                                            className = {!this.props.initialisedServices.includes("HPM") ? "inactive" : ""}
                                        />
                                        {this.props.isKimEnabled &&
                                             <Tab
                                                label =
                                                    {
                                                        <TabLabel
                                                            icon = {<PersonIcon/>}
                                                            labelText = "KIM"
                                                            tooltipText = {this.props.initialisedServices.includes("KIM") ? "Versand an einen Einzelnperson über KIM" : "KIM-Modul ist nicht eingerichtet"}
                                                        />
                                                }
                                                {...a11yProps(2)}
                                                disableRipple = {!this.props.initialisedServices.includes("KIM")}
                                                className = {!this.props.initialisedServices.includes("KIM") ? "inactive" : ""}
                                            />
                                        }

                                    </Tabs>
                                </Box>


                                {this.props.initialisedServices.length !== 0 ?
                                    <Box className="modal-content">
                                        {(this.state.tabValue === 0 || this.state.tabValue === 1) &&
                                            <CdaSizeComponent size={this.props.cdaSize} konsilStatus="IN_ARBEIT" uploadPath = {UploadPath.HPM} limit = {KonsilLimitSizes.HPM_IN_ARBEIT}/>
                                        }
                                        {this.state.tabValue === 2 &&
                                            <CdaSizeComponent size={this.props.cdaSize} konsilStatus="IN_ARBEIT" uploadPath = {UploadPath.KIM} limit = {KonsilLimitSizes.KIM_IN_ARBEIT}/>
                                        }
                                        <Typography className="last-reciver-label" variant="body1" color="inherit">
                                            Eine Zusammenfassung des Konsilauftrags wurde erstellt. Bitte überprüfen
                                            Sie den Konsilauftrag (.pdf) auf Richtigkeit.
                                        </Typography>

                                        <TabPanel className="receiver-container" value={this.state.tabValue} index={1}>
                                            <HpmSingleTabPanel
                                                 typingReminder = {this.state.typingReminder}
                                                 empfaengerTyp = {this.state.empfaengerTyp}
                                                 isEmptySearch = {this.state.isEmptySearch}
                                                 filteredKonsiliare = {this.state.filteredKonsiliare}
                                                 anchorEl = {this.state.anchorEl}
                                                 selectedReceiver = {this.state.selectedReceiver}
                                                 checkbox = {this.state.konsil.patienteneinwilligung?.einwilligungVersandUmwandlung}
                                                 timeHandleSearchChange = {this.timeHandleSearchChange}
                                                 handleMenuOpen = {this.handleMenuOpen}
                                                 handleChangeEmpfaengerTyp = {this.handleChangeEmpfaengerTyp}
                                                 handleMenuClose = {this.handleMenuClose}
                                                 handleMenuSelect = {this.handleMenuSelect}
                                                 handleConsentGroupDispatchCheckbox = {this.handleConsentGroupDispatchCheckbox}
                                            />
                                        </TabPanel>
                                        <TabPanel className="receiver-container" value={this.state.tabValue} index={0} >
                                            <HpmGroupTabPanel
                                                selectedReceiverGroup = {this.state.selectedReceiverGroup}
                                                receiverGroups = {this.props.receiverGroups}
                                                handleChangeHpmGroupAutocomplete = {this.handleChangeHpmGroupAutocomplete}
                                            />
                                        </TabPanel>
                                        <TabPanel className="receiver-container" value={this.state.tabValue} index={2} >
                                            <KimTabPanel
                                                availableDermatologists = {this.props.availableDermatologists}
                                                isDermaSearchOpen = {this.state.isDermaSearchOpen}
                                                handleOpenClose = {this.handleOpenCloseKimAutocomplete}
                                                handleInputChange = {this.handleInputChangeKimAutocomplete}
                                                selectedKimReceiver = {this.state.selectedKimReceiver}
                                                handleChange = {this.handleChangeKimAutocomplete}
                                                isDermaListLoading = {this.props.isDermaListLoading}

                                            />
                                             <SignatureDialogContainer
                                                isOpen = {this.state.isSignatureDialogOpen}
                                                handleClose = {this.handleSignatureDialogClose}
                                                tiConnectorEvent = {this.props.tiConnectorEvent}
                                                forSettings = {false}
                                                triggerSendOnSignSuccess = {this.triggerSendKimKonsilOnSignSuccess}
                                            />
                                        </TabPanel>
                                        {this.state.tabValue !== 2 &&
                                            <>
                                                <ZertifikatPasswortComponent
                                                    password = {(this.props.isComfortVersand && this.props.comfortVersandPassword !== "" && !this.state.hasPasswordChanged) ? this.props.comfortVersandPassword : this.state.password}
                                                    handlePasswordChange = {this.handlePasswordChange}
                                                />
                                                <Tooltip
                                                    title = "Passwort für die aktuelle Sitzung speichern"
                                                    placement = 'top'
                                                >
                                                    <FormControlLabel
                                                        control =
                                                            {<Checkbox
                                                                name="comfort-versand-checkbox"
                                                                checked = {this.props.isComfortVersand}
                                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                    if (!event.target.checked)
                                                                        this.props.dispatch_setComfortVersandPassword("");
                                                                    this.props.dispatch_setIsComfortVersand(!this.props.isComfortVersand);
                                                                }}
                                                            />}
                                                        label={<Typography variant="body2" color="inherit">Passwort merken</Typography>}
                                                    />
                                                </Tooltip>
                                            </>
                                        }
                                        {
                                            this.state.konsil.pvsPatient == null &&
                                            <Typography
                                                className="pad-top failure"
                                                variant="body1">
                                                Kein PVS-Patient im Konsil vorhanden!
                                            </Typography>
                                        }
                                    </Box> :
                                    <Box className = "modal-content">
                                        <Typography className = 'failure'>
                                            Sie können jetzt keine Telekonile senden. Bitte richten Sie das HPM- oder KIM-Modul in den Einstellungen ein
                                        </Typography>
                                    </Box>
                                }
                                <Box className="modal-footer">
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            this.props.dispatch_setCdaSize(NaN);
                                            this.handleListReset();
                                            this.props.toggleReceiverModal();
                                            this.setState({password: ""});
                                            this.props.dispatch_clearPdfString();
                                            this.props.dispatch_setIsKonsilSigned(false);
                                        }}
                                    >
                                        Abbrechen
                                    </Button>
                                    <Button
                                        disabled = {this.isSendDisabled()}
                                        variant="contained" color="primary"
                                        onClick={this.handleClickVersenden}
                                    >
                                        Versenden
                                    </Button>

                                </Box>
                                { this.props.isSupportEnabled &&
                                    <Button
                                        color='secondary'
                                        style={{position: "absolute", transformOrigin: "bottom-left", bottom: "1rem", left: "2rem"}}
                                        // className='mobile-hide'
                                        onClick={() => this.handleDownloadCdaDokument()}
                                        variant='contained' disabled={this.props.isPdfLoading}
                                    >
                                        CDA exportieren
                                    </Button>
                                }
                            </form>
                            <PdfInline pdfData={this.props.pdfPreviewData} isPdfLoading = {this.props.isPdfLoading} header = "Zusammenfassung Konsilauftrag"/>
                        </Box>
                    </Paper>
                </SimpleModalContainer>
                <StartSendPopup open={this.props.isSendingKonsil} closeStartSendPopup={this.closeStartSendPopup}/>
            </>
        );
    }
}

export const SendKonsilDialogContainer = connector(withTypingTimeout(SendKonsilDialog));
export default SendKonsilDialogContainer;


function TabPanel(props: { [x: string]: any; children: any; value: any; index: any; }) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}




