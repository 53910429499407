import React from 'react';
import {Box, Tooltip} from '@material-ui/core';
import {VerifiedUser, Loop} from '@material-ui/icons';
import { UploadPath } from 'telescan-core';


interface IProps {
    dispatch_checkConnection: () => Promise<any>;
    uploadPath: keyof typeof UploadPath;
    isConnected: boolean;
    dispatch_setIsConnected: (isConnected: boolean) => void;
}

interface IState {
    timeoutId: ReturnType<typeof setTimeout> | null;
}

export default class ConnectionBadge extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            timeoutId: null
        };
    }

    componentDidMount() {
        this.timeoutFunction();
        const timeout = setInterval(this.timeoutFunction, 20000);
        this.setState({timeoutId: timeout});
    }


    componentWillUnmount() {
       this.state.timeoutId && clearInterval(this.state.timeoutId);
       this.props.dispatch_setIsConnected(false);
    }

    timeoutFunction = () => {
        this.props.dispatch_checkConnection()
        .then(() => {
            this.state.timeoutId && clearInterval(this.state.timeoutId);             
        })
    }

    render() {
        if (this.props.uploadPath === "HPM") {
            if (this.props.isConnected)
                return (
                    <Tooltip title={"ok"}>
                        <Box className="status-badge hpm-ok">HPM Status: <VerifiedUser/></Box>
                    </Tooltip>
                    )
            else 
                return (
                    <Tooltip title="unbekannt, versuche Status abzufragen">
                        <Box className="status-badge hpm-loading">HPM Status: <Loop/></Box>
                    </Tooltip>
                    )
        }
        if (this.props.uploadPath === "KIM") {
            if (this.props.isConnected)
                return (
                    <Tooltip title={"ok"}>
                        <Box className="status-badge hpm-ok">KIM Status: <VerifiedUser/></Box>
                    </Tooltip>
                    )
            else 
                return (
                    <Tooltip title="unbekannt, versuche Status abzufragen">
                        <Box className="status-badge hpm-loading">KIM Status: <Loop/></Box>
                    </Tooltip>
                    )
        } 
    }
}

