import { ETiConnectorActions, TTiConnectorActionTypes } from "../types/ti_connector_types";

export interface ITiConnectorState {
    isKimMailInLdap: boolean;
    isLdapRequest: boolean;
}

const initialState: ITiConnectorState = {
    isKimMailInLdap: false,
    isLdapRequest: false,
}

export function ti_connector_reducer(state = initialState, action: TTiConnectorActionTypes): ITiConnectorState {
    switch (action.type) {
        case ETiConnectorActions.SET_IS_KIM_MAIL_IN_LDAP:
            return {
                ...state,
                isKimMailInLdap: action.isKimMailInLdap
            }

        case ETiConnectorActions.SET_IS_LDAP_REQUEST:
            return {
                ...state,
                isLdapRequest: action.isLdapRequest
            }

        default:
            return state
    }
}