import { Quartalsabrechnungen, StatusQuartalsabrechnung, QuartalsabrechnungUpdate } from 'telescan-core';

export enum EAbrechnungActions {
    SET_QUARTALSABRECHNUNGEN = "SET_QUARTALSABRECHNUNGEN",
    SET_CURRENTQUARTALSABRECHNUNG = "SET_CURRENTQUARTALSABRECHNUNG",
    FILTER_CHANGED = "FILTER_CHANGED",
    SET_NEW_AVAILABLE_BILLS = "SET_NEW_AVAILABLE_BILLS",
    SET_KONSILABRECHNUNGEN_STATUS = "SET_KONSILABRECHNUNGEN_STATUS",
    SET_CURRENT_QUARTALSABRECHNUNG_STATUS = "SET_CURRENT_QUARTALSABRECHNUNG_STATUS",
    SET_KONSILABRECHNUNG_IN_ABRECHNUNG_FLAG = "SET_KONSILABRECHNUNG_IN_ABRECHNUNG_FLAG",
    SET_CURRENT_QUARTALSABRECHNUNG_IS_IN_PROGRESS = "SET_CURRENT_QUARTALSABRECHNUNG_IS_IN_PROGRESS",
    SET_IS_KONSIL_FROM_BILLING_OPENED = "SET_IS_KONSIL_FROM_BILLING_OPENED",
}

export interface ISetQuartalsabrechnungenAction {
    readonly type: EAbrechnungActions.SET_QUARTALSABRECHNUNGEN;
    readonly quartalsabrechnungen: Quartalsabrechnungen[];
}
export interface ISetCurrentQuartalsabrechnungAction {
    readonly type: EAbrechnungActions.SET_CURRENTQUARTALSABRECHNUNG;
    readonly currentQuartalsabrechnung: Quartalsabrechnungen;
}

export interface IFilterChangedAction {
    readonly type: EAbrechnungActions.FILTER_CHANGED;
    readonly quartalsabrechnungFilter: string[];
}

export interface ISetNewAvailableBills {
    readonly type: EAbrechnungActions.SET_NEW_AVAILABLE_BILLS;
    readonly quartalsabrechnungUpdate: QuartalsabrechnungUpdate;
}

export interface ISetKonsilabrechnungenStatus {
    readonly type: EAbrechnungActions.SET_KONSILABRECHNUNGEN_STATUS;
    readonly quartalsabrechnungId: string;
}

export interface ISetCurrentQuartalsabrechnungStatus {
    readonly type: EAbrechnungActions.SET_CURRENT_QUARTALSABRECHNUNG_STATUS;
    readonly status: keyof typeof StatusQuartalsabrechnung;
}

export interface ISetKonsilabrechnungInAbrechnungFlag {
    readonly type: EAbrechnungActions.SET_KONSILABRECHNUNG_IN_ABRECHNUNG_FLAG;
    readonly konsilabrechnungId: string;
    readonly isInAbrechnung: boolean;
}

export interface ISetCurrentQuartalsabrechnungIsInProgress {
    readonly type: EAbrechnungActions.SET_CURRENT_QUARTALSABRECHNUNG_IS_IN_PROGRESS;
    readonly isInProgress: boolean,
}

export interface ISetIsKonsilFromBillingOpened {
    readonly type: EAbrechnungActions.SET_IS_KONSIL_FROM_BILLING_OPENED;
    readonly isKonsilFromBillingOpened: boolean;
    readonly billId: string;
}

export type TAbrechnungActionTypes =
    ISetQuartalsabrechnungenAction |
    ISetCurrentQuartalsabrechnungAction |
    IFilterChangedAction |
    ISetNewAvailableBills |
    ISetKonsilabrechnungenStatus |
    ISetCurrentQuartalsabrechnungStatus |
    ISetKonsilabrechnungInAbrechnungFlag |
    ISetCurrentQuartalsabrechnungIsInProgress|
    ISetIsKonsilFromBillingOpened;