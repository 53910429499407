export enum EEvaluationActions {
    TAB_STATUS_CHANGED = "TAB_STATUS_CHANGED",
    ADD_TAB_STATUS = "ADD_TAB_STATUS",
    DELETE_TAB_STATUS = "DELETE_TAB_STATUS",
    CHANGE_KONSILANAMNESE_ERRORS = "CHANGE_KONSILANAMNESE_ERRORS",
    ADD_KONSILANAMNESE_ERRORS = "ADD_KONSILANAMNESE_ERRORS",
    DELETE_KONSILANAMNESE_ERRORS = "DELETE_KONSILANAMNESE_ERRORS",
    CHANGE_KONSILFRAGE_ERRORS = "CHANGE_KONSILFRAGE_ERRORS",
    ADD_KONSILFRAGE_ERRORS = "ADD_KONSILFRAGE_ERRORS",
    DELETE_KONSILFRAGE_ERRORS = "DELETE_KONSILFRAGE_ERRORS",
    CHANGE_KONSILPATIENT_ERRORS = "CHANGE_KONSILPATIENT_ERRORS",
    ADD_KONSILPATIENT_ERRORS = "ADD_KONSILPATIENT_ERRORS",
    DELETE_KONSILPATIENT_ERRORS = "DELETE_KONSILPATIENT_ERRORS",
    CHANGE_LOCATION_ERRORS = "CHANGE_LOCATION_ERRORS",
    ADD_LOCATION_ERRORS = "ADD_LOCATION_ERRORS",
    DELETE_LOCATION_ERRORS = "DELETE_LOCATION_ERRORS",
    CHANGE_KONSILFEEDBACK_ERRORS = "CHANGE_KONSILFEEDBACK_ERRORS",
    ADD_KONSILFEEDBACK_ERRORS = "ADD_KONSILFEEDBACK_ERRORS",
    DELETE_KONSILFEEDBACK_ERRORS = "DELETE_KONSILFEEDBACK_ERRORS",
    KONSILIARBERICHT_ERRORS = "KONSILIARBERICHT_ERRORS",
    KONSILIARFEEDBACK_ERRORS = "KONSILIARFEEDBACK_ERRORS",
    SET_KIM_SETTINGS_ERROR = "SET_KIM_SETTINGS_ERROR",
    ADD_EINWILLIGUNG_ERRORS = "ADD_EINWILLIGUNG_ERRORS",
    CHANGE_EINWILLIGUNG_ERRORS = "CHANGE_EINWILLIGUNG_ERRORS",
    DELETE_EINWILLIGUNG_ERRORS = "DELETE_EINWILLIGUNG_ERRORS",
}

export interface ITabStatusChangedAction {
    readonly type: EEvaluationActions.TAB_STATUS_CHANGED;
    readonly konsilID: string;
    readonly statusMap: Map<number, Record<string, boolean>>
}
export interface IAddTabStatusAction {
    readonly type: EEvaluationActions.ADD_TAB_STATUS;
    readonly konsilID: string;

    readonly statusMap: Map<number, Record<string, boolean>>
}

export interface IDeleteTabStatusAction {
    readonly type: EEvaluationActions.DELETE_TAB_STATUS;
    readonly konsilID: string;
}

export interface IChangeKonsilAnamneseErrors {
    readonly type: EEvaluationActions.CHANGE_KONSILANAMNESE_ERRORS;
    readonly konsilID: string;
    readonly fieldErrorMap: Map<string, string>; // <Feldname, Fehlermeldung>
}

export interface IAddKonsilAnamneseErrors {
    readonly type: EEvaluationActions.ADD_KONSILANAMNESE_ERRORS;
    readonly konsilID: string;
    readonly fieldErrorMap: Map<string, string>; // <Feldname, Fehlermeldung>
}

export interface IDeleteKonsilAnamneseErrors {
    readonly type: EEvaluationActions.DELETE_KONSILANAMNESE_ERRORS;
    readonly konsilID: string;

}

export interface IChangeKonsilPatientErrors {
    readonly type: EEvaluationActions.CHANGE_KONSILPATIENT_ERRORS;
    readonly konsilID: string;
    readonly fieldErrorMap: Map<string, string>; // <Feldname, Fehlermeldung>
}

export interface IAddKonsilPatientErrors {
    readonly type: EEvaluationActions.ADD_KONSILPATIENT_ERRORS;
    readonly konsilID: string;
    readonly fieldErrorMap: Map<string, string>; // <Feldname, Fehlermeldung>
}

export interface IDeleteKonsilPatientErrors {
    readonly type: EEvaluationActions.DELETE_KONSILPATIENT_ERRORS;
    readonly konsilID: string;
}

export interface IChangeLocationErrors {
    readonly type: EEvaluationActions.CHANGE_LOCATION_ERRORS;
    readonly konsilID: string;
    readonly fieldErrorMap: Map<string, string>; // <Feldname, Fehlermeldung>
}

export interface IAddLocationErrors {
    readonly type: EEvaluationActions.ADD_LOCATION_ERRORS;
    readonly konsilID: string;
    readonly fieldErrorMap: Map<string, string>; // <Feldname, Fehlermeldung>
}

export interface IDeleteLocationErrors {
    readonly type: EEvaluationActions.DELETE_LOCATION_ERRORS;
    readonly konsilID: string;
}

export interface IChangeKonsilFrageErrors {
    readonly type: EEvaluationActions.CHANGE_KONSILFRAGE_ERRORS;
    readonly konsilID: string;
    readonly fieldErrorMap: Map<string, string>; // <Feldname, Fehlermeldung>
}

export interface IAddKonsilFrageErrors {
    readonly type: EEvaluationActions.ADD_KONSILFRAGE_ERRORS;
    readonly konsilID: string;
    readonly fieldErrorMap: Map<string, string>; // <Feldname, Fehlermeldung>
}

export interface IDeleteKonsilFrageErrors {
    readonly type: EEvaluationActions.DELETE_KONSILFRAGE_ERRORS;
    readonly konsilID: string;
}

export interface IAddEinwilligungErrors {
    readonly type: EEvaluationActions.ADD_EINWILLIGUNG_ERRORS;
    readonly konsilID: string;
    readonly fieldErrorMap: Map<string, string>; // <Feldname, Fehlermeldung>
}
export interface IChangeEinwilligungErrors {
    readonly type: EEvaluationActions.CHANGE_EINWILLIGUNG_ERRORS;
    readonly konsilID: string;
    readonly fieldErrorMap: Map<string, string>; // <Feldname, Fehlermeldung>
}

export interface IDeleteEinwilligungErrors {
    readonly type: EEvaluationActions.DELETE_EINWILLIGUNG_ERRORS;
    readonly konsilID: string;
}

export interface IChangeKonsilFeedbackErrors {
    readonly type: EEvaluationActions.CHANGE_KONSILFEEDBACK_ERRORS;
    readonly konsilabschlussID: string;
    readonly fieldErrorMap: Map<string, string>; // <Feldname, Fehlermeldung>
}

export interface IAddKonsilFeedbackErrors {
    readonly type: EEvaluationActions.ADD_KONSILFEEDBACK_ERRORS;
    readonly konsilabschlussID: string;
    readonly fieldErrorMap: Map<string, string>; // <Feldname, Fehlermeldung>
}

export interface IDeleteKonsilFeedbackErrors {
    readonly type: EEvaluationActions.DELETE_KONSILFEEDBACK_ERRORS;
    readonly konsilabschlussID: string;
}

export interface IKonsiliarberichtErrors {
    readonly type: EEvaluationActions.KONSILIARBERICHT_ERRORS;
    readonly fieldErrorMap: Map<string, string>; // <Feldname, Fehlermeldung>
}

export interface IKonsiliarFeedbackErrors {
    readonly type: EEvaluationActions.KONSILIARFEEDBACK_ERRORS;
    readonly fieldErrorMap: Map<string, string>; // <Feldname, Fehlermeldung>
}

export interface ISetKimSettingsError {
    readonly type: EEvaluationActions.SET_KIM_SETTINGS_ERROR;
    readonly hasError: boolean;
}

export type TEvaluationActionTypes =
    ITabStatusChangedAction |
    IAddTabStatusAction |
    IDeleteTabStatusAction |
    IChangeKonsilAnamneseErrors |
    IAddKonsilAnamneseErrors |
    IDeleteKonsilAnamneseErrors |
    IChangeKonsilPatientErrors |
    IAddKonsilPatientErrors |
    IDeleteKonsilPatientErrors |
    IChangeLocationErrors |
    IAddLocationErrors |
    IDeleteLocationErrors |
    IChangeKonsilFrageErrors |
    IAddKonsilFrageErrors |
    IDeleteKonsilFrageErrors |
    IChangeKonsilFeedbackErrors |
    IAddKonsilFeedbackErrors |
    IDeleteKonsilFeedbackErrors |
    IKonsiliarberichtErrors |
    IKonsiliarFeedbackErrors |
    ISetKimSettingsError |
    IAddEinwilligungErrors |
    IChangeEinwilligungErrors |
    IDeleteEinwilligungErrors
