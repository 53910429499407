import React from 'react';
// material components
import {Box, Button, Checkbox, FormControlLabel, Paper, TextField, Tooltip, Typography} from '@material-ui/core'
import {Konsil, TIConnectorEvent, UploadPath} from 'telescan-core';
import SimpleModalContainer from '../../elements/simple_modal';
import ZertifikatPasswortComponent from '../../elements/zertifikat_passwort';
import ConnectionBadge from '../../elements/connection_badge';
import { SignatureDialogContainer } from '../../settings/kim_configuration/signature_dialog';

interface IState {
    konsil: Konsil;
}

interface IProps {
    isOpen: boolean;
    toggleRueckfrageModal: () => void;
    handleVersenden: () => void;
    password: string;
    handlePasswordChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    dispatch_updateKonsil: (konsil: Konsil) => void;
    currentKonsil: Konsil;
    isComfortVersand: boolean;
    comfortVersandPassword: string;
    dispatch_setIsComfortVersand: (isComfortVersnad: boolean) => void;
    dispatch_setComfortVersandPassword: (comfortVersandPassword: string) => void;
    hasPasswordChanged: boolean;
    dispatch_getKimConnectionStatus: () => Promise<any>;
    isKimConnected: boolean;
    dispatch_setIsKimConnected: (isKimConnected: boolean) => void;
    isKonsilSigned: boolean;
    handleSignatureDialogOpen: () => void;
    handleSignatureDialogClose: () => void;
    isSignatureDialogOpen: boolean;
    tiConnectorEvent: TIConnectorEvent;
    isPending: boolean;
    dispatch_setIsPending: (isPending: boolean) => void;
}

export default class AdditonalQuestionPopup extends React.Component<IProps, IState> {
    intervalId = 0;
    constructor(props: IProps) {
        super(props);
        this.state = {
            konsil: this.props.currentKonsil || new Konsil(),
        };
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (prevProps.currentKonsil !== this.props.currentKonsil) {
            this.setState({
                konsil: this.props.currentKonsil || new Konsil(),
            });
        }
    }

    componentWillUnmount() {
        window.clearInterval(this.intervalId);
    }

    handleSendButton = () => {
        window.clearInterval(this.intervalId);
        if (this.props.currentKonsil.uploadPath === UploadPath.KIM) {
            this.props.handleSignatureDialogOpen();
        }
        if (this.props.currentKonsil.uploadPath === UploadPath.HPM || !this.props.currentKonsil.uploadPath) {
            this.props.handleVersenden();
            if (this.props.isComfortVersand && (this.props.comfortVersandPassword === "" || this.props.hasPasswordChanged))
                this.props.dispatch_setComfortVersandPassword(this.props.password);
        }
    }


    render() {
        let disabled: boolean = true;
        if (this.props.currentKonsil.uploadPath === "HPM" || !this.props.currentKonsil.uploadPath)
            disabled = this.state.konsil.konsilrueckfrage?.freitext == null ||
            this.state.konsil.konsilrueckfrage?.freitext === "" ||
            (this.props.password === "" && this.props.comfortVersandPassword === "");
        else if (this.props.currentKonsil.uploadPath === "KIM")
            disabled = this.state.konsil.konsilrueckfrage?.freitext == null ||
            this.state.konsil.konsilrueckfrage?.freitext === "" ||
            !this.props.isKimConnected;

        return (
            <SimpleModalContainer isOpen={this.props.isOpen}>
                <Paper className="modal-wrapper konsil-question-pop">
                    <form className="form-content" noValidate autoComplete="off"
                        onSubmit={(e) => {
                            e.preventDefault()
                            if (!this.props.isPending)
                                this.handleSendButton();
                            else {
                                this.intervalId = window.setInterval(() => this.handleSendButton(), 500);
                            }
                        }}
                    >
                        <Box className="modal-header">
                            {this.props.currentKonsil.uploadPath === UploadPath.KIM &&
                                <ConnectionBadge
                                    dispatch_checkConnection = {this.props.dispatch_getKimConnectionStatus}
                                    uploadPath = 'KIM'
                                    isConnected = {this.props.isKimConnected}
                                    dispatch_setIsConnected = {this.props.dispatch_setIsKimConnected}
                                />
                            }
                        </Box>
                        <Box className="modal-content">
                            <h2>Rückfrage</h2>
                            <TextField
                                required
                                error={this.state.konsil.konsilrueckfrage?.freitext == null || this.state.konsil.konsilrueckfrage?.freitext === ""}
                                placeholder="Rückfragetext"
                                multiline
                                variant="filled"
                                minRows = {3}
                                maxRows={6}
                                value={this.state.konsil.konsilrueckfrage?.freitext || ""}
                                onChange={(event) => {
                                    this.setState({
                                        konsil: {
                                            ...this.state.konsil,
                                            konsilrueckfrage: {
                                                ...this.state.konsil.konsilrueckfrage,
                                                freitext: event.target.value
                                            }
                                        }
                                    });
                                }}
                                onBlur={() =>  {
                                    this.props.dispatch_updateKonsil(this.state.konsil);
                                    this.props.dispatch_setIsPending(true);
                                }}
                            />
                            {this.props.currentKonsil.uploadPath === UploadPath.KIM &&
                                <Typography className="failure last-reciver-label pad-top" variant="body1">
                                    Bereiten Sie Ihr persönliches eHBA vor. Sie werden aufgefordert, den Konsilauftrag damit zu unterschreiben.
                                </Typography>
                            }

                            {(this.props.currentKonsil.uploadPath === "HPM" || !this.props.currentKonsil.uploadPath) &&
                                <>
                                    <ZertifikatPasswortComponent
                                        password={(this.props.isComfortVersand && this.props.comfortVersandPassword !== "" && !this.props.hasPasswordChanged) ? this.props.comfortVersandPassword : this.props.password}
                                        handlePasswordChange={this.props.handlePasswordChange}
                                    />
                                    <Tooltip
                                        title = "Passwort für die aktuelle Sitzung speichern"
                                        placement = 'top'
                                    >
                                        <FormControlLabel
                                            control =
                                                {<Checkbox
                                                    name="comfort-versand-checkbox"
                                                    checked = {this.props.isComfortVersand}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        if (!event.target.checked)
                                                            this.props.dispatch_setComfortVersandPassword("");
                                                        this.props.dispatch_setIsComfortVersand(!this.props.isComfortVersand);
                                                    }}
                                                />}
                                            label={<Typography variant="body2" color="inherit">Passwort merken</Typography>}
                                        />
                                    </Tooltip>
                                </>
                            }

                            <SignatureDialogContainer
                                isOpen = {this.props.isSignatureDialogOpen}
                                handleClose = {this.props.handleSignatureDialogClose}
                                tiConnectorEvent = {this.props.tiConnectorEvent}
                                forSettings = {false}
                                triggerSendOnSignSuccess = {this.props.handleVersenden}
                            />
                        </Box>
                        <Box className="modal-footer">
                            <Button variant="text" onClick={() => this.props.toggleRueckfrageModal()}>
                                Zurück
                            </Button>
                            <Button
                                disabled={disabled}
                                variant="contained"
                                color="primary"
                                type='submit'
                            >
                                Absenden
                            </Button>
                        </Box>
                    </form>
                </Paper>
            </SimpleModalContainer>
        );
    }
}
