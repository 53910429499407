import { ZertifikatMetainformation } from "telescan-core";

export enum EZertifikatActions {
    SET_ZERTIFIKAT_STATUS = "SET_ZERTIFIKAT_STATUS",
    SET_ZERTIFIKATE_METAINFO = "SET_ZERTIFIKATE_METAINFO"
}


export interface ISetZertifikatStatusAction {
    readonly type: EZertifikatActions.SET_ZERTIFIKAT_STATUS;
    readonly zertifikatStatus: {status: string, arztZertifikat: string, betriebsstaettenZertifikat: string, meldung: string | null};
}

export interface ISetZertifikateMetaInfoAction {
    readonly type: EZertifikatActions.SET_ZERTIFIKATE_METAINFO;
    readonly arztZertifikateMetaInfo: ZertifikatMetainformation[];
    readonly betriebsstaettenZertifikatMetaInfo: ZertifikatMetainformation;
}

export type TZertifikatActionTypes =
    ISetZertifikatStatusAction |
    ISetZertifikateMetaInfoAction;