import {Failure, Medikation, MedikationType} from "telescan-core";
import React from "react";
import {connect, ConnectedProps} from "react-redux";
import {
    Box,
    Button,
    Chip,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Tooltip,
    Typography,
} from "@material-ui/core";
import {Delete, Edit} from "@material-ui/icons";
import {ViewText} from '../../viewComponents/view_text';
import SimpleModalContainer from "../../../elements/simple_modal";
import { RootState } from "../../../../redux/reducers";
import { failure } from "../../../../redux/actions";
import uuid from 'uuid';

interface IMedProps {
    medikation: Medikation;
    error: boolean;
    delete: (medikation: Medikation) => void;
    isViewOnly: boolean;
    onEditClick?: () => void;
    onViewClick?: () => void;
}


export class MedikationComponent extends React.Component<IMedProps, IState> {
    render() {
        // replace Dosierschema inputs with more readable view
        let dosierschema = this.props.medikation.dosierschema?.split("-").map(item => { //go over all items
            let separated = item.replace(/[^\d,.?/]/g, ' ').replace(/  +/g, ' ').replace(/,/g, '.').split(" ").map(subitem => { // for each element replace all not-number elements. except dots and commas with space, split it another array
                if (subitem === "0.25" || subitem === ".25") { // check if each subitem has to be replaced
                    return subitem = "1/4";
                }
                else if (subitem.startsWith("0.33") || subitem.startsWith(".33"))
                    return subitem = "1/3";
                else if (subitem === "0.5" || subitem === ".5")
                    return subitem = "1/2";
                else if (subitem === "0.125" || subitem === ".125")
                    return subitem = "1/8";
                else if (subitem === "")
                    return subitem = "0";
                else
                    return subitem;
            }).join(" bis "); // join subarray of subitems back to one string
            return separated;
        });
        const dosierschemaUpdated: string | undefined = dosierschema?.join(" \u2022 "); // join array to one string separated with big dots
        const dosieranweisung = this.props.medikation.dosieranweisung?.replace(/[{}']+/g,'').split("\n").filter(item => item !== ""); // remove {} brackets, split string by "\n" symbol, remove empty string elements from resulting array

        //set dosieranweisung representation depending on incoming data
        let dosierAnweisungComponent: any;
        if ((this.props.medikation.dosierschema == null || this.props.medikation.dosierschema === "") &&
            (this.props.medikation.dosieranweisung == null || this.props.medikation.dosieranweisung === "") &&
            (this.props.medikation.sonstigeAngabeDosierschema == null || this.props.medikation.sonstigeAngabeDosierschema === "")) {
            dosierAnweisungComponent =
                <Chip
                    className="margin-left"
                    variant="outlined"
                    size="small"
                    label= "ohne Dosieranweisung"
                    color = "primary"
                />
        }
        else if ((this.props.medikation.dosierschema == null || this.props.medikation.dosierschema === "") && (this.props.medikation.dosieranweisung == null || this.props.medikation.dosieranweisung === "")) {
            dosierAnweisungComponent =
                <Chip
                    className="margin-left"
                    variant="outlined"
                    size="small"
                    label= "nur Dosierinstruktion"
                    color = "primary"
                />
        }

        else if (this.props.medikation.dosierschema && this.props.medikation.dosierschema !== "" && (!this.props.medikation.dosieranweisung || this.props.medikation.dosieranweisung === "") && (this.props.medikation.sonstigeAngabeDosierschema || this.props.medikation.sonstigeAngabeDosierschema !== "")) {
            dosierAnweisungComponent =
                <Tooltip
                    title = {
                        <Typography style={{whiteSpace: 'pre-line'}} variant = 'body2'>{"ohne Dosieranweisung \n nur freitextliche Dosierinstruktion"}</Typography>                      
                    }
                    placement="top">
                    <Chip
                        className="margin-left"
                        variant="outlined"
                        size="small"
                        label={dosierschemaUpdated}
                        color = "primary"
                    />
                </Tooltip>
        }

        else if (this.props.medikation.dosierschema && this.props.medikation.dosierschema !== "" && (!this.props.medikation.dosieranweisung || this.props.medikation.dosieranweisung === "")) {
            dosierAnweisungComponent =
                <Tooltip title = "ohne Dosieranweisung"
                        placement="top">
                    <Chip
                        className="margin-left"
                        variant="outlined"
                        size="small"
                        label={dosierschemaUpdated}
                        color = "primary"
                    />
                </Tooltip>
        }

        else if (this.props.medikation.dosierschema && this.props.medikation.dosierschema !== "" && this.props.medikation.dosieranweisung && this.props.medikation.dosieranweisung !== "") {
            dosierAnweisungComponent =
            <Tooltip
                className = 'medikation-tooltip'
                title={
                    <ul style = {{listStylePosition: "inside", paddingLeft: "0", fontSize: "10pt"}}>
                        {dosieranweisung?.map(item => {
                            return <li key = {item} style = {{marginBottom: "7px"}}>{item}</li>
                        })}
                    </ul>
                }
                placement="top"
            >
                <Chip
                    className="margin-left"
                    variant="outlined"
                    size="small"
                    label={dosierschemaUpdated}
                    color = "primary"
                />
            </Tooltip>
        }
        
        else if ((!this.props.medikation.dosierschema || this.props.medikation.dosierschema === "") && this.props.medikation.dosieranweisung && this.props.medikation.dosieranweisung !== "") {
            dosierAnweisungComponent =
            <Tooltip
                title={
                    <>
                        <Typography variant = 'body2'>Dosieranweisung</Typography>
                        <ul style = {{listStylePosition: "inside", paddingLeft: "0", fontSize: "10pt"}}>
                            {dosieranweisung?.map(item => {
                                return <li key = {item} style = {{marginBottom: "7px"}}>{item}</li>
                            })}
                        </ul>
                    </>
                }
                placement="top"
            >
                <Chip
                    className="margin-left"
                    variant="outlined"
                    size="small"
                    label= "mit Dosieranweisung"
                    color = "primary"
                />
            </Tooltip>
        }

        return (
            <Paper
                className={"derma-diagnose-container" + (this.props.error ? " failure" : "") + (this.props.isViewOnly ? " view-only" : "")}
                key={"medikation_" + this.props.medikation.id}
                onClick={(this.props.isViewOnly) ? this.props.onViewClick : () => {
                }}
            >
                <Box className="flex-row-center-align-start">
                    <Box className="flex-row-center-wrap">
                        <Tooltip title="Handelsbezeichnung" placement="top">
                            <span className="bold">{this.props.medikation.arzneimittelHandelsbezeichnung}</span>
                        </Tooltip>
                        {this.props.medikation.arzneimittelPzn &&
                            <Tooltip title="Pharmazentralnummer" placement="top">
                                <span>{" (" + this.props.medikation.arzneimittelPzn + ")"}</span>
                            </Tooltip>
                        }
                        {this.props.medikation.dauermedikation &&
                            <Tooltip title="Dauermedikation" placement="top">
                                <Typography className="medikation-dauermedikation">DM</Typography>
                            </Tooltip>
                        }
                        {dosierAnweisungComponent}
                    </Box>

                    {!this.props.isViewOnly &&
                    <>
                        <Tooltip title="Bearbeiten" placement="top">
                            <IconButton className = "margin-left-auto" aria-label="delete" size="small"
                                        onClick={this.props.onEditClick}>
                                <Edit/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Löschen" placement="top">
                            <IconButton
                                size="small"
                                aria-label="delete laborwert"
                                onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    this.props.delete(this.props.medikation);
                                }}
                            >
                                <Delete/>
                            </IconButton>
                        </Tooltip>
                    </>
                    }
                </Box>
            </Paper>
        )
    }
}

interface IMedikationEditProps {
    medikation: Medikation;
    open: boolean;
    add: boolean;
    callback: (medikation: Medikation | null, add: boolean) => void;

}

interface IState {
    medikation: Medikation;
    dosiermengeString: string;
    dosierschemaArray: (string | null)[];
    isDosierschemaCorrect: boolean[];
}


const mapStateToProps = (state: RootState) => ({

})

const mapDispatchToProps = {
    dispatch_failure: (id: string, failureObject: Failure) => failure(id, failureObject),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>


export class MedikationEditComponent extends React.Component<IMedikationEditProps & TPropsFromRedux, IState> {
    constructor(props: IMedikationEditProps & TPropsFromRedux) {
        super(props);
        this.state = {
            medikation: props.medikation,
            dosiermengeString: (this.props.medikation?.dosiermenge && this.props.medikation?.dosiermenge !== 0) ? String(this.props.medikation?.dosiermenge) : "",
            dosierschemaArray: (this.props.medikation?.dosierschema && this.props.medikation?.dosierschema !== "") ? this.props.medikation?.dosierschema.split("-") : [null, null, null, null],
            isDosierschemaCorrect: [true, true, true, true],
        };
        this.medikationErrors = new Map()
        this.handleArzneiPznField = this.handleArzneiPznField.bind(this);
        this.checkDosierschemaElement = this.checkDosierschemaElement.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    private medikationErrors: Map<string, boolean>

    componentDidUpdate(prevProps: Readonly<IMedikationEditProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.medikation !== this.props.medikation) {
            this.setState({
                medikation: this.props.medikation
            });
        }
        if (prevProps.medikation?.dosiermenge !== this.props.medikation?.dosiermenge) {
            this.setState({
                dosiermengeString: (this.props.medikation?.dosiermenge !== 0) ? String(this.props.medikation?.dosiermenge) : ""
            });
        }
        if (prevProps.medikation?.dosierschema !== this.props.medikation?.dosierschema) {
            this.setState({
                dosierschemaArray: (this.props.medikation?.dosierschema && this.props.medikation?.dosierschema !== "") ? this.props.medikation?.dosierschema.split("-") : [null, null, null, null]
            });
        }
    }

    checkDosierschemaElement(idx: number) { // check if dosierschema input from user consists only from digits which can be separated with dots
        if (this.state.dosierschemaArray[idx]?.match(/^\d*\.?\d+(?:[+-]?\d+)?$/g) || this.state.dosierschemaArray[idx] === "" || this.state.dosierschemaArray[idx] == null) {
            return false;
        }
        else {
            return true;
        }
    }

    handleSubmit() { // submit form, evaluate inputs for arzneiMittel, arzneimittelHandelsbezeichnung and dosierschema
        if (!this.state.isDosierschemaCorrect.every(item => item)) {
            const failureId: string = uuid.v4();
            const failure = new Failure();
            failure.error = "Fehlerhaftes Dosierschema: Im Dosierschema sind nur Ziffern (0-9) und Punkt (.) als Trennzeichen zulässig"
            this.props.dispatch_failure(failureId, failure);
        }
        else if (this.medikationErrors.get("arzneimittelPzn") || this.medikationErrors.get("arzneimittelHandelsbezeichnung")) {
            const failureId: string = uuid.v4();
            const failure = new Failure();
            failure.error = "Bitte fügen Sie eine Pharmazentralnummer und eine Handelsbezeichnung der Medikation hinzu"
            this.props.dispatch_failure(failureId, failure);
        }
        else {
            if (this.state.medikation.dauermedikation == null || this.state.medikation.dauermedikation === undefined) {
                this.setState({
                        medikation: {
                            ...this.state.medikation,
                            dauermedikation: false,
                        }
                    },
                    () => this.props.callback(this.state.medikation, this.props.add)
                )
            }
            else {
                this.props.callback(this.state.medikation, this.props.add);
            }
            this.setState({
                dosiermengeString: "",
                dosierschemaArray: [null, null, null, null]
            });
        }

    }

    handleArzneiPznField(event: any) {
        if (event.target.value !== "PZN") {
            this.setState({
                medikation: {
                    ...this.state.medikation,
                    //arzneimittelType: event.target.value as "PZN" | "NI" | "NA" | "UNK",
                    arzneimittelType: event.target.value as "PZN" | "NI" | "UNK",
                    arzneimittelPzn: "",
                }
            });
        } else {
            this.setState({
                medikation: {
                    ...this.state.medikation,
                    //arzneimittelType: event.target.value as "PZN" | "NI" | "NA" | "UNK",
                    arzneimittelType: event.target.value as "PZN" | "NI" | "UNK",
                }
            });
        }


    }

    renderArzneimittelPzn() {
        return (
            <Box className='search-container medikation-search'>
                <TextField
                    className='search-input'
                    id={"med_" + this.state.medikation.arzneimittelPzn}
                    label={this.state.medikation.arzneimittelType === "PZN" ? "Pharmazentralnummer" : ""}
                    placeholder={this.state.medikation.arzneimittelType === "PZN" ? "Pharmazentralnummer" : ""}
                    multiline
                    minRows = {1}
                    maxRows={4}
                    value={this.state.medikation.arzneimittelPzn || ""}
                    onChange={(event) => this.setState({
                        medikation: {
                            ...this.state.medikation,
                            arzneimittelPzn: event.target.value
                        }
                    })}
                    error={this.medikationErrors.get("arzneimittelPzn")}
                    disabled={
                        this.state.medikation.arzneimittelType === "NI" ||
                        //this.state.medikation.arzneimittelType==="NA" ||
                        this.state.medikation.arzneimittelType === "UNK" ||
                        (this.state.medikation.dosieranweisung != null && this.state.medikation.dosieranweisung !== "")
                    }
                />
                <Select className="search-select"
                        disabled={this.state.medikation.dosieranweisung != null && this.state.medikation.dosieranweisung !== ""}
                        labelId="pzn-select-label"
                        value={this.state.medikation.arzneimittelType || "PZN"}
                        onChange={event => {
                            this.handleArzneiPznField(event)
                        }}
                        displayEmpty>
                    {Object.keys(MedikationType).map(key =>
                        <MenuItem
                            key={key}
                            value={key}>
                            {MedikationType[key]}
                        </MenuItem>
                    )}
                </Select>
            </Box>
        )
    }

    renderHandelsbezeichnung() {
        return (
            <TextField
                disabled={this.state.medikation.dosieranweisung != null && this.state.medikation.dosieranweisung !== ""}
                id={"med_" + this.state.medikation.arzneimittelHandelsbezeichnung}
                label="Handelsbezeichnung"
                placeholder="Verpackungsname"
                multiline
                minRows = {1}
                maxRows={4}
                value={this.state.medikation.arzneimittelHandelsbezeichnung || ""}
                onChange={(event) => this.setState({
                    medikation: {
                        ...this.state.medikation,
                        arzneimittelHandelsbezeichnung: event.target.value
                    }
                })}
                error={this.medikationErrors.get("arzneimittelHandelsbezeichnung")}
            />
        )
    }

    renderMedTextfield(prop: string, label: string, placeholder?: string, error?: string, onBlur?: () => void) {
        return (
            <TextField
                id={"med_" + prop}
                label={label}
                placeholder={placeholder || label}
                multiline
                minRows = {1}
                maxRows={4}
                value={this.state.medikation[prop] || ""}
                onChange={(event) => this.setState({
                    medikation: {
                        ...this.state.medikation,
                        [prop]: event.target.value
                    }
                })}
                onBlur={onBlur}
                disabled={this.state.medikation.dosieranweisung != null && this.state.medikation.dosieranweisung !== ""}
                //error={
                //error ?
                //this.medikationErrors.get(error) :
                //false}
            />
        )
    }

    render() {
        this.medikationErrors = new Map([
            ["arzneimittelPzn",
                (this.state.medikation.arzneimittelPzn == null ||
                    this.state.medikation.arzneimittelPzn === "") &&
                this.state.medikation.arzneimittelType === "PZN"],
            ["arzneimittelHandelsbezeichnung",
                (this.state.medikation.arzneimittelHandelsbezeichnung == null || this.state.medikation.arzneimittelHandelsbezeichnung === "")],
            //&& this.state.medikation.arzneimittelType!=="NA"],

            ["dosiereinheit", this.state.medikation.dosiereinheit == null || this.state.medikation.dosiereinheit === ""],
            ["dosiermenge", this.state.medikation.dosiermenge == null || this.state.medikation.dosiermenge === 0 || isNaN(this.state.medikation.dosiermenge) === true],
            ["dosierschema", this.state.medikation.dosierschema == null || this.state.medikation.dosierschema === "" || this.state.medikation.dosierschema === "----"]
        ])

        return <SimpleModalContainer isOpen={this.props.open} additionalClassname="modal-add-data">
            <Paper className="modal-wrapper">
                <h2 className="modal-header">{this.props.add ? "Medikation hinzufügen" : "Medikation bearbeiten"}</h2>
                <Box className="modal-content">
                    <form className="add-form med-form" noValidate autoComplete="off">
                        {this.renderArzneimittelPzn()}
                        {this.renderHandelsbezeichnung()}
                        <FormControl component="fieldset">
                            <FormLabel
                                component="legend"
                                className="MuiInputLabel-shrink"
                                //error={this.state.medikation.dosierschema == null || this.state.medikation.dosierschema === "" || this.state.medikation.dosierschema === "----"}
                            >
                                Dosierschema
                            </FormLabel>
                            <Box className="combo-ipt-multi">
                                {
                                    [{label: "Früh", className: "first-ipt", id: "morning"},
                                        {label: "Mittag", className: "mid-ipt", id: "lunch"},
                                        {label: "Abend", className: "mid-ipt", id: "evening"},
                                        {label: "Nacht", className: "mid-ipt", id: "night"},
                                    ].map((item, index) =>
                                        <TextField
                                            disabled={this.state.medikation.dosieranweisung != null && this.state.medikation.dosieranweisung !== ""}
                                            key={item.id}
                                            id={"med_schema_" + item.id}
                                            className={item.className}
                                            label={item.label}
                                            multiline
                                            minRows = {1}
                                            maxRows={4}
                                            value={
                                                this.state.dosierschemaArray[index] || ""
                                            }
                                            onChange={(event) => {
                                                const dosierschemaArray = this.state.dosierschemaArray;
                                                dosierschemaArray[index] = event.target.value
                                                this.setState({
                                                    dosierschemaArray: dosierschemaArray,
                                                    medikation: {
                                                        ...this.state.medikation,
                                                        dosierschema: dosierschemaArray.join("-")
                                                    }
                                                })
                                            }}
                                            error={this.checkDosierschemaElement(index)}
                                            onBlur = {() => {
                                                this.checkDosierschemaElement(index) ?
                                                this.setState(prevState => {
                                                    let checked: boolean[] = prevState.isDosierschemaCorrect;
                                                    checked[index] = false;
                                                    return {
                                                        isDosierschemaCorrect: checked
                                                    }
                                                }) :
                                                this.setState(prevState => {
                                                    let checked: boolean[] = prevState.isDosierschemaCorrect;
                                                    checked[index] = true;
                                                    return {
                                                        isDosierschemaCorrect: checked
                                                    }
                                                })
                                            }}
                                        />)
                                }
                                {this.renderMedTextfield("dosiereinheit", "Einheit", "z.b.: mg", "dosiereinheit")}
                            </Box>
                        </FormControl>
                        <Box className="combo-ipt-num-unit">
                            <TextField
                                id="dosierschema_sonstige"
                                className='last-ipt dosing-sonstig'
                                label="Dosierschema (Sonstige Angaben)"
                                multiline
                                minRows = {1}
                                maxRows={4}
                                value={this.state.medikation.sonstigeAngabeDosierschema || ""}
                                onChange={(event) => {
                                    this.setState({
                                        medikation: {
                                            ...this.state.medikation,
                                            sonstigeAngabeDosierschema: event.target.value
                                        }
                                    })
                                }}
                            />
                        </Box>
                        <FormControl className="med-dauermedikation" component="fieldset">
                            <FormLabel component="legend" className = 'float-left'>
                                Dauermedikation *
                            </FormLabel>

                            <RadioGroup
                                className=""
                                row
                                aria-label="outdoor"
                                name="outdoor"
                                value={this.state.medikation.dauermedikation ? "true" : "false"}
                                onChange={event => {
                                    let dm: boolean;
                                    event.target.value === "true" ?
                                    dm = true :
                                    dm = false;
                                    this.setState({
                                        medikation: {
                                            ...this.state.medikation,
                                            dauermedikation: dm,
                                        }
                                    })
                                }}
                            >
                                <FormControlLabel value="true" control={<Radio/>} label="ja"/>
                                <FormControlLabel value="false" control={<Radio/>} label="nein"/>
                            </RadioGroup>
                        </FormControl>
                    </form>
                </Box>
                <Box className="modal-footer">
                    <Button variant="text" color="primary" onClick={() => {
                        this.props.callback(null, this.props.add);
                        this.setState({
                            medikation: this.props.add ? new Medikation() : this.props.medikation,
                            dosiermengeString: this.props.add ? "" : String(this.props.medikation.dosiermenge),
                            dosierschemaArray: this.props.add ? [null, null, null, null, null] : (this.props.medikation.dosierschema || "").split("-")
                        });
                    }}>
                        Abbrechen
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleSubmit()}
                    >
                        {this.props.add ? "Hinzufügen" : "Speichern"}
                    </Button>
                </Box>
            </Paper>
        </SimpleModalContainer>
    }
}

export const MedikationEditComponentContainer = connector(MedikationEditComponent);


interface IMedikationViewProps {
    medikation: Medikation;
    isOpen: boolean;
    handleClose: () => void;
}

export class MedikationViewComponent extends React.Component<IMedikationViewProps> {

    render() {
        let dosierschema = this.props.medikation.dosierschema?.split("-").map(item => { //go over all items
            let separated = item.replace(/[^\d,.?/]/g, ' ').replace(/  +/g, ' ').replace(/,/g, '.').split(" ").map(subitem => { // for each element replace all not-number elements. except dots and commas with space, split it another array
                if (subitem === "0.25" || subitem === ".25") { // check if each subitem has to be replaced
                    return subitem = "1/4";
                }
                else if (subitem.startsWith("0.33") || subitem.startsWith(".33"))
                    return subitem = "1/3";
                else if (subitem === "0.5" || subitem === ".5")
                    return subitem = "1/2";
                else if (subitem === "0.125" || subitem === ".125")
                    return subitem = "1/8";
                else if (subitem === "")
                    return subitem = "0";
                else
                    return subitem;             
            }).join(" bis "); // join subarray of subitems back to one string
            return separated;
        });
        const dosierschemaUpdated: string | undefined = dosierschema?.join(" \u2022 "); // join array to one string separated with big dots
        let dosiereinheit = this.props.medikation.dosiereinheit?.replace(/[{}']+/g,''); //remove brackets from dosiereinheit
        const dosierschemaUpdatedAndDosiereinheit = [dosierschemaUpdated, dosiereinheit].join(" ");

        const dosieranweisung = this.props.medikation.dosieranweisung?.replace(/[{}']+/g,'').split("\n").filter(item => item !== ""); // remove {} brackets, split string by "\n" symbol, remove empty string elements from resulting array
        let dosierSchemaComponent: any;

        if (this.props.medikation.dosierschema == null || this.props.medikation.dosierschema === "") {
            dosierSchemaComponent =
                <ViewText
                    labelVariant='caption'
                    label='Dosierschema'
                    text = "-"
                />
        }

        else {
            dosierSchemaComponent =
                <ViewText
                    labelVariant='caption'
                    label='Dosierschema'
                    text={dosierschemaUpdatedAndDosiereinheit || "-"}
                />
        }

        let dosieranweisungComponent: any;
        if (this.props.medikation.dosieranweisung && this.props.medikation.dosieranweisung !== "") {
            dosieranweisungComponent = 
                <ViewText
                    labelVariant='caption'
                    label='Dosieranweisung'
                    text={dosieranweisung || "-"}
                    isSmallFont = {true}
                />
        }
        else {
            dosieranweisungComponent = 
                <ViewText
                    labelVariant='caption'
                    label='Dosieranweisung'
                    text={"-"}
                    isSmallFont = {true}
                />
        }

        return <SimpleModalContainer isOpen={this.props.isOpen} onClose={() => this.props.handleClose()}
                                     additionalClassname="modal-add-data">
            <Paper className="modal-wrapper view-medikation">
                <h2 className="modal-header">Medikation</h2>
                <Box className="modal-content data-content">
                    <Box className="txt-grid">
                        <ViewText
                            labelVariant='caption'
                            label='Pharmazentralnummer'
                            text={this.props.medikation.arzneimittelPzn || "-"}
                        />
                        <ViewText
                            labelVariant='caption'
                            label='Handelsbezeichnung'
                            text={this.props.medikation.arzneimittelHandelsbezeichnung || "-"}
                        />
                        {dosierSchemaComponent}
                        {dosieranweisungComponent}
                        <ViewText
                            labelVariant='caption'
                            label = 'Freitextliche Dosierinstruktion'
                            text={this.props.medikation.sonstigeAngabeDosierschema || "-"}
                        />
                        <ViewText
                            labelVariant='caption'
                            label='Dauermedikation'
                            text={(this.props.medikation.dauermedikation) ? "JA" : "NEIN"}
                        />
                    </Box>
                </Box>
                <Box className="modal-footer">
                    <Button variant="text" color="primary" onClick={() => this.props.handleClose()}>
                        Schließen
                    </Button>
                </Box>
            </Paper>
        </SimpleModalContainer>
    }
}


