import React from 'react';
// material components
import {
    Box,
    Button,
    Paper,
    Typography,
} from '@material-ui/core'
import SimpleModalContainer from '../elements/simple_modal';
import { Warning } from '@material-ui/icons';


interface IProps {
    isOpen: boolean;
    onClose: (event: any) => void;
    onConfirm: (event: any) => void;
    text: string;
}

export default class ConfirmationPopupComponent extends React.Component<IProps> {

    render() {
        return (
                <SimpleModalContainer isOpen={this.props.isOpen} onClose={this.props.onClose}>
                    <Paper className="modal-wrapper konsil-answer-pop">
                        <Box className="modal-content konsil-submit-feedback">
                            <Warning className="konsil-submit-alert"/>
                            <h2>Achtung</h2>
                            <Typography className="question-txt" variant="body1" color="inherit">
                                {this.props.text}
                            </Typography>
                        </Box>
                        <Box className="modal-footer">
                            <Button variant="text" onClick={this.props.onClose}>
                                Abbrechen
                            </Button>
                            <Button variant="contained" color="primary"
                                onClick={this.props.onConfirm}>
                                Ja
                            </Button>
                        </Box>
                    </Paper>
            </SimpleModalContainer>
        );
    }
}