import React from 'react';
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import { GdtConfiguration, GDTVersion, UploadPath } from 'telescan-core';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { CheckCircle, Edit, HighlightOff, PlayCircleFilledOutlined, Stop } from '@material-ui/icons';
import GdtEditPopup from './gdt_edit_popup';
import { saveGdtConfig, startGdtWatcher, stopGdtWatcher } from '../../../redux/actions/gdt_actions';

interface IState {
    isGdtEditPopupOpen: boolean;
    gdtConfig: GdtConfiguration;
    gdtConfigBackup: GdtConfiguration;
}

const mapStateToProps = (state: RootState) => ({
    gdtConfig: state.gdt.gdtConfiguration,
    role: state.user.role,
    initialisedServices: state.general.initialisedServices,
})

const mapDispatchToProps = {
    dispatch_saveGdtConfig: (gdtConfig: GdtConfiguration) => saveGdtConfig(gdtConfig),
    dispatch_startGdtWatcher: () => startGdtWatcher(),
    dispatch_stopGdtWatcher: () => stopGdtWatcher(),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class GdtConfigurationComponent extends React.Component<TPropsFromRedux, IState> {

    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            isGdtEditPopupOpen: false,
            gdtConfig: new GdtConfiguration(),
            gdtConfigBackup: new GdtConfiguration(),
        }
    }

    componentDidMount() {
        this.setState({gdtConfig: this.props.gdtConfig});
    }

    componentDidUpdate(prevProps: TPropsFromRedux) {
        if (prevProps.gdtConfig !== this.props.gdtConfig) {
            this.setState({gdtConfig: this.props.gdtConfig});
        }
    }

    closeGdtEditPopup = () => {
        this.setState({isGdtEditPopupOpen: false})
        this.setState({gdtConfig: this.state.gdtConfigBackup});
    }

    handleOnTableRowClick = () => {
        this.setState({gdtConfigBackup: this.props.gdtConfig});
        this.setState({isGdtEditPopupOpen: true});
    }

    handleChangeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({
            gdtConfig: {
                ...this.state.gdtConfig,
                gdtVersion: event.target.value as GDTVersion
            }
        })
    }

    handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            gdtConfig: {
                ...this.state.gdtConfig,
                [event.target.name]: event.target.checked
            }
        })
    }

    handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            gdtConfig: {
                ...this.state.gdtConfig,
                // [event.target.name]: event.target.name === "attachmentDirectory" && event.target.value === "" ? null : event.target.value,
                [event.target.name]: event.target.value === "" ? null : event.target.value,
            }
        })
    }

    handleSaveGdtCongigButton = () => {
        this.setState({
            isGdtEditPopupOpen: false,
        });
        if (this.props.role === "DERMATOLOGE" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE") {
            this.setState(
                {
                    gdtConfig: {
                        ...this.state.gdtConfig,
                        directory: this.state.gdtConfig.exportDirectory
                }},
                () => this.props.dispatch_saveGdtConfig(this.state.gdtConfig)
            )
        } else {
            this.props.dispatch_saveGdtConfig(this.state.gdtConfig);
        }      
    }

    render() {
        return (
            <Box className="bsnr-info-container" >
                <Box className="flex-row-center">
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                        GDT Konfiguration 
                    </Typography>
                    {this.props.initialisedServices.includes(UploadPath.KIM) &&
                    <>
                        <Tooltip title="Konfiguration des GDT-Dienstes verwalten">
                            <span className = "margin-left-auto margin-right">
                                <Button
                                    className = "slim"
                                    variant="contained"
                                    color="primary"
                                    onClick = {this.handleOnTableRowClick}
                                    disabled = {this.props.gdtConfig.active}
                                >
                                    <Edit/>                     
                                </Button>
                            </span>                   
                        </Tooltip>
                        {this.props.gdtConfig.active ?
                            <Tooltip title = "GDT-Dienst stoppen">
                                <span>
                                    <Button
                                        className="slim"
                                        color="primary"
                                        variant="contained"
                                        onClick = {() => this.props.dispatch_stopGdtWatcher()}
                                    >
                                        <Stop/>
                                    </Button>
                                </span>                          
                            </Tooltip> :
                            <Tooltip title = "GDT-Dienst starten">
                                <span>
                                    <Button
                                        className="slim"
                                        variant="contained"
                                        color="primary"
                                        onClick = {() => this.props.dispatch_startGdtWatcher()}
                                        disabled = {!this.props.gdtConfig.directory || this.props.gdtConfig.directory === "" ||
                                            !this.props.gdtConfig.gdtVersion ||
                                            !this.props.gdtConfig.gdtIdTelescan || this.props.gdtConfig.gdtIdTelescan === "" ||
                                            !this.props.gdtConfig.gdtIdPVS || this.props.gdtConfig.gdtIdPVS === "" ||
                                            (this.props.gdtConfig.importFileByRegex && (!this.props.gdtConfig.importFilename || this.props.gdtConfig.importFilename === ""))}
                                    >
                                        <PlayCircleFilledOutlined/>
                                    </Button>
                                </span>                         
                            </Tooltip>
                        }    
                    </>                    
                    }
                </Box>
                <Paper elevation={0} className="popup-insert">
                    {this.props.initialisedServices.includes(UploadPath.KIM) ?
                    <>
                        <Typography className="bsnr-info" variant="caption" color="inherit">
                            {!this.props.gdtConfig.directory || this.props.gdtConfig.directory === "" || !this.props.gdtConfig.gdtVersion ?
                                "GTD ist nicht vollständig konfiguriert. Vervollstendigen Sie Ihre GDT-Einstellungen, um GDT-Dienst starten zu können"
                                :
                                "Hier können Sie Ihre GDT-Einstellungen verwalten"
                            }
                            
                        </Typography>
                        <TableContainer className="zertifikate-table selectable-rows">
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Importordner</TableCell>       
                                        <TableCell>Exportordner</TableCell>                          
                                        <TableCell>GDT-ID TeleScan</TableCell>
                                        <TableCell>GDT-ID PVS</TableCell>
                                        <TableCell align = 'right'>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow
                                        hover
                                        onClick = {this.handleOnTableRowClick}
                                    >
                                        <TableCell>{this.props.gdtConfig.directory || ""}</TableCell>   
                                        <TableCell>{this.props.gdtConfig.exportDirectory || ""}</TableCell>                             
                                        <TableCell>{this.props.gdtConfig.gdtIdTelescan || ""}</TableCell>
                                        <TableCell>{this.props.gdtConfig.gdtIdPVS || ""}</TableCell>
                                        <TableCell align = 'right'>
                                            {this.props.gdtConfig.active ?
                                                <Tooltip title = "In Betrieb">
                                                    <CheckCircle className = 'up-to-date'/>
                                                </Tooltip> 
                                                :
                                                <Tooltip title = "Außer Betrieb">
                                                    <HighlightOff className = 'out-of-date'/> 
                                                </Tooltip>                    
                                            }
                                        </TableCell>                   
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer> 
                    </> :
                    <Typography className="bsnr-info" variant="caption" color="inherit">
                        GDT-Funktionalität steht Ihnen zur Verfügung, wenn KIM-Erweiterung vollständig eingerichtet ist.                 
                    </Typography>
                    }
        
                </Paper>
            <GdtEditPopup
                isOpen = {this.state.isGdtEditPopupOpen}
                handleClose = {this.closeGdtEditPopup}
                gdtConfig = {this.state.gdtConfig}
                onChangeSelect = {this.handleChangeSelect}
                onChangeCheckbox = {this.handleChangeCheckbox}
                onChangeInput = {this.handleChangeInput}
                onSaveButtonClick = {this.handleSaveGdtCongigButton}
                isGdtRunning = {this.props.gdtConfig.active}
                role = {this.props.role}
            />
            </Box>
        )
    }
}

export const GdtConfigurationComponentContainer = connector(GdtConfigurationComponent);
export default GdtConfigurationComponentContainer;

