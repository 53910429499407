import { InformationUpdate, UpdateStepStatusType } from "telescan-core"
import { TUpdateActionTypes, EUpdateActions } from "../types/update_types"

export interface IUpdateState {
    updateRunning: boolean;
    updateInfo: InformationUpdate[];
    hpmUpdateSteps: {step: string, status: keyof typeof UpdateStepStatusType, progress: string, message: string}[];
    teleScanUpdateSteps: {step: string, status: keyof typeof UpdateStepStatusType, progress: string, message: string}[];
    module: string;
    listOfUpdateSteps: Object[];
    isUpdateSuccess: boolean;
}

const initialState: IUpdateState = {
    updateRunning: false,
    updateInfo: [],
    hpmUpdateSteps: [],
    teleScanUpdateSteps: [],
    module: "",
    listOfUpdateSteps: [],
    isUpdateSuccess: false,
}

export function update_reducer(state = initialState, action: TUpdateActionTypes): IUpdateState {
    switch (action.type) {
        case EUpdateActions.SET_UPDATE_RUNNING:
            return {
                ...state,
                updateRunning: action.updateRunning
            }
        case EUpdateActions.SET_UPDATE_INFO:
            return {
                ...state,
                updateInfo: action.updateInfo
            }
        case EUpdateActions.SET_HPM_UPDATE_STEPS:
            return {
                ...state,
                hpmUpdateSteps: action.hpmUpdateSteps
            }
        case EUpdateActions.SET_TELESCAN_UPDATE_STEPS:
            return {
                ...state,
                teleScanUpdateSteps: action.teleScanUpdateSteps
            }
        case EUpdateActions.SET_UPDATING_MODULE:
            return {
                ...state,
                module: action.module
            }
        case EUpdateActions.SET_LIST_OF_UPDATE_STEPS:
            return {
                ...state,
                listOfUpdateSteps: action.listOfUpdateSteps
            }
        case EUpdateActions.SET_UPDATE_SUCCESS:
            return {
                ...state,
                isUpdateSuccess: action.isUpdateSuccess
            }
        default:
            return state
    }
}