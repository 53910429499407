import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMars, faVenus, faVenusMars, faUser } from '@fortawesome/free-solid-svg-icons'
import { Patient, PatientService } from 'telescan-core';
import Box from '@material-ui/core/Box';


interface PropsGeschlecht { geschlecht: string | undefined }

class GeschlechtIcon extends React.Component<PropsGeschlecht> {
    render() {
        if (this.props.geschlecht === "MAENNLICH")
            return <FontAwesomeIcon icon={faMars} />
        else if (this.props.geschlecht === "WEIBLICH")
            return <FontAwesomeIcon icon={faVenus} />
        else
            return <FontAwesomeIcon icon={faVenusMars} />
    }
}

interface PropsPatient { patient: Patient }

export class KonsilPatient extends React.Component<PropsPatient> {
    patientService: PatientService = new PatientService();

    render() {
        return (
            <Box className="patient">
                <FontAwesomeIcon icon={faUser} /> {this.props.patient.title} {this.props.patient.vorname} {this.props.patient.nachname} <GeschlechtIcon geschlecht={this.props.patient.geschlecht} />
            </Box>
        )
    }
}
