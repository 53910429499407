import  React from 'react';
import {connect, ConnectedProps} from "react-redux";
import { UserContainer } from '../../header';
import Logo from "../../../static/images/logo-telescan.svg";
import { Box, Button, CircularProgress } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { RootState } from '../../../redux/reducers';
import { failure, navigate, } from "../../../redux/actions";
import { proveBillStatus, sendQuartalsBill  } from '../../../redux/actions/abrechnung_actions';
import DialogNoNewBills from './dialog_chech_no_new_bills';

const mapStateToProps = (state: RootState) => ({
    isDesktop: state.general.isDesktop,
    role: state.user.role,
    user: state.user.user,
    currentQuartalsAbrechnung: state.quartalsabrechnungenStore.currentQuartalsabrechnung,
})

const mapDispatchToProps = {
    dispatch_navigate: (url: string) => navigate(url),
    dispatch_proveBillStatus: (id: string) => proveBillStatus(id),
    dispatch_failure: (id: string, error: string) => failure(id, error),
    dispatch_sendQuartalsBill: (id: string) => sendQuartalsBill(id),
}

type TPropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapStateToProps, mapDispatchToProps)

interface IState {
    isCheckNoNewBillsDialogOpened: boolean;
    isSendNoNewBillsDialogOpened: boolean;
    dialogText: string;
}

class SingleQuartalBillTableHeader extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            isCheckNoNewBillsDialogOpened: false,
            isSendNoNewBillsDialogOpened: false,
            dialogText: "",
        };
    }

    onDialogSubmitProve = () => {
        if (this.props.currentQuartalsAbrechnung.konsilabrechnung?.filter(item => item.status !== "ABGERECHNET").length === 0) {
            this.setState({
                isCheckNoNewBillsDialogOpened: true,
                dialogText: "Die Quartalsabrechnung enthält keine neu zu übermittelnden Leistungen. Sie können dennoch eine Prüfung durchführen.",
            });
        }
        else
            this.props.dispatch_proveBillStatus(this.props.currentQuartalsAbrechnung.id || "");
    }

    onDialogSubmitSend = () => {
        if (this.props.currentQuartalsAbrechnung.konsilabrechnung?.filter(item => item.status !== "ABGERECHNET").length === 0) {
            this.setState({
                isSendNoNewBillsDialogOpened: true,
                dialogText: "Die Quartalsabrechnung enthält keine neu zu übermittelnden Leistungen. Sie können dennoch alle Daten erneut übermitteln. Dies kann notwendig sein, wenn Sie dazu aufgefordert werden, oder nach 3 Werktagen keine Bestätigung für den Eingang Ihrer letzten Quartalsabrechnung bekommen haben.",
            });
        }
        else 
            this.props.dispatch_sendQuartalsBill(this.props.currentQuartalsAbrechnung.id || ""); 
    }

    hanldeCloseDialog = () => {
        this.setState({
            isCheckNoNewBillsDialogOpened: false,
            isSendNoNewBillsDialogOpened: false,
        });
    }

    render() {
        return (
            <header className="header-bill">
                <DialogNoNewBills
                    isNoNewBillsDialogOpened = {this.state.isCheckNoNewBillsDialogOpened}
                    handleSubmitAction = {this.props.dispatch_proveBillStatus}
                    handleCloseDialog = {this.hanldeCloseDialog}
                    dialogText = {this.state.dialogText}
                    currentQuartalsAbrechnungId = {this.props.currentQuartalsAbrechnung.id || ""}
                />
                <DialogNoNewBills
                    isNoNewBillsDialogOpened = {this.state.isSendNoNewBillsDialogOpened}
                    handleSubmitAction = {this.props.dispatch_sendQuartalsBill}
                    handleCloseDialog = {this.hanldeCloseDialog}
                    dialogText = {this.state.dialogText}
                    currentQuartalsAbrechnungId = {this.props.currentQuartalsAbrechnung.id || ""}
                />
                <Box className="flex-row-center">
                    <Button className="grid-button back-btn left narrow" onClick={() => this.props.dispatch_navigate("/billing")}>
                        Zurück
                    </Button>
                    {this.props.isDesktop && <h1 className="title pad-left-l">Abrechnung</h1>}
                </Box>
                
                <img src={Logo} alt="Logo" className="logo"/>
                <UserContainer userDetails={this.props.user}/>
                <Box className="title-actions">
                    {!this.props.isDesktop && <h1 className="title">Abrechnung</h1>}
                <div className="actions flex-row-center">
                        {this.props.currentQuartalsAbrechnung.statusQuartalsabrechnung === "GEPRÜFT" ?
                            <Button
                                variant = 'contained'
                                color = 'primary'
                                size = 'small'
                                endIcon  = {<CheckBoxIcon/>}
                                style={{ backgroundColor: 'limegreen' }}
                            >
                                GEPRÜFT
                            </Button> :
                            <Button
                                variant = 'contained'
                                color = 'primary'
                                size = 'small'
                                onClick = {this.onDialogSubmitProve}
                                disabled = {this.props.currentQuartalsAbrechnung.statusQuartalsabrechnung === "ABGERECHNET" || this.props.currentQuartalsAbrechnung.konsilabrechnung?.length === 0}
                                endIcon  = {this.props.currentQuartalsAbrechnung.isInProgress && <CircularProgress size = {25} className = 'circular-progress-white'/>}
                            >
                                PRÜFEN
                            </Button>
                        }
                        <Button
                            variant = 'contained'
                            color = 'primary'
                            size = 'small'
                            disabled = {this.props.currentQuartalsAbrechnung.statusQuartalsabrechnung !== "GEPRÜFT"}
                            onClick = {this.onDialogSubmitSend}
                            endIcon  = {this.props.currentQuartalsAbrechnung.isInProgress && this.props.currentQuartalsAbrechnung.statusQuartalsabrechnung === "GEPRÜFT" && <CircularProgress size = {25} color = 'secondary'/>}
                        >
                            ABSENDEN
                        </Button>
                        {this.props.isDesktop && 
                            <>
                                <Box>
                                    {this.props.currentQuartalsAbrechnung.abrechnenderArzt?.bsnr || "-"} / {this.props.currentQuartalsAbrechnung.abrechnenderArzt?.lanr || "-"} / 
                                        {this.props.currentQuartalsAbrechnung.abrechnenderArzt?.vorname || "-"} {this.props.currentQuartalsAbrechnung.abrechnenderArzt?.nachname || "-"}<br/>
                                    {this.props.currentQuartalsAbrechnung.jahr || "-"} / Q{this.props.currentQuartalsAbrechnung.quartal || "-"}
                                </Box>
                                <Box className="flex-spacer"/>
                            </>
                        }
                        {/* <FormControl variant="outlined">
                            <Select
                                labelId="filter-label"
                                id="mutiple-filter-checkbox"
                                className="table-filter select-dopdown-btn MuiButtonBase-root MuiButton-root MuiButton-outlined slim MuiButton-outlinedSizeSmall MuiButton-sizeSmall"
                                multiple
                                displayEmpty
                                value={this.props.filter}
                                //onChange={this.changeFilter}
                                input={<Input />}
                                SelectDisplayProps={{
                                    onClick: this.selectHandleOnOpen
                                }}
                                MenuProps={{
                                    onClose:(event, reason:'backdropClick' | 'escapeKeyDown') => this.selectHandleOnOpen(event)
                                }}
                                open={this.state.canOpen}
                                renderValue={(selected) => {
                                    if ((selected as string[]).length === 0) {
                                        return <span>
                                            {this.props.isDesktop && <Tooltip title="Tabelle filtern" placement="top"><span>Filter</span></Tooltip>}
                                            {!this.props.isDesktop && <Tooltip title="Tabelle filtern" placement="top"><VolumeMute className="filter-icon"/></Tooltip>}
                                        </span>
                                    } else {
                                        return (
                                            <span className="flex-row-center" >
                                                {this.props.isDesktop && <Tooltip title="Tabelle filtern" placement="top"><span>Filter</span></Tooltip>}
                                            {!this.props.isDesktop && <Tooltip title="Tabelle filtern" placement="top"><VolumeMute className="filter-icon"/></Tooltip>}
                                                <Tooltip title="Filter zurücksetzen" placement="left">
                                                    <span className="filter-close"
                                                        onClick={(event) => {
                                                                event.stopPropagation();
                                                            }}
                                                    >
                                                        <Close/>
                                                    </span>
                                                </Tooltip>
                                            </span>
                                        );
                                    }

                                    }}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem disabled value="">
                                    <em>Filter</em>
                                </MenuItem>
                            

                            Object.keys(KonsilStatusType).filter(item => item !== "IN_ARBEIT").map((status) => (
                                <MenuItem key={status} value={KonsilStatusType[status]}>
                                <Checkbox checked={this.props.filter.indexOf(KonsilStatusType[status]) > -1}
                                />
                                <ListItemText primary={KonsilStatusType[status]} />
                                </MenuItem>
                            ))
                            </Select>

                        </FormControl> */}
                    </div>
                </Box>
                {/* <HeaderSearch/> */}
            </header>
        );
    }
}

const SingleQuartalBillTableHeaderContainer = connector(SingleQuartalBillTableHeader);
export default SingleQuartalBillTableHeaderContainer;