import { KonsilMetaInformation} from "telescan-core";
import {EDermatologeActions, TDermatologeActionTypes} from '../types/dermatologe_types';


export interface IDermaState {
    metadatenAdressiert: KonsilMetaInformation[];
    metadatenGerichtet: KonsilMetaInformation[];
    activeAreas: boolean[];
}

const initialState: IDermaState = {
    metadatenAdressiert: [],
    metadatenGerichtet: [],
    activeAreas: [false, false, false, false],
}

export function dermatologe_reducer(state = initialState, action: TDermatologeActionTypes): IDermaState {
    switch (action.type) {
        case EDermatologeActions.SET_METADATEN_ADRESSIERT:
            return {
                ...state,
                metadatenAdressiert: action.metadatenAdressiert
            }
            
        case EDermatologeActions.SET_METADATEN_GERICHTET:
            return {
                ...state,
                metadatenGerichtet: action.metadatenGerichtet
            }

        case EDermatologeActions.SET_ACTIVE_AREAS:
            return {
                ...state,
                activeAreas: {
                    ...state.activeAreas,
                    [action.areaIndex]: action.isActive
                }
            }

        case EDermatologeActions.SET_METADATEN_ADRESSIERT_KONSIL_BEARBEITUNGSSTATUS:
            const konsilIndexAdd = state.metadatenAdressiert.findIndex(item => item.vorgangsId === action.vorgangsId);
            const metadatenAdressiertNewArray = [...state.metadatenAdressiert];
            metadatenAdressiertNewArray[konsilIndexAdd].bearbeitungsStatus = action.bearbeitungsStatus;
            return {
                ...state,
                metadatenAdressiert: metadatenAdressiertNewArray,
            }

        case EDermatologeActions.SET_METADATEN_GERICHTET_KONSIL_BEARBEITUNGSSTATUS:
            const konsilIndexGer = state.metadatenGerichtet.findIndex(item => item.vorgangsId === action.vorgangsId);
            const metadatenGerichtetNewArray = [...state.metadatenGerichtet];
            metadatenGerichtetNewArray[konsilIndexGer].bearbeitungsStatus = action.bearbeitungsStatus;
            return {
                ...state,
                metadatenGerichtet: metadatenGerichtetNewArray,
            }

        case EDermatologeActions.REMOVE_METADATEN_ADRESSIERT_KONSIL:
            const metadatenAdressiertFiltered = state.metadatenAdressiert.filter(item => item.vorgangsId !== action.vorgangsId)
            return {
                ...state,
                metadatenAdressiert: metadatenAdressiertFiltered,
            }

        case EDermatologeActions.REMOVE_METADATEN_GERICHTET_KONSIL:
            const metadatenGerichtetFiltered = state.metadatenGerichtet.filter(item => item.vorgangsId !== action.vorgangsId)
            return {
                ...state,
                metadatenGerichtet: metadatenGerichtetFiltered,
            }
        default:
            return state
    }
}
