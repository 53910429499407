import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../reducers';
import  * as NotificationTypes from '../types/notification_types';
import { Failure, Notification, NotificationActionType, NotificationService, ApplicationType, NotificationSubscriptionType } from 'telescan-core';
import uuid from 'uuid';
import { failure } from './general_actions';
// 1. Pure redux actions

export function setAllNotifications(allNotification: Notification[]): NotificationTypes.ISetAllNotifications {
    return {
        type: NotificationTypes.ENotificationActions.SET_ALL_NOTIFICATIONS,
        allNotifications: allNotification
    }
}

export function setIsAllNotifications(isAllNotification: boolean): NotificationTypes.ISetIsAllNotifications {
    return {
        type: NotificationTypes.ENotificationActions.SET_IS_ALL_NOTIFICATIONS,
        isAllNotifications: isAllNotification
    }
}

export function setIsNotificationsLoading(isNotificationsLoading: boolean): NotificationTypes.ISetIsNotificationsLoading {
    return {
        type: NotificationTypes.ENotificationActions.SET_IS_NOTIFICATIONS_LOADING,
        isNotificationsLoading: isNotificationsLoading
    }
}

export function setIsCriticalNotification(isCriticalNotification: boolean): NotificationTypes.ISetIsCriticalNotification {
    return {
        type: NotificationTypes.ENotificationActions.SET_IS_CRITICAL_NOTIFICATION,
        isCriticalNotification: isCriticalNotification,
    }
}

export function setIsWarningNotification(isWarningNotification: boolean): NotificationTypes.ISetIsWarningNotification {
    return {
        type: NotificationTypes.ENotificationActions.SET_IS_WARNING_NOTIFICATION,
        isWarningNotification: isWarningNotification,
    }
}

export function setIsNormalNotification(isNormalNotification: boolean): NotificationTypes.ISetIsNormalNotification {
    return {
        type: NotificationTypes.ENotificationActions.SET_IS_NORMAL_NOTIFICATION,
        isNormalNotification: isNormalNotification
    }
}

export function setNotifications(notifications: Notification[]): NotificationTypes.ISetNotifications {
    return {
        type: NotificationTypes.ENotificationActions.SET_NOTIFICATIONS,
        notifications: notifications,
    }
}

export function setIsNotificationConfirmed(id: string, isNotificationConfirmed: boolean): NotificationTypes.ISetIsNotificationConfirmed {
    return {
        type: NotificationTypes.ENotificationActions.SET_IS_NOTIFICATION_CONFIRMED,
        id: id,
        isNotificationConfirmed: isNotificationConfirmed
    }
}


export function setIsNotificationDisplayed(id: string, isNotificationDisplayed: boolean): NotificationTypes.ISetIsNotificationDisplayed {
    return {
        type: NotificationTypes.ENotificationActions.SET_IS_NOTIFICATION_DISPLAYED,
        id: id,
        isNotificationDisplayed: isNotificationDisplayed
    }
}

export function removeNotification(id: string): NotificationTypes.IRemoveNotification {
    return {
        type: NotificationTypes.ENotificationActions.REMOVE_NOTIFICATION,
        id: id
    }
}

export function setIsNewsSubscribed(isNewsSubscribed: boolean): NotificationTypes.ISetIsNewsSubscribed {
    return {
        type: NotificationTypes.ENotificationActions.SET_IS_NEWS_SUBSCRIBED,
        isNewsSubscribed: isNewsSubscribed
    }
}

export function setIsSubscriptionLoading(isSubscriptionLoading: boolean): NotificationTypes.ISetIsSubscriptionLoading {
    return {
        type: NotificationTypes.ENotificationActions.SET_IS_SUBSCRIPTION_LOADING,
        isSubscriptionLoading: isSubscriptionLoading
    }
} 
// 2. Thunk middleware functions

export function getAllCurrentNotifications(): ThunkAction<any, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        dispatch(setIsNotificationsLoading(true));
        const application: ApplicationType = (getState().user.role === "HAUSARZT" || getState().user.role === "BETRIEBSSTAETTE_HAUSARZT") ? ApplicationType[ApplicationType.HA_SERVER] : ApplicationType[ApplicationType.DERMA_SERVER];
        const notificationService = new NotificationService();
        notificationService.getAllCurrentNotifications(application)
        .then((notifications: Notification[]) => {
            dispatch(setIsNotificationsLoading(false));
            notifications && dispatch(setAllNotifications(notifications));
        })
        .catch((failureObj: Failure) => {
            dispatch(setIsNotificationsLoading(false));
            const failureId: string = uuid.v4();
            dispatch(failure(failureId, failureObj));
        });
    }
}

export function getCurrentNotifications(): ThunkAction<any, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const application: ApplicationType = (getState().user.role === "HAUSARZT" || getState().user.role === "BETRIEBSSTAETTE_HAUSARZT") ? ApplicationType[ApplicationType.HA_SERVER] : ApplicationType[ApplicationType.DERMA_SERVER];
        const notificationService = new NotificationService();
        notificationService.getCurrentNotifications(application)
        .then((notifications: Notification[]) => {           
            if (notifications.some(notification => notification.notificationType === "RISK")) {
                const riskNotifications: Notification[] = notifications.filter(notification => notification.notificationType === "RISK");
                riskNotifications.length !== 0 && dispatch(setNotifications(riskNotifications));
                dispatch(setIsCriticalNotification(true));
            } else {                
                dispatch(setNotifications(notifications));
                if (notifications.some(notification => notification.notificationType === "WARNING")) {
                    dispatch(setIsWarningNotification(true));
                }
                if (notifications.some(notification => notification.notificationType === "NEWS" || notification.notificationType === "NOTE")) {
                    dispatch(setIsNormalNotification(true)); 
                }
 
            }          
        })
        .catch((failureObj: Failure) => {
            const failureId: string = uuid.v4();
            dispatch(failure(failureId, failureObj));
        });
    }
}

export function getCurrentRiskAndWarningNotifications(): ThunkAction<any, RootState, unknown, Action<string>> {
    return(dispatch, getState) => {
        const application: ApplicationType = (getState().user.role === "HAUSARZT" || getState().user.role === "BETRIEBSSTAETTE_HAUSARZT") ? ApplicationType[ApplicationType.HA_SERVER] : ApplicationType[ApplicationType.DERMA_SERVER];
        const notificationService = new NotificationService();
        notificationService.getCurrentNotifications(application)
        .then((notifications: Notification[]) => {           
            if (notifications.some(notification => notification.notificationType === "RISK")) {
                const riskNotifications: Notification[] = notifications.filter(notification => notification.notificationType === "RISK");
                riskNotifications.length !== 0 && dispatch(setNotifications(riskNotifications));
                dispatch(setIsCriticalNotification(true));
            } else if (notifications.some(notification => notification.notificationType === "WARNING")) {  
                const warningNotifications: Notification[] = notifications.filter(notification => notification.notificationType === "WARNING");              
                warningNotifications.length !== 0 && dispatch(setNotifications(warningNotifications));
                dispatch(setIsWarningNotification(true));
            }          
        })
        .catch((failureObj: Failure) => {
            const failureId: string = uuid.v4();
            dispatch(failure(failureId, failureObj));
        });
    }
}

export function sendNotification(notificationId: string, notificationAction: NotificationActionType): ThunkAction<any, RootState, unknown, Action<string>> {
    return dispatch => {
        const notificationService = new NotificationService();
        notificationService.sendNotificationAction(notificationId, notificationAction)
        .then(() => {
            if (notificationAction === NotificationActionType.DISPLAYED)
                dispatch(setIsNotificationDisplayed(notificationId || "", true));
        })
        .catch((failureObj: Failure) => {
            const failureId: string = uuid.v4();
            dispatch(failure(failureId, failureObj));
        });
    }
}

export function getSubscriptions(): ThunkAction<any, RootState, unknown, Action<string>> {
    return dispatch => {
        const notificationService = new NotificationService();
        notificationService.getSubscriptions()
        .then((response: NotificationSubscriptionType[]) => {
            if (response.includes(NotificationSubscriptionType.NEWS))
                dispatch(setIsNewsSubscribed(true));
            else
                dispatch(setIsNewsSubscribed(false));
        })
        .catch((failureObj: Failure) => {
            const failureId: string = uuid.v4();
            dispatch(failure(failureId, failureObj));
        });
    }
}

export function subscribe(type: NotificationSubscriptionType): ThunkAction<any, RootState, unknown, Action<string>> {
    return dispatch => {
        dispatch(setIsSubscriptionLoading(true));
        const notificationService = new NotificationService();
        notificationService.subscribe(type)
        .then(() => {
            dispatch(setIsSubscriptionLoading(false));
            if (type === NotificationSubscriptionType.NEWS)
                dispatch(setIsNewsSubscribed(true));
        })
        .catch((failureObj: Failure) => {
            dispatch(setIsSubscriptionLoading(false));
            const failureId: string = uuid.v4();
            dispatch(failure(failureId, failureObj));
        });
    }
}

export function unsubscribe(type: NotificationSubscriptionType): ThunkAction<any, RootState, unknown, Action<string>> {
    return dispatch => {
        dispatch(setIsSubscriptionLoading(true));
        const notificationService = new NotificationService();
        notificationService.unsubscribe(type)
        .then(() => {
            dispatch(setIsSubscriptionLoading(false));
            if (type === NotificationSubscriptionType.NEWS)
                dispatch(setIsNewsSubscribed(false));
        })
        .catch((failureObj: Failure) => {
            dispatch(setIsSubscriptionLoading(false));
            const failureId: string = uuid.v4();
            dispatch(failure(failureId, failureObj));
        });
    }
}