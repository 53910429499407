import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';
import { Attachment, AttachmentService, ConfigurationService, Failure, ImageSize, Konsil, KonsilImage, KonsilImageService, KonsilImageType } from 'telescan-core';
import uuid from 'uuid';
import { RootState } from '../reducers';
import * as ImageAttachmentTypes from '../types/image_attachment_types';
import { checkDermaFeedbackStatus, checkKonsilFeedbackStatus, checkKonsiliarberichtStatus, checkLocationStatus } from './evaluation_actions';
import { failure, loading, loadSuccess } from './general_actions';
import { setIsKonsilUpdateConflict, setIsKonsilUpdatedEventShown, setIsKonsilUpdateEventSuccess, setKonsil, updateKonsilOnEvent } from './konsil_actions';

// 1. Pure redux actions
export function setKonsilIdOfImageLoading(imageIdLoading: string, arrayAction: "add" | "remove"): ImageAttachmentTypes.ISetIsFirstImageLoading {
    return {
        type: ImageAttachmentTypes.EImageAttachmentActions.SET_IS_FIRST_IMAGE_LOADING,
        konsilIdOfImageLoading: imageIdLoading,
        arrayAction: arrayAction
    }
}

export function clearKonsilImageList(): ImageAttachmentTypes.IClearKonsilImageList {
    return {
        type: ImageAttachmentTypes.EImageAttachmentActions.CLEAR_KONSIL_IMAGE_LIST,
    }
}

export function clearRueckantwortImageList(): ImageAttachmentTypes.IClearRueckantwortImageList {
    return {
        type: ImageAttachmentTypes.EImageAttachmentActions.CLEAR_RUECKANTWORT_IMAGE_LIST,
    }
}


export function uploadKonsilImageProgress(image_id: string, percent: number): ImageAttachmentTypes.IUploadKonsilImageProcess {
    return {
        type: ImageAttachmentTypes.EImageAttachmentActions.UPLOAD_KONSIL_IMAGE_PROCESS,
        percent: percent,
        image_id: image_id
    }
}

export function removeKonsilImage(image_id: string): ImageAttachmentTypes.IRemoveKonsilImage {
    return {
        type: ImageAttachmentTypes.EImageAttachmentActions.REMOVE_KONSIL_IMAGE,
        image_id: image_id
    }
}

export function removeRueckantwortImage(image_id: string): ImageAttachmentTypes.IRemoveRueckantwortImage {
    return {
        type: ImageAttachmentTypes.EImageAttachmentActions.REMOVE_RUECKANTWORT_IMAGE,
        image_id: image_id
    }
}

export function uploadAttachmentProgress(attachment_id: string, percent: number): ImageAttachmentTypes.IUploadAttachmentProcess {
    return {
        type: ImageAttachmentTypes.EImageAttachmentActions.UPLOAD_ATTACHMENT_PROCESS,
        percent: percent,
        attachment_id: attachment_id
    }
}

export function uploadRueckantwortAttachmentProgress(attachment_id: string, percent: number): ImageAttachmentTypes.IUploadRueckantwortAttachmentProcess {
    return {
        type: ImageAttachmentTypes.EImageAttachmentActions.UPLOAD_RUECKANTWORT_ATTACHMENT_PROCESS,
        percent: percent,
        attachment_id: attachment_id
    }
}

export function clearAttachmentList(): ImageAttachmentTypes.IClearAttachmentList {
    return {
        type: ImageAttachmentTypes.EImageAttachmentActions.CLEAR_ATTACHMENT_LIST,
    }
}

export function clearRueckantwortAttachmentList(): ImageAttachmentTypes.IClearRueckantwortAttachmentList {
    return {
        type: ImageAttachmentTypes.EImageAttachmentActions.CLEAR_RUECKANTWORT_ATTACHMENT_LIST,
    }
}

export function removeAttachment(attachment_id: string): ImageAttachmentTypes.IRemoveAttachment {
    return {
        type: ImageAttachmentTypes.EImageAttachmentActions.REMOVE_ATTACHMENT,
        attachment_id: attachment_id
    }
}

export function removeRueckantwortAttachment(attachment_id: string): ImageAttachmentTypes.IRemoveRueckantwortAttachment {
    return {
        type: ImageAttachmentTypes.EImageAttachmentActions.REMOVE_RUECKANTWORT_ATTACHMENT,
        attachment_id: attachment_id
    }
}

export function changeImageLoadingStatus(imageId: string, isLoading: boolean): ImageAttachmentTypes.IChangeKonsilImageLoadingStatus {
    return {
        type: ImageAttachmentTypes.EImageAttachmentActions.CHANGE_KONSIL_IMAGE_LOADING_STATUS,
        image_id: imageId,
        loading: isLoading,
    }
}
export function setAllowedMinimumDimensions(imageSize: ImageSize): ImageAttachmentTypes.ISetAllowedMinimumDimension {
    return {
        type: ImageAttachmentTypes.EImageAttachmentActions.SET_ALLOWED_MINIMUM_DIMENSIONS,
        imageSize: imageSize,
    }
}

// 2. Thunk middleware functions
export function handleRemoveKonsilImage(image_id: string): ThunkAction<Promise<any>, RootState, unknown, Action<string>> {
    return dispatch => {
        return new Promise((resolve) => {
            resolve(dispatch(removeKonsilImage(image_id)));
        })
    }
}

export function loadKonsilImage(imageId: string, konsilId: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const path: string[] = getState().router.location.pathname.split("/");
        const konsilIdStart = path[path.length - 1];
        dispatch(changeImageLoadingStatus(imageId, true));
        dispatch(setKonsilIdOfImageLoading(konsilId, "add"));
        const loadInstanceId: string = uuid.v4();
        //dispatch(loading(loadInstanceId));

        const konsilImageService: KonsilImageService = new KonsilImageService();
        konsilImageService.getImage(imageId).then(
            (img) => {
                const path = getState().router.location.pathname.split("/");
                if (konsilIdStart === path[path.length - 1] && path[path.length - 1] !== "") {
                    dispatch(setKonsilIdOfImageLoading(konsilId, "remove"));
                    dispatch({
                        type: ImageAttachmentTypes.EImageAttachmentActions.LOAD_KONSIL_IMAGE,
                        image_id: imageId,
                        url: img,
                        loading: false,
                    });
                }
            }
        ).catch(
            (response) => {
                dispatch(failure(loadInstanceId, response));
                dispatch(changeImageLoadingStatus(imageId, false));
                dispatch(setKonsilIdOfImageLoading(konsilId, "remove"));
            }
        );
    }
}

export function getImageMetadata(imageId: string): ThunkAction<Promise<any>, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {

        return new Promise((resolve) => {
            const path: string[] = getState().router.location.pathname.split("/");
            const konsilIdStart = path[path.length - 1];
            dispatch(changeImageLoadingStatus(imageId, true));
            const konsilImageService: KonsilImageService = new KonsilImageService();
            konsilImageService.getImageMetadata(imageId)
            .then((konsilImage: KonsilImage) => {
                const path = getState().router.location.pathname.split("/");
                if (konsilIdStart === path[path.length - 1] && path[path.length - 1] !== "") {
                    dispatch({
                        type: ImageAttachmentTypes.EImageAttachmentActions.ADD_KONSIL_IMAGE,
                        image_id: konsilImage.id,
                        image_type: konsilImage.imageType,
                        dateCreated: konsilImage.dateCreated,
                        dateRecorded: konsilImage.dateRecorded,
                        loading: false,
                        dermatoscopeType: konsilImage.dermatoscopeType,
                    });
                }
            })
            .then(() => {
                dispatch(checkLocationStatus(getState().konsile.current_konsil, getState().image_attachment.konsilImages));
            })
            .catch(error => {
                const failureId: string = uuid.v4();
                dispatch(failure(failureId, error));
                dispatch(changeImageLoadingStatus(imageId, false));
            });
            resolve("ok");
        })
    };
}

export function loadRueckantwortImage(id: string): any {
    return dispatch => {
        const loadInstanceId: string = uuid.v4();
        dispatch(loading(loadInstanceId));

        const konsilImageService: KonsilImageService = new KonsilImageService();
        konsilImageService.getRueckantwortImage(id).then(
            (img) => {
                dispatch(loadSuccess(loadInstanceId));
                dispatch({
                    type: ImageAttachmentTypes.EImageAttachmentActions.LOAD_RUECKANTWORT_IMAGE,
                    image_id: id,
                    loading: false,
                    url: img
                });
            }
        ).catch(
            (response) => {
                dispatch(failure(loadInstanceId, response));
            }
        );
    }
}

export function uploadKonsilImage(konsil: Konsil, file: File, imageType: KonsilImageType, loadInstanceId: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const path: string[] = getState().router.location.pathname.split("/");
        const konsilIdStart = path[path.length - 1];
        const imageIdTemp = uuid.v4();
        dispatch(changeImageLoadingStatus(imageIdTemp, true));
        if (konsil.id)
            dispatch(setKonsilIdOfImageLoading(konsil.id, "add"));
        const konsilImageService: KonsilImageService = new KonsilImageService();
        konsilImageService.uploadImageAsBlob(file, konsil.id || "Unknown konsilID", imageType, new Date(), getState().webSocket.clientId)
            .then(konsilImage => {
                if (getState().konsile.isKonsilUpdatedEventShown)
                    dispatch(updateKonsilOnEvent(konsil.id || ""));
                if (konsil.id)
                    dispatch(setKonsilIdOfImageLoading(konsil.id, "remove"));
                dispatch(handleRemoveKonsilImage(imageIdTemp))
                .then(() => {
                    const path = getState().router.location.pathname.split("/");
                    if (konsilImage.id && konsilIdStart === path[path.length - 1] && path[path.length - 1] !== "") {
                        dispatch(getImageMetadata(konsilImage.id))
                        .then(() => {
                            if (konsil.id && konsilImage.id)
                                dispatch(loadKonsilImage(konsilImage.id, konsil.id))
                        })
                    }
                })
            })
            .catch(error => {
                dispatch(failure(loadInstanceId, error));
                dispatch(handleRemoveKonsilImage(imageIdTemp));
                dispatch(changeImageLoadingStatus(imageIdTemp, false));
                if (konsil.id)
                    dispatch(setKonsilIdOfImageLoading(konsil.id, "remove"));
            }
        );
    };
}

export function uploadRueckantwortImage(konsil: Konsil, file: File, imageType: string, id?: string): ThunkAction<any, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const path: string[] = getState().router.location.pathname.split("/");
        const konsilIdStart = path[path.length - 1];
        const loadInstanceId: string = id || uuid.v4();
        dispatch(loading(loadInstanceId)); // maybe remove
        const konsilImageService: KonsilImageService = new KonsilImageService();
        return new Promise((resolve, reject) => {
            konsilImageService.uploadRueckantwortImageAsBlob(file, konsil?.rueckantwort?.id || "Unknown rueckantwortID", imageType, getState().webSocket.clientId)
            .then((image) => {
                dispatch(loadSuccess(loadInstanceId)); // maybe remove
                if (getState().konsile.isKonsilUpdatedEventShown)
                    dispatch(updateKonsilOnEvent(konsil.id || ""));
                dispatch(removeRueckantwortImage(file.name));
                const path = getState().router.location.pathname.split("/");
                if (konsilIdStart === path[path.length - 1] && path[path.length - 1] !== "") {
                    dispatch({
                        type: ImageAttachmentTypes.EImageAttachmentActions.ADD_RUECKANTWORT_IMAGE,
                        image_id: image.id,
                        image_type: image.imageType,
                        loading: false,
                        dateRecorded: image.dateRecorded,
                        filename: file.name,
                    });
                    image.id && dispatch(loadRueckantwortImage(image.id))
                }})
            .catch((response) => {
                    dispatch(failure(loadInstanceId, response));
                    dispatch(removeRueckantwortImage(file.name));
                //reject(response);
            });
        })
    };
}

export function deleteKonsilImage(konsil: Konsil, image_id: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const loadInstanceId: string = uuid.v4();
        //dispatch(loading(loadInstanceId)); // maybe remove
        const konsilImageService: KonsilImageService = new KonsilImageService();
        konsilImageService.delete(image_id)
        .then(() => {
            if (getState().konsile.isKonsilUpdatedEventShown)
                dispatch(updateKonsilOnEvent(konsil.id || ""));
        })
        .catch((response) => dispatch(failure(loadInstanceId, response)))
    }
}

export function deleteRueckantwortImage(konsil: Konsil, image_id: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const loadInstanceId: string = uuid.v4();
        dispatch(loading(loadInstanceId)); // maybe remove
        const konsilImageService: KonsilImageService = new KonsilImageService();
        konsilImageService.deleteRueckantwortImage(image_id, getState().webSocket.clientId)
            .then(() => {
                dispatch(loadSuccess(loadInstanceId));
                dispatch(removeRueckantwortImage(image_id));
                if (getState().konsile.isKonsilUpdatedEventShown)
                    dispatch(updateKonsilOnEvent(konsil.id || ""));
                })
            //.then(() => dispatch(updateKonsil(konsil, loadInstanceId)))
            .catch((response) => dispatch(failure(loadInstanceId, response)))
    }
}

export function changeKonsilImageType(image_id: string | null, imageType: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const loadInstanceId: string = uuid.v4();
        if (getState().konsile.isKonsilUpdatedEventShown)
            dispatch(updateKonsilOnEvent(getState().konsile.current_konsil.id || ""));
        if (image_id === null) {
            const error = new Failure();
            error.error = "Kann Bildtyp nicht ändern, da die Bild-ID null ist!";
            dispatch(failure(loadInstanceId, error));
        }
        else {
            const konsilImageService: KonsilImageService = new KonsilImageService();
            konsilImageService.changeImageType(image_id, imageType, getState().webSocket.clientId).then(() =>
                dispatch({
                    type: ImageAttachmentTypes.EImageAttachmentActions.CHANGE_KONSIL_IMAGE_TYPE,
                    imageType: imageType,
                    image_id: image_id
                })
            ).catch((response) => dispatch(failure(loadInstanceId, response)));
        }
    }
}

export function changeRueckantwortImageType(image_id: string | null, imageType: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const loadInstanceId: string = uuid.v4();
        if (getState().konsile.isKonsilUpdatedEventShown)
            dispatch(updateKonsilOnEvent(getState().konsile.current_konsil.id || ""));
        if (image_id === null) {
            const failureObj = new Failure();
            failureObj.error = "Kann Bildtyp nicht ändern, da die Bild-ID null ist!";
            dispatch(failure(loadInstanceId, failureObj));
        }
        else {
            const konsilImageService: KonsilImageService = new KonsilImageService();
            konsilImageService.changeRueckantwortImageType(image_id, imageType, getState().webSocket.clientId).then(() =>
                dispatch({
                    type: ImageAttachmentTypes.EImageAttachmentActions.CHANGE_RUECKANTWORT_IMAGE_TYPE,
                    imageType: imageType,
                    image_id: image_id
                })
            ).catch((response) => dispatch(failure(loadInstanceId, response)));
        }
    }
}

export function uploadAttachment(konsil_id: string, file: File, loadInstanceId: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        dispatch(loading(loadInstanceId)); // maybe remove
        dispatch({
            type: ImageAttachmentTypes.EImageAttachmentActions.ADD_ATTACHMENT,
            attachment_id: file.name,
            filename: file.name,
            originalFilename: file.name,
            loading: true,
            date: null
        });

        const attachmentService: AttachmentService = new AttachmentService();
        attachmentService.uploadAttachmentAsBlob(file, konsil_id, getState().webSocket.clientId)
        .then(
            (attachment) => {
                dispatch(loadSuccess(loadInstanceId)); // maybe remove
                if (getState().konsile.isKonsilUpdatedEventShown)
                    dispatch(updateKonsilOnEvent(konsil_id));
                dispatch({
                    type: ImageAttachmentTypes.EImageAttachmentActions.ADD_ATTACHMENT,
                    attachment_id: attachment.id,
                    filename: attachment.filename,
                    originalFilename: attachment.originalFilename,
                    loading: false,
                    date: attachment.dateCreated,
                });
                dispatch(removeAttachment(file.name));
            }
        ).catch(
            (response) => {
                dispatch(failure(loadInstanceId, response));
                dispatch(removeAttachment(file.name));
            }
        );
    }
}

export function downloadAttachment(attachment_id: string): ThunkAction<Promise<any>, RootState, unknown, Action<string>> {
    return dispatch => {
        const loadInstanceId: string = uuid.v4();
        dispatch(loading(loadInstanceId));

        const attachmentService: AttachmentService = new AttachmentService();
        return new Promise((resolve, reject) => {
            attachmentService.getAttachment(attachment_id).then(
                (attachment) => {
                    dispatch(loadSuccess(loadInstanceId));
                    dispatch({
                        type: ImageAttachmentTypes.EImageAttachmentActions.LOAD_ATTACHMENT,
                        attachment_id: attachment_id,
                        data: attachment,
                        loading: false,
                    });
                    resolve(attachment)
                }
            ).catch(
                (response) => {
                    dispatch(failure(loadInstanceId, response));
                    //reject(response)
                    //dispatch(removeAttachment(file.name));
                }
            );
        })
    }
}

export function uploadRueckantwortAttachment(rueckantwort_id: string, file: File, id?: string): ThunkAction<any, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const loadInstanceId: string = id || uuid.v4();
        dispatch(loading(loadInstanceId)); // maybe remove

        dispatch({
            type: ImageAttachmentTypes.EImageAttachmentActions.ADD_RUECKANTWORT_ATTACHMENT,
            attachment_id: file.name,
            filename: file.name,
            originalFilename: file.name,
            loading: true,
            date: null
        });

        const attachmentService: AttachmentService = new AttachmentService();
        return attachmentService.uploadRueckantwortAttachmentAsBlob(file, rueckantwort_id, getState().webSocket.clientId)
        .then((attachment) => {
            if (getState().konsile.isKonsilUpdatedEventShown)
                dispatch(updateKonsilOnEvent(getState().konsile.current_konsil.id || ""));
            dispatch(loadSuccess(loadInstanceId)); // maybe remove
            dispatch({
                type: ImageAttachmentTypes.EImageAttachmentActions.ADD_RUECKANTWORT_ATTACHMENT,
                attachment_id: attachment.id,
                filename: attachment.filename,
                originalFilename: attachment.originalFilename,
                loading: false,
                date: attachment.dateCreated,
            });
            dispatch(removeRueckantwortAttachment(file.name));
        })
        .catch(
            (response) => {
                dispatch(failure(loadInstanceId, response));
                dispatch(removeRueckantwortAttachment(file.name));
            }
        );
    }
}

export function downloadRueckantwortAttachment(attachment_id: string): ThunkAction<Promise<any>, RootState, unknown, Action<string>> {
    return dispatch => {
        const loadInstanceId: string = uuid.v4();
        dispatch(loading(loadInstanceId));

        const attachmentService: AttachmentService = new AttachmentService();
        return new Promise((resolve, reject) => {
            attachmentService.getAttachment(attachment_id).then(
                (attachment) => {
                    dispatch(loadSuccess(loadInstanceId));
                    dispatch({
                        type: ImageAttachmentTypes.EImageAttachmentActions.LOAD_RUECKANTWORT_ATTACHMENT,
                        attachment_id: attachment_id,
                        data: attachment,
                        loading: false,
                    });
                    resolve(attachment)
                }
            ).catch(
                (response) => {
                    dispatch(failure(loadInstanceId, response));
                    //reject(response)
                    //dispatch(removeAttachment(file.name));
                }
            );
        })
    }
}

export function deleteAttachment(attachment_id: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const loadInstanceId: string = uuid.v4();
        dispatch(loading(loadInstanceId)); // maybe remove
        const attachmentService: AttachmentService = new AttachmentService();
        attachmentService.delete(attachment_id, getState().webSocket.clientId)
            .then(() => {
                dispatch(loadSuccess(loadInstanceId));
                if (getState().konsile.isKonsilUpdatedEventShown)
                    dispatch(updateKonsilOnEvent(getState().konsile.current_konsil.id || ""));
                dispatch(removeAttachment(attachment_id));
            })
            .catch((response) => dispatch(failure(loadInstanceId, response)))
    }
}

export function deleteRueckantwortAttachment(attachment_id: string): ThunkAction<any, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const loadInstanceId: string = uuid.v4();
        dispatch(loading(loadInstanceId)); // maybe remove
        const attachmentService: AttachmentService = new AttachmentService();
        return attachmentService.deleteRueckantwortAttachment(attachment_id, getState().webSocket.clientId)
            .then(() => {
                dispatch(loadSuccess(loadInstanceId));
                if (getState().konsile.isKonsilUpdatedEventShown)
                    dispatch(updateKonsilOnEvent(getState().konsile.current_konsil.id || ""));
                dispatch(removeRueckantwortAttachment(attachment_id))
            })
            .catch((response) => dispatch(failure(loadInstanceId, response)))
    }
}

export function updateLoadedKonsil(konsil: Konsil, loadInstanceId: string): ThunkAction<any, RootState, unknown, Action<string>> {
    return ((dispatch, getState) => {
        if (getState().konsile.isKonsilUpdatedEventShown || getState().konsile.isKonsilUpdateConflict) {
            dispatch(setIsKonsilUpdatedEventShown(false));
            dispatch(setIsKonsilUpdateEventSuccess(true));
            setTimeout(() => dispatch(setIsKonsilUpdateEventSuccess(false)), 4000);
            setTimeout(() => dispatch(setIsKonsilUpdateConflict(false)), 15000)
            dispatch(clearAttachmentList());
            dispatch(clearRueckantwortImageList());
            dispatch(clearRueckantwortAttachmentList());
            if (konsil.konsilImages)
                konsil.konsilImages.forEach((konsilImage: KonsilImage) =>
                    {if (konsilImage.id) {
                        dispatch({
                            type: ImageAttachmentTypes.EImageAttachmentActions.CHANGE_KONSIL_IMAGE_TYPE,
                            imageType: konsilImage.imageType,
                            image_id: konsilImage.id
                        })
                    }});
        }

        dispatch(loadSuccess(loadInstanceId));
        if (konsil.konsilImages)
            konsil.konsilImages.forEach((konsilImage: KonsilImage) =>
                {if (konsilImage.id) {
                    // dispatch({
                    //     type: ImageAttachmentTypes.EImageAttachmentActions.CHANGE_KONSIL_IMAGE_TYPE,
                    //     imageType: konsilImage.imageType,
                    //     image_id: konsilImage.id
                    // })
                    const urlIsSet = (getState().image_attachment.konsilImages[konsilImage.id])? getState().image_attachment.konsilImages[konsilImage.id].url: false
                    if (!(getState().image_attachment.konsilImages[konsilImage.id] || urlIsSet) && konsil.id) {
                        dispatch(getImageMetadata(konsilImage.id));
                        dispatch(loadKonsilImage(konsilImage.id, konsil.id));
                    }
                }});

        if (konsil.attachments)
            konsil.attachments.forEach((attachment: Attachment) => {
                dispatch({
                    type: ImageAttachmentTypes.EImageAttachmentActions.ADD_ATTACHMENT,
                    attachment_id: attachment.id,
                    filename: attachment.filename,
                    originalFilename: attachment.originalFilename,
                    loading: false,
                    date: attachment.dateCreated,
                });
            });

        if (konsil.rueckantwort?.rueckantwortImages)
            konsil.rueckantwort?.rueckantwortImages.forEach((image: KonsilImage) =>
                {if (image.id) {
                    const urlIsSet = (getState().image_attachment.rueckantwortImages[image.id])? getState().image_attachment.rueckantwortImages[image.id].url: false
                    if (! (getState().image_attachment.rueckantwortImages[image.id] || urlIsSet)) dispatch(loadRueckantwortImage(image.id))
                    dispatch({
                        type: ImageAttachmentTypes.EImageAttachmentActions.ADD_RUECKANTWORT_IMAGE,
                        image_id: image.id,
                        filename: image.filename,
                        image_type: image.imageType,
                        date: image.dateCreated,
                        //originalFilename: image.filename
                    })
                }});

        if (konsil.rueckantwort?.attachments)
            konsil.rueckantwort?.attachments.forEach((attachment: Attachment) => {
                dispatch({
                    type: ImageAttachmentTypes.EImageAttachmentActions.ADD_RUECKANTWORT_ATTACHMENT,
                    attachment_id: attachment.id,
                    filename: attachment.filename,
                    originalFilename: attachment.originalFilename,
                    loading: false,
                    date: attachment.dateCreated,
                });
            });

        if (konsil.konsiliarbericht) {
            dispatch(checkKonsiliarberichtStatus(konsil));
            dispatch(checkDermaFeedbackStatus(konsil));
        }
        if (konsil.konsilabschluss) {
            dispatch(checkKonsilFeedbackStatus(konsil.konsilabschluss));
        }
        dispatch(setKonsil(konsil ? konsil : new Konsil()));
    })

}

export const getMinimumDimensions = (): ThunkAction<any, RootState, unknown, Action<string>> => {
    return (dispatch, getState) => {
    const configService = new ConfigurationService()
    configService.getImageSize()
        .then((imageSize) => {
            dispatch(setAllowedMinimumDimensions(imageSize))
        }
        , (reason) => {
            dispatch(failure(uuid.v4(), new Failure(reason)))
        })
}}



