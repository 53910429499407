import React from "react";

import { Typography, Divider } from "@material-ui/core";
import { Arzt, Geschlecht } from "telescan-core";
import { ViewText } from "../konsil";


interface IArztProps {
    basisdatenArzt: Arzt;
}

export default class ViewArztDatenComponent extends React.Component<IArztProps> {

    render() {
        return (
            <form className="form-content label-content-grid" noValidate autoComplete="off" style={{marginBottom: "8px"}}>
                <ViewText
                    labelVariant='caption'
                    label='BSNR'
                    text={this.props.basisdatenArzt.bsnr || "-"}
                />
                <ViewText
                    labelVariant='caption'
                    label='LANR'
                    text={this.props.basisdatenArzt.lanr || "-"}
                />
                <Divider />
                <ViewText
                    labelVariant='caption'
                    label='Geschlecht'
                    text={(this.props.basisdatenArzt.geschlecht)? Geschlecht[this.props.basisdatenArzt.geschlecht] : "-"}
                />
                <ViewText
                    labelVariant='caption'
                    label='Titel'
                    text={this.props.basisdatenArzt.titel || "-"}
                />
                <ViewText
                    labelVariant='caption'
                    label='Vorname'
                    text={this.props.basisdatenArzt.vorname || "-"}
                />
                <ViewText
                    labelVariant='caption'
                    label='Nachname'
                    text={this.props.basisdatenArzt.nachname || "-"}
                />
                <Divider />
                <ViewText
                    labelVariant='caption'
                    label='HAEVG-Mitgliedsnummer'
                    text={this.props.basisdatenArzt.haevgMitgliedsNummer || "-"}
                />
                <ViewText
                    labelVariant='caption'
                    label='Telefon'
                    text={this.props.basisdatenArzt.telefon || "-"}
                />
                <ViewText
                    labelVariant='caption'
                    label='Fax'
                    text={this.props.basisdatenArzt.fax || "-"}
                />
                <ViewText
                    labelVariant='caption'
                    label='Mobil'
                    text={this.props.basisdatenArzt.telefonMobil || "-"}
                />
                <ViewText
                    labelVariant='caption'
                    label='Zustimmung zum automatisierten Abruf von Dokumenten erteilt'
                    text={(this.props.basisdatenArzt.abrufArzt)? "JA" : "NEIN"}
                />
                <Divider />
                <Typography variant="subtitle1">
                    Adresse, wenn von Betriebsstättenadresse abweichend.
                </Typography>
                <ViewText
                    labelVariant='caption'
                    label='Straße / Hausnummer'
                    text={this.props.basisdatenArzt.strasseHausnummer || "-"}
                />
                <ViewText
                    labelVariant='caption'
                    label='PLZ'
                    text={this.props.basisdatenArzt.plz || "-"}
                />
                <ViewText
                    labelVariant='caption'
                    label='Stadt'
                    text={this.props.basisdatenArzt.ort || "-"}
                />
            </form>
        )
    }
}