import React from 'react';
import {Collapse, IconButton, Menu, MenuItem, Typography} from '@material-ui/core';
import {ArrowDropDown, ExpandLess, ExpandMore} from '@material-ui/icons';

interface IState {
    anchorEl: any
    menuOpen: boolean
    maxHeight: number;
    open: boolean;
    openSubmenuIndex: number;
}

interface IProps {
    options: any;
    maxHeight?: number;
    onChange: (result: string) => void;
}

export class LongMenu extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            anchorEl: null,
            menuOpen: false,
            maxHeight: this.props.maxHeight || 48,
            open: false,
            openSubmenuIndex: -1
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    // let [anchorEl, setAnchorEl] = React.useState(null);
    // let open = Boolean(anchorEl);

    handleClick = (event: any) => {
        this.setState({
            anchorEl: event.currentTarget,
            menuOpen: true
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
            menuOpen: false,
            openSubmenuIndex: -1
        });
    };

    handleClickExpand = (index) => {
        if (index === this.state.openSubmenuIndex) {
            this.setState({openSubmenuIndex: -1});
        } else {
            this.setState({openSubmenuIndex: index});
        }
    };

    render() {
        return (
            <div>
                <IconButton
                    aria-label="bodypart"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    className="body-menu"
                    size="small"
                    onClick={this.handleClick}>
                    <ArrowDropDown/>
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={this.state.menuOpen}
                    onClose={this.handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: this.state.maxHeight * 6,
                        },
                    }}>

                    {Object.keys(this.props.options).map((option, index) => (
                        <div key={index}>
                            <Typography id={'expand_' + index} onClick={this.handleClickExpand.bind(this, index)}
                                        className="MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button menu-expand-header">
                                {option} {this.state.openSubmenuIndex === index ? <ExpandLess/> : <ExpandMore/>}
                            </Typography>
                            <Collapse className="long-menu-item-container" in={this.state.openSubmenuIndex === index} timeout="auto" unmountOnExit>
                                {Object.keys(this.props.options[option]).map((bodyOption) => (
                                    <MenuItem className="long-menu-item" key={bodyOption} selected={bodyOption === ''}
                                              onClick={() => {
                                                  this.props.onChange(bodyOption);
                                                  this.setState({menuOpen: false});
                                              }}>
                                        {this.props.options[option][bodyOption]}
                                    </MenuItem>
                                ))}
                            </Collapse>
                        </div>
                    ))}
                </Menu>
            </div>
        );

        /*
        if (this.state.selection.has(bodyOption))
            this.setState({selection: _.filter(this.state.selection, bodyOption)});
        else
            this.setState({selection: this.state.selection.set(bodyOption, this.props.options[option][bodyOption])});
        */
    }
}
