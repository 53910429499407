export enum ETiConnectorActions {
    SET_IS_KIM_MAIL_IN_LDAP = "SET_IS_KIM_MAIL_IN_LDAP",
    SET_IS_LDAP_REQUEST = "SET_IS_LDAP_REQUEST"
}

export interface ISetIsKimMailInLdap {
    readonly type: ETiConnectorActions.SET_IS_KIM_MAIL_IN_LDAP;
    readonly isKimMailInLdap: boolean; 
}

export interface ISetIsLdapRequest {
    readonly type: ETiConnectorActions.SET_IS_LDAP_REQUEST;
    readonly isLdapRequest: boolean; 
}

export type TTiConnectorActionTypes = 
    ISetIsKimMailInLdap |
    ISetIsLdapRequest;