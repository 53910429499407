import {applyMiddleware, createStore, Middleware} from 'redux';
import ThunkMiddleware from 'redux-thunk';
import {routerMiddleware} from 'connected-react-router';
import {history, rootReducer, resetEnhancer} from './reducers';
import { composeWithDevToolsDevelopmentOnly } from "@redux-devtools/extension";
//import { compose } from 'redux';
/**
 * Logs all actions and states after they are dispatched.
 */

export const logger: Middleware = cur_store => next => action => {
    /*if (process.env.NODE_ENV !== "production") {
        console.group(action.type)
        console.info('dispatching', action)
    }*/
    let result = next(action)
    /*if (process.env.NODE_ENV !== "production") {
        console.log('next state', cur_store.getState())
        console.groupEnd()
    }*/
    return result;
}
/**
 * Sends crash reports as state is updated and listeners are notified.
 */
export const crashReporter: Middleware = cur_store => next => action => {
    try {
        return next(action)
    } catch (err) {
        console.error('Caught an exception!')
        console.error('state:', cur_store.getState());
        console.error('action', action);
        console.error('error', err);
        throw err;
    }
}

export const store = createStore(resetEnhancer(rootReducer), composeWithDevToolsDevelopmentOnly(applyMiddleware(logger, crashReporter, ThunkMiddleware, routerMiddleware(history))));

