import React from "react";
import {connect, ConnectedProps} from "react-redux";
import { Box, Button, Typography } from "@material-ui/core";
import Ellipse from '../../static/images/login-ellipse.svg';
import Triangle from '../../static/images/login_triangle.svg';
import { RootState } from "../../redux/reducers";
import { navigate } from "../../redux/actions";


interface IProps {
}

interface IState {
}

const mapStateToProps = (state: RootState) => ({
})

const mapDispatchToProps = {
    dispatch_navigate: (url: string) => navigate(url),
}
type TPropsFromRedux = ConnectedProps<typeof connector>

const connector = connect(mapStateToProps, mapDispatchToProps)

export class Error500 extends React.Component<IProps & TPropsFromRedux, IState> {

    render() {
        return (
            <Box className="error-screen-wrapper">
                <Box className="error-content">
                    <Typography className="error-reload-title" variant="h1" component="h2" gutterBottom>500 <Typography variant="h4" component="h2" gutterBottom>Error</Typography></Typography>
                    <Typography className="error-reload-text" variant="subtitle1" gutterBottom>Hoppla, da ist was schief gegangen!</Typography>
                    <Button
                        className="error-reload-btn"
                        variant="contained"
                        color="primary"
                        onClick = {() => this.props.dispatch_navigate("/login")}
                    >
                        Neu laden
                    </Button>
                </Box> 
                <img src={Ellipse} className="ellipse" alt="TeleScan Login Logo"/>
                <img src={Triangle} className="triangle" alt="TeleScan Login Logo"/>
            </Box>
        )
    }
}

const Error500Container = connector(Error500);
export default Error500Container;