import React from 'react';
import uuid from 'uuid';
import {Box, Typography} from '@material-ui/core';
import {connect, ConnectedProps} from "react-redux";
import {RootState} from "../../redux/reducers";
import {clearAttachmentList, clearKonsilImageList, clearRueckantwortImageList, downloadKonsil, navigate, removeSendFailure} from "../../redux/actions";
import { setIsNormalNotification } from '../../redux/actions/notification_actions';
import { Alert, UploadPath } from 'telescan-core';

interface IProps {
    alerts: Alert[];
    handleClose: () => void;
}


const mapStateToProps = (state: RootState, ownProps: IProps) => ({
    isDesktop: state.general.isDesktop,
    role: state.user.role,
    currentKonsil: state.konsile.current_konsil,
    ...ownProps
})

const mapDispatchToProps = {
    dispatch_navigate: (url: string) => navigate(url),
    dispatch_downloadKonsil: (dokumentenId: string, uploadPath: keyof typeof UploadPath) => downloadKonsil(dokumentenId, uploadPath),
    dispatch_removeSendFailure: (dokumentenId: string) => removeSendFailure(dokumentenId),
    dispatch_clearKonsilImageList: () => clearKonsilImageList(),
    dispatch_clearRueckantwortImageList: () => clearRueckantwortImageList(),
    dispatch_clearAttachmentList: () => clearAttachmentList(),
    dispatch_setIsNormalNotification: (isNormalNotification: boolean) => setIsNormalNotification(isNormalNotification),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>


export class BellPopup extends React.Component<TPropsFromRedux> {

    handleClick = (alert: Alert) => {
        if (alert.type === "FAILURE") {
            this.props.dispatch_removeSendFailure(alert.content.konsilID);
            this.props.dispatch_navigate("/konsil/" + alert.content.konsilID);
            if (this.props.currentKonsil.id !== alert.content.konsilID) {
                this.props.dispatch_clearKonsilImageList();
                this.props.dispatch_clearRueckantwortImageList();
                this.props.dispatch_clearAttachmentList();
            }
        } else if (this.props.role === "DERMATOLOGE" && alert.type === "KONSIL") {
            this.props.dispatch_downloadKonsil(alert.content.dokumentIdentifikator, alert.content.uploadPath)
        } else if (alert.type === "CERTIFICATE") {
            this.props.dispatch_navigate("/settings");
        } else if (alert.type === "NOTIFICATION") {
            this.props.dispatch_setIsNormalNotification(true);
            this.props.handleClose();
        }
    }

    render() {
        return (
            <Box className="bell-popup">
                {this.props.alerts.length === 0 ?
                    <Box className="no-content-item bell-popup-container">
                        <Typography variant="body1" color="inherit">- keine Nachrichten vorhanden -</Typography>
                    </Box>
                    :
                    this.props.alerts.map((alert: Alert) =>
                        <Box className="alert-item bell-popup-container"
                            key={uuid.v4()}
                            onClick={() => this.handleClick(alert)}
                        >
                            <Typography variant="h2" color="inherit">{alert.title}</Typography>
                            <Typography variant="body1" color="inherit">{alert.displayContent}</Typography>
                        </Box>
                    )
                }
            </Box>
        );
    }
}

export const BellPopupContainer = connector(BellPopup);
export default BellPopupContainer;
