import React from 'react';
import {ViewText} from './view_text';
import {Box, Divider, Typography} from '@material-ui/core'
import {RootState} from "../../../redux/reducers";
import {connect, ConnectedProps} from "react-redux";
import ViewKonsiliarBerichtContentContainer from './view_berichtcontent';
import ViewKonsiliarberichtFragenContainer from './view_konsiliarbericht_fragen';
import ViewKonsiliarberichtFeedbackContainer from './view_konsiliarbericht_feedback';
import {EditKonsiliarBerichtContentContainer} from '../dermatologeComponents';
import {Failure, KonsilStatusType, RoleTypes} from 'telescan-core';
import {withPdfPreviewCreation, WithPdfPreviewCreationProps} from '../../../utils/with_pdf_preview';
import { failure } from '../../../redux/actions';

interface IState {
    isReplyPopupOpen: boolean;
}

interface IProps {
    hasSideView: boolean;
}

const mapStateToProps = (state: RootState, ownProps: IProps) => ({
    currentKonsil: state.konsile.current_konsil,
    isDesktop: state.general.isDesktop,
    role: state.user.role,
    ...ownProps
})

const mapDispatchToProps = {
    dispatch_failure: (id: string, failureObj: Failure) => failure(id, failureObj),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>


export class ViewKonsiliarbericht extends React.Component<TPropsFromRedux & WithPdfPreviewCreationProps, IState> {
    constructor(props: TPropsFromRedux & WithPdfPreviewCreationProps) {
        super(props);
        this.state = {
            isReplyPopupOpen: false,
        };
        this.renderSwitchKonsiliarbericht = this.renderSwitchKonsiliarbericht.bind(this);
        this.toggleReplyPopup = this.toggleReplyPopup.bind(this);
    }
   
    toggleReplyPopup() {
        this.setState(prevState => {
            return {
                isReplyPopupOpen: !prevState.isReplyPopupOpen,
            }
        })
    }

    renderSwitchKonsiliarbericht(konsilStatus: keyof typeof KonsilStatusType, role: keyof typeof RoleTypes) {
        //TODO: Fall abfangen, wo Ärzte und Dermatologen in gleicher BS sind
        if (konsilStatus === "BEFUNDET" || konsilStatus === "ABGESCHLOSSEN" || konsilStatus === "ABGEBROCHEN")
            return (
                <ViewKonsiliarBerichtContentContainer/>
            )
        else if ((role === "HAUSARZT" || role === "BETRIEBSSTAETTE_HAUSARZT") && konsilStatus === "RUECKFRAGE")
            return (
                <Typography className="no-answer pad-bottom" variant="body1" color="inherit">
                    Ihr Konsilauftrag ist in Arbeit. Der Konsiliar hat hierzu eine Rückfrage gestellt.
                    Bitte antworten Sie, um eine weitere Bearbeitung zu ermöglichen.
                </Typography>
            )
        else if (role === "HAUSARZT" || role === "BETRIEBSSTAETTE_HAUSARZT")
            return (
                <Typography className="no-answer pad-bottom" variant="body1" color="inherit">
                    Noch keine Auswertung erhalten
                </Typography>
            )
        else // if (role === "DERMATOLOGE")
            return (
                <EditKonsiliarBerichtContentContainer/>
            )
    }

    render() {
        return (
            <Box className="konsiliarbericht-data-container">
                <Box
                    className={(this.props.hasSideView || !this.props.isDesktop) ? "scroll-box scroll-box-bg" : "scroll-box"}>
                    {(this.props.hasSideView || !this.props.isDesktop) &&
                    <Typography className="konsil-header" variant="subtitle1" color="inherit">
                        Konsiliarbericht
                    </Typography>
                    }
                    {(((this.props.hasSideView || !this.props.isDesktop) && (this.props.role === "DERMATOLOGE" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE")) ||
                        ((this.props.hasSideView || !this.props.isDesktop) && this.props.currentKonsil?.konsilStatus !== "RUECKFRAGE")) &&
                    <ViewKonsiliarberichtFragenContainer
                        isReplyPopupOpen = {this.state.isReplyPopupOpen}
                        toggleReplyPopup = {this.toggleReplyPopup}
                        createPdfPreview = {this.props.createPdfPreview}
                        resetPdfPreview = {this.props.resetPdfPreviewData}
                        pdfPreviewData = {this.props.pdfPreviewData}
                    />
                    }
                    <Box className="data-content">
                        {(this.props.role === "HAUSARZT" || this.props.role === "BETRIEBSSTAETTE_HAUSARZT") &&
                        <Box className="konsiliar">
                            <ViewText
                                labelVariant='subtitle1'
                                label='Konsiliar'
                                text={[this.props.currentKonsil?.konsiliar?.titel, this.props.currentKonsil?.konsiliar?.vorname, this.props.currentKonsil?.konsiliar?.nachname].join(" ")}
                            />
                        </Box>
                        }
                        {(this.props.role === "DERMATOLOGE" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE") &&
                        <Box className="konsiliar">
                            <ViewText
                                labelVariant='subtitle1'
                                label='Beauftragender Arzt'
                                text={[this.props.currentKonsil?.beauftragender_arzt?.titel, this.props.currentKonsil?.beauftragender_arzt?.vorname, this.props.currentKonsil?.beauftragender_arzt?.nachname].join(" ")}
                            />
                        </Box>
                        }
                        <Divider/>
                        <Box className="konsil-response-container active">
                            {this.renderSwitchKonsiliarbericht(this.props.currentKonsil.konsilStatus, this.props.role)}
                        </Box>
                    </Box>
                    {((this.props.hasSideView || !this.props.isDesktop) && this.props.currentKonsil?.konsilStatus === "RUECKFRAGE" && (this.props.role === "HAUSARZT" || this.props.role === "BETRIEBSSTAETTE_HAUSARZT")) &&
                        <ViewKonsiliarberichtFragenContainer
                            isReplyPopupOpen = {this.state.isReplyPopupOpen}
                            toggleReplyPopup = {this.toggleReplyPopup}
                            createPdfPreview = {this.props.createPdfPreview}
                            resetPdfPreview = {this.props.resetPdfPreviewData}
                            pdfPreviewData = {this.props.pdfPreviewData}
                        />
                    }
                    {(this.props.hasSideView || !this.props.isDesktop) &&
                    <ViewKonsiliarberichtFeedbackContainer/>
                    }
                </Box>
                {(!this.props.hasSideView && this.props.isDesktop) &&
                <Box className="scroll-box-side konsil-side">
                    <ViewKonsiliarberichtFragenContainer
                        isReplyPopupOpen = {this.state.isReplyPopupOpen}
                        toggleReplyPopup = {this.toggleReplyPopup}
                        createPdfPreview = {this.props.createPdfPreview}
                        resetPdfPreview = {this.props.resetPdfPreviewData}
                        pdfPreviewData = {this.props.pdfPreviewData}
                    />
                    <ViewKonsiliarberichtFeedbackContainer/>
                </Box>
                }

            </Box>
        );
    }
}

export const ViewKonsiliarberichtContainer = connector(withPdfPreviewCreation(ViewKonsiliarbericht));
export default ViewKonsiliarberichtContainer;
