import React from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { createBill } from '../../../redux/actions';
import { RootState } from '../../../redux/reducers';
import AddNewBillRender from './add_new_bill_render';

const mapStateToProps = (state: RootState) => ({
    user: state.user.user,
    doctors: state.user.betriebsstaettenAerzte
})

const mapDispatchToProps = {
    dispatch_createBill: (bsnr: string, lanr: string, year: number, quartal: number) => createBill(bsnr, lanr, year, quartal),
}

type TPropsFromRedux = ConnectedProps<typeof connector>
const connector = connect(mapStateToProps, mapDispatchToProps)

interface IProps {
    isAddNewBillOpen: boolean;
    closeAddNewBillModal: () => void;
}

interface IState {
    bsnrToSend: string;
    lanrToSend: string;
    currentDate: Date;
    currentYear: number;
    currentQuartal: number;
    toSendYear: number;
    toSendQuartal: number;
    lanrList: string[];
}
type Props = TPropsFromRedux & IProps

export class AddNewBill extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props)
        this.state = {
            bsnrToSend: this.props.user[0]?.bsnr || "",
            lanrToSend: this.props.user[0]?.lanr || "",
            currentDate: new Date(),
            currentYear: 0,
            currentQuartal: 0,
            toSendQuartal: 0,
            toSendYear: 0,
            lanrList: [],
        }
    }

    componentDidMount() {
        this.setState({
            currentQuartal: this.getQuartalFromDate(new Date()),
            currentYear: this.getYearFromDate(new Date()),
            toSendQuartal: this.getQuartalFromDate(new Date()),
            toSendYear: this.getYearFromDate(new Date()),
        });
        this.props.doctors.map(item => {
            return this.setState(prevState =>
                {
                    const lanrList: string[] = prevState.lanrList;
                    item.lanr && lanrList.push(item.lanr);
                    return {lanrList: lanrList}
                },
                () => this.setState({lanrToSend: this.state.lanrList[0]})
            )
        });

    }

    getQuartalFromDate = (inputDate: Date) => {
        const date: Date = inputDate;
        const quartal = Math.ceil((date.getMonth()+1)/3);
        return quartal;
    }

    getYearFromDate = (inputDate: Date) => {
        const date: Date = inputDate;
        const year = date.getFullYear();
        return year;
    }

    validateLanr = () => {
        const re = /^[0-9]+$/;

        // LANR may be null or undefined if the form field is cleared.
        if (!this.state.lanrToSend) {
            return false;
        }

        if (this.state.lanrToSend.length !== 9 || !re.test(this.state.lanrToSend)) {
            return false;
        }
        return true
    }

    validateBsnr = () => {
        const re = /^[0-9]+$/;
        if (this.state.bsnrToSend.length !== 9 || !re.test(this.state.bsnrToSend))
            return false;
        else return true
    }

    validateQuartal = () => {
        if (isNaN(this.state.toSendQuartal))
            return false;
        else if (this.state.currentYear===this.state.toSendYear) {
            if (this.state.toSendQuartal>this.state.currentQuartal)
                return false;
            else return true;
        }
        else if (this.state.currentYear - this.state.toSendYear === 1) {
            if (this.state.currentQuartal-this.state.toSendQuartal < 2)
                return true;
            else return false;
        }
        else if (this.state.currentYear - this.state.toSendYear === 2) {
            if (this.state.toSendQuartal === 4)
                return true;
            else return false;
        }
        else return false;
    }

    validateYear = () => {
        if (this.state.toSendYear < 2021 || this.state.toSendYear > this.state.currentYear || isNaN(this.state.toSendYear) || (this.state.currentYear - this.state.toSendYear) > 2)
            return false;
        else
            return true;
    }

    onBsnrChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({bsnrToSend: event.target.value});
    }

    onLanrChange = (lanr: string) => {
        this.setState({lanrToSend: lanr});
    }

    onYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({toSendYear: parseInt(event.target.value)});
    }

    onQuartalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({toSendQuartal: parseInt(event.target.value)});
    }

    render() {
        return (
            <AddNewBillRender
                isAddNewBillOpen = {this.props.isAddNewBillOpen}
                bsnrToSend = {this.state.bsnrToSend}
                lanrToSend = {this.state.lanrToSend}
                toSendQuartal = {this.state.toSendQuartal}
                toSendYear = {this.state.toSendYear}
                onBsnrChange = {this.onBsnrChange}
                onLanrChange = {this.onLanrChange}
                onYearChange = {this.onYearChange}
                onQuartalChange = {this.onQuartalChange}
                validateBsnr = {this.validateBsnr}
                validateLanr = {this.validateLanr}
                validateYear = {this.validateYear}
                validateQuartal = {this.validateQuartal}
                closeAddNewBillModal = {this.props.closeAddNewBillModal}
                dispatch_createBill = {this.props.dispatch_createBill}
                lanrList = {this.state.lanrList}
            />
        )
    }
}

const AddNewBillContainer = connector(AddNewBill);
export default AddNewBillContainer;
