import React from "react";
import {connect, ConnectedProps} from 'react-redux';

import { Box, Typography, Paper, Button, Tooltip, CircularProgress, Checkbox } from "@material-ui/core";
import { RootState } from "../../redux/reducers";
import { failure, getInitialisationStatus, getIsHpmAvailableStatus, setHpmApplied, setHpmToInitialise, setIsHpmConfigChanged } from "../../redux/actions";
import HPMInstallationComponent from "./hpm_installation";
import { WithHpmInstallMethodsProps, withHpmInstallMethods } from "../../utils";
import { updateModul } from "../../redux/actions/update_actions";
import HPMKonfigurationComponent from "./hpm_konfiguration";
import { Edit } from "@material-ui/icons";
import { HPMKonfiguration, Failure, UploadPath} from "telescan-core";


interface IState {
    isEditHpmConfig: boolean;
    hpmConfig: HPMKonfiguration;
    hpmToInitialise: boolean;
}

const mapStateToProps = (state: RootState) => ({
    initialisationStatus: state.user.initialisationStatus,
    hpmUpdateSteps: state.update.hpmUpdateSteps,
    isHpmConfigChanged: state.general.isHpmConfigChanged,
    initialisedServices: state.general.initialisedServices,
    hpmToInitialise: state.general.hpmToInitialise,
    isKimEnabled: state.general.isKimEnabled,
    role: state.user.role,
})

const mapDispatchToProps = {
    dispatch_updateModul: (modulName: string) => updateModul(modulName),
    dispatch_getInitialisationStatus: () => getInitialisationStatus(),
    dispatch_failure: (id: string, error: Failure) => failure(id, error),
    dispatch_getIsHpmAvailableStatus: () => getIsHpmAvailableStatus(),
    setIsHpmConfigChanged,
    dispatch_setHpmApplied: (isApplied: boolean) => setHpmApplied(isApplied),
    dispatch_setHpmToInitialise: (hpmToInitialise: boolean) => setHpmToInitialise(hpmToInitialise),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>
type Props = TPropsFromRedux & WithHpmInstallMethodsProps

export class Update extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isEditHpmConfig: false,
            hpmConfig: new HPMKonfiguration(),
            hpmToInitialise: false,
        };

        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleDoneClick = this.handleDoneClick.bind(this);
    }

    componentDidMount() {
        if (this.props.isKimEnabled) {
            if (this.props.role === "DERMATOLOGE" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE") {
                this.props.dispatch_setHpmToInitialise(true);
                this.setState({hpmToInitialise: true});
                this.props.setIsHpmConfigChanged(true);
                this.props.handleCheckHpmInstallation();   
            } else {
                this.setState({hpmToInitialise: this.props.hpmToInitialise});
                if (this.props.initialisedServices.includes(UploadPath.HPM)) {
                    this.props.setIsHpmConfigChanged(true);
                    this.props.handleCheckHpmInstallation();     
                }
            }          
        } else {
            this.setState({hpmToInitialise: true})
            this.props.setIsHpmConfigChanged(true);
            this.props.handleCheckHpmInstallation();    
        }        
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.hpmUpdateSteps !== this.props.hpmUpdateSteps && this.props.hpmUpdateSteps.every(item => item.status === "DONE") && this.state.hpmToInitialise) {
            this.props.handleCheckHpmInstallation();
            this.props.dispatch_getInitialisationStatus();
        }
    }

    handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({hpmToInitialise: event.target.checked});
        this.props.dispatch_setHpmToInitialise(event.target.checked);
        if (event.target.checked) {
            this.props.setIsHpmConfigChanged(true);
            this.props.handleCheckHpmInstallation();
        } else {
            this.props.dispatch_setHpmApplied(false);
        }
    }

    handleEditClick() {
        this.setState({
            isEditHpmConfig: true,
        })
    }

    handleDoneClick(hpmConfig: HPMKonfiguration) {
        this.setState({
            isEditHpmConfig: false,
            hpmConfig: hpmConfig,
        })
        //if (!this.props.initialisationStatus.standalone) {
            this.props.handleCheckHpmInstallation();
            this.props.dispatch_getInitialisationStatus();
            this.props.dispatch_getIsHpmAvailableStatus();
        //}
    }

    renderCheckHPM() {
        if (!this.props.isCheckedHPM && this.props.hpmStatus==="checking") { 
            this.props.setIsHpmConfigChanged(true);       
            return(
                <Box className="hpm-config-two-buttons pad-top">
                    <CircularProgress/>
                    <Typography className="pad-top" >Prüfe HPM Version...</Typography>
                </Box>
            )
        }
        else if (this.props.isCheckedHPM){
            if (this.props.hpmStatus==="ok" && this.props.hpmVersion !== "unbekannt")
                return (
                    <Typography className="pad-top" >HPM Version {this.props.hpmVersion} ist installiert.</Typography>
                )
            else {
                if (this.props.initialisationStatus.standalone)
                    return (
                        <Typography className="pad-top failure" >Keine installierte HPM-Version gefunden.</Typography>
                    )
                else if (!this.props.initialisationStatus.standalone && !this.props.isInstallingHpm)
                    return (
                        <Typography className="pad-top failure" >Keine installierte HPM-Version gefunden. Bitte wenden Sie sich an Ihren PVS-Hersteller!</Typography>
                    )
                else 
                    return (<></>)
            }
        } else 
            return (<></>)
    }

    render() {
        return (
            <Box className="main-content initialisation-content">
                {this.props.isKimEnabled &&
                    <>
                        {(this.props.role === "HAUSARZT" || this.props.role === "BETRIEBSSTAETTE_HAUSARZT") &&
                            <>
                                <Typography className="bs-doctors-description" variant="caption" color="inherit">
                                    Es muss mindestens eine Versandsweise eingerichtet werden. Entweder: Versand über HÄVG Prüfmodul (hierfür ist eine Integration von TeleScan in das PVS Voraussetzung). Oder: Versand über die Telematik-Infrastruktur als KIM-Nachricht.
                                </Typography>
                                <Typography className="bs-doctors-description" variant="caption" color="inherit">
                                    Für die Einrichtung des Telekonilversands über das HÄVG Prüfmodul (HPM) ist ein installiertes, aktuelles HPM und die Integration von TeleScan in Ihr Praxisverwaltungssystem notwendig.
                                </Typography>
                            </>
                          
                        }
                        {(this.props.role === "DERMATOLOGE" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE") &&
                            <Typography className="bs-doctors-description" variant="caption" color="inherit" gutterBottom>
                                Für die Einrichtung des Telekonilversands über das HÄVG Prüfmodul (HPM) ist ein installiertes, aktuelles HPM notwendig. Zudem ist das HPM für die Abrechnung der Telekonsile zwingend erfordlerich.
                            </Typography>
                        }
                        {(this.props.role === "HAUSARZT" || this.props.role === "BETRIEBSSTAETTE_HAUSARZT") &&
                            <Box className = "flex-row-end">
                                <Typography className="txt-header" variant="subtitle1" color="inherit">
                                        Möchten Sie das HÄVG-Prüfmodul (HPM) einrichten?
                                </Typography>
                                <Checkbox
                                    checked = {this.state.hpmToInitialise}
                                    color="primary"
                                    onChange={this.handleSwitchChange}
                                /> 
                            </Box>
                        }
                       
                    </>
                }
           
                {this.state.hpmToInitialise &&
                    <form className="form-content" noValidate autoComplete="off">
                    {/* <Typography className="txt-header" variant="subtitle1" color="inherit">
                        Installation des HÄVG-Prüfmoduls (HPM)
                    </Typography> */}

                        <Paper className="hpm-card hpm-installation-card" elevation={0}>
                            <Typography variant="caption" className="pad-bottom" color="inherit">
                                Um TeleScan nutzen zu können, ist eine kompatible HPM-Version notwendig.
                            </Typography>
                            {(this.props.initialisationStatus.standalone)? 
                            <Typography variant="caption" className="pad-bottom" color="inherit">
                                Die folgende Konfiguration wird standardmäßig für die HPM-Installation genutzt. Falls Sie das HPM über TeleScan installieren wollen, können Sie den Port anpassen.
                                Sollten Sie den Status Ihrer bereits installierten HPM-Version prüfen wollen, so wählen Sie dafür bitte das passende Protokoll, den passenden Host, sowie den richtigen Port aus. Diese Konfiguration kann nachträglich in den Einstellungen geändert werden.
                            </Typography>
                            :
                            <Typography variant="caption" className="pad-bottom" color="inherit">
                                Bitte geben Sie das passende Protokoll, den passenden Host, sowie den richtigen Port an, unter denen das HPM installiert ist, um die installierte HPM-Version zu prüfen. Diese Konfiguration kann nachträglich in den Einstellungen geändert werden. Sollten Fehler auftreten, wenden Sie sich bitte an Ihren PVS Hersteller.
                            </Typography>
                            // Prüfe Sie bitte die Installation des HPM, die über Ihr PVS vorgenommen wurde. Wählen Sie dafür bitte das passende Protokoll, den passenden Host, sowie den richtigen Port aus.
                            }
                            <div className="hpm-config-container">
                                <HPMKonfigurationComponent handleDoneClick={this.handleDoneClick} isEdit={this.state.isEditHpmConfig}/>
                                {!this.state.isEditHpmConfig && 
                                    <Tooltip title="Konfiguration bearbeiten">
                                        <Button className="slim edit-button margin-left" variant="contained" color="primary" onClick={this.handleEditClick} >
                                            <Edit/>
                                        </Button>
                                    </Tooltip>
                                }
                            </div>
                            {(this.props.initialisationStatus.standalone && (this.state.hpmConfig.protokoll !== "http" && this.state.hpmConfig.host !== "docker-build.intern.zollsoft.org" )
                            //(window?.location?.hostname || "localhost")) 
                            ) &&
                            <Typography className="failure pad-bottom">Achtung! Die Installation des HPMs ist unter diesem Pfad nicht möglich.</Typography>
                            }
                            <div className = {this.props.initialisationStatus.standalone ? "hpm-config-three-buttons" : "hpm-config-two-buttons"}>
                                {this.props.initialisationStatus.standalone &&
                                <Button variant="contained" color="primary" onClick={this.props.handleClickInstallHpm} 
                                        disabled={this.props.isInstallingHpm || (this.props.hpmVersion !== "unbekannt" && this.props.hpmStatus === "ok") || this.props.initialisationStatus.hpmInstalliert ||
                                        (this.state.hpmConfig.protokoll !== "http" && this.state.hpmConfig.host !== "docker-build.intern.zollsoft.org") //(window?.location?.hostname || "localhost")
                                    }
                                    >
                                    HPM-Installation starten
                                </Button> 
                                }
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        this.props.handleCheckHpmInstallation();
                                        this.props.dispatch_getInitialisationStatus();
                                    }}
                                >
                                    HPM-Pfad prüfen
                                </Button> 
                                {(this.props.isCheckedHPM && this.props.hpmStatus==="ok" && this.props.hpmVersion !== "unbekannt") &&
                                <Button
                                    variant="contained"
                                    color="primary" 
                                    onClick={() => {
                                        this.props.setIsHpmConfigChanged(false);
                                        this.props.dispatch_setHpmApplied(true);
                                    }} 
                                    disabled = {!this.props.isHpmConfigChanged}
                                >
                                    Anwenden
                                </Button> 
                                }
                                {(this.props.isCheckedHPM && this.props.hpmStatus!=="ok") &&
                                <Button
                                    variant="contained"
                                    color="primary" 
                                    onClick={() => {
                                        this.props.setIsHpmConfigChanged(false);
                                        this.props.dispatch_setHpmApplied(true);
                                    }} 
                                    disabled = {!this.props.isHpmConfigChanged}
                                >
                                    Trotzdem verwenden
                                </Button> 
                                }
                            </div>
                            {this.props.isInstallingHpm &&
                                <HPMInstallationComponent steps={this.props.hpmUpdateSteps} context="INITIALISATION"/> 
                            }
                            {this.renderCheckHPM()}
                        </Paper>
                    </form>
                }
               
            </Box>
        )
    }
}

export const UpdateComponent = connector(withHpmInstallMethods(Update));
export default UpdateComponent;