import React from "react";
import {Konsil} from "telescan-core";
import {EditPersonalDataContainer, ViewPersonendatenContainer} from ".";

interface IProps {
    konsil: Konsil | null;
}

export class PersonalData extends React.Component<IProps> {
    render() {
        if (this.props.konsil?.konsilStatus === "IN_ARBEIT" && this.props.konsil?.bearbeitungsStatus !== "UPLOAD")
            return <EditPersonalDataContainer/>
        else
            return <ViewPersonendatenContainer/>
    }
}
