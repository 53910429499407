import { Box, Typography, Paper, Checkbox, Table, TableBody, TableCell, TableContainer, TableRow, Button, Dialog, DialogActions, DialogContent, DialogContentText, TextField, FormControlLabel, LinearProgress, LinearProgressProps } from '@material-ui/core';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { getSupportEnabledStatus, setIsSupportEnabled } from '../../redux/actions';
import { RootState } from '../../redux/reducers';
import { clearKimInbox, setKimDeleteMailProgress } from '../../redux/actions/kim_actions';


function LinearProgressWithLabel(props: LinearProgressProps & { current: number, total: number }) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" value={(props.current / props.total) * 100} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2">{`${props.current} / ${props.total}`}</Typography>
        </Box>
      </Box>
    );
  }


interface IState {
    isDialog: boolean;
    password: string
    deleteAllMessages: boolean
}

const mapStateToProps = (state: RootState) => ({
    isSupportEnabled: state.general.isSupportEnabled,
    clearKimInboxProgress: state.kim.kimDeleteProgress
})

const mapDispatchToProps = {
    dispatch_getSupportEnabledStatus: (password: string) => getSupportEnabledStatus(password),
    dispatch_setSupportEnabledStatus: (isSupportEnabled: boolean) => setIsSupportEnabled(isSupportEnabled),
    dispatch_clearKimInbox: (deleteAllMessages: boolean) => clearKimInbox(deleteAllMessages),
    dispatch_resetKimInboxProgress: () => setKimDeleteMailProgress()
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>
type Props = TPropsFromRedux

class SupportSettingsComponent extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props)
        this.state = {
            isDialog: false,
            password: "",
            deleteAllMessages: false
        }
    }
    onClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            this.setState({isDialog: true});
        } else
            this.props.dispatch_setSupportEnabledStatus(false);
    }

    onToggleDeleteAllMessages = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({deleteAllMessages: event.target.checked});
    }

    handleClearInbox = () => {
        this.props.dispatch_clearKimInbox(this.state.deleteAllMessages)
    }

    componentWillUnmount() {
        // Clear kim inbox progress object from store
        this.props.dispatch_resetKimInboxProgress()
    }

    render() {
        return (
            <Box className="bsnr-info-container">
                <Box className="arzt-info">
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                        Support-Einstellungen
                    </Typography>
                </Box>
                <Paper elevation={0}>

                <TableContainer className="icd10-table">
                    <Table stickyHeader aria-label="sticky table">
                        <TableBody>
                            <TableRow key = 'status_update'>
                                <TableCell>Zusätzliche Supporteinstellungen aktivieren</TableCell>
                                <TableCell align = 'right'>
                                    <Checkbox
                                        checked = {this.props.isSupportEnabled}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        onChange = {(event: React.ChangeEvent<HTMLInputElement>) => this.onClick(event)}
                                        className = 'bsnr-info'
                                    />
                                </TableCell>
                            </TableRow>
                            {this.props.isSupportEnabled &&
                            <>
                            <TableRow key = 'delete_kiminbox'>
                                <TableCell>
                                    <Typography variant="body1">
                                    KIM-Inbox löschen
                                    </Typography>

                                <FormControlLabel
                                        label={<Typography variant="caption">
                                            Alle Nachrichten löschen? (Sowohl Konsilnachrichten als auch externe Nachrichten)
                                        </Typography>}
                                        control={
                                            <Checkbox
                                                checked = {this.state.deleteAllMessages}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                onChange = {(event: React.ChangeEvent<HTMLInputElement>) => this.onToggleDeleteAllMessages(event)}
                                                className = 'bsnr-info'
                                            />
                                        }
                                    />
                                </TableCell>
                                <TableCell align = 'right'>
                                    <Button
                                        color='primary'
                                        variant="contained"
                                        onClick={() => this.handleClearInbox()}
                                    >
                                        Nachrichten löschen
                                    </Button>

                                </TableCell>
                            </TableRow>
                            {this.props.clearKimInboxProgress &&
                            <TableRow key = 'kim_delete_progress'>
                                <TableCell>Fortschritt</TableCell>
                                <TableCell align = 'right'>
                                    <LinearProgressWithLabel
                                        current={this.props.clearKimInboxProgress.currentMessage}
                                        total={this.props.clearKimInboxProgress.totalMessages}
                                    />
                                </TableCell>
                            </TableRow>
                            }
                        </>
                        }
                        </TableBody>
                    </Table>
                </TableContainer>

                </Paper>
                <Dialog open = {this.state.isDialog}>
                    <form>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Bitte geben Sie das Support-Passwort ein
                            </DialogContentText>
                            <TextField
                                autoFocus
                                type="password"
                                name="password"
                                label="Support-Passwort"
                                value={this.state.password}
                                onChange = {(e) => this.setState({password: e.target.value})}
                            >
                            </TextField>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                type='button'
                                variant = 'outlined'
                                color = 'primary'
                                onClick = {() => {
                                    this.setState({isDialog: false});
                                }}
                            >
                                Abbrechen
                            </Button>
                            <Button
                                type="submit"
                                variant = 'contained'
                                color = 'primary'
                                onClick = {(event) => {
                                    event.preventDefault()
                                    this.props.dispatch_getSupportEnabledStatus(this.state.password);
                                    this.setState({isDialog: false});
                                }}
                            >
                                Bestätigen
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </Box>
        )
    }
}

export const SupportSettings = connector(SupportSettingsComponent)
