import { Box, Button, CircularProgress, FormControlLabel, Paper, Switch, TextField, Tooltip, Typography } from '@material-ui/core'
import { CheckCircle, HighlightOff } from '@material-ui/icons';
import { DropzoneArea } from 'material-ui-dropzone';
import React from 'react'
import { Arzt, CertTypes, KIMConnector, KIMContext, KIMGeneral, KIMUser, TIConnectorEvent, AuthType } from 'telescan-core';
import { SignatureDialogContainer } from './signature_dialog';


enum TextFieldName {
    HOST = "host",
    SMTP_PORT = "smtpPort",
    POP3_PORT = "pop3Port",
    KIM_HOST = "kimHost",
    KIM_SMTP_PORT = "kimSmtpPort",
    KIM_POP3_PORT = "kimPop3Port",
    KIM_EMAIL_ADDRESS_BS = "kimEmailAddressBs",
    PASSWORD_BS = "passwordBs",
    CONNECTOR_IP = "connectorIp",
    CONNECTOR_PORT = "connectorPort",
    BASIC_AUTH_UN = "basicAuthUsername",
    BASIC_AUTH_PW = "basicAuthPassword",
    AUTH_TYPE = "authType",
    LDAP_PORT = "ldapPort",
    CERTIFICATE_PASSWORD = "certificatePassword",
    CLIENTSYSTEM_ID = "clientsystemId",
    WORKPLACE_ID = "workplaceId",
    MANDANT_ID = "mandantId",
    ICCSN = "iccsn"
}

interface IProps {
    handleSaveClick?: (kimGeneral: KIMGeneral, kimUserSettings: KIMUser) => void;
    handleCancelClick?: () => void;
    kimGeneral: KIMGeneral;
    kimUser: KIMUser;
    dispatch_uploadCertificates: (cert: Blob[], connectorId: string, certificatePassword: string) => void;
    dispatch_sendKimSettings: (kimGeneral: KIMGeneral, kimUser: KIMUser) => void;
    dispatch_registerEHBA: () => void;
    dispatch_setTiConnectorEvent: (tiConnectorEvent: TIConnectorEvent) => void;
    tiConnectorEvent: TIConnectorEvent;
    dispatch_getKimSettings: () => void;
    isSignatureDialogOpen: boolean;
    kimSettingsError?: boolean;
    handleSignatureDialogOpen: () => void;
    handleSignatureDialogClose: () => void;
    isTiConnected: boolean;
    dispatch_getTiConnectionStatus: () => Promise<any>;
    isTiStatusLoading: boolean;
    dispatch_getKimMailInLdapStatus: (kimMail: string) => Promise<any>;
    isKimMailInLdap: boolean;
    isLdapRequest: boolean;
    isTestTiConnectionClicked: boolean;
    dispatch_setIsTestTiConnectionClicked: (isClicked: boolean) => void;
    user: Arzt[];
}

interface IState {
    kimGeneral: KIMGeneral;
    kimUser: KIMUser;
    certToUpload: Blob[];
    isTextFieldChanged: boolean;
}

export default class EditSettings extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            kimGeneral: new KIMGeneral(),
            kimUser: new KIMUser(),
            certToUpload: [],
            isTextFieldChanged: false,
        }
    }

    componentDidMount() {
        this.setState(
            {
                kimGeneral: this.props.kimGeneral,
                kimUser: this.props.kimUser
            },
            () => {
                this.assignDefaultValues();
            });
    }

    componentDidUpdate (prevProps: IProps) {
        if (prevProps.kimGeneral !== this.props.kimGeneral) {
            this.setState({
                kimGeneral: this.props.kimGeneral,
            }, () => this.assignDefaultValues())
        }

        if (prevProps.kimUser !== this.props.kimUser) {
            this.setState({
                kimUser: this.props.kimUser
            })
        }
    }

    assignDefaultValues = () => {
        if (this.state.kimGeneral) {

            const updatedConnectorList =
                this.state.kimGeneral.kimConnectorList
                ? this.state.kimGeneral.kimConnectorList.map((conn) => {
                    return {...conn, authType: conn.authType ? conn.authType : AuthType.CERT}
                })
                : [{authType: AuthType.CERT}]

            this.setState({
                kimGeneral: {
                    ...this.state.kimGeneral,
                    smtpPort: !this.state.kimGeneral.smtpPort ? "10465" : this.state.kimGeneral.smtpPort,
                    pop3Port: !this.state.kimGeneral.pop3Port ? "10995" : this.state.kimGeneral.pop3Port,
                    kimSmtpPort: !this.state.kimGeneral.kimSmtpPort ? "465" : this.state.kimGeneral.kimSmtpPort,
                    kimPop3Port: !this.state.kimGeneral.kimPop3Port ? "995" : this.state.kimGeneral.kimPop3Port,
                    kimConnectorList: updatedConnectorList
                }
            });
        }


    }

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({isTextFieldChanged: true});
        let value: string;
        if (event.target.name === TextFieldName.SMTP_PORT || event.target.name === TextFieldName.POP3_PORT || event.target.name === TextFieldName.KIM_SMTP_PORT ||
            event.target.name === TextFieldName.KIM_POP3_PORT || event.target.name === TextFieldName.ICCSN) {
            value = event.target.value.replace(/\s/g, "").replace("-", "").replace(/[^0-9]+$/, "");
        } else {
            value = event.target.value.replace(/\s/g, "");
        }
        this.setState({
            kimGeneral: {
                ...this.state.kimGeneral,
                [event.target.name]: value
            },
            kimUser: {
                ...this.state.kimUser,
                [event.target.name]: value,
                isSignatureInitialised: (event.target.name === "iccsn" && value !== this.state.kimUser[event.target.name]) ? false : this.state.kimUser.isSignatureInitialised
            },
        })
    }

    handleInputChangeConnector = (event: React.ChangeEvent<HTMLInputElement>, elementId: string) => {
        this.setState({isTextFieldChanged: true});
        if (elementId === "")
            return;

        let value: string = "";
        if (event.target.name === TextFieldName.CONNECTOR_PORT || event.target.name === TextFieldName.LDAP_PORT) {
            value = event.target.value.replace(/\s/g, "").replace("-", "").replace(/[^0-9]+$/, "");
        } else {
            value = event.target.value.replace(/\s/g, "");
        }

        let kimConnectorList: KIMConnector[];
        if (this.state.kimGeneral.kimConnectorList && this.state.kimGeneral.kimConnectorList.length !== 0) {
            kimConnectorList = this.state.kimGeneral.kimConnectorList;
            kimConnectorList.forEach(item => {
                if (item.id === elementId) {
                    if (event.target.type === "checkbox") {
                        if (event.target.name === "authType") {
                            item[event.target.name] = event.target.checked ? AuthType.BASIC : AuthType.CERT;
                        } else {
                            item[event.target.name] = event.target.checked
                        }
                    } else
                         item[event.target.name] = value;
                } else {
                    if (event.target.type === "checkbox")
                        item[event.target.name] = false;
                }
            })
        } else {
            kimConnectorList = [];
            const kimConnector = new KIMConnector();
            if (event.target.type === "checkbox") {
                kimConnector[event.target.name] = event.target.checked;
            } else
                kimConnector[event.target.name] = value;
            kimConnectorList.push(kimConnector);
        }
        this.setState({
            kimGeneral: {
                ...this.state.kimGeneral,
                kimConnectorList: kimConnectorList
            }
        })
    }

    handleInputChangeContext = (event: React.ChangeEvent<HTMLInputElement>, elementId: string) => {
        this.setState({isTextFieldChanged: true});
        if (elementId === "")
            return;
        let kimContextList: KIMContext[];

        let value: string = "";
        if (event.target.value) {
            value = event.target.value.replace(/\s/g, "");
        }
        if (this.state.kimGeneral.kimContextList && this.state.kimGeneral.kimContextList.length !== 0) {
            kimContextList = this.state.kimGeneral.kimContextList;
            kimContextList.forEach(item => {
                if (item.id === elementId) {
                    if (event.target.type === "checkbox") {
                        item[event.target.name] = event.target.checked;
                    } else
                         item[event.target.name] = value;
                } else {
                    if (event.target.type === "checkbox")
                        item[event.target.name] = false;
                }
            })
        } else {
            kimContextList = [];
            const kimContext = new KIMContext();
            if (event.target.type === "checkbox") {
                kimContext[event.target.name] = event.target.checked;
            } else
                kimContext[event.target.name] = value;
            kimContextList.push(kimContext);
        }
        this.setState({
            kimGeneral: {
                ...this.state.kimGeneral,
                kimContextList: kimContextList
            }
        })
    }

    addCertForUpload = (cert: Blob[]) => {
        if (cert.length !== 0) {
            const newCerts = this.state.certToUpload.filter(item => item.type !== cert[0].type);
            newCerts.push(cert[0]);
            this.setState({certToUpload: newCerts});
        }

    }

    removeCertForUpload = (cert: Blob) => {
            const newCerts = this.state.certToUpload.filter(item => item.type !== cert.type);
            this.setState({certToUpload: newCerts});
    }

    handleUploadCertButton = (item: KIMConnector) => {
        this.props.dispatch_setIsTestTiConnectionClicked(false);
        this.props.dispatch_uploadCertificates(this.state.certToUpload, item.id || "", item.certificatePassword || "");
    }

    uploadButtonDisabled = (connector: KIMConnector) => {
        if (this.state.certToUpload.length === 0)
            return true;
        if (this.state.certToUpload.some(item => item.type === CertTypes.CLIENT) && (!connector.certificatePassword || connector.certificatePassword === ""))
            return true;
    }

    handleTestTiButton = () => {
        this.props.dispatch_getTiConnectionStatus();
        this.props.dispatch_setIsTestTiConnectionClicked(true);
    }

    handleOnBlurKimMail = () => {
        if (this.props.kimGeneral.kimEmailAddressBs !== this.state.kimGeneral.kimEmailAddressBs) {
            this.props.dispatch_setIsTestTiConnectionClicked(false);
            if (this.state.kimGeneral.kimEmailAddressBs && this.state.kimGeneral.kimEmailAddressBs !== "" && this.state.kimGeneral.kimConnectorList && this.state.kimGeneral.kimConnectorList[0].ldapPort && this.state.kimGeneral.kimConnectorList[0].ldapPort !== "") {
                this.props.dispatch_getKimMailInLdapStatus(this.state.kimGeneral.kimEmailAddressBs || "")
                .then(() => {
                    this.props.dispatch_sendKimSettings(this.state.kimGeneral, this.state.kimUser);
                })
            } else {
                this.props.dispatch_sendKimSettings(this.state.kimGeneral, this.state.kimUser);
            }
        }
    }

    handleOnBlurSettings = () => {
        if (this.state.isTextFieldChanged) {
            this.props.dispatch_setIsTestTiConnectionClicked(false);
            this.props.dispatch_sendKimSettings(this.state.kimGeneral, this.state.kimUser);
        }
        this.setState({isTextFieldChanged: false});
    }

    handleToggleSettings = () => {
        this.props.dispatch_setIsTestTiConnectionClicked(false);
        this.props.dispatch_sendKimSettings(this.state.kimGeneral, this.state.kimUser);

        this.setState({isTextFieldChanged: false});
    }


    renderTestTiConnectionResult = () => {
        if (this.props.isTestTiConnectionClicked) {
            return (
                 this.props.isTiStatusLoading ?
                    <CircularProgress/>
                    :
                    this.props.isTiConnected ?
                    <Tooltip title = "Verbindung konnte erfolgreich hergestellt werden">
                        <CheckCircle className = 'up-to-date'/>
                    </Tooltip>
                    :
                    <Tooltip title = "Verbindung fehlgeschlagen. Überprüfen Sie Ihre Eingaben und Netzwerkverbindung">
                        <HighlightOff className = 'out-of-date'/>
                    </Tooltip>
            )
        } else return null
    }

    render() {
        return (
            <Box >
                <Paper className="initialisation-content initialisation-overview hpm-config popup-insert pad-bottom" elevation={0}>
                    <Typography className="bsnr-info" variant="body1" color="inherit">
                        TI-Konnektor Einstellungen <strong>(betriebsstättenweit!)</strong>
                    </Typography>
                    {this.state.kimGeneral.kimConnectorList && this.state.kimGeneral.kimConnectorList.length !== 0 &&
                        this.state.kimGeneral.kimConnectorList.map((item: KIMConnector, idx: number) => {
                            return (
                                <Box key = {item.id}>
                                    {idx === 1 &&
                                        <Typography className="bsnr-info" variant="body2" color="inherit">
                                            Weitere TI-Konnektoren
                                        </Typography>
                                    }
                                        <Box className = "bs-infos-card" >
                                            <TextField
                                                label = "IP-Adresse des TI-Konnektors"
                                                value = {item.connectorIp}
                                                autoComplete="off"
                                                name = "connectorIp"
                                                onChange = {(event: React.ChangeEvent<HTMLInputElement>) => this.handleInputChangeConnector(event, item.id || "")}
                                                onBlur = {this.handleOnBlurSettings}
                                                error = {!item.connectorIp || item.connectorIp === ""}
                                            />
                                            <TextField
                                                label = "LDAP-Port des TI-Konnektors"
                                                value = {item.ldapPort}
                                                autoComplete="off"
                                                fullWidth
                                                name = "ldapPort"
                                                onChange = {(event: React.ChangeEvent<HTMLInputElement>) => this.handleInputChangeConnector(event, item.id || "")}
                                                onBlur = {this.handleOnBlurSettings}
                                                error = {!item.ldapPort || item.ldapPort === ""}
                                            />
                                            {/* <TextField
                                                label = "TI-Konnektor-Id"
                                                value = {item.connectorId}
                                                autoComplete="off"
                                                name = "connectorId"
                                                onChange = {(event: React.ChangeEvent<HTMLInputElement>) => this.handleInputChangeConnector(event, item.id || "")}
                                                onBlur = {() => this.props.dispatch_sendKimSettings(this.state.kimGeneral, this.state.kimUser)}
                                                error = {!item.connectorId || item.connectorId === ""}
                                            /> */}
                                            {/* <FormControlLabel
                                                control = {
                                                    <Checkbox
                                                        color="primary"
                                                        name = "isCurrent"
                                                        checked = {item.isCurrent}
                                                        disabled = {this.state.kimGeneral.kimConnectorList?.length === 1}
                                                        onChange = {(event: React.ChangeEvent<HTMLInputElement>) => this.handleInputChangeConnector(event, item.id || "")}
                                                    />
                                                }
                                                label = {<Typography variant = 'caption'>in Betrieb</Typography>}
                                                labelPlacement="top"
                                                className = "align-self-center margin-auto"
                                            /> */}

                                    </Box>
                                    {(!item.clientCertificate || !item.serverCertificate) &&
                                                    <Box className = "bs-infos-card hpm-config-container">
                                                        <Typography className = 'failure'>Sie müssen Client- und Server-Zertifikate des TI-Konnektors hochladen, um eine Verbindung mit Ti-Konnector herzustellen</Typography>
                                                    </Box>
                                                }

                                                    <Box className = "bs-infos-card hpm-config-container">
                                                        {/* <Typography>Die Zertifikate wurden erfolgreich hochgeladen. Verwenden Sie zum Aktualisieren denselben Upload-Bereich unten</Typography> */}
                                                        <Typography>Bitte verwenden Sie zum Hochladen oder Aktualisieren der Zertifikate den Upload-Bereich unten</Typography>
                                                        {/* <Tooltip title = "In Betrieb">
                                                            <CheckCircle className = 'up-to-date'/>
                                                        </Tooltip>       */}
                                                    </Box>

                                    <Box className = "bs-infos-card-4columns-unequal">
                                        <DropzoneArea
                                            acceptedFiles={["application/x-pkcs12"]}
                                            filesLimit = {1}
                                            maxFileSize={5000000}
                                            onChange={(cert) => {
                                                this.addCertForUpload(cert)
                                            }}
                                            onDelete = {(file) => {
                                                this.removeCertForUpload(file);
                                            }}
                                            showPreviewsInDropzone = {true}
                                            showPreviews={false}
                                            showFileNamesInPreview={true}
                                            showFileNames = {true}
                                            useChipsForPreview
                                            dropzoneText = "Ziehen Sie das Client-Zertifikat (.p12 Datei) des TI-Konnektors per Drag & Drop hierher oder klicken Sie auf"
                                            dropzoneParagraphClass = "dropzone-paragraph"
                                            dropzoneClass = "dropzone-align"
                                            showAlerts = {false}
                                        />
                                        {item.clientCertificate ?
                                            <Tooltip title = "Das Client-Zertifikat ist hochgeladen. Die Gültigkeit des Zertifikats kann nur überprüft werden, indem die Verbindung mit dem KIM-Modul über das Button unten getestet wird.">
                                                <CheckCircle/>
                                            </Tooltip>
                                            :
                                            <Tooltip title = "Das Client-Zertifikat fehlt">
                                                <HighlightOff className = 'out-of-date'/>
                                            </Tooltip>
                                        }
                                        <DropzoneArea
                                            acceptedFiles={['application/pkix-cert', 'application/x-x509-ca-cert', 'application/x-x509-user-cert', 'application/x-pem-file']}
                                            filesLimit = {1}
                                            maxFileSize={5000000}
                                            onChange={(cert) => {
                                                this.addCertForUpload(cert)
                                            }}
                                            onDelete = {(file) => {
                                                this.removeCertForUpload(file);
                                            }}
                                            showPreviewsInDropzone = {true}
                                            showPreviews={false}
                                            showFileNamesInPreview={true}
                                            showFileNames = {true}
                                            useChipsForPreview
                                            dropzoneText = "Ziehen Sie das Server-Zertifikat (.cer, .crt, .pem Datei) des TI-Konnektors per Drag & Drop hierher oder klicken Sie auf"
                                            dropzoneParagraphClass = "dropzone-paragraph"
                                            dropzoneClass = "dropzone-align"
                                            showAlerts = {false}
                                        />
                                        {item.serverCertificate ?
                                            <Tooltip title = "Das Server-Zertifikat ist hochgeladen. Die Gültigkeit des Zertifikats kann nur überprüft werden, indem die Verbindung mit dem KIM-Modul über das Button unten getestet wird.">
                                                <CheckCircle/>
                                            </Tooltip>
                                         :
                                         <Tooltip title = "Das Server-Zertifikat fehlt">
                                            <HighlightOff className = 'out-of-date'/>
                                        </Tooltip>
                                        }
                                    </Box>
                                    <Box className = "bs-infos-card">
                                        <TextField
                                            id = "certificatePassword"
                                            label = "Passwort des Client-Zertifikats des TI-Konnektors"
                                            type = 'password'
                                            value = {item.certificatePassword}
                                            autoComplete="new-password"
                                            name = "certificatePassword"
                                            onChange = {(event: React.ChangeEvent<HTMLInputElement>) => this.handleInputChangeConnector(event, item.id || "")}
                                            onBlur = {this.handleOnBlurSettings}
                                            fullWidth
                                            error = {!item.certificatePassword || item.certificatePassword === ""}
                                        />
                                        <Box className = 'align-self-center'>
                                            <Button
                                                className="btn-right"
                                                variant="contained"
                                                color="primary"
                                                onClick = {() => this.handleUploadCertButton(item)}
                                                disabled = {this.uploadButtonDisabled(item)}
                                            >
                                                {(item.clientCertificate && item.serverCertificate) ? "Aktualisieren": "Hochladen"}
                                            </Button>
                                        </Box>

                                    </Box>
                                    <Typography className="bsnr-info" variant="body1" color="inherit">
                                        Alternative Authentifizierungsmethode via Benutzername und Passwort
                                    </Typography>

                                    <Box>
                                        <Box className = "bs-infos-card">
                                        <FormControlLabel
                                            value="top"
                                            control={
                                                <Switch
                                                    color="primary"
                                                    name="authType"
                                                    id="authType"
                                                    checked={item.authType === AuthType.BASIC ? true : false}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        this.handleInputChangeConnector(event, item.id || "")
                                                        this.handleToggleSettings()
                                                    }}
                                                />
                                            }
                                            label="Alternative Clientauthentifizierung verwenden? (nicht empfohlen)"
                                            labelPlacement="end"
                                        />
                                        </Box>
                                        {item.authType === AuthType.BASIC &&
                                            <Box className = "bs-infos-card-2columns">
                                                <TextField
                                                    id = "basicAuthUsername"
                                                    value = {item.basicAuthUsername}
                                                    label = "Benutzername des Clientsystems"
                                                    autoComplete="off"
                                                    name = "basicAuthUsername"
                                                    onBlur = {this.handleOnBlurSettings}
                                                    onChange = {(event: React.ChangeEvent<HTMLInputElement>) => this.handleInputChangeConnector(event, item.id || "")}
                                                    fullWidth
                                                />
                                                <TextField
                                                    id = "basicAuthPassword"
                                                    value = {item.basicAuthPassword}
                                                    label = "Passwort des Clientsystems"
                                                    type = 'password'
                                                    autoComplete="off"
                                                    name = "basicAuthPassword"
                                                    onBlur = {this.handleOnBlurSettings}
                                                    onChange = {(event: React.ChangeEvent<HTMLInputElement>) => this.handleInputChangeConnector(event, item.id || "")}
                                                    fullWidth
                                                />
                                            </Box>
                                        }
                                    </Box>
                                </Box>

                            )
                        })
                    }
                </Paper>

                <br/>
                {/* <Paper className="initialisation-content initialisation-overview hpm-config popup-insert pad-bottom"  elevation={0}>
                </Paper>
                <br/> */}

                <Paper className="initialisation-content initialisation-overview hpm-config popup-insert pad-bottom" elevation={0}>
                    <Typography className="bsnr-info" variant="body1" color="inherit">
                        Aufrufkontext des TI-Konnektors <strong>(betriebsstättenweit!)</strong>
                    </Typography>

                    {this.state.kimGeneral.kimContextList && this.state.kimGeneral.kimContextList.length !== 0 &&
                        this.state.kimGeneral.kimContextList.map((item: KIMContext, idx: number) => {
                            return (
                                <Box key = {item.id}>
                                    {idx === 1 &&
                                        <Typography className="bsnr-info" variant="body2" color="inherit">
                                            Weitere Aufrufkontexte
                                        </Typography>
                                    }
                                        <Box className = "bs-infos-card-3columns" key = {item.id}>
                                        <TextField
                                            label = "Clientsystem"
                                            value = {item.clientsystemId}
                                            autoComplete="off"
                                            name = "clientsystemId"
                                            onChange = {(event: React.ChangeEvent<HTMLInputElement>) => this.handleInputChangeContext(event, item.id || "")}
                                            onBlur = {this.handleOnBlurSettings}
                                            fullWidth
                                            error = {!item.clientsystemId || item.clientsystemId === ""}
                                        />
                                        <TextField
                                            label = "Arbeitsplatz"
                                            value = {item.workplaceId}
                                            autoComplete="off"
                                            fullWidth
                                            name = "workplaceId"
                                            onChange = {(event: React.ChangeEvent<HTMLInputElement>) => this.handleInputChangeContext(event, item.id || "")}
                                            onBlur = {this.handleOnBlurSettings}
                                            error = {!item.workplaceId || item.workplaceId === ""}
                                        />
                                        <TextField
                                            label = "Mandant"
                                            value = {item.mandantId}
                                            autoComplete="off"
                                            fullWidth
                                            name = "mandantId"
                                            onChange = {(event: React.ChangeEvent<HTMLInputElement>) => this.handleInputChangeContext(event, item.id || "")}
                                            onBlur = {this.handleOnBlurSettings}
                                            error = {!item.mandantId || item.mandantId === ""}
                                        />

                                        {/* <FormControlLabel
                                            control = {
                                                <Checkbox
                                                    color="primary"
                                                    name = "isCurrent"
                                                    checked = {item.isCurrent}
                                                    disabled = {this.state.kimGeneral.kimContextList?.length === 1}
                                                    onChange = {(event: React.ChangeEvent<HTMLInputElement>) => this.handleInputChangeContext(event, item.id || "")}
                                                />
                                            }
                                            label = {<Typography variant = 'caption'>in Betrieb</Typography>}
                                            labelPlacement="top"
                                            className = "align-self-center margin-auto"
                                        /> */}
                                    </Box>
                                </Box>
                            )
                        })

                    }
                </Paper>

                <br/>

                <Paper className="initialisation-content initialisation-overview hpm-config popup-insert pad-bottom" elevation={0}>
                    <Typography className="bsnr-info" variant="body1" color="inherit">
                            KIM-Mail Einstellungen <strong>(betriebsstättenweit!)</strong>
                    </Typography>
                    <Box className="bs-infos-card-3columns">
                        <TextField
                            id = "host"
                            label = "Hostname/IP des Clientmoduls"
                            value = {this.state.kimGeneral.host}
                            name = "host"
                            autoComplete="off"
                            onChange = {this.handleInputChange}
                            onBlur = {this.handleOnBlurSettings}
                            error = {!this.state.kimGeneral.host || this.state.kimGeneral.host === ""}
                        />
                        <TextField
                            id = "Port SMTP"
                            label = "SMTP-Port des Clientmoduls"
                            value = {this.state.kimGeneral.smtpPort}
                            name = "smtpPort"
                            autoComplete="off"
                            onChange = {this.handleInputChange}
                            onBlur = {this.handleOnBlurSettings}
                            error = {!this.state.kimGeneral.smtpPort || this.state.kimGeneral.smtpPort === ""}
                        />
                        <TextField
                            id = "Port POP3"
                            label = "POP3-Port des Clientmoduls"
                            value = {this.state.kimGeneral.pop3Port}
                            autoComplete="off"
                            name = "pop3Port"
                            onChange = {this.handleInputChange}
                            onBlur = {this.handleOnBlurSettings}
                            error = {!this.state.kimGeneral.pop3Port || this.state.kimGeneral.pop3Port === ""}
                        />
                        <TextField
                            id = "kimHost"
                            label = "Hostname des KIM-Dienstes"
                            value = {this.state.kimGeneral.kimHost}
                            name = "kimHost"
                            autoComplete="off"
                            onChange = {this.handleInputChange}
                            onBlur = {this.handleOnBlurSettings}
                            error = {!this.state.kimGeneral.kimHost || this.state.kimGeneral.kimHost === ""}
                        />
                        <TextField
                            id = "kimSmtpPort"
                            label = "SMTP-Port des KIM-Dienstes"
                            value = {this.state.kimGeneral.kimSmtpPort}
                            name = "kimSmtpPort"
                            autoComplete="off"
                            onChange = {this.handleInputChange}
                            onBlur = {this.handleOnBlurSettings}
                            error = {!this.state.kimGeneral.kimSmtpPort || this.state.kimGeneral.kimSmtpPort === ""}
                        />
                        <TextField
                            id = "kimPop3Port"
                            label = "POP3-Port des KIM-Dienstes"
                            value = {this.state.kimGeneral.kimPop3Port}
                            autoComplete="off"
                            name = "kimPop3Port"
                            onChange = {this.handleInputChange}
                            onBlur = {this.handleOnBlurSettings}
                            error = {!this.state.kimGeneral.kimPop3Port || this.state.kimGeneral.kimPop3Port === ""}
                        />
                    </Box>

                    <Box className = "bs-infos-card">
                        <TextField
                            id = "kimEmailAddressBs"
                            label = "KIM-Adresse für TeleScan"
                            value = {this.state.kimGeneral.kimEmailAddressBs}
                            autoComplete="off"
                            name = "kimEmailAddressBs"
                            onChange = {this.handleInputChange}
                            onBlur = {this.handleOnBlurSettings}
                            //onBlur = {() => this.handleOnBlurKimMail()}
                            //disabled = {this.props.isLdapRequest}
                            //error = {!this.state.kimGeneral.kimEmailAddressBs || this.state.kimGeneral.kimEmailAddressBs === "" || !this.props.isKimMailInLdap}
                            error = {!this.state.kimGeneral.kimEmailAddressBs || this.state.kimGeneral.kimEmailAddressBs === ""}
                            // InputProps = {{
                            //     endAdornment: <InputAdornment position="start">{this.props.isLdapRequest ? <CircularProgress size = {25}/> : null}</InputAdornment>,
                            // }}
                        />
                        <TextField
                            id = "passwordBs"
                            label = "Passwort"
                            type = 'password'
                            value = {this.state.kimGeneral.passwordBs}
                            autoComplete="new-password"
                            name = "passwordBs"
                            onChange = {this.handleInputChange}
                            onBlur = {this.handleOnBlurSettings}
                            error = {!this.state.kimGeneral.passwordBs || this.state.kimGeneral.passwordBs === ""}
                        />
                    </Box>
                    <Box className = "bs-infos-card">
                        <Box >
                            <Box className="margin-bottom-m">
                                <Button
                                    variant = "contained"
                                    color = "primary"
                                    onClick = {this.handleTestTiButton}
                                    disabled = {this.props.isTiStatusLoading}
                                >
                                    KIM-Modul testen
                                </Button>
                            </Box>
                            {(!this.props.isTestTiConnectionClicked || !this.props.isTiConnected) &&
                                <Typography className = "failure">
                                    Bitte überprüfen Sie die Verbindung mit dem KIM-Clientmodul, um die Einstellungen speichern zu können
                                </Typography>
                            }
                        </Box>
                        {this.renderTestTiConnectionResult()}
                    </Box>

                </Paper>

                <br/>

                <Paper className="initialisation-content initialisation-overview hpm-config popup-insert pad-bottom" elevation={0}>
                    <Typography className="bsnr-info" variant="body1" color="inherit">
                        Digitale Signatur des eHBAs
                        {this.props.user.length === 1
                            ? <span>{" "}von
                                {" "}{this.props.user[0].titel ? this.props.user[0].titel : ""}
                                {" "}{this.props.user[0].vorname ? this.props.user[0].vorname : ""}
                                {" "}{this.props.user[0].nachname ? this.props.user[0].nachname : ""}
                                </span>
                            : <span> des ersten angelegten Arztes{this.props.kimUser.lanr ? ` (LANR ${this.props.kimUser.lanr})` : ""}.
                                <br/><span>Die Signaturen anderer Ärzte können im Anschluss an die Ersteinrichtung nach Nutzerwechsel in den Einstellungen hinterlegt werden.</span>
                              </span>
                        }
                    </Typography>

                    {this.props.kimUser.isSignatureInitialised &&
                        <Box className = "bs-infos-card hpm-config-container">
                            <Typography>Die Signatur wurde erfolgreich hinterlegt.</Typography>
                            <Tooltip title = "In Betrieb">
                                <CheckCircle className = 'up-to-date'/>
                            </Tooltip>
                        </Box>
                    }

                    {!this.props.kimUser.isSignatureInitialised &&
                        <Box className = "bs-infos-card hpm-config-container">
                            <Typography className = "failure">Die digitale Signatur ist nicht hinterlegt oder die ICCSN-Nummer Ihrer eHBA-Karte wurde geändert und weicht von der hinterlegten Signatur ab. Bitte aktualisieren Sie die Signatur.</Typography>
                        </Box>
                    }
                    <Box className = "bs-infos-card">

                        <TextField
                            id = "iccsn"
                            label = "ICCSN-Nummer der eHBA-Karte"
                            value = {this.state.kimUser.iccsn}
                            name = "iccsn"
                            onChange = {this.handleInputChange}
                            onBlur = {() => this.props.dispatch_sendKimSettings(this.state.kimGeneral, this.state.kimUser)}
                            error = {!this.state.kimUser.iccsn || this.state.kimUser.iccsn === "" || this.state.kimUser.iccsn.length !== 20}
                        />
                        <Box className = 'align-self-center'>
                            <Button
                                className="btn-right"
                                variant = 'contained'
                                color = 'primary'
                                onClick = {this.props.handleSignatureDialogOpen}
                                disabled = {!this.state.kimUser.iccsn || this.state.kimUser.iccsn === ""}
                            >
                                {this.props.kimUser.isSignatureInitialised ? "Signatur erneuern" : "Signatur hinterlegen"}
                            </Button>
                        </Box>
                    </Box>
                    {/* <Box className = "margin-left-m margin-bottom-m">
                        <Tooltip title="Ein weiteres TI-Konnektor hinzufügen">
                            <Button className="slim" variant="contained" color="primary">
                                <Add/>
                            </Button>
                        </Tooltip>
                    </Box> */}
                </Paper>
                <br/>

                    {this.props.handleSaveClick && this.props.handleCancelClick &&
                        <Box>
                            <Button
                                className="slim save-config"
                                variant="text" color="primary"
                                onClick={this.props.handleCancelClick}
                            >
                                Abbrechen
                            </Button>
                            <Button
                                className="slim save-config"
                                variant="contained"
                                color="primary"
                                onClick={() => this.props.handleSaveClick && this.props.handleSaveClick(this.state.kimGeneral, this.state.kimUser)}
                                disabled = {this.props.kimSettingsError || !this.props.isTestTiConnectionClicked || !this.props.isTiConnected || this.props.isTiStatusLoading}
                            >
                                Speichern
                            </Button>
                        </Box>
                    }



                <SignatureDialogContainer
                    isOpen = {this.props.isSignatureDialogOpen}
                    handleClose = {this.props.handleSignatureDialogClose}
                    tiConnectorEvent = {this.props.tiConnectorEvent}
                    forSettings = {true}
                />
            </Box>
        )
    }
}
