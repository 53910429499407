import React from "react";
import {connect, ConnectedProps} from 'react-redux';

import { Box, Typography, CircularProgress} from "@material-ui/core";
import { RootState } from "../../redux/reducers";
import { CheckBoxOutlined, CheckBoxOutlineBlank, Error } from "@material-ui/icons";
import { UpdateStepStatusType } from "telescan-core";

interface IProps {
    steps: {step: string, status: keyof typeof UpdateStepStatusType, progress: string, message: string}[];
    context: "INITIALISATION" | "SETTINGS"
}

interface IState {
}

const mapStateToProps = (state: RootState, ownProps: IProps) => ({
    initialisationStatus: state.user.initialisationStatus,
    ...ownProps
})

const mapDispatchToProps = {
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class HPMInstallation extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <>
                <Typography className="txt-header" variant="subtitle1" color="inherit">
                    HPM Installations-/Updatefortschritt
                </Typography>
                {this.props.context === "SETTINGS" &&
                <Typography className="failure pad-bottom" variant="subtitle1">
                    Bitte warten Sie bis die Installation/Update abgeschlossen ist. Das Erstellen, Bearbeiten und Versenden von Konsilen kann zu Datenverlust führen!
                </Typography> 
                }
                {this.props.context === "INITIALISATION" &&
                <Typography className="failure pad-bottom" variant="subtitle1">
                    Bitte warten Sie bis die Installation/Update abgeschlossen ist. Ein Verlassen oder Neuladen der Seite kann zum Abbruch führen!
                </Typography>
                }
                {this.props.steps.map((item) => 
                    <InstallStep key={item.step} text={item.step} status={item.status} progress={item.progress} errorMessage={item.message} modul="hpm"/>
                )}
            </>
        )
    }
}

export const HPMInstallationComponent = connector(HPMInstallation);
export default HPMInstallationComponent;


interface IStepProps {
    text: string;
    status: keyof typeof UpdateStepStatusType;
    progress: string;
    errorMessage: string;
    modul: string;
}

interface IStepState {
}

export class InstallStep extends React.Component<IStepProps, IStepState> {

    renderSwitchIcon(status: keyof typeof UpdateStepStatusType) {
        switch (status) {
            case "WAITING":
                return (
                    <CheckBoxOutlineBlank className="hpm-installation-icon"/>
                )
            case "RUNNING":
                return (
                    <CircularProgress className="hpm-installation-icon"/>
                )
            case "DOWNLOAD":
                return (
                    <CircularProgress className="hpm-installation-icon"/>
                )
            case "DONE":
                return (
                    <CheckBoxOutlined className="hpm-installation-icon"/>
                )
            case "ERROR":
                return (
                    <Error className="hpm-installation-icon"/>
                )
            default:
                return <></>
        }
    }

    render() {
        return (
            <Box className="hpm-installation-step">
                {this.renderSwitchIcon(this.props.status)}
                <Typography className="pad-left">{this.props.text}</Typography>
                {(this.props.status === "RUNNING" && this.props.text === "Durchführung des Updates" && this.props.modul === "TELESCAN") &&
                    <Typography className="pad-left failure">Der Server ist vorrübergehend nicht erreichbar. Bitte bleiben Sie auf dieser Seite!</Typography>
                }
                {this.props.status === "DOWNLOAD" &&
                    <Typography className="pad-left">Fortschritt: {this.props.progress}</Typography>
                }
                {this.props.status === "ERROR" &&
                    <Typography className="pad-left failure">Fehler: {this.props.errorMessage}</Typography>
                }
            </Box>
        );
    }
}
