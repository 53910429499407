import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from '@material-ui/core';

interface IProps {
    isKonsilDeleted: boolean,
    onClose: () => void,
}


export default class DeletedKonsilNotificationContainer extends React.Component<IProps> {
    render () {
        return (
            <Dialog open = {this.props.isKonsilDeleted}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    <Typography>Der Konsilauftrag wurde von einem anderen Client bereits gelöscht. Sie werden automatisch zur Konsil-Übersicht weitergeleitet</Typography>
                    
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant = 'contained'
                        color = 'primary'
                        onClick = {() => this.props.onClose()}
                    >
                        OK
                    </Button>
            </DialogActions>
        </Dialog>
        )
    }
}

