import { EWebSocketActions, TWebSocketActionsTypes } from "../types/websocket_types";

export interface IWebSocketState {
    clientId: string;
}

const initialState: IWebSocketState = {
    clientId: "",
}

export function websocket_reducer(state = initialState, action: TWebSocketActionsTypes): IWebSocketState {
    switch (action.type) {
        case EWebSocketActions.SET_CLIENT_ID:
            return {
                ...state,
                clientId: action.clientId
            }
        default:
            return state
    }
}