import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import uuid from 'uuid';
import { RootState } from '../../redux/reducers';

// material components
import {Box, Button, Paper, Typography, ListItemText, MenuItem, Select, FormControl, Input} from '@material-ui/core'
import SimpleModalContainer from '../elements/simple_modal';


interface IProps {
    showPopup: boolean;
    lanr: string;
    bsnr: string;
    onConfirm: (lanr: string | undefined) => void;
    error: string;
}

const mapStateToProps = (state: RootState, ownProps: IProps) => ({
    userDetails: state.user.user,
    arztList: state.user.betriebsstaettenAerzte,
    ...ownProps
})

const mapDispatchToProps = {}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>


interface IState {
    showPopup: boolean;
    canOpen: boolean;
    lanr: string;
}

export class LanrSelection extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            showPopup: this.props.showPopup,
            canOpen: false,
            lanr: this.props.lanr
        };

        this.closePopup = this.closePopup.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.selectHandleOnOpen = this.selectHandleOnOpen.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<TPropsFromRedux>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.showPopup !== this.props.showPopup){
            this.setState({
                showPopup: this.props.showPopup
            })}   
        if (prevProps.lanr !== this.props.lanr){
            this.setState({
                lanr: this.props.lanr
            })}   
    }

    closePopup() {
        this.setState({
            showPopup: false
        });
    }

    handleChange(event) {
        this.setState({lanr: event.target.value})
    }

    selectHandleOnOpen() {    
        this.setState({canOpen: !this.state.canOpen});  
    }

    render() {
        return (
            <SimpleModalContainer isOpen={this.state.showPopup}>
                <Paper className="modal-wrapper reciever-pop">
                    <Box className="modal-header">
                        <h2>Nutzer wählen</h2>
                        <Typography className="txt-header" variant="subtitle1" color="inherit">
                            Bitte wählen Sie Ihre LANR aus den hinterlegten Ärzten der Betriebsstätte mit der BSNR {this.props.bsnr}.
                        </Typography>
                        <Typography className="txt-header" variant="subtitle1" color="inherit">
                            Sollte Ihre LANR nicht vorhanden sein, fügen Sie sich bitte in den Einstellungen zur Arztliste hinzu.
                        </Typography>
                    </Box>
                    <Box className="modal-content">
                        {this.props.error === "" &&
                        <FormControl variant="outlined">
                            <Select
                                labelId="filter-label"
                                id="mutiple-filter-checkbox"
                                displayEmpty
                                value={this.state.lanr || this.props.arztList[0]?.lanr}
                                onChange={this.handleChange}
                                input={<Input />}
                                onClick={this.selectHandleOnOpen}
                                open={this.state.canOpen}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                            {this.props.arztList.map((arzt) => ( 
                                <MenuItem key={arzt.lanr || uuid.v4()} value={arzt.lanr}>
                                    <ListItemText primary={arzt.lanr + ", "+ [arzt.titel, arzt.vorname, arzt.nachname].join(" ")} />
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                        }
                        {this.props.error !== "" &&
                        <Typography>{this.props.error}</Typography>
                        }
                    </Box>
                    <Box className="modal-footer">
                        <Button variant="text" onClick={this.closePopup}>
                            Abbrechen
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => {this.props.onConfirm(this.state.lanr); this.closePopup()}}>
                            Bestätigen
                        </Button>
                    </Box>
                </Paper>
            </SimpleModalContainer>
        );
    }
}
export const LanrSelectionContainer = connector(LanrSelection);
export default LanrSelectionContainer;