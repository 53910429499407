import {EGeneralActions, TGeneralActionTypes} from '../types/general_types';
import { Failure, HPMRestartStatus, HpmServiceStatusType, UploadPath} from 'telescan-core';

export interface IGeneralState {
    loading: string[],
    loaded: boolean;
    failures: {
        id: string,
        failure: Failure,
    }[];
    successMessages: {
        id: string,
        message: string,
    }[];
    isDesktop?: boolean;
    auth_failure?: string;
    login_failure?: string;
    id?: string;
    isHpmAvailable: boolean;
    hpmServiceStatus: keyof typeof HpmServiceStatusType | null;
    accessTokenExpirationDate: number | null;
    isComfortVersand: boolean;
    comfortVersandPassword: string;
    isHpmConfigChanged: boolean;
    isInitialisationStatusLoading: boolean;
    hasRiskNotification: boolean;
    isSupportEnabled: boolean;
    isPending: boolean;
    hpmRestartStatus: HPMRestartStatus;
    initialisedServices: (keyof typeof UploadPath)[];
    hpmToInitialise: boolean;
    kimToInitialise: boolean;
    isKimEnabled: boolean;
    useSignature: boolean;
}

const initialState: IGeneralState = {
    loading: [],
    loaded: false,
    failures: [],
    successMessages: [],
    isDesktop: true,
    auth_failure: "",
    login_failure: "",
    id: undefined,
    isHpmAvailable: false,
    hpmServiceStatus: null,
    accessTokenExpirationDate: null,
    isComfortVersand: true,
    comfortVersandPassword: "",
    isHpmConfigChanged: false,
    isInitialisationStatusLoading: false,
    hasRiskNotification: false,
    isSupportEnabled: false,
    isPending: false,
    hpmRestartStatus: new HPMRestartStatus(),
    initialisedServices: [],
    hpmToInitialise: false,
    kimToInitialise: false,
    isKimEnabled: false,
    useSignature: true,
}

export function general_reducer(state = initialState, action: TGeneralActionTypes): IGeneralState {
    switch (action.type) {
        case EGeneralActions.LOADING:
            if (action.id)
                return {
                    ...state,
                    loading: [
                        ...state.loading,
                        action.id
                    ]
                }
            else
                return {
                    ...state
                }
        case EGeneralActions.LOADING_SUCCESS:
            return {
                ...state,
                loading: state.loading.filter(id => id !== action.id),
                failures: state.failures.filter(item => item.id !== action.id),
            }

        case EGeneralActions.LOADING_FAILURE:
            return {
                ...state,
                loading: state.loading.filter(id => id !== action.id),
                failures: [
                    ...state.failures,
                    {
                        id: action.id,
                        failure: action.failure,
                    }
                ]
            }

        case EGeneralActions.LOADING_FAILURE_REMOVE:
            return {
                ...state,
                failures: state.failures.filter(item => item.id !== action.id)
            }

        case EGeneralActions.SET_SUCCESS_MESSAGE:
            return {
                ...state,
                successMessages: [
                    ...state.successMessages,
                    {
                        id: action.id,
                        message: action.message
                    }
                ]
            }

        case EGeneralActions.SUCCESS_MESSAGE_REMOVE:
            return {
                ...state,
                successMessages: state.successMessages.filter(item => item.id !== action.id)
            }

        case EGeneralActions.REFRESH_SUCCESS:
            return {
                ...state,
                auth_failure: ""
            }
        case EGeneralActions.REFRESH_FAILURE:
            return {
                ...state,
                auth_failure: action.auth_failure
            }

        case EGeneralActions.SCREENSIZE_CHANGED:
            return {
                ...state,
                isDesktop: action.isDesktop
            }

        case EGeneralActions.SET_IS_HPM_AVAILABLE:
            return {
                ...state,
                isHpmAvailable: action.isHpmAvailable
            }

        case EGeneralActions.SET_HPM_SERVICE_STATUS:
            return {
                ...state,
                hpmServiceStatus: action.hpmServiceStatus
            }

        case EGeneralActions.SET_ACCESS_TOKEN_EXPIRATION:
            return {
                ...state,
                accessTokenExpirationDate: action.currentDate + 3570000,
            }

        case EGeneralActions.SET_IS_COMFORT_VERSAND:
            return {
                ...state,
                isComfortVersand: action.isComfortVersand,
            }

        case EGeneralActions.SET_COMFORT_VERSAND_PASSWORD:
            return {
                ...state,
                comfortVersandPassword: action.comfortVersandPassword,
            }

        case EGeneralActions.SET_IS_HPM_CONFIG_CHANGED:
            return {
                ...state,
                isHpmConfigChanged: action.isHpmConfigChanged
            }

        case EGeneralActions.SET_IS_INITIALISATION_STATUS_LOADING:
            return {
                ...state,
                isInitialisationStatusLoading: action.isInitialisationStatusLoading
            }

        case EGeneralActions.SET_HAS_RISK_NOTIFICATION:
            return {
                ...state,
                hasRiskNotification: action.hasRiskNotification
            }

        case EGeneralActions.SET_IS_SUPPORT_ENABLED:
            return {
                ...state,
                isSupportEnabled: action.isSupportEnabled
            }

        case EGeneralActions.SET_IS_PENDING:
            return {
                ...state,
                isPending: action.isPending
            }

        case EGeneralActions.SET_HPM_RESTART_STATUS:
            return {
                ...state,
                hpmRestartStatus: action.hpmRestartStatus
            }

        case EGeneralActions.SET_INITIALISED_SERVICES:
            return {
                ...state,
                initialisedServices: action.initialisedServices
            }

        case EGeneralActions.SET_HPM_TO_INITIALISE:
            return {
                ...state,
                hpmToInitialise: action.hpmToInitialise
            }

        case EGeneralActions.SET_KIM_TO_INITIALISE:
            return {
                ...state,
                kimToInitialise: action.kimToInitialise
            }

        case EGeneralActions.SET_IS_KIM_ENABLED:
            return {
                ...state,
                isKimEnabled: action.isKimEnabled
            }

        default:
            return state
    }
}
