import { Paper, Typography, Box, Button } from "@material-ui/core";
import React from "react";
import { Patient } from "telescan-core";
import SimpleModalContainer from "../../../elements/simple_modal";
import { ViewPatientDetailComponent } from "../../viewComponents/view_personendatencontent";


interface IProps {
    patient: Patient;
    isOpen: boolean;
    onClose: () => void
}
export class ViewPatientGeneralDataModal extends React.Component<IProps> {
    render() {
        return (
            <SimpleModalContainer isOpen={this.props.isOpen}
                                  onClose={this.props.onClose}
                                  additionalClassname="modal-profile-data"
            >
                <Paper className="modal-wrapper">
                    <h2 id="transition-modal-title" className="modal-header">Patienten Informationen</h2>
                    <Typography variant="caption" color="inherit" className="modal-subheader">
                        Die folgenden Patientendaten wurden aus Ihrem Praxisverwaltungssystem (PVS) übertragen.
                        Bitte nehmen Sie Änderungen an den Patientendaten direkt in Ihrem PVS vor.
                        Eventuell ist es notwendig, einen neuen Konsilauftrag anzulegen, damit die Änderungen wirksam
                        werden.
                    </Typography>
                    <Box className="modal-content">
                        <ViewPatientDetailComponent patient={this.props.patient} role="HAUSARZT"/>
                    </Box>
                    <Box className="modal-footer">
                        <Button variant="text" color="primary" onClick={this.props.onClose}>
                            Schliessen
                        </Button>
                    </Box>
                </Paper>
            </SimpleModalContainer>
        )
    }
}