import React from 'react';
import {
    Box,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@material-ui/core'
import {RootState} from "../../../redux/reducers";
import {connect, ConnectedProps} from "react-redux";
import {BearbeitungsStatusType, Konsil, KonsilStatusType} from 'telescan-core';
import {checkKonsilFeedbackStatus, updateKonsil} from '../../../redux/actions';
import {Qualitaet} from 'telescan-core/lib/entities/konsil_abschluss';


interface IState {
    isOpen: boolean;
    konsil: Konsil;
}

const mapStateToProps = (state: RootState) => ({
    currentKonsil: state.konsile.current_konsil,
    currentKonsilPatient: state.konsile.current_konsil?.konsilPatient, 
    role: state.user.role,
    konsiliarFeedbackErrors: state.evaluation.konsiliarFeedbackErrors,
    arztFeedbackErrors: state.evaluation.konsilFeedbackErrors,
})

const mapDispatchToProps = {
    updateKonsil: (konsil: Konsil) => updateKonsil(konsil),
    dispatch_checkKonsilFeedbackStatus: (konsilabschluss) => checkKonsilFeedbackStatus(konsilabschluss),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class ViewKonsiliarberichtFeedback extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            isOpen: false,
            konsil: this.props.currentKonsil || new Konsil(),
        };

        this.updateKonsil = this.updateKonsil.bind(this);
        this.renderArztColleagueFeedback = this.renderArztColleagueFeedback.bind(this);
        this.renderArztOwnFeedback = this.renderArztOwnFeedback.bind(this);
        this.renderDermaColleagueFeedback = this.renderDermaColleagueFeedback.bind(this);
        this.renderDermaOwnFeedback = this.renderDermaOwnFeedback.bind(this);
};

    getClassNameKonsilQualityFeedback = (): string => {
        if (this.props.currentKonsil.konsilabschluss?.feedbackKonsilqualitaet === "GUT") {
            return "rate-1";
        }           
        else if (this.props.currentKonsil.konsilabschluss?.feedbackKonsilqualitaet === "AUSREICHEND")
            return "rate-2";
        else if (this.props.currentKonsil.konsilabschluss?.feedbackKonsilqualitaet === "UNZUREICHEND")
            return "rate-3";
        else return "";
    }

    getClassNameImageQualityFeedback = (): string => {
        if (this.props.currentKonsil.konsiliarbericht?.feedbackBildqualitaet === "GUT") {
            return "rate-1";
        }
        else if (this.props.currentKonsil.konsiliarbericht?.feedbackBildqualitaet === "AUSREICHEND")
            return "rate-2";
        else if (this.props.currentKonsil.konsiliarbericht?.feedbackBildqualitaet === "UNZUREICHEND")
            return "rate-3";
        else return "";
    }

    getClassNameDataQualityFeedback = (): string => {
        if (this.props.currentKonsil.konsiliarbericht?.feedbackAngabenqualitaet === "GUT") {
            return "rate-1";
        }
        else if (this.props.currentKonsil.konsiliarbericht?.feedbackAngabenqualitaet  === "AUSREICHEND")
            return "rate-2";
        else if (this.props.currentKonsil.konsiliarbericht?.feedbackAngabenqualitaet  === "UNZUREICHEND")
            return "rate-3";
        else return "";
    }

    componentDidUpdate(prevProps: Readonly<TPropsFromRedux>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.currentKonsil !== this.props.currentKonsil) {
            this.setState({
                konsil: this.props.currentKonsil || new Konsil()
            })
        }
    }

    private updateKonsil() {
        this.props.updateKonsil(this.state.konsil);
    }

    renderArztColleagueFeedback(konsilStatus: keyof typeof KonsilStatusType, bearbeitungsStatus: keyof typeof BearbeitungsStatusType | undefined) {
        if (konsilStatus === "BEAUFTRAGT" || (konsilStatus === "IN_ARBEIT" && bearbeitungsStatus === "UPLOAD") || konsilStatus === "RUECKFRAGE" || konsilStatus === "BEANTWORTET")
            return (<></>)
        else
            return (
                <>
                    <Box className="feedback-container">
                        <Typography className="txt-header" variant="subtitle1" color="inherit">
                            Feedback Dermatologe
                        </Typography>
                        <Paper className="pad">
                            <Typography variant="body1" color="inherit">
                                Bildqualität:
                                <Typography className={"txt-rate " + this.getClassNameImageQualityFeedback()}
                                            variant="caption" color="inherit">
                                    {(this.state.konsil.konsiliarbericht?.feedbackBildqualitaet) ? Qualitaet[this.state.konsil.konsiliarbericht?.feedbackBildqualitaet] : "-"}
                                </Typography>
                            </Typography>
                        </Paper>
                        <Paper className="pad">
                            <Typography variant="body1" color="inherit">
                                Qualität anamnestischer Angaben:
                                <Typography className={"txt-rate " + this.getClassNameDataQualityFeedback()}
                                            variant="caption" color="inherit">
                                    {(this.state.konsil.konsiliarbericht?.feedbackAngabenqualitaet) ? Qualitaet[this.state.konsil.konsiliarbericht?.feedbackAngabenqualitaet] : "-"}
                                </Typography>
                            </Typography>
                        </Paper>
                        <Paper className="pad">
                            <Typography variant="body1" color="inherit" style={{whiteSpace: 'pre-line'}}>
                                Weiteres
                                Feedback: {this.state.konsil.konsiliarbericht?.feedbackFreitext || "keine Angabe"}
                            </Typography>

                        </Paper>
                    </Box>
                </>
            )

    }

    renderArztOwnFeedback(konsilStatus: keyof typeof KonsilStatusType) {
        //const arztFeedbackErrors: Map<string, string> = this.props.arztFeedbackErrors.find(item => item.KonsilabschlussID === this.props.currentKonsil.konsilabschluss?.id)?.errors || new Map<string, string>();
        if (konsilStatus === "BEFUNDET")  //(konsilStatus !== "ABGESCHLOSSEN" && konsilStatus !== "ABGEBROCHEN")
            return (
                <Box className="give-feedback-container">
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                        Feedback Hausarzt
                    </Typography>
                    <Box className="feedback-container">
                        <FormControl
                            required
                            className="psoriasis"
                            component="fieldset"
                            error={!this.props.currentKonsil.konsilabschluss?.feedbackKonsilqualitaet}
                            //error={arztFeedbackErrors.has("konsilabschluss.feedbackKonsilqualitaet")}
                        >
                            <FormLabel component="legend">Konsilqualität</FormLabel>
                            <RadioGroup className="pad-top-l" id="konsilabschluss.feedbackKonsilqualitaet" row
                                        aria-label="psoriasis" name="psoriasis"
                                        value={(this.state.konsil.konsilabschluss?.feedbackKonsilqualitaet) ? Qualitaet[this.state.konsil.konsilabschluss?.feedbackKonsilqualitaet] as Qualitaet : ""}
                                        onChange={(event) => {
                                            let qualitaetKonsil = Object.keys(Qualitaet).find(key => Qualitaet[key] === event.target.value) as keyof typeof Qualitaet
                                            this.setState({
                                                konsil: {
                                                    ...this.state.konsil,
                                                    konsilabschluss: {
                                                        ...this.state.konsil.konsilabschluss,
                                                        feedbackKonsilqualitaet: qualitaetKonsil
                                                    }
                                                }
                                            },
                                            () => this.updateKonsil())
                                        }}
                            >
                                {Object.keys(Qualitaet).map(key =>
                                    <FormControlLabel key={key} value={Qualitaet[key]} label={Qualitaet[key]}
                                                      control={<Radio/>}/>
                                )}
                            </RadioGroup>
                        </FormControl>
                        <FormGroup className="feedback">
                            <FormControl component="fieldset" className="feedbacktext">
                                <FormLabel className="full-span pad-top" component="legend">Weiteres
                                    Feedback</FormLabel>
                                <TextField
                                    className="pad-top"
                                    multiline
                                    variant="filled"
                                    minRows = {2}
                                    maxRows={15}
                                    value={this.state.konsil.konsilabschluss?.feedbackFreitext || ""}
                                    onChange={(event) => {
                                        this.setState({
                                            konsil: {
                                                ...this.state.konsil,
                                                konsilabschluss: {
                                                    ...this.state.konsil.konsilabschluss,
                                                    feedbackFreitext: event.target.value
                                                }
                                            }
                                        });
                                    }}
                                    onBlur={() => this.updateKonsil()}
                                />
                            </FormControl>
                        </FormGroup>
                    </Box>
                </Box>
            )
        else if (konsilStatus === "ABGESCHLOSSEN")
            return (
                <Box className="feedback-container">
                     <Typography className="txt-header" variant="subtitle1" color="inherit">
                        Feedback Hausarzt
                    </Typography>
                    <Paper className="pad">
                        <Typography variant="body1" color="inherit">
                            Konsilqualität:
                            <Typography
                                className={"txt-rate " + this.getClassNameKonsilQualityFeedback()}
                                variant="caption"
                                color="inherit"
                            >
                                {(this.state.konsil.konsilabschluss?.feedbackKonsilqualitaet) ? Qualitaet[this.state.konsil.konsilabschluss?.feedbackKonsilqualitaet] : "-"}
                            </Typography>
                        </Typography>
                    </Paper>
                    <Paper className="pad">
                        <Typography variant="body1" color="inherit">
                            Weiteres Feedback: {this.state.konsil.konsilabschluss?.feedbackFreitext || "keine Angabe"}
                        </Typography>
                    </Paper>
                </Box>
            )
        else
            return (<></>)
    }

    renderDermaColleagueFeedback(konsilStatus: keyof typeof KonsilStatusType) {
        if (konsilStatus !== "ABGESCHLOSSEN" && konsilStatus !== "ABGEBROCHEN")
            return (<></>)
        else
            return (
                <>
                    <Box className="feedback-container">
                        <Typography className="txt-header" variant="subtitle1" color="inherit">
                            Feedback Hausarzt
                        </Typography>
                        <Paper className="pad">
                            <Typography variant="body1" color="inherit">
                                Konsilqualität:
                                <Typography
                                    className={"txt-rate " + this.getClassNameKonsilQualityFeedback()}
                                    variant="caption" color="inherit">
                                    {(this.state.konsil.konsilabschluss?.feedbackKonsilqualitaet) ? Qualitaet[this.state.konsil.konsilabschluss?.feedbackKonsilqualitaet] : "-"}
                                </Typography>
                            </Typography>
                        </Paper>
                        <Paper className="pad">
                            <Typography variant="body1" color="inherit"  style={{whiteSpace: 'pre-line'}}>
                                Weiteres
                                Feedback: {this.state.konsil.konsilabschluss?.feedbackFreitext || "keine Angabe"}
                            </Typography>
                        </Paper>
                    </Box>
                </>
            )
    }

    renderDermaOwnFeedback(konsilStatus: keyof typeof KonsilStatusType) {
        if (konsilStatus !== "BEFUNDET" && konsilStatus !== "ABGESCHLOSSEN" && konsilStatus !== "ABGEBROCHEN")
            return (
                <Box className="feedback-container">
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                        Feedback Dermatologe
                    </Typography>
                    <FormControl required className="psoriasis" component="fieldset"
                                 error={this.props.konsiliarFeedbackErrors.has("feedbackDermatologe.qualitaetBild")}
                    >
                        <FormLabel className="float-left" component="legend" focused = {false}>Bildqualität</FormLabel>
                        <RadioGroup className="pad-top-l" row aria-label="psoriasis" name="psoriasis"
                                    id="feedbackDermatologe.qualitaetBild"
                                    value={(this.state.konsil.konsiliarbericht?.feedbackBildqualitaet) ? Qualitaet[this.state.konsil.konsiliarbericht?.feedbackBildqualitaet] as Qualitaet : ""}
                                    onChange={(event) => {
                                        let qualitaetBild = Object.keys(Qualitaet).find(key => Qualitaet[key] === event.target.value) as keyof typeof Qualitaet

                                        this.setState({
                                            konsil: {
                                                ...this.state.konsil,
                                                konsiliarbericht: {
                                                    ...this.state.konsil.konsiliarbericht,
                                                    feedbackBildqualitaet: qualitaetBild,
                                                    // feedbackGeben: this.state.konsil.konsiliarbericht?.feedbackGeben || true
                                                }
                                            }
                                        }, () => setTimeout(this.updateKonsil, 500));
                                    }}
                        >
                            {Object.keys(Qualitaet).map(key =>
                                <FormControlLabel key={key} value={Qualitaet[key]} label={Qualitaet[key]}
                                                  control={<Radio/>}/>
                            )}
                        </RadioGroup>
                    </FormControl>
                    <FormControl required className="psoriasis" component="fieldset"
                                 error={this.props.konsiliarFeedbackErrors.has("feedbackDermatologe.qualitaetAngaben")}
                    >
                        <FormLabel className="float-left" component="legend" focused = {false}>Qualität anamnestischer Angaben</FormLabel>
                        <RadioGroup className="pad-top-l" row aria-label="psoriasis" name="psoriasis"
                                    id="fedbackDermatologe.qualitaetAngaben"
                                    value={(this.state.konsil.konsiliarbericht?.feedbackAngabenqualitaet) ? Qualitaet[this.state.konsil.konsiliarbericht?.feedbackAngabenqualitaet] as Qualitaet : ""}
                                    onChange={(event) => {
                                        let qualitaetAngaben = Object.keys(Qualitaet).find(key => Qualitaet[key] === event.target.value) as keyof typeof Qualitaet

                                        this.setState({
                                            konsil: {
                                                ...this.state.konsil,
                                                konsiliarbericht: {
                                                    ...this.state.konsil.konsiliarbericht,
                                                    feedbackAngabenqualitaet: qualitaetAngaben,
                                                    // feedbackGeben: this.state.konsil.konsiliarbericht?.feedbackGeben ||true
                                                }
                                            }
                                        }, () => setTimeout(this.updateKonsil, 500));
                                    }}
                        >
                            {Object.keys(Qualitaet).map(key =>
                                <FormControlLabel key={key} value={Qualitaet[key]} label={Qualitaet[key]}
                                                  control={<Radio/>}/>
                            )}
                        </RadioGroup>
                    </FormControl>
                    <FormGroup className="antwort">
                        <FormControl component="fieldset" className="antworttext">
                            <FormLabel className="full-span pad-top float-left" component="legend">Weiteres Feedback</FormLabel>
                            <TextField
                                className="pad-top"
                                multiline
                                variant="filled"
                                minRows = {2}
                                maxRows={15}
                                value={this.state.konsil.konsiliarbericht?.feedbackFreitext || ""}
                                onChange={(event) => {
                                    this.setState({
                                        konsil: {
                                            ...this.state.konsil,
                                            konsiliarbericht: {
                                                ...this.state.konsil.konsiliarbericht,
                                                feedbackFreitext: event.target.value,
                                                // feedbackGeben: this.state.konsil.konsiliarbericht?.feedbackGeben || true
                                            }
                                        }
                                    });
                                }}
                                onBlur={() => this.updateKonsil()}
                            />
                        </FormControl>
                    </FormGroup>
                </Box>
            )
        else
            return (
                <Box className="feedback-container">
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                        Feedback Dermatologe
                    </Typography>
                    <Paper className="pad">
                        <Typography variant="body1" color="inherit">
                            Bildqualität:
                            <Typography className={"float-left txt-rate " + this.getClassNameImageQualityFeedback()}
                                        variant="caption" color="inherit">
                                {(this.state.konsil.konsiliarbericht?.feedbackBildqualitaet) ? Qualitaet[this.state.konsil.konsiliarbericht?.feedbackBildqualitaet] : "-"}
                            </Typography>
                        </Typography>
                    </Paper>
                    <Paper className="pad">
                        <Typography variant="body1" color="inherit">
                            Qualität anamnestischer Angaben:
                            <Typography className={"float-left txt-rate " + this.getClassNameDataQualityFeedback()}
                                        variant="caption" color="inherit">
                                {(this.state.konsil.konsiliarbericht?.feedbackAngabenqualitaet) ? Qualitaet[this.state.konsil.konsiliarbericht?.feedbackAngabenqualitaet] : "-"}
                            </Typography>
                        </Typography>
                    </Paper>
                    <Paper className="pad">
                        <Typography variant="body1" color="inherit" className = "float-left"  style={{whiteSpace: 'pre-line'}}>
                            Weiteres Feedback: {this.state.konsil.konsiliarbericht?.feedbackFreitext || "Keine Angabe"}
                        </Typography>
                    </Paper>
                    <Divider/>
                </Box>
            )
    }

    render() {
        let renderColleagueFeedback: (konsilStatus: keyof typeof KonsilStatusType, bearbeitungsstatus?: keyof typeof BearbeitungsStatusType) => void
            = (this.props.role === "HAUSARZT" || this.props.role === "BETRIEBSSTAETTE_HAUSARZT") ? this.renderArztColleagueFeedback : this.renderDermaColleagueFeedback;
        let renderOwnFeedback: (konsilStatus: keyof typeof KonsilStatusType) => void
            = (this.props.role === "HAUSARZT" || this.props.role === "BETRIEBSSTAETTE_HAUSARZT") ? this.renderArztOwnFeedback : this.renderDermaOwnFeedback;

        return (
            <Box className="feedback-view">
                {renderOwnFeedback(this.state.konsil.konsilStatus)}
                {((this.props.role === "HAUSARZT" || this.props.role === "BETRIEBSSTAETTE_HAUSARZT") || this.state.konsil.konsilStatus === "ABGESCHLOSSEN") &&
                renderColleagueFeedback(this.state.konsil.konsilStatus, this.state.konsil.bearbeitungsStatus)
                }
            </Box>
        );
    }
}

export const ViewKonsiliarberichtFeedbackContainer = connector(ViewKonsiliarberichtFeedback);
export default ViewKonsiliarberichtFeedbackContainer;
