import React from 'react';
import uuid from 'uuid';
import { KonsilService, Failure, } from 'telescan-core';


export interface WithPdfCreationProps {
    createPdf: (attachmentKonsilId: string,
    uploadMethod: (file: File, id: string, ...uploadArgs: any) => void, ...uploadArgs: any[]) => void;
}

export function withPdfCreation(Component) {
    type Props = {
        dispatch_loading: (id: string) => void;
        dispatch_loadSuccess: (id: string) => void;
        dispatch_failure: (id: string, failure: Failure) => void;
        uploadFile: (file: File, uploadMethod: (file: File, id: string, ...uploadArgs: any[]) => void, ...uploadArgs: any[]) => void;
    }
    type State = {}

    // ...and returns another component...
    return class extends React.Component<Props, State> {
        constructor(props: Props) {
            super(props);
            this.state = {
            };

            this.createPdf = this.createPdf.bind(this);
        }

        private createPdf(attachmentKonsilId: string, uploadMethod, ...uploadArgs: any) {
            const loadInstanceIdArzt = uuid.v4();
            this.props.dispatch_loading(loadInstanceIdArzt);
            new KonsilService().getPdfv2(attachmentKonsilId).then((response) => {
                this.props.dispatch_loadSuccess(loadInstanceIdArzt);
                let attachmentKonsil = response;
                attachmentKonsil.name = "Altes Konsil mit der ID " + attachmentKonsilId;
                this.props.uploadFile(attachmentKonsil, uploadMethod, [], ...uploadArgs); // here, we dont really need the dimension array
            }).catch((error) => {
                this.props.dispatch_failure(loadInstanceIdArzt, error);
            })
        }

        render() {
            return (
                <Component 
                    createPdf={this.createPdf}
                    {...(this.props as any)}
                />
            )
        }
    };
}