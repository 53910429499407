import { Box, Checkbox, Typography } from '@material-ui/core'
import React from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { CertTypes, KIMConnector, KIMContext, KIMGeneral, KIMUser, TIConnectorEvent } from 'telescan-core';
import { RootState } from '../../../redux/reducers';
import { checkKimSettings, setKimToInitialise } from '../../../redux/actions';
import { getKimSettings, getTiConnectionStatus, registerEHBA, sendKimSettings, setIsTestTiConnectionClicked, setTiConnectorEvent, uploadCertificates } from '../../../redux/actions/kim_actions';
import { SignatureDialogContainer } from '../../settings/kim_configuration/signature_dialog';
import { isEmptyObject } from '../../../utils';
import EditSettings from '../../settings/kim_configuration/edit_settings';
import { cancelSignKonsil, getKimMailInLdapStatus } from '../../../redux/actions/ti_connector_actions';

interface IProps {
    kimGeneral: KIMGeneral;
    kimUser: KIMUser;
}

interface IState {
    kimGeneral: KIMGeneral;
    kimUser: KIMUser;
    certToUpload: Blob[];
    kimToInitialise: boolean;
    isSignatureDialogOpen: boolean;
}

const mapStateToProps = (state: RootState) => ({
    kimGeneral: state.kim.kimGeneral,
    kimUser: state.kim.kimUser,
    initialisedServices: state.general.initialisedServices,
    kimToInitialise: state.general.kimToInitialise,
    tiConnectorEvent: state.kim.tiKonnectorEvent,
    isTiConnected: state.kim.isTiConnected,
    isTiStatusLoading: state.kim.isTiStatusLoading,
    useSignature: state.general.useSignature,
    role: state.user.role,
    user: state.user.user,
    isKimMailInLdap: state.ti_connector.isKimMailInLdap,
    isLdapRequest: state.ti_connector.isLdapRequest,
    isTestTiConnectorClicked: state.kim.isTestTiConnectionClicked,
})

const mapDispatchToProps = {
    dispatch_setKimToInitialise: (kimToInitialise: boolean) => setKimToInitialise(kimToInitialise),
    dispatch_checkKimSettings : (kimGeneral: KIMGeneral, kimUser: KIMUser) => checkKimSettings(kimGeneral, kimUser),
    dispatch_sendKimSettings: (kimGeneral: KIMGeneral, kimUser: KIMUser) => sendKimSettings(kimGeneral, kimUser),
    dispatch_getKimSettings: () => getKimSettings(),
    dispatch_uploadCertificates: (certToUpload: Blob[], connectorId: string, certPassword: string) => uploadCertificates(certToUpload, connectorId, certPassword),
    dispatch_registerEHBA: () => registerEHBA(),
    dispatch_setTiConnectorEvent: (tiConnectorEvent: TIConnectorEvent) => setTiConnectorEvent(tiConnectorEvent),
    dispatch_getTiConnectionStatus: () => getTiConnectionStatus(),
    dispatch_getKimMailInLdapStatus: (kimMail: string) => getKimMailInLdapStatus(kimMail),
    dispatch_cancelSignKonsil: () => cancelSignKonsil(),
    dispatch_setIsTestTiConnectionClicked: (isClicked: boolean) => setIsTestTiConnectionClicked(isClicked),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class KimConfigurationInit extends React.Component<TPropsFromRedux & IProps, IState> {
    constructor(props: TPropsFromRedux & IProps) {
        super(props);
        this.state = {
            kimGeneral: new KIMGeneral(),
            kimUser: new KIMUser(),
            certToUpload: [],
            kimToInitialise: false,
            isSignatureDialogOpen: false,
        }
    }

    componentDidMount() {
        //if (this.props.kimToInitialise) {
            this.props.dispatch_getKimSettings();
        //}

        this.setState({
            kimToInitialise: this.props.kimToInitialise,
            kimGeneral: this.props.kimGeneral,
            kimUser: this.props.kimUser
        });
    }

    componentDidUpdate (prevProps: TPropsFromRedux & IProps) {
        if (prevProps.kimGeneral !== this.props.kimGeneral) {
            this.setState({
                kimGeneral: this.props.kimGeneral,
            })
        }

        if (prevProps.kimUser !== this.props.kimUser) {
            this.setState({
                kimUser: this.props.kimUser
            })
        }

        if (prevProps.kimToInitialise !== this.props.kimToInitialise) {
            this.setState({kimToInitialise: this.props.kimToInitialise});
        }
    }

    handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({kimToInitialise: event.target.checked});
        this.props.dispatch_setKimToInitialise(event.target.checked);
        if (event.target.checked && isEmptyObject(this.props.kimGeneral) && isEmptyObject(this.props.kimUser)) {
            this.props.dispatch_sendKimSettings(this.initialiseNewSettings(), new KIMUser()) // to get id from BE
        }
    }

    initialiseNewSettings = (): KIMGeneral => {
        const kimGeneral = new KIMGeneral();
        const kimConnectorList: KIMConnector[] = [];
        const kimContextList: KIMContext[] = [];
        const kimConnector = new KIMConnector();
        kimConnector.ldapPort = "636";
        kimConnector.isCurrent = true;
        const kimContext = new KIMContext();
        kimContext.isCurrent = true;
        kimConnectorList.push(kimConnector);
        kimContextList.push(kimContext);
        kimGeneral.kimConnectorList = kimConnectorList;
        kimGeneral.kimContextList = kimContextList;
        return kimGeneral;
    }

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            kimGeneral: {
                ...this.state.kimGeneral,
                [event.target.name]: event.target.value
            },
            kimUser: {
                ...this.state.kimUser,
                [event.target.name]: event.target.value,
                isSignatureInitialised: event.target.name === "iccsn" ? false : this.state.kimUser.isSignatureInitialised
            },
        })
    }

    handleInputChangeConnector = (event: React.ChangeEvent<HTMLInputElement>, elementId: string) => {
        if (elementId === "")
            return;
        let kimConnectorList: KIMConnector[];
        if (this.state.kimGeneral.kimConnectorList && this.state.kimGeneral.kimConnectorList.length !== 0) {
            kimConnectorList = this.state.kimGeneral.kimConnectorList;
            kimConnectorList.forEach(item => {
                if (item.id === elementId) {
                    if (event.target.name === "certificatePassword") {
                        item.clientCertificate = false;
                    }
                    if (event.target.type === "checkbox") {
                        item[event.target.name] = event.target.checked;
                    } else
                         item[event.target.name] = event.target.value;
                } else {
                    if (event.target.type === "checkbox")
                        item[event.target.name] = false;
                }
            })
        } else {
            kimConnectorList = [];
            const kimConnector = new KIMConnector();
            if (event.target.type === "checkbox") {
                kimConnector[event.target.name] = event.target.checked;
            } else
                kimConnector[event.target.name] = event.target.value;
            kimConnectorList.push(kimConnector);
        }
        this.setState({
            kimGeneral: {
                ...this.state.kimGeneral,
                kimConnectorList: kimConnectorList
            }
        })
    }

    handleInputChangeContext = (event: React.ChangeEvent<HTMLInputElement>, elementId: string) => {
        if (elementId === "")
            return;
        let kimContextList: KIMContext[];

        if (this.state.kimGeneral.kimContextList && this.state.kimGeneral.kimContextList.length !== 0) {
            kimContextList = this.state.kimGeneral.kimContextList;
            kimContextList.forEach(item => {
                if (item.id === elementId) {
                    if (event.target.type === "checkbox") {
                        item[event.target.name] = event.target.checked;
                    } else
                         item[event.target.name] = event.target.value;
                } else {
                    if (event.target.type === "checkbox")
                        item[event.target.name] = false;
                }
            })
        } else {
            kimContextList = [];
            const kimContext = new KIMContext();
            if (event.target.type === "checkbox") {
                kimContext[event.target.name] = event.target.checked;
            } else
                kimContext[event.target.name] = event.target.value;
            kimContextList.push(kimContext);
        }
        this.setState({
            kimGeneral: {
                ...this.state.kimGeneral,
                kimContextList: kimContextList
            }
        })
    }

    addCertForUpload = (cert: Blob[]) => {
        if (cert.length !== 0) {
            const newCerts = this.state.certToUpload.filter(item => item.type !== cert[0].type);
            newCerts.push(cert[0]);
            this.setState({certToUpload: newCerts});
        }

    }

    removeCertForUpload = (cert: Blob) => {
            const newCerts = this.state.certToUpload.filter(item => item.type !== cert.type);
            this.setState({certToUpload: newCerts});
    }

    handleUploadCertButton = (item: KIMConnector) => {
        this.props.dispatch_uploadCertificates(this.state.certToUpload, item.id || "", item.certificatePassword || "");
    }

    uploadButtonDisabled = (connector: KIMConnector) => {
        // if (this.state.certToUpload.length !== 2 || !connector.certificatePassword || connector.certificatePassword === "")
        //     return true;
        // return false;
        if (this.state.certToUpload.length === 0)
            return true;
        if (this.state.certToUpload.some(item => item.type === CertTypes.CLIENT) && (!connector.certificatePassword || connector.certificatePassword === ""))
            return true;
    }

    handleSignatureDialogOpen = () => {
        this.props.dispatch_setTiConnectorEvent(new TIConnectorEvent());
        this.setState({isSignatureDialogOpen: true});
        this.props.dispatch_registerEHBA();
    }

    handleSignatureDialogClose = () => {
        this.setState({isSignatureDialogOpen: false});
        this.props.dispatch_getKimSettings();
        //this.props.dispatch_setTiConnectorEvent(new TIConnectorEvent());
    }

    render() {
        return (
            <Box className="main-content initialisation-content">
                {(this.props.role === "HAUSARZT" || this.props.role === "BETRIEBSSTAETTE_HAUSARZT") &&
                    <Typography className="bs-doctors-description" variant="caption" color="inherit">
                        Es muss mindestens eine Versandsweise eingerichtet werden. Entweder: Versand über HÄVG Prüfmodul (hierfür ist eine Integration von TeleScan in das PVS Voraussetzung). Oder: Versand über die Telematik-Infrastruktur als KIM-Nachricht.
                    </Typography>
                }
                <Typography className="bs-doctors-description" variant="caption" color="inherit">
                    Für die Einrichtung des Telekonsilversands über die Telematik Infrastruktur ist ein vollständig eingerichtetes KIM-Clientmodul, sowie ein vollständig konfigurierter TI-Konnektor notwendig. Zudem benötigen Sie eine eigenständige, nicht andersweitig verwendete KIM-Adresse.
                </Typography>
                <Box className = "flex-row-end">
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                            Möchten Sie das KIM-Modul einrichten?
                    </Typography>
                    <Checkbox
                        checked = {this.state.kimToInitialise}
                        color="primary"
                        onChange={this.handleSwitchChange}
                    />
                </Box>
                {this.state.kimToInitialise &&
                   <EditSettings
                        kimGeneral = {this.props.kimGeneral}
                        kimUser = {this.props.kimUser}
                        dispatch_uploadCertificates = {this.props.dispatch_uploadCertificates}
                        dispatch_sendKimSettings = {this.props.dispatch_sendKimSettings}
                        dispatch_registerEHBA = {this.props.dispatch_registerEHBA}
                        dispatch_setTiConnectorEvent = {this.props.dispatch_setTiConnectorEvent}
                        tiConnectorEvent = {this.props.tiConnectorEvent}
                        dispatch_getKimSettings = {this.props.dispatch_getKimSettings}
                        isSignatureDialogOpen = {this.state.isSignatureDialogOpen}
                        handleSignatureDialogOpen = {this.handleSignatureDialogOpen}
                        handleSignatureDialogClose = {this.handleSignatureDialogClose}
                        isTiConnected = {this.props.isTiConnected}
                        dispatch_getTiConnectionStatus = {this.props.dispatch_getTiConnectionStatus}
                        isTiStatusLoading = {this.props.isTiStatusLoading}
                        dispatch_getKimMailInLdapStatus = {this.props.dispatch_getKimMailInLdapStatus}
                        isKimMailInLdap = {this.props.isKimMailInLdap}
                        isLdapRequest = {this.props.isLdapRequest}
                        isTestTiConnectionClicked = {this.props.isTestTiConnectorClicked}
                        dispatch_setIsTestTiConnectionClicked = {this.props.dispatch_setIsTestTiConnectionClicked}
                        user = {this.props.user}
                   />
                }
                <SignatureDialogContainer
                    isOpen = {this.state.isSignatureDialogOpen}
                    handleClose = {this.handleSignatureDialogClose}
                    tiConnectorEvent = {this.props.tiConnectorEvent}
                    forSettings = {true}
                />
            </Box>
        )
    }
}

export const KimConfigurationInitContainer = connector(KimConfigurationInit);
export default KimConfigurationInitContainer;
