import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";
import { HighlightOff, NavigateBefore, NavigateNext } from "@material-ui/icons";
import React from "react";
import { Notification, NotificationType } from "telescan-core";

interface IProps {
    allNotifications: Notification[],
    isAllNotifications: boolean,
    dispatch_setIsAllNotifications: (isAllAnotifications: boolean) => void,
    isNotificationsLoading: boolean,
}
interface IState {
    notificationNumber: number;
    currentNotification: Notification;
}

export class AllNotificationsPopup extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            notificationNumber: 0,
            currentNotification: new Notification(),
        }
    }

    componentDidMount() {
        this.setState({currentNotification: this.props.allNotifications[this.state.notificationNumber]});
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (prevProps.allNotifications !== this.props.allNotifications) {
            this.setState({currentNotification: this.props.allNotifications[this.state.notificationNumber]});
        }
    }

    onNextNotificationClick = () => {
        this.setState(prevState => 
            {
                return {
                    notificationNumber: prevState.notificationNumber + 1
                }            
            },
            () => this.setState(
                {currentNotification: this.props.allNotifications[this.state.notificationNumber]},
                //() => !this.state.currentNotification.isNotificationDisplayed && this.props.dispatch_sendNotification(this.state.currentNotification.id || "", NotificationActionType.DISPLAYED)
            )
        )
    }

    onPreviousNotificationClick = () => {
        this.setState(prevState => 
            {
                return {
                    notificationNumber: prevState.notificationNumber - 1
                }            
            },
            () => this.setState({currentNotification: this.props.allNotifications[this.state.notificationNumber]})
        )
    }

    render() {
        let isExist: boolean;
        if (this.state.currentNotification) isExist = true;
        else isExist = false;
        return (
            <Dialog
                open = {this.props.isAllNotifications}
                fullWidth = {true}
            >                          
                <Box>
                    <DialogTitle>
                    {this.props.isNotificationsLoading ?
                    <Typography>Wird geladen…</Typography> :
                    <Box className = 'flex-row-center justify-content-space-between'>
                        {isExist &&
                        (this.state.currentNotification.notificationVersion && parseInt(this.state.currentNotification.notificationVersion) > 1 ?
                            <Typography variant = 'h5'>
                                {`${this.state.currentNotification.notificationType && NotificationType[this.state.currentNotification.notificationType]} (Aktualisierung)`}
                            </Typography> :
                            <Typography variant = 'h5'>
                                {this.state.currentNotification.notificationType && NotificationType[this.state.currentNotification.notificationType]}
                            </Typography>)                  
                        }
                        <IconButton
                            onClick={() => {
                                this.props.dispatch_setIsAllNotifications(false);
                            }}
                        >
                            <HighlightOff/>
                        </IconButton>
                    </Box>
                    }                        
                    </DialogTitle>
                    <DialogContent className = {`notification-content ${this.props.isNotificationsLoading && 'overflow-hidden'}`}>
                        {this.props.isNotificationsLoading ?
                            <CircularProgress/> :
                            this.props.allNotifications.length === 0 ?
                                <Typography className = 'flex-row justify-content-center'>Keine aktuelle Benachrichgungen sind vorhanden</Typography> :                          
                                <>
                                    <Typography>{this.state.currentNotification && this.state.currentNotification.publisher && this.state.currentNotification.publisher}</Typography>
                                    <Typography>{this.state.currentNotification && this.state.currentNotification.affectedTime && this.state.currentNotification.affectedTime}</Typography>
                                    <Typography>{this.state.currentNotification && this.state.currentNotification.affectedVersionServerAsString && this.state.currentNotification.affectedVersionServerAsString}</Typography>                     
                                    <iframe
                                        className = "notification-iframe"
                                        title = "notification-popup"
                                        srcDoc = {this.state.currentNotification && this.state.currentNotification.htmlAsString}
                                        sandbox = "allow-scripts"
                                        frameBorder = '0'
                                    />
                                </>
                        }

                    </DialogContent>
                    <DialogActions className = 'dialog-action'>
                        <Box className = 'flex-row-center justify-content-center'>
                            <IconButton
                                aria-label="previous"
                                className="img-prev"
                                disabled={this.state.notificationNumber <= 0}
                                onClick={this.onPreviousNotificationClick}
                                size = 'small'
                            >
                                <NavigateBefore fontSize="large" />
                            </IconButton>
                            <Typography>{`${this.props.allNotifications.length === 0 ? 0 :this.state.notificationNumber + 1}/${this.props.allNotifications.length}`}</Typography>
                            <IconButton
                                aria-label="previous"
                                className="img-prev"
                                disabled={this.state.notificationNumber >= this.props.allNotifications.length - 1}
                                onClick={this.onNextNotificationClick}
                                size = 'small'
                            >
                                <NavigateNext fontSize="large" />
                            </IconButton>
                        </Box>
                        {/* <Box className = 'flex-row-column-gap'>
                            {this.state.currentNotification && this.state.currentNotification.notificationType === Object.keys(NotificationType).filter(key => key === "WARNING")[0] &&
                                <Button
                                    variant = 'contained'
                                    color = 'primary'
                                    onClick = {this.onNotificationConfirm}
                                    size = 'small'
                                    disabled = {this.state.currentNotification.isNotificationConfirmed}
                                >
                                    Bestätigen
                                </Button>
                            }
                            <Button
                                variant = {this.state.currentNotification && this.state.currentNotification.notificationType === Object.keys(NotificationType).filter(key => key === "WARNING")[0] ? 'text' : 'contained'}
                                color = 'primary'
                                onClick = {this.onLogout}
                                size = 'small'
                            >
                                LOGOUT
                            </Button>
                            {this.state.currentNotification && this.state.currentNotification.notificationType === Object.keys(NotificationType).filter(key => key === "RISK")[0] &&
                                <Button
                                    variant = 'text'
                                    color = 'primary'
                                    onClick = {() => this.props.dispatch_navigate("/settings")}
                                >
                                    Einstellungen
                                </Button>
                            }                                
                        </Box> */}
                    </DialogActions> 
                </Box>         
            </Dialog>
        )
    }
}