import React from 'react';
import uuid from 'uuid';
import { HPMService, Failure } from 'telescan-core';


export interface WithHpmInstallMethodsProps {
    isInstallingHpm: boolean;
    handleClickInstallHpm: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    setInstallingHpmFalse: () => void;
    handleClickUpdateHpm: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    handleCheckHpmInstallation: ()=> void;
    isCheckedHPM: boolean;
    hpmVersion: string;
    hpmStatus: string;
}

export function withHpmInstallMethods(Component) {
    type Props = {
        dispatch_failure: (id: string, failure: Failure) => void;
        dispatch_updateModul: (modulName: string) => Promise<any>;
    }
    type State = {
        isInstallingHpm: boolean;
        isCheckedHPM: boolean;
        hpmVersion: string;
        hpmStatus: string;
    }

    // ...and returns another component...
    return class extends React.Component<Props, State> {
        constructor(props: Props) {
            super(props);
            this.state = {
                isInstallingHpm: false,
                isCheckedHPM: false,
                hpmVersion: "",
                hpmStatus: "",
            };

            this.handleClickInstallHpm = this.handleClickInstallHpm.bind(this);
            this.handleClickUpdateHpm = this.handleClickUpdateHpm.bind(this);
            this.handleCheckHpmInstallation = this.handleCheckHpmInstallation.bind(this);
            this.setInstallingHpmFalse = this.setInstallingHpmFalse.bind(this);
        }


        handleClickInstallHpm(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
            event.preventDefault();
            event.stopPropagation();
            this.props.dispatch_updateModul("hpm").then(() => 
                this.setState({
                    isInstallingHpm: true
                })
            );
        }

        setInstallingHpmFalse() {
            this.setState({
                isInstallingHpm: false
            })
        }

        handleClickUpdateHpm(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
            event.preventDefault();
            event.stopPropagation();
            this.handleClickInstallHpm(event);
        }

        handleCheckHpmInstallation() {
            const loadInstanceId: string = uuid.v4();
            const hpmService: HPMService = new HPMService();
            this.setState({
                isCheckedHPM: false,
                hpmStatus: "checking"
            }, () =>
            hpmService.getHPMInstallationStatus().then((response) => {
                this.setState({
                    isCheckedHPM: true,
                    hpmVersion: response.version,
                    hpmStatus: response.status
                })
            }).catch((error) => {
                this.props.dispatch_failure(loadInstanceId, error);
                this.setState({
                    isCheckedHPM: true,
                })
            }))
        }

        render() {
            return (
                <Component 
                    isInstallingHpm={this.state.isInstallingHpm}
                    handleClickInstallHpm={this.handleClickInstallHpm}
                    setInstallingHpmFalse={this.setInstallingHpmFalse}
                    handleClickUpdateHpm={this.handleClickUpdateHpm}
                    handleCheckHpmInstallation={this.handleCheckHpmInstallation}
                    isCheckedHPM={this.state.isCheckedHPM}
                    hpmVersion={this.state.hpmVersion}
                    hpmStatus={this.state.hpmStatus}
                    {...(this.props as any)}
                />
            )
        }
    };
}