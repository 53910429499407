import React from 'react';
import { Fade, Modal, Backdrop } from '@material-ui/core';


interface IProps {
    isOpen: boolean;
    onClose?: (event?: any) => void;
    additionalClassname?: string;
}


export default class SimpleModalContainer extends React.Component<IProps> {


    render() {
        let childComponent = this.props.children as unknown as JSX.Element

        return(
            <Modal
                open={this.props.isOpen}
                onClose={this.props.onClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={"modal " + this.props.additionalClassname}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={this.props.isOpen}>
                    {childComponent}
                </Fade>
            </Modal>
        )
    }
}
