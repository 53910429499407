import { GdtConfiguration, Patient } from 'telescan-core';
export enum EGdtActions {
    SET_GDT_CONFIGURATION = "SET_GDT_CONFIGURATION",
    SET_GDT_PATIENTS = "SET_GDT_PATIENTS",
}

export interface ISetGdtConfiguration {
    readonly type: EGdtActions.SET_GDT_CONFIGURATION;
    readonly gdtConfig: GdtConfiguration; 
}

export interface ISetGdtPatients {
    readonly type: EGdtActions.SET_GDT_PATIENTS;
    readonly gdtPatients: Patient[];
}



export type TGdtActionTypes = 
    ISetGdtConfiguration |
    ISetGdtPatients;