import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';
import uuid from 'uuid';

import { ZertifikatService, ZertifikatMetainformation } from "telescan-core";
import {RootState} from '../reducers';
import { failure } from './general_actions';
import * as ZertifikatTypes from '../types/zertifikat_types';



export function loadZertifikate(): ThunkAction<Promise<{arztZertifikate: ZertifikatMetainformation[], betriebsstaettenZertifikat: ZertifikatMetainformation}>, RootState, unknown, Action<string>> {
    return dispatch => {
        const loadInstanceId: string = uuid.v4();
        //dispatch(loading(loadInstanceId));

        const zertifikatService: ZertifikatService = new ZertifikatService();
        return new Promise((resolve, reject) => {
            zertifikatService.query().then( //"arztZertifikat"
                (zertifikate) => {
                    //dispatch(loadSuccess(loadInstanceId));
                    dispatch(setZertifikateMetaInformation(zertifikate))
                    resolve(zertifikate);
                }
            ).catch(
                (response) => {
                    dispatch(failure(loadInstanceId, response));
                    reject(response);
                }
            );
        })
    }
}

export function setZertifikateMetaInformation(info: {arztZertifikate: ZertifikatMetainformation[], betriebsstaettenZertifikat: ZertifikatMetainformation}): ZertifikatTypes.ISetZertifikateMetaInfoAction {
    return {
        type: ZertifikatTypes.EZertifikatActions.SET_ZERTIFIKATE_METAINFO,
        arztZertifikateMetaInfo: info.arztZertifikate,
        betriebsstaettenZertifikatMetaInfo: info.betriebsstaettenZertifikat
    }
}

export function getZertifikatStatus(): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        const loadInstanceId: string = uuid.v4();
        const zertifikatService: ZertifikatService = new ZertifikatService();
        zertifikatService.getZertifikatStatus()
            .then(
                (zertifikatStatus) => {
                    zertifikatStatus && dispatch(setZertifikatStatus(zertifikatStatus))
                }).catch(
            (response) => {
                dispatch(failure(loadInstanceId, response));
            }
        );
    };
}

export function setZertifikatStatus(zertifikatStatus: any): ZertifikatTypes.ISetZertifikatStatusAction {
    return {
        type: ZertifikatTypes.EZertifikatActions.SET_ZERTIFIKAT_STATUS,
        zertifikatStatus: zertifikatStatus
    }
}
