import React from 'react';
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
} from '@material-ui/core'
import {RootState} from "../../../redux/reducers";
import {connect, ConnectedProps} from "react-redux";
import {ICDService, Konsil} from 'telescan-core';
import {Add} from '@material-ui/icons';
import {updateKonsil} from '../../../redux/actions';
import {Diagnose, DiagnoseKonfidenz, TherapieDringlichkeit} from 'telescan-core/lib/entities/konsil_abschluss';
import { KrankheitDermatologeComponent, KrankheitDermatologeEditComponentContainer } from './krankheit_dermatologe';


const mapStateToProps = (state: RootState) => ({
    role: state.user.role,
    currentKonsil: state.konsile.current_konsil,
    currentKonsilPatient: state.konsile.current_konsil?.konsilPatient,
    konsiliarberichtErrors: state.evaluation.konsiliarberichtErrors
})

const mapDispatchToProps = {
    updateKonsil: (konsil: Konsil) => updateKonsil(konsil),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

interface IState {
    konsil: Konsil;
    addDiagnose: boolean;
    editDiagnose: boolean;
    diagnose: Diagnose | null;
    icd10Data: {
        kapitelData: string[],
        kapitelVersion: string,
        kapitelYear: string,
        oid: string,
        isIcd10Loading: boolean,
        loadingError: string,
        krankheitBeginnYear: string,
    };
}

export class EditKonsiliarBerichtContent extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            konsil: this.props.currentKonsil || new Konsil(),
            addDiagnose: false,
            editDiagnose: false,
            diagnose: null,
            icd10Data: {
                kapitelData: [],
                kapitelVersion: "",
                kapitelYear: "",
                oid: "",
                isIcd10Loading: false,
                loadingError: "",
                krankheitBeginnYear: "",
            },
        };

        this.openDiagnosePopup = this.openDiagnosePopup.bind(this);
        this.deleteDiagnose = this.deleteDiagnose.bind(this);
        this.updateKonsil = this.updateKonsil.bind(this);
        this.isEmptyDiagnose = this.isEmptyDiagnose.bind(this);
        this.getIcd10Katalog = this.getIcd10Katalog.bind(this);
        this.clearIcd10KatalogLoadingError = this.clearIcd10KatalogLoadingError.bind(this);
    }

    private saveDiagnose: Diagnose[] | undefined = []

    componentDidUpdate(prevProps) {
        if (prevProps.currentKonsil !== this.props.currentKonsil) {
            this.setState({
                konsil: this.props.currentKonsil || new Konsil()
            });
        }
    }

    openDiagnosePopup() {
        this.setState({
            addDiagnose: true,
            editDiagnose: true,
            diagnose: new Diagnose()
        })
    }

    deleteDiagnose(index: number) {
        let diagnosen: Diagnose[] = (this.state.konsil?.konsiliarbericht?.diagnose) ? [...this.state.konsil?.konsiliarbericht?.diagnose] : [];
        diagnosen.splice(index, 1)
        this.setState({
            konsil: {
                ...this.state.konsil,
                konsiliarbericht: {
                    ...this.state.konsil.konsiliarbericht,
                    diagnose: diagnosen
                }
            }
        }, this.updateKonsil)
    }


    private updateKonsil() {
        this.props.updateKonsil(this.state.konsil);
    }

    isEmptyDiagnose(diagnose: Diagnose) {
        return (
            (diagnose?.diagnoseCode === "" || diagnose?.diagnoseCode == null) &&
            (diagnose?.diagnoseAnzeigenname === "" || diagnose?.diagnoseAnzeigenname == null) &&
            (diagnose?.diagnoseSicherheit == null) &&
            diagnose?.seitenlokalisation == null
        )
    }

    getIcd10Katalog(date?: number) {
        const year =
            date ?
                new Date(date).getFullYear().toString() :
                new Date().getFullYear().toString();
        this.setState({
            icd10Data: {
                ...this.state.icd10Data,
                isIcd10Loading: true,
                krankheitBeginnYear: year,
            }
        })
        const prevIcd10 = this.state.icd10Data;

        const icdService = new ICDService();
        this.state.icd10Data.kapitelYear !== year || this.state.icd10Data.loadingError !== "" ?
            icdService.getIcd10(date || new Date().getTime()).then(responce => {
                let parsedRes = JSON.parse(responce);
                const year = parsedRes.version.split(" ")[0];
                this.setState({
                    icd10Data: {
                        ...this.state.icd10Data,
                        kapitelData: parsedRes.kapitel,
                        kapitelVersion: parsedRes.version,
                        kapitelYear: year,
                        oid: parsedRes.oid,
                        isIcd10Loading: false,
                        loadingError: "",
                    },
                });
            })
                .catch(error => {
                    this.setState({
                        icd10Data: {
                            ...this.state.icd10Data,
                            isIcd10Loading: false,
                            loadingError: error.message,
                            kapitelYear: "",
                        },
                    })
                    console.log(error);
                }) :
            this.setState({
                icd10Data: prevIcd10,
            })

    }

    clearIcd10KatalogLoadingError() {
        this.setState({
            icd10Data: {
                ...this.state.icd10Data,
                loadingError: "",
            }
        })
    }

    render() {
        return (
            <form className="bericht-data-container derma-version">
                <FormControl required component="fieldset" className="beschreibung"
                             error={this.props.konsiliarberichtErrors.has("konsiliarbericht.befund")}>
                    <FormLabel className="bold-label full-span float-left" component="legend">Befundbeschreibung</FormLabel>
                    <TextField
                        id="konsiliarbericht.befund"
                        className="pad-top"
                        multiline
                        variant="filled"
                        minRows = {4}
                        maxRows={15}
                        value={this.state.konsil.konsiliarbericht?.befund || ""}
                        onChange={(event) => {
                            this.setState({
                                konsil: {
                                    ...this.state.konsil,
                                    konsiliarbericht: {
                                        ...this.state.konsil.konsiliarbericht,
                                        befund: event.target.value
                                    }
                                }
                            });
                        }}
                        onBlur={() => this.updateKonsil()}
                    />
                </FormControl>
                <Divider/>

                <Box className="diag-label">
                    <FormControl required className="diagnose" component="fieldset"
                                 error={this.props.konsiliarberichtErrors.has("konsiliarbericht.diagnosen")}
                    >
                        <FormLabel className="bold-label float-left" component="legend">Diagnose(n)</FormLabel>
                    </FormControl>
                    <Tooltip title="Diagnose hinzufügen" placement="left">
                        <Button className="slim add-diagnose"
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={() => {
                                    this.setState({
                                        addDiagnose: true,
                                        editDiagnose: true,
                                        diagnose: new Diagnose()
                                    })
                                    this.getIcd10Katalog() 
                                }}
                        >
                            <Add/>
                        </Button>
                    </Tooltip>
                </Box>
                {(this.state.konsil.konsiliarbericht?.diagnose && this.state.konsil.konsiliarbericht?.diagnose.length > 0) && this.state.konsil.konsiliarbericht?.diagnose.map((diagnoseItem, index) => {
                    return (
                        <KrankheitDermatologeComponent key={diagnoseItem.id || ""}
                                            diagnose={diagnoseItem}
                                            onEditClick={() => {
                                                this.setState({
                                                    addDiagnose: false,
                                                    editDiagnose: true,
                                                    diagnose: diagnoseItem
                                                })
                                                this.getIcd10Katalog()
                                            }}
                                            delete={(k) => this.deleteDiagnose(index)}
                                            hasError={false}
                                            isViewOnly={false}
                                            role = {this.props.role}
                        />
                    )
                })}
                <KrankheitDermatologeEditComponentContainer
                    krankheit={this.state.diagnose || new Diagnose()}
                    open={this.state.editDiagnose}
                    add={this.state.addDiagnose}
                    callback={(krankheit, add) => {
                        if (krankheit === null) {
                            this.setState({
                                addDiagnose: false,
                                editDiagnose: false
                            });
                            return;
                        }
                        const diagnosen: Diagnose[] = this.state.konsil.konsiliarbericht?.diagnose || [];
                        if (add)
                            diagnosen.push(krankheit);
                        else if (this.state.diagnose)
                            diagnosen[diagnosen.indexOf(this.state.diagnose)] = krankheit

                        this.setState({
                                konsil: {
                                    ...this.state.konsil,
                                    konsiliarbericht: {
                                        ...this.state.konsil.konsiliarbericht,
                                        diagnose: diagnosen,
                                        keineDiagnose: false
                                    }
                                },
                                addDiagnose: false,
                                editDiagnose: false
                            },
                            this.updateKonsil)
                    }}
                    icd10Data={this.state.icd10Data}
                    clearIcd10KatalogLoadingError={this.clearIcd10KatalogLoadingError}
                />
                <Divider/>
                <FormControl required className="diagnosekonfidenz" component="fieldset"
                             error={this.props.konsiliarberichtErrors.has("konsiliarbericht.diagnoseKonfidenz")}>
                    <FormLabel component="legend" className="bold-label float-left">Diagnosekonfidenz</FormLabel>
                    <RadioGroup className="" row aria-label="confidence" name="confidence"
                                id="konsiliarbericht.diagnoseKonfidenz"
                                value={(this.state.konsil.konsiliarbericht?.diagnoseKonfidenz) ? DiagnoseKonfidenz[this.state.konsil.konsiliarbericht?.diagnoseKonfidenz] as DiagnoseKonfidenz : ""}
                                onChange={(event) => {
                                    let diagnosekonfidenz = Object.keys(DiagnoseKonfidenz).find(key => DiagnoseKonfidenz[key] === event.target.value) as keyof typeof DiagnoseKonfidenz
                                    this.setState({
                                        konsil: {
                                            ...this.state.konsil,
                                            konsiliarbericht: {
                                                ...this.state.konsil.konsiliarbericht,
                                                diagnoseKonfidenz: diagnosekonfidenz,
                                            }
                                        }
                                    }, () => this.updateKonsil());
                                }}
                    >
                        {Object.keys(DiagnoseKonfidenz).map(key =>
                            <FormControlLabel key={key} value={DiagnoseKonfidenz[key]} label={DiagnoseKonfidenz[key]}
                                              control={<Radio/>}/>
                        )}
                    </RadioGroup>
                </FormControl>
                <Divider/>
                <FormControl className="therapie" component="fieldset">
                    <FormLabel className="bold-label full-span float-left" component="legend">Therapievorschlag</FormLabel>
                    <TextField
                        className="pad-top"
                        multiline
                        variant="filled"
                        minRows = {2}
                        maxRows={4}
                        value={this.state.konsil.konsiliarbericht?.therapievorschlag || ""}
                        onChange={(event) => {
                            this.setState({
                                konsil: {
                                    ...this.state.konsil,
                                    konsiliarbericht: {
                                        ...this.state.konsil.konsiliarbericht,
                                        therapievorschlag: event.target.value
                                    }
                                }
                            });
                        }}
                        onBlur={() => this.updateKonsil()}
                    />
                </FormControl>
                <FormControl required className="dringlichkeit" component="fieldset"
                             error={this.props.konsiliarberichtErrors.has("konsiliarbericht.therapieDringlichkeit")}>
                    <FormLabel component="legend" className="pad-top-l full-span float-left" focused = {false}>Therapiedringlichkeit</FormLabel>
                    <RadioGroup row aria-label="urgency" name="urgency"
                                value={(this.state.konsil.konsiliarbericht?.therapieDringlichkeit) ? TherapieDringlichkeit[this.state.konsil.konsiliarbericht?.therapieDringlichkeit] : TherapieDringlichkeit["KEINEANGABE"]}
                                onChange={(event) => {
                                    let dringlichkeit = Object.keys(TherapieDringlichkeit).find(key => TherapieDringlichkeit[key] === event.target.value) as keyof typeof TherapieDringlichkeit

                                    this.setState({
                                        konsil: {
                                            ...this.state.konsil,
                                            konsiliarbericht: {
                                                ...this.state.konsil.konsiliarbericht,
                                                therapieDringlichkeit: dringlichkeit
                                            }
                                        }
                                    }, () => this.updateKonsil());
                                }}
                    >
                        <FormControlLabel value={TherapieDringlichkeit.NONE} label={TherapieDringlichkeit.NONE}
                                          control={<Radio id="konsiliarbericht.therapieDringlichkeit"/>}/>
                        <FormControlLabel value={TherapieDringlichkeit.LOW} label={TherapieDringlichkeit.LOW}
                                          control={<Radio/>}/>
                        <FormControlLabel value={TherapieDringlichkeit.MODERATE} label={TherapieDringlichkeit.MODERATE}
                                          control={<Radio/>}/>
                        <FormControlLabel value={TherapieDringlichkeit.HIGH} label={TherapieDringlichkeit.HIGH}
                                          control={<Radio/>}/>
                        {/* {Object.keys(TherapieDringlichkeit).map(key =>
                                    <FormControlLabel key={key} value={TherapieDringlichkeit[key]} label={TherapieDringlichkeit[key]} control={<Radio/>} />
                                )} */}
                    </RadioGroup>
                </FormControl>
                <Divider/>
                <FormControl className="prognose" component="fieldset">
                    <FormLabel className="bold-label full-span float-left" component="legend">Prognose</FormLabel>
                    <TextField
                        className="pad-top"
                        multiline
                        variant="filled"
                        minRows = {2}
                        maxRows={4}
                        value={this.state.konsil.konsiliarbericht?.prognose || ""}
                        onChange={(event) => {
                            this.setState({
                                konsil: {
                                    ...this.state.konsil,
                                    konsiliarbericht: {
                                        ...this.state.konsil.konsiliarbericht,
                                        prognose: event.target.value
                                    }
                                }
                            });
                        }}
                        onBlur={() => this.updateKonsil()}
                    />
                </FormControl>
                <Divider/>
                <FormControl className="facharzt" component="fieldset">
                    <FormLabel component="legend" className="full-span float-left" focused = {false}>Vorstellung beim Facharzt notwendig</FormLabel>
                    <RadioGroup className="" row aria-label="facharzt" name="facharzt"
                                value={(this.state.konsil.konsiliarbericht?.vorstellungFacharzt !== null) ? (this.state.konsil.konsiliarbericht?.vorstellungFacharzt ? "Ja" : "Nein") : "Keine Angabe"}
                                onChange={(event) => {
                                    let vorstellungFacharzt: boolean | undefined = this.state.konsil.konsiliarbericht?.vorstellungFacharzt;
                                    if (event.target.value === "Ja") {
                                        vorstellungFacharzt = true;
                                    } else if (event.target.value === "Nein") {
                                        vorstellungFacharzt = false;
                                    } else {
                                        vorstellungFacharzt = undefined;
                                    }

                                    this.setState({
                                        konsil: {
                                            ...this.state.konsil,
                                            konsiliarbericht: {
                                                ...this.state.konsil.konsiliarbericht,
                                                vorstellungFacharzt: vorstellungFacharzt
                                            }
                                        }
                                    }, () => this.updateKonsil());
                                }}
                    >
                        <FormControlLabel value="Ja" control={<Radio/>} label="Ja"/>
                        <FormControlLabel value="Nein" control={<Radio/>} label="Nein"/>
                         <FormControlLabel value="Keine Angabe" control={<Radio/>} label="Keine Angabe"/>
                    </RadioGroup>
                </FormControl>
            </form>
        );
    }
}

export const EditKonsiliarBerichtContentContainer = connector(EditKonsiliarBerichtContent);
export default EditKonsiliarBerichtContentContainer;
