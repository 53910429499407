import React from 'react';
import { Tooltip, Chip, Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';


interface IProps { 
    konsilId: string
}

interface IState {
    showId: boolean;
}


export default class IdButtonComponent extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            showId: false,
        };
        this.copyToClipboard = this.copyToClipboard.bind(this);
    }

    copyToClipboard(url: string) {
        var f = (e: ClipboardEvent) => {
            e.clipboardData?.setData("text/plain", url);
            e.preventDefault();
        };
        document.addEventListener("copy", f);
        document.execCommand("copy");
        document.removeEventListener("copy", f);
    };

    render() {
        return (
        <>
            <Tooltip title={this.props.konsilId + " | Kopieren"}
                        aria-label={this.props.konsilId}>
                <Chip size="small" color="primary" label="ID" className="konsil-id" variant="outlined"
                        onClick={
                            (event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                this.setState({showId: true});
                                this.copyToClipboard(this.props.konsilId);
                                //this.copyToClipboard(this.props.currentKonsil?.id ? this.props.currentKonsil?.id : "");
                            }
                        }/>
            </Tooltip>
            <Snackbar
                open={this.state.showId}
                autoHideDuration={50000}
                onClose={() => this.setState({showId: false})}>
                <Alert severity="info">
                    <AlertTitle>Konsil ID (in Zwischenablage kopiert)</AlertTitle>
                    {this.props.konsilId}
                </Alert>
            </Snackbar>
        </>
        )
    }
}