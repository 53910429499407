import { EPdfActions, TPdfActionTypes } from "../types/pdf_types";

export interface IPdfState {
    pdfString: string | null;
    isPdfLoading: boolean;
}

const initialState: IPdfState = {
    pdfString: null,
    isPdfLoading: false,
}

export function pdf_reducer(state = initialState, action: TPdfActionTypes): IPdfState {
    switch (action.type) {
        case EPdfActions.SET_PDF_STRING:
            return {
                ...state,
                pdfString: action.pdfString
            }

        case EPdfActions.CLEAR_PDF_STRING:
            return {
                ...state,
                pdfString: null
            }

        case EPdfActions.SET_IS_PDF_LOADING:
            return {
                ...state,
                isPdfLoading: action.isPdfLoading
            }

        default:
            return state
    }
}