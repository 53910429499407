import { Box, Button, Divider, Paper, Tooltip, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
//import { Add } from "@material-ui/icons";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Arzt, DermatologistInfo } from "telescan-core";
import { getGdtConfig } from "../../../redux/actions/gdt_actions";
import { activateDermatologistInDirectory, getDermatologistRegistrationStatus, registerDermatologistInDirectory } from "../../../redux/actions/kim_actions";
import { RootState } from "../../../redux/reducers";
import DirectoryServiceEditPopup from "../../settings/directory_service/directory_service_edit_popup";
import { DirectoryServiceTable } from "../../settings/directory_service/directory_service_table";
import GdtConfigurationComponentContainer from "../../settings/gdt_settings/gdt_configuration";

interface IState {
    isDirectoryServiceDialogOpen: boolean;
    isDirectoryServiceDialogEdit: boolean;
    currentUser: Arzt | undefined;
}

const mapStateToProps = (state: RootState) => ({
    dermaRegistrationInfo: state.kim.dermatologistRegistration,
    role: state.user.role,
    users: state.user.user,
    kimGeneral: state.kim.kimGeneral,
})

const mapDispatchToProps = {
    dispatch_getDermatologistRegistrationStatus: () => getDermatologistRegistrationStatus(),
    dispatch_registerDermatologistInDirectory: (dermatologistMetadata: DermatologistInfo) => registerDermatologistInDirectory(dermatologistMetadata),
    dispatch_activateDermatologistInDirectory: (activationKey: string) => activateDermatologistInDirectory(activationKey),
    dispatch_getGdtConfig: () => getGdtConfig(),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>
export class DirectoryServiceGdtInit extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            isDirectoryServiceDialogOpen: false,
            isDirectoryServiceDialogEdit: false,
            currentUser: new Arzt(),
        }
    }

    componentDidMount() {
        if (this.props.role === "DERMATOLOGE" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE") {
            this.props.dispatch_getDermatologistRegistrationStatus();
        }
        this.props.dispatch_getGdtConfig();
        const users: Arzt[] = [...this.props.users];
        const user: Arzt | undefined = users.length !== 0 ? users.filter(item => item && item.lanr === localStorage.getItem("LANR"))[0]: undefined;
        this.setState({currentUser: user});
    }

    handleChangeEditFlag = (isEdit: boolean) => {
        this.setState({isDirectoryServiceDialogEdit: isEdit});
    }

    handleOnTableRowClick = () => {
        this.setState({isDirectoryServiceDialogOpen: true});
        this.handleChangeEditFlag(false);
    }

    handleDirectoryServiceDialogClose = () => {
        this.setState({isDirectoryServiceDialogOpen: false});
    }

    handleRegister = (dermatologistMetadata: DermatologistInfo) => {
        this.props.dispatch_registerDermatologistInDirectory(dermatologistMetadata);
    }

    handleOpenDirectoryServicePopup = () => {
        this.setState({isDirectoryServiceDialogOpen: true});
        this.handleChangeEditFlag(true);
    }

    render() {
        return (
            <Box className="main-content initialisation-content">
                {(this.props.role === "DERMATOLOGE" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE") &&
                    <>
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                        Verzeichnisdienst 
                    </Typography>
                        <Box>     
                            {!this.props.dermaRegistrationInfo?.active &&
                                <Typography className="bsnr-info" variant="caption" color="inherit" gutterBottom>
                                    Sie sind im TeleScan-Verzeichnisdienst nicht registriert. Beachten Sie, dass Ihre LANR, BSNR und private E-Mail Adresse erst über zollsoft oder Managementgesellschaft hinterlegt werden mussen.
                                    Danach können Sie ihren TeleScan Account im Verzeichnisdienst freischalten. Erst nach erfolgreicher Freischaltung können Hausärzte dermatologische Telekonsile an Sie versenden.
                                </Typography>
                            }   
                            {(this.props.dermaRegistrationInfo?.active && this.props.dermaRegistrationInfo?.active === "INACTIVE") &&
                                <Typography className="bsnr-info" variant="caption" color="inherit">
                                    Bitte schalten Sie ihren TeleScan Account im Verzeichnisdienst frei. Erst nach erfolgreicher Freischaltung können Hausärzte dermatologische Telekonsile an Sie versenden.
                                    Diese Angaben können jederzeit in den Einstellungen überprüft oder bearbeitet werden.
                                </Typography> 
                            }
                            {(this.props.dermaRegistrationInfo?.active && this.props.dermaRegistrationInfo?.active === "ACTIVE") &&
                                <>
                                    <Typography className="bsnr-info" variant="caption" color="inherit">
                                        Hier können Sie Ihre Daten im TeleScan-Verzeichnisdienst überprufen.
                                    </Typography>
                                </>
                            }           
                           
                            {(this.props.dermaRegistrationInfo?.active && this.props.dermaRegistrationInfo?.active === "INACTIVE") &&
                                <Tooltip title="Beim Verzeichnisdienst anmelden">
                                    <Button
                                        className="slim margin-top"
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleOpenDirectoryServicePopup}
                                    >
                                        <Add />
                                    </Button>
                                </Tooltip>
                            }
                          
                        </Box>
                   
                        <Paper elevation={0}>
                            {this.props.dermaRegistrationInfo.active && this.props.dermaRegistrationInfo.active === "ACTIVE" &&
                                <DirectoryServiceTable
                                    hanldeOnRowClick={this.handleOnTableRowClick}
                                    dermaRegistrationInfo = {this.props.dermaRegistrationInfo}
                                    handleChangeEditFlag = {this.handleChangeEditFlag}
                                />                       
                            }                  
                        </Paper>
                    </>
                }
                <br/>
                <Divider/>

                <DirectoryServiceEditPopup
                    dermaRegistrationInfo = {this.props.dermaRegistrationInfo}
                    isDirectoryServiceDialogOpen = {this.state.isDirectoryServiceDialogOpen}
                    handleClose = {this.handleDirectoryServiceDialogClose}
                    handleRegister = {this.handleRegister}
                    isEdit = {this.state.isDirectoryServiceDialogEdit}
                    handleChangeEditFlag = {this.handleChangeEditFlag}
                    dispatch_activateDermatologistInDirectory = {this.props.dispatch_activateDermatologistInDirectory}
                    currentUser = {this.state.currentUser}
                    kimEmailAddress = {this.props.kimGeneral.kimEmailAddressBs}
                />
                <GdtConfigurationComponentContainer/>
            </Box>
        )
    }
}

export const DirectoryServiceGdtInitContainer = connector(DirectoryServiceGdtInit);
export default DirectoryServiceGdtInitContainer;