import React from 'react';
import {Box} from '@material-ui/core';
import {RootState} from "../../../redux/reducers";
import {connect, ConnectedProps} from "react-redux";

import ViewKonsiliarberichtContainer from './view_konsiliarbericht';
import ViewLokalisationContentContainer from './view_lokalisationcontent';


const mapStateToProps = (state: RootState) => ({
    role: state.user.role
})

const mapDispatchToProps = {}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>


export class ViewLokalisation extends React.Component<TPropsFromRedux> {

    render() {
        return (
            <Box className="splittscreen">
                <Box className="ha-localisation-container">
                    <ViewLokalisationContentContainer/>
                </Box>
                <Box className="split-konsil">
                    <ViewKonsiliarberichtContainer hasSideView={true}/>
                </Box>
            </Box>
        );
    }
}

export const ViewLokalisationContainer = connector(ViewLokalisation);
export default ViewLokalisationContainer;
