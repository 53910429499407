import { Arzt, Betriebsstaette, RoleTypes, Kennzeichnung, Initialisierung } from "telescan-core";

export enum EUserActions {
    SET_USER = "SET_USER",
    SET_ROLE = "SET_ROLE",
    SET_BETRIEBSSTAETTEN_AERZTE = "SET_BETRIEBSSTAETTEN_AERZTE",
    SET_BETRIEBSSTAETTE = "SET_BETRIEBSSTAETTE",
    BASISDATEN_BS_ERRORS = "BASISDATEN_BS_ERRORS",
    BASISDATEN_ARZT_ERRORS = "BASISDATEN_ARZT_ERRORS",
    SET_INITIALISATION_STATUS = "SET_INITIALISATION_STATUS",
    SET_EINVERSTAENDNIS = "SET_EINVERSTAENDNIS",
    SET_ARZTZERTIFIKAT_ERFASST = "SET_ARZTZERTIFIKAT_ERFASST",
    SET_BSZERTIFIKAT_ERFASST = "SET_BSZERTIFIKAT_ERFASST",
    SET_KENNZEICHNUNG = "SET_KENNZEICHNUNG",
    SET_FACHRICHTUNG = "SET_FACHRICHTUNG",
}

export interface ISetFachrichtung {
    readonly type: EUserActions.SET_FACHRICHTUNG;
    readonly fachrichtung: string;
}

export interface ISetUserAction {
    readonly type: EUserActions.SET_USER;
    readonly user: Arzt[];
}

export interface ISetRoleAction {
    readonly type: EUserActions.SET_ROLE;
    readonly role: keyof typeof RoleTypes;
}

export interface ISetAerzteAction {
    readonly type: EUserActions.SET_BETRIEBSSTAETTEN_AERZTE;
    readonly betriebsstaettenAerzte: Arzt[];
    //readonly betriebsstaettenArzt: Arzt;
}

export interface ISetBetriebsstaetteAction {
    readonly type: EUserActions.SET_BETRIEBSSTAETTE;
    readonly betriebsstaette: Betriebsstaette;
}

export interface IBasisdatenBSErrors {
    readonly type: EUserActions.BASISDATEN_BS_ERRORS;
    readonly fieldErrorMap: Map<string, string>; // <Feldname, Fehlermeldung>
}

export interface IBasisdatenArztErrors {
    readonly type: EUserActions.BASISDATEN_ARZT_ERRORS;
    readonly fieldErrorMap: Map<string, string>; // <Feldname, Fehlermeldung>
}

export interface ISetInitialisationStatusAction {
    readonly type: EUserActions.SET_INITIALISATION_STATUS;
    readonly initialisationStatus: Initialisierung; // <Feldname, Fehlermeldung>
}

export interface ISetEinverstaendnisAction {
    readonly type: EUserActions.SET_EINVERSTAENDNIS;
    readonly einverstaendnis: boolean; // <Feldname, Fehlermeldung>
}

export interface ISetArztZertifikatErfasstAction {
    readonly type: EUserActions.SET_ARZTZERTIFIKAT_ERFASST;
    readonly zertifikatArztVorhanden: boolean; // <Feldname, Fehlermeldung>
}

export interface ISetBSZertifikatErfasstAction {
    readonly type: EUserActions.SET_BSZERTIFIKAT_ERFASST;
    readonly zertifikatBetriebsstaetteVorhanden: boolean; // <Feldname, Fehlermeldung>
}

export interface ISetKennzeichnung {
    readonly type: EUserActions.SET_KENNZEICHNUNG;
    readonly kennzeichnung: Kennzeichnung;
}

export type TUserActionTypes =
    ISetUserAction |
    ISetRoleAction |
    ISetAerzteAction |
    ISetBetriebsstaetteAction |
    IBasisdatenBSErrors |
    IBasisdatenArztErrors |
    ISetInitialisationStatusAction |
    ISetEinverstaendnisAction |
    ISetArztZertifikatErfasstAction |
    ISetBSZertifikatErfasstAction |
    ISetKennzeichnung|
    ISetFachrichtung;
