import React from 'react';
import {
    IconButton,
    Tooltip,
} from '@material-ui/core';
import {Edit, Visibility} from '@material-ui/icons';
import {RootState} from '../../../../redux/reducers';
import {connect, ConnectedProps} from 'react-redux';
import { Patient, Failure, UploadPath} from 'telescan-core';
import {checkKonsilPatientStatus, loadFailure, updateKonsilPatient, updateTelescanPatient} from '../../../../redux/actions';
import {Patienteneinwilligung} from 'telescan-core/lib/entities/patienteneinwilligung';
import { ViewPatientGeneralDataModal } from './view_patient_general_data_modal';
import EditPatientGeneralDataModal from './edit_patient_general_data_modal';


const mapStateToProps = (state: RootState) => ({
    currentKonsil: state.konsile.current_konsil,
    currentKonsilPatient: state.konsile.current_konsil?.konsilPatient || new Patient(),
    konsilPatientErrors: state.evaluation.konsilPatientErrors,
    initialisedServices: state.general.initialisedServices,
    availabelInsuranceKim: state.konsile.availableInsuranceKim,
})

const mapDispatchToProps = {
    updateKonsilPatient: (konsil_id: string, patient: Patient | null, patienteneinwilligung: Patienteneinwilligung) => updateKonsilPatient(konsil_id, patient, patienteneinwilligung),
    loadFailure: (id: string, message: Failure) => loadFailure(id, message),
    dispatch_checkKonsilPatientStatus: (patient: Patient, konsilId: string) => checkKonsilPatientStatus(patient, konsilId),
    dispatch_updateTelescanPatient: (patient: Patient, konsilId: string) => updateTelescanPatient(patient, konsilId),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

interface IState {
    isOpenEditModal: boolean;
    isOpenViewModal: boolean;
    backupedPatientState: Patient;
}

type TProps = TPropsFromRedux & {
    hasError: boolean
}

export class PatientGeneralData extends React.Component<TProps, IState> {
    constructor(props: TProps) {
        super(props);
        this.state = {
            isOpenEditModal: false,
            isOpenViewModal: false,
            backupedPatientState: new Patient(),
        };
    }

    componentDidUpdate(prevProps: TProps) {
        if (prevProps.currentKonsilPatient !== this.props.currentKonsilPatient &&
            !this.props.initialisedServices.includes(UploadPath.HPM) &&
            this.props.konsilPatientErrors && this.props.konsilPatientErrors.length !== 0 && this.props.konsilPatientErrors.find(item => item.konsilID === this.props.currentKonsil.id)?.errors &&
            this.props.konsilPatientErrors && this.props.konsilPatientErrors.length !== 0 && this.props.konsilPatientErrors.find(item => item.konsilID === this.props.currentKonsil.id)?.errors.size !== 0) {
            this.setState({
                isOpenEditModal: true,
                backupedPatientState: this.props.currentKonsilPatient,
            });
        }
    }


    // handleCloseEdit = () => {
    //     this.setState({isOpenEditModal: false});
    //     if (this.props.currentKonsilPatient.telescanCreated) {
    //         this.props.dispatch_checkKonsilPatientStatus(this.state.backupedPatientState, this.props.currentKonsil.patienteneinwilligung || new Patienteneinwilligung(), this.props.currentKonsil.id || "");
    //         this.props.dispatch_updateTelescanPatient(this.state.backupedPatientState, this.props.currentKonsil.id || "");
    //     } else {
    //         this.props.updateKonsilPatient(this.props.currentKonsil?.id as string, this.state.backupedPatientState, this.props.currentKonsil.patienteneinwilligung || new Patienteneinwilligung());
    //     }

    // };

    // handleSavePatientButton = (patient: Patient) => {
    //     this.setState({isOpenEditModal: false});
    //     if (patient.telescanCreated) {
    //         this.props.dispatch_checkKonsilPatientStatus(patient, this.props.currentKonsil.patienteneinwilligung || new Patienteneinwilligung(), this.props.currentKonsil.id || "");
    //         this.props.dispatch_updateTelescanPatient(patient, this.props.currentKonsil.id || "");
    //     } else {
    //         this.updateKonsilPatient(patient);
    //     }
    // }

    handleSaveOrCancelPatientData = (patient?: Patient) => {
        const patientToUpdate: Patient = patient ? patient : this.state.backupedPatientState;
        this.setState({isOpenEditModal: false});
        if (patientToUpdate.telescanCreated) {
            this.props.dispatch_checkKonsilPatientStatus(patientToUpdate, this.props.currentKonsil.id || "");
            this.props.dispatch_updateTelescanPatient(patientToUpdate, this.props.currentKonsil.id || "");
        } else {
            this.updateKonsilPatient(patientToUpdate);
        }
    }

    handleCloseView = () => {
        this.setState({
            isOpenViewModal: false
        });
    };

    updateKonsilPatient = (patient: Patient) => {
        this.props.updateKonsilPatient(this.props.currentKonsil?.id as string, patient, this.props.currentKonsil.patienteneinwilligung || new Patienteneinwilligung());
        this.props.dispatch_checkKonsilPatientStatus(patient, this.props.currentKonsil.id || "");
    }

    handleEditPatientBasicDataIcon = () => {
        this.setState({
            isOpenEditModal: true,
            backupedPatientState: this.props.currentKonsilPatient,
        });
    }

    render() {
        const patientError: Map<string, string> = this.props.konsilPatientErrors.find(item => item.konsilID === this.props.currentKonsil.id)?.errors || new Map<string, string>();
        if (!this.props.currentKonsilPatient)
            return (<strong>Konnte keinen Patienten finden!</strong>)
        else
            return (
                <>
                    <span
                        className={(patientError.has("patient") || patientError.has("krankenkasse")) ? " failure" : ""}
                        style={{cursor: "pointer"}}
                        onClick={() => {
                            if (this.props.currentKonsil.pvsPatient != null)
                                this.setState({
                                    isOpenViewModal: true
                                })
                            else {
                                this.setState({
                                    isOpenEditModal: true
                                })
                            }
                        }}
                    >
                        <span>
                            {this.props.currentKonsil?.konsilPatient?.title} {this.props.currentKonsil?.konsilPatient?.vorname} {this.props.currentKonsil?.konsilPatient?.namenszusatz} {this.props.currentKonsil?.konsilPatient?.vorsatzwort} {this.props.currentKonsil?.konsilPatient?.nachname} {this.props.currentKonsil?.konsilPatient?.suffix}
                        </span>
                        {this.props.currentKonsilPatient.geburtsdatum &&
                            <span>&emsp;•&emsp;{new Date(this.props.currentKonsilPatient?.geburtsdatum).toLocaleDateString()}</span>
                        }
                        <span>&emsp;•&emsp;{this.props.currentKonsilPatient?.egkNummer}</span>
                    </span>
                    <div className="profile-data-edit">
                        <div>
                            {(this.props.currentKonsil.pvsPatient != null && this.props.initialisedServices.includes(UploadPath.HPM)) ?
                                <Tooltip title="Profil Informationen" placement="top">
                                    <IconButton size="small" aria-label="delete" onClick={() => this.setState({
                                        isOpenViewModal: true
                                    })}>
                                        <Visibility/>
                                    </IconButton>
                                </Tooltip>
                                :
                                <Tooltip title="Profil Informationen" placement="top">
                                    <IconButton
                                        size="small"
                                        aria-label="delete"
                                        onClick={this.handleEditPatientBasicDataIcon}
                                    >
                                        <Edit className={this.props.hasError ? "failure" : ""}/>
                                    </IconButton>
                                </Tooltip>
                            }
                        </div>

                        <EditPatientGeneralDataModal
                            isOpenEditModal = {this.state.isOpenEditModal}
                            //handleCloseEdit = {this.handleCloseEdit}
                            updateKonsilPatient = {this.updateKonsilPatient}
                            currentKonsil = {this.props.currentKonsil}
                            currentKonsilPatient = {this.props.currentKonsilPatient}
                            konsilPatientErrors = {this.props.konsilPatientErrors}
                            initialisedServices = {this.props.initialisedServices}
                            dispatch_updateTelescanPatient = {this.props.dispatch_updateTelescanPatient}
                            dispatch_checkKonsilPatientStatus = {this.props.dispatch_checkKonsilPatientStatus}
                            availabelInsuranceKim = {this.props.availabelInsuranceKim}
                            handleSaveOrCancelPatientData = {this.handleSaveOrCancelPatientData}
                        />

                        <ViewPatientGeneralDataModal
                            patient={this.props.currentKonsilPatient}
                            isOpen={this.state.isOpenViewModal}
                            onClose={this.handleCloseView}
                        />
                    </div>
                </>
            );
    }
}

export const EditPatientGeneralDataComponent = connector(PatientGeneralData);
export default EditPatientGeneralDataComponent;



