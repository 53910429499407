import { TZertifikatActionTypes, EZertifikatActions } from "../types/zertifikat_types"
import { ZertifikatMetainformation } from "telescan-core"



export interface IZertifikatState {
    zertifikatStatus: {status: string, arztZertifikat: string, betriebsstaettenZertifikat: string, meldung: string | null};
    arztZertifikateMetaInfo: ZertifikatMetainformation[];
    betriebsstaettenZertifikatMetaInfo: ZertifikatMetainformation;
}

const initialState: IZertifikatState = {
    zertifikatStatus: {status: "ok", arztZertifikat: "AKTIV", betriebsstaettenZertifikat: "AKTIV", meldung: null},
    arztZertifikateMetaInfo: [],
    betriebsstaettenZertifikatMetaInfo: new ZertifikatMetainformation(),
}

export function zertifikat_reducer(state = initialState, action: TZertifikatActionTypes): { zertifikatStatus: any, arztZertifikateMetaInfo: ZertifikatMetainformation[], betriebsstaettenZertifikatMetaInfo: ZertifikatMetainformation } {
    switch (action.type) {
        case EZertifikatActions.SET_ZERTIFIKAT_STATUS:
            return {
                ...state,
                zertifikatStatus: action.zertifikatStatus
            }
        case EZertifikatActions.SET_ZERTIFIKATE_METAINFO:
            return {
                ...state,
                arztZertifikateMetaInfo: action.arztZertifikateMetaInfo,
                betriebsstaettenZertifikatMetaInfo: action.betriebsstaettenZertifikatMetaInfo
            }
        default:
            return state
    }
}