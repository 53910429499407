import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';
import uuid from 'uuid';

import { UpdateService, InformationUpdate, GlobalUpdateEventData, TeleScanUpdateEventData } from "telescan-core";
import {RootState} from '../reducers';
import { failure } from './general_actions';
import * as UpdateTypes from '../types/update_types';
import { UpdateStepStatusType } from "telescan-core";

export function getUpdateInformation(): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        const loadInstanceId: string = uuid.v4();
        //dispatch(loading(loadInstanceId));

        const updateService: UpdateService = new UpdateService();
        updateService.getUpdateInformation().then(
            (info) => {
                //dispatch(loadSuccess(loadInstanceId));
                dispatch(setUpdateInformation(info));
            }
        ).catch(
            (response) => {
                dispatch(failure(loadInstanceId, response));
            }
        );
    }
}

export function setUpdateInformation(info: InformationUpdate[]): UpdateTypes.ISetUpdateInfoAction {
    return {
        type: UpdateTypes.EUpdateActions.SET_UPDATE_INFO,
        updateInfo: info,
    }
}

export function handleGlobalUpdateEvent(data: GlobalUpdateEventData): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        console.log("Update Running Event detected!", data)
        if (data.status === "START") {
            dispatch(setUpdateRunning(true));
            dispatch(setUpdateSuccess(false));
            return;
        }

        if (data.status === "RUNNING") {
            dispatch(setUpdateRunning(true));
            dispatch(setUpdateSuccess(false));
            return;
        }

        if (data.status === "SUCCESS") {
            dispatch(setUpdateRunning(true));
            dispatch(setUpdateSuccess(true));
            return;
        }

        if (data.status === "ERROR") {
            const failureId = uuid.v4();
            data.error && dispatch(failure(failureId, data.error));
            dispatch(setUpdateRunning(false));
            dispatch(setUpdateSuccess(false));
            return;
        }
        dispatch(setUpdateRunning(false))
        dispatch(setUpdateSuccess(false));
    }
}

export function setUpdateRunning(updateRunning: boolean): UpdateTypes.ISetUpdateRunning {
    return {
        type: UpdateTypes.EUpdateActions.SET_UPDATE_RUNNING,
        updateRunning: updateRunning
    }
}

export function setUpdateSuccess(isUpdateSuccess: boolean): UpdateTypes.ISetUpdateSuccessAction {
    return {
        type: UpdateTypes.EUpdateActions.SET_UPDATE_SUCCESS,
        isUpdateSuccess: isUpdateSuccess
    }
}

export function handleUpdatingEvent(data: TeleScanUpdateEventData, modulName: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const idxOfStep = getState().update.listOfUpdateSteps.findIndex(item => Object.keys(item)[0] === data.updateStep)
        const step: string = idxOfStep !== -1 ? Object.values(getState().update.listOfUpdateSteps[idxOfStep])[0] : "";
        if (data.status === "start" ) {
            if (modulName === "hpm") {
                let newHpmUpdateSteps: {step: string, status: keyof typeof UpdateStepStatusType, progress: string, message: string}[] = getState().update.hpmUpdateSteps.slice();
                const index = newHpmUpdateSteps.findIndex((item) => item.step === step)
                newHpmUpdateSteps[index] = {step: step || "", status: "RUNNING", progress: "", message: ""}
                dispatch(setHpmUpdateSteps(newHpmUpdateSteps))
                return;
            } else if (modulName === "TELESCAN") {
                let newUpdateSteps: {step: string, status: keyof typeof UpdateStepStatusType, progress: string, message: string}[] = getState().update.teleScanUpdateSteps.slice();
                const index = newUpdateSteps.findIndex((item) => item.step === step)
                newUpdateSteps[index] = {step: step || "", status: "RUNNING", progress: "", message: ""}
                dispatch(setTeleScanUpdateSteps(newUpdateSteps))
                return;
            }
        }

        if (data.status === "downloading") {
            if (modulName === "hpm") {
                let newHpmUpdateSteps: {step: string, status: keyof typeof UpdateStepStatusType, progress: string, message: string}[] = getState().update.hpmUpdateSteps.slice();
                const index = newHpmUpdateSteps.findIndex((item) => item.step === step)
                newHpmUpdateSteps[index] = {step: step || "", status: "DOWNLOAD", progress: data.msg || "", message: ""}
                dispatch(setHpmUpdateSteps(newHpmUpdateSteps))
                return;
            } else if (modulName === "TELESCAN") {
                let newUpdateSteps: {step: string, status: keyof typeof UpdateStepStatusType, progress: string, message: string}[] = getState().update.teleScanUpdateSteps.slice();
                const index = newUpdateSteps.findIndex((item) => item.step === step)
                newUpdateSteps[index] = {step: step || "", status: "DOWNLOAD", progress: data.msg || "", message: ""}
                dispatch(setTeleScanUpdateSteps(newUpdateSteps))
                return;
            }
        }

        if (data.status === "ok") {
            if (modulName === "hpm") {
                let newHpmUpdateSteps: {step: string, status: keyof typeof UpdateStepStatusType, progress: string, message: string}[] = getState().update.hpmUpdateSteps.slice();
                const index = newHpmUpdateSteps.findIndex((item) => item.step === step)
                newHpmUpdateSteps[index] = {step: step || "", status: "DONE", progress: "", message: ""}
                dispatch(setHpmUpdateSteps(newHpmUpdateSteps))
            } else if (modulName === "TELESCAN") {
                let newUpdateSteps: {step: string, status: keyof typeof UpdateStepStatusType, progress: string, message: string}[] = getState().update.teleScanUpdateSteps.slice();
                const index = newUpdateSteps.findIndex((item) => item.step === step)
                newUpdateSteps[index] = {step: step || "", status: "DONE", progress: "", message: ""}
                dispatch(setTeleScanUpdateSteps(newUpdateSteps))
            }
        } else {
            console.log("Updateschritt " + data.updateStep + " fehlgeschlagen.");
            if (modulName === "hpm") {
                let newHpmUpdateSteps: {step: string, status: keyof typeof UpdateStepStatusType, progress: string, message: string}[] = getState().update.hpmUpdateSteps.slice();
                const index = newHpmUpdateSteps.findIndex((item) => item.step === step)
                newHpmUpdateSteps[index] = {step: step || "", status: "ERROR", progress: "", message: data.error?.error || ""}
                dispatch(setHpmUpdateSteps(newHpmUpdateSteps))
            }else if (modulName === "TELESCAN") {
                let newUpdateSteps: {step: string, status: keyof typeof UpdateStepStatusType, progress: string, message: string}[] = getState().update.teleScanUpdateSteps.slice();
                const index = newUpdateSteps.findIndex((item) => item.step === step)
                newUpdateSteps[index] = {step: step || "", status: "ERROR", progress: "", message: data.error?.error || ""}
                dispatch(setTeleScanUpdateSteps(newUpdateSteps))
            }
        }
    }
}

export function updateModul(modulName: string): ThunkAction<Promise<any>, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const loadInstanceId: string = uuid.v4();
        dispatch(setUpdatingModule(modulName))
        const updateService = new UpdateService();

        return new Promise<any>((resolve, reject) => {
            updateService.updateModul(modulName, getState().webSocket.clientId).then((response) => {
                if (modulName === "hpm") {
                    response.updateSchritte && dispatch(setListOfUpdateSteps(response.updateSchritte));
                    dispatch(setHpmUpdateSteps(
                        (response.updateSchritte)? response.updateSchritte.map(step => {
                            return {step: Object.values(step)[0], status: "WAITING", progress: "", message: ""}
                        }) : []
                    ))
                }
                else if (modulName === "TELESCAN"){
                    response.updateSchritte && dispatch(setListOfUpdateSteps(response.updateSchritte));
                    dispatch(setTeleScanUpdateSteps(
                        (response.updateSchritte)? response.updateSchritte.map(step => {
                            return {step: Object.values(step)[0], status: "WAITING", progress: "", message: ""}
                        }) : []
                    ))}
                resolve(response);
            }).catch((error) => {
                dispatch(failure(loadInstanceId, error));
                //reject(error);
            })
        })
    }
}

export function setHpmUpdateSteps(hpmUpdateSteps: {step: string, status: keyof typeof UpdateStepStatusType, progress: string, message: string}[]): UpdateTypes.ISetHpmUpdateStepsAction {
    return {
        type: UpdateTypes.EUpdateActions.SET_HPM_UPDATE_STEPS,
        hpmUpdateSteps: hpmUpdateSteps,
    }
}

export function setTeleScanUpdateSteps(teleScanUpdateSteps: {step: string, status: keyof typeof UpdateStepStatusType, progress: string, message: string}[]): UpdateTypes.ISetTeleScanUpdateStepsAction {
    return {
        type: UpdateTypes.EUpdateActions.SET_TELESCAN_UPDATE_STEPS,
        teleScanUpdateSteps: teleScanUpdateSteps,
    }
}

export function setUpdatingModule(module: string): UpdateTypes.ISetUpdatingModuleAction {
    return {
        type: UpdateTypes.EUpdateActions.SET_UPDATING_MODULE,
        module: module
    }
}

export function setListOfUpdateSteps(listOfUpdateSteps: Object[]): UpdateTypes.ISetListOfUpdateStepsAction {
    return {
        type: UpdateTypes.EUpdateActions.SET_LIST_OF_UPDATE_STEPS,
        listOfUpdateSteps: listOfUpdateSteps,
    }
}

