import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { EhbaEventResultCode, EhbaEventType, TIConnectorEvent } from "telescan-core";
import { cancelSignKonsil } from "../../../redux/actions/ti_connector_actions";
import { RootState } from "../../../redux/reducers";


interface IState {
    timeoutId: number;
    isWarningDialog: boolean;
}
interface IProps {
    isOpen: boolean;
    handleClose: () => void;
    tiConnectorEvent: TIConnectorEvent;
    forSettings: boolean;
    triggerSendOnSignSuccess?: () => void;
}

const mapStateToProps = (state: RootState) => ({
    currentKonsil: state.konsile.current_konsil,
})

const mapDispatchToProps = {
    dispatch_cancelSignKonsil: () => cancelSignKonsil(),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class SignatureDialog extends React.Component<IProps & TPropsFromRedux, IState> {
    constructor(props: IProps & TPropsFromRedux) {
        super(props);
        this.state = {
            timeoutId: 0,
            isWarningDialog: false,
        }
    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.tiConnectorEvent !== prevProps.tiConnectorEvent && this.props.tiConnectorEvent.eventTopic === EhbaEventType.CARD_OCCUPIED) {
            this.setState({isWarningDialog: true});
        }

        if (this.props.forSettings) {
            if (this.props.tiConnectorEvent !== prevProps.tiConnectorEvent && this.props.tiConnectorEvent.eventTopic === EhbaEventType.PIN_FINISHED && this.props.tiConnectorEvent.result === EhbaEventResultCode.OK) {
                const setTimeoutId: number = window.setTimeout(() => this.props.handleClose(), 2000);
                this.setState({timeoutId: setTimeoutId});
            }
        } else {
            if (this.props.tiConnectorEvent !== prevProps.tiConnectorEvent && this.props.tiConnectorEvent.eventTopic === EhbaEventType.SIGN_FINISHED) {
                const setTimeoutId: number = window.setTimeout(() => {
                    this.props.handleClose();
                    this.props.triggerSendOnSignSuccess && this.props.triggerSendOnSignSuccess();
                }
                , 2000);
                this.setState({timeoutId: setTimeoutId});
            }
                
        }
        
    }

    componentWillUnmount() {
        window.clearTimeout(this.state.timeoutId);
    }

    handleCancelWhenPinRequested = () => {   
        window.clearTimeout(this.state.timeoutId);
        this.props.dispatch_cancelSignKonsil();
        if (this.props.tiConnectorEvent.eventTopic === EhbaEventType.CARD_INSERTED || (this.props.tiConnectorEvent.eventTopic === EhbaEventType.PIN_FINISHED && this.props.tiConnectorEvent.result === EhbaEventResultCode.REJECTED)) {
            this.setState({isWarningDialog: true});
        } else {          
            this.props.handleClose();
        }
    }

    handleCloseWarningDialog = () => {
        this.setState({isWarningDialog: false});
        this.props.handleClose();
    }

    render() {
        return(
            <Dialog
                open = {this.props.isOpen}
                fullWidth = {true}
            >
                <DialogTitle>
                    {this.props.forSettings ?
                        "Hinterlegen der Signatur" :
                        "Konsilauftrag unterschreiben"
                    }
                    
                </DialogTitle>
                <DialogContent>
                        {Object.values(this.props.tiConnectorEvent).every(item => !item) &&
                            <Typography>
                                Stecken Sie bitte Ihren eHBA in ein passendes Kartenterminal ein.
                            </Typography> 
                        }
                        {this.props.tiConnectorEvent.eventTopic === EhbaEventType.CARD_INSERTED &&
                            <Box>
                                <Typography gutterBottom>
                                    eHBA wurde eingesteckt. Bitte geben Sie die PIN Ihres eHBA ein.
                                </Typography> 
                               
                            </Box>

                        }

                        {this.props.tiConnectorEvent.eventTopic === EhbaEventType.PIN_FINISHED && this.props.tiConnectorEvent.result === EhbaEventResultCode.OK && this.props.forSettings &&
                                <Typography>
                                    Ihre digitale Signatur wurde erfolgreich hinterlegt.
                                </Typography>
                        }

                        {this.props.tiConnectorEvent.eventTopic === EhbaEventType.PIN_FINISHED && this.props.tiConnectorEvent.result === EhbaEventResultCode.OK && !this.props.forSettings &&
                                 <Typography>
                                    Die PIN ist korrekt.
                                </Typography>
                        }

                        {this.props.tiConnectorEvent.eventTopic === EhbaEventType.PIN_FINISHED && this.props.tiConnectorEvent.result === EhbaEventResultCode.REJECTED &&
                            <Box>
                                <Typography gutterBottom className = "failure">
                                    Die PIN ist falsch, versuchen Sie bitte es erneut.
                                </Typography> 
                            </Box>
                        }

                        {/* {this.props.tiConnectorEvent.eventTopic === EhbaEventType.PIN_FINISHED && this.props.tiConnectorEvent.result === EhbaEventResultCode.ERROR &&
                            <Box>
                                <Typography gutterBottom className = "failure">
                                    Timeout-Fehler, bitte starten Sie den Signiervorgang erneut.
                                </Typography> 
                            </Box>
                        } */}

                        {this.props.tiConnectorEvent.eventTopic === EhbaEventType.TIMEOUT &&
                            <Box>
                                <Typography gutterBottom className = "failure">
                                    Timeout-Fehler, bitte starten Sie den Signiervorgang erneut.
                                </Typography> 
                            </Box>
                        }

                        {this.props.tiConnectorEvent.eventTopic === EhbaEventType.SIGN_FINISHED && !this.props.forSettings &&
                            <Box>
                                <Typography gutterBottom>
                                    Ihr Konsilauftrag wurde erfolgreich signiert.
                                </Typography> 
                            </Box>
                        }

                        {(this.props.tiConnectorEvent.eventTopic === EhbaEventType.CANCEL_BY_USER || (this.props.tiConnectorEvent.eventTopic === EhbaEventType.PIN_FINISHED && this.props.tiConnectorEvent.result === EhbaEventResultCode.ERROR)) &&
                            <Box>
                                <Typography gutterBottom>
                                    Die PIN Eingabe wurde abgebrochen, bitte starten Sie den Signiervorgang erneut.
                                </Typography> 
                            </Box>
                        }

                        {this.props.tiConnectorEvent.eventTopic === EhbaEventType.CARD_REMOVED &&
                            <Box>
                                <Typography gutterBottom>
                                    eHBA wurde entfernt. Bitte starten Sie den Versand neu und stecken den eHBA erneut ein.
                                </Typography> 
                            </Box>
                        }

                        {this.props.tiConnectorEvent.eventTopic === EhbaEventType.OCSP_EXCEPTION &&
                            <Box>
                                <Typography gutterBottom>
                                    Fehlermeldung vom TI-Konnektor. Bitte prüfen Sie den Status des OCSP-Responders an ihrem TI-Konnektor und starten den Signiervorgang erneut.
                                </Typography> 
                            </Box>
                        }

                        {this.props.tiConnectorEvent.eventTopic === EhbaEventType.CARD_ELEVATED_SECURITY_LEVEL &&
                            <Box>
                                <Typography gutterBottom className = "failure">
                                    Ihre eHBA hat mindestens eine Kartensitzung zu einer anderen UserId, deren Sicherheitszustand erhöht ist.
                                    Bitte versuchen Sie es später erneut oder stecken Sie erneut Ihren eHBA in das Kartenterminal.
                                </Typography> 
                            </Box>
                        }

                        {this.props.tiConnectorEvent.eventTopic === EhbaEventType.BUSY &&
                            <Box>
                                <Typography gutterBottom className = "failure">
                                    Ihre Anfrage kann nicht bearbeitet werden. Es wird bereits eine Signatur am Konnektor erstellt. Bitte versuchen Sie es später erneut.
                                </Typography> 
                            </Box>
                        }
                                      
                </DialogContent>
                <DialogActions>                  
                    <Box className = 'flex-row-column-gap'> 
                        {(this.props.tiConnectorEvent.eventTopic === EhbaEventType.CARD_ELEVATED_SECURITY_LEVEL || this.props.tiConnectorEvent.eventTopic === EhbaEventType.BUSY) &&
                            <Button
                                variant = "contained"
                                onClick = {this.props.handleClose}
                                size = 'small'
                            >
                              OK
                            </Button>      
                        }
                        {this.props.tiConnectorEvent.eventTopic !== EhbaEventType.CARD_ELEVATED_SECURITY_LEVEL && this.props.tiConnectorEvent.eventTopic !== EhbaEventType.BUSY &&
                            <Button
                                variant = "contained"
                                onClick = {this.handleCancelWhenPinRequested}
                                size = 'small'
                            >
                                Abbrechen
                            </Button>             
                        }
                        
                    </Box>
                </DialogActions> 

                <Dialog open = {this.state.isWarningDialog}>
                    <DialogContent>
                        <Typography className = "failure">
                            Ihre eHBA ist durch die laufende PIN-Abfrage am Kartenlesegerät belegt.
                            Sie können einen neuen Signatur-Auftrag erst dann starten, wenn sie die PIN-Eingabe am Kartenlesegerät manuell beenden oder sobald die PIN-Eingabe-Zeit abgelaufen ist (ca. 60 s).
                        </Typography>
                        
                    </DialogContent>
                    <DialogActions>                  
                        <Box className = 'flex-row-column-gap'>                       
                            <Button
                                variant = "contained"
                                onClick = {this.handleCloseWarningDialog}
                                size = 'small'
                            >
                                OK
                            </Button>                 
                        </Box>
                    </DialogActions> 
                </Dialog>

            </Dialog>
        )
    }
}


export const SignatureDialogContainer = connector(SignatureDialog);
export default SignatureDialogContainer;