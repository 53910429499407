import React from 'react';
import uuid from 'uuid';
import {
    Box,
    Checkbox,
    Paper,
    Typography,
    Tooltip
} from '@material-ui/core';
import {
    TelescanConfig,
    KonsilFruehereKonsile,
} from 'telescan-core';
import { KonsilStatus } from '../konsillist';
import IdButtonComponent from './id_button';



interface OldKonsilProps {
    konsilMetaInfo: KonsilFruehereKonsile;
    selectAsAttachment: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLButtonElement>, attachmentKonsilId: string) => void;
    deleteAttachment: (attachment_id: string) => void;
    selected: string | undefined;
    variant?: "outlined" | "elevation";
    dispatch_successMessage?: (id: string, message: string) => void;
}

export class OldKonsil extends React.Component<OldKonsilProps> {
    constructor(props: OldKonsilProps) {
        super(props);
        this.state = {
        };

        this.handleClick = this.handleClick.bind(this);
    }  


    handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLButtonElement>) {
        event.preventDefault();
        event.stopPropagation();
        if (!this.props.selected) 
            this.props.selectAsAttachment(event, this.props.konsilMetaInfo.id || "unknown ID");
        else 
            this.props.deleteAttachment(this.props.selected);
    }

    render() {
        const teledermConfig = TelescanConfig.getInstance();
        return (
            <Box key={uuid.v4()}>
                <Paper 
                    className="attachment old-konsil pad" 
                    onClick={(event)=> { 
                        this.props.dispatch_successMessage && this.props.dispatch_successMessage(uuid.v4(), "PDF wird in einem neuen Tab geöffnet");
                        event.stopPropagation();
                        window.open(`${teledermConfig.getConstant("SERVER_API_URL")}konsil/${this.props.konsilMetaInfo.id}/pdf?access_token=${teledermConfig.getConstant("ACCESS_TOKEN")}`, "_blank");
                        }} 
                    variant={this.props.variant}
                >
                    {(this.props.selected)? 
                        <Tooltip title="Als Anhang übernehmen">
                            <Checkbox checked
                                size="small"
                                aria-label="delete-attachment"
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        this.handleClick(event);
                                    }
                                }}
                                onClick={this.handleClick}
                            />
                        </Tooltip>
                        :
                        <Tooltip title="Als Anhang übernehmen">
                            <Checkbox
                                size="small"
                                aria-label="delete-attachment"
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        this.handleClick(event);
                                    }
                                }}
                                onClick={this.handleClick}
                            />
                        </Tooltip>
                    }
                    <span className="konsil-status-id">
                        <KonsilStatus therapiedringlichkeit={undefined} hasPvsPatient={true} bearbeitungsStatus={"OK"} status={this.props.konsilMetaInfo.konsilStatus || "BEAUFTRAGT"} isMetaData={false} role="HAUSARZT"/>
                        <IdButtonComponent konsilId={this.props.konsilMetaInfo.id || "keine Angabe"}/>
                    </span>
                    <span className="konsil-date">
                        <Typography variant="body1" color="inherit">
                            Erstellt am:
                        </Typography>
                        <Typography variant="body1" color="inherit">
                            {new Date(this.props.konsilMetaInfo.dateCreated).toLocaleDateString()}
                        </Typography>
                    </span>
                    {(this.props.konsilMetaInfo.fragestellung && this.props.konsilMetaInfo.fragestellung?.length > 40) &&
                    <Tooltip title={this.props.konsilMetaInfo.fragestellung}>
                        <Typography className="konsil-question" variant="body1" color="inherit">
                            Frage: {this.props.konsilMetaInfo.fragestellung?.substring(0, 40) + "..."}
                        </Typography>
                    </Tooltip>
                    }
                    {!(this.props.konsilMetaInfo.fragestellung && this.props.konsilMetaInfo.fragestellung?.length > 40) &&
                    <Typography className="konsil-question" variant="body1" color="inherit">
                        Frage: {(this.props.konsilMetaInfo.fragestellung)? (this.props.konsilMetaInfo.fragestellung?.length > 40)? this.props.konsilMetaInfo.fragestellung?.substring(40) + "..." : this.props.konsilMetaInfo.fragestellung : "keine Frage gestellt"}
                    </Typography>
                    }
                </Paper>
            </Box>
        )
    }
}
