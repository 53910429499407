import React from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {RootState} from '../../redux/reducers';
import {TabPanel} from '../konsil/konsil';
import { BetriebsStaetteDatenComponent } from './daten_betriebsstaette';
import { AerzteDatenComponent } from './daten_aerzte';
import { PasswortComponent } from './passwort';
import { ZertifikateComponent } from './zertifikate';
import { ZusammenfassungComponent } from './zusammenfassung';
import UpdateComponent from './hpm';
import { KimConfigurationInitContainer } from './kim_configuration/kim_configuration_init';
import { DirectoryServiceGdtInitContainer } from './kim_configuration/directory_service_gdt_init';


const mapStateToProps = (state: RootState) => ({
    userType: state.user.role,
    currentKonsil: state.konsile.current_konsil,
    tabIndex: state.konsile.tabIndex,
    isDesktop: state.general.isDesktop,
    konsile: state.konsile,
    userDetails: state.user.user,
    initialisationStatus: state.user.initialisationStatus,
    isKimEnabled: state.general.isKimEnabled
})

const mapDispatchToProps = {}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

interface IState {
    tabIndex: number;
}

export class Initalisation extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            tabIndex: (props.currentKonsil.konsilStatus === 'IN_ARBEIT' && props.tabIndex > 6) ? 0 : props.tabIndex
        };
    }


    // componentDidUpdate(prevProps: Readonly<TPropsFromRedux>) {
    //     if (prevProps !== this.props){
    //         this.setState({
    //             tabIndex: (this.props.currentKonsil.konsilStatus === 'IN_ARBEIT' && this.props.tabIndex > 6) ? 0 : this.props.tabIndex
    //         });
    //     }
        
    // }

    render() {
        return (
            // Tab Daten was split in 2:
            // Betriebsstätte
            // Ärzte
            <div className="main-tab-container">
                <TabPanel className="tab-container" value={this.props.tabIndex} index={0}>
                    <PasswortComponent/>
                </TabPanel>
                <TabPanel className="tab-container" value={this.props.tabIndex} index={1}>
                    <BetriebsStaetteDatenComponent/>
                </TabPanel>
                <TabPanel className="tab-container" value={this.props.tabIndex} index={2}>
                    <AerzteDatenComponent/>
                </TabPanel>
                <TabPanel className="tab-container" value={this.props.tabIndex} index={3}>
                    <UpdateComponent/>
                </TabPanel>
                <TabPanel className="tab-container" value={this.props.tabIndex} index={4}>
                    <ZertifikateComponent arztZertifikatVorhanden={this.props.initialisationStatus.arztZertifikatErzeugt} message=""/>
                </TabPanel>
                {this.props.isKimEnabled &&
                    <>
                        <TabPanel className="tab-container" value={this.props.tabIndex} index={5}>
                            <KimConfigurationInitContainer/>
                        </TabPanel>
                        <TabPanel className="tab-container" value={this.props.tabIndex} index={6}>
                            <DirectoryServiceGdtInitContainer/>
                        </TabPanel>            
                    </>
                }                 
                <TabPanel className="tab-container" value={this.props.tabIndex} index = {this.props.isKimEnabled ? 7 : 5}>
                    <ZusammenfassungComponent/>
                </TabPanel>
            </div>
        );
    }
}

export const InitialisationContainer = connector(Initalisation);
export default InitialisationContainer;