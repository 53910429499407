import { Icd10Info, KatalogStatusType } from "telescan-core";

export enum EIcd10Actions {
    SET_ICD10_INFO = "SET_ICD10_INFO",
    SET_ICD10_INSTALLATION_STATUS = "SET_ICD10_INSTALLATION_STATUS",
}

export interface ISetIcd10Info {
    readonly type: EIcd10Actions.SET_ICD10_INFO;
    readonly icd10Info: Icd10Info[];
}

export interface ISetIcd10InstallationStatus {
    readonly type: EIcd10Actions.SET_ICD10_INSTALLATION_STATUS;
    readonly identifier: string;
    readonly installationStatus: keyof typeof KatalogStatusType;
}

export type TIcd10ActionTypes =
    ISetIcd10Info |
    ISetIcd10InstallationStatus;