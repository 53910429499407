import { Icd10Info } from 'telescan-core';
import { EIcd10Actions, TIcd10ActionTypes} from '../types/icd10_types';

export interface IIcd10State {
    icd10Info: Icd10Info[]
}

const initialState: IIcd10State = {
    icd10Info: []
}

export function icd10_reducer(state = initialState, action: TIcd10ActionTypes): IIcd10State {
    switch (action.type) {
        case EIcd10Actions.SET_ICD10_INFO:
            return {
                ...state,
                icd10Info: action.icd10Info,
            }
        case EIcd10Actions.SET_ICD10_INSTALLATION_STATUS:
            const icd10InfoIndex = state.icd10Info.findIndex(item => item.identifier === action.identifier)
            const newIcd10InfoArray = [...state.icd10Info];
            newIcd10InfoArray[icd10InfoIndex].status = action.installationStatus;
            return {
                ...state,
                icd10Info: newIcd10InfoArray,
            }
        default:
            return state
    }
}


