import React, { PropsWithChildren } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import {connect, ConnectedProps} from 'react-redux'
import {TelescanConfig} from 'telescan-core';
import { refresh } from '../../redux/actions/login_actions';
import { RootState } from '../../redux/reducers';

interface IState {
}

const mapStateToProps = (state: RootState, ownProps: RouteProps) => ({
    auth_failure: state.general.auth_failure,
    accessTokenExpirationDate: state.general.accessTokenExpirationDate,
    ...ownProps
})

const mapDispatchToProps = {
    dispatch_refresh: (token: string) => refresh( token ),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = PropsWithChildren<ConnectedProps<typeof connector>>


export class PrivateRoute extends React.Component<TPropsFromRedux, IState> {


    render() {
        let childComponent = this.props.children;
        if (Date.now() > this.props.accessTokenExpirationDate!) {
            const telescanConfig: TelescanConfig = TelescanConfig.getInstance();
            const refresh_token = telescanConfig.getConstant('REFRESH_TOKEN');
            if (refresh_token === "") {
                return(
                    <Route {...this.props}>
                        <Redirect to={{ pathname: '/login', state: { from: this.props.location } }} />
                    </Route>
                )
            }
            else {
                this.props.dispatch_refresh(refresh_token);
                if (this.props.auth_failure !== "") {
                    return(
                        <Route {...this.props}>
                            <Redirect to={{ pathname: '/login', state: { from: this.props.location } }} />
                        </Route>)
                } else {
                    return(<Route {...this.props}>{childComponent}</Route>)
                }
            }
        } else {
            return(<Route {...this.props}>{childComponent}</Route>)
        }
    }
}

export const PrivateRouteComponent=  connector(PrivateRoute);
export default PrivateRouteComponent
