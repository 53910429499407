import React from 'react';
import {connect, ConnectedProps} from 'react-redux'
import {RootState} from '../../redux/reducers';
// material components
import {Box, Button, Paper, Typography, Tooltip} from '@material-ui/core';
import UpdateTableContainer, { UpdateRow } from './update_table';
import { Edit, HighlightOff, PlayCircleFilledOutlined, Replay, Stop } from '@material-ui/icons';
import HPMKonfigurationComponent from '../initalisation/hpm_konfiguration';
import { getUpdateInformation } from '../../redux/actions/update_actions';
import { getIsHpmAvailableStatus, restartHpm, startHpm, stopHpm } from '../../redux/actions';
import { UploadPath } from 'telescan-core';


interface IState {
    successMessage: string;
    isEditHpmConfig: boolean;
}

const mapStateToProps = (state: RootState) => ({
    loading: state.general.loading,
    user: state.user.user,
    role: state.user.role,
    initialisationStatus: state.user.initialisationStatus,
    updateInfo: state.update.updateInfo,
    hpmUpdateSteps: state.update.hpmUpdateSteps,
    teleScanUpdateSteps: state.update.teleScanUpdateSteps,
    isHpmAvailableStatus: state.general.isHpmAvailable,
    hpmServiceStatus: state.general.hpmServiceStatus,
    initialisedServices: state.general.initialisedServices
})


const mapDispatchToProps = {
    dispatch_getUpdateInformation: () => getUpdateInformation(),
    dispatch_startHpm: () => startHpm(),
    dispatch_stopHpm: () => stopHpm(),
    dispatch_restartHpm: () => restartHpm(),
    dispatch_getIsHpmAvailableStatus: () => getIsHpmAvailableStatus(),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>



export class InstallationUpdates extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            successMessage: "",
            isEditHpmConfig: false,
        };

        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleDoneClick = this.handleDoneClick.bind(this);
    }

    componentDidMount() {
        this.props.dispatch_getUpdateInformation();
    }

    componentDidUpdate(prevProps: Readonly<TPropsFromRedux>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.hpmUpdateSteps !== this.props.hpmUpdateSteps && this.props.hpmUpdateSteps.every(item => item.status === "DONE")) {
            this.props.dispatch_getUpdateInformation();
        }
        if (prevProps.teleScanUpdateSteps !== this.props.teleScanUpdateSteps && this.props.teleScanUpdateSteps.every(item => item.status === "DONE")) {
            this.props.dispatch_getUpdateInformation();
        }
    }

    handleEditClick() {
        this.setState({
            isEditHpmConfig: true
        })
    }

    handleDoneClick() {
        this.setState({
            isEditHpmConfig: false
        })
        this.props.dispatch_getUpdateInformation();
        this.props.dispatch_getIsHpmAvailableStatus();
    }

    
    render() {
        const update_rows: UpdateRow[] = this.props.updateInfo.map(item => item as UpdateRow);

        return (
        <>
            <Box className="bsnr-info-container">
                <Box className="arzt-info">
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                        Software-Übersicht
                    </Typography>
                    <Button className="slim" variant="contained" color="primary" onClick={this.props.dispatch_getUpdateInformation}
                    >
                        Updates suchen
                    </Button>
                </Box>
                <Paper elevation={0}>
                    <Typography className="bsnr-info" variant="caption" color="inherit">
                        Hier werden die aktuell installierten, bzw. verfügbaren Versionen der TeleScan-Komponente und des HPM angezeigt.
                    </Typography>
                    <UpdateTableContainer data={update_rows} />
                </Paper>
            </Box>
            <Box className="bsnr-info-container">
                <Box className="hpm-configuration">
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                        HPM Konfiguration
                    </Typography>
                    {this.props.role === "DERMATOLOGE" &&
                        <>
                            <Tooltip title = "HPM neu starten">
                                <Button
                                    className="slim margin-left-auto margin-right"
                                    variant="contained"
                                    color="primary"
                                    onClick = {this.props.dispatch_restartHpm}
                                    disabled = {this.props.hpmServiceStatus === "WAITING_FOR_SERVER_RESPONSE"}
                                >
                                    <Replay/>
                                </Button>
                            </Tooltip>
                            {this.props.isHpmAvailableStatus ?
                                <Tooltip title = "HPM stoppen">
                                    <Button
                                        className="slim margin-right"
                                        variant="contained"
                                        color="primary"
                                        onClick = {this.props.dispatch_stopHpm}
                                        disabled = {this.props.hpmServiceStatus === "WAITING_FOR_SERVER_RESPONSE"}
                                    >
                                        <Stop/>
                                    </Button>
                                </Tooltip> :
                                <Tooltip title = "HPM starten">
                                    <Button
                                        className="slim margin-right"
                                        variant="contained"
                                        color="primary"
                                        onClick = {this.props.dispatch_startHpm}
                                        disabled = {this.props.hpmServiceStatus === "WAITING_FOR_SERVER_RESPONSE"}
                                    >
                                        <PlayCircleFilledOutlined/>
                                    </Button>
                                </Tooltip>
                            }
                        </>
                    }
                    <Tooltip title="Konfiguration bearbeiten">
                        <Button
                            className = {this.props.role === "HAUSARZT" ? "slim margin-left-auto" : "slim"}
                            variant="contained"
                            color="primary"
                            onClick={this.handleEditClick}
                        >
                            <Edit/>
                        </Button>
                    </Tooltip>
                </Box>
                <Paper className="initialisation-content initialisation-overview hpm-config popup-insert pad-bottom" elevation={0}>
                    {this.props.initialisedServices.includes(UploadPath.HPM) ?
                        this.props.initialisationStatus.standalone ?
                            <>
                                <Typography className="bsnr-info" variant="caption" color="inherit">
                                    Ändern Sie hier die URL, unter der Ihr HÄVG-Prüfmodul (HPM) verfügbar ist.
                                </Typography>
                                <HPMKonfigurationComponent handleDoneClick={this.handleDoneClick}  isEdit={this.state.isEditHpmConfig}/>
                            </>
                            :
                            <Typography className="bsnr-info" variant="caption" color="inherit">
                                Die Konfiguration des HÄVG-Prüfmodul (HPM) muss über Ihr Praxisverwaltungssystem vorgenommen werden.
                            </Typography>
                        :
                        <Box className = "flex-row-end">
                            <Typography className="bsnr-info" variant="caption" color="inherit">
                                HPM ist nicht freigeschaltet
                            </Typography>
                            <HighlightOff className = 'out-of-date margin-left-auto margin-right'/>  
                        </Box>   
                    }
                       
                </Paper>
            </Box>
        </>
        );
    }
}

export const InstallationUpdatesContainer = connector(InstallationUpdates);
export default InstallationUpdatesContainer;
