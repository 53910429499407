import React from 'react';
import {connect, ConnectedProps} from 'react-redux'

import {Arzt, Konsil, Patient, TelescanConfig, KonsilStatusType, BearbeitungsStatusType, UploadPath } from 'telescan-core';

import {
    changeKonsilTablePage,
    changeKonsilTabelRowsPerPage,
    login,
    logout,
    loadZertifikate,
    getUserWithToken,
} from '../../redux/actions';
import {RootState} from '../../redux/reducers';
import {DermaKonsilTableContainer, KonsilTableContainer} from '.';
import LanrSelectionContainer from '../login/lanr_selection';
import ZertifikateComponent from '../initalisation/zertifikate';
import { Paper, Box, Button, Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { needsUpdate } from '../../utils/utils';
import SimpleModalContainer from '../elements/simple_modal';
import { getDermatologistRegistrationStatus } from '../../redux/actions/kim_actions';


export interface KonsilRowHausarzt {
    adressat: string;
    id: string;
    patient: Patient;
    date_created: number;
    konsilStatus: keyof typeof KonsilStatusType;
    uploadPath: keyof typeof UploadPath;
    bearbeitungsStatus: keyof typeof BearbeitungsStatusType;
    konsiliar: Arzt;
    konsil: Konsil
}

export interface KonsilRowDerma {
    id: string;
    patient: Patient| undefined;
    date_created: any;
    date_send: number;
    letzterBearbeitungszeitpunkt: number;
    konsilStatus: keyof typeof KonsilStatusType;
    bearbeitungsStatus: keyof typeof BearbeitungsStatusType;
    senderArzt: Arzt;
    senderLanr: string;
    konsil: Konsil | undefined;
    isMetaData: boolean;
    dokumentenId: string | undefined;
    uploadPath: keyof typeof UploadPath;
}


const mapStateToProps = (state: RootState) => ({
    queryString: state.router.location.search,
    location: state.router.location.pathname,
    konsile: state.konsile.konsile,
    loading: state.general.loading,
    userDetails: state.user.user,
    role: state.user.role,
    arztList: state.user.betriebsstaettenAerzte,
    metadatenAdressiert: state.dermatologe.metadatenAdressiert,
    kimMetadatenAdressiert: state.kim.kimMetadaten,
    metadatenGerichtet: state.dermatologe.metadatenGerichtet,
    totalSize: state.konsile.totalSize,
    initialisationStatus: state.user.initialisationStatus,
    zertifikatArztVorhanden: state.user.zertifikatArztVorhanden,
    zertifikatBetriebsstaetteVorhanden: state.user.zertifikatBetriebsstaetteVorhanden,
    zertifikatStatus: state.zertifikate.zertifikatStatus,
    arztZertifikateMetaInfo: state.zertifikate.arztZertifikateMetaInfo,
    bsZertifikatMetaInfo: state.zertifikate.betriebsstaettenZertifikatMetaInfo,
    initialisedServices: state.general.initialisedServices,
})

const mapDispatchToProps = {
    dispatch_login: (bsnr: string, password: string, lanr: string | null) => login(bsnr, password, lanr),
    dispatch_changePage: (page: number) => changeKonsilTablePage(page),
    dispatch_changeRowsPerPage: (rowsPerPage: number) => changeKonsilTabelRowsPerPage(rowsPerPage),
    dispatch_logout: () => logout(),
    dispatch_loadZertifikate: () => loadZertifikate(),
    dispatch_getUserWithToken: (refresh_token: string, targetPath) => getUserWithToken(refresh_token, targetPath),
    dispatch_getDermatologistRegistrationStatus: () => getDermatologistRegistrationStatus(),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>


interface IState {
    showPopup: boolean;
    error: string;
    isOpenCreateZertifikatModal: boolean;
    isOpenRefreshZertifikatModal: boolean;
}

export class KonsilOverview extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            showPopup: false,
            error: "",
            isOpenCreateZertifikatModal: false,
            isOpenRefreshZertifikatModal: false,
        };
    }

    componentDidUpdate(prevProps: Readonly<TPropsFromRedux>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.userDetails !== this.props.userDetails) {
            if (this.props.userDetails[0]?.bsnr !== "" && this.props.userDetails[0]?.bsnr !== null) {
                this.checkUserStatus(this.props.userDetails)
            } else
                console.log("Ungültige BSNR")
        }
        if (this.props.initialisedServices.includes(UploadPath.HPM)) {
            if (prevProps.zertifikatArztVorhanden !== this.props.zertifikatArztVorhanden || prevProps.zertifikatBetriebsstaetteVorhanden !== this.props.zertifikatBetriebsstaetteVorhanden)
            this.checkUserCertificateStatus();
        if (prevProps.zertifikatStatus !== this.props.zertifikatStatus ) {
            this.checkUserCertificateStatus();}
        }
      
    }

    componentDidMount() {
        if (this.props.userDetails.length > 0 && this.props.userDetails[0]?.bsnr !== "" && this.props.userDetails[0]?.bsnr !== null) {
            this.checkUserStatus(this.props.userDetails)
        }
        if ((this.props.role === "DERMATOLOGE" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE") && this.props.initialisedServices.includes(UploadPath.KIM)) {
            this.props.dispatch_getDermatologistRegistrationStatus();
        }
    }
    checkUserStatus = (arztList: Arzt[]) => {
        if (this.props.role === "BETRIEBSSTAETTE_HAUSARZT" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE") {
            if (arztList.length === 0) {
                this.setState({
                    error: "Keine Ärzte mit gültigen Arztzertifikaten in der Betriebsstätte vorhanden!"
                }, () => this.newPopup());
            } else if (arztList.length === 1) {
                this.automaticLogin(arztList[0].lanr)
            } else {
                this.newPopup();
            }
        }
    }

    checkUserCertificateStatus = () => {
        if (((this.props.role === "DERMATOLOGE" || this.props.role === "HAUSARZT") && !this.props.zertifikatArztVorhanden) || !this.props.zertifikatBetriebsstaetteVorhanden)
            {this.openCreateCertificatePopup()}
        /*
        if (this.props.initialisationStatus.standalone && (((this.props.role === "DERMATOLOGE" || this.props.role === "HAUSARZT") && (this.props.zertifikatStatus.arztZertifikat !== "AKTIV" || ((this.props.arztZertifikateMetaInfo.filter(zert => zert.lanr === localStorage.getItem("LANR")).length > 0)? needsUpdate(new Date(this.props.arztZertifikateMetaInfo.filter(zert => zert.lanr === localStorage.getItem("LANR"))[0].gueltigBis), 1): false))) || 
            this.props.zertifikatStatus.betriebsstaettenZertifikat !== "AKTIV" || needsUpdate(new Date(this.props.bsZertifikatMetaInfo.gueltigBis), 1)) )
            {this.openRefreshZertifikatPopup()}
        else if (!this.props.initialisationStatus.standalone && (((this.props.role === "DERMATOLOGE" || this.props.role === "HAUSARZT") && this.props.zertifikatStatus.arztZertifikat !== "AKTIV") || 
            this.props.zertifikatStatus.betriebsstaettenZertifikat !== "AKTIV") )
            {this.openRefreshZertifikatPopup()}
        else
            this.closeRefreshZertifikatPopup()
        */
        if (this.props.role === "DERMATOLOGE") {
            if (this.props.initialisationStatus.standalone && ((this.props.zertifikatStatus.arztZertifikat !== "AKTIV" || ((this.props.arztZertifikateMetaInfo.filter(zert => zert.lanr === localStorage.getItem("LANR")).length > 0)? needsUpdate(new Date(this.props.arztZertifikateMetaInfo.filter(zert => zert.lanr === localStorage.getItem("LANR"))[0].gueltigBis), 1): false)) || 
                this.props.zertifikatStatus.betriebsstaettenZertifikat !== "AKTIV" || needsUpdate(new Date(this.props.bsZertifikatMetaInfo.gueltigBis), 1)) )
                {this.openRefreshCertificatePopup()}
            else if (!this.props.initialisationStatus.standalone && (this.props.zertifikatStatus.arztZertifikat !== "AKTIV" || 
                this.props.zertifikatStatus.betriebsstaettenZertifikat !== "AKTIV") )
                {this.openRefreshCertificatePopup()}
            else
                this.closeRefreshCertificatePopup()
        }
        if (this.props.role === "HAUSARZT") {
            if (this.props.initialisationStatus.standalone && (this.props.zertifikatStatus.arztZertifikat === "GESPERRT" || this.props.zertifikatStatus.arztZertifikat === "GELOESCHT" ||
                this.props.zertifikatStatus.betriebsstaettenZertifikat === "GESPERRT" || this.props.zertifikatStatus.betriebsstaettenZertifikat === "GELOESCHT"))
                {this.openRefreshCertificatePopup()}
            else if (!this.props.initialisationStatus.standalone && (this.props.zertifikatStatus.arztZertifikat === "GESPERRT" || this.props.zertifikatStatus.arztZertifikat === "GELOESCHT" ||
            this.props.zertifikatStatus.betriebsstaettenZertifikat === "GESPERRT" || this.props.zertifikatStatus.betriebsstaettenZertifikat === "GELOESCHT") )
                {this.openRefreshCertificatePopup()}
            else
                this.closeRefreshCertificatePopup()
        }
    }

    openCreateCertificatePopup = () => {
        this.setState({
            isOpenCreateZertifikatModal: true
        })
    }

    closeCreateCertificatePopup = () => {
        this.setState({
            isOpenCreateZertifikatModal: false
        })
    }

    openRefreshCertificatePopup = () => {
        this.setState({
            isOpenRefreshZertifikatModal: true
        })
    }

    closeRefreshCertificatePopup = () => {
        this.setState({
            isOpenRefreshZertifikatModal: false
        })
    }

    newPopup = () => {
        this.setState({
            showPopup: true
        });
    };

    automaticLogin = (lanr: string | undefined) => {
        localStorage.setItem("LANR", lanr || "");
        TelescanConfig.getInstance().setConstant("LANR", lanr || "");
        let bsnr = (this.props.userDetails.length > 0) ? this.props.userDetails[0].bsnr: "";
        const password = TelescanConfig.getInstance().getConstant("PASSWORD")
        this.props.dispatch_logout();
        this.props.dispatch_login(bsnr || "", password, lanr || "")
        .catch( (error) => {
            console.log(error);
            this.props.dispatch_logout();
        })
    }

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.dispatch_changeRowsPerPage(parseInt(event.target.value, 10));
    };

    handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        this.props.dispatch_changePage(newPage);
    };


    render() {
        const konsilRowsHausarzt: KonsilRowHausarzt[] =
        this.props.konsile ?
        this.props.konsile.map((konsil: Konsil) => {
            return {
                adressat: konsil.adressat,
                id: konsil.id,
                patient: konsil.konsilPatient,
                date_created: konsil.dateCreated,
                konsilStatus: konsil.konsilStatus,
                uploadPath: konsil.uploadPath,
                bearbeitungsStatus: konsil.bearbeitungsStatus,
                konsiliar: konsil.konsiliar,
                konsil: konsil
            } as KonsilRowHausarzt;
            }) :
        [];

        const konsilRowsDerma: KonsilRowDerma[] =
        this.props.konsile ?
        this.props.konsile.map((konsil) => {
            return {
                id: konsil.id,
                patient: konsil.konsilPatient,
                date_created: konsil.dateCreated,
                date_send: konsil.dateSend,
                konsilStatus: konsil.konsilStatus,
                bearbeitungsStatus: konsil.bearbeitungsStatus,
                senderArzt: konsil.beauftragender_arzt,
                konsil: konsil,
                isMetaData: false,
                dokumentenId: undefined,
                uploadPath: konsil.uploadPath
            } as KonsilRowDerma;
            }) : 
        [];

        const metadataRowsAdressiert: KonsilRowDerma[] = [];
        this.props.metadatenAdressiert.forEach((metainfo) => {
            const senderArztToSave: Arzt = new Arzt();
            senderArztToSave.nachname = metainfo.senderArzt;
            metadataRowsAdressiert.push({
                id: metainfo.vorgangsId,
                patient: undefined, // replace with actually empty thing
                date_created: undefined,
                letzterBearbeitungszeitpunkt: metainfo.letzterBearbeitungszeitpunkt,
                konsilStatus: metainfo.statusTelekonsil,
                bearbeitungsStatus: metainfo.bearbeitungsStatus,
                senderArzt: senderArztToSave,
                senderLanr: metainfo.senderLanr,
                konsil: undefined,
                isMetaData: true,
                dokumentenId: metainfo.dokumentIdentifikator,
                uploadPath: metainfo.uploadPath,
            } as KonsilRowDerma)
        });
        this.props.kimMetadatenAdressiert.forEach((metainfo) => {
            const senderArztToSave: Arzt = new Arzt();
            senderArztToSave.nachname = metainfo.senderArzt;
            metadataRowsAdressiert.push({
                id: metainfo.vorgangsId,
                patient: undefined, // replace with actually empty thing
                date_created: undefined,
                letzterBearbeitungszeitpunkt: metainfo.letzterBearbeitungszeitpunkt,
                konsilStatus: metainfo.statusTelekonsil,
                bearbeitungsStatus: metainfo.bearbeitungsStatus,
                senderArzt: senderArztToSave,
                senderLanr: metainfo.senderLanr,
                konsil: undefined,
                isMetaData: true,
                dokumentenId: metainfo.dokumentIdentifikator,
                uploadPath: metainfo.uploadPath,
            } as KonsilRowDerma)
        });
        metadataRowsAdressiert.sort((a, b) => a.letzterBearbeitungszeitpunkt - b.letzterBearbeitungszeitpunkt); // sort by date

        const metadataRowsGerichtet: KonsilRowDerma[] = [];
        this.props.metadatenGerichtet.forEach((metainfo) => {
            const senderArztToSave: Arzt = new Arzt();
            senderArztToSave.nachname = metainfo.senderArzt;
            metadataRowsGerichtet.push({
                id: metainfo.vorgangsId,
                patient: undefined, // replace with actually empty thing
                date_created: undefined,
                letzterBearbeitungszeitpunkt: metainfo.letzterBearbeitungszeitpunkt,
                konsilStatus: metainfo.statusTelekonsil,
                bearbeitungsStatus: metainfo.bearbeitungsStatus,
                senderArzt: senderArztToSave,
                senderLanr: metainfo.senderLanr,
                konsil: undefined,
                isMetaData: true,
                dokumentenId: metainfo.dokumentIdentifikator,
                uploadPath: metainfo.uploadPath,
            } as KonsilRowDerma)
        })

        return (
            <div className="konsil-uebersicht-container">
                {(this.props.role === "HAUSARZT" || this.props.role === "BETRIEBSSTAETTE_HAUSARZT") &&
                    <KonsilTableContainer
                        data = {konsilRowsHausarzt}
                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        handleChangePage={this.handleChangePage}
                    />
                }
                {(this.props.role === "DERMATOLOGE" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE") &&
                    <DermaKonsilTableContainer
                        data = {konsilRowsDerma}
                        metadataAdressiert = {metadataRowsAdressiert}
                        metadataGerichtet = {metadataRowsGerichtet}
                        totalSize={this.props.totalSize}
                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        handleChangePage={this.handleChangePage}
                    />
                }
                <LanrSelectionContainer
                    showPopup={this.state.showPopup}
                    bsnr={this.props.userDetails[0]?.bsnr || ""}
                    lanr={this.props.userDetails[0]?.lanr || ""}
                    onConfirm={this.automaticLogin} error={this.state.error}
                />

                <SimpleModalContainer isOpen={this.state.isOpenCreateZertifikatModal} >
                        <Paper className="modal-wrapper konsil-question-pop">
                            <ZertifikateComponent message="Achtung! Ihre Zertifikate sind unvollständig." arztZertifikatVorhanden={this.props.zertifikatArztVorhanden} />
                        <Box className="modal-footer">
                                <Button disabled={!this.props.initialisationStatus.betriebsstaetteZertifikatErzeugt || !this.props.zertifikatArztVorhanden} 
                                    variant="contained" color="primary" 
                                    onClick={() => {this.closeCreateCertificatePopup(); this.props.dispatch_loadZertifikate()}}>
                                    Fertig
                                </Button>
                            </Box>
                        </Paper>
                </SimpleModalContainer>
                <SimpleModalContainer isOpen={this.state.isOpenRefreshZertifikatModal} >
                    <Paper className="modal-wrapper">
                        <Box className="modal-content konsil-submit-feedback">
                            <h2>Zertifikate aktualisieren</h2>
                            <Warning className="konsil-submit-alert"/>
                            <Box>{this.props.initialisationStatus.standalone? 
                                <Typography variant="body1" color="inherit" style={{width: "60vw"}}>
                                    Achtung! Eines Ihrer Zertifikate ist nicht aktiv, bzw. läuft in Kürze ab. 
                                    Dies beeinflusst das Senden und Empfangen von Telekonsilen.
                                    Bitte aktualisieren Sie Ihre Zertifikate in den Einstellungen.
                                </Typography>
                                :
                                <Typography variant="body1" color="inherit" style={{width: "60vw"}}>
                                    Achtung! Eines Ihrer Zertifikate ist nicht aktiv, bzw. läuft in Kürze ab. 
                                    Dies beeinflusst das Senden und Empfangen von Telekonsilen.
                                    Bitte aktualisieren Sie Ihre Zertifikate in Ihrem Praxisverwaltungssystem.
                                </Typography>
                                }
                            </Box>
                        </Box>
                        <Box className="modal-footer">
                            <Button variant="contained" color="primary" onClick={() => this.closeRefreshCertificatePopup()}>
                                OK
                            </Button>
                        </Box>
                    </Paper>
                </SimpleModalContainer>
            </div>
        );
    }
}

export const KonsilOverviewContainer = connector(KonsilOverview);
export default KonsilOverviewContainer;
