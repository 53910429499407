import React from "react";
import {connect, ConnectedProps} from 'react-redux';

import {
    Box,
    Typography,
    Paper,
    FormControl,
    TextField,
    FormControlLabel,
    Checkbox,
    Tooltip,
    Button,
    FormHelperText,
    InputLabel
} from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import { Arzt, ArztBasisdaten } from "telescan-core";
import { RootState } from "../../redux/reducers";
import { ViewText } from "../konsil";
import { setBetriebsstaettenAerzte, checkBasisdatenArztStatus } from "../../redux/actions";
import uuid from 'uuid';

const mapStateToProps = (state: RootState) => ({
    basisdatenAerzte: state.user.betriebsstaettenAerzte,
    basisdatenArztErrors: state.user.basisdatenArztErrors,
    role: state.user.role
})

const mapDispatchToProps = {
    dispatch_setBetriebsstaettenAerzte: (aerzte: Arzt[]) => setBetriebsstaettenAerzte(aerzte),
    dispatch_checkBasisdatenArztStatus: (aerzte: Arzt[]) => checkBasisdatenArztStatus(aerzte),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>



export class ADaten extends React.Component<TPropsFromRedux> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {};

        this.knownLanr = this.props.basisdatenAerzte.map((item) => item?.lanr);
        this.emptyArzt = new ArztBasisdaten();
        this.emptyArzt.id = "14695845-76f6-4a60-a391-a39c47dc57b7";

        this.updateAerzte = this.updateAerzte.bind(this);
        this.addArzt = this.addArzt.bind(this);
        this.deleteArzt = this.deleteArzt.bind(this);
    }

    private knownLanr: (string | undefined)[];
    private emptyArzt: ArztBasisdaten;

    componentDidMount() {
        // check if there are errors in the basis data
        this.props.dispatch_checkBasisdatenArztStatus(this.props.basisdatenAerzte);
    }

    updateAerzte(arzt: ArztBasisdaten) {
        // this.props.dispatch_setBetriebsstaettenAerzte(arzt);
        arzt.bsnr = localStorage.getItem("BSNR") || undefined;
        if (this.props.basisdatenAerzte.length === 0 || (this.props.basisdatenAerzte.length === 1 && this.props.basisdatenAerzte[0].id === "14695845-76f6-4a60-a391-a39c47dc57b7")){
            this.props.dispatch_setBetriebsstaettenAerzte([arzt as Arzt]);
        }
        else {
            let matchingArztIndex: number = this.props.basisdatenAerzte.findIndex((value, index, obj) => value.id === arzt.id)
            if (matchingArztIndex !== -1){
                this.props.basisdatenAerzte[matchingArztIndex] = arzt}
            else {
                //this.props.basisdatenAerzte.push(arzt)
                console.log("neuer Arzt muss hinzugefügt werden")
            }
            this.props.dispatch_setBetriebsstaettenAerzte(this.props.basisdatenAerzte);
        }
    }

    addArzt() {
        let aerzte = this.props.basisdatenAerzte;
        let arzt: Arzt = new Arzt();
        arzt.id = uuid.v4();
        aerzte.push(arzt);
        this.props.dispatch_setBetriebsstaettenAerzte(aerzte);
    }

    deleteArzt(id: string) {
        let aerzte = this.props.basisdatenAerzte.filter((item) => item.id !== id);
        this.props.dispatch_setBetriebsstaettenAerzte(aerzte);
    }


    render() {
        return (
            <Box className="main-content initialisation-content">
                <Typography className="bs-doctors-description" variant="caption" color="inherit">
                    Es muss mindestens ein verantwortlicher Arzt festgelegt und dessen Daten hinterlegt werden.
                    Weitere Ärzte können jetzt oder zu jedem späteren Zeitpunkt hinzugefügt werden.
                    Nur Ärzte mit vollständig erfassten Arztdaten können Konsile senden und empfangen.
                </Typography>
                <Box className="bs-doctors-header">
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                        Daten der verantwortlichen Ärzte
                    </Typography>
                    <Tooltip title="Weiteren Arzt erfassen" placement="left">
                        <Button className="bs-add-doctor slim" variant="contained" color="primary" size="small"
                                onClick={this.addArzt}
                            >
                            <Add/>
                        </Button>
                    </Tooltip>
                </Box>
                {/* <ArztDatenComponent isEdit={true} keyProp={uuid.v4()} index={0} basisdatenArzt={new Arzt()} bsnr={localStorage.getItem("BSNR") || undefined} deleteArzt={this.deleteArzt} updateAerzte={this.updateAerzte} basisdatenArztErrors={this.props.basisdatenArztErrors}/> */}
                {this.props.basisdatenAerzte.length === 0 &&
                    <ADatenComponent isEdit={true} key={this.emptyArzt.id || ""} index={0}
                        basisdatenArzt={this.emptyArzt} bsnr={localStorage.getItem("BSNR") || undefined}
                        deleteArzt={this.deleteArzt} updateAerzte={this.updateAerzte}
                        basisdatenArztErrors={this.props.basisdatenArztErrors}
                        role={this.props.role}
                    />
                }
                {this.props.basisdatenAerzte.length > 0 && this.props.basisdatenAerzte.map((arzt, index) =>
                <ADatenComponent isEdit={(arzt?.lanr)? !this.knownLanr.includes(arzt?.lanr): true}
                    key={arzt.id || ""}
                    index={index} basisdatenArzt={arzt} bsnr={localStorage.getItem("BSNR") || undefined}
                    deleteArzt={this.deleteArzt} updateAerzte={this.updateAerzte}
                    basisdatenArztErrors={this.props.basisdatenArztErrors}
                    role={this.props.role}
                />
                )}

            </Box>
        )
    }
}

export const AerzteDatenComponent = connector(ADaten);
export default AerzteDatenComponent;


interface IArztProps {
    basisdatenArzt: ArztBasisdaten;
    updateAerzte: (arzt: ArztBasisdaten) => void;
    deleteArzt: ((id: string) => void) | undefined;
    bsnr: string | undefined;
    basisdatenArztErrors: Map<string, string>;
    index: number;
    isEdit: boolean;
    role: "BETRIEBSSTAETTE_HAUSARZT" | "BETRIEBSSTAETTE_DERMATOLOGE" | "HAUSARZT" | "DERMATOLOGE"
}

interface IArztState {
    basisdatenArzt: ArztBasisdaten;

}

export class ADatenComponent extends React.Component<IArztProps, IArztState> {
    constructor(props: IArztProps) {
        super(props);
        this.state = {
            basisdatenArzt: this.props.basisdatenArzt,
        };
    }

    componentDidMount() {
        if (this.props.index === 0){
            let updatedArzt = {
                ...this.state.basisdatenArzt,
            } as Arzt

            if (!updatedArzt.lanr) {
                updatedArzt.lanr = localStorage.getItem("LANR")!
            }

            this.setState({
                basisdatenArzt: updatedArzt
            })
        }
    }

    renderArztTextfield(prop: string, label: string, error?: string) {
        if (prop === "telefon" || prop === "telefonMobil" || prop === "fax")
            return(
                <TextField
                    required={error? true: false}
                    error={error? (this.props.basisdatenArztErrors.has(String(this.props.index) + ".arzt."+error)): false}
                    id={"arzt-"+prop}
                    label={label}
                    placeholder={label}
                    value={this.state.basisdatenArzt?.[prop] || ""}
                    onChange={(event) => {
                        let updatedArzt = {...this.state.basisdatenArzt} as Arzt
                        updatedArzt[prop] = event.target.value.replace(" ", "").replace("-", "").replace(/[^0-9+]+$/, "");
                        this.setState({
                            basisdatenArzt: updatedArzt
                        })}}
                    onBlur={() => this.props.updateAerzte(this.state.basisdatenArzt)}
                    autoComplete="off"
                />
            )
        else
            return(
                <TextField
                    required={error? true: false}
                    error={error? this.props.basisdatenArztErrors.has(String(this.props.index) + ".arzt."+error): false}
                    id={"arzt-"+prop}
                    label={label}
                    placeholder={label}
                    value={this.state.basisdatenArzt?.[prop] || ""}
                    onChange={(event) => {
                        let updatedArzt = {...this.state.basisdatenArzt} as Arzt
                        updatedArzt[prop] = event.target.value

                        this.setState({
                            basisdatenArzt: updatedArzt
                        })}}
                    onBlur={() => this.props.updateAerzte(this.state.basisdatenArzt)}
                    autoComplete="off"
                />
            )
    }


    renderLANRfield() {
        if (this.props.isEdit && this.props.index !== 0)
        return(
            this.renderArztTextfield("lanr", "LANR", "lanr")
        )
        else if (this.props.index === 0)
            return (
                <ViewText
                    labelVariant='caption'
                    label='LANR'
                    text={localStorage.getItem("LANR") || ""}
                />
            )
        else
            return (
                <ViewText
                    labelVariant='caption'
                    label='LANR'
                    text={this.state.basisdatenArzt?.lanr || ""}
                />
            )
    }

    render() {
        return (
            <form className="form-content" noValidate autoComplete="off" style={{marginBottom: "8px"}}>
                <Paper className="bs-infos-card" elevation={0}>
                    <Box className="flex-row">
                        <ViewText
                            labelVariant='caption'
                            label='BSNR'
                            text={this.props.bsnr || "-"}
                        />
                        <Box className="pad-left">
                            {this.renderLANRfield()}
                        </Box>

                    </Box>

                    {(this.props.deleteArzt && this.props.isEdit) &&
                    <Tooltip  title="Lösche Arzt" placement="left">
                        <Button className="slim bs-doctors-delete" variant="contained" color="primary" size="small" style={{maxWidth: "40px", maxHeight: "40px"}}
                            onClick={() => {if (this.props.deleteArzt) this.props.deleteArzt(this.state.basisdatenArzt.id || "")}}
                        >
                            <Delete/>
                        </Button>
                    </Tooltip>
                    }
                    {!(this.props.deleteArzt && this.props.isEdit) &&
                    <Box ></Box>
                    }

                    <Box className="bs-left">
                        {this.renderArztTextfield("titel", "Titel")}
                        {this.renderArztTextfield("vorname", "Vorname", "vorname")}
                        {this.renderArztTextfield("nachname", "Nachname", "nachname")}
                        <Typography className={(this.props.basisdatenArztErrors.has(String(this.props.index) + ".arzt.adresse"))?
                                                "bs-doctor-subheader error": "bs-doctor-subheader"}
                                    variant="subtitle1" color="inherit">
                            Adresse, wenn von Betriebsstättenadresse abweichend.
                        </Typography>
                        {/* <FormControl
                            error={this.props.basisdatenArztErrors.has(String(this.props.index) + ".arzt.adresse")}
                        >
                            <InputLabel shrink id="adress-label"><Typography className="bs-doctor-subheader" variant="subtitle1" color="inherit">
                            Adresse, wenn von Betriebsstättenadresse abweichend.
                        </Typography></InputLabel> */}
                            {this.renderArztTextfield("strasseHausnummer", "Straße / Hausnummer")}
                            <Box className="adress">
                                {this.renderArztTextfield("plz", "PLZ")}
                                {this.renderArztTextfield("ort", "Ort")}
                            </Box>
                        {/* </FormControl> */}
                    </Box>
                    <Box className="bsdata-right">
                        {(this.props.role === "HAUSARZT" || this.props.role === "BETRIEBSSTAETTE_HAUSARZT") && this.renderArztTextfield("haevgMitgliedsNummer", "HÄVG-Mitgliedsnummer", "haevgMitgliedsNummer")}
                        {(this.props.role === "DERMATOLOGE" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE") && this.renderArztTextfield("mediId", "Medi-ID", "mediId")}
                        <FormControl
                            required
                            error={this.props.basisdatenArztErrors.has(String(this.props.index) + ".arzt.kontakt")}
                        >
                            <InputLabel shrink id="contact-data-label">Kontaktdaten</InputLabel>
                            {this.renderArztTextfield("telefon", "Telefon")}
                            {this.renderArztTextfield("fax", "Fax")}
                            {this.renderArztTextfield("telefonMobil", "Mobil")}
                        </FormControl>
                        <Typography className="bs-doctor-subheader" variant="subtitle1" color="inherit">
                            Zustimmung zum automatisierten Abruf von Dokumenten
                        </Typography>
                        <FormControl error={this.props.basisdatenArztErrors.has("arzt.abrufArzt")}>
                            {/* <FormLabel className="" component="legend" required
                                error={this.props.basisdatenArztErrors.has("arzt.abrufArzt")}
                            >Berechtigung erteilt</FormLabel> */}

                            <FormControlLabel
                                control={<Checkbox name="berechtigung"
                                                    required
                                                    checked={this.state.basisdatenArzt?.abrufArzt || false}
                                                    onChange={(event) => {
                                                        let updatedArzt = {
                                                            ...this.state.basisdatenArzt,
                                                            abrufArzt: event.target.checked
                                                        } as Arzt

                                                    this.setState({
                                                        basisdatenArzt: updatedArzt
                                                    }, () => this.props.updateAerzte(this.state.basisdatenArzt))}}
                                                />
                            } label="Berechtigung erteilt"
                            />
                            {this.props.basisdatenArztErrors.has("arzt.abrufArzt") &&
                                <FormHelperText>{this.props.basisdatenArztErrors.get("arzt.abrufArzt") }</FormHelperText>
                            }
                        </FormControl>
                    </Box>
                </Paper>
            </form>
        )
    }
}
