import { Box, Typography } from "@material-ui/core";
import React from "react";
import { Arzt, DermatologistInfo } from "telescan-core";

interface IProps {
    title: string;
    receiverHPM?: Arzt;
    receiverKIM?: DermatologistInfo;
    isSelected?: boolean;
    handleClick?: () => void;
}

export class RecieverCardComponent extends React.Component<IProps> {

    render() {
        if (this.props.receiverHPM)
            return (
                <Box className="last-reciver" onClick={this.props.handleClick}>
                    <Typography className="last-reciver-label bold-label" variant="subtitle1"
                                color="inherit">{this.props.title}</Typography>
                    <Box
                        className={(this.props.receiverHPM.nachname !== "" && this.props.receiverHPM.nachname !== undefined) ? (this.props.isSelected) ? "reciver-card active" : "last-reciver-card" : "reciver-card"}>
                        <Typography variant="h4"
                                    color="inherit">{(this.props.receiverHPM.nachname !== "" && this.props.receiverHPM.nachname !== undefined) ? [this.props.receiverHPM.titel, this.props.receiverHPM.vorname, this.props.receiverHPM.nachname].filter(item => (item !== null && item !== "")).join(" ") : "Dr. Vorname Nachname"}</Typography>
                        <Typography variant="caption" color="inherit">Dermatologe</Typography>
                        <Typography className="reciver-credentials" variant="caption"
                                    color="inherit">LANR: {this.props.receiverHPM.lanr}</Typography>
                        <Typography className="reciver-credentials" variant="caption"
                                    color="inherit">BSNR: {this.props.receiverHPM.betriebsstaette?.bsnr || ""}</Typography>
                        <Typography variant="caption"
                                    color="inherit">{(this.props.receiverHPM.betriebsstaette?.strasseHausnummer) ? this.props.receiverHPM.betriebsstaette?.strasseHausnummer + ", " : "Strasse und Hausnummer"}</Typography>
                        <Typography variant="caption"
                                    color="inherit">{(this.props.receiverHPM.betriebsstaette?.plz && this.props.receiverHPM.betriebsstaette?.ort) ? this.props.receiverHPM.betriebsstaette?.plz + " " + this.props.receiverHPM.betriebsstaette?.ort : "PLZ und Ort"}</Typography>
                    </Box>
                </Box>
            )
        if (this.props.receiverKIM)
            return (
                <Box className="last-reciver" onClick={this.props.handleClick}>
                    <Typography className="last-reciver-label bold-label" variant="subtitle1"
                                color="inherit">{this.props.title}</Typography>
                    <Box
                        className={(this.props.receiverKIM.lastName !== "" && this.props.receiverKIM.lastName !== undefined) ? (this.props.isSelected) ? "reciver-card active" : "last-reciver-card" : "reciver-card"}>
                        <Typography variant="h4"
                                    color="inherit">{(this.props.receiverKIM.lastName !== "" && this.props.receiverKIM.lastName !== undefined) ? [this.props.receiverKIM.title, this.props.receiverKIM.firstName, this.props.receiverKIM.lastName].filter(item => (item !== null && item !== "")).join(" ") : "Dr. Vorname Nachname"}</Typography>
                        <Typography variant="caption" color="inherit">Dermatologe</Typography>
                        <Typography className="reciver-credentials" variant="caption"
                                    color="inherit">LANR: {this.props.receiverKIM.lanr}</Typography>
                        <Typography className="reciver-credentials" variant="caption"
                                    color="inherit">BSNR: {this.props.receiverKIM.bsnr || ""}</Typography>
                        <Typography variant="caption"
                                    color="inherit">{(this.props.receiverKIM.postalCode && this.props.receiverKIM.city) ? this.props.receiverKIM.postalCode + " " + this.props.receiverKIM.city : "PLZ und Ort"}</Typography>
                    </Box>
                </Box>
            )
    }
}