import React from 'react';
import {Box, Button, FormControl, FormLabel, Paper, TextField, Typography} from '@material-ui/core';
import {Konsil, Vorbehandlung} from 'telescan-core';
import {RootState} from "../../../../redux/reducers";
import {checkKonsilFrageStatus, setIsPending, updateKonsil} from "../../../../redux/actions";
import {connect, ConnectedProps} from "react-redux";
import { Add } from '@material-ui/icons';
import { PretreatmentDisplay } from './pretreatment_display';
import VorbehandlungEditContainer from './pretreatment_edit';
import * as _ from 'lodash';


const mapStateToProps = (state: RootState) => ({
    isDesktop: state.general.isDesktop,
    currentKonsil: state.konsile.current_konsil,
    konsilFrageErrors: state.evaluation.konsilFrageErrors
})

const mapDispatchToProps = {
    updateKonsil: (konsil: Konsil, previousKonsil?: Konsil) => updateKonsil(konsil, previousKonsil),
    dispatch_checkKonsilFrageStatus: (konsil: Konsil) => checkKonsilFrageStatus(konsil),
    dispatch_setIsPending: (isPending: boolean) => setIsPending(isPending),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

interface IState {
    konsil: Konsil;
    previousKonsil: Konsil;
    editVorbehandlungen: boolean;
    editVorbehandlung: boolean;
    addVorbehandlung: boolean;
    vorbehandlung: Vorbehandlung | null;
    clickedId: string;
}

export class EditQuestions extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            konsil: props.currentKonsil || new Konsil(),
            previousKonsil: new Konsil(),
            editVorbehandlungen: false,
            editVorbehandlung: false,
            addVorbehandlung: false,
            vorbehandlung: null,
            clickedId: "",
        }

        this.updateKonsil = this.updateKonsil.bind(this);
        this.handleVorbehandlungEditCallback = this.handleVorbehandlungEditCallback.bind(this);
        this.handleDeleteVorbehandlung = this.handleDeleteVorbehandlung.bind(this);
    }

    private saveVorbehandlung: Vorbehandlung = new Vorbehandlung();

    private recoverVorbehandlung(vorbehandlung: Vorbehandlung) {
        vorbehandlung.beschreibung = this.saveVorbehandlung.beschreibung;
        vorbehandlung.dauer = this.saveVorbehandlung.dauer;
        vorbehandlung.einheit = this.saveVorbehandlung.einheit;
    }

    componentDidUpdate(prevProps: TPropsFromRedux) {
        if (prevProps.currentKonsil !== this.props.currentKonsil) {
            this.setState({
                konsil: this.props.currentKonsil || new Konsil(),
            });
        }
    }

    private updateKonsil() {
        this.props.updateKonsil(this.state.konsil, this.state.previousKonsil);
        this.props.dispatch_checkKonsilFrageStatus(this.state.konsil);
    }

    handleVorbehandlungEditCallback(vorbehandlung: Vorbehandlung, clickedId: string) {
        if (vorbehandlung === null) {
            this.setState({
                addVorbehandlung: false,
                editVorbehandlung: false
            });
            return;
        }
        const vorbehandlungen: Vorbehandlung[] = this.state.konsil.konsilPatient.vorbehandlung || [];
        let vorbehandlungenIds: string[] = [];
        vorbehandlungen.map(item => {
            return vorbehandlungenIds.push(item.id || "")
        })
        if (this.state.addVorbehandlung) {
            vorbehandlungen.push(vorbehandlung);
        }
        else if (clickedId !== "" && vorbehandlungenIds.includes(clickedId)) {
            vorbehandlungen[vorbehandlungenIds.indexOf(clickedId)] = vorbehandlung;
        }
        this.setState
            ({
                addVorbehandlung: false,
                editVorbehandlung: false,
                konsil: {
                    ...this.state.konsil,
                    konsilPatient: {
                        ...this.state.konsil.konsilPatient,
                        vorbehandlung: vorbehandlungen
                    }
                }
            },
                () => this.updateKonsil()
            )
    }

    handleDeleteVorbehandlung(vorbehandlung: Vorbehandlung) {
        this.setState({previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))});
        const vorbehandlungen: Vorbehandlung[] = this.state.konsil.konsilPatient.vorbehandlung || [];
        _.pull(vorbehandlungen, vorbehandlung);
        this.setState
        ({
            addVorbehandlung: false,
            editVorbehandlung: false,
            konsil: {
                ...this.state.konsil,
                konsilPatient: {
                    ...this.state.konsil.konsilPatient,
                    vorbehandlung: vorbehandlungen
                }
            }
        },
            () => this.updateKonsil()
        )

        //this.updateKonsilPatient(updatedPatient);
    }

    render() {
        const frageError: Map<string, string> = this.props.konsilFrageErrors.find(item => item.konsilID === this.props.currentKonsil.id)?.errors || new Map<string, string>();
        return (
            <Box className="scroll-box-fragestellung">
                <form className="fragestellung-data-container">
                    <FormControl required className="konsiliar-frage" component="fieldset"
                                 error={frageError.has("konsil.fragestellung")}
                    >
                        <FormLabel className="bold-label" component="legend">Fragestellung an den Dermatologen</FormLabel>
                        <TextField
                            id="weitere-angaben-textarea"
                            className="pad-top"
                            multiline
                            variant="filled"
                            minRows = {10}
                            maxRows={15}
                            value={this.state.konsil.fragestellung || ""}
                            onChange={(event) => {
                                this.setState({
                                    konsil: {
                                        ...this.state.konsil,
                                        fragestellung: event.target.value
                                    }
                                });
                                //this.onChangeDebounced(event)
                            }}
                            onFocus = {() => this.setState({previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))})}
                            onBlur={() => {
                                this.updateKonsil();
                                this.props.dispatch_setIsPending(true);
                            }}
                        />
                    </FormControl>
                    <Box className = "pad">
                        <Box className = "button-header">
                            <FormLabel className="bold-label" component="legend">Vorbehandlungen</FormLabel>
                            <Button
                                variant="contained"
                                className="slim"
                                size="small"
                                color="primary"
                                onClick={() => {
                                    this.setState({
                                        addVorbehandlung: true,
                                        editVorbehandlung: true,
                                        vorbehandlung: new Vorbehandlung(),
                                        previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil)),
                                    });
                                }}
                            >
                                <Add/>
                            </Button>
                        </Box>
                        <VorbehandlungEditContainer
                            vorbehandlung={this.state.vorbehandlung || new Vorbehandlung()}
                            open={this.state.editVorbehandlung}
                            add={this.state.addVorbehandlung}
                            handleVorbehandlungEditCallback = {this.handleVorbehandlungEditCallback}
                            clickedId = {this.state.clickedId}
                        />
                        {
                            this.state.konsil.konsilPatient.vorbehandlung.length === 0 &&
                            <Typography>
                                Bitte fügen Sie im Bedarfsfall eine oder mehrere Vorbehandlungen über das (+) hinzu.
                            </Typography>
                        }
                        {
                            this.state.konsil.konsilPatient.vorbehandlung.length>0 &&
                            this.state.konsil.konsilPatient.vorbehandlung.map(item => {
                                return (
                                    <Paper
                                        className = "pad cursor-over margin-vorbehandlungen"
                                        key={"vorbehandlung_" + item.id}
                                        variant="outlined"
                                        elevation = {0}
                                        onClick={() => {
                                            this.setState({
                                                addVorbehandlung: false,
                                                editVorbehandlung: true,
                                                clickedId: item.id || "",
                                                vorbehandlung: item,
                                                previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil)),
                                            })
                                        }}
                                    >
                                        <PretreatmentDisplay
                                            vorbehandlung = {item}
                                            isViewOnly = {false}
                                            handleDeleteVorbehandlung = {this.handleDeleteVorbehandlung}
                                        />
                                    </Paper>
                                )
                            })
                        }
                    </Box>
                </form>
            </Box>

        );
    }
}

export const EditQuestionsContainer = connector(EditQuestions);
export default EditQuestionsContainer;
