import React from "react";
import {connect, ConnectedProps} from 'react-redux';

import { Typography } from "@material-ui/core";
import { RootState } from "../../redux/reducers";
import { InstallStep } from "./hpm_installation";
import { UpdateStepStatusType } from "telescan-core";

interface IProps {
    steps: {step: string, status: keyof typeof UpdateStepStatusType, progress: string, message: string}[];
}

interface IState {
}

const mapStateToProps = (state: RootState, ownProps: IProps) => ({
    initialisationStatus: state.user.initialisationStatus,
    ...ownProps
})

const mapDispatchToProps = {
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class TeleScanUpdate extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <>
                <Typography className="txt-header" variant="subtitle1" color="inherit">
                    TeleScan Updatefortschritt
                </Typography>
                <Typography className="failure pad-bottom" variant="subtitle1">
                    Bitte warten Sie bis das Update abgeschlossen ist.
                </Typography>
                {this.props.steps.map((item) => 
                    <InstallStep key={item.step} text={item.step} status={item.status} progress={item.progress} errorMessage={item.message} modul="TELESCAN"/>
                )}
            </>
        )
    }
}

export const TeleScanUpdateComponent = connector(TeleScanUpdate);
export default TeleScanUpdateComponent;