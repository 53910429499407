import React from 'react';
import {match} from 'react-router-dom';
import {connect, ConnectedProps} from 'react-redux'
import {KonsilContainer} from '.';
import {RootState} from '../../redux/reducers';
import {getUserWithToken, loadKonsil} from '../../redux/actions';
import { TelescanConfig } from 'telescan-core';


const mapStateToProps = (state: RootState) => ({
    loading: state.general.loading,
    location: state.router.location.pathname,
})

const mapDispatchToProps = {
    dispatch_loadKonsil: (id: string) => loadKonsil(id),
    dispatch_getUserWithToken: (refresh_token: string, targetPath: string) => getUserWithToken(refresh_token, targetPath),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

interface DetailParams {
    id: string;
}

type Props = TPropsFromRedux & {
    match?: match<DetailParams>,
}

export class KonsilComponent extends React.Component<Props> {
    componentDidMount() {
        const konsil_id: string | undefined = this.props.match?.params.id;
        if (konsil_id != null) {  
            const telescanConfig: TelescanConfig = TelescanConfig.getInstance();
            if (telescanConfig.getConstant("ACCESS_TOKEN")==="") { 
                // Set User (Hausarzt or Dermatologist) for the session when following direct route to konsil table with refresh token as parameter, that is bypassing login procedure
                const refresh_token = telescanConfig.getConstant("REFRESH_TOKEN");
                this.props.dispatch_getUserWithToken(refresh_token, this.props.location);
            }
            else {
                this.props.dispatch_loadKonsil(konsil_id);
            }
        }
    }

    render() {
        return (this.props.loading.length === 0 && <KonsilContainer/>)
    }
}

export const KonsilComponentContainer = connector(KonsilComponent);
export default KonsilComponentContainer;
