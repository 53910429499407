import { BearbeitungsStatusType, DermaRegistrationStatus, DermatologistInfo, KIMGeneral, KIMUser, KimUserValidation, KonsilMetaInformation, TIConnectorEvent } from 'telescan-core';

export interface KimClearInboxProgress {
    currentMessage: number,
    totalMessages: number
}

export enum EKimActions {
    SET_KIM_GENERAL = "SET_KIM_GENERAL",
    SET_KIM_USER = "SET_KIM_USER",
    SET_DERMATOLOGISTS = "SET_DERMATOLOGISTS",
    SET_KIM_USER_VALIDATION = "SET_KIM_USER_VALIDATION",
    SET_KIM_METADATEN = "SET_KIM_METADATEN",
    SET_KIM_METADATEN_KONSIL_BEARBEITUNGSSTATUS = "SET_KIM_METADATEN_KONSIL_BEARBEITUNGSSTATUS",
    REMOVE_KIM_METADATEN_KONSIL = "REMOVE_KIM_METADATEN_KONSIL",
    SET_TICONNECTOR_EVENT = "SET_TICONNECTOR_EVENT",
    SET_IS_KONSIL_SIGNED = "SET_IS_KONSIL_SIGNED",
    SET_DERMATOLOGIST_REGISTRATION  = "SET_DERMATOLOGIST_REGISTRATION",
    SET_IS_TI_CONNECTED = "SET_IS_TI_CONNECTED",
    SET_IS_TI_STATUS_LOADING = "SET_IS_TI_STATUS_LOADING",
    SET_IS_DATA_LOADING = "SET_IS_DATA_LOADING",
    SET_IS_DERMA_LIST_LOADING = "SET_IS_DERMA_LIST_LOADING",
    SET_IS_TEST_TI_CONNECTION_CLICKED = "SET_IS_TEST_TI_CONNECTION_CLICKED",
    SET_CLEAR_INBOX_PROGRESS = "SET_CLEAR_INBOX_PROGRESS"
}

export interface ISetKimGeneral {
    readonly type: EKimActions.SET_KIM_GENERAL;
    readonly kimGeneral: KIMGeneral;
}

export interface ISetKimUser {
    readonly type: EKimActions.SET_KIM_USER;
    readonly kimUser: KIMUser;
}

export interface ISetDermatologists {
    readonly type: EKimActions.SET_DERMATOLOGISTS;
    readonly dermatologists: DermatologistInfo[];
}

export interface ISetKimUserValidation {
    readonly type: EKimActions.SET_KIM_USER_VALIDATION;
    readonly kimUserValidation: KimUserValidation;
}

export interface ISetKimMetadaten {
    readonly type: EKimActions.SET_KIM_METADATEN;
    readonly kimMetadaten: KonsilMetaInformation[];
}

export interface ISetKimMetadatenKonsilBearbeitungsstatus {
    readonly type: EKimActions.SET_KIM_METADATEN_KONSIL_BEARBEITUNGSSTATUS
    readonly vorgangsId: string;
    readonly bearbeitungsStatus: keyof typeof BearbeitungsStatusType;
}

export interface IRemoveKimMetadatenKonsil {
    readonly type: EKimActions.REMOVE_KIM_METADATEN_KONSIL
    readonly vorgangsId: string;
}

export interface ISetTIConnectorEvent {
    readonly type: EKimActions.SET_TICONNECTOR_EVENT;
    readonly tiConnectorEvent: TIConnectorEvent
}

export interface ISetIsKonsilSigned {
    readonly type: EKimActions.SET_IS_KONSIL_SIGNED;
    readonly isKonsilSigned: boolean;
}

export interface ISetDermatologistRegistration {
    readonly type: EKimActions.SET_DERMATOLOGIST_REGISTRATION;
    readonly dermatologistRegistration: DermaRegistrationStatus;
}

export interface ISetIsTiConnected {
    readonly type: EKimActions.SET_IS_TI_CONNECTED;
    readonly isTiConnected: boolean;
}

export interface ISetIsTiStatusLoading {
    readonly type: EKimActions.SET_IS_TI_STATUS_LOADING;
    readonly isTiStatusLoading: boolean;
}

export interface ISetIsDataLoading {
    readonly type: EKimActions.SET_IS_DATA_LOADING;
    readonly isDataLoading: boolean;
}

export interface ISetIsDermaListLoading {
    readonly type: EKimActions.SET_IS_DERMA_LIST_LOADING;
    readonly isDermaListLoading: boolean;
}

export interface ISetIsTestTiConnectionClicked {
    readonly type: EKimActions.SET_IS_TEST_TI_CONNECTION_CLICKED;
    readonly isTestTiConnectionClicked: boolean;
}

export interface ISetClearInboxProgress {
    readonly type: EKimActions.SET_CLEAR_INBOX_PROGRESS;
    readonly progress?: KimClearInboxProgress;
}


export type TKimActionTypes =
    ISetKimGeneral |
    ISetKimUser |
    ISetDermatologists |
    ISetKimUserValidation |
    ISetKimMetadaten |
    ISetKimMetadatenKonsilBearbeitungsstatus |
    IRemoveKimMetadatenKonsil |
    ISetTIConnectorEvent |
    ISetIsKonsilSigned |
    ISetDermatologistRegistration |
    ISetIsTiConnected |
    ISetIsTiStatusLoading |
    ISetIsDataLoading |
    ISetIsDermaListLoading |
    ISetIsTestTiConnectionClicked |
    ISetClearInboxProgress;
