import { InformationUpdate, UpdateStepStatusType } from "telescan-core";

export enum EUpdateActions {
    SET_UPDATE_RUNNING = "SET_UPDATE_RUNNING",
    SET_UPDATE_INFO = "SET_UPDATE_INFO",
    SET_HPM_UPDATE_STEPS = "SET_HPM_UPDATE_STEPS",
    SET_TELESCAN_UPDATE_STEPS = "SET_TELESCAN_UPDATE_STEPS",
    SET_UPDATING_MODULE = "SET_UPDATING_MODULE",
    SET_LIST_OF_UPDATE_STEPS = "SET_LIST_OF_UPDATE_STEPS",
    SET_UPDATE_SUCCESS = "SET_UPDATE_SUCCESS",
}

export interface ISetUpdateRunning {
    readonly type: EUpdateActions.SET_UPDATE_RUNNING;
    readonly updateRunning: boolean;
}

export interface ISetUpdateInfoAction {
    readonly type: EUpdateActions.SET_UPDATE_INFO;
    readonly updateInfo: InformationUpdate[];
}

export interface ISetHpmUpdateStepsAction {
    readonly type: EUpdateActions.SET_HPM_UPDATE_STEPS;
    readonly hpmUpdateSteps: {step: string, status: keyof typeof UpdateStepStatusType, progress: string, message: string}[];
}

export interface ISetTeleScanUpdateStepsAction {
    readonly type: EUpdateActions.SET_TELESCAN_UPDATE_STEPS;
    readonly teleScanUpdateSteps: {step: string, status: keyof typeof UpdateStepStatusType, progress: string, message: string}[];
}

export interface ISetUpdatingModuleAction {
    readonly type: EUpdateActions.SET_UPDATING_MODULE;
    readonly module: string;
}

export interface ISetListOfUpdateStepsAction {
    readonly type: EUpdateActions.SET_LIST_OF_UPDATE_STEPS;
    readonly listOfUpdateSteps: Object[];
}

export interface ISetUpdateSuccessAction {
    readonly type: EUpdateActions.SET_UPDATE_SUCCESS;
    readonly isUpdateSuccess: boolean;
}

export type TUpdateActionTypes =
    ISetUpdateRunning |
    ISetUpdateInfoAction |
    ISetHpmUpdateStepsAction |
    ISetTeleScanUpdateStepsAction |
    ISetUpdatingModuleAction |
    ISetListOfUpdateStepsAction |
    ISetUpdateSuccessAction;