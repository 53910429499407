import { Box, Typography, Tooltip, TextField, CircularProgress } from "@material-ui/core"
import { HelpOutline } from "@material-ui/icons"
import { Autocomplete } from "@material-ui/lab"
import React from "react"
import { KonsilEmpfaengerGruppe } from "telescan-core"

interface IProps {
    selectedReceiverGroup: KonsilEmpfaengerGruppe;
    receiverGroups: KonsilEmpfaengerGruppe[];
    handleChangeHpmGroupAutocomplete: (_event: React.ChangeEvent<{ value: unknown }>, newValue: KonsilEmpfaengerGruppe | null) => void;
}
export const HpmGroupTabPanel = (props: IProps) => {
    return (
        <Box>
            <Typography
                className="last-reciver-label"
                variant="body1"
                color="inherit"
            >
                    Das Senden des Konsilauftrags an eine Empfängergruppe gewährleistet eine schnelle Bearbeitung
                    <Tooltip title="Der Konsilauftrag wird an eine Empfängergruppe gesendet. z.B. enthält die Empfängergruppe '513 - Dermatologie' alle Vertragsdermatologen. Der Empfänger, der Ihren Konsilauftrag als erstes annimmt, wird Konsiliar. Für alle anderen Empfänger wird der Konsilauftrag ab diesem Zeitpunkt nicht mehr zur Verfügung stehen.">
                    <HelpOutline
                        style={{verticalAlign: 'middle', display: 'inline-flex'}}/>
                    </Tooltip>
            </Typography>
            <Typography
                className="last-reciver-label"
                variant="body1"
                color="inherit"
            >
                    Das Konsil wird an folgende Empfängersgruppe gesendet:
            </Typography>
            <Box className="search-container">
                {props.selectedReceiverGroup ?
                    <Autocomplete
                        id="send_groups"
                        options={props.receiverGroups}
                        getOptionLabel={(option) => `${option?.empfaengergruppeIdentifikator?.toString()} \u2022 ${option?.empfaengergruppeBezeichnung}` || ""}
                        value = {props.selectedReceiverGroup}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label="Empfängergruppe"
                            />
                        }
                        onChange={props.handleChangeHpmGroupAutocomplete}
                    /> :
                    <CircularProgress size = {25}/>
                }
            </Box>
        </Box>
    )
}