import { Konsilabrechnung, Quartalsabrechnungen, QuartalsabrechnungUpdate } from 'telescan-core';
import {EAbrechnungActions, TAbrechnungActionTypes} from '../types/abrechnung_types';

export interface IAbrechnungState {
    quartalsabrechnungen: Quartalsabrechnungen[];
    currentQuartalsabrechnung: Quartalsabrechnungen;
    quartalsabrechnungFilter: string[];
    quartalsabrechnungUpdate: QuartalsabrechnungUpdate[];
    isKonsilFromBillingOpened: boolean;
    billId: string;
}

const initialState: IAbrechnungState = {
    quartalsabrechnungen: [],
    currentQuartalsabrechnung: new Quartalsabrechnungen(),
    quartalsabrechnungFilter: ["In Arbeit", "Abgerecnet"],
    quartalsabrechnungUpdate: [],
    isKonsilFromBillingOpened: false,
    billId: "",
}

export function abrechnung_reducer(state = initialState, action: TAbrechnungActionTypes): IAbrechnungState {
    switch (action.type) {
        case EAbrechnungActions.SET_QUARTALSABRECHNUNGEN:
            return {
                ...state,
                quartalsabrechnungen: action.quartalsabrechnungen
            }
        case EAbrechnungActions.SET_CURRENTQUARTALSABRECHNUNG:
            return {
                ...state,
                currentQuartalsabrechnung: action.currentQuartalsabrechnung
            }
        case EAbrechnungActions.FILTER_CHANGED:
            return {
                ...state,
                quartalsabrechnungFilter: action.quartalsabrechnungFilter,
            }
        case EAbrechnungActions.SET_NEW_AVAILABLE_BILLS:
            const newBillIdx = state.quartalsabrechnungUpdate.findIndex(item => item.id === action.quartalsabrechnungUpdate.id);
            let newBillAvailableArray = [...state.quartalsabrechnungUpdate];
            if (newBillIdx < 0)
                newBillAvailableArray.push(action.quartalsabrechnungUpdate);
            else 
                newBillAvailableArray[newBillIdx] = action.quartalsabrechnungUpdate;
            return {
                ...state,
                quartalsabrechnungUpdate: newBillAvailableArray
            }
        case EAbrechnungActions.SET_KONSILABRECHNUNGEN_STATUS:
            const newCurrentQuartalsabrechnung = state.currentQuartalsabrechnung;
            newCurrentQuartalsabrechnung.konsilabrechnung?.map((item: Konsilabrechnung) => {
                return item.status = "ABGERECHNET";
            })
            return {
                ...state,
                currentQuartalsabrechnung: newCurrentQuartalsabrechnung
            }
        case EAbrechnungActions.SET_CURRENT_QUARTALSABRECHNUNG_STATUS:
            return {
                ...state,
                currentQuartalsabrechnung: {
                    ...state.currentQuartalsabrechnung,
                    statusQuartalsabrechnung: action.status,
                }
            }
        case EAbrechnungActions.SET_KONSILABRECHNUNG_IN_ABRECHNUNG_FLAG:
            if (state.currentQuartalsabrechnung.konsilabrechnung) {
                const konsilabrechnungCheckedIdx = state.currentQuartalsabrechnung.konsilabrechnung.findIndex(item => item.id === action.konsilabrechnungId);
                const newKonsilabrechnungArray = [...state.currentQuartalsabrechnung.konsilabrechnung];
                newKonsilabrechnungArray[konsilabrechnungCheckedIdx].inAbrechnung = action.isInAbrechnung;
                return {
                    ...state,
                    currentQuartalsabrechnung: {
                        ...state.currentQuartalsabrechnung,
                        konsilabrechnung: newKonsilabrechnungArray,
                    }
                }
            }
            else
                return state
        
        case EAbrechnungActions.SET_CURRENT_QUARTALSABRECHNUNG_IS_IN_PROGRESS:
            return {
                ...state,
                currentQuartalsabrechnung: {
                    ...state.currentQuartalsabrechnung,
                    isInProgress: action.isInProgress,
                }
            }
        case EAbrechnungActions.SET_IS_KONSIL_FROM_BILLING_OPENED:
            return {
                ...state,
                isKonsilFromBillingOpened: action.isKonsilFromBillingOpened,
                billId: action.billId,
            }
        default:
            return state
    }
    
}