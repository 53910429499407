import React from 'react';
import {
    Typography, CircularProgress, Box,
} from '@material-ui/core';
import {
    KonsilStatusType, UploadPath,
} from 'telescan-core';
import { formatBytes, checkCdaSize } from '../../utils';



interface IProps {
    size: number;
    konsilStatus: keyof typeof KonsilStatusType;
    uploadPath: keyof typeof UploadPath;
    limit: number;
}

export class CdaSizeComponent extends React.Component<IProps> {

    render() {
        /*
        if (!isNaN(this.props.size)) {
            if (checkCdaSize(this.props.size, this.props.konsilStatus))
                return(
                    <Typography className="last-reciver-label" variant="body2" color="inherit">
                        Konsilauftragsgröße: {formatBytes(this.props.size)}.
                    </Typography>
                )
            else
                return(
                    <Typography className="last-reciver-label failure" variant="body2" color="error">
                        Achtung! Der Konsilauftrag ist zu groß für den Versand ({formatBytes(this.props.size)}). 
                        Versuchen Sie die Anzahl der Anhänge oder Bilder zu reduzieren.
                    </Typography>
                )
        } else
            return(
                <Box className="pdf-preview" style={{display: "flex", alignItems: "center", justifyContent:"space-between"}} >
                    <span className="pdf-preview-load last-reciver-label"><CircularProgress /> Größe des Konsils wird bestimmt...</span>
                </Box>
            )
        */

        if (!checkCdaSize(this.props.size, this.props.konsilStatus, this.props.uploadPath))
            if (!isNaN(this.props.size)) {
                return(
                    <Typography className="last-reciver-label failure" variant="body2" color="error">
                        Achtung! Der Konsilauftrag ist zu groß für den Versand ({formatBytes(this.props.size)}). Das Maximum beträgt {formatBytes(this.props.limit)}.
                        Versuchen Sie die Anzahl der Anhänge oder Bilder zu reduzieren.
                    </Typography>
                )
            }
            else {
                return(
                    <Box className="pdf-preview" style={{display: "flex", alignItems: "center", justifyContent:"space-between"}} >
                        <span className="pdf-preview-load last-reciver-label failure"><CircularProgress /> Größe des Konsils wird bestimmt...</span>
                    </Box>
                )
            }
        else {
            return true;
        }
        
    }
}
