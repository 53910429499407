import {DiagnoseSicherheit, Failure, ICDService, Seitenlokation} from "telescan-core";
import React from "react";
import {ViewText} from '../viewComponents/view_text';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    IconButton,
    Paper,
    TextField,
    Tooltip,
    Typography,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';
import {Delete, Edit} from "@material-ui/icons";
import SimpleModalContainer from "../../elements/simple_modal";
import Icd10TreeView from '../../elements/icd10_treeView';
import {Diagnose} from "telescan-core/lib/entities/konsil_abschluss";
import {RootState} from "../../../redux/reducers";
import {connect, ConnectedProps} from "react-redux";
import { failure } from "../../../redux/actions";
import uuid from 'uuid';

interface IKrankheitDermatologeProps {
    diagnose: Diagnose,
    delete: (k) => void,
    hasError: boolean;
    isViewOnly: boolean;
    onViewClick?: (event) => void;
    onEditClick?: (event) => void;
    style?: any;
    role: string;
}


export class KrankheitDermatologeComponent extends React.Component<IKrankheitDermatologeProps> {
    render() {
        return (
            <>
                <Paper style={this.props.style}
                       className={"derma-diagnose-container" + (this.props.hasError ? " failure" : "") + (this.props.isViewOnly ? " view-only" : "")}
                       elevation={1}
                       onClick={(this.props.isViewOnly) ? this.props.onViewClick : () => {
                       }}
                >
                    <Box className="flex-row-center">

                        <Typography className="bold" variant="subtitle1">
                            {this.props.diagnose?.diagnoseCode || "???"}
                        </Typography>
                        <Tooltip
                            title={this.props.diagnose.diagnoseSicherheit ? DiagnoseSicherheit[this.props.diagnose.diagnoseSicherheit] : DiagnoseSicherheit["UNK"]}
                            placement="top">
                            <Typography className="diagnose-rank" variant="body1">
                                {this.props.diagnose.diagnoseSicherheit || "?"}
                            </Typography>
                        </Tooltip>
                        {(this.props.diagnose.seitenlokalisation != null && this.props.diagnose.seitenlokalisation !== "UNK") &&
                        <Tooltip title={Seitenlokation[this.props.diagnose.seitenlokalisation || "UNK"]}
                                 placement="top">
                            <Typography className="diagnose-location" variant="body1">
                                {this.props.diagnose.seitenlokalisation || "?"}
                            </Typography>
                        </Tooltip>
                        }
                        {!this.props.isViewOnly &&
                        <>
                            <Tooltip title="Bearbeiten" placement="top">
                                <IconButton className="edit-diagnose" aria-label="delete" size="small"
                                            onClick={this.props.onEditClick}>
                                    <Edit/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Löschen" placement="top">
                                <IconButton className="delete-diagnose" aria-label="delete"
                                            size="small" //onClick={this.props.delete}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                this.props.delete(this.props.diagnose);
                                            }}>
                                    <Delete/>
                                </IconButton>
                            </Tooltip>
                        </>
                        }
                    </Box>
                    <Typography className="diagnose-text" variant="body1">
                        {this.props.diagnose.diagnoseAnzeigenname || "???"}
                    </Typography>
                </Paper>
            </>
        )
    }
}


const mapStateToProps = (state: RootState) => ({
    role: state.user.role,
})

const mapDispatchToProps = {
    dispatch_failure: (id: string, failureObject: Failure) => failure(id, failureObject)
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

interface IKrankheitDermatologeEditProps {
    krankheit: Diagnose;
    open: boolean;
    add: boolean;
    callback: (krankheit: Diagnose | null, add: boolean) => void;
    icd10Data: {
        kapitelData: string[],
        kapitelVersion: string,
        kapitelYear: string,
        oid: string,
        isIcd10Loading: boolean,
        loadingError: string,
        krankheitBeginnYear: string,
    };
    clearIcd10KatalogLoadingError?: () => void;
}

interface IState {
    krankheit: Diagnose;
    diagnoseCodeIcd10: string;
    diagnoseTextIcd10: string;
    diagnoseSearchRequest: string;
    diagnoseCodeError: string;
    typingTimeout: any;
    diagnoseSearchChar: string;
    isSearch: boolean;
    bottom: number;
    isStartZeitpunktNullflavor: boolean;
    isEndZeitpunktNullflavor: boolean;
}

export class KrankheitDermatologeEditComponent extends React.Component<IKrankheitDermatologeEditProps & TPropsFromRedux, IState> {
    constructor(props: IKrankheitDermatologeEditProps & TPropsFromRedux) {
        super(props);
        this.state = {
            krankheit: props.krankheit,
            diagnoseCodeIcd10: "",
            diagnoseTextIcd10: "",
            diagnoseSearchRequest: "",
            diagnoseCodeError: "",
            typingTimeout: 0,
            diagnoseSearchChar: "",
            isSearch: false,
            bottom: 0,
            isStartZeitpunktNullflavor: false,
            isEndZeitpunktNullflavor: false,
        };
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.diagnoseFromIcd10TreeView = this.diagnoseFromIcd10TreeView.bind(this);
        this.evaluateDiagnoseCodeInput = this.evaluateDiagnoseCodeInput.bind(this);
        this.timeOutMethod = this.timeOutMethod.bind(this);
        this.timehandleTreeSearch = this.timehandleTreeSearch.bind(this);
        this.setDiagnoseSearchRequest = this.setDiagnoseSearchRequest.bind(this);
        this.setDiagnoseSearchChar = this.setDiagnoseSearchChar.bind(this);
        this.stopSearch = this.stopSearch.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    componentDidUpdate(prevProps: Readonly<IKrankheitDermatologeEditProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.krankheit !== this.props.krankheit) {
            this.setState({
                krankheit: this.props.krankheit,
            });
        }
    }

    timeOutMethod(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                  getUserInput: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => string,
                  evaluateUserInput: (val: string) => void, duration: number): void {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }
        let value: string = getUserInput(e);

        this.setState({
            typingTimeout: setTimeout(() => {
                evaluateUserInput(value);
            }, duration)
        });
    }

    timehandleTreeSearch(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
        this.timeOutMethod(e, this.setDiagnoseSearchChar, this.setDiagnoseSearchRequest, 500)
    }

    stopSearch() {
        this.setState({
            isSearch: false,
        })
    }

    setDiagnoseSearchChar(event): string {
        this.setState({
            diagnoseSearchChar: event.target.value.toLowerCase(),
            isSearch: true,
        })
        return event.target.value.toLowerCase();
    }

    setDiagnoseSearchRequest(code: string) {
        this.setState({
            diagnoseSearchRequest: code,
        })
    }

    diagnoseFromIcd10TreeView(diagnoseCode: string, diagnoseBezeichnung: string, icd1Oid: string) {
        this.setState({
            krankheit: {
                ...this.state.krankheit,
                diagnoseCode: diagnoseCode,
                diagnoseAnzeigenname: diagnoseBezeichnung,
                icdKatalog: icd1Oid,
                datumDiagnosestellung: new Date().getTime(),
            },
            diagnoseCodeError: "",
        })
    }

    evaluateDiagnoseCodeInput(date: Date, code: string) {
        const icdService = new ICDService();
        icdService.validateIcd10DiagnoseCode(date.getTime(), code).then(responce => {
            if (responce.icd10.isValid === "true") {
                this.setState({
                    krankheit: {
                        ...this.state.krankheit,
                        diagnoseAnzeigenname: responce.icd10.bezeichnung,
                        icdKatalog: responce.icd10.oid,
                        datumDiagnosestellung: new Date().getTime(),
                    },
                    diagnoseCodeError: "",
                })
            } else {
                this.setState({
                    diagnoseCodeError: "Der Diagnosecode ist ungültig. Bitte überprüfen Sie Ihre Angabe"
                })
            }
        })
            .catch(error => {
                this.setState({
                    diagnoseCodeError: error
                })
            })
    }

    handleKeyDown(event: React.KeyboardEvent) {
        if (!(document?.activeElement === document?.getElementById("diag-code") || document?.activeElement === document?.getElementById("diag-text") || document?.activeElement === document?.getElementById("diag-search"))) {
            const upperKey: string = event.key.toUpperCase();
            if (upperKey === 'A' || upperKey === 'G' || upperKey === 'V' || upperKey === 'Z') {
                event.preventDefault();
                this.setState({
                    krankheit: {
                        ...this.state.krankheit,
                        diagnoseSicherheit: upperKey as keyof typeof DiagnoseSicherheit
                    }
                });
                document?.getElementById("diagnoseSicherheit_" + upperKey)?.focus();
            } else if (upperKey === 'L' || upperKey === 'R' || upperKey === 'B' || event.key === '?') {
                event.preventDefault();
                this.setState({
                    krankheit: {
                        ...this.state.krankheit,
                        seitenlokalisation: (event.key === "?") ? "UNK" : upperKey as "L" | "R" | "B"
                    }
                })
                document?.getElementById((event.key === "?") ? "diagnoseLokalisation_UNK" : "diagnoseLokalisation_" + upperKey)?.focus();
            }
        }
    }

    handleScroll = (e) => {
        const prevBottom = this.state.bottom;
        if (e.target.scrollTop >= e.target.scrollHeight - e.target.clientHeight - Math.floor(e.target.scrollTop) * 0.01) {
            this.setState({
                bottom: prevBottom + 1
            })
        }
    }

    handleSubmit() {
        if (this.state.krankheit.diagnoseCode === "" || this.state.krankheit.diagnoseCode === undefined || this.state.diagnoseCodeError || this.props.icd10Data.loadingError !== "") { //if diagnosen code empty or not valid, through message to user, that add/save diagnose not possible
            const failureId: string = uuid.v4();
            const failure = new Failure();
            failure.error = "Bitte fügen Sie einen gültigen Diagnosecode hinzu."
            this.props.dispatch_failure(failureId, failure);
        }
        else if (!this.state.krankheit.diagnoseSicherheit || this.state.krankheit.diagnoseSicherheit === undefined) {
            const failureId: string = uuid.v4();
            const failure = new Failure();
            failure.error = "Bitte geben Sie eine Diagnosesicherheit an."
            this.props.dispatch_failure(failureId, failure);
        }
        else {
            this.props.callback(this.state.krankheit, this.props.add);
            this.setState({
                diagnoseSearchRequest: "",
            })
        }
    }

    render() {
        return (
            <SimpleModalContainer isOpen={this.props.open} additionalClassname="modal-add-data">
                <Paper className="modal-wrapper diagnose-modal" onKeyDown={this.handleKeyDown}>
                    <h2 className="modal-header">{this.props.add ? "Diagnose hinzufügen" : "Diagnose bearbeiten"}</h2>
                    <Box className="modal-content">
                        <Box className="left-side">
                            <form className="add-form lab-form" noValidate autoComplete="off">
                                <TextField
                                    required
                                    error={this.state.krankheit.diagnoseCode == null || this.state.krankheit.diagnoseCode === "" || this.state.diagnoseCodeError !== ""}
                                    id="diag-code"
                                    label="Diagnosecode"
                                    multiline
                                    minRows = {1}
                                    maxRows={4}
                                    value={this.state.krankheit.diagnoseCode || ""}
                                    onChange={(event) => {
                                        this.setState({
                                            krankheit: {
                                                ...this.state.krankheit,
                                                diagnoseCode: event.target.value
                                            }
                                        })
                                    }}
                                    onBlur={() => {
                                            this.state.krankheit.diagnoseCode !== undefined && this.evaluateDiagnoseCodeInput(new Date(), this.state.krankheit.diagnoseCode)
                                    }}
                                />
                                <ViewText
                                    labelVariant='caption'
                                    label='Diagnosetext'
                                    hasError={this.state.diagnoseCodeError !== ""}
                                    text={this.state.diagnoseCodeError === "" ? this.state.krankheit.diagnoseAnzeigenname || "" : this.state.diagnoseCodeError}
                                />
                                <FormControl>
                                    <FormLabel component="legend">Diagnosesicherheit *</FormLabel>
                                    <ToggleButtonGroup
                                        color="primary"
                                        className="btn-group-select pad-top"
                                        aria-label="outlined primary button group"
                                        exclusive
                                        onChange={(event, value) => {
                                            this.setState({
                                                krankheit: {
                                                    ...this.state.krankheit,
                                                    diagnoseSicherheit: value as "A" | "G" | "V" | undefined
                                                }
                                            })
                                        }}
                                    >
                                        {
                                            Object.keys(DiagnoseSicherheit).slice(0, -1).map(key => // Dermatologe view, diagnosesicherheits options A, G, V
                                                <Tooltip
                                                    key={key}
                                                    title={DiagnoseSicherheit[key]}
                                                    placement="top">
                                                    <ToggleButton
                                                        value={key}
                                                        id={"diagnoseSicherheit_" + key}
                                                        className={this.state.krankheit.diagnoseSicherheit === key ? 'active' : ''}
                                                    >
                                                        {key}
                                                    </ToggleButton>
                                                </Tooltip>
                                            )
                                        }
                                    </ToggleButtonGroup>
                                </FormControl>
                                <FormControl>
                                    <FormLabel component="legend">Seitenlokalisation</FormLabel>
                                    <ToggleButtonGroup color="primary" className="btn-group-select pad-top"
                                                       aria-label="outlined primary button group"
                                                       exclusive
                                                       onChange={(event, value) => {
                                                           this.setState({
                                                               krankheit: {
                                                                   ...this.state.krankheit,
                                                                   seitenlokalisation: value as "L" | "R" | "B" | "UNK",
                                                               },
                                                           })
                                                       }}
                                    >
                                        {Object.keys(Seitenlokation).filter(item => (item !== "U" && item !== "A")).map(key =>
                                            <Tooltip key={key} title={Seitenlokation[key]} placement="top">
                                                <ToggleButton value={key} id={"diagnoseLokalisation_" + key}
                                                              className={(this.state.krankheit.seitenlokalisation === key ? 'active' : '')}>{(key !== "UNK") ? key : "?"}</ToggleButton>
                                            </Tooltip>
                                        )}
                                    </ToggleButtonGroup>
                                </FormControl>
                            </form>
                        </Box>
                        <Box className="right-side" onScroll={this.handleScroll}>
                            <Box className="icd-search-container">
                                {this.props.icd10Data.kapitelYear !== "" && new Date().getFullYear() > parseInt(this.props.icd10Data.kapitelYear) &&
                                    <Typography className = 'failure' variant = 'body2' gutterBottom>
                                        Im ersten Quartal eines Jahres ist es möglich den ICD10 Katalog des Vorjahres zu nutzen. Bitte denken Sie an eine rechtzeitige Installation des neuen ICD10 Katalogs.
                                    </Typography>
                                }
                                <TextField
                                    id="diag-search"
                                    onChange={this.timehandleTreeSearch}
                                    placeholder={`ICD10 ${this.props.icd10Data.kapitelYear === "" ? this.props.icd10Data.krankheitBeginnYear : this.props.icd10Data.kapitelYear} | Suche`}
                                    className="icd-search"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon/>
                                            </InputAdornment>
                                        )
                                    }}
                                >
                                </TextField>
                            </Box>
                            <Icd10TreeView /* Insert Icd10 catalog here */
                                icd10Data={this.props.icd10Data}
                                addDiagnose={this.diagnoseFromIcd10TreeView}
                                diagnoseSearchRequest={this.state.diagnoseSearchRequest}
                                isSearch={this.state.isSearch}
                                stopSearch={this.stopSearch}
                                bottom={this.state.bottom}
                            />
                        </Box>
                    </Box>
                    <Box className="modal-footer">
                        <Button variant="text" color="primary" onClick={() => {
                            this.setState({
                                diagnoseCodeIcd10: "",
                                diagnoseTextIcd10: "",
                                diagnoseSearchChar: "",
                                diagnoseSearchRequest: "",
                                diagnoseCodeError: "",
                                isStartZeitpunktNullflavor: false,
                                isEndZeitpunktNullflavor: false,
                            });
                            this.props.clearIcd10KatalogLoadingError && this.props.clearIcd10KatalogLoadingError();
                            this.setState({ // set state back to previous if "Abbrechnen" is clicked
                                krankheit: this.props.krankheit
                            });
                            this.props.callback(null, this.props.add);
                        }}>
                            Abbrechen
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick = {this.handleSubmit}
                        >
                            {this.props.add ? "Hinzufügen" : "Speichern"}
                        </Button>
                    </Box>
                </Paper>
            </SimpleModalContainer>
        )
    }
}

export const KrankheitDermatologeEditComponentContainer = connector(KrankheitDermatologeEditComponent);




