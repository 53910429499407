import React from 'react';
import {Box, Collapse, IconButton, Tooltip, Typography} from '@material-ui/core';
import {connect, ConnectedProps} from 'react-redux';
import VizSensor from 'react-visibility-sensor';
import PropTypes from 'prop-types';

import {Anamnese, Fragestellung, Lokalisation, PersonalData, ViewKonsiliarberichtContainer} from '.';
import {RootState} from '../../redux/reducers';
import ViewAnamneseContentContainer from './viewComponents/view_anamnesecontent';
import ViewFragestellungContentContainer from './viewComponents/view_fragestellungcontent';
import ViewLokalisationContentContainer from './viewComponents/view_lokalisationcontent';
import ViewPersonendatenContentContainer from './viewComponents/view_personendatencontent';
import { setActiveAreas, setIsKonsilUpdateConflict, setIsKonsilUpdateConflictDialog, setIsKonsilUpdateEventSuccess, updateKonsilOnEvent } from '../../redux/actions';
import { Alert } from '@material-ui/lab';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Close } from '@material-ui/icons';
import VisibilityIcon from '@material-ui/icons/Visibility';

const mapStateToProps = (state: RootState) => ({
    currentKonsil: state.konsile.current_konsil,
    tabIndex: state.konsile.tabIndex,
    konsile: state.konsile,
    role: state.user.role,
    isDesktop: state.general.isDesktop,
    isKonsilUpdatedEventShown: state.konsile.isKonsilUpdatedEventShown,
    isKonsilUpdateEventSuccess: state.konsile.isKonsilUpdateEventSuccess,
    isKonsilUpdateConflict: state.konsile.isKonsilUpdateConflict,
})

const mapDispatchToProps = {
    dispatch_setActiveAreas: (areaIndex: number, isActive: boolean) => setActiveAreas(areaIndex, isActive),
    dispatch_updateKonsilOnEvent: (konsilId: string) => updateKonsilOnEvent(konsilId),
    dispatch_setIsKonsilUpdateEventSuccess: (isKonsilUpdateEventSuccess: boolean) => setIsKonsilUpdateEventSuccess(isKonsilUpdateEventSuccess),
    dispatch_setIsKonsilUpdateConflict: (isKonsilUpdateConflict: boolean) => setIsKonsilUpdateConflict(isKonsilUpdateConflict),
    dispatch_setIsKonsilUpdateConflictDialog: (isKonsilUpdateConflictDialog: boolean) => setIsKonsilUpdateConflictDialog(isKonsilUpdateConflictDialog),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

interface IState {
    tabIndex: number;
    isAlertOpenedTemp: boolean;
    infoBannerHeight: number;
    successBannerHeight: number;
    conflictBannerHeight: number;
}

export class KonsilWrapper extends React.Component<TPropsFromRedux, IState> {
    private infoBannerRef: React.RefObject<HTMLInputElement>;
    private successBannerRef: React.RefObject<HTMLInputElement>;
    private conflictBannerRef: React.RefObject<HTMLInputElement>;
    constructor(props: TPropsFromRedux) {
        super(props);
        this.infoBannerRef = React.createRef();
        this.successBannerRef = React.createRef();
        this.conflictBannerRef = React.createRef();
        this.state = {
            tabIndex: (props.currentKonsil.konsilStatus === 'IN_ARBEIT' && props.tabIndex > 3) ? 0 : props.tabIndex,
            isAlertOpenedTemp: true,
            infoBannerHeight: 0,
            successBannerHeight: 0,
            conflictBannerHeight: 0,
        };
    }

    componentDidMount() {
        const infoBannerHeight = this.infoBannerRef.current?.clientHeight;
        const successBannerHeight = this.successBannerRef.current?.clientHeight;
        const conflictBannerHeight = this.conflictBannerRef.current?.clientHeight;
        infoBannerHeight && this.setState({infoBannerHeight: infoBannerHeight});
        successBannerHeight && this.setState({successBannerHeight: successBannerHeight});
        conflictBannerHeight && this.setState({conflictBannerHeight: conflictBannerHeight});
        window.addEventListener('resize', this.calcHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calcHeight);
    }

    componentDidUpdate(prevProps: Readonly<TPropsFromRedux>) {
        if (prevProps.tabIndex !== this.props.tabIndex) {
            this.setState({
                tabIndex: (this.props.currentKonsil.konsilStatus === 'IN_ARBEIT' && this.props.tabIndex > 3) ? 0 : this.props.tabIndex
            });
        }
    }

    calcHeight = () => {
        const infoBannerHeight = this.infoBannerRef.current?.clientHeight;
        const successBannerHeight = this.successBannerRef.current?.clientHeight;
        const conflictBannerHeight = this.conflictBannerRef.current?.clientHeight;
        infoBannerHeight && this.setState({infoBannerHeight: infoBannerHeight});
        successBannerHeight && this.setState({successBannerHeight: successBannerHeight});
        conflictBannerHeight && this.setState({conflictBannerHeight: conflictBannerHeight});
    }

    render() {
        let bannerHeight = {};
        if (this.props.isKonsilUpdatedEventShown)
            bannerHeight['--info-banner-height'] = `${this.state.infoBannerHeight.toString()}px`;
        else
            bannerHeight['--info-banner-height'] = "0px";

        if (this.props.isKonsilUpdateEventSuccess)
            bannerHeight['--success-banner-height'] = `${this.state.successBannerHeight.toString()}px`;
        else
            bannerHeight['--success-banner-height'] = "0px";

        if (this.props.isKonsilUpdateConflict)
            bannerHeight['--conflict-banner-height'] = `${this.state.conflictBannerHeight.toString()}px`;
        else
            bannerHeight['--conflict-banner-height'] = "0px";

        return (
            <>
                {this.props.role === "DERMATOLOGE" && // TODO: Fall abfangen, wo Ärzte und Dermatologen in der gleichen Betriebsstätte arbeiten
                <div className="main-tab-container">
                    <TabPanel className="tab-container" value={this.state.tabIndex} index={0} style = {bannerHeight}>
                        <Box className="splittscreen">
                            <Box className="splitt-derma-content">
                                <VizSensor
                                    offset={(this.props.isDesktop) ? {top: 110} : {top: 130}}
                                    partialVisibility
                                    onChange={(isVisible) => {
                                        this.props.dispatch_setActiveAreas(0, isVisible)
                                    }}
                                >
                                    <Box id="question-link" className="scroll-box-fragestellung">
                                        <ViewFragestellungContentContainer/>
                                    </Box>
                                </VizSensor>
                                <VizSensor
                                    offset={(this.props.isDesktop) ? {top: 110} : {top: 130}}
                                    partialVisibility
                                    onChange={(isVisible) => {
                                        this.props.dispatch_setActiveAreas(1, isVisible)
                                    }}
                                >
                                    <Box id="location-link" className="derma-localisation-container">
                                        <ViewLokalisationContentContainer/>
                                    </Box>
                                </VizSensor>
                                <VizSensor
                                    offset={(this.props.isDesktop) ? {top: 110} : {top: 130}}
                                    partialVisibility
                                    onChange={(isVisible) => {
                                        this.props.dispatch_setActiveAreas(2, isVisible)
                                    }}
                                >
                                    <Box id="anamnese-link" className="anamnese-data-container">
                                        <ViewAnamneseContentContainer/>
                                    </Box>
                                </VizSensor>
                                <VizSensor
                                    offset={(this.props.isDesktop) ? {top: 110} : {top: 130}}
                                    partialVisibility
                                    onChange={(isVisible) => {
                                        this.props.dispatch_setActiveAreas(3, isVisible)
                                    }}
                                >
                                    <Box id="patient-link" className="person-data-container">
                                        <ViewPersonendatenContentContainer/>
                                    </Box>
                                </VizSensor>
                            </Box>
                            <Box className="split-konsil">
                                <ViewKonsiliarberichtContainer hasSideView={true}/>
                            </Box>
                        </Box>

                    </TabPanel>
                    <TabPanel className="tab-container" value={this.state.tabIndex} index={4} style = {bannerHeight}>
                        <ViewKonsiliarberichtContainer hasSideView={false}/>
                    </TabPanel>
                </div>
                }
                {(this.props.role === "HAUSARZT" || this.props.role === "BETRIEBSSTAETTE_HAUSARZT") && //TODO: Fall abfangen, wo Ärzte und Dermatologen in gleicher BS sind
                    <div className="main-tab-container">
                        <Collapse in={this.props.isKonsilUpdateEventSuccess}>
                            <Alert
                                ref = {this.successBannerRef}
                                severity = "success"
                                action = {                                  
                                    <IconButton
                                        onClick = {() => this.props.dispatch_setIsKonsilUpdateEventSuccess(false)}
                                        size = 'small'
                                    >
                                        <Close/>
                                    </IconButton>                                  
                                }
                            >
                                Konsilauftrag wurde erfolgreich aktualisiert
                            </Alert>
                        </Collapse>
                        <Collapse in={this.props.isKonsilUpdatedEventShown}>
                            <Alert
                                ref = {this.infoBannerRef}
                                severity = "info"
                                action = {
                                    <Tooltip title = "Jetzt aktualisieren" placement = 'left'>
                                        <IconButton
                                            onClick = {() => this.props.dispatch_updateKonsilOnEvent(this.props.currentKonsil.id || "")}
                                            size = 'small'
                                        >
                                            <RefreshIcon/>
                                        </IconButton>
                                    </Tooltip>                                  
                                }
                            >
                                Es wurde Änderungen an diesem Konsilauftrag vorgenommen. Die Anzeige des Konsilauftrags wird mit Ihrer nächsten Eingabe automatisch aktualisiert.
                            </Alert>
                        </Collapse>
                        <Collapse in={this.props.isKonsilUpdateConflict}>
                            <Alert
                                ref = {this.conflictBannerRef}
                                severity = "warning"
                                action = {
                                    <>
                                        <Tooltip title = "Untersuchen">
                                            <IconButton
                                                onClick = {() => {
                                                    this.props.dispatch_setIsKonsilUpdateConflictDialog(true);
                                                    this.props.dispatch_setIsKonsilUpdateConflict(false);
                                                }}
                                                size = 'small'
                                            >
                                                <VisibilityIcon/>
                                            </IconButton>
                                        </Tooltip>                                    
                                        <IconButton
                                            onClick = {() => this.props.dispatch_setIsKonsilUpdateConflict(false)}
                                            size = 'small'
                                        >
                                            <Close/>
                                        </IconButton>
                                    </>                                        
                                }
                            >
                                Achtung! Ihre Änderung steht im Konflikt mit der letzten Aktualisierung des Konsilauftrags.
                            </Alert>
                        </Collapse>
                        <TabPanel className="tab-container" value={this.state.tabIndex} index={0} style = {bannerHeight}>
                            <PersonalData konsil={this.props.currentKonsil}/>
                        </TabPanel>
                        <TabPanel className="tab-container lokalisation-tab" value={this.state.tabIndex} index={1} style = {bannerHeight}>
                            <Lokalisation konsil={this.props.currentKonsil}/>
                        </TabPanel>
                        <TabPanel className="tab-container" value={this.state.tabIndex} index={2} style = {bannerHeight}>
                            <Anamnese konsil={this.props.currentKonsil}/>
                        </TabPanel>
                        <TabPanel className="tab-container" value={this.state.tabIndex} index={3} style = {bannerHeight}>
                            <Fragestellung konsil={this.props.currentKonsil}/>
                        </TabPanel>
                        <TabPanel className="tab-container" value={this.state.tabIndex} index={4} style = {bannerHeight}>
                            <ViewKonsiliarberichtContainer hasSideView={false}/>
                        </TabPanel>
                    </div>
                }
            </>
        );
    }
}

export const KonsilContainer = connector(KonsilWrapper);
export default KonsilContainer;

export function TabPanel(props: { [x: string]: any; children: any; value: any; index: any; }) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
