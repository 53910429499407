import React from 'react';
// material components
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { Tooltip } from '@material-ui/core';


interface IProps {
    userName: string;
    avatar: string;
    lanr: string;
    bsnr: string;
}


export class UserContent extends React.Component<IProps> {

    render() {
        return (
            <Box className="inner-user-content">
                <Avatar className="main-avatar" alt={this.props.userName} src={this.props.avatar} />
                <div>
                <Typography variant="subtitle1" color="inherit">
                    {this.props.userName}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                    <Tooltip title="BSNR"><span> {this.props.bsnr}</span></Tooltip> / <Tooltip title="LANR"><span>{this.props.lanr}</span></Tooltip>
                </Typography>
                </div>
            </Box>
        );
    }
}

export const UserContentContainer = UserContent;
export default UserContentContainer;