import React, {createRef} from 'react';
// import SvgFileZoomPan from 'react-svg-file-zoom-pan';
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import {Box, Button, CircularProgress, Divider, IconButton, Menu, MenuItem, Paper, Typography} from '@material-ui/core';
import {Accessibility, Add, ArrowDropDown, CloudDownload, Delete, List, PhotoLibrary} from '@material-ui/icons';
import {connect, ConnectedProps} from 'react-redux';
import uuid from 'uuid';
import BackgroundImageOnLoad from 'background-image-on-load';
import ImagePlaceholder from "../../../static/images/placeholder_img.svg";


import {LongMenu, SelectInput} from '../../elements';
import {RootState} from '../../../redux/reducers';

import {
    changeKonsilImageType,
    checkLocationStatus,
    deleteKonsilImage,
    failure,
    getMinimumDimensions,
    updateKonsil,
    uploadKonsilImage
} from '../../../redux/actions';
import {getEnumKeyByEnumValue, Konsil, KonsilImageType, Lateralitaet, Lokalisation, Failure, DermatoscopeType, fussBodyMapping, handBodyMapping, kopfBodyMapping, MenuItems} from 'telescan-core';
import {KonsilImageState} from '../../../redux/reducers/image_attachment_reducer';

import {ReactComponent as BodyMapFrau} from "../../../static/images/localisation/frau.svg";
import {ReactComponent as BodyMapMann} from "../../../static/images/localisation/mann.svg";
import {ReactComponent as BodyMapKopfMann} from '../../../static/images/localisation/kopf-mann.svg';
import {ReactComponent as BodyMapKopfFrau} from '../../../static/images/localisation/kopf-frau.svg';
import {ReactComponent as BodyMapHandRechts} from '../../../static/images/localisation/hand-rechts.svg';
import {ReactComponent as BodyMapFussRechts} from '../../../static/images/localisation/fuss-rechts.svg';
import {ReactComponent as BodyMapHandLinks} from '../../../static/images/localisation/hand-links.svg';
import {ReactComponent as BodyMapFussLinks} from '../../../static/images/localisation/fuss-links.svg';
import {withFileUpload, WithFileUploadProps} from '../../../utils';
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';
import {withLokalisationHelper, WithLokalisationHelpertsProps} from '../../../utils/with_lokalisation_helper';
import ConfirmationPopupComponent from '../../konsillist/confirmation_popup';
import {FullScreenImageComponent} from '../viewComponents/view_lokalisationcontent';

const viewSelectOptions = [
    "Ganzkörper",
    "Kopf",
    "Linke Hand",
    "Rechte Hand",
    "Linker Fuß",
    "Rechter Fuß"
];


const mapStateToProps = (state: RootState) => ({
    currentKonsil: state.konsile.current_konsil,
    konsilImages: state.image_attachment.konsilImages,
    konsilImageLocation: state.image_attachment.konsilImageLocation,
    isDesktop: state.general.isDesktop,
    locationError: state.evaluation.locationErrors,
    konsilIdOfImageLoadingArray: state.image_attachment.konsilIdOfImageLoadingArray,
    isKonsilUpdatedEventSuccess: state.konsile.isKonsilUpdateEventSuccess,
    allowedMinimumDimensions: state.image_attachment.allowedMinimumDimensions
})

const mapDispatchToProps = {
    dispatch_failure: (id: string, failureObj: Failure) => failure(id, failureObj),
    uploadImage: (file: File, loadInstanceId: string, konsil: Konsil, imageType: KonsilImageType) => uploadKonsilImage(konsil, file, imageType, loadInstanceId),
    deleteImage: (konsil: Konsil, image_id: string) => deleteKonsilImage(konsil, image_id),
    changeImageType: (image_id: string | null, imageType: string) => changeKonsilImageType(image_id, imageType),
    updateKonsil: (konsil: Konsil, previousKonsil: Konsil) => updateKonsil(konsil, previousKonsil),
    dispatch_checkLocationStatus: (konsil: Konsil, konsilImages: Map<string, KonsilImageState>) => checkLocationStatus(konsil, konsilImages),
    dispatch_getMinimumDimensions: () => getMinimumDimensions()
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type TPropsFromRedux = ConnectedProps<typeof connector>
type Props = TPropsFromRedux & WithFileUploadProps & WithLokalisationHelpertsProps

interface IState {
    bodyMap: any;
    bodyMapName: string;
    shouldShowMap: boolean;
    isOpenImageTypeMenu: boolean;
    imageTypeMenuAnchor: any;
    imageTypeMenuImageId: string | null;
    locations: Lokalisation | undefined;
    isActiveHandLeft: boolean;
    isActiveHandRight: boolean;
    isActiveFussLeft: boolean;
    isActiveFussRight: boolean;
    isOpenDeleteConfirm: boolean;
    isFullscreenImg: boolean;
    img_id: string;
    deleteImgId: string;
    imgSrc: string;
    bgIsLoaded: boolean;
}

export class EditLokalisation extends React.Component<Props, IState> {
    private input = createRef<HTMLInputElement>();
    constructor(props: Props) {
        super(props);
        const [bodyMap, bodyMapName] = this.getBodyMapBySelection();
        this.state = {
            bodyMap: bodyMap,
            bodyMapName: bodyMapName,
            shouldShowMap: false,
            isOpenImageTypeMenu: false,
            imageTypeMenuImageId: '',
            imageTypeMenuAnchor: null,
            locations: props.currentKonsil.befund.lokalisation[0],
            isActiveHandLeft: false,
            isActiveHandRight: false,
            isActiveFussLeft: false,
            isActiveFussRight: false,
            isOpenDeleteConfirm: false,
            isFullscreenImg: false,
            img_id: "",
            deleteImgId: "",
            imgSrc: "",
            bgIsLoaded: false,
        };
        this.openImageTypeMenu = this.openImageTypeMenu.bind(this);
        this.selectImageType = this.selectImageType.bind(this);
        this.handleSvgClick = this.handleSvgClick.bind(this);
        this.setBodyMap = this.setBodyMap.bind(this);
        this.highlightLocations = this.highlightLocations.bind(this);
        this.handleClickPrevImg = this.handleClickPrevImg.bind(this);
        this.handleClickNextImg = this.handleClickNextImg.bind(this);
        this.getBodyMapSelectionFromMenu = this.getBodyMapSelectionFromMenu.bind(this);
    }

    componentDidMount() {
        this.state.locations && this.highlightLocations(this.state.locations);
        this.props.dispatch_getMinimumDimensions()
    }

    componentDidUpdate(prevProps: Readonly<TPropsFromRedux>) {
        if (prevProps !== this.props) {
            if (this.props.isKonsilUpdatedEventSuccess) {
                const bodyMapName = this.getBodyMapBySelection()[1];
                this.setBodyMap(bodyMapName);
                if (this.state.locations) {
                    if (this.state.locations.lateralitaet === "LINKS" || this.state.locations.lateralitaet === "RECHTS") {
                        let elementName: string = this.props.getElementName(this.state.locations);
                        let element = document.getElementById(elementName);
                        element?.classList.remove("active")
                    } else {
                        document.getElementById(this.state.locations.lokalisation + "_l")?.classList.remove("active");
                        document.getElementById(this.state.locations.lokalisation + "_r")?.classList.remove("active");
                    }
                };
                this.props.currentKonsil.befund.lokalisation[0] && this.highlightLocations(this.props.currentKonsil.befund.lokalisation[0])
            }
            this.setState({
                locations: this.props.currentKonsil.befund.lokalisation[0],
            });
        }
    }

    uploadFile = () => {
        this.input?.current?.click();
    }

    private frauContext = require.context('../../../static/images/localisation/frau', true, /\.svg$/);
    private mannContext = require.context('../../../static/images/localisation/mann', true, /\.svg$/);
    private gemeinsamContext = require.context('../../../static/images/localisation/gemeinsam', true, /\.svg$/);

    private getBodyMapBySelection(): [any, string] {
        if (!this.props.currentKonsil.befund.lokalisation || this.props.currentKonsil.befund.lokalisation.length === 0)
            return [(this.props.currentKonsil.konsilPatient.geschlecht === "WEIBLICH") ? <BodyMapFrau/> :
                <BodyMapMann/>, "Ganzkörper"];
        else {
            let side: "L" | "R" | "B";
            if (this.props.currentKonsil.befund.lokalisation[0].lateralitaet === "LINKS")
                side = "L"
            else if (this.props.currentKonsil.befund.lokalisation[0].lateralitaet === "RECHTS")
                side = "R"
            else
                side = "B"
            const element: string = this.props.currentKonsil.befund.lokalisation[0].lokalisation || "";
            if (Object.keys(kopfBodyMapping).indexOf(element) !== -1)
                return [(this.props.currentKonsil.konsilPatient.geschlecht === "WEIBLICH") ? <BodyMapKopfFrau/> :
                    <BodyMapKopfMann/>, "Kopf"];
            else if (Object.keys(handBodyMapping).indexOf(element) !== -1 && side === "L")
                return [<BodyMapHandLinks/>, "Linke Hand"];
            else if (Object.keys(handBodyMapping).indexOf(element) !== -1 && side !== "L")
                return [<BodyMapHandRechts/>, "Rechte Hand"];
            else if (Object.keys(fussBodyMapping).indexOf(element) !== -1 && side === "L")
                return [<BodyMapFussRechts/>, "Linker Fuß"];
            else if (Object.keys(fussBodyMapping).indexOf(element) !== -1 && side !== "L")
                return [<BodyMapFussRechts/>, "Rechter Fuß"];
            else
                return [(this.props.currentKonsil.konsilPatient.geschlecht === "WEIBLICH") ? <BodyMapFrau/> :
                    <BodyMapMann/>, "Ganzkörper"];
        }
    }

    private openImageTypeMenu(event: any, image_id: string) {
        this.setState({
            imageTypeMenuAnchor: event.currentTarget,
            isOpenImageTypeMenu: true,
            imageTypeMenuImageId: image_id
        });
    };

    private selectImageType(event) {
        if (event.target.textContent == null || event.target.textContent === "") {
            this.setState({
                imageTypeMenuAnchor: null,
                isOpenImageTypeMenu: false,
                imageTypeMenuImageId: null
            });
            return;
        }
        this.props.changeImageType(this.state.imageTypeMenuImageId, getEnumKeyByEnumValue(KonsilImageType, event.target.textContent));
        this.setState({
            imageTypeMenuAnchor: null,
            isOpenImageTypeMenu: false,
            imageTypeMenuImageId: null
        });
    };

    highlightLocations(location: Lokalisation) {
        if (location.lokalisation === "hand-interdigital") {
            if (location.lateralitaet === "LINKS")
                this.setState({isActiveHandLeft: true, isActiveHandRight: false})
            else if (location.lateralitaet === "RECHTS")
                this.setState({isActiveHandLeft: false, isActiveHandRight: true})
            else
                this.setState({isActiveHandLeft: true, isActiveHandRight: true})
        } else if (location.lokalisation === "fuss-interdigital") {
            if (location.lateralitaet === "LINKS")
                this.setState({isActiveFussLeft: true, isActiveFussRight: false})
            else if (location.lateralitaet === "RECHTS")
                this.setState({isActiveFussLeft: false, isActiveFussRight: true})
            else
                this.setState({isActiveFussLeft: true, isActiveFussRight: true})
        }

        if (location.lateralitaet === "LINKS" || location.lateralitaet === "RECHTS") {
            let elementName: string = this.props.getElementName(location);
            let element = document.getElementById(elementName);
            element?.classList.add("active")
        } else {
            document.getElementById(location.lokalisation + "_l")?.classList.add("active");
            document.getElementById(location.lokalisation + "_r")?.classList.add("active");
        }
    }

    private setBodyMap(selection: string) {
        this.setState({
            bodyMap: this.getBodyMap(selection),
            bodyMapName: selection
        }, () => this.state.locations && this.highlightLocations(this.state.locations));
    }

    private getBodyMap(selection: string) {
        let bodyMap;
        switch (selection) {
            case "Kopf":
                if (this.props.currentKonsil.konsilPatient && this.props.currentKonsil.konsilPatient.geschlecht === "MAENNLICH")
                    bodyMap = <BodyMapKopfMann/>;
                else if (this.props.currentKonsil.konsilPatient && this.props.currentKonsil.konsilPatient.geschlecht === "WEIBLICH")
                    bodyMap = <BodyMapKopfFrau/>;
                else
                    bodyMap = <BodyMapKopfMann/> ;
                break;
            case "Rechte Hand":
                bodyMap = <BodyMapHandRechts/>;
                break;
            case "Linke Hand":
                bodyMap = <BodyMapHandLinks/>;
                break;
            case "Rechter Fuß":
                bodyMap = <BodyMapFussRechts/>;
                break;
            case "Linker Fuß":
                bodyMap = <BodyMapFussLinks/>;
                break;
            default:
                if (this.props.currentKonsil.konsilPatient && this.props.currentKonsil.konsilPatient.geschlecht === "MAENNLICH")
                    bodyMap = <BodyMapMann/>;
                else if (this.props.currentKonsil.konsilPatient && this.props.currentKonsil.konsilPatient.geschlecht === "WEIBLICH")
                    bodyMap = <BodyMapFrau/>;
                else
                    bodyMap = <BodyMapMann/>;
                break;
        }

        return bodyMap;
    }


    private handleSvgClick(event, element: string) {
        if (element === "hand-interdigital_l")
            this.setState({isActiveHandLeft: !this.state.isActiveHandLeft});
        else if (element === "hand-interdigital_r")
            this.setState({isActiveHandRight: !this.state.isActiveHandRight});
        else if (element === "fuss-interdigital_l")
            this.setState({
                isActiveFussLeft: !this.state.isActiveFussLeft,
            });
        else if (element === "fuss-interdigital_r")
            this.setState({
                isActiveFussRight: !this.state.isActiveFussRight,
            });
        else {
            element && this.setState({
                isActiveFussRight: false,
                isActiveFussLeft: false,
                isActiveHandRight: false,
                isActiveHandLeft: false,
            });
        }

        if (event) {
            if ((event as any).target.classList.contains("active"))
                (event as any).target.classList.remove("active")
            else
                (event as any).target.classList.add("active");
        }

        if (element === "kopf") {
            this.setBodyMap("Kopf");

        }
        else if (element === "hand_r") {
            this.setBodyMap("Rechte Hand");

        }
        else if (element === "hand_l") {
            this.setBodyMap("Linke Hand");

        }
        else if (element === "fuss_r") {
            this.setBodyMap("Rechter Fuß");

        }
        else if (element === "fuss_l") {
            this.setBodyMap("Linker Fuß");

        }

        else if (element !== "" && !element.includes("Pfad_")) {
            let locations: Lokalisation | undefined;
            const elementCleaned = element.replace("_l", "").replace("_r", "");
            const elementPrevious = this.props.getElementName(this.state.locations || new Lokalisation());
            const lateralitaetPrevious = this.state.locations?.lateralitaet;
            let lateralitaet: keyof typeof Lateralitaet;
            if (element.includes("_l"))
                lateralitaet = "LINKS"
            else if (element.includes("_r"))
                lateralitaet = "RECHTS"
            else
                lateralitaet = "BEIDSEITIG"

            if (!(document.getElementById(element)) && element === elementCleaned) {
                //if (!this.state.locations.some(item => (item.lokalisation === element))){
                if (this.state.locations?.lokalisation !== element) {
                    document.getElementById(elementPrevious + "_r")?.classList.remove("active")
                    document.getElementById(elementPrevious + "_l")?.classList.remove("active")
                    document.getElementById(elementPrevious)?.classList.remove("active")
                    //locations = _.concat(this.state.locations, {lokalisation: element, lateralitaet: "BEIDSEITIG"});
                    locations = {id: this.state.locations?.id, lokalisation: element, lateralitaet: "BEIDSEITIG"}
                    document.getElementById(element + "_r")?.classList.add("active")
                    document.getElementById(element + "_l")?.classList.add("active")

                    //} else if (this.state.locations.some(item => (item.lokalisation === element && item.lateralitaet !== "BEIDSEITIG"))){
                } else if (this.state.locations?.lokalisation === element && this.state.locations?.lateralitaet !== "BEIDSEITIG") {
                    //locations = _.concat(this.state.locations, {lokalisation: element, lateralitaet: "BEIDSEITIG"});
                    locations = {id: this.state.locations.id, lokalisation: element, lateralitaet: "BEIDSEITIG"}
                    document.getElementById(element + "_r")?.classList.add("active")
                    document.getElementById(element + "_l")?.classList.add("active")

                    //} else if (this.state.locations.some(item => (item.lokalisation === element && item.lateralitaet === "BEIDSEITIG"))){
                } else if (this.state.locations?.lokalisation === element && this.state.locations?.lateralitaet === "BEIDSEITIG") {
                    //locations = [...this.state.locations].filter(item => (item.lokalisation !== elementCleaned))
                    locations = undefined;
                    document.getElementById(element + "_r")?.classList.remove("active")
                    document.getElementById(element + "_l")?.classList.remove("active")
                } else {
                    locations = this.state.locations
                }
            } else {
                const domElement = document.getElementById(element);
                if (this.state.locations?.lokalisation !== elementCleaned) {
                    locations = {id: this.state.locations?.id, lokalisation: elementCleaned, lateralitaet: lateralitaet};
                    if (lateralitaetPrevious === "BEIDSEITIG") {
                        if (this.state.locations?.lokalisation === "anus" || this.state.locations?.lokalisation === "genital") {
                            document.getElementById(elementPrevious)?.classList.remove("active");
                        }
                        document.getElementById(elementPrevious + "_r")?.classList.remove("active")
                        document.getElementById(elementPrevious + "_l")?.classList.remove("active")
                    } else
                        document.getElementById(elementPrevious)?.classList.remove("active")
                    domElement?.classList.add("active")
                } else if (this.state.locations?.lokalisation === elementCleaned && this.state.locations?.lateralitaet !== lateralitaet && this.state.locations?.lateralitaet !== "BEIDSEITIG") {
                    locations = {id: this.state.locations?.id, lokalisation: elementCleaned, lateralitaet: "BEIDSEITIG"}
                    domElement?.classList.add("active")
                } else if (this.state.locations?.lokalisation === elementCleaned && this.state.locations?.lateralitaet !== lateralitaet && this.state.locations?.lateralitaet === "BEIDSEITIG") {
                    locations = {
                        id: this.state.locations?.id,
                        lokalisation: elementCleaned,
                        lateralitaet: (lateralitaet === "LINKS") ? "RECHTS" : "LINKS"
                    };
                    if (lateralitaet === "LINKS")
                        document.getElementById(elementCleaned + "_l")?.classList.remove("active");
                    if (lateralitaet === "RECHTS")
                        document.getElementById(elementCleaned + "_r")?.classList.remove("active");
                } else {
                    locations = (this.state.locations?.lokalisation !== elementCleaned) ? this.state.locations : undefined;
                    domElement?.classList.remove("active")
                }
            }
            this.updateLocations(locations);
        }
    };

    private updateLocations(locations: Lokalisation | undefined) {
        console.log("LOK", locations)
        this.setState({
            locations: locations || new Lokalisation()
        });

        const updatedKonsil: Konsil = {
            ...this.props.currentKonsil,
            befund: {
                ...this.props.currentKonsil.befund,
                lokalisation: locations != null ? [locations]:[]
            }
        };
        this.props.updateKonsil(updatedKonsil, this.props.currentKonsil);
        this.props.dispatch_checkLocationStatus(updatedKonsil, this.props.konsilImages)
    }

    private handleChangeSeitenLokation(element: string, side: keyof typeof Lateralitaet) {
        const elementCleaned: string = element.replace("_l", "").replace("_r", "");
        let locations: Lokalisation | undefined = (this.state.locations) ? Object.assign({}, this.state.locations) : undefined;
        //const index: number = locations.findIndex(item => item.lokalisation === elementCleaned);
        let newElement: string = (side === "LINKS" || side === "RECHTS") ? elementCleaned + "_" + Lateralitaet[side].toLowerCase() : elementCleaned;
        document.getElementById(elementCleaned + "_r")?.classList.remove("active")
        document.getElementById(elementCleaned + "_l")?.classList.remove("active")
        document.getElementById(elementCleaned)?.classList.remove("active")
        if (elementCleaned === "hand-interdigital") {
            this.setState({isActiveHandLeft: false});
            this.setState({isActiveHandRight: false});
        }
        if (elementCleaned === "fuss-interdigital") {
            this.setState({isActiveFussLeft: false});
            this.setState({isActiveFussRight: false});
        }

        if (!(document.getElementById(newElement)) && side === "BEIDSEITIG") {
            //locations[index] = {lokalisation: elementCleaned, lateralitaet: "BEIDSEITIG"}
            locations = {id: this.state.locations?.id, lokalisation: elementCleaned, lateralitaet: "BEIDSEITIG"}
            document.getElementById(elementCleaned + "_r")?.classList.add("active")
            document.getElementById(elementCleaned + "_l")?.classList.add("active")
            if (elementCleaned === "hand-interdigital") {
                this.setState({isActiveHandLeft: true});
                this.setState({isActiveHandRight: true});
            }
            if (elementCleaned === "fuss-interdigital") {
                this.setState({isActiveFussLeft: true});
                this.setState({isActiveFussRight: true});
            }
        } else {
            //locations[index] = {lokalisation: elementCleaned, lateralitaet: side}
            locations = {id: this.state.locations?.id, lokalisation: elementCleaned, lateralitaet: side}
            document.getElementById(newElement)?.classList.add("active")
            if (newElement === "hand-interdigital_l") {
                this.setState({isActiveHandLeft: true});
            }
            if (newElement === "fuss-interdigital_l") {
                this.setState({isActiveFussLeft: true});
            }
            if (newElement === "hand-interdigital_r") {
                this.setState({isActiveHandRight: true});
            }
            if (newElement === "fuss-interdigital_l") {
                this.setState({isActiveFussRight: true});
            }
        }
        if ((element.includes("hand") || element.includes("finger")|| element.includes("naegel")) && side==="RECHTS")
            this.setBodyMap("Rechte Hand");
        if ((element.includes("hand") || element.includes("finger") || element.includes("naegel")) && side==="LINKS")
            this.setBodyMap("Linke Hand");
        if ((element.includes("fuss") || element.includes("sohle") || element.includes("zehen")) && side==="RECHTS")
            this.setBodyMap("Rechter Fuß");
        if ((element.includes("fuss") || element.includes("sohle") || element.includes("zehen")) && side==="LINKS")
            this.setBodyMap("Linker Fuß");
        this.updateLocations(locations);
    }

    handleClickPrevImg() {
        const id = this.state.img_id;
        const idArray = Object.keys(this.props.konsilImages);
        const index = (idArray.indexOf(id) - 1 !== -1) ? idArray.indexOf(id) - 1 : idArray.length - 1
        this.setState({
            img_id: idArray[index]
        });
    };

    handleClickNextImg() {
        const id = this.state.img_id;
        const idArray = Object.keys(this.props.konsilImages);
        const index = (idArray.indexOf(id) + 1 !== idArray.length) ? idArray.indexOf(id) + 1 : 0
        this.setState({
            img_id: idArray[index]
        });
    };


    getBodyMapSelectionFromMenu(element: string) {
        let menuItem: any;
        Object.values(MenuItems).map(item => {
            if (Object.keys(item).includes(element)) {
                menuItem = item;
                return menuItem;
            }
            else
                return null;
        })
        const bodyMapSelection = Object.keys(MenuItems).find(key => MenuItems[key] === menuItem);
        if (element === "hals") {
            this.setBodyMap("Ganzkörper")
        }
        else {
            if (bodyMapSelection==="Kopf und Hals") {
                this.setBodyMap("Kopf")
            }
            else if (bodyMapSelection==="Hand") {
                this.setBodyMap("Rechte Hand")
            }
            else if (bodyMapSelection==="Fuß") {
                this.setBodyMap("Rechter Fuß")
            }
            else {
                this.setBodyMap("Ganzkörper")
            }
        }
    }
    renderLocations() {
        let bodyElement: any;
        let locationName: string = this.props.getElementName(this.state.locations || new Lokalisation());

        try {
            if (this.props.currentKonsil.konsilPatient.geschlecht === "WEIBLICH")
                bodyElement = this.frauContext('./' + locationName + '.svg');
            else if (this.props.currentKonsil.konsilPatient.geschlecht === "MAENNLICH")
                bodyElement = this.mannContext('./' + locationName + '.svg');
            else
                bodyElement = this.mannContext('./' + locationName + '.svg');
        } catch {
            try {
                bodyElement = this.gemeinsamContext('./' + locationName + '.svg');
            } catch {
                bodyElement = null
            }
        }

        if (bodyElement) return (
            <Box className="body-location-list-element" key={uuid.v4()}>
                <Paper
                    elevation={3}
                    className="vorschau"
                    style={{backgroundImage: "url(" + bodyElement + ")"}}
                ></Paper>
                <Typography className="image-txt" variant="subtitle1" color="inherit">
                    {this.props.getBodyElementValue(this.state.locations?.lokalisation || "")}
                </Typography>
                <ToggleButtonGroup color="primary" className="btn-group-select pad-top"
                                   aria-label="outlined primary button group"
                                   exclusive
                                   onChange={(event, value) => {
                                        (this.state.locations?.lokalisation !== "anus" && this.state.locations?.lokalisation !== "genital") &&
                                       this.handleChangeSeitenLokation(this.state.locations?.lokalisation || "", value)
                                   }}
                >
                    {
                        (this.state.locations?.lokalisation !== "anus" && this.state.locations?.lokalisation !== "genital") ?
                        Object.keys(Lateralitaet).map(key =>
                            <ToggleButton key={key} value={key}
                                        className={((this.state.locations?.lateralitaet || "B") === key ? 'active' : '')}>{Lateralitaet[key]}</ToggleButton>
                        ) :
                        Object.keys(Lateralitaet).map(key =>
                            <ToggleButton key={key} value={key}
                                        className={((this.state.locations?.lateralitaet || "B") === key ? 'active inactive' : 'inactive')}>{Lateralitaet[key]}</ToggleButton>
                        )
                    }
                </ToggleButtonGroup>
                <IconButton className="delete" size="small"
                            aria-label="delete-bodypart"
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                this.handleSvgClick(undefined, this.props.getElementName(this.state.locations || new Lokalisation()));
                                this.setBodyMap("Ganzkörper")
                            }}>
                    <Delete/>
                </IconButton>
            </Box>
        )
        else
            return (<></>)
    }


    render() {
        const locationError: Map<string, string> = this.props.locationError.find(item => item.konsilID === this.props.currentKonsil.id)?.errors || new Map<string, string>();
        return (
            <>
                <Box className="lokalisation-data-container"
                     onDragEnter={this.props.onDragEnter}>
                    <div className={"drag-drop-container" + (this.props.isActiveDrag ? " active" : "")}
                         onDragEnter={this.props.onDragEnter}
                         onDragOver={this.props.onDragOver}
                         onDragLeave={this.props.onDragLeave}
                         onDrop={(event) => this.props.onDrop(
                             event,
                             this.props.uploadImage,
                             [this.props.allowedMinimumDimensions.largeAxis, this.props.allowedMinimumDimensions.smallAxis, Infinity, Infinity],
                             this.props.currentKonsil,
                             getEnumKeyByEnumValue(KonsilImageType, KonsilImageType.UEBERSICHTSFOTO)
                         )}
                    >
                        <div className="border-container">
                            <div className="elements">
                                <PhotoLibrary className="photo-icon"/>
                                <p>Bilder hier ablegen</p>
                            </div>
                        </div>
                    </div>
                    <Menu id="img-upload-menu"
                          anchorEl={this.state.imageTypeMenuAnchor}
                          keepMounted
                          open={this.state.isOpenImageTypeMenu}
                          onClose={this.selectImageType}>
                        {Object.keys(KonsilImageType).map((key) =>
                            <MenuItem key={key} onClick={this.selectImageType}>
                                {KonsilImageType[key]}
                            </MenuItem>
                        )}
                    </Menu>
                    <input
                        accept="image/png, image/jpeg"
                        className="upload-input"
                        id="upload-img"
                        multiple={true}
                        type="file"
                        key = {uuid.v4()}
                        ref={this.input}
                        onChange={(event) => {
                            console.log("File Upload", getEnumKeyByEnumValue(KonsilImageType, KonsilImageType.UEBERSICHTSFOTO));
                            this.props.onInputChanged(
                                event,
                                this.props.uploadImage,
                                [this.props.allowedMinimumDimensions.largeAxis, this.props.allowedMinimumDimensions.smallAxis, Infinity, Infinity],
                                this.props.currentKonsil,
                                getEnumKeyByEnumValue(KonsilImageType, KonsilImageType.UEBERSICHTSFOTO)
                            );
                        }}
                    />
                    <Box className="side-view">
                        <Box className="bodypart">
                            <Box className="bodypart-header">
                                <Typography variant="subtitle1"
                                            color={(locationError.has("konsil.lokation")) ? "error" : "inherit"}>
                                    Körperregion *
                                </Typography>
                                <LongMenu
                                    options={MenuItems}
                                    maxHeight={48}
                                    onChange={(result) => {
                                        this.handleSvgClick(undefined, result);
                                        this.getBodyMapSelectionFromMenu(result);
                                    }}
                                />
                            </Box>
                            {(this.state.locations == null) ?
                                <Box
                                    className="bodypart-select">
                                    <Typography className="image-txt" variant="caption" color="inherit">
                                        Ein Körperteil können Sie entweder im obigen Auswahlmenü (
                                        <ArrowDropDown/>
                                        ) oder durch Anklicken im Körperstempel (<Accessibility/>) auswählen.
                                    </Typography>
                                </Box> :
                                this.renderLocations()
                            }
                        </Box>
                        <Divider/>
                        <Box className="body-images">
                            <Box className="body-images-header">
                                <Typography variant="subtitle1"
                                            color={(locationError.has("konsil.images") || Object.keys(this.props.konsilImages).length>3) ? "error" : "inherit"}>
                                    Fotografien*
                                </Typography>
                                <Button
                                    variant="contained"
                                    size="small"
                                    className="slim"
                                    color="primary"
                                    aria-controls="img-upload-menu"
                                    onClick={() => {
                                        if (Object.keys(this.props.konsilImages).length>2) {
                                            const failure: Failure = new Failure();
                                            failure.error = "Es dürfen maximal 3 Bilder zu einem Konsil hinzugefügt werden. Bitte löschen Sie ein Bild bevor Sie ein weiteres Bild hinzufügen";
                                            this.props.dispatch_failure(uuid.v4(), failure);
                                        }
                                        else
                                            this.uploadFile();
                                    }}
                                >
                                    <Add/>
                                </Button>
                            </Box>
                            <Box className="body-image-content">
                                <Box
                                    className="bodyimage-text">
                                    <Typography className="image-txt" variant="caption" color="inherit">
                                        {`Bitte fügen Sie mindestens ein Bild (maximal drei) mit einer minimalen Auflösung von ${this.props.allowedMinimumDimensions.largeAxis}\u00d7${this.props.allowedMinimumDimensions.smallAxis} px hinzu.`}
                                    </Typography>
                                </Box>
                                {Object.keys(this.props.konsilImages).length>3 &&
                                    <Typography className="pad-left-l pad-bottom" variant="body2" color="error">
                                        Durch Synchronisation mit der mobilen Anwendung sind zur Zeit mehr als drei Fotografien an Ihren Konsilauftrag angehängt. Bitte reduzieren Sie die Anzahl durch Löschen der der überzähligen Fotografien.
                                    </Typography>
                                }
                                {Object.keys(this.props.konsilImages).length === 0 ? (
                                    <Box
                                        className="body-image add-image"
                                        onClick={this.uploadFile}
                                    >
                                        {this.props.konsilIdOfImageLoadingArray.find(item => item === this.props.currentKonsil.id) ?
                                            <Paper
                                                elevation={3}
                                                className="vorschau-loading">
                                                <CircularProgress size = {25}/>
                                            </Paper> :
                                            <>
                                                 <Paper
                                                    elevation={3}
                                                    className="add-first-image">
                                                    <Add/>
                                                </Paper>
                                                <Typography className="image-txt" variant="caption" color="inherit">
                                                    Neues Bild hinzufügen
                                                </Typography>
                                            </>
                                        }

                                    </Box>) : (
                                    Object.keys(this.props.konsilImages).map((image_id) => {
                                        const konsilImageState: KonsilImageState = this.props.konsilImages[image_id];
                                        return (
                                            <Box className="body-image" key={"konsil_image_" + image_id}>
                                                {(konsilImageState.loading || konsilImageState.loading === undefined) ?
                                                <>
                                                    <Paper
                                                        elevation={3}
                                                        className="vorschau-loading"
                                                    >
                                                        <CircularProgress/>
                                                    </Paper>
                                                    <Box className="image-txt">
                                                        <Typography variant="subtitle1" color="inherit">
                                                            Wird geladen…
                                                        </Typography>

                                                    </Box>
                                                </> :
                                                    <>
                                                        <Paper
                                                            elevation={3}
                                                            className="vorschau"
                                                            //style={{backgroundImage: `url(${this.state.bgIsLoaded ? this.props.konsilImages[image_id].url : ""})`}}
                                                            //style={{backgroundImage: `url(${this.props.konsilImages[image_id].url}`}}
                                                            //style={{backgroundImage: this.state.bgIsLoaded ? `url(${this.props.konsilImages[image_id].url}` : `url(${ImagePlaceholder})`}}
                                                            style={{backgroundImage: this.state.bgIsLoaded ? `url(${this.props.konsilImages[image_id].url}` : `url(${ImagePlaceholder})`}}
                                                            onClick={() => {
                                                                this.setState({
                                                                    isFullscreenImg: true,
                                                                    img_id: image_id
                                                                })
                                                            }}
                                                        >

                                                        </Paper>
                                                        <BackgroundImageOnLoad
                                                            src={ImagePlaceholder}
                                                            onLoadBg={() => {
                                                                this.setState({bgIsLoaded: true});
                                                            }}
                                                            onError={err => console.log('error', err)}
                                                        />
                                                        <Box className="image-txt"
                                                            onClick={(event) => this.openImageTypeMenu(event, image_id)}>
                                                            <Typography variant="subtitle1" color="inherit">
                                                                {KonsilImageType[konsilImageState.imageType]}
                                                            </Typography>
                                                            {konsilImageState.dermatoscopeType &&
                                                                <Typography variant = "body2" color="inherit">
                                                                    {DermatoscopeType[konsilImageState.dermatoscopeType]}
                                                                </Typography>
                                                            }
                                                            <Typography variant="body2" color="inherit">
                                                                {konsilImageState.dateRecorded !== null &&
                                                                    new Date(konsilImageState.dateRecorded).toLocaleDateString().split(",")[0]
                                                                }
                                                                {(konsilImageState.dateRecorded === null && konsilImageState.dateCreated !== null) &&
                                                                    new Date(konsilImageState.dateCreated).toLocaleDateString().split(",")[0]
                                                                }
                                                                {(konsilImageState.dateRecorded === null && konsilImageState.dateCreated === null) &&
                                                                    "-"
                                                                }
                                                            </Typography>
                                                        </Box>
                                                        <IconButton className="delete" size="small"
                                                                    aria-label="delete-image"
                                                                    onClick={(event) => {
                                                                        event.preventDefault();
                                                                        event.stopPropagation();
                                                                        this.setState({
                                                                            isOpenDeleteConfirm: true,
                                                                            deleteImgId: image_id
                                                                        })
                                                                    }}>
                                                            <Delete/>
                                                        </IconButton>
                                                        <IconButton className="delete" size="small"
                                                                    style={{marginRight: "2rem"}}
                                                                    aria-label="download-image"
                                                                    download={`konsilImage_${image_id.slice(0, 8)}.jpg`}
                                                                    href={`${this.props.konsilImages[image_id].url}`}
                                                                    >
                                                            <CloudDownload/>
                                                        </IconButton>
                                                    </>
                                                }
                                                <ConfirmationPopupComponent
                                                    text="Wollen Sie das Bild wirklich löschen?"
                                                    isOpen={this.state.isOpenDeleteConfirm}
                                                    onClose={(event) => {
                                                        event.stopPropagation();
                                                        this.setState({isOpenDeleteConfirm: false})
                                                    }}
                                                    onConfirm={(event) => {
                                                        event.stopPropagation();
                                                        this.props.deleteImage(this.props.currentKonsil, this.state.deleteImgId);
                                                        this.setState({isOpenDeleteConfirm: false, deleteImgId: ""})
                                                    }}
                                                />
                                            </Box>
                                        );
                                    })
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <Box className={'map ' + (this.state.shouldShowMap ? 'active' : '')}>
                        <Box
                            onClick={(event) => {
                                this.handleSvgClick(event, (event as any).target.id);
                            }}
                            className="svg-container"
                        >
                            {(this.state.bodyMapName === "Linke Hand") &&
                            <Button id = 'test' className={"slim btn-interdigital" + (this.state.isActiveHandLeft ? " active" : "")}
                                    variant="contained" color="secondary"
                                    onClick={() => this.handleSvgClick(undefined, "hand-interdigital_l")}>
                                Interdigital
                            </Button>
                            }
                            {(this.state.bodyMapName === "Rechte Hand") &&
                            <Button
                                className={"slim btn-interdigital" + (this.state.isActiveHandRight ? " active" : "")}
                                variant="contained" color="secondary"
                                onClick={() => this.handleSvgClick(undefined, "hand-interdigital_r")}>
                                Interdigital
                            </Button>
                            }
                            {(this.state.bodyMapName === "Linker Fuß") &&
                            <Button className={"slim btn-interdigital" + (this.state.isActiveFussLeft ? " active" : "")}
                                    variant="contained" color="secondary"
                                    onClick={() => this.handleSvgClick(undefined, "fuss-interdigital_l")}>
                                Interdigital
                            </Button>
                            }
                            {(this.state.bodyMapName === "Rechter Fuß") &&
                            <Button
                                className={"slim btn-interdigital" + (this.state.isActiveFussRight ? " active" : "")}
                                variant="contained" color="secondary"
                                onClick={() => this.handleSvgClick(undefined, "fuss-interdigital_r")}>
                                Interdigital
                            </Button>
                            }
                            <TransformWrapper limitToBounds={true} minScale={0.5} centerOnInit={true}>
                                <TransformComponent>
                                    {this.state.bodyMap}
                                </TransformComponent>
                            </TransformWrapper>
                        </Box>
                        {!this.props.isDesktop &&
                        <Button
                            className="slim btn-lokalisation-list"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                    this.setState({shouldShowMap: this.props.isDesktop !== undefined ? this.props.isDesktop : true});
                                }}
                        >
                            <List/>
                        </Button>
                        }
                        {(this.props.isDesktop && this.state.bodyMapName !== "Ganzkörper") &&
                        <Button className="slim btn-lokalisation-list" variant="contained" color="primary"
                                onClick={() => this.setBodyMap("Ganzkörper")}>
                            <Accessibility/>
                        </Button>
                        }
                        <Paper className="pad change-view" square>
                            <Typography variant="subtitle1" color="inherit">
                                Ansicht
                            </Typography>
                            <Divider orientation="vertical" flexItem/>
                            <SelectInput options={viewSelectOptions} value={this.state.bodyMapName}
                                         onChange={(selection) => {
                                             this.setBodyMap(selection)
                                         }}/>
                        </Paper>

                    </Box>
                </Box>

                <FullScreenImageComponent
                    isActive={(this.state.isFullscreenImg)}
                    handleClose={() => this.setState({
                        isFullscreenImg: false,
                        img_id: ""
                    })}
                    handleClickPrev={this.handleClickPrevImg}
                    handleClickNext={this.handleClickNextImg}
                    title={KonsilImageType[this.props.konsilImages[this.state.img_id]?.imageType]}
                    src={this.props.konsilImages[this.state.img_id]?.url}
                    alt={this.props.konsilImages[this.state.img_id]?.imageType}
                    timeStamp={this.props.konsilImages[this.state.img_id]?.dateRecorded === null ? "-" : new Date(this.props.konsilImages[this.state.img_id]?.dateRecorded).toLocaleString()}
                />
            </>
        );
    }
}

export const EditLokalisationContainer = connector(withFileUpload(withLokalisationHelper(EditLokalisation)));
export default EditLokalisationContainer;
