import { Box, CircularProgress, IconButton, Typography } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf'; // import necessary components from react-pdf library
import pdfjsWorker from "react-pdf/src/pdf.worker.entry";

interface IPdfInlineState {
    numPdfPages: number | null; //decalre state value to store number of pdf pages
    pageNumber: number;
    isMouseMoving: boolean;
    isAtBottom: boolean;
    isMouseOver: boolean;
    timeoutId: number;
}

interface IPdfInlineProps {
    pdfData: string | null;
    isPdfLoading?: boolean;
    header?: string
}

export default class PdfInline extends React.Component<IPdfInlineProps, IPdfInlineState> {
    private _element: React.RefObject<HTMLInputElement>;
    constructor(props: IPdfInlineProps) {
        super(props);
        this.state = {
            numPdfPages: null,
            pageNumber: 1,
            isMouseMoving: false,
            isAtBottom: false,
            isMouseOver: false,
            timeoutId: 0,
        }
        this._element = React.createRef();
        //pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; // request pdf-worker for pdf-react functionality
        pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
        this.onPdfLoadSuccess = this.onPdfLoadSuccess.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.setPreviousPage = this.setPreviousPage.bind(this);
        this.setNextPage = this.setNextPage.bind(this);
        this.handleOnScroll = this.handleOnScroll.bind(this);
        this.handleOnMouseMove = this.handleOnMouseMove.bind(this);
    }

    onPdfLoadSuccess({ numPages }) { // get number of pdf pages, when pdf is loaded
        this.setState({ numPdfPages: numPages });
    }

    handleChangePage(offset: number) {
        this.setState(
            prevState => {
                return {
                    pageNumber: prevState.pageNumber + offset
                }
            },
            () => {
                setTimeout(() => { // Timeout to wait until this._element.current gets actual scroll position after page change
                    if (this._element.current) {
                        if ((this._element.current?.scrollTop >= this._element.current?.scrollHeight - this._element.current?.clientHeight - Math.floor(this._element.current?.scrollTop) * 0.01))
                            this.setState({ isAtBottom: true });
                        else
                            this.setState({ isAtBottom: false });
                    }
                }, 300)
            }
        )
    }

    setPreviousPage() {
        this.handleChangePage(-1);
    }

    setNextPage() {
        this.handleChangePage(1);
    }

    handleOnScroll(event) {
        if ((event.target.scrollTop >= event.target.scrollHeight - event.target.clientHeight - Math.floor(event.target.scrollTop) * 0.01))
            this.setState({ isAtBottom: true });
        else
            this.setState({ isAtBottom: false });
    }

    handleOnMouseMove(event) {
        event.persist();
        clearTimeout(this.state.timeoutId)
        this.setState(
            { isMouseMoving: true },
            () => {
                const timeoutId = window.setTimeout(() => this.setState({ isMouseMoving: false }), 2000)
                this.setState({ timeoutId: timeoutId })
            }
        )
    }

    render() {
        
        return (
            <div
                ref={this._element}
                className="pdf-preview-container"
                onMouseMove={(event) => this.handleOnMouseMove(event)}
                onScroll={this.handleOnScroll}
            >
                {this.props.header &&
                    <h2 className="modal-header" >{this.props.header}</h2>
                }              
                {(this.props.pdfData && this.props.pdfData !== "") &&
                    <>
                        <Document
                            file={this.props.pdfData}
                            onLoadSuccess={this.onPdfLoadSuccess}
                            className="pdf-wrapper flex-column-align-center"
                        >
                            <Page pageNumber={this.state.pageNumber} width={800} />

                            {/* {
                            Array.apply(null, Array(this.state.numPdfPages))
                                .map((x, i) => i = i + 1)
                                .map((page, idx) =>
                                    <Page pageNumber={page} key={idx}/>
                                )
                        } */}
                        </Document>
                        <Box
                            onMouseEnter={() => this.setState({ isMouseOver: true })}
                            onMouseLeave={() => this.setState({ isMouseOver: false })}
                            className={(this.state.isMouseMoving || this.state.isAtBottom || this.state.isMouseOver) ? `flex-row-center pdf-pager` : `flex-row-center pdf-pager hidden`}
                        >
                            <IconButton
                                aria-label="previous"
                                className="img-prev"
                                disabled={this.state.pageNumber <= 1}
                                onClick={this.setPreviousPage}
                                size = 'small'
                            >
                                {/* switch to previous page*/}
                                <NavigateBefore fontSize="large" />
                            </IconButton>
                            <Typography>
                                {this.state.pageNumber || (this.state.numPdfPages ? 1 : '--')} / {this.state.numPdfPages || '--'}
                            </Typography>
                            <IconButton
                                aria-label="next"
                                className="img-next"
                                disabled={this.state.numPdfPages ? this.state.pageNumber >= this.state.numPdfPages : false}
                                onClick={this.setNextPage}
                                size = 'small'
                            >
                                {/* switch to next page*/}
                                <NavigateNext fontSize="large" />
                            </IconButton>
                        </Box>
                    </>
                }
                {(this.props.pdfData === undefined) &&
                    <Box className="pdf-load">
                        <Typography variant="body1" className='pdf-load-text2'>
                            PDF in Vorbereitung...
                    </Typography>
                        <CircularProgress
                            size={50}
                            className='pdf-load-progress'
                        />
                    </Box>
                }
                {(this.props.isPdfLoading) &&
                    <Box className="pdf-load">
                        <Typography variant="body1" className='pdf-load-text2'>
                            PDF in Vorbereitung...
                    </Typography>
                        <CircularProgress
                            size={50}
                            className='pdf-load-progress'
                        />
                    </Box>
                }
                {(this.props.pdfData === "") &&
                    <span className="pdf-load failure"> Pdf-Erstellung fehlgeschlagen</span>
                }
            </div>

        )
    }
}
