import  React from 'react';
import {connect, ConnectedProps} from "react-redux";
import { UserContainer } from '../../header';
import Logo from "../../../static/images/logo-telescan.svg";
import { Box, Button, Fab, IconButton, Tooltip } from '@material-ui/core';
import { RootState } from '../../../redux/reducers';
import { getAllBills, navigate, checkNewInQuartalsBill } from "../../../redux/actions";
import { Add, Replay} from '@material-ui/icons';
import AddNewBillContainer from '../new_bill/add_new_bill';
import { Quartalsabrechnungen } from 'telescan-core';

const mapStateToProps = (state: RootState) => ({
    isDesktop: state.general.isDesktop,
    role: state.user.role,
    user: state.user.user,
    sendFailures: state.konsile.sendFailures,
    arztZertifikateMetaInfo: state.zertifikate.arztZertifikateMetaInfo,
    bsZertifikatMetaInfo: state.zertifikate.betriebsstaettenZertifikatMetaInfo,
    updateInfo: state.update.updateInfo,
    zertifikatStatus: state.zertifikate.zertifikatStatus,
})

const mapDispatchToProps = {
    dispatch_navigate: (url: string) => navigate(url),
    dispatch_getAllBills: () => getAllBills(),
    dispatch_checkNewInQuartalsBill: (id: string) => checkNewInQuartalsBill(id),
}
type TPropsFromRedux = ConnectedProps<typeof connector>

const connector = connect(mapStateToProps, mapDispatchToProps)


interface IState {
    canOpen: boolean;
    isAddNewBillOpen: boolean
}
class QuartalBillsTableHeader extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            canOpen: false,
            isAddNewBillOpen: false,
        };
        this.selectHandleOnOpen = this.selectHandleOnOpen.bind(this);
        this.toogleAddNewBillModal = this.toogleAddNewBillModal.bind(this);
        this.closeAddNewBillModal = this.closeAddNewBillModal.bind(this);
    }
    componentDidMount(){
        this.props.dispatch_getAllBills()
        .then((response: Quartalsabrechnungen[]) => {
            response.map((item: Quartalsabrechnungen) => {
                if (item.statusQuartalsabrechnung !== "ABGERECHNET")
                    return this.props.dispatch_checkNewInQuartalsBill(item.id || "");
                else return null  
            })
        })
        .catch(error => console.log(error));
    }
    selectHandleOnOpen(event) {
        this.setState({canOpen: !this.state.canOpen});
    }

    toogleAddNewBillModal() {
        this.setState({isAddNewBillOpen: !this.state.isAddNewBillOpen})
    }

    closeAddNewBillModal() {
        this.setState({isAddNewBillOpen: false});
    }

    render() {
        return (
            <header className="header-bills-quartal">
                <Box className="flex-row-center">
                    <Button className="grid-button back-btn left narrow" onClick={() => this.props.dispatch_navigate("/")}>
                        Zurück
                    </Button>
                    {this.props.isDesktop && <h1 className="title pad-left-l">Quartalsabrechnungen</h1>}
                </Box>
                <img src={Logo} alt="Logo" className="logo"/>
                
                <UserContainer userDetails={this.props.user}/>
                <Box className="title-actions">
                    {!this.props.isDesktop && <h1 className="title">Quartalsabrechnungen</h1>}
                <div className="actions flex-row-center">
                        <Tooltip
                            title="Neuer Abrechnung anlegen"
                            placement="top"
                        >
                            <Button
                                className="add-konsil slim"
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick = {() => this.toogleAddNewBillModal()}
                            >
                                <Add/>
                            </Button>
                        </Tooltip>
                        {
                            <AddNewBillContainer
                                isAddNewBillOpen = {this.state.isAddNewBillOpen}
                                closeAddNewBillModal = {this.closeAddNewBillModal}
                            />
                        }
                        <Tooltip title="Neues Konsil" placement="left">
                            <Fab
                                className="add-konsil-action"
                                color="primary"
                                aria-label="add"
                                onClick = {() => this.toogleAddNewBillModal()}
                            >
                                <Add/>
                            </Fab>
                        </Tooltip>

                        <Tooltip title="Quartalsabrechnungen aktualisieren" placement="top">
                            <IconButton
                                type="submit"
                                size="small"
                                className=""
                                aria-label="Quartalsabrechnungen aktualisieren"
                                onClick={() => this.props.dispatch_getAllBills()}
                            >
                                <Replay/>
                            </IconButton>
                        </Tooltip>
                        {this.props.isDesktop && 
                            <Box className="flex-spacer"/>
                        }
                        {/* <FormControl variant="outlined">
                            <Select
                                labelId="filter-label"
                                id="mutiple-filter-checkbox"
                                className="table-filter select-dopdown-btn MuiButtonBase-root MuiButton-root MuiButton-outlined slim MuiButton-outlinedSizeSmall MuiButton-sizeSmall"
                                multiple
                                displayEmpty
                                value={this.props.filter}
                                //onChange={this.changeFilter}
                                input={<Input />}
                                SelectDisplayProps={{
                                    onClick: this.selectHandleOnOpen
                                }}
                                MenuProps={{
                                    onClose:(event, reason:'backdropClick' | 'escapeKeyDown') => this.selectHandleOnOpen(event)
                                }}
                                open={this.state.canOpen}
                                renderValue={(selected) => {
                                    if ((selected as string[]).length === 0) {
                                        return <span>
                                            {this.props.isDesktop && <Tooltip title="Tabelle filtern" placement="top"><span>Filter</span></Tooltip>}
                                            {!this.props.isDesktop && <Tooltip title="Tabelle filtern" placement="top"><VolumeMute className="filter-icon"/></Tooltip>}
                                        </span>
                                    } else {
                                        return (
                                            <span className="flex-row-center" >
                                                {this.props.isDesktop && <Tooltip title="Tabelle filtern" placement="top"><span>Filter</span></Tooltip>}
                                            {!this.props.isDesktop && <Tooltip title="Tabelle filtern" placement="top"><VolumeMute className="filter-icon"/></Tooltip>}
                                                <Tooltip title="Filter zurücksetzen" placement="left">
                                                    <span className="filter-close"
                                                        onClick={(event) => {
                                                                event.stopPropagation();
                                                                //this.resetFilter();
                                                            }}
                                                    >
                                                        <Close/>
                                                    </span>
                                                </Tooltip>
                                            </span>
                                        );
                                    }

                                    }}
                                inputProps={{ 'aria-label': 'Without label' }}
                                //MenuProps={MenuProps}
                                //{(selected) => ((selected as string[]).join(', '))}
                            >
                                <MenuItem disabled value="">
                                    <em>Filter</em>
                                </MenuItem>
                            

                            Object.keys(KonsilStatusType).filter(item => item !== "IN_ARBEIT").map((status) => (
                                <MenuItem key={status} value={KonsilStatusType[status]}>
                                <Checkbox checked={this.props.filter.indexOf(KonsilStatusType[status]) > -1}
                                />
                                <ListItemText primary={KonsilStatusType[status]} />
                                </MenuItem>
                            ))
                            </Select>

                        </FormControl> */}
                    </div>
                </Box>
                {/* <HeaderSearch/> */}
            </header>
        );
    }
}

const QuartalBillsTableHeaderContainer = connector(QuartalBillsTableHeader);
export default QuartalBillsTableHeaderContainer;