import React from "react";
import {Konsil} from "telescan-core";
import {EditAnamneseContainer, ViewAnamneseContainer} from ".";

interface IProps {
    konsil: Konsil | null;
}

export class Anamnese extends React.Component<IProps> {
    render() {
        if (this.props.konsil?.konsilStatus === "IN_ARBEIT" && this.props.konsil?.bearbeitungsStatus !== "UPLOAD")
            return <EditAnamneseContainer/>
        else
            return <ViewAnamneseContainer/>
    }
}
