import { Box, Button, Paper, Tooltip, Typography } from "@material-ui/core";
import { Add, CheckCircle, Edit, HighlightOff } from "@material-ui/icons";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { KIMConnector, KIMContext, KIMGeneral, KIMUser, TIConnectorEvent, UploadPath } from "telescan-core";
import { getKimSettings, uploadCertificates, sendKimSettings, registerEHBA, setTiConnectorEvent, getTiConnectionStatus, setIsTestTiConnectionClicked } from "../../../redux/actions/kim_actions";
import { RootState } from "../../../redux/reducers";
import EditSettings from "./edit_settings";
import { checkKimSettingsBE, getInitialisedServices, setSuccessMessage } from "../../../redux/actions";
import { isEmptyObject } from "../../../utils";
import { cancelSignKonsil, getKimMailInLdapStatus } from "../../../redux/actions/ti_connector_actions";
import uuid from 'uuid';

interface IState {
    isEditKim: boolean;
    isUploadCertificatesDialog: boolean;
    isNewSettings: boolean;
    kimGeneralBackup: KIMGeneral;
    kimUserBackup: KIMUser;
    isSignatureDialogOpen: boolean;
}


const mapStateToProps = (state: RootState) => ({
    kimGeneral: state.kim.kimGeneral,
    kimUser: state.kim.kimUser,
    initialisedServices: state.general.initialisedServices,
    tiConnectorEvent: state.kim.tiKonnectorEvent,
    kimSettingsError: state.evaluation.kimSettingsError,
    isTiConnected: state.kim.isTiConnected,
    isTiStatusLoading: state.kim.isTiStatusLoading,
    useSignature: state.general.useSignature,
    isKimMailInLdap: state.ti_connector.isKimMailInLdap,
    isLdapRequest: state.ti_connector.isLdapRequest,
    isTestTiConnectionClicked: state.kim.isTestTiConnectionClicked,
    user: state.user.user
})

const mapDispatchToProps = {
    dispatch_getKimSettings: () => getKimSettings(),
    dispatch_sendKimSettings: (kimGeneral: KIMGeneral, kimUser: KIMUser) => sendKimSettings(kimGeneral, kimUser),
    dispatch_uploadCertificates: (certList: Blob[], connectorId: string, certPassword: string) => uploadCertificates(certList, connectorId, certPassword),
    dispatch_registerEHBA: () => registerEHBA(),
    dispatch_setTiConnectorEvent: (tiConnectorEvent: TIConnectorEvent) => setTiConnectorEvent(tiConnectorEvent),
    dispatch_checkKimSettingsBE: () => checkKimSettingsBE(),
    dispatch_getInitialisedServices: () => getInitialisedServices(),
    dispatch_setSuccessMessage: (id: string, message: string) => setSuccessMessage(id, message),
    dispatch_getTiConnectionStatus: () => getTiConnectionStatus(),
    dispatch_getKimMailInLdapStatus: (kimMail: string) => getKimMailInLdapStatus(kimMail),
    dispatch_cancelSignKonsil: () => cancelSignKonsil(),
    dispatch_setIsTestTiConnectionClicked: (isClicked: boolean) => setIsTestTiConnectionClicked(isClicked),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

class KimConfiguration extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            isEditKim: false,
            isUploadCertificatesDialog: false,
            isNewSettings: false,
            kimGeneralBackup: new KIMGeneral(),
            kimUserBackup: new KIMUser(),
            isSignatureDialogOpen: false,
        }
    }

    componentDidUpdate(prevProps: TPropsFromRedux) {
        if (this.props.initialisedServices !== prevProps.initialisedServices && this.props.initialisedServices.includes(UploadPath.KIM)) {
            this.props.dispatch_setSuccessMessage(uuid.v4(), "KIM-Erweiterung wurde erfolgreich konfiguriert")
        }
    }

    handleEditKimClick = () => {
        if (isEmptyObject(this.props.kimGeneral) && isEmptyObject(this.props.kimUser)) {
            this.props.dispatch_sendKimSettings(this.initialiseNewSettings(), new KIMUser())
            .then(() => {
                this.setState({
                    kimGeneralBackup: {...this.props.kimGeneral},
                    kimUserBackup: {...this.props.kimUser}
                })
            })
        } else {
            this.setState({
                kimGeneralBackup: {...this.props.kimGeneral},
                kimUserBackup: {...this.props.kimUser}
            })
        }
        this.setState({isEditKim: true});
    }



    handleCancelKimClick = () => {
        this.props.dispatch_sendKimSettings(this.state.kimGeneralBackup, this.state.kimUserBackup);
        this.setState({isEditKim: false});
    }

    handleSaveKimClick = () => {
        this.setState({isEditKim: false});
        this.props.dispatch_checkKimSettingsBE()
        .then(() => {
            this.props.dispatch_getInitialisedServices();
        })
    }

    handleUploadCertButton = (certToUpload: Blob[], item: KIMConnector) => {
        this.props.dispatch_uploadCertificates(certToUpload, item.id || "", item.certificatePassword || "");
    }

    initialiseNewSettings = (): KIMGeneral => {
        const kimGeneral = new KIMGeneral();
        const kimConnectorList: KIMConnector[] = [];
        const kimContextList: KIMContext[] = [];
        const kimConnector = new KIMConnector();
        kimConnector.ldapPort = "636";
        kimConnector.isCurrent = true;
        const kimContext = new KIMContext();
        kimContext.isCurrent = true;
        kimConnectorList.push(kimConnector);
        kimContextList.push(kimContext);
        kimGeneral.kimConnectorList = kimConnectorList;
        kimGeneral.kimContextList = kimContextList;
        return kimGeneral;
    }

    handleSignatureDialogOpen = () => {
        this.props.dispatch_setTiConnectorEvent(new TIConnectorEvent());
        this.setState({isSignatureDialogOpen: true});
        this.props.dispatch_registerEHBA();
    }

    handleSignatureDialogClose = () => {
        this.setState({isSignatureDialogOpen: false});
        this.props.dispatch_getKimSettings();
        //this.props.dispatch_setTiConnectorEvent(new TIConnectorEvent());
    }

    renderButtons = () => {
        if (!this.state.isEditKim)
            return (
                <Box className = 'margin-left-auto'>
                    {this.props.initialisedServices.includes(UploadPath.KIM) ?
                        <Tooltip title="Konfiguration der KIM-Erweiterung bearbeiten">
                            <Button
                                className = "slim"
                                variant="contained"
                                color="primary"
                                onClick = {this.handleEditKimClick}
                            >
                                <Edit/>
                            </Button>
                        </Tooltip> :
                        <Tooltip title="KIM-Erweiterung einrichten">
                            <Button
                                className = "slim"
                                variant="contained"
                                color="primary"
                                onClick = {this.handleEditKimClick}
                            >
                                <Add/>
                            </Button>
                        </Tooltip>
                    }
                </Box>
            )
    }

    render() {
        return (
            <Box className="bsnr-info-container">
                <Box className="hpm-configuration">
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                        KIM Konfiguration
                    </Typography>
                    {this.renderButtons()}
                </Box>


                {(this.state.isEditKim) &&
                    <EditSettings
                        handleCancelClick = {this.handleCancelKimClick}
                        handleSaveClick = {this.handleSaveKimClick}
                        kimGeneral = {this.props.kimGeneral}
                        kimUser = {this.props.kimUser}
                        dispatch_uploadCertificates = {this.props.dispatch_uploadCertificates}
                        dispatch_sendKimSettings = {this.props.dispatch_sendKimSettings}
                        dispatch_registerEHBA = {this.props.dispatch_registerEHBA}
                        dispatch_setTiConnectorEvent = {this.props.dispatch_setTiConnectorEvent}
                        tiConnectorEvent = {this.props.tiConnectorEvent}
                        dispatch_getKimSettings = {this.props.dispatch_getKimSettings}
                        kimSettingsError = {this.props.kimSettingsError}
                        isSignatureDialogOpen = {this.state.isSignatureDialogOpen}
                        handleSignatureDialogOpen = {this.handleSignatureDialogOpen}
                        handleSignatureDialogClose = {this.handleSignatureDialogClose}
                        isTiConnected = {this.props.isTiConnected}
                        dispatch_getTiConnectionStatus = {this.props.dispatch_getTiConnectionStatus}
                        isTiStatusLoading = {this.props.isTiStatusLoading}
                        dispatch_getKimMailInLdapStatus = {this.props.dispatch_getKimMailInLdapStatus}
                        isKimMailInLdap = {this.props.isKimMailInLdap}
                        isLdapRequest = {this.props.isLdapRequest}
                        isTestTiConnectionClicked = {this.props.isTestTiConnectionClicked}
                        dispatch_setIsTestTiConnectionClicked = {this.props.dispatch_setIsTestTiConnectionClicked}
                        user = {this.props.user}
                    />
                }
                {/* {this.state.isViewKim && !this.state.isEditKim &&
                    <ViewSettings
                        kimGeneral = {this.props.kimGeneral}
                        kimUser = {this.props.kimUser}
                    />
                }     */}
                {!this.state.isEditKim && !this.props.initialisedServices.includes("KIM") &&
                    <Paper className="initialisation-content initialisation-overview hpm-config popup-insert pad-bottom" elevation={0}>
                        <Box className = "flex-row-end">
                            <Typography className="bsnr-info" variant="caption" color="inherit">
                                KIM-Modul ist nicht konfiguriert
                            </Typography>
                            <HighlightOff className = 'out-of-date'/>
                        </Box>
                    </Paper>
                }
                {!this.state.isEditKim && this.props.initialisedServices.includes("KIM") &&
                    <Paper className="initialisation-content initialisation-overview hpm-config popup-insert pad-bottom" elevation={0}>
                        <Box className = "flex-row-end">
                            <Typography className="bsnr-info" variant="caption" color="inherit">
                                KIM-Modul ist vollständig konfiguriert
                            </Typography>
                            <CheckCircle className = 'up-to-date margin-left-auto margin-right'/>
                        </Box>

                    </Paper>
                 }
            </Box>

        );
    }
}

export const KimConfigurationContainer = connector(KimConfiguration);
export default KimConfigurationContainer;
