export enum EPdfActions {
    SET_PDF_STRING = "SET_PDF_STRING",
    CLEAR_PDF_STRING = "CLEAR_PDF_STRING",
    SET_IS_PDF_LOADING = "SET_IS_PDF_LOADING",
}

export interface ISetPdfStringAction {
    readonly type: EPdfActions.SET_PDF_STRING;
    readonly pdfString: string; 
}

export interface IClearPdfStringAction {
    readonly type: EPdfActions.CLEAR_PDF_STRING;
}

export interface ISetIsPdfLoading {
    readonly type: EPdfActions.SET_IS_PDF_LOADING;
    readonly isPdfLoading: boolean;
}

export type TPdfActionTypes = 
    ISetPdfStringAction |
    IClearPdfStringAction |
    ISetIsPdfLoading;