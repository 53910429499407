import { EhbaEventType, Failure, KonsilMetaInformation, WebSocketService } from "telescan-core";
import {ThunkAction} from "redux-thunk";
import {RootState} from "../reducers";
import {Action} from "redux";
import uuid from "uuid";
import { failure, hpmStatusEventListenerCallback, loadFailure } from "./general_actions";
import * as WebSocketTypes from '../types/websocket_types';
import { loadArztKonsile, loadKonsil, removeKonsil, setIsKonsilDeleted, setIsKonsilUpdateConflict, setIsKonsilUpdatedEventShown, setKonsilUpdateConflictData } from "./konsil_actions";
import { getImageMetadata, handleRemoveKonsilImage, loadKonsilImage } from "./image_attachment_actions";
import { handleGlobalUpdateEvent, handleUpdatingEvent } from "./update_actions";
import { handleDownloadKonsilEvent, handleUploadKonsilEvent } from "./upload_download_konsil_actions";
import { getIcd10Info, handleDeleteIcd10, handleInstallIcd10 } from "./icd10_actions";
import { checkLocationStatus } from ".";
import { getCurrentRiskAndWarningNotifications } from "./notification_actions";
import { removeKimMetadatenKonsil, setIsDataLoading, setKimDeleteMailProgress, setKimMetadaten, setTiConnectorEvent } from "./kim_actions";
import { getAllGdtPatients } from "./gdt_actions";

export function setWebSocketClientId(id: string): WebSocketTypes.ISetClientId {
    return {
        type: WebSocketTypes.EWebSocketActions.SET_CLIENT_ID,
        clientId: id
    }
}

export function connectWebSocketService(url: string, accessToken: string, webSocketService: WebSocketService): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const clientId: string = uuid.v4();
        dispatch(setWebSocketClientId(clientId));
        const failureId: string = uuid.v4();
        if (webSocketService === undefined) {
            const failureObj = new Failure();
            failureObj.error = "WebSocketService ist nicht gesetzt!";
            dispatch(loadFailure(failureId, failureObj))
            return;
        }
        if (url && url !== "" && accessToken && accessToken !== "") {
            webSocketService = WebSocketService.getInstance();
            webSocketService.setErrorCallback((error) => console.error("A websocket error occurred: ", error));
            webSocketService.connect(url, getState().webSocket.clientId, accessToken);
            // 1. General listeners
            webSocketService.addListener(
                "clearMessageQueue",
                (data) => data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString())
            );
            // 2. Update listeners
            webSocketService.addListener(
                "updateEvent",
                (data) => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    dispatch(handleGlobalUpdateEvent(data.data));
                }
            )
            webSocketService.addListener(
                "teleScanUpdateEvent",
                (data) => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    dispatch(handleUpdatingEvent(data.data, getState().update.module))
                }
            )
            webSocketService.addListener(
                "hpmUpdateEvent",
                (data) => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    dispatch(handleUpdatingEvent(data.data, getState().update.module))
                }
            )
            //3. Konsile send/download and update listeners
            webSocketService.addListener(
                "telekonsilUploadEvent",
                (data) => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    dispatch(handleUploadKonsilEvent(data.data, getState().send_download_konsil.eventText))
                }
            )
            webSocketService.addListener(
                "telekonsilDownloadEvent",
                (data) => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    dispatch(handleDownloadKonsilEvent(data.data))
                }
            )
            webSocketService.addListener(
                "konsilUpdatedEvent",
                (data) => {

                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    if (data.data.konsilId === getState().konsile.current_konsil.id && getState().router.location.pathname === `/konsil/${getState().konsile.current_konsil.id}`) {
                        dispatch(setIsKonsilUpdatedEventShown(true));
                    }

                }
            )
            webSocketService.addListener(
                "konsilUpdateConflictEvent",
                (data) => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    if (data.data.konsilId === getState().konsile.current_konsil.id) {
                        dispatch(setIsKonsilUpdateConflict(true));
                        dispatch(setIsKonsilUpdatedEventShown(false));
                        dispatch(setKonsilUpdateConflictData(data.data.changes));
                    }
                }
            )
            //4. Image manipulation listeners
            webSocketService.addListener(
                "ImageDeletedEvent",
                (data) => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    if (data.data.konsilId === getState().konsile.current_konsil.id && getState().router.location.pathname === `/konsil/${getState().konsile.current_konsil.id}`) {
                        dispatch(handleRemoveKonsilImage(data.data.imageId))
                        .then(() => {
                            dispatch(checkLocationStatus(getState().konsile.current_konsil, getState().image_attachment.konsilImages));
                        })
                        .catch(error => console.log(error));
                    }
                }
            );
            webSocketService.addListener(
                "ImageUploadEvent",
                (data) => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString())
                    if (data.data.konsilId === getState().konsile.current_konsil.id && getState().router.location.pathname === `/konsil/${getState().konsile.current_konsil.id}`) {
                        dispatch(getImageMetadata(data.data.imageId))
                        .then(() => {
                            if (getState().konsile.current_konsil.id)
                                dispatch(loadKonsilImage(data.data.imageId, getState().konsile.current_konsil.id || ""));
                        })
                    }
                }
            );
            //5. ICD10 install/delete listeners
            webSocketService.addListener(
                "icd10InstallEvent",
                (data) => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    if (getState().webSocket.clientId !== data.data.initiator) {
                        dispatch(getIcd10Info())
                        .then(() => dispatch(handleInstallIcd10(data.data)))
                        .catch(error => console.log(error));
                    } else
                        dispatch(handleInstallIcd10(data.data));
                }
            );
            webSocketService.addListener(
                "icd10DeleteEvent",
                (data) => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    if (getState().webSocket.clientId !== data.data.initiator) {
                        dispatch(getIcd10Info())
                        .then(() => dispatch(handleDeleteIcd10(data.data)))
                        .catch(error => console.log(error));
                    } else
                        dispatch(handleDeleteIcd10(data.data));
                }
            );
            //6. Create/delete konsil listeners
            webSocketService.addListener(
                "konsilCreatedEvent",
                (data) => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    if (getState().router.location.pathname === "/")
                        dispatch(loadArztKonsile());
                }
            );
            webSocketService.addListener(
                "konsilDeletedEvent",
                data => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    if (getState().router.location.pathname === "/")
                        data.data.konsilId && dispatch(removeKonsil(data.data.konsilId))
                    if (data.data.konsilId && (getState().konsile.current_konsil.id === data.data.konsilId)) {
                        dispatch(setIsKonsilDeleted(true));
                    }

                }
            );
            //7. Start/Stop/Restart HPM listeners
            webSocketService.addListener(
                "hpmStatusEvent",
                data => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    dispatch(hpmStatusEventListenerCallback(data.data.status));
                }
            )

            //8. Telescan notifications
            webSocketService.addListener(
                "notificationEvent",
                data => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    if (getState().user.initialisationStatus.abgeschlossen)
                        dispatch(getCurrentRiskAndWarningNotifications());
                }
            )

            //9. New KIM metadata notifications
            webSocketService.addListener(
                "metadataEvent",
                data => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    dispatch(setIsDataLoading(false));
                    if (getState().user.role === "DERMATOLOGE") {
                        const metadata: KonsilMetaInformation[] = [...getState().kim.kimMetadaten];
                        metadata.push(data.data.metadaten);
                        const uniqueMetadata = metadata.filter((value, index, self) =>
                            index === self.findIndex((t) => (
                                t.dokumentIdentifikator === value.dokumentIdentifikator
                            ))
                        )
                        dispatch(setKimMetadaten(uniqueMetadata));
                    }

                }
            )

            //10. eHBA card events
            webSocketService.addListener(
                "cardEvent",
                data => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    if (getState().konsile.current_konsil.id === data.data.konsilId || getState().router.location.pathname.includes("settings") || getState().router.location.pathname.includes("initialisation")) {
                        dispatch(setTiConnectorEvent(data.data));
                    }
                }
            )

            webSocketService.addListener(
                "konnektorEvent",
                data => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    if (data.data.konsilId === getState().konsile.current_konsil.id || getState().router.location.pathname.includes("settings") || getState().router.location.pathname.includes("initialisation")) {
                        dispatch(setTiConnectorEvent(data.data));
                    }
                    if (data.data.eventTopic === EhbaEventType.POP3_EXCEPTION) {
                        const failureObj = new Failure();
                        failureObj.error = data.data.description;
                        dispatch(failure(uuid.v4(), failureObj));
                    }
                }
            )

            //11. GDT events
            webSocketService.addListener(
                "gdtImportEvent",
                data => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    if (!getState().gdt.gdtPatients.includes(data.data.id) && (getState().user.role === "HAUSARZT" || getState().user.role === "BETRIEBSSTAETTE_HAUSARZT")) {
                        dispatch(getAllGdtPatients());
                    }
                }
            )

            webSocketService.addListener(
                "gdtImportErrorEvent",
                data => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    if (getState().user.role === "HAUSARZT" || getState().user.role === "BETRIEBSSTAETTE_HAUSARZT") {
                        const failureObj: Failure = new Failure();
                        failureObj.error = data.data.errorMessage;
                        dispatch(failure(uuid.v4(), failureObj));
                    }
                }
            )

            //12. Cancel overdue KIM konsil
            webSocketService.addListener(
                "kimKonsilDeletedEvent",
                data => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    if (getState().router.location.pathname === "/")
                        dispatch(loadArztKonsile())
                    if (data.data.konsilId && (getState().konsile.current_konsil.id === data.data.konsilId)) {
                        dispatch(loadKonsil(data.data.konsilId))
                    }

                }
            );

             //12. Delete overdue KIM metadata which was not downloaded by Dermatologist
             webSocketService.addListener(
                "kimMetadataDeletedEvent",
                data => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    dispatch(removeKimMetadatenKonsil(data.data.konsilId))
                }
            );

            //13. Finish checking KIM-inbox
            webSocketService.addListener(
                "kimInboxCheckFinishedEvent",
                data => {
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    dispatch(setIsDataLoading(false));
                }
            );
            //14. Update progress on clearing KIM-inbox
            webSocketService.addListener(
                "KimMailDeletedEvent",
                data => {
                    console.log(data)
                    data.msgNr && webSocketService.sendAcknowledgement(data.msgNr.toString());
                    dispatch(setKimDeleteMailProgress({
                        totalMessages: data.data.totalMailCount,
                        currentMessage: data.data.currentMail
                    }));
                }
            );

        } else {
            const failureObj = new Failure();
            failureObj.error = "url oder accessToken leer, kann den WebSocket nicht verbinden!";
            dispatch(loadFailure(failureId, failureObj));
        }
    }
}



