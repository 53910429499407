import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {
    HeaderContainer,
    KonsilComponentContainer,
    KonsilOverviewContainer,
    LoginContainer,
    UserSettingsContainer,
    QuartalBillsTableContainer,
    SingleQuartalBillTableContainer,
    ImprintContainer,
    DataprotectionContainer,
    HelpContainer

} from '../components';

import {connect, ConnectedProps} from 'react-redux';
import {RootState} from '../redux/reducers';
import {screenSizeChanged} from '../redux/actions';
import {TelescanConfig, WebSocketService, PatientNumber, TestVersion} from "telescan-core";
import PrivateRouteComponent from './login/private_route';
import InitialisationContainer from './initalisation/initialisation';
import WelcomeContainer from './initalisation/welcome';
import Error500Container from './error/error';
import UpdatePopupComponent from './error/update_popup';
import TestHinweis from './elements/test_hinweis';

const mapStateToProps = (state: RootState) => ({
    roles: state.user.role,
    location: state.router.location.pathname,
    queryString: state.router.location.search,
    updateRunning: state.update.updateRunning,
})

const mapDispatchToProps = {
    dispatch_screenSizeChange: (screensize: number) => screenSizeChanged(screensize)
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class Shell extends React.Component<TPropsFromRedux> {
    private devMode: Boolean;

    constructor(props: TPropsFromRedux) {
        super(props);
        this.updatePredicate = this.updatePredicate.bind(this);

        this.devMode = process.env.NODE_ENV !== "production";

        const telescanConfig: TelescanConfig = TelescanConfig.getInstance();
        const port: String = (this.devMode) ? "8767" : (window?.location?.port ?? "8765");
        if (this.devMode)
            console.log("%cTeleScan Development Mode", "color: red; font-size: x-large; font-weight: bold");

        telescanConfig.setConstant("SERVER_API_URL", `${window?.location?.protocol ?? 'http:'}//${window?.location?.hostname ?? 'localhost'}:${port}/telescan/`);

        // initialize WebSocketService
        const webSocketService = WebSocketService.getInstance();
        webSocketService.clearListeners();

        const token = this.parseRefreshToken(this.props.queryString)
        const patientNumber = this.parsePatientNumber(this.props.queryString);
        if (token)
            telescanConfig.setConstant('REFRESH_TOKEN', token);
        if (patientNumber) {
            const pn: PatientNumber = PatientNumber.getInstance();
            pn.setConstant("PATIENT_NUMBER", patientNumber)
        }
    }
    parsePatientNumber(queryString: string) {
        let patientNumber: string | undefined;
        if (queryString.indexOf("?pn=") !== -1) {
            const startIndex: number = queryString.indexOf("?pn=") + 4;
            const stopIndex: number = queryString.indexOf("&refresh_token");
            patientNumber = queryString.substring(startIndex, stopIndex);
            return patientNumber;
        }
        else {
            return patientNumber = undefined;
        }
    }
    parseRefreshToken(queryString: string) {
        let token: string | undefined;
        if (queryString.indexOf("refresh_token=") !== -1) {
            const startIndex: number = queryString.indexOf("refresh_token=") + 14;
            const nextCharIndex: number = Math
                .min
                .apply(Math, [":", "/", "?", "#", "[", "]", "@", "!", "$", "&", "'", "(", ")", "*", "+", ",", ";", "="]
                    .map(reservedChar => {
                        let index: number;
                        index = (queryString.indexOf(reservedChar, startIndex) !== -1) ? queryString.indexOf(reservedChar, startIndex) : Infinity;
                        return (index)
                    }));
            token = (nextCharIndex !== -1) ? queryString.substring(startIndex, nextCharIndex) : queryString.substring(startIndex);
            return token;
        } else {
            return token = undefined // no refresh token send
        }
    }

    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate);
    }

    updatePredicate() {
        this.props.dispatch_screenSizeChange(window.innerWidth);
    }

    render() {
        return (
            <>
                {new TestVersion().getConstant() &&
                    <TestHinweis/>
                }
                {this.devMode && <div className="ribbon"><span>Development</span></div>}
                {(this.props.location !== '/login' && this.props.location !== '/welcome' && this.props.location !== '/error') &&
                <HeaderContainer/>}
                {(this.props.updateRunning) && <UpdatePopupComponent/>}
                <Switch>
                    {/* <RefreshRouteComponent exact path="/error">
                        <Error500Container/>
                    </RefreshRouteComponent> */}
                    <Route exact path="/error">
                        <Error500Container/>
                    </Route>
                    <Route exact path="/welcome">
                        <WelcomeContainer/>
                    </Route>
                    <Route exact path="/login">
                        <LoginContainer/>
                    </Route>
                    <Route exact path="/hilfe">
                        <HelpContainer/>
                    </Route>
                    <Route exact path="/impressum">
                        <ImprintContainer/>
                    </Route>
                    <Route exact path="/datenschutz">
                        <DataprotectionContainer/>
                    </Route>
                    <PrivateRouteComponent exact path="/initialisation">
                        <InitialisationContainer/>
                    </PrivateRouteComponent>
                    <PrivateRouteComponent exact path="/">
                        <KonsilOverviewContainer/>
                    </PrivateRouteComponent>
                    <PrivateRouteComponent
                        path="/billing/:id"
                        component={(props: any) => {
                        return (
                            <SingleQuartalBillTableContainer {...props} />
                        )
                        }}
                    />
                    <PrivateRouteComponent
                        path="/konsil/:id"
                        component={(props: any) => {
                        return (
                            <KonsilComponentContainer {...props} />
                        )
                        }}
                    />
                    <PrivateRouteComponent path="/billing">
                        <QuartalBillsTableContainer/>
                    </PrivateRouteComponent>
                    <PrivateRouteComponent path="/settings">
                        <UserSettingsContainer/>
                    </PrivateRouteComponent>
                    <PrivateRouteComponent path="*">
                        <KonsilOverviewContainer/>
                    </PrivateRouteComponent>
                    {/* <Route exact path="/">
                        <KonsilUebersichtContainer/>
                    </Route>
                    <Route path="/konsil/:id" component={(props: any) => <KonsilComponentContainer {...props} />}/>
                    <Route path="/settings">
                        <UserSettingsContainer/>
                    </Route> */}
                </Switch>
            </>
        );
    }
}

export const ShellContainer = connector(Shell);
export default ShellContainer;

