import React from "react";
import {connect, ConnectedProps} from 'react-redux';

import { Typography, TextField } from "@material-ui/core";
import { RootState } from "../../redux/reducers";
import { PasswordStrengthComponent } from "../initalisation/password_strength";
import { failure } from "../../redux/actions";
import { withTypingTimeout, WithTypingTimeoutsProps } from "../../utils/with_typing_timeout";
import { Failure } from 'telescan-core';

interface IProps {
    setStrength: (strength: "weak" | "good" | "strong") => void;
    newPassword: string;
    newRepeatedPassword: string;
    handleNewPasswordChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => string;
    handleNewRepeatedPasswordChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    context: "LOGIN" | "ZERTIFIKAT";  
    variant?: "filled";
}

interface IState {
    evalPassword: string;
}


const mapStateToProps = (state: RootState, ownProps: IProps) => ({
    ...ownProps
})

const mapDispatchToProps = {
    dispatch_failure: (id: string, error: Failure) => failure(id, error),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>
type Props = TPropsFromRedux & WithTypingTimeoutsProps

export class NewPassword extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            evalPassword: "",
        };

        this.timeHandleNewPasswordChange = this.timeHandleNewPasswordChange.bind(this);
        this.sendPassword = this.sendPassword.bind(this);
    }


    timeHandleNewPasswordChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
        this.props.timeOutMethod(e, this.props.handleNewPasswordChange, this.sendPassword, 1000)
    }

    sendPassword(password: string): void {
        this.setState({
            evalPassword: password
        })
    }

    
    render() {
        return (
            <>
            {this.props.context === "ZERTIFIKAT" &&
            <Typography variant="caption" color="inherit">
                Bitte vergeben Sie ein neues Passwort für Ihr Arzt-Zertifikat.
            </Typography>}
            <TextField
                name = "newPasswordInSettings"
                id="new-pw-textarea"
                placeholder="Neues Passwort"
                value={this.props.newPassword}
                onChange={this.timeHandleNewPasswordChange}
                type="password"
                autoComplete="new-password"
                variant={this.props.variant}
            />
            <TextField
                name = "newPasswordInSettingsRepeat"
                id="new-repeated-pw-textarea"
                placeholder="Neues Passwort wiederholen"
                value={this.props.newRepeatedPassword}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => this.props.handleNewRepeatedPasswordChange(event)}
                type="password"
                autoComplete="new-password"
                variant={this.props.variant}
            />
            {this.props.context === "LOGIN" &&
            <Typography variant="caption" color="inherit">
                Ein starkes Passwort besteht aus 8 verschiedenen(!) Zeichen. 
                Darin enthalten sind mindestens eine Zahl, ein Sonderzeichen, sowie ein Groß- und Kleinbuchstabe.
            </Typography>
            }
            {this.props.context === "ZERTIFIKAT" &&
            <Typography variant="caption" color="inherit">
                Ein gültiges Passwort besteht aus mindestens 4 Zeichen (Ziffern und Sonderzeichen sind erlaubt).
            </Typography>}
            <PasswordStrengthComponent setStrength={this.props.setStrength} context={this.props.context}
                password={this.state.evalPassword} dispatch_failure={this.props.dispatch_failure}/>
            </>
        )
    }
}

export const NewPasswordContainer = connector(withTypingTimeout(NewPassword));
export default NewPasswordContainer;