import { GdtConfiguration, Patient } from "telescan-core";
import { EGdtActions, TGdtActionTypes } from "../types/gdt_types";

export interface IGdtState {
    gdtConfiguration: GdtConfiguration,
    gdtPatients: Patient[],
}

const initialState: IGdtState = {
    gdtConfiguration: new GdtConfiguration(),
    gdtPatients: []
}

export function gdt_reducer(state = initialState, action: TGdtActionTypes): IGdtState {
    switch (action.type) {
        case EGdtActions.SET_GDT_CONFIGURATION:
            return {
                ...state,
                gdtConfiguration: action.gdtConfig,
            }
        
        case EGdtActions.SET_GDT_PATIENTS:
            return {
                ...state,
                gdtPatients: action.gdtPatients
            }

        default:
            return state
    }
}


