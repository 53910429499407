import React from 'react';
import uuid from 'uuid';
import { Konsil, Failure } from 'telescan-core';


const getImageDimensions = async (src: string): Promise<number[]> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = function() {
            resolve([img.width, img.height])
        }
        img.src = src;
    })
}


export interface WithFileUploadProps {
    onInputChanged: (event: any, uploadMethod: (file: File, id: string, ...uploadArgs: any) => void, dimensions: number[], ...uploadArgs: any[]) => void;
    uploadFile: (file: File, uploadMethod: (file: File, id: string, ...uploadArgs: any) => void, dimensions: number[], ...uploadArgs: any[]) => void;
    onDragEnter: (event) => void;
    onDragOver: (event) => void;
    onDragLeave: (event) => void;
    onDrop: (event, uploadMethod: (file: File, id: string, ...uploadArgs: any) => void, dimensions: number[], ...uploadArgs: any[]) => void;
    isActiveDrag: boolean;
}

export function withFileUpload(Component) {
    type Props = {
        dispatch_failure: (id: string, failure: Failure) => void;
        currentKonsil: Konsil;
    }
    type State = {
        isActiveDrag: boolean;
    }

    // ...and returns another component...
    return class extends React.Component<Props, State> {
        constructor(props: Props) {
            super(props);
            this.state = {
                isActiveDrag: false,
            };

            this.onDragEnter = this.onDragEnter.bind(this);
            this.onDragOver = this.onDragOver.bind(this);
            this.onDragLeave = this.onDragLeave.bind(this);
            this.onDrop = this.onDrop.bind(this);
            this.onInputChanged = this.onInputChanged.bind(this);
            this.uploadFile = this.uploadFile.bind(this);
            this.checkImageDimensions = this.checkImageDimensions.bind(this);
        }

        private onInputChanged(event, uploadMethod: (file: File, id: string, ...uploadArgs: any) => void, dimensions: number[], ...uploadArgs: any) {//: React.ChangeEvent<HTMLInputElement>) {
            event.preventDefault();
            event.stopPropagation();
            
            if (event.type === "drop"){
            console.log("Files: ", event.dataTransfer);
            ([...event.dataTransfer.files]).forEach((file: File) => this.uploadFile(file, uploadMethod, dimensions, ...uploadArgs));
            } else {
                console.log("Files: ", event, event.target, event.target.files);
                ([...event.target.files]).forEach((file: File) => this.uploadFile(file, uploadMethod, dimensions, ...uploadArgs));
            }
        }
    
        private uploadFile(file: File, uploadMethod: (file: File, id: string, ...uploadArgs: any) => void, dimensions: number[], ...uploadArgs: any ) {
            const loadInstanceId: string = uuid.v4();
            let failure = new Failure();
            if (file?.name && file?.type) console.log("Uploading " + file.name + ": ", file.type);
            if (file.type === "image/png" || file.type === "image/jpeg"){
                this.checkImageDimensions(file).then(([width, height]) => {
                    if ((width >= dimensions[0] && height >= dimensions[1] && width <= dimensions[2] && height <= dimensions[3]) || (width >= dimensions[1] && height >= dimensions[0] && width <= dimensions[2] && height <= dimensions[3]))
                        uploadMethod(file, loadInstanceId, ...uploadArgs)
                    else if (width > dimensions[2] || height > dimensions[3]) {
                        failure.error = "Das Bild hat eine zu hohe Auflösung! Bitte wählen Sie ein kleineres Bild.";
                        this.props.dispatch_failure(loadInstanceId, failure);
                    }
                    else {
                        failure.error = "Das Bild hat eine zu geringe Auflösung! Bitte wählen Sie ein größeres Bild.";
                        this.props.dispatch_failure(loadInstanceId, failure);
                    }
                }).catch( error => 
                    this.props.dispatch_failure(loadInstanceId, error)
                )
            } else if (file.type === "application/pdf")
                uploadMethod(file, loadInstanceId, ...uploadArgs)
            else {
                failure.error = "Ungültiger Dateityp! Nur PNG und JPG Bilder, sowie PDFs können angehängt werden.";
                this.props.dispatch_failure(loadInstanceId, failure);
            }
        }

        private checkImageDimensions(file: File){
            return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = function() { // file is loaded
                if (typeof fileReader.result === "string") {
                    getImageDimensions(fileReader.result).then(
                        ([width, height]) => {
                            resolve([width, height])
                        }
                    ).catch(error =>
                        reject("Die Datei konnte nicht gelesen werden. Bitte versuchen Sie es erneut oder verwenden Sie ein anderes Bild.")
                    )
                } else
                    reject("Die Datei konnte nicht gelesen werden. Bitte versuchen Sie es erneut oder verwenden Sie ein anderes Bild.")
            };
            fileReader.readAsDataURL(file);
            })
        }

        private onDragEnter(event) {
            this.setState({isActiveDrag: true});
            event.preventDefault();
            event.stopPropagation();
            event.dataTransfer.dropEffect = 'copy';
        }
    
        private onDragOver(event) {
            event.preventDefault();
            event.stopPropagation();
        }
    
        private onDragLeave(event) {
            this.setState({isActiveDrag: false});
            event.preventDefault();
            event.stopPropagation();
        }
    
        private onDrop(event, uploadMethod: (file: File, id: string, ...uploadArgs: any) => void, dimensions: number[], ...uploadArgs: any) {
            event.preventDefault();
            event.stopPropagation();
            this.setState({isActiveDrag: false});
            this.onInputChanged(event, uploadMethod, dimensions, ...uploadArgs)
        }

        render() {
            return (
                <Component 
                    onInputChanged={this.onInputChanged}
                    uploadFile={this.uploadFile}
                    onDragEnter = {this.onDragEnter}
                    onDragOver = {this.onDragOver}
                    onDragLeave = {this.onDragLeave}
                    onDrop = {this.onDrop}
                    isActiveDrag={this.state.isActiveDrag}
                    {...(this.props as any)}
                />
            )
        }
    };
}
