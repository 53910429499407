import React from "react";
import {connect, ConnectedProps} from 'react-redux';

import { Box, TextField, Button, Tooltip, Typography, CircularProgress } from "@material-ui/core";
import { RootState } from "../../redux/reducers";
import { failure, getIsHpmAvailableStatus, setIsHpmConfigChanged } from "../../redux/actions";
import uuid from 'uuid';
import { HPMService, HPMKonfiguration, Failure } from "telescan-core";
import { ViewText } from "../konsil";
import { CheckCircle, HighlightOff } from "@material-ui/icons";

interface IProps {
    isEdit: boolean;
    // isInitialisation: boolean;
    handleDoneClick: (hpmConfig?: HPMKonfiguration) => void;
}

interface IState {
    hpmConfig: HPMKonfiguration;
    prevHpmConfig: HPMKonfiguration;
}

const mapStateToProps = (state: RootState, ownProps: IProps) => ({
    initialisationStatus: state.user.initialisationStatus,
    hpmServiceStatus: state.general.hpmServiceStatus,
    ...ownProps
})

const mapDispatchToProps = {
    dispatch_failure: (id: string, error: Failure) => failure(id, error),
    dispatch_getIsHpmAvailableStatus: () => getIsHpmAvailableStatus(),
    setIsHpmConfigChanged,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class HPMConfig extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            hpmConfig: new HPMKonfiguration(),
            prevHpmConfig: new HPMKonfiguration(),
        };

        this.getHPMConfig = this.getHPMConfig.bind(this);
        this.updateHPMConfig = this.updateHPMConfig.bind(this);
        this.abortHPMConfig = this.abortHPMConfig.bind(this);
    }

    componentDidMount() {
        this.getHPMConfig();
        this.props.dispatch_getIsHpmAvailableStatus();
    }

    getHPMConfig() {
        const loadInstanceIdArzt = uuid.v4();
        const hpmService = new HPMService();
        hpmService.getHpmKonfiguration().then((hpmConfig) => {
            this.setState({
                hpmConfig: hpmConfig,
                prevHpmConfig: hpmConfig
            })
        }).catch((error) => {
            this.props.dispatch_failure(loadInstanceIdArzt, error);
        })
    }

    updateHPMConfig(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        this.props.setIsHpmConfigChanged(true);
        const hpmConfig: HPMKonfiguration = this.state.hpmConfig;
        const loadInstanceIdArzt = uuid.v4();
        const hpmService = new HPMService();
        hpmService.updateHpmKonfiguration(hpmConfig).then(() => {
            this.props.setIsHpmConfigChanged(false);
            this.props.handleDoneClick(this.state.hpmConfig);
        }).catch((error) => {
            this.props.setIsHpmConfigChanged(false);
            this.props.dispatch_failure(loadInstanceIdArzt, error);
        })
    }

    abortHPMConfig(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        this.setState({
            hpmConfig: this.state.prevHpmConfig
        });
        this.props.handleDoneClick(this.state.prevHpmConfig);
    }

    render() {
        if (this.props.isEdit)
            return (
                <>
                    <Box className="bs-infos-card" >
                        <Box className="bs-left">
                            <TextField
                                id="hpm-protocol"
                                label="Protokoll"
                                placeholder="Protokoll"
                                value={(this.state.hpmConfig.protokoll != null)? this.state.hpmConfig.protokoll : "http"}
                                onChange={(event) => {
                                    let updatedHpmConfig = {
                                        ...this.state.hpmConfig,
                                        protokoll: event.target.value,
                                    } as HPMKonfiguration

                                    this.setState({
                                        hpmConfig: updatedHpmConfig
                                    })}}
                                autoComplete="off"
                            />
                            <TextField
                                id="bs-strasseHausnummer"
                                label="Host"
                                placeholder="Host"
                                value={this.state.hpmConfig.host || ""}
                                onChange={(event) => {
                                    let updatedHpmConfig = {
                                        ...this.state.hpmConfig,
                                        host: event.target.value,
                                    } as HPMKonfiguration

                                    this.setState({
                                        hpmConfig: updatedHpmConfig
                                    })}}
                                autoComplete="off"
                            />
                        </Box>
                        <Box className="bs-right">
                            <TextField
                                id="hpm-port"
                                label="Port"value={this.state.hpmConfig.port || ""}
                                onChange={(event) => {
                                    let updatedHpmConfig = {
                                        ...this.state.hpmConfig,
                                        port: parseInt(event.target.value),
                                    } as HPMKonfiguration

                                    this.setState({
                                        hpmConfig: updatedHpmConfig
                                    })}}
                                autoComplete="off"
                            />
                        </Box>
                    </Box>
                    <Button
                        className="slim save-config"
                        variant="text" color="primary"
                        onClick={this.abortHPMConfig}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        className="slim save-config"
                        variant="contained"
                        color="primary"
                        onClick={this.updateHPMConfig}
                    >
                        Speichern
                    </Button>
                </>
            )
        else if (!this.props.isEdit) {
            let hpmServiceStatus: any;
            if (this.props.hpmServiceStatus === "RUNNING") {
                hpmServiceStatus = 
                    <Tooltip title = "In Betrieb">
                        <CheckCircle className = 'up-to-date'/>
                    </Tooltip>       
            }
            else if (this.props.hpmServiceStatus === "NOT_RUNNING") {
                hpmServiceStatus = 
                    <Tooltip title = "Außer Betrieb">
                        <HighlightOff className = 'out-of-date'/>
                    </Tooltip>    
            }
            else if (this.props.hpmServiceStatus === "WAITING_FOR_SERVER_RESPONSE") {
                hpmServiceStatus = 
                    <Tooltip title = "Wird bearbeitet">
                        <CircularProgress size = {25}/>
                    </Tooltip>  
            }
            return (
                <div className="bs-infos-card" >
                    <Box className="bs-left">
                        <ViewText labelVariant='subtitle1'
                                    label='Protokoll'
                                    text={this.state.hpmConfig.protokoll || ""}
                        />
                        <ViewText labelVariant='subtitle1'
                                    label='Host'
                                    text={this.state.hpmConfig.host || ""}
                        />
                    </Box>
                    <Box className="bs-right">
                        <ViewText labelVariant='subtitle1'
                                    label='Port'
                                    text={(this.state.hpmConfig.port)? String(this.state.hpmConfig.port) : ""}
                        />
                        <Box className = 'hpm-status'>
                            <Typography
                                className = "txt-label"
                                variant = 'subtitle1'
                            >
                                Status
                            </Typography>
                            {hpmServiceStatus}
                        </Box>
                        
                    </Box>
                </div>
            )
        }
    }
}

export const HPMKonfigurationComponent = connector(HPMConfig);
export default HPMKonfigurationComponent;