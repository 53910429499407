import React from 'react';
import {connect, ConnectedProps} from 'react-redux';
import uuid from 'uuid';

import { Tooltip, IconButton, Paper, Box, Typography, Button} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { Arzt, BasisdatenService, RoleTypes, Failure, } from 'telescan-core';
import { ADatenComponent } from '../initalisation/daten_aerzte';
import ViewArztDatenComponent from './view_arztdaten';
import { RootState } from '../../redux/reducers';
import { 
    setBetriebsstaettenAerzte, 
    loading, 
    loadSuccess, 
    getBetriebsstaettenAerzte, 
    getBasisdatenArztStatus,
    failure
} from '../../redux/actions';
import SimpleModalContainer from '../elements/simple_modal';


interface InfoState {
    arzt: Arzt;
    isEdit: boolean;
    error: string;
    arztErrors: Map<string, string>;
}

interface InfoProps {
    isOpen: boolean;
    toggleModal: (arzt: Arzt, isAdd?: boolean) => void;
    updateAerzte: (arzt: Arzt) => void;
    arzt: Arzt;
    index: number;
    isAdd: boolean;
}

const mapStateToProps = (state: RootState, ownProps: InfoProps) => ({
    user: state.user.user,
    role: state.user.role,
    basisdatenAerzte: state.user.betriebsstaettenAerzte,
    basisdatenArztErrors: state.user.basisdatenArztErrors,
    ...ownProps
})


const mapDispatchToProps = {
    dispatch_getBetriebsstaettenAerzte: () => getBetriebsstaettenAerzte(),
    dispatch_setBetriebsstaettenAerzte: (aerzte: Arzt[]) => setBetriebsstaettenAerzte(aerzte),
    dispatch_loading: (id: string) => loading(id),
    dispatch_loadSuccess: (id: string) => loadSuccess(id),
    dispatch_failure: (id: string, message: Failure) => failure(id, message),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class ArztInfoModal extends React.Component<TPropsFromRedux, InfoState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            arzt: this.props.arzt,
            isEdit: false,
            error: "",
            arztErrors: new Map<string, string>(),
        };

        this.updateBasisdatenArzt = this.updateBasisdatenArzt.bind(this);
        this.updateArzt = this.updateArzt.bind(this);
        this.checkArztErrors = this.checkArztErrors.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<InfoProps>, prevState: Readonly<InfoState>) {
        if (prevProps.arzt !== this.props.arzt) {
            this.setState({
                arzt: this.props.arzt
            });
        }
    }

    updateBasisdatenArzt(arzt: Arzt) {
        const loadInstanceId = uuid.v4();
        const basisdatenService = new BasisdatenService();
        basisdatenService.setBasisdatenAerzte([arzt]).then(() => {
            this.props.toggleModal(arzt);
            this.props.dispatch_getBetriebsstaettenAerzte();
        }).catch((error) => {
            this.setState({
                error: error
            })
            this.props.dispatch_failure(loadInstanceId, error);

        })
    }

    updateArzt(arzt: Arzt) {
        this.checkArztErrors(this.props.role, arzt);
        if (this.props.isAdd) {
            let arztWithBsnr: Arzt = arzt;
            arztWithBsnr.bsnr = localStorage.getItem("BSNR") || undefined;
            this.setState({
                arzt: arztWithBsnr
            });
        } else {
            this.setState({
                arzt: arzt
            });
            this.props.updateAerzte(arzt);
        }
    }

    checkArztErrors(role: keyof typeof RoleTypes, arzt: Arzt ) {
        let errors = new Map<string, string>();
        errors = getBasisdatenArztStatus(role, arzt, this.props.index, errors);

        this.setState({
            arztErrors: errors,
            error: ""
        })
    }

    render() {
        return (
            <SimpleModalContainer isOpen={this.props.isOpen}>
                <Paper className="modal-wrapper settings-arzt-pop">
                    <h2 className="modal-header flex-row-center space-between">Arzt-Basisdaten 
                        {(!this.props.isAdd && !this.state.isEdit) &&
                        <Tooltip title="Basisdaten bearbeiten" placement="top">
                            <IconButton size="small" aria-label="delete" onClick={() => this.setState({isEdit: true})}>
                                <Edit/>
                            </IconButton>
                        </Tooltip>
                        }
                    </h2>
                    <Box className="modal-content">
                        {(this.state.isEdit || this.state.arzt.id === undefined)?
                        <Box className="initialisation-content popup-insert">
                            <ADatenComponent isEdit={this.props.isAdd} key={this.state.arzt.id} index={this.props.index}
                                basisdatenArzt={this.state.arzt} bsnr={localStorage.getItem("BSNR") || undefined}
                                deleteArzt={undefined} updateAerzte={this.updateArzt}
                                basisdatenArztErrors={(this.props.isAdd)? this.state.arztErrors: this.props.basisdatenArztErrors}
                                role={this.props.role}
                        />
                        </Box>
                        :
                        <ViewArztDatenComponent key={this.state.arzt.id} basisdatenArzt={this.state.arzt} />
                        }
                        {this.state.error !== "" &&
                        <Typography className="failure">{this.state.error}</Typography>
                        }

                        {(this.state.isEdit || this.props.isAdd)?
                            <Typography variant="caption" color="inherit">
                                ! Sind noch keine Basisdaten für diesen Arzt erfasst, so wird der Arzt neu angelegt.
                            </Typography> : ''
                        }
                    </Box>
                    
                    <Box className="modal-footer">
                        {(this.state.isEdit || this.props.isAdd)?
                        <>
                            <Button variant="contained" color="primary"
                                disabled={(this.props.isAdd)? (this.state.arztErrors.size > 0 || this.state.error !== "" || this.state.arzt.lanr === undefined): (this.props.basisdatenArztErrors.size > 0 || this.state.error !== "" || this.state.arzt.lanr === undefined)}
                                onClick={() => {this.setState({isEdit: false}); this.updateBasisdatenArzt(this.state.arzt);}}>
                                Aktualisieren
                            </Button>
                            <Button variant="text" onClick={() => {this.setState({isEdit: false}); this.props.toggleModal(this.state.arzt)}}>
                                Schließen
                            </Button>
                            
                        </>
                        :
                        <Button variant="contained" color="primary"
                            onClick={() => {this.setState({isEdit: false}); this.props.toggleModal(this.state.arzt);}}>
                            OK
                        </Button>
                        }
                    </Box>
                </Paper>
            </SimpleModalContainer>
        );
    }
}

export const ArztInfoModalComponent = connector(ArztInfoModal);
export default ArztInfoModalComponent;
