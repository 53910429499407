import React from "react";
import {connect, ConnectedProps} from "react-redux";
import uuid from 'uuid';

import {
    Box,
    Button,
    Tab,
    Tabs,
} from "@material-ui/core";

import Logo from "../../static/images/logo-telescan.svg";
import {RootState} from "../../redux/reducers";
import { changeKonsilTab, navigate, logout, getBetriebsstaettenAerzte, login, failure, checkKimSettingsBE, setLastEditedTab, getInitialisedServices } from "../../redux/actions";
import {a11yProps} from '../header/header_konsil'
import { BasisdatenService, ArztBasisdaten, Arzt, TelescanConfig, Failure } from 'telescan-core';
import { updateModul } from "../../redux/actions/update_actions";

enum InitialisationTabsDerma {
    PASSWORD = "Passwort",
    BS = "Betriebsstätte",
    AERZTE = "Ärzte",
    HPM = "HÄVG-Prüfmodul",
    CERT = "Zertifikate",
    KIMSETTINGS = "KIM/TI-Einstellungen",
    DIRECTORYSERVICE_GDT = "Verzeichnisdienst / GDT",
    SUMMARY = "Zusammenfassung"
}

enum InitialisationTabsHA {
    PASSWORD = "Passwort",
    BS = "Betriebsstätte",
    AERZTE = "Ärzte",
    HPM = "HÄVG-Prüfmodul",
    CERT = "Zertifikate",
    KIMSETTINGS = "KIM/TI-Einstellungen",
    GDT = "GDT-Einstellungen",
    SUMMARY = "Zusammenfassung"
}

enum InitialisationTabsNoKim {
    PASSWORD = "Passwort",
    BS = "Betriebsstätte",
    AERZTE = "Ärzte",
    HPM = "HÄVG-Prüfmodul",
    CERT = "Zertifikate",
    SUMMARY = "Zusammenfassung"
}

const mapStateToProps = (state: RootState) => ({
    tabIndex: state.konsile.tabIndex,
    basisdatenBetriebsstaette: state.user.betriebsstaette,
    basisdatenAerzte: state.user.betriebsstaettenAerzte,
    basisdatenBSErrors: state.user.basisdatenBSErrors,
    basisdatenArztErrors: state.user.basisdatenArztErrors,
    initialisationStatus: state.user.initialisationStatus,
    einverstandnis: state.user.einverstaendnis,
    zertifikatBetriebsstaetteVorhanden: state.user.zertifikatBetriebsstaetteVorhanden,
    zertifikatArztVorhanden: state.user.zertifikatArztVorhanden,
    role: state.user.role,
    isHpmConfigChanged: state.general.isHpmConfigChanged,
    hpmToInitialise: state.general.hpmToInitialise,
    kimToInitialise: state.general.kimToInitialise,
    kimGeneral: state.kim.kimGeneral,
    kimUser: state.kim.kimUser,
    kimSettingsError: state.evaluation.kimSettingsError,
    dermatologistRegistration: state.kim.dermatologistRegistration,
    IsInitialisationStatusLoading: state.general.isInitialisationStatusLoading,
    isKimEnabled: state.general.isKimEnabled,
    isKimMailInLdap: state.ti_connector.isKimMailInLdap,
    isLdapRequest: state.ti_connector.isLdapRequest,
    isTestTiConnectionClicked: state.kim.isTestTiConnectionClicked,
    isTiConnected: state.kim.isTiConnected,
    isTiStatusLoading: state.kim.isTiStatusLoading,
})

const mapDispatchToProps = {
    dispatch_navigate: (url: string) => navigate(url),
    dispatch_tabChange: (index: number) => changeKonsilTab(index),
    dispatch_logout: () => logout(),
    dispatch_failure: (loadInstanceId: string, error: Failure) => failure(loadInstanceId, error),
    dispatch_getBetriebsstaettenAerzte: () => getBetriebsstaettenAerzte(),
    dispatch_updateModul: (modulName: string) => updateModul(modulName),
    dispatch_login: (bsnr: string, password: string, lanr: string | null) => login(bsnr, password, lanr),
    dispatch_checkKimSettingsBE: () => checkKimSettingsBE(),
    dispatch_setLastEditedTab: (index: number) => setLastEditedTab(index),
    dispatch_getInitialisedServices: () => getInitialisedServices(),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>


class HeaderInitialisation extends React.Component<TPropsFromRedux> {

    updateBasisdatenBS = () => {
        const loadInstanceId: string = uuid.v4();
        const basisdatenService = new BasisdatenService();
        basisdatenService.setBasisdatenBetriebsstaette(this.props.basisdatenBetriebsstaette).then((response) => {
            this.props.dispatch_tabChange(this.props.tabIndex + 1)
        }).catch((error) => {
            this.props.dispatch_tabChange(1);
            this.props.dispatch_failure(loadInstanceId, error);
        })
    }

    updateBasisdatenAerzte = () => {
        const loadInstanceId: string = uuid.v4();
        const basisdatenService = new BasisdatenService();
        let basisdatenAerzteFull: Arzt[] = this.props.basisdatenAerzte.map(arzt => ({...arzt}));
        let basisdatenAerzte: ArztBasisdaten[] = basisdatenAerzteFull.map((arzt) => {arzt.id = undefined; return arzt;})
        basisdatenService.setBasisdatenAerzte(basisdatenAerzte).then((response) => {
            this.props.dispatch_tabChange(this.props.tabIndex + 1)
        }).catch((error) => {
            this.props.dispatch_tabChange(2);
            this.props.dispatch_failure(loadInstanceId, error);
        })
    }

    handleWeiter = () => {
        if (this.props.isKimEnabled) {
            if (this.props.tabIndex === 1) {
                this.props.dispatch_setLastEditedTab(this.props.tabIndex + 1);
                this.updateBasisdatenBS();
            } else if (this.props.tabIndex === 2) {
                this.props.dispatch_setLastEditedTab(this.props.tabIndex + 1);
                this.updateBasisdatenAerzte();
            } else if (this.props.tabIndex === 3) {
                if (!this.props.hpmToInitialise) {
                    this.props.dispatch_tabChange(this.props.tabIndex + 2);
                    this.props.dispatch_setLastEditedTab(this.props.tabIndex + 2);
                } else {
                    this.props.dispatch_tabChange(this.props.tabIndex + 1);
                    this.props.dispatch_setLastEditedTab(this.props.tabIndex + 1);
                }
            } else if (this.props.tabIndex === 5) {          
                if (!this.props.kimToInitialise) {
                    this.props.dispatch_tabChange(this.props.tabIndex + 2);
                    this.props.dispatch_setLastEditedTab(this.props.tabIndex + 2);
                } else {
                    this.props.dispatch_checkKimSettingsBE()
                    .then(() => {
                        this.props.dispatch_getInitialisedServices();
                    });
                    this.props.dispatch_tabChange(this.props.tabIndex + 1);
                    this.props.dispatch_setLastEditedTab(this.props.tabIndex + 1);
                }
            } else {
                this.props.dispatch_tabChange(this.props.tabIndex + 1);
                this.props.dispatch_setLastEditedTab(this.props.tabIndex + 1);
            }
        } else {
            if (this.props.tabIndex === 1) {
                this.props.dispatch_setLastEditedTab(this.props.tabIndex + 1);
                this.updateBasisdatenBS();
            } else if (this.props.tabIndex === 2) {
                this.props.dispatch_setLastEditedTab(this.props.tabIndex + 1);
                this.updateBasisdatenAerzte();
            } else {
                this.props.dispatch_tabChange(this.props.tabIndex + 1);
                this.props.dispatch_setLastEditedTab(this.props.tabIndex + 1);
            }
        }
      
    }

    render() {
        let disabledWeiter: boolean;
        if (this.props.isKimEnabled) {
            if (this.props.tabIndex === 1) {
                disabledWeiter = (this.props.basisdatenBSErrors.size > 0 || this.props.basisdatenBetriebsstaette === null)
            }
            else if (this.props.tabIndex === 2) {
                disabledWeiter = (this.props.basisdatenArztErrors.size > 0 || this.props.basisdatenAerzte?.length === 0);
            }
            else if (this.props.tabIndex === 0){
                disabledWeiter = (this.props.einverstandnis === false || !this.props.initialisationStatus.recoveryCodeErzeugt)
            }
    
            else if (this.props.tabIndex === 3){
                    //disabledWeiter = (!this.props.initialisationStatus.hpmInstalliert || this.props.isHpmConfigChanged)
                    disabledWeiter = (this.props.hpmToInitialise && this.props.isHpmConfigChanged)
            }
    
            else if (this.props.tabIndex === 4){
                if (this.props.initialisationStatus.standalone){
                    disabledWeiter = !this.props.initialisationStatus.betriebsstaetteZertifikatErzeugt || !this.props.initialisationStatus.arztZertifikatErzeugt;
                }
                else
                    disabledWeiter = !this.props.zertifikatBetriebsstaetteVorhanden || !this.props.zertifikatArztVorhanden;
            }   
    
            else if (this.props.tabIndex === 5){
                //disabledWeiter = (!this.props.initialisationStatus.hpmInstalliert || this.props.isHpmConfigChanged)
                disabledWeiter = (this.props.kimToInitialise && (this.props.kimSettingsError || !this.props.isTestTiConnectionClicked || !this.props.isTiConnected || this.props.isTiStatusLoading)) 
                || (!this.props.kimToInitialise && !this.props.hpmToInitialise)
            }
    
            // else if (this.props.tabIndex === 6) {
            //     if ((this.props.role === "DERMATOLOGE" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE") && (!this.props.dermatologistRegistration.active || this.props.dermatologistRegistration.active === "INACTIVE")) {
            //         disabledWeiter = true;
            //     } else
            //         disabledWeiter = false; //isEmptyObject(this.props.dermatologistRegistration)
            // }
    
            else
                disabledWeiter = false
        } else {
            if (this.props.tabIndex === 1) {
                disabledWeiter = (this.props.basisdatenBSErrors.size > 0 || this.props.basisdatenBetriebsstaette === null)
            }
            else if (this.props.tabIndex === 2) {
                disabledWeiter = (this.props.basisdatenArztErrors.size > 0 || this.props.basisdatenAerzte?.length === 0);
            }
            else if (this.props.tabIndex === 0){
                disabledWeiter = (this.props.einverstandnis === false || !this.props.initialisationStatus.recoveryCodeErzeugt)
            }
    
            else if (this.props.tabIndex === 3){
                    disabledWeiter = (!this.props.initialisationStatus.hpmInstalliert || this.props.isHpmConfigChanged)
            }
    
            else if (this.props.tabIndex === 4){
                if (this.props.initialisationStatus.standalone){
                    disabledWeiter = !this.props.initialisationStatus.betriebsstaetteZertifikatErzeugt || !this.props.initialisationStatus.arztZertifikatErzeugt;
                }
                else
                    disabledWeiter = !this.props.zertifikatBetriebsstaetteVorhanden || !this.props.zertifikatArztVorhanden;
            }     
            else
                disabledWeiter = false
        }



        let tabsArray: any;
        if (!this.props.isKimEnabled) {
            tabsArray = Object.values(InitialisationTabsNoKim);
        } else {
            if (this.props.role === "DERMATOLOGE" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE") {
                tabsArray = Object.values(InitialisationTabsDerma);
            } else {
                tabsArray = Object.values(InitialisationTabsHA);
            }
        }
        return (
            <>
            <header className="header-initialisation">
                <Button className="grid-button back-btn left narrow" onClick={this.props.dispatch_logout}
                >
                    Abbrechen
                </Button>
                <img src={Logo} alt="Logo" className="logo"/>
                <h1 className="title">Ersteinrichtung</h1>
                <Tabs
                    value={this.props.tabIndex}
                    className="initialisation-tabs"
                    onChange={(_, index) => {this.props.dispatch_tabChange(index)}}
                    variant="scrollable"
                    scrollButtons="off"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable force tabs example"
                >
                    {tabsArray.map((titleItem, index) => 
                        <Tab value={index}
                            key={titleItem}
                            disabled={this.props.tabIndex <= index || this.props.IsInitialisationStatusLoading || (this.props.isKimEnabled && index === 4 && !this.props.hpmToInitialise) || (this.props.isKimEnabled && index === 6 && !this.props.kimToInitialise) }
                            label={
                                <Box className="tab-label">
                                <Box className="tab-number">{index+1}</Box> <span> {titleItem}</span>
                                </Box>}
                            {...a11yProps(index)}
                        />
                    )}
                </Tabs>
                {(this.props.tabIndex < tabsArray.length - 1) &&
                <Button variant="contained" color="primary" size="small" className="btn-right"
                        disabled={disabledWeiter}
                        onClick={this.handleWeiter}>
                    Weiter
                </Button>
                }
                {this.props.tabIndex === tabsArray.length - 1 &&
                <Button variant="contained" color="primary" size="small" className="btn-right"
                        disabled={!this.props.initialisationStatus.abgeschlossen}
                        onClick={() => {
                            const lanr = localStorage.getItem("LANR");
                            const bsnr = localStorage.getItem("BSNR");
                            const password = TelescanConfig.getInstance().getConstant("PASSWORD");
                            this.props.dispatch_login(bsnr || "", password, lanr)
                    }}
                >
                    Abschließen
                </Button>
                }
            </header>
            </>
        );
    }
}

const HeaderInitialisationContainer = connector(HeaderInitialisation);
export default HeaderInitialisationContainer;
