import React from 'react';


export interface WithNewPasswordMethodsProps {
    newPassword: string;
    newRepeatedPassword: string;
    passwordStrength: "strong" | "good" | "weak" | undefined;
    handleNewPasswordChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => string;
    handleNewRepeatedPasswordChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
    setStrength: (strength: "weak" | "good" | "strong") => void;
    clearFields: () => void;
}

export function withNewPasswordMethods(Component) {
    type Props = {}
    type State = {
        newPassword: string;
        newRepeatedPassword: string;
        passwordStrength: "strong" | "good" | "weak" | undefined;
    }

    // ...and returns another component...
    return class extends React.Component<Props, State> {
      constructor(props: Props) {
        super(props);
        this.state = {
            newPassword: "",
            newRepeatedPassword: "",
            passwordStrength: undefined,
        };

        this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
        this.handleNewRepeatedPasswordChange = this.handleNewRepeatedPasswordChange.bind(this);
        this.setStrength = this.setStrength.bind(this);
        this.clearFields = this.clearFields.bind(this);
      }

        handleNewPasswordChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): string {
            this.setState({ newPassword: e.target.value });
            return e.target.value;
        } 

        handleNewRepeatedPasswordChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
            this.setState({ newRepeatedPassword: e.target.value });
        } 

        setStrength(strength: "weak" | "good" | "strong") {
            this.setState({passwordStrength: strength})
        }

        clearFields() {
            this.setState({
                newPassword: "",
                newRepeatedPassword: "",
            });
        }
    

        render() {
            return (
                <Component newPassword={this.state.newPassword} 
                    newRepeatedPassword={this.state.newRepeatedPassword} 
                    passwordStrength={this.state.passwordStrength}
                    handleNewPasswordChange={this.handleNewPasswordChange}
                    handleNewRepeatedPasswordChange={this.handleNewRepeatedPasswordChange}
                    setStrength={this.setStrength}
                    clearFields={this.clearFields}
                    {...(this.props as any)}
                />
            )
        }
    };
}




/* export function withPasswordMethods <P extends WithPasswordProps, C> (Component: React.JSXElementConstructor<P> & C) {

    type Props = JSX.LibraryManagedAttributes<C, Omit<P, "newPassword">>;
    //type Props = Pick<P, Exclude<keyof P, "newPassword">>

    type State = {
        newPassword: string;
        newRepeatedPassword: string;
        passwordStrength: "strong" | "good" | "weak" | undefined;
    }
    // ...and returns another component...
    return class extends React.Component<Props, State> {
      constructor(props: Props) {
        super(props);
        this.state = {
            newPassword: "",
            newRepeatedPassword: "",
            passwordStrength: undefined,
        };

        this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
        this.handleNewRepeatedPasswordChange = this.handleNewRepeatedPasswordChange.bind(this);
        this.setStrength = this.setStrength.bind(this);
      }

        handleNewPasswordChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
            this.setState({ newPassword: e.target.value });
        } 

        handleNewRepeatedPasswordChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
            this.setState({ newRepeatedPassword: e.target.value });
        } 

        setStrength(strength: "weak" | "good" | "strong") {
            this.setState({passwordStrength: strength})
        }
    

        render() {
            return (
                <Component newPassword={this.state.newPassword} 
                    newRepeatedPassword={this.state.newRepeatedPassword} 
                    passwordStrength={this.state.passwordStrength}
                    handleNewPasswordChange={this.handleNewPasswordChange}
                    handleNewRepeatedPasswordChange={this.handleNewRepeatedPasswordChange}
                    setStrength={this.setStrength}
                    {...(this.props as any)}
                />
            )
        }
    };
} */