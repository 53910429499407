import { Arzt, Betriebsstaette, Initialisierung, RoleTypes, Kennzeichnung } from "telescan-core"
import { TUserActionTypes, EUserActions } from "../types/user_types"

export interface IUserState {
    user: Arzt[];
    role: keyof typeof RoleTypes;
    betriebsstaettenAerzte: Arzt[];
    betriebsstaette: Betriebsstaette;
    basisdatenBSErrors: Map<string, string>; // <Feldname, Fehlermeldung>
    basisdatenArztErrors: Map<string, string>; // <Feldname, Fehlermeldung>
    initialisationStatus: Initialisierung;
    einverstaendnis: boolean | undefined;
    zertifikatArztVorhanden: boolean;
    zertifikatBetriebsstaetteVorhanden: boolean;
    kennzeichnung: Kennzeichnung;
    fachrichtung: string;
}

const initialState: IUserState = {
    //userDetails: new UserDetails(),
    user: [],
    role: "HAUSARZT",
    betriebsstaettenAerzte: [new Arzt()],
    betriebsstaette: new Betriebsstaette(),
    basisdatenBSErrors: new Map<string, string>(),
    basisdatenArztErrors: new Map<string, string>(),
    initialisationStatus: new Initialisierung(),
    einverstaendnis: undefined,
    zertifikatArztVorhanden: true,
    zertifikatBetriebsstaetteVorhanden: true,
    kennzeichnung: new Kennzeichnung(),
    fachrichtung: "",
}

export function user_reducer(state = initialState, action: TUserActionTypes): IUserState {
    switch (action.type) {
        case EUserActions.SET_FACHRICHTUNG:
            return {
                ...state,
                fachrichtung: action.fachrichtung
            }

        case EUserActions.SET_KENNZEICHNUNG:
            return {
                ...state,
                kennzeichnung: action.kennzeichnung
            }

        case EUserActions.SET_USER:
            return {
                ...state,
                user: action.user
            }

        case EUserActions.SET_ROLE:
            return {
                ...state,
                role: action.role
            }

        case EUserActions.SET_BETRIEBSSTAETTEN_AERZTE:
            return {
                ...state,
                betriebsstaettenAerzte: action.betriebsstaettenAerzte
            }

        case EUserActions.SET_BETRIEBSSTAETTE:
            return {
                ...state,
                betriebsstaette: action.betriebsstaette
            }

        case EUserActions.BASISDATEN_BS_ERRORS:
            return {
                ...state,
                basisdatenBSErrors: action.fieldErrorMap
            }

        case EUserActions.BASISDATEN_ARZT_ERRORS:
            return {
                ...state,
                basisdatenArztErrors: action.fieldErrorMap
            }

        case EUserActions.SET_INITIALISATION_STATUS:
            return {
                ...state,
                initialisationStatus: action.initialisationStatus
            }

        case EUserActions.SET_EINVERSTAENDNIS:
            return {
                ...state,
                einverstaendnis: action.einverstaendnis
            }

        case EUserActions.SET_ARZTZERTIFIKAT_ERFASST:
            return {
                ...state,
                zertifikatArztVorhanden: action.zertifikatArztVorhanden
            }

        case EUserActions.SET_BSZERTIFIKAT_ERFASST:
            return {
                ...state,
                zertifikatBetriebsstaetteVorhanden: action.zertifikatBetriebsstaetteVorhanden
            }

        default:
            return state
    }
}
