import React from "react";
import {connect, ConnectedProps} from "react-redux";
import SimpleModalContainer from "../elements/simple_modal";
import { Paper, Typography, Box, Button } from "@material-ui/core";
import { RootState } from "../../redux/reducers";
import TeleScanUpdateComponent from "../initalisation/telescan_update";
import HpmUpdateComponent from "../initalisation/hpm_update";
import { UpdateStepStatusType } from "telescan-core";
import { setHpmUpdateSteps, setTeleScanUpdateSteps } from "../../redux/actions/update_actions";
import { setUpdateRunning } from "../../redux/actions/update_actions";
import { getIsHpmAvailableStatus } from "../../redux/actions";

interface IProps {
}

const mapStateToProps = (state: RootState, ownProps: IProps) => ({
    updateRunning: state.update.updateRunning,
    teleScanUpdateSteps: state.update.teleScanUpdateSteps,
    hpmUpdateSteps: state.update.hpmUpdateSteps,
    updatingModule: state.update.module,
    isUpdateSuccess: state.update.isUpdateSuccess,
    ...ownProps,
})

const mapDispatchToProps = {
    dispatch_setHpmUpdateSteps: (hpmUpdateSteps: {step: string, status: keyof typeof UpdateStepStatusType, progress: string, message: string}[]) => setHpmUpdateSteps(hpmUpdateSteps),
    dispatch_setTelescanUpdateSteps: (teleScanpdateSteps: {step: string, status: keyof typeof UpdateStepStatusType, progress: string, message: string}[]) => setTeleScanUpdateSteps(teleScanpdateSteps),
    dispatch_setUpdateRunning: (isUpdateRunning: boolean) => setUpdateRunning(isUpdateRunning),
    dispatch_getIsHpmAvailableStatus: () => getIsHpmAvailableStatus(),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>


export class UpdatePopup extends React.Component<TPropsFromRedux> {

    onOkSubmit = () => {
        this.props.dispatch_setUpdateRunning(false);
        this.props.updatingModule === "hpm" && this.props.dispatch_getIsHpmAvailableStatus();
    }
    render () {
        return (
            <SimpleModalContainer isOpen={(this.props.updateRunning)}
                onClose={() => {}}>
                <Paper className="modal-wrapper">
                    {this.props.isUpdateSuccess ?
                        <h2 className="modal-header">Installation/Update wurde erfolgreich durchgeführt</h2> :
                        <h2 className="modal-header">Installation/Update wird ausgeführt</h2>
                    }
                    <Box className="modal-content update-popup">
                        {this.props.isUpdateSuccess ?
                            this.props.updatingModule === "TELESCAN" && <Typography>Um Änderungen zu übernehmen, aktualisieren Sie bitte die Seite und loggen Sie sich erneuet ein.</Typography> :
                            <Typography>Zur Zeit findet ein Installation/Update statt. Bitte haben Sie etwas Geduld.</Typography>
                        }

                        {((this.props.updatingModule === "TELESCAN" && this.props.teleScanUpdateSteps.length > 0 && this.props.teleScanUpdateSteps[this.props.teleScanUpdateSteps.length-1].status !== "DONE")) &&
                        <Box className="hpm-installation-card pad-bottom">
                            <TeleScanUpdateComponent steps={this.props.teleScanUpdateSteps}/>
                        </Box>
                        }
                        {((this.props.updatingModule === "hpm" && this.props.hpmUpdateSteps.length > 0 && this.props.hpmUpdateSteps[this.props.hpmUpdateSteps.length-1].status !== "DONE")) &&
                        <Box className="hpm-installation-card pad-bottom">
                            <HpmUpdateComponent steps={this.props.hpmUpdateSteps}/>
                        </Box>
                        }
                    </Box>
                    <Box className="modal-footer">
                        {this.props.isUpdateSuccess &&
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className="btn-right"
                            onClick={this.onOkSubmit}
                        >
                            OK
                        </Button>
                        }
                    </Box> 
                </Paper>
            </SimpleModalContainer>
        )
    }
}

const UpdatePopupComponent = connector(UpdatePopup);
export default UpdatePopupComponent;
