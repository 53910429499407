import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux';
import { history } from './redux/reducers';
import { store } from './redux/store';
import { ShellContainer } from './components';
import './App.scss';
import './static/leaflet/leaflet.css';
import theme from './theme';
import { createTheme } from '@material-ui/core/styles';
import { deDE } from '@material-ui/core/locale';

const localeTheme = createTheme(theme, deDE);

export class App extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <div className="App">
                    <ThemeProvider theme={localeTheme}>
                        <CssBaseline />
                        <ConnectedRouter history={history}>
                            <ShellContainer />
                        </ConnectedRouter>
                    </ThemeProvider>
                </div >
            </Provider>
        );
    }
}

export default App;
