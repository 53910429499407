import React from 'react';
import uuid from 'uuid';
// material components
import {Box, Button, Paper, Typography} from '@material-ui/core'
import {RootState} from '../../redux/reducers';
import {connect, ConnectedProps} from 'react-redux';
import {loading, loadSuccess} from '../../redux/actions';
import {IdentifikationsCode} from '../initalisation/zertifikate';
import {withIdentifikationscodeMethods, WithIdentifikationscodeMethodsProps} from '../../utils';
import SimpleModalContainer from '../elements/simple_modal';
import ZertifikatPasswortComponent from '../elements/zertifikat_passwort';


interface IState {
    password: string;
    //identifikationscode: string;
}

interface IProps {
    isOpen: boolean;
    toggleModal: () => void;
    createZertifikat: (identificationCode: string, password?: string) => void;
    type: "Betriebsstaette" | "Arzt";
}

const mapStateToProps = (state: RootState, ownProps: IProps) => ({
    loading: state.general.loading,
    role: state.user.role,
    ...ownProps
})

const mapDispatchToProps = {
    dispatch_loading: (id: string) => loading(id),
    dispatch_loadSuccess: (id: string) => loadSuccess(id),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>
type Props = TPropsFromRedux & WithIdentifikationscodeMethodsProps


export class CreateZertifikatPopup extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            password: "",
        };

        this.loadInstanceIdArzt = uuid.v4();

        this.handlePasswordChange = this.handlePasswordChange.bind(this);
    }

    private loadInstanceIdArzt: string;


    handlePasswordChange(e) {
        this.setState({password: e.target.value});
    }

    render() {
        const title: string = (this.props.type === "Arzt") ? "Neues Arzt-Zertifikat erstellen" : "Neues Betriebsstätten-Zertifikat erstellen";
        const infoText: string = (this.props.type === "Arzt") ?
            "Zum Erzeugen des Arztzertifikats wird ein Identifikationscode, sowie Ihr persönliches Zertifikat-Passwort benötigt." :
            "Zum Erzeugen des Betriebsstättenzertifikats wird ein Identifikationscode benötigt.";
        const disabled: boolean = (this.props.type === "Arzt") ?
            (this.state.password == null || this.state.password === "" || (this.props.identifikationscodeHA.length !== 19 || this.props.loading[0] === this.loadInstanceIdArzt)) :
            (this.props.identifikationscodeHA.length !== 19 || this.props.loading[0] === this.loadInstanceIdArzt)

        return (
            <SimpleModalContainer isOpen={this.props.isOpen}>
                <Paper className="modal-wrapper konsil-question-pop">
                    <Box className="modal-content initialisation-content"
                         style={{height: "auto", paddingBottom: "0px", background: "white"}}>
                        <h2>{title}</h2>
                        <Paper className="recovery-code-card" elevation={0}>
                            {this.props.type === "Arzt" &&
                            <Typography variant="caption" color="inherit" style={{maxWidth: "60vw"}}>
                                {infoText}
                            </Typography>
                            }
                            < IdentifikationsCode setIdentifkationsCode={this.props.setIdentifikationscode}/>
                            {this.props.type === "Arzt" &&
                            <Box className="pad-top">
                                <ZertifikatPasswortComponent password={this.state.password}
                                                             handlePasswordChange={this.handlePasswordChange}/>
                            </Box>
                            }
                        </Paper>
                    </Box>
                    <Box className="modal-footer">
                        <Button variant="text" onClick={() => this.props.toggleModal()}>
                            Zurück
                        </Button>
                        <Button disabled={disabled} variant="contained" color="primary"
                                onClick={() => {
                                    if (this.props.role === "HAUSARZT" || this.props.role === "BETRIEBSSTAETTE_HAUSARZT")
                                        this.props.createZertifikat(this.props.identifikationscodeHA, this.state.password)
                                    else
                                        this.props.createZertifikat(this.props.identifikationscodeDerma, this.state.password)
                                }}>
                            Zertifikat erstellen
                        </Button>
                    </Box>
                </Paper>
            </SimpleModalContainer>
        );
    }
}

export const CreateZertifikatPopupComponent = connector(withIdentifikationscodeMethods(CreateZertifikatPopup));
export default CreateZertifikatPopupComponent;
