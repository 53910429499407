import React from 'react';
import Chip from '@material-ui/core/Chip';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentDots } from '@fortawesome/free-solid-svg-icons'
import { KonsilStatusType, BearbeitungsStatusType, RoleTypes } from 'telescan-core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ErrorOutline, AccountCircle } from '@material-ui/icons';
import { TherapieDringlichkeit } from 'telescan-core/lib/entities/konsil_abschluss';
import { Tooltip } from '@material-ui/core';

interface Props {
    status: keyof typeof KonsilStatusType | "VERFUEGBAR";
    role: keyof typeof RoleTypes;
    isMetaData: boolean;
    bearbeitungsStatus: keyof typeof BearbeitungsStatusType | undefined;
    hasPvsPatient: boolean;
    therapiedringlichkeit?: TherapieDringlichkeit;
}

const rueckfrageTheme = createTheme({
    overrides: {
        MuiChip: {
            root: {
                backgroundColor: '#D58910',
                color: 'white',
            },
        },
    },
});
const befundetThemeNone = createTheme({
    overrides: {
        MuiChip: {
            root: {
                backgroundColor: '#b7b7b7',
                color: 'white',
            },
        },
    },
});

const befundetThemeLow = createTheme({
    overrides: {
        MuiChip: {
            root: {
                backgroundColor: '#50BC86',
                color: 'white',
            },
        },
    },
});

const befundetThemeModerate = createTheme({
    overrides: {
        MuiChip: {
            root: {
                backgroundColor: '#cbc615',
                color: 'white',
            },
        },
    },
});

const befundetThemeHigh = createTheme({
    overrides: {
        MuiChip: {
            root: {
                backgroundColor: '#e55555',
                color: 'white',
            },
        },
    },
});

const beauftragtTheme = createTheme({
    overrides: {
        MuiChip: {
            root: {
                backgroundColor: '#859EB4',
                color: 'white',
            },
        },
    },
});

const abgeschlossenTheme = createTheme({
    overrides: {
        MuiChip: {
            root: {
                backgroundColor: '#b7b7b7',
                color: 'white',
            },
        },
    },
});



export class KonsilStatus extends React.Component<Props> {

    private getTheme(konsilStatus: keyof typeof KonsilStatusType | "VERFUEGBAR", therapiedringlichkeit: TherapieDringlichkeit | undefined){
        if (konsilStatus === "IN_ARBEIT" ||  konsilStatus === "BEAUFTRAGT"  ||  konsilStatus === "VERFUEGBAR")
            return beauftragtTheme
        else if (konsilStatus === "RUECKFRAGE" || konsilStatus === "BEANTWORTET")
            return rueckfrageTheme
        else if (konsilStatus === "BEFUNDET" && (therapiedringlichkeit === "Keine" || therapiedringlichkeit == null))
            return befundetThemeNone
        else if (konsilStatus === "BEFUNDET" && (therapiedringlichkeit === "Niedrig"))
            return befundetThemeLow
        else if (konsilStatus === "BEFUNDET" && therapiedringlichkeit === "Mittel")
            return befundetThemeModerate
        else if (konsilStatus === "BEFUNDET" && therapiedringlichkeit === "Hoch")
            return befundetThemeHigh
        else if (konsilStatus === "ABGESCHLOSSEN" || konsilStatus === "ABGEBROCHEN")
            return abgeschlossenTheme
        else
            return beauftragtTheme
    }

    private getLabel(
        bearbeitungsStatus: keyof typeof BearbeitungsStatusType | undefined,
        dringlichkeit: TherapieDringlichkeit | undefined,
        hasPvsPatient: boolean,
        konsilStatus: keyof typeof KonsilStatusType | "VERFUEGBAR"
    ) {
        if (bearbeitungsStatus === "ERROR")
            return <span className="send"><ErrorOutline/> {KonsilStatusType[konsilStatus] + this.getDringlichkeitMarker(dringlichkeit)}</span>
        else if (bearbeitungsStatus === "UPLOAD")
            return <span className="send"><CircularProgress /> &nbsp; Versende</span>
        else if (bearbeitungsStatus === "DOWNLOAD")
            return <span className="send"><CircularProgress /> &nbsp; Lädt...</span>
        else if (!hasPvsPatient)
            return <span className="send"><AccountCircle /> {KonsilStatusType[konsilStatus] + this.getDringlichkeitMarker(dringlichkeit)}</span>
        else
            return KonsilStatusType[konsilStatus] + this.getDringlichkeitMarker(dringlichkeit)
    }

    private getDringlichkeitMarker(dringlichkeit: TherapieDringlichkeit | undefined) {
        if (dringlichkeit) {
            switch (dringlichkeit) {
                case 'Hoch':
                    return " !!"
                case "Mittel":
                    return " !"
            }
        }
        return ""
    }

    private getLabelMeta(bearbeitungsStatus: keyof typeof BearbeitungsStatusType | undefined) {
        if (bearbeitungsStatus === "ERROR")
            return <span className="send"><ErrorOutline/> Neu</span>
        else if (bearbeitungsStatus === "UPLOAD")
            return <span className="send"><CircularProgress /> &nbsp; Versende</span>
        else if (bearbeitungsStatus === "DOWNLOAD")
            return <span className="send"><CircularProgress size = {17}/> &nbsp; Lädt...</span>
        else
            return "Neu"
    }

    private iconMapping: Object = {
        RUECKFRAGE: faCommentDots,
        BEANTWORTET: faCommentDots,

    }

    render() {
        if (this.props.isMetaData && this.props.bearbeitungsStatus === "OK")
            return (
                <ThemeProvider theme={beauftragtTheme}>
                    <Chip className="status-chip" label="Neu" size="small" />
                </ThemeProvider>
            )
        else if (this.props.isMetaData && this.props.bearbeitungsStatus !== "OK")
            return (
                <ThemeProvider theme={beauftragtTheme}>
                    <Chip className="status-chip" label={this.getLabelMeta(this.props.bearbeitungsStatus)} size="small" />
                </ThemeProvider>
            )
        else
            return (
                <ThemeProvider theme={this.getTheme(this.props.status, this.props.therapiedringlichkeit)}>
                    <Tooltip title={this.props.therapiedringlichkeit && this.props.role.includes("HAUSARZT") ? `Therapiedringlichkeit: ${this.props.therapiedringlichkeit}` : ""}>
                        <Chip
                            className="status-chip"
                            icon={
                                (this.props.bearbeitungsStatus === "OK" && this.iconMapping[this.props.status])
                                ? <FontAwesomeIcon icon={this.iconMapping[this.props.status]}/>
                                : undefined
                            }
                            label={
                                this.getLabel(this.props.bearbeitungsStatus, this.props.therapiedringlichkeit, this.props.hasPvsPatient, this.props.status)
                            }
                            size="small"
                            />
                    </Tooltip>
                </ThemeProvider>
            )
    }
}
