import React from 'react';
import {Box, Typography} from '@material-ui/core';
import uuid from 'uuid';

interface ViewTextProps {
    labelVariant: "caption" | "body1" | "subtitle1",
    label: string,
    text: string | string[],
    hasError?: boolean,
    isSmallFont?: boolean,
}

export class ViewText extends React.Component<ViewTextProps> {
    render() {
        return (
            <Box className="konsil-txt-container">
                <Typography className={"txt-label"} variant={this.props.labelVariant} color={(this.props.hasError) ? "error" : "inherit"}>
                    {this.props.label}
                </Typography>
                {(Array.isArray(this.props.text)) &&
                <div className="txt-content">
                    {this.props.text.map((str) =>
                        <Typography key={uuid.v4()} variant = {this.props.isSmallFont ? "body2" : "body1"} color={(this.props.hasError) ? "error" : "inherit"} style = {{marginBottom: "5px", whiteSpace: 'pre-line'}} >
                            {str}
                        </Typography>
                    )}
                </div>
                }
                {(typeof this.props.text === "string") &&
                <Typography className="txt-content" variant="body1" color={(this.props.hasError) ? "error" : "inherit"} style={{whiteSpace: 'pre-line'}}>
                    {this.props.text}
                </Typography>
                }
            </Box>
        );
    }
}

interface ViewTextHtmlProps {
    labelVariant: "caption" | "body1" | "subtitle1",
    label: string,
    textHtml: any,
    hasError?: boolean,
}

export class ViewTextHtml extends React.Component<ViewTextHtmlProps> {
    render() {
        return (
            <Box className="konsil-txt-container">
                <Typography className={"txt-label"} variant={this.props.labelVariant} color={(this.props.hasError) ? "error" : "inherit"}>
                    {this.props.label}
                </Typography>
                <div dangerouslySetInnerHTML={{__html: this.props.textHtml}}/>
            </Box>
        );
    }
}
