import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    IconButton,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@material-ui/core';
import { Add, Close, Delete, Edit } from '@material-ui/icons';
import { ICDService, Konsil, Krankheit, Labor, Medikation, Patient, Risikofaktoren, Failure, Laborwertcode, LaborcodeService } from 'telescan-core';
import * as _ from 'lodash';

import uuid from 'uuid';

import {
    EditPatientGeneralDataComponent,
    LaborComponent,
    LaborEditComponent,
    LaborViewTextComponent
} from '../..'
import { checkEinwilligungStatus, checkKonsilPatientStatus, failure, loadFailure, setIsPending, updateKonsil } from '../../../../redux/actions';
import { RootState } from '../../../../redux/reducers';
import { MedikationComponent, MedikationEditComponentContainer, MedikationViewComponent } from './medication';
import { Patienteneinwilligung } from 'telescan-core/lib/entities/patienteneinwilligung';
import { LaborViewComponent } from './labor';
import { KrankheitHausarztComponent, KrankheitHausarztEditComponentContainer, KrankheitHausarztViewComponent } from './disease_hausarzt';


const mapStateToProps = (state: RootState) => ({
    role: state.user.role,
    currentKonsil: state.konsile.current_konsil,
    patientErrors: state.evaluation.konsilPatientErrors,
    isPending: state.general.isPending,
    initialisedServices: state.general.initialisedServices
})

const mapDispatchToProps = {
    loadFailure: (loadInstanceId: string, message: Failure) => loadFailure(loadInstanceId, message),
    dispatch_updateKonsil: (konsil: Konsil, previousKonsil?: Konsil) => updateKonsil(konsil, previousKonsil),
    dispatch_checkKonsilPatientStatus: (patient: Patient, konsilId: string) => checkKonsilPatientStatus(patient, konsilId),
    dispatch_checkEinwilligungStatus: (konsil: Konsil) => checkEinwilligungStatus(konsil, konsil.patienteneinwilligung),
    dispatch_failure: (id: string, failureObject: Failure) => failure(id, failureObject),
    dispatch_setIsPending: (isPending: boolean) => setIsPending(isPending),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

interface IState {
    addDiagnose: boolean,
    editDiagnose: boolean,
    viewDiagnose: boolean,
    diagnose: Krankheit | null,
    addLab: boolean,
    editLab: boolean,
    viewLab: boolean,
    viewLabText: boolean,
    lab: Labor | null,
    addMed: boolean,
    editMed: boolean,
    viewMed: boolean,
    med: Medikation | null,
    konsil: Konsil;
    previousKonsil: Konsil;
    editDiagnosen: boolean;
    editLabs: boolean;
    editMeds: boolean;
    icd10Data: {
        kapitelData: string[],
        kapitelVersion: string,
        kapitelYear: string,
        oid: string,
        isIcd10Loading: boolean,
        loadingError: string,
        krankheitBeginnYear: string,
    };
    laborwertCodes: Laborwertcode[];
}

export class EditPersonalData extends React.Component<TPropsFromRedux, IState> {
    private intervalId = 0;
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            addDiagnose: false,
            editDiagnose: false,
            viewDiagnose: false,
            diagnose: null,
            addLab: false,
            editLab: false,
            viewLab: false,
            viewLabText: false,
            lab: null,
            addMed: false,
            editMed: false,
            viewMed: false,
            med: null,
            konsil: props.currentKonsil || new Konsil(),
            previousKonsil: new Konsil(),
            editDiagnosen: false,
            editLabs: false,
            editMeds: false,
            icd10Data: {
                kapitelData: [],
                kapitelVersion: "",
                kapitelYear: "",
                oid: "",
                isIcd10Loading: false,
                loadingError: "",
                krankheitBeginnYear: "",
            },
            laborwertCodes: [],
        };

        this.closeDiagnose = this.closeDiagnose.bind(this);
        this.closeLabor = this.closeLabor.bind(this);
        this.closeLaborText = this.closeLaborText.bind(this);
        this.closeMedikation = this.closeMedikation.bind(this);
        this.getIcd10Katalog = this.getIcd10Katalog.bind(this);
        this.clearIcd10KatalogLoadingError = this.clearIcd10KatalogLoadingError.bind(this);
        this.getLaborcodes = this.getLaborcodes.bind(this);
    }

    componentDidUpdate(prevProps: TPropsFromRedux) {
        if (prevProps.currentKonsil !== this.props.currentKonsil) {
            this.setState({ konsil: this.props.currentKonsil });
        }
    }

    componentWillUnmount() {
        window.clearInterval(this.intervalId);
    }

    updateKonsil = () => {
        window.clearInterval(this.intervalId);
        this.props.dispatch_updateKonsil(this.state.konsil, this.state.previousKonsil);
        this.props.dispatch_checkKonsilPatientStatus(this.state.konsil.konsilPatient, this.props.currentKonsil.id || "");
        this.props.dispatch_checkEinwilligungStatus(this.state.konsil);
    }

    updateKonsilWithIntervalDelay = (updatedKonsil: Konsil) => {
        if (!this.props.isPending) {
            window.clearInterval(this.intervalId);
            this.setState(
                {konsil: updatedKonsil},
                this.updateKonsil
            );
        }
        else {
            this.intervalId = window.setInterval(() => {
                this.setState(
                    {konsil: updatedKonsil},
                    this.updateKonsil
                );
            }, 500);
        }
    }

    closeDiagnose() {
        this.setState({
            viewDiagnose: false
        })
    }

    closeLabor() {
        this.setState({
            viewLab: false
        })
    }

    closeLaborText() {
        this.setState({
            viewLabText: false
        })
    }

    closeMedikation() {
        this.setState({
            viewMed: false
        })
    }

    getLaborcodes() {
        if (this.state.laborwertCodes.length === 0) {
            const laborcodeService: LaborcodeService = new LaborcodeService();
            laborcodeService.getLaborcodes()
                .then(response =>
                    this.setState({ laborwertCodes: response })
                )
                .catch(error =>
                    console.log(error)
                )
        }

    }

    getIcd10Katalog(date?: number) {
        const year =
            date ?
                new Date(date).getFullYear().toString() :
                new Date().getFullYear().toString();
        this.setState({
            icd10Data: {
                ...this.state.icd10Data,
                isIcd10Loading: true,
                krankheitBeginnYear: year,
            }
        })
        const prevIcd10 = this.state.icd10Data;
        const icdService = new ICDService();
        this.state.icd10Data.kapitelYear !== year || this.state.icd10Data.loadingError !== "" ?
            icdService.getIcd10(date || new Date().getTime()).then(responce => {
                let parsedRes = JSON.parse(responce);
                const year = parsedRes.version.split(" ")[0];
                this.setState({
                    icd10Data: {
                        ...this.state.icd10Data,
                        kapitelData: parsedRes.kapitel,
                        kapitelVersion: parsedRes.version,
                        kapitelYear: year,
                        oid: parsedRes.oid,
                        isIcd10Loading: false,
                        loadingError: "",
                    },
                });
            })
                .catch(error => {
                    this.setState({
                        icd10Data: {
                            ...this.state.icd10Data,
                            isIcd10Loading: false,
                            loadingError: error.message,
                            kapitelYear: "",
                        },
                    })
                }) :
            this.setState({
                icd10Data: prevIcd10,
            })
    }

    clearIcd10KatalogLoadingError() {
        this.setState({
            icd10Data: {
                ...this.state.icd10Data,
                loadingError: "",
            }
        })
    }

    handleChangeAussentaetigkeit = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))});
        const patient = this.state.konsil.konsilPatient as Patient;
        const risikofaktoren: Risikofaktoren = patient.risikofaktoren || new Risikofaktoren();
        risikofaktoren.aussentaetigkeit = undefined;
        if (event.target.value === "ja")
            risikofaktoren.aussentaetigkeit = true;
        else if (event.target.value === "nein")
            risikofaktoren.aussentaetigkeit = false;
        else
            risikofaktoren.aussentaetigkeit = undefined;
        let updatedKonsil: Konsil = {
            ...this.state.konsil,
            konsilPatient: {
                ...this.state.konsil.konsilPatient,
                risikofaktoren: risikofaktoren
            }
        }
        this.updateKonsilWithIntervalDelay(updatedKonsil);
    }

    render() {
        const patientError: Map<string, string> = this.props.patientErrors.find(item => item.konsilID === this.props.currentKonsil.id)?.errors || new Map<string, string>();
        if (this.state.konsil.konsilPatient == null)
            return <Box className="no-patient">Kein Patient gefunden!</Box>
        return (
            <div className="person-data-container">
                <Box className="scroll-box">
                    {this.props.currentKonsil.pvsPatient == null &&
                        <Box className="profile-data">Es ist noch kein Eintrag aus Ihrem PVS verknüpft!</Box>
                    }
                    <form className="form-content" noValidate autoComplete="off"
                        onSubmit={(event) => event.preventDefault()}>
                        <Box className="profile-data">
                            <EditPatientGeneralDataComponent
                                //hasError={this.props.patientErrors.has("patient") || this.props.patientErrors.has("krankenkasse")}
                                hasError={patientError.has("patient") || patientError.has("krankenkasse")}
                            />
                        </Box>
                        <Divider />
                        <FormControl className="beruf" component="fieldset">
                            <FormLabel className="pad-bottom bold-label" component="legend">Berufliche Tätigkeit
                            </FormLabel>
                            <TextField id="beruf" variant="filled"
                                value={this.state.konsil.konsilPatient.risikofaktoren?.beruflicheTaetigkeiten || ""}
                                onChange={(event) => {
                                    const patient = this.state.konsil.konsilPatient as Patient;
                                    const risikofaktoren: Risikofaktoren = patient.risikofaktoren || new Risikofaktoren();
                                    risikofaktoren.beruflicheTaetigkeiten = event.target.value;
                                    let updatedKonsil: Konsil = {
                                        ...this.state.konsil,
                                        konsilPatient: {
                                            ...this.state.konsil.konsilPatient,
                                            risikofaktoren: risikofaktoren
                                        }
                                    }
                                    this.setState({konsil: updatedKonsil})
                                }}
                                onFocus = {() => this.setState({previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))})}
                                onBlur={() => {
                                    this.updateKonsil();
                                    this.props.dispatch_setIsPending(true);
                                }}
                            />
                        </FormControl>
                        <FormControl className="aussentaetig" component="fieldset">
                            <FormLabel component="legend" className="bold-label float-left">Außentätigkeit</FormLabel>
                            <RadioGroup
                                className="pad-top-l"
                                row aria-label="outdoor"
                                name="outdoor"
                                value={this.state.konsil.konsilPatient.risikofaktoren?.aussentaetigkeit == null ? "unbekannt" : (this.state.konsil.konsilPatient.risikofaktoren?.aussentaetigkeit ? "ja" : "nein")}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setTimeout(() => this.handleChangeAussentaetigkeit(event), 300);
                                }}
                            >
                                <FormControlLabel value="ja" control={<Radio />} label="ja" />
                                <FormControlLabel value="nein" control={<Radio />} label="nein" />
                                <FormControlLabel value="unbekannt" control={<Radio />} label="nicht bekannt" />
                            </RadioGroup>
                        </FormControl>
                        <Divider />
                        <Box className="einwilligung">
                            <FormGroup className="form-grid-content"
                                            >
                                <FormControl className='einwilligung-item'>
                                    <FormControlLabel
                                        control={<Checkbox name="einwilligung-forschung"
                                            checked={this.state.konsil.patienteneinwilligung?.einwilligungForschung || false}
                                            disabled
                                        />}
                                        label={<Typography variant="subtitle1">Einwilligung für
                                            Forschungszwecke</Typography>}
                                    />
                                    <Typography variant="caption" color="inherit">
                                        Dieses Feld wird derzeit nicht erfasst.
                                    </Typography>
                                </FormControl>

                                {this.props.initialisedServices.includes("KIM") &&
                                    <FormControl
                                    className='einwilligung-item'
                                    error={!this.state.konsil.patienteneinwilligung?.einwilligungKonsil}
                                    >
                                        <FormLabel component="legend" className="bold-label float-left">
                                            Einwilligung ins Konsil
                                        </FormLabel>
                                        <FormControlLabel
                                            className="einwilligung-konsil"
                                            control={<Checkbox
                                                name="einwilligung-konsil" className="einwilligung-konsil"
                                                checked={this.state.konsil.patienteneinwilligung?.einwilligungKonsil || false}
                                                disableTouchRipple
                                                />}
                                                label={<Typography>Der Patient hat eingewilligt.</Typography>}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    let updatedKonsil = {
                                                        ...this.state.konsil,
                                                        patienteneinwilligung: {
                                                            ...this.state.konsil.patienteneinwilligung,
                                                            einwilligungKonsil: event.target.checked,
                                                            dateKonsileinwilligung: (event.target.checked) ? Date.now() : null
                                                        } as Patienteneinwilligung
                                                    }
                                                    this.setState({
                                                        konsil: updatedKonsil
                                                    }, this.updateKonsil);
                                                }}
                                                />
                                        <FormHelperText variant="standard" color="inherit">
                                            Die Einwilligung des Patienten in den Konsilauftrag beinhaltet das Einverständnis in
                                            die Anfertigung des Konsilauftrags und grundsätzlich das Einverständnis in den
                                            Versand dieses Konsils an eine Gruppe von Dermatologen (gerichteter Versand).
                                        </FormHelperText>
                                    </FormControl>
                            }
                            </FormGroup>
                        </Box>
                    </form>
                </Box>
                <Box className="scroll-box-side">
                    <Paper className="side-view" elevation={0} square>
                        <Typography variant="caption" color="inherit">
                            Die folgenden <b>Diagnosen</b>, <b>Laborwerte</b> und <b>Medikationen</b> wurden für diesen
                            Patienten aus Ihrem PVS übertragen.
                            Bitte beachten Sie, dass eine Änderung an diesen Daten keine Änderung der Daten in Ihrem PVS
                            bewirkt.
                        </Typography>
                        <div>
                            <Box className="button-header">
                                <Typography variant="subtitle1" color="inherit">
                                    Diagnosen
                                </Typography>
                                {this.state.editDiagnosen &&
                                    <Box>
                                        <Button variant="contained" className="slim" size="small" color="primary"
                                            onClick={() => {
                                                this.setState({
                                                    addDiagnose: true,
                                                    editDiagnose: true,
                                                    diagnose: new Krankheit(),
                                                    previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil)),
                                                })
                                                this.getIcd10Katalog()
                                            }}>
                                            <Add />
                                        </Button>
                                        <Button className="slim" size="small"
                                            onClick={() => this.setState({
                                                editDiagnosen: false
                                            })}>
                                            <Close />
                                        </Button>
                                    </Box>
                                }
                                {!this.state.editDiagnosen &&
                                    <Button
                                        className="slim"
                                        size="small"
                                        onClick={() => {
                                            this.setState({
                                                editDiagnosen: true,
                                            })

                                        }}
                                    >
                                        <Edit />
                                    </Button>
                                }
                            </Box>

                            <KrankheitHausarztEditComponentContainer
                                krankheit={this.state.diagnose || new Krankheit()}
                                open={this.state.editDiagnose}
                                add={this.state.addDiagnose}
                                callback={
                                    (krankheit, add) => {
                                        if (krankheit === null) {
                                            this.setState({
                                                addDiagnose: false,
                                                editDiagnose: false
                                            });
                                            return;
                                        }

                                        const krankheiten: Krankheit[] = this.state.konsil.konsilPatient?.krankheiten || [];
                                        if (add)
                                            krankheiten.push(krankheit as Krankheit);
                                        else if (this.state.diagnose)
                                            krankheiten[krankheiten.indexOf(this.state.diagnose)] = krankheit as Krankheit
                                        let updatedKonsil: Konsil = {
                                            ...this.state.konsil,
                                            konsilPatient: {
                                                ...this.state.konsil.konsilPatient,
                                                krankheiten: krankheiten
                                            }
                                        }
                                        this.setState(
                                            {
                                                konsil: updatedKonsil,
                                                addDiagnose: false,
                                                editDiagnose: false
                                            },
                                            this.updateKonsil
                                        )
                                    }}
                                icd10Data={this.state.icd10Data}
                                getIcd10Katalog={this.getIcd10Katalog}
                                clearIcd10KatalogLoadingError={this.clearIcd10KatalogLoadingError}
                            />

                            {this.state.konsil.konsilPatient.krankheiten?.sort((item1, item2) => { //sort diagnosis, dauerdiagnose === true go first
                                return + item2.dauerdiagnose - + item1.dauerdiagnose
                            })
                                .map(krankheit =>
                                    <KrankheitHausarztComponent key={"krankheit_component_" + krankheit.id + uuid.v4()}
                                        diagnose={krankheit}
                                        onEditClick={() => {
                                            this.setState({
                                                addDiagnose: false,
                                                editDiagnose: true,
                                                diagnose: krankheit,
                                                previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil)),
                                            })
                                            this.getIcd10Katalog(krankheit.datumDiagnosestellung)
                                        }}
                                        onViewClick={() => this.setState({
                                            viewDiagnose: true,
                                            diagnose: krankheit
                                        })}
                                        delete={(k) => {
                                            this.setState({previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))});
                                            const krankheiten: Krankheit[] = this.state.konsil.konsilPatient?.krankheiten || [];
                                            _.pull(krankheiten, k);
                                            let updatedKonsil: Konsil = {
                                                ...this.state.konsil,
                                                konsilPatient: {
                                                    ...this.state.konsil.konsilPatient,
                                                    krankheiten: krankheiten
                                                }
                                            }
                                            this.setState(
                                                {konsil: updatedKonsil},
                                                this.updateKonsil
                                            )
                                        }}
                                        hasError={patientError.has("krankheit." + (this.state.konsil.konsilPatient?.krankheiten || []).indexOf(krankheit))}
                                        isViewOnly={!this.state.editDiagnosen}
                                        role={this.props.role}
                                    />
                                )}
                            <KrankheitHausarztViewComponent handleClose={this.closeDiagnose} openView={this.state.viewDiagnose}
                                krankheit={this.state.diagnose || new Krankheit()} />
                        </div>
                        <div>
                            <Box className="button-header">
                                <Typography variant="subtitle1" color="inherit">
                                    Laborwerte
                                </Typography>
                                {this.state.editLabs &&
                                    <Box>
                                        {(!this.state.konsil.konsilPatient.laborSdTextHtml || this.state.konsil.konsilPatient.laborSdTextHtml === "") &&
                                            <Button variant="contained" className="slim" size="small" color="primary"
                                                onClick={() => {
                                                    this.getLaborcodes();
                                                    this.setState({
                                                        addLab: true,
                                                        editLab: true,
                                                        lab: new Labor(),
                                                        previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))
                                                    });
                                                }}>
                                                <Add />
                                            </Button>
                                        }
                                        <Button className="slim" size="small"
                                            onClick={() => {
                                                this.setState({ editLabs: false });
                                            }}>
                                            <Close />
                                        </Button>
                                    </Box>
                                }
                                {!this.state.editLabs &&
                                    <Button className="slim" size="small"
                                        onClick={() => {
                                            this.setState({ editLabs: true });
                                        }}>
                                        <Edit />
                                    </Button>
                                }
                            </Box>

                            <LaborEditComponent
                                labor={this.state.lab || new Labor()}
                                open={this.state.editLab}
                                add={this.state.addLab}
                                callback={
                                    (labor, add) => {
                                        if (labor === null) {
                                            this.setState({
                                                addLab: false,
                                                editLab: false
                                            });
                                            return;
                                        }
                                        const laborwerte: Labor[] = this.state.konsil.konsilPatient?.laborwerte || [];
                                        if (add)
                                            laborwerte.push(labor);
                                        else if (this.state.lab)
                                            laborwerte[laborwerte.indexOf(this.state.lab)] = labor
                                        let updatedKonsil: Konsil = {
                                            ...this.state.konsil,
                                            konsilPatient: {
                                                ...this.state.konsil.konsilPatient,
                                                laborwerte: laborwerte
                                            }
                                        }
                                        this.setState(
                                            {
                                                konsil: updatedKonsil,
                                                addLab: false,
                                                editLab: false
                                            },
                                            this.updateKonsil
                                        );
                                    }}
                                laborwertCodes={this.state.laborwertCodes}
                                dispatch_failure = {this.props.dispatch_failure}
                            />

                            {this.state.konsil.konsilPatient.laborwerte?.map(labor =>
                                <LaborComponent key={"labor_component_" + labor.id} labor={labor}
                                    onEditClick={() => {
                                        this.getLaborcodes();
                                        this.setState({
                                            addLab: false,
                                            editLab: true,
                                            lab: labor,
                                            previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))
                                        });
                                    }}
                                    onViewClick={() => this.setState({
                                        viewLab: true,
                                        lab: labor
                                    })}
                                    delete={(l) => {
                                        this.setState({previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))});
                                        const laborwerte: Labor[] = this.state.konsil.konsilPatient?.laborwerte || [];
                                        _.pull(laborwerte, l);
                                        let updatedKonsil: Konsil = {
                                            ...this.state.konsil,
                                            konsilPatient: {
                                                ...this.state.konsil.konsilPatient,
                                                laborwerte: laborwerte
                                            }
                                        }
                                        this.setState(
                                            {konsil: updatedKonsil},
                                            this.updateKonsil
                                        );
                                    }}
                                    error={patientError.has("labor." + (this.state.konsil.konsilPatient?.laborwerte || []).indexOf(labor))}
                                    isViewOnly={!this.state.editLabs}
                                />)}
                            <LaborViewComponent handleClose={this.closeLabor} openView={this.state.viewLab}
                                labor={this.state.lab || new Labor()} />
                            <LaborViewTextComponent openView={this.state.viewLabText}
                                laborSdTextHtml={this.state.konsil.konsilPatient?.laborSdTextHtml || ""}
                                handleClose={this.closeLaborText} />
                            {this.state.konsil.konsilPatient?.laborSdTextHtml && this.state.konsil.konsilPatient?.laborSdTextHtml !== "" &&
                                <Box className='flex-row'>
                                    <Button variant="contained" color="primary" disableElevation
                                        onClick={() => this.setState({
                                            viewLabText: true
                                        })}>
                                        Unstrukturierte Werte anzeigen
                                </Button>
                                    {this.state.editLabs &&
                                        <IconButton
                                            className="margin-left-auto"
                                            aria-label="delete-laborTextSD"
                                            size="small"
                                            onClick={(event) => {
                                                this.setState({previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))});
                                                event.preventDefault();
                                                event.stopPropagation();
                                                this.setState(
                                                    {konsil: {
                                                        ...this.state.konsil,
                                                        konsilPatient: {
                                                            ...this.state.konsil.konsilPatient,
                                                            laborSdTextHtml: undefined,
                                                            laborSdText: undefined,
                                                        }
                                                    }},
                                                    this.updateKonsil
                                                )
                                            }}
                                        >
                                            <Delete />
                                        </IconButton>
                                    }
                                </Box>
                            }
                        </div>
                        <div>
                            <Box className="button-header">
                                <Typography variant="subtitle1" color="inherit">
                                    Medikation
                                </Typography>
                                {this.state.editMeds &&
                                    <Box>
                                        <Button variant="contained" className="slim" size="small" color="primary"
                                            onClick={() => this.setState({
                                                addMed: true,
                                                editMed: true,
                                                med: new Medikation(),
                                                previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil)),
                                            })}>
                                            <Add />
                                        </Button>
                                        <Button className="slim" size="small"
                                            onClick={() => this.setState({
                                                editMeds: false
                                            })}>
                                            <Close />
                                        </Button>
                                    </Box>
                                }
                                {!this.state.editMeds &&
                                    <Button className="slim" size="small"
                                        onClick={() => this.setState({
                                            editMeds: true
                                        })}>
                                        <Edit />
                                    </Button>
                                }
                            </Box>

                            <MedikationEditComponentContainer
                                medikation={this.state.med || new Medikation()}
                                open={this.state.editMed}
                                add={this.state.addMed}
                                callback={
                                    (medikation, add) => {
                                        if (medikation === null) {
                                            this.setState({
                                                addMed: false,
                                                editMed: false
                                            });
                                            return;
                                        }

                                        const medikationen: Medikation[] = this.state.konsil.konsilPatient?.medikationen || [];
                                        if (add) {
                                            medikationen.push(medikation);
                                        } else if (this.state.med)
                                            medikationen[medikationen.indexOf(this.state.med)] = medikation
                                        let updatedKonsil: Konsil = {
                                            ...this.state.konsil,
                                            konsilPatient: {
                                                ...this.state.konsil.konsilPatient,
                                                medikationen: medikationen
                                            }
                                        }
                                        this.setState({
                                                konsil: updatedKonsil,
                                                addMed: false,
                                                editMed: false
                                            },
                                            this.updateKonsil
                                        );
                                    }}
                                />

                            {this.state.konsil.konsilPatient.medikationen?.map(medikation =>
                                <MedikationComponent
                                    key={"medikatio_component_" + (medikation.id || uuid.v4())}
                                    medikation={medikation}
                                    onEditClick={() => this.setState({
                                        addMed: false,
                                        editMed: true,
                                        med: medikation,
                                        previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))
                                    })}
                                    onViewClick={() => this.setState({
                                        viewMed: true,
                                        med: medikation
                                    })}
                                    delete={(m) => {
                                        this.setState({previousKonsil: JSON.parse(JSON.stringify(this.props.currentKonsil))});
                                        const medikationen: Medikation[] = this.state.konsil.konsilPatient?.medikationen || [];
                                        _.pull(medikationen, m);

                                        let updatedKonsil: Konsil = {
                                            ...this.state.konsil,
                                            konsilPatient: {
                                                ...this.state.konsil.konsilPatient,
                                                medikationen: medikationen
                                            }
                                        }

                                        this.setState(
                                            {konsil: updatedKonsil},
                                            this.updateKonsil
                                        );
                                    }}
                                    error={patientError.has("medikation." + (this.state.konsil.konsilPatient?.medikationen || []).indexOf(medikation))}
                                    isViewOnly={!this.state.editMeds}
                                />)}
                            <MedikationViewComponent handleClose={this.closeMedikation} isOpen={this.state.viewMed}
                                medikation={this.state.med || new Medikation()} />
                        </div>
                    </Paper>
                </Box>
            </div>
        );
    }
}

export const EditPersonalDataContainer = connector(EditPersonalData);
export default EditPersonalDataContainer;
