import React from 'react';
import {connect, ConnectedProps} from 'react-redux'
import uuid from 'uuid';
import {UserService, ZertifikatMetainformation, Betriebsstaette, Arzt, TelescanConfig, Failure, NotificationSubscriptionType, EnableNotifications } from 'telescan-core';
import {changePassword, loadZertifikate, setBetriebsstaettenAerzte, getZertifikatStatus, login, setBetriebsstaette, logout, reset} from '../../redux/actions';
import {RootState} from '../../redux/reducers';
import { failure } from '../../redux/actions/general_actions';
// material components
import {Box, Button, FormControl, Paper, Typography, Snackbar, CircularProgress, Tooltip, ListItemText, ListItem, TextField} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ArztInfoTableContainer, { ArztRow } from './arztinfo_table';
import BSInfoTableContainer, { BSInfoRow } from './bsinfo_table';
import { Add } from '@material-ui/icons';
import { NewPasswordContainer } from "../elements/new_password";
import { RecoveryCodeComponent } from '../initalisation/recovery_code';
import InstallationUpdatesContainer from './installation_updates';
import { WithNewPasswordMethodsProps, withNewPasswordMethods } from '../../utils';
import SimpleModalContainer from '../elements/simple_modal';
import Icd10Container from './icd10_table';
import { NotificationControl } from './notifications_settings/notification_control';
import { getAllCurrentNotifications, getSubscriptions, setIsAllNotifications, subscribe, unsubscribe } from '../../redux/actions/notification_actions';
import { SupportSettings } from './support_settings';
import { KimConfigurationContainer } from './kim_configuration/kim_configuration';
import { getDermatologistRegistrationStatus, getKimSettings } from '../../redux/actions/kim_actions';
import DirectoryServiceContainer from './directory_service/directory_service';
import GdtConfigurationComponentContainer from './gdt_settings/gdt_configuration';
import { getGdtConfig } from '../../redux/actions/gdt_actions';

interface IState {
    submitted: boolean,
    successMessage: string;
    qrCode: string;
    arzt: Arzt;
    isOpenArzt: boolean;
    betriebsstaette: Betriebsstaette;
    isOpenBS: boolean;
    isOpenAddBS: boolean;
    isAdd: boolean;
    isClickedRecoveryCode: boolean;
    recoveryCode: string;
    isOpenChangeBS: boolean;
    bsnr: string;
    lanr: string;
    betriebsstaetten: Betriebsstaette[];
}

const mapStateToProps = (state: RootState) => ({
    loading: state.general.loading,
    user: state.user.user,
    role: state.user.role,
    basisdatenBetriebsstaette: state.user.betriebsstaette,
    basisdatenAerzte: state.user.betriebsstaettenAerzte,
    basisdatenArztErrors: state.user.basisdatenArztErrors,
    arztZertifikate: state.zertifikate.arztZertifikateMetaInfo,
    bsZertifikat: state.zertifikate.betriebsstaettenZertifikatMetaInfo,
    initialisationStatus: state.user.initialisationStatus,
    isNewsSubscribed: state.notifications.isNewsSubscribed,
    isSubscriptionLoading: state.notifications.isSubscriptionLoading,
    allNotifications: state.notifications.allNotifications,
    isAllNotifications: state.notifications.isAllNotifications,
    isNotificationsLoading: state.notifications.isNotificationsLoading,
    isSupportEnabled: state.general.isSupportEnabled,
    initialisedServices: state.general.initialisedServices,
    isKimEnabled: state.general.isKimEnabled
})


const mapDispatchToProps = {
    dispatch_loadZertifikate: () => loadZertifikate(),
    dispatch_changePassword: (newPassword: string, loadInstanceId: string) => changePassword(newPassword, loadInstanceId),
    dispatch_failure: (loadInstanceId: string, message: Failure) => failure(loadInstanceId, message),
    dispatch_setBetriebsstaettenAerzte: (aerzte: Arzt[]) => setBetriebsstaettenAerzte(aerzte),
    dispatch_getZertifikatStatus: () => getZertifikatStatus(),
    dispatch_login: (bsnr: string, password: string, lanr: string | null, id: string) => login(bsnr, password, lanr, id),
    dispatch_setBetriebsstaette: (basisdatenBS: Betriebsstaette) => setBetriebsstaette(basisdatenBS),
    dispatch_logout: () => logout(),
    dispatch_reset: () => reset(),
    dispatch_getSubscriptions: () => getSubscriptions(),
    dispatch_subscribe: (type: NotificationSubscriptionType) => subscribe(type),
    dispatch_unsubscribe: (type: NotificationSubscriptionType) => unsubscribe(type),
    dispatch_getAllCurrentNotifications: () => getAllCurrentNotifications(),
    dispatch_setIsAllNotifications: (isAllNotifications: boolean) => setIsAllNotifications(isAllNotifications),
    dispatch_getKimSettings: () => getKimSettings(),
    dispatch_getDermatologistRegistrationStatus: () => getDermatologistRegistrationStatus(),
    dispatch_getGdtConfig: () => getGdtConfig(),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>
type Props = TPropsFromRedux & WithNewPasswordMethodsProps


export class UserSettings extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            submitted: false,
            successMessage: "",
            qrCode: "",
            arzt: new Arzt(),
            isOpenArzt: false,
            betriebsstaette: this.props.basisdatenBetriebsstaette,
            isOpenBS: false,
            isAdd: false,
            isClickedRecoveryCode: false,
            recoveryCode: "",
            isOpenChangeBS: false,
            isOpenAddBS: false,
            bsnr: "",
            lanr: "",
            betriebsstaetten: [],
        };
        this.handleSubmitClick = this.handleSubmitClick.bind(this);
        this.setSuccessMessage = this.setSuccessMessage.bind(this);
        this.addArzt = this.addArzt.bind(this);
        this.toggleArztModal = this.toggleArztModal.bind(this);
        this.toggleBSModal = this.toggleBSModal.bind(this);
        this.handleShowRecoveryCodeClick = this.handleShowRecoveryCodeClick.bind(this);
        this.closeRecoveryCodeClick = this.closeRecoveryCodeClick.bind(this);
        this.handleChangeBSClose = this.handleChangeBSClose.bind(this);
        this.handleAddBSClose = this.handleAddBSClose.bind(this);
        this.handleAddBS = this.handleAddBS.bind(this);
        this.handleSwitchBS = this.handleSwitchBS.bind(this);
    }

    componentDidMount() {
        const loadInstanceId: string = uuid.v4();
        const userService = new UserService();
        userService.getQRCode().then((qrCode) => {
            this.setState({qrCode: qrCode})
        }).catch((error: Failure) => {
            this.props.dispatch_failure(loadInstanceId, error)
        })
        userService.getBetriebsstaettenUebersicht().then((betriebsstaetten) => {
            this.setState({betriebsstaetten: betriebsstaetten})
        }).catch((error: Failure) => {
            this.props.dispatch_failure(loadInstanceId, error) // TODO: find out what goes wrong here, why isnt that a string
        })
        this.props.dispatch_getSubscriptions();
        if (this.props.isKimEnabled) {
            this.props.dispatch_getKimSettings();
            if (this.props.role === "DERMATOLOGE" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE") {
                this.props.dispatch_getDermatologistRegistrationStatus();
            }
            this.props.dispatch_getGdtConfig();
        }
    }

    setSuccessMessage(message: string): void {
        this.setState({
            successMessage: message
        });
    }

    handleSubmitClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
        const loadInstanceId: string = uuid.v4();
        e.preventDefault();
        this.setState({ submitted: true });
        if (this.props.newPassword !== "" && this.props.newPassword===this.props.newRepeatedPassword) {
            this.props.dispatch_changePassword(this.props.newPassword, loadInstanceId).then((recoveryCode) => {
                this.setSuccessMessage("Passwortänderung erfolgreich!");
                this.setState({
                    recoveryCode: recoveryCode.recoveryCode
                });
            }).catch((error) => {
                console.log(error)
            })
            this.props.clearFields();
        } else {
            const failure = new Failure();
            failure.error = "Passwortänderung fehlgeschlagen! Das neue Passwort darf nicht leer sein und muss mit seiner Wiederholung übereinstimmen.";
            this.props.dispatch_failure(loadInstanceId, failure);
        }
    }

    handleShowRecoveryCodeClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        const loadInstanceId: string = uuid.v4();
        e.preventDefault();
        this.setState({ isClickedRecoveryCode: true });
        const userService = new UserService();
        userService.getRecoveryCode().then((recoveryCode) => {
            this.setState({
                recoveryCode: recoveryCode.recoveryCode
            });
        }).catch((error) => {
            this.props.dispatch_failure(loadInstanceId, error);
        })
    }

    closeRecoveryCodeClick () {
        this.setState({
            isClickedRecoveryCode: false
        })
    }

    addArzt(): void {
        this.toggleArztModal(new Arzt(), true)
    }

    toggleArztModal(arzt: Arzt, isAdd: boolean): void {
        this.setState({
            arzt: arzt,
            isOpenArzt: !this.state.isOpenArzt,
            isAdd: isAdd
        })
    }

    toggleBSModal(): void {
        this.setState({
            isOpenBS: !this.state.isOpenBS
        })
    }

    handleChangeBSClose() {
        this.setState({
            isOpenChangeBS: false
        })
    }

    handleAddBSClose() {
        this.setState({
            isOpenAddBS: false
        })
    }

    handleAddBS() {
        const loadInstanceId: string = uuid.v4();
        const userService = new UserService();
        const nutzer: "HAUSARZT" | "DERMATOLOGE" = (this.props.role === "HAUSARZT" || this.props.role === "BETRIEBSSTAETTE_HAUSARZT")? "HAUSARZT": "DERMATOLOGE";
        userService.setInitialisierungBetriebsstaette(this.state.bsnr, this.state.lanr, this.props.initialisationStatus.standalone, nutzer).then((response) => {
            this.props.dispatch_reset();
            this.props.dispatch_login(this.state.bsnr, response.recoveryCode.substring(3,23), this.state.lanr, loadInstanceId)
            .catch(
                () => this.props.dispatch_logout()
            );
        }).catch((error: Failure) => {
            this.props.dispatch_failure(loadInstanceId, error) // TODO: find out what goes wrong here, why isnt that a string
        })
    }

    handleSwitchBS(event, bsnr: string) {
        this.props.dispatch_logout();
        // automatic login of the chosen BS
        const loadInstanceId: string = uuid.v4();
        const password = TelescanConfig.getInstance().getConstant("PASSWORD");
        localStorage.setItem("BSNR", bsnr)
        localStorage.setItem("LANR", "")
        this.props.dispatch_login(bsnr, password, null, loadInstanceId).catch(
            () => this.props.dispatch_logout()
        );
    }

    render() {
        const arzt_zertifikate_rows: ArztRow[] = this.props.basisdatenAerzte ? this.props.basisdatenAerzte.map((arzt, index) => {
            const zertifikat: ZertifikatMetainformation | undefined = (this.props.arztZertifikate.filter((z) => z.lanr === arzt.lanr).length === 1)? this.props.arztZertifikate.filter((z) => z.lanr === arzt.lanr)[0] : undefined;
            return {
                id: arzt.id,
                zertifikat: zertifikat,
                lanr: arzt.lanr || '-',
                inhaber: [arzt.titel, arzt.vorname, arzt.nachname].join(" ") || '-',//zertifikat?.inhaber || '-',
                arzt: arzt,
                arztIndex: index,
            } as ArztRow;
        }) : [];
        const bs_zertifikate_rows: BSInfoRow[] =  (this.props.basisdatenBetriebsstaette.id)?
            [{
                id: this.props.basisdatenBetriebsstaette.id || '',
                zertifikat: this.props.bsZertifikat,
                bsnr: this.props.basisdatenBetriebsstaette.bsnr || '-',
                name: this.props.basisdatenBetriebsstaette.name || '-',
                betriebsstaette: Betriebsstaette,
            } as BSInfoRow]
        : [];

        return (
            <Box className="main-content settings-content">
                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "right"}}
                    autoHideDuration={10000}
                    open={this.state.successMessage !== ""}
                    onClose={() => {this.setState({successMessage: ""})}}
                    >
                    <Alert severity="success">
                        {this.state.successMessage}
                    </Alert>
                </Snackbar>
                <Box className="settings-grid">
                    <form className="form-content password-container" noValidate autoComplete="off">
                        <Typography className="txt-header" variant="subtitle1" color="inherit">
                            Neues Passwort
                        </Typography>
                        <Paper className="set-password-card" elevation={0}>
                            <Typography variant="caption" color="inherit" >
                                Legen Sie ein neues Login-Passwort fest. Achtung: Das Passwort wird für alle Ärzte der Betriebsstätte geändert.
                            </Typography>
                            <FormControl component="fieldset">
                                <NewPasswordContainer variant="filled" setStrength={this.props.setStrength} newPassword={this.props.newPassword}
                                        newRepeatedPassword={this.props.newRepeatedPassword} handleNewPasswordChange={this.props.handleNewPasswordChange}
                                        handleNewRepeatedPasswordChange={this.props.handleNewRepeatedPasswordChange} context="LOGIN" />
                            </FormControl>
                            <Button disabled={this.props.newRepeatedPassword !== this.props.newPassword || this.props.passwordStrength !== "strong" || this.props.newPassword === "" || !this.props.newPassword} variant="contained" onClick={this.handleSubmitClick} >
                                Neues Passwort setzen
                            </Button>
                            {(this.state.submitted) &&
                            <form className="form-content" noValidate autoComplete="off">
                                <RecoveryCodeComponent isPasswordReset={true}
                                    recoveryCode={this.state.recoveryCode}
                                />
                            </form>}
                        </Paper>
                    </form>
                    {(this.props.role === "HAUSARZT" || this.props.role === "BETRIEBSSTAETTE_HAUSARZT") &&
                    <Box className="qr-login-container">
                        <Typography className="txt-header" variant="subtitle1" color="inherit">
                            QR-App-Login
                        </Typography>
                        <Paper elevation={0} className="qr-login">
                            <Box className="qr-image">
                                {!this.state.qrCode &&
                                <>
                                    <CircularProgress />
                                    <br/>
                                    <Typography variant="caption" align="center" color="inherit">Es konnte kein QR-Code erzeugt werden.</Typography>
                                </>}
                                {this.state.qrCode && <img src={this.state.qrCode} alt=""/>}
                            </Box>
                            <Typography variant="caption" color="inherit">Scannen Sie diesen QR-Code um sich schneller in der TeleScan-App anzumelden.</Typography>
                        </Paper>
                    </Box>
                    }
                    <form className="form-content recovery-code-container" noValidate autoComplete="off">
                        <Typography className="txt-header" variant="subtitle1" color="inherit">
                            Recovery-Code
                        </Typography>
                        <Paper className="set-password-card" elevation={0}>
                            {(!this.state.isClickedRecoveryCode) && <Button variant="outlined" size="small" color="primary" onClick={this.handleShowRecoveryCodeClick}>
                                Recovery-Code anzeigen
                            </Button>}
                            {(this.state.isClickedRecoveryCode) && <Button variant="contained" size="small" color="primary" onClick={this.closeRecoveryCodeClick}>
                                Recovery-Code ausblenden
                            </Button> }
                            {(this.state.isClickedRecoveryCode) &&
                            <form className="form-content" noValidate autoComplete="off">
                                <RecoveryCodeComponent isPasswordReset={false}
                                    recoveryCode={this.state.recoveryCode}
                                />
                            </form>}
                        </Paper>
                    </form>
                    <Box className="bsnr-info-container">
                        <Box className="arzt-info">
                            <Typography id="update-anchor" className="txt-header" variant="subtitle1" color="inherit">
                                Betriebsstätten-Informationen
                            </Typography>
                            <span >
                            <Tooltip title="Betriebsstätte neu anlegen">
                                <Button className="slim" variant="contained" color="primary" onClick={() => this.setState({
                                    isOpenAddBS: true
                                })}
                                >
                                    <Add/>
                                </Button>
                            </Tooltip>
                            {/* <Tooltip title="Betriebsstätte wechseln">
                                <Button style={{marginLeft: "8px"}} className="pad-left slim" variant="contained" color="primary" onClick={() => this.setState({
                                    isOpenChangeBS: true
                                })}
                                >
                                    <SyncAlt/>
                                </Button>
                            </Tooltip> */}
                            </span>
                        </Box>
                        <Paper elevation={0}>
                            <Typography className="bsnr-info" variant="caption" color="inherit">
                                Hier können Sie Ihre Betriebsstättenbasisdaten und Betriebsstättenzertifikate verwalten.
                            </Typography>
                            <BSInfoTableContainer betriebsstaette={this.props.basisdatenBetriebsstaette} isOpenBS={this.state.isOpenBS} toggleBSModal={this.toggleBSModal} data={bs_zertifikate_rows} />
                            <SimpleModalContainer isOpen={this.state.isOpenChangeBS}
                                onClose={this.handleChangeBSClose}
                            >
                                <Paper className="modal-wrapper add-new-konsil-pop">
                                <h2 className="modal-header">Betriebsstätte wechseln</h2>
                                    <Box className="modal-content" style={{paddingBottom: "32px"}}>
                                        <Box className="SearchResults">
                                        {this.state.betriebsstaetten.map((bs) =>
                                            <ListItem button key={bs.id}
                                                onClick={(event) => this.handleSwitchBS(event, bs.bsnr || "")}
                                            >
                                                <Box className="flex-column change-user-dialog-content">
                                                    <ListItemText primary={bs.bsnr}/>
                                                    <ListItemText primary={bs.name}/>
                                                </Box>
                                            </ListItem>
                                        )}
                                        </Box>
                                    </Box>
                                </Paper>
                            </SimpleModalContainer>
                            <SimpleModalContainer isOpen={this.state.isOpenAddBS}
                                onClose={this.handleAddBSClose}
                            >
                                <Paper className="modal-wrapper add-new-konsil-pop">
                                    <h2 className="modal-header">Betriebsstätte anlegen</h2>
                                    <Box className="modal-content row-space">
                                        <TextField
                                            error={this.state.bsnr.length > 0 && this.state.bsnr.length !== 9}
                                            id="bs-name"
                                            label="BSNR"
                                            value={this.state.bsnr || ""}
                                            onChange={(event) => {
                                                this.setState({
                                                    bsnr: event.target.value
                                                })}}
                                            autoComplete="off"
                                        />
                                        <TextField
                                            error={this.state.lanr.length > 0 && this.state.lanr.length !== 9}
                                            id="bs-lanr"
                                            label="LANR"
                                            value={this.state.lanr || ""}
                                            onChange={(event) => {
                                                this.setState({
                                                    lanr: event.target.value
                                                })}}
                                            autoComplete="off"
                                        />
                                    </Box>
                                    <Box className="modal-footer">
                                    <Button variant="text" onClick={() => {this.setState({isOpenAddBS: false})}} >
                                        Abbrechen
                                    </Button>
                                    <Button variant="contained" color="primary" disabled={this.state.bsnr.length !== 9 || this.state.lanr.length !== 9} onClick={this.handleAddBS}>
                                        Anlegen
                                    </Button>

                                    </Box>
                                </Paper>
                            </SimpleModalContainer>
                        </Paper>
                    </Box>
                    <Box className="bsnr-info-container">
                        <Box className="arzt-info">
                            <Typography className="txt-header" variant="subtitle1" color="inherit">
                            Arzt-Informationen
                            </Typography>
                            <Tooltip title="Arzt zur Betriebsstätte hinzufügen">
                                <Button className="slim" variant="contained" color="primary" onClick={() => this.addArzt()}>
                                    <Add/>
                                </Button>
                            </Tooltip>
                        </Box>
                        <Paper elevation={0}>
                            <Typography className="bsnr-info" variant="caption" color="inherit">
                                Hier können Sie Ihre Arztbasisdaten und Arztzertifikate verwalten, sowie neue Ärzte zur Betriebsstätte hinzufügen.
                            </Typography>
                            <ArztInfoTableContainer isAdd={this.state.isAdd} arzt={this.state.arzt} isOpenArzt={this.state.isOpenArzt} toggleArztModal={this.toggleArztModal} data={arzt_zertifikate_rows} />
                        </Paper>
                    </Box>
                    <InstallationUpdatesContainer/>
                    {this.props.isKimEnabled &&
                        <KimConfigurationContainer/>
                    }

                    {this.props.isKimEnabled && (this.props.role === "DERMATOLOGE" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE") &&
                        <DirectoryServiceContainer/>
                    }
                    {this.props.isKimEnabled &&
                        <GdtConfigurationComponentContainer/>
                    }

                    <Icd10Container/>
                    {new EnableNotifications().getConstant() &&
                         <NotificationControl
                            isNewsSubscribed = {this.props.isNewsSubscribed}
                            dispatch_subscribe = {this.props.dispatch_subscribe}
                            dispatch_unsubscribe = {this.props.dispatch_unsubscribe}
                            isSubscriptionLoading = {this.props.isSubscriptionLoading}
                            dispatch_getAllCurrentNotifications = {this.props.dispatch_getAllCurrentNotifications}
                            allNotifications = {this.props.allNotifications}
                            isAllNotifications = {this.props.isAllNotifications}
                            dispatch_setIsAllNotifications = {this.props.dispatch_setIsAllNotifications}
                            isNotificationsLoading = {this.props.isNotificationsLoading}
                     />
                    }
                    <SupportSettings />
                </Box>
            </Box>
        );
    }
}

export const UserSettingsContainer = connector(withNewPasswordMethods(UserSettings));
export default UserSettingsContainer;
