// Depricated. Functionality moved to redux actions.
import React from 'react';
import uuid from 'uuid';
import { KonsilService, KonsilStatusType, Failure } from 'telescan-core';


export interface WithPdfPreviewCreationProps {
    createPdfPreview: (konsilId: string, konsilStatus: keyof typeof KonsilStatusType) => Promise<any>;
    pdfPreviewData: any;
    resetPdfPreviewData: () => Promise<string>;
}

export function withPdfPreviewCreation(Component) {
    type Props = {
        dispatch_failure: (id: string, failure: Failure) => void;
    }
    type State = {
        pdfPreviewData: any;
    }

    // ...and returns another component...
    return class extends React.Component<Props, State> {
        constructor(props: Props) {
            super(props);
            this.state = {
                pdfPreviewData: undefined,
            };

            this.createPdfPreview = this.createPdfPreview.bind(this);
            this.resetPdfPreviewData = this.resetPdfPreviewData.bind(this);
        }


        private createPdfPreview(konsilId: string, konsilStatus: keyof typeof KonsilStatusType): Promise<any> {
            const loadInstanceId: string = uuid.v4();
            const konsilService = new KonsilService();
            return new Promise((resolve, reject) => {
                konsilService.getPdfPreview(konsilId || "", konsilStatus).then((data) => {
                    this.setState({
                        pdfPreviewData: data
                    })
                    resolve(data)
                }).catch((error) => {
                    this.props.dispatch_failure(loadInstanceId, error);
                    this.setState({
                        pdfPreviewData: ""
                    })
                })
            }) 
        }

        private resetPdfPreviewData(): Promise<string> {
            return new Promise((resolve) => 
                this.setState({
                    pdfPreviewData: undefined
                }, () => resolve("ok"))
            )
        }

        render() {
            return (
                <Component 
                    createPdfPreview={this.createPdfPreview}
                    pdfPreviewData={this.state.pdfPreviewData}
                    resetPdfPreviewData={this.resetPdfPreviewData}
                    {...(this.props as any)}
                />
            )
        }
    };
}