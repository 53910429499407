import { Notification } from 'telescan-core';

export enum ENotificationActions {
    SET_ALL_NOTIFICATIONS = "SET_ALL_NOTIFICATIONS",
    SET_IS_ALL_NOTIFICATIONS = "SET_IS_ALL_NOTIFICATIONS",
    SET_IS_NOTIFICATIONS_LOADING = "SET_IS_NOTIFICATIONS_LOADING",
    SET_IS_CRITICAL_NOTIFICATION = "SET_IS_CRITICAL_NOTIFICATION",
    SET_IS_WARNING_NOTIFICATION = "SET_IS_WARNING_NOTIFICATION",
    SET_IS_NORMAL_NOTIFICATION = "SET_IS_NORMAL_NOTIFICATION",
    SET_NOTIFICATIONS = "SET_NOTIFICATIONS",
    SET_IS_NOTIFICATION_CONFIRMED = "SET_IS_NOTIFICATION_CONFIRMED",
    SET_IS_NOTIFICATION_DISPLAYED = "SET_IS_NOTIFICATION_DISPLAYED",
    REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION",
    SET_IS_NEWS_SUBSCRIBED = "SET_IS_NEWS_SUBSCRIBED",
    SET_IS_SUBSCRIPTION_LOADING = "SET_IS_SUBSCRIPTION_LOADING"
}

export interface ISetAllNotifications {
    readonly type: ENotificationActions.SET_ALL_NOTIFICATIONS;
    readonly allNotifications: Notification[];
}

export interface ISetIsAllNotifications {
    readonly type: ENotificationActions.SET_IS_ALL_NOTIFICATIONS;
    readonly isAllNotifications: boolean;
}

export interface ISetIsNotificationsLoading {
    readonly type: ENotificationActions.SET_IS_NOTIFICATIONS_LOADING;
    readonly isNotificationsLoading: boolean;
}

export interface ISetIsCriticalNotification {
    readonly type: ENotificationActions.SET_IS_CRITICAL_NOTIFICATION;
    readonly isCriticalNotification: boolean;
}

export interface ISetIsWarningNotification {
    readonly type: ENotificationActions.SET_IS_WARNING_NOTIFICATION;
    readonly isWarningNotification: boolean;
}

export interface ISetIsNormalNotification {
    readonly type: ENotificationActions.SET_IS_NORMAL_NOTIFICATION;
    readonly isNormalNotification: boolean;
}

export interface ISetNotifications {
    readonly type: ENotificationActions.SET_NOTIFICATIONS;
    readonly notifications: Notification[];
}

export interface ISetIsNotificationConfirmed {
    readonly type: ENotificationActions.SET_IS_NOTIFICATION_CONFIRMED;
    readonly id: string;
    readonly isNotificationConfirmed: boolean;
}

export interface ISetIsNotificationDisplayed {
    readonly type: ENotificationActions.SET_IS_NOTIFICATION_DISPLAYED;
    readonly id: string;
    readonly isNotificationDisplayed: boolean;
}

export interface IRemoveNotification {
    readonly type: ENotificationActions.REMOVE_NOTIFICATION;
    readonly id: string;
}

export interface ISetIsNewsSubscribed {
    readonly type: ENotificationActions.SET_IS_NEWS_SUBSCRIBED;
    readonly isNewsSubscribed: boolean;
}

export interface ISetIsSubscriptionLoading {
    readonly type: ENotificationActions.SET_IS_SUBSCRIPTION_LOADING;
    readonly isSubscriptionLoading: boolean;
}

export type TNotificationActionTypes = 
    ISetAllNotifications |  
    ISetIsAllNotifications |
    ISetIsNotificationsLoading |
    ISetIsCriticalNotification |
    ISetIsWarningNotification |
    ISetIsNormalNotification |
    ISetNotifications |
    ISetIsNotificationConfirmed|
    ISetIsNotificationDisplayed |
    IRemoveNotification |
    ISetIsNewsSubscribed |
    ISetIsSubscriptionLoading;
