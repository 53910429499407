import React from 'react';
import {connect, ConnectedProps} from 'react-redux';
import uuid from 'uuid';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    IconButton,
    Box,
    Typography,
    Paper,
    CircularProgress
} from '@material-ui/core';
import { RootState } from '../../redux/reducers';
import { setSuccessMessage, failure } from '../../redux/actions';
import { Add, Delete } from '@material-ui/icons';
import {ICDService, Icd10Info, Failure} from 'telescan-core';
import { telescanFixable } from '../../utils';
import { getIcd10Info } from '../../redux/actions/icd10_actions';

interface IState {
    icd10Info: Icd10Info[]
}

interface IProps {
}

const mapStateToProps = (state: RootState, ownProps: IProps) => ({
    ...ownProps,
    icd10Info: state.icd10.icd10Info,
    clientId: state.webSocket.clientId,
})

const mapDispatchToProps = {
    dispatch_failure: (id: string, message: Failure) => failure(id, message),
    dispatch_successMessage: (id: string, message: string) => setSuccessMessage(id, message),
    dispatch_getIcd10Info: () => getIcd10Info(),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class Icd10 extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            icd10Info: []
        };
        this.handleClickInstallIcd10 = this.handleClickInstallIcd10.bind(this);
        this.handleClickDeleteIcd10 = this.handleClickDeleteIcd10.bind(this);
    }

    componentDidMount() {
        this.props.dispatch_getIcd10Info();
    }

    handleClickInstallIcd10(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string | undefined) {
        const loadInstanceId: string = uuid.v4();
        const icdService = new ICDService();
        if (id){

            icdService.installIcd10Katalog(id, this.props.clientId).then((eventId) => {
            }).catch((error) => {
                this.props.dispatch_failure(loadInstanceId, error);
            })
        } else {
            const failureObj = new Failure();
            failureObj.error = "Ungültiger Katalogidentifikator." + telescanFixable;
            this.props.dispatch_failure(loadInstanceId, failureObj);
        }
    }

    handleClickDeleteIcd10(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string | undefined) {
        const loadInstanceId: string = uuid.v4();
        const icdService = new ICDService();
        if (id){
            icdService.deleteIcd10Katalog(id, this.props.clientId).then((eventId) => {
            }).catch((error) => {
                this.props.dispatch_failure(loadInstanceId, error);
            })
        } else {
            const failure = new Failure();
            failure.error = "Ungültiger Katalogidentifikator." + telescanFixable;
            this.props.dispatch_failure(loadInstanceId, failure);
        }
    }

    render() {
        return (
            <>
            <Box className="bsnr-info-container">
                <Box className="arzt-info">
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                        ICD10-Kataloge
                    </Typography>
                </Box>
                <Paper elevation={0}>
                    <Typography className="bsnr-info" variant="caption" color="inherit">
                        Hier werden die aktuell installierten, bzw. verfügbaren ICD10-Kataloge angezeigt.
                    </Typography>
                    <Icd10Table data={this.props.icd10Info} handleClickInstallIcd10={this.handleClickInstallIcd10} handleClickDeleteIcd10={this.handleClickDeleteIcd10} />
                </Paper>
            </Box>
        </>
        );
    }
}

export const Icd10Container = connector(Icd10);
export default Icd10Container;

interface ITableProps {
    data: Icd10Info[],
    handleClickInstallIcd10: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string | undefined) => void;
    handleClickDeleteIcd10: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string | undefined) => void;
}

class Icd10Table extends React.Component<ITableProps> {

    render() {
        return (
            <TableContainer className="icd10-table">
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ICD10-Katalog</TableCell>
                            <TableCell>Version</TableCell>
                            <TableCell>Installiert</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.data.map(rowData => (
                        <TableRow key={`${rowData.oid}_${rowData.version?.replace(" ", "-") ?? ""}`}>
                            <TableCell>{rowData.oid}</TableCell>
                            <TableCell>{rowData.version}</TableCell>
                            <TableCell>
                                {(rowData.status === "NICHTINSTALLIERT") && "NEIN"}
                                {(rowData.status === "INSTALLIERT") && "JA"}
                                {(rowData.status === "WIRDINSTALLIERT") &&
                                    <span className="installation-icon">
                                        <CircularProgress/> Wird installiert
                                    </span>
                                }
                                {(rowData.status === "WIRDDEINSTALLIERT") &&
                                    <span className="installation-icon">
                                        <CircularProgress/> Wird deinstalliert
                                    </span>
                                }
                            </TableCell>
                            <TableCell align="right">
                                {(rowData.status === "NICHTINSTALLIERT") &&
                                <>
                                {/* <Tooltip title="Nicht Installiert" placement="top">
                                    <span className="up-to-date">
                                        <HighlightOff/>
                                    </span>
                                </Tooltip> */}
                                <Tooltip title="Hinzufügen" placement="top">
                                    <IconButton
                                        type="submit"
                                        size="small"
                                        className=""
                                        aria-label="Hinzufügen"
                                        onClick={(event) => this.props.handleClickInstallIcd10(event, rowData.identifier)}
                                    >
                                        <Add/>
                                    </IconButton>
                                </Tooltip>
                                </>
                                }
                                {(rowData.status === "INSTALLIERT") &&
                                <>
                                <Tooltip title="Löschen" placement="top">
                                    <IconButton
                                        type="submit"
                                        size="small"
                                        className=""
                                        aria-label="Löschen"
                                        onClick={(event) => this.props.handleClickDeleteIcd10(event, rowData.identifier)}
                                    >
                                        <Delete/>
                                    </IconButton>
                                </Tooltip>
                                </>
                                }
                            </TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}
