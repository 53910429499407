import React from 'react';
import uuid from 'uuid';
import { KonsilFruehereKonsile, KonsilService, Konsil, Failure } from 'telescan-core';


export interface WithLoadKonsileAsAttachmentsProps {
    oldKonsileAsAttachments: KonsilFruehereKonsile[];
}

export function withLoadKonsileAsAttachments(Component) {
    type Props = {
        currentKonsil: Konsil;
        dispatch_failure: (id: string, failure: Failure) => void;
    }
    type State = {
        oldKonsileAsAttachments: KonsilFruehereKonsile[];
    }

    // ...and returns another component...
    return class extends React.Component<Props, State> {
        constructor(props: Props) {
            super(props);
            this.state = {
                oldKonsileAsAttachments: [],
            };

            this.getKonsileAsAttachments = this.getKonsileAsAttachments.bind(this);
        }

        componentDidMount() {
            this.getKonsileAsAttachments();
        }

        getKonsileAsAttachments() {
            const loadInstanceIdArzt = uuid.v4();
            const konsilService = new KonsilService();
            konsilService.getAlteKonsile(this.props.currentKonsil.id || "").then((response) => {
                this.setState({
                    oldKonsileAsAttachments: response
                })
            }).catch((error) => {
                this.props.dispatch_failure(loadInstanceIdArzt, error);
            })
        }
    

        render() {
            return (
                <Component 
                    oldKonsileAsAttachments={this.state.oldKonsileAsAttachments}
                    {...(this.props as any)}
                />
            )
        }
    };
}
