import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    IconButton,
    Typography,
    Tooltip,
   } from '@material-ui/core';
import { Replay, SortByAlpha } from '@material-ui/icons';
import { Quartalsabrechnungen, QuartalsabrechnungUpdate, StatusQuartalsabrechnung} from 'telescan-core';
import { Delete } from '@material-ui/icons';

interface IProps {
    quartalBillsTableData: Quartalsabrechnungen[];
    quartalBillsUpdate: QuartalsabrechnungUpdate[];
    dispatch_getBill: (id: string) => void;
    dispatch_deleteBill: (id: string) => void;
    navigateQartalsAbrechnung: (id: string) => void;
    dispatch_updateQuartalsBill: (id: string) => void;
}

export default class QuartalBillsTableRender extends React.Component<IProps> {

    render() {
        return(
            <TableContainer className="bill-table-quartal">
            <Box className="mobile-table-header">
                <IconButton aria-label="delete">
                    <SortByAlpha />
                </IconButton>
            </Box>
            {this.props.quartalBillsTableData.length!==0 ?
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell>BSNR/ LANR/ Arzt</TableCell>
                        <TableCell>Jahr/ Quartal</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Abrechnungsdatum</TableCell>
                        <TableCell>Transfer ID</TableCell>
                        <TableCell>Konsilabrechnungen</TableCell>
                        <TableCell>Gesamtbetrag</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        this.props.quartalBillsTableData.map(row => {
                            const billsAbgerechnet: number = row.konsilabrechnung?.filter(item => item.status === "ABGERECHNET").length || 0;
                            const totalBills: number= row.konsilabrechnung?.length || 0;
                            const toProcessBills: number = totalBills - billsAbgerechnet;
                            return(
                                <TableRow
                                    key = {row.id}
                                    onClick = {() => {
                                        row.id && this.props.dispatch_getBill(row.id);
                                        row.id && this.props.navigateQartalsAbrechnung(row.id);
                                    }}
                                    className = {(row.konsilabrechnung && row.konsilabrechnung.filter(item => item.status !== "ABGERECHNET").length !== 0) ? "table-row bold-highlight" : "table-row"}
                                >
                                    <TableCell className="bill-abrechnender-arzt">
                                        {row.abrechnenderArzt?.bsnr || "-"}/ {row.abrechnenderArzt?.lanr || "-"}/ {row.abrechnenderArzt?.vorname || "-"} {row.abrechnenderArzt?.nachname || "-"}
                                    </TableCell>
                                    <TableCell className="bill-timepoint">
                                        {row.jahr || "-"}/ Q{row.quartal || "-"}
                                    </TableCell>
                                    <TableCell className="bill-status">
                                        {StatusQuartalsabrechnung[row.statusQuartalsabrechnung] || "-"}
                                    </TableCell>
                                    <TableCell className="bill-date">
                                        {row.datumAbrechnung ? new Date(row.datumAbrechnung).toLocaleString().split(",")[0] : "-"}
                                    </TableCell>
                                    <TableCell className="bill-id">
                                        {row.transferId ? row.transferId.slice(0, 8) : "-"}
                                    </TableCell>
                                    <TableCell className="bill-length">
                                        {row.statusQuartalsabrechnung === "ABGERECHNET" ?
                                            row.konsilabrechnung ? row.konsilabrechnung.length : "" :
                                            `+${toProcessBills} (${billsAbgerechnet}/${totalBills})`
                                        }
                                    </TableCell>
                                    <TableCell className="bill-price">
                                        {row.gesamtbetrag ? `${row.gesamtbetrag} €` : "-"}
                                    </TableCell>
                                    <TableCell align="right" className="bill-action">
                                        {row.statusQuartalsabrechnung !== "ABGERECHNET" &&
                                            <Box className = 'flex-row'>
                                                <Tooltip title = "Quartalsabrechnung aktualisieren" placement = 'top'>
                                                    <IconButton
                                                        aria-label="more"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            event.preventDefault();
                                                            this.props.dispatch_updateQuartalsBill(row.id || "");
                                                        }}
                                                        size="small"
                                                        className="icd-more margin-right">
                                                        <Replay/>
                                                    </IconButton>
                                                </Tooltip>
                                            <Tooltip title = "Quartalsabrechnung löschen" placement = 'top'>
                                                <IconButton
                                                        aria-label="more"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            event.preventDefault();
                                                            this.props.dispatch_deleteBill(row.id || "");
                                                        }}
                                                        size="small"
                                                        className="icd-more">
                                                        <Delete/>
                                                    </IconButton>
                                            </Tooltip>
                                            </Box>
                                        }
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>:
            <Box>
            <Typography
                paragraph = {true}
                className = 'pad'
            >
                Es wurde bisher keine Quartalsabrechnung erstellt. Eine neue Quartalsabrechnung erstellen Sie, indem Sie auf "neue Quartalsabrechnung erstellen" ('+' Symbol) klicken.
            </Typography>
        </Box>}
        </TableContainer>
        )
    }
}
