import React from 'react';
import {FormControl, MenuItem, Select} from '@material-ui/core';

interface IState {
    value: string
    options: any
}

interface IProps {
    options: any;
    onChange: (value) => void;
    value: string,
}

export class SelectInput extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            value: props.value,
            options: props.options || [],
        };
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps !== this.props) {
            this.setState({
                value: this.props.value,
                options: this.props.options || [],
            });
        }
    }

    render() {
        return (
            <FormControl>
                <Select
                    value={this.state.value}
                    onChange={(event) => {
                        this.setState({
                            value: event.target.value as string
                        });
                        this.props.onChange(event.target.value);
                    }}
                    displayEmpty>
                    {this.state.options.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }
}
