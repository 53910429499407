import React from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {RootState} from '../../../redux/reducers';
import { setCurrentQuartalsAbrechnungStatus, setIsKonsilFromBillingOpened, toggleInAbrechnungStatus} from '../../../redux/actions';
import { Box, Checkbox, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import { Forward, SortByAlpha } from '@material-ui/icons';
import { StatusAbrechnung, StatusQuartalsabrechnung } from 'telescan-core';
import { push } from 'connected-react-router';

const mapStateToProps = (state: RootState) => ({
    loading: state.general.loading,
    location: state.router.location.pathname,
    konsilAbrechnungen: state.quartalsabrechnungenStore.currentQuartalsabrechnung.konsilabrechnung,
    currentQuartalsAbrechnung: state.quartalsabrechnungenStore.currentQuartalsabrechnung,
    router: state.router,
})

const mapDispatchToProps = {
    dispatch_toggleStatus: (id: string, isChecked: boolean) => toggleInAbrechnungStatus(id, isChecked),
    dispatch_setCurrentQuartalsAbrechnungStatus: (status: keyof typeof StatusQuartalsabrechnung) => setCurrentQuartalsAbrechnungStatus(status),
    push: (path: string) => push(path),
    dispatch_setIsKonsilFromBillingOpened: (billId: string, isKonsilFromBillingOpened: boolean) => setIsKonsilFromBillingOpened(billId, isKonsilFromBillingOpened),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class SingleQuartalBillTable extends React.Component<TPropsFromRedux> {

    toggleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        const id = event.target.name;
        const isChecked = event.target.checked;
        this.props.dispatch_setCurrentQuartalsAbrechnungStatus("IN_ARBEIT");
        this.props.dispatch_toggleStatus(id, isChecked);
    }

    onRedirectToKonsil = (konsilId: string, billId: string) => {
        this.props.dispatch_setIsKonsilFromBillingOpened(billId, true);
        this.props.push(`/konsil/${konsilId}`);
    }

    getLatestSubmitDate = () => {
        let month: string;
        let year: string;
            if ( this.props.currentQuartalsAbrechnung.jahr && this.props.currentQuartalsAbrechnung.quartal === 4) {
                year = (this.props.currentQuartalsAbrechnung.jahr + 2).toString();
                month = "01";
                return (`${month}.${year}`);
            }
            else if (this.props.currentQuartalsAbrechnung.jahr && this.props.currentQuartalsAbrechnung.quartal === 1) {
                year = (this.props.currentQuartalsAbrechnung.jahr + 1).toString();
                month = "04";
                return (`${month}.${year}`);
            }
            else if (this.props.currentQuartalsAbrechnung.jahr && this.props.currentQuartalsAbrechnung.quartal === 2) {
                year = (this.props.currentQuartalsAbrechnung.jahr + 1).toString();
                month = "07";
                return (`${month}.${year}`);
            }
            else if (this.props.currentQuartalsAbrechnung.jahr && this.props.currentQuartalsAbrechnung.quartal === 3) {
                year = (this.props.currentQuartalsAbrechnung.jahr + 1).toString();
                month = "10";
                return (`${month}.${year}`);
            }
        
    }

    render() {
        return (this.props.loading.length === 0 &&
            <TableContainer className="bill-table">
            <Box className="mobile-table-header">
                <IconButton aria-label="delete">
                    <SortByAlpha />
                </IconButton>
            </Box>
            <Table className = 'margin-bottom-xl' stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Patient</TableCell>
                        <TableCell>Geb. Datum</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Transfer ID</TableCell>
                        <TableCell>Befundsdatum</TableCell>
                        <TableCell>Befund</TableCell>
                        <TableCell>Leistung/ Betrag</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {this.props.currentQuartalsAbrechnung.statusQuartalsabrechnung !== "ABGERECHNET" &&
                    this.props.konsilAbrechnungen && this.props.konsilAbrechnungen.length === 0 &&
                    <TableRow>
                            <TableCell colSpan = {9}>
                                <Typography className = 'pad'>
                                    In dieser Abrechnung sind für dieses Quartal noch keine Leistungen zu übermitteln.
                                </Typography>
                            </TableCell>
                        </TableRow>
                    }

                    {this.props.currentQuartalsAbrechnung.statusQuartalsabrechnung === "ABGERECHNET" &&
                    (this.props.konsilAbrechnungen && this.props.konsilAbrechnungen.filter(item => item.status === "ABGERECHNET").length !== 0) &&
                        <TableRow>
                            <TableCell colSpan = {9}>
                                <Typography className = 'pad'>
                                    In dieser Abrechnung sind für dieses Quartal folgende Leistungen neu übermittelt worden:
                                </Typography>
                            </TableCell>
                        </TableRow>
                    }

                    {this.props.currentQuartalsAbrechnung.statusQuartalsabrechnung === "ABGERECHNET" &&
                    (this.props.konsilAbrechnungen && this.props.konsilAbrechnungen.filter(item => item.status === "ABGERECHNET").length === 0) &&
                        <TableRow>
                            <TableCell colSpan = {9}>
                                <Typography className = 'pad'>
                                    In dieser Abrechnung sind für dieses Quartal keine neuen Leistungen übermittelt worden. Jedoch wurden alle bereits übertragenen Abrechnungen für dieses Quartal erneut versendet.
                                </Typography>
                            </TableCell>
                        </TableRow>
                    }

                    {
                        this.props.konsilAbrechnungen &&
                        this.props.konsilAbrechnungen.filter(item => item.status !== "ABGERECHNET")
                        .map((row) => {
                            return(
                                <TableRow
                                    className = {row.status === "ABGERECHNET" ? 'inactive table-row' : 'table-row'}
                                    key = {row.id}
                                >   
                                    <TableCell className="bill-check">
                                        <Checkbox
                                            disabled = {row.status === "ABGERECHNET" || this.props.currentQuartalsAbrechnung.isInProgress}
                                            name={row.id}
                                            checked = {row.inAbrechnung}
                                            onChange={this.toggleCheckBox}
                                        /> 
                                    </TableCell>
                                    <TableCell className="bill-patient">
                                        {row.patient?.vorname || "-"} {row.patient?.nachname || "-"}
                                    </TableCell>
                                    <TableCell className="bill-birthday">
                                        {row.patient?.geburtsdatum && 
                                            <>
                                                <span className="desktop-hide">Geburtstag:&nbsp;</span> 
                                                {new Date(row.patient?.geburtsdatum).toLocaleString().split(",")[0]}
                                            </>
                                        }
                                    </TableCell>
                                    <TableCell className="bill-status">
                                        {row.status ? StatusAbrechnung[row.status]: "-"}
                                    </TableCell>
                                    <TableCell className="bill-id">
                                        {row.transferId ? row.transferId.slice(0,8) : "-"}
                                    </TableCell>
                                    <TableCell className="bill-date">
                                        {row.datumLeistung && new Date(row.datumLeistung).toLocaleString().split(",")[0]}
                                    </TableCell>
                                    <TableCell className="bill-befund">
                                        {row.konsil && row.konsil.befund ?
                                            row.konsil.befund.length > 100 ?
                                            <Tooltip
                                                title = {<Typography variant = 'body2'>{row.konsil.befund}</Typography>}
                                            >
                                                <Typography>{`${row.konsil.befund.slice(0, 100 )} …`}</Typography>
                                            </Tooltip> :
                                            <Typography>{row.konsil.befund}</Typography> :
                                            "-"
                                        }
                                    </TableCell>
                                    <TableCell className="bill-price">
                                        {`${row.leistungsziffer || "-"}/ ${row.betrag || "-"} €`}
                                    </TableCell>
                                    <TableCell className = 'bill-action'>
                                        <Tooltip title = "Zum Konsil">
                                            <IconButton
                                                onClick = {() => this.onRedirectToKonsil(row.konsil?.id || "", this.props.currentQuartalsAbrechnung.id || "")}
                                            >
                                                <Forward fontSize = 'small'/>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }

                    {this.props.currentQuartalsAbrechnung.statusQuartalsabrechnung !== "ABGERECHNET" &&
                    (this.props.konsilAbrechnungen && this.props.konsilAbrechnungen.filter(item => item.status === "ABGERECHNET").length !== 0) &&
                    <TableRow>
                            <TableCell colSpan = {9}>
                                <Typography className = 'pad'>
                                    In diesem Quartal bereits abgerechnete Leistungen:
                                </Typography>
                            </TableCell>
                        </TableRow>
                    }

                    {
                        this.props.konsilAbrechnungen &&
                        this.props.konsilAbrechnungen.filter(item => item.status === "ABGERECHNET")
                        .map((row) => {
                            return(
                                <TableRow
                                    className = {row.status === "ABGERECHNET" ? 'inactive table-row' : 'table-row'}
                                    key = {row.id}
                                >   
                                    <TableCell className="bill-check">
                                        <Checkbox
                                            disabled = {row.status === "ABGERECHNET"}
                                            name={row.id}
                                            checked = {row.inAbrechnung}
                                            onChange={this.toggleCheckBox}
                                            className = "abrechnung-abgerechnet"
                                        /> 
                                    </TableCell>
                                    <TableCell className="bill-patient">
                                        {row.patient?.vorname || "-"} {row.patient?.nachname || "-"}
                                    </TableCell>
                                    <TableCell className="bill-birthday">
                                        {row.patient?.geburtsdatum && new Date(row.patient?.geburtsdatum).toLocaleString().split(",")[0]}
                                    </TableCell>
                                    <TableCell className="bill-status">
                                        {row.status ? StatusAbrechnung[row.status]: "-"}
                                    </TableCell>
                                    <TableCell className="bill-id">
                                        {row.transferId ? row.transferId.slice(0,8) : "-"}
                                    </TableCell>
                                    <TableCell className="bill-date">
                                        {row.datumLeistung && new Date(row.datumLeistung).toLocaleString().split(",")[0]}
                                    </TableCell>
                                    <TableCell className="bill-befund">
                                        {row.konsil && row.konsil.befund ?
                                            row.konsil.befund.length > 100 ?
                                            <Tooltip
                                                title = {<Typography variant = 'body2'>{row.konsil.befund}</Typography>}
                                            >
                                                <Typography>{`${row.konsil.befund.slice(0, 100)} …`}</Typography>
                                            </Tooltip> :
                                            <Typography>{row.konsil.befund}</Typography> :
                                            "-"
                                        }
                                    </TableCell>
                                    <TableCell className="bill-price">
                                        {`${row.leistungsziffer || "-"}/ ${row.betrag || "-"} €`}
                                    </TableCell>
                                    <TableCell className = 'bill-action'>
                                        <Tooltip title = "Zum Konsil">
                                            <IconButton
                                                onClick = {() => this.onRedirectToKonsil(row.konsil?.id || "", this.props.currentQuartalsAbrechnung.id || "")}
                                            >
                                                <Forward fontSize = 'small'/>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
                </Table>
                {this.props.currentQuartalsAbrechnung.statusQuartalsabrechnung !== "ABGERECHNET" &&
                <Box className = 'bill-notification'>
                    <Typography className = 'pad'>
                        {`Eine Übertragung der Quartalsabrechnung ist jederzeit möglich, jedoch spätestens zum 04.${this.getLatestSubmitDate()}.`}                      
                    </Typography>
                </Box>
            }
        </TableContainer>
        )
    }
}

export const SingleQuartalBillTableContainer = connector(SingleQuartalBillTable);
export default SingleQuartalBillTableContainer;


