import {Failure, Labor, Laborwertcode, LaborwertInterpratation } from "telescan-core";
import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormLabel,
    IconButton,
    Paper,
    TextField,
    Tooltip,
    Typography
} from "@material-ui/core";
import {Adjust, Delete, Edit} from "@material-ui/icons";
import {DatePicker} from "../../../elements/date_picker";
import {ViewText} from '../../viewComponents/view_text';
import SimpleModalContainer from "../../../elements/simple_modal";
import {Autocomplete, ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import uuid from 'uuid';

interface ILaborProps {
    labor: Labor;
    error: boolean;
    delete: (labor: Labor) => void;
    isViewOnly: boolean;
    onEditClick?: (event) => void;
    onViewClick?: (event) => void;
}

const mapAuffaelligkeit: Object = {
    N: <Adjust fontSize="small"/>,
    A: "!",
    AA: "!!",
    LU: "--",
    L: "-",
    LL: "--!",
    H: "+",
    HH: "++!",   
    HU: "++",
}

export class LaborComponent extends React.Component<ILaborProps> {
    render() {
        return <Paper
            className={"derma-diagnose-container" + (this.props.error ? " failure" : "") + (this.props.isViewOnly ? " view-only" : "")}
            key={"labor_" + this.props.labor.id}
            onClick={(this.props.isViewOnly) ? this.props.onViewClick : () => {
            }}
        >
            <Box className="flex-row-center">
                {/* <BarChart className="icon"/> */}
                <Typography variant="body1" color="inherit">
                    <span
                        className="bold">{this.props.labor.laborwertName}:</span> {" " + this.props.labor.messwert} {this.props.labor.einheit}
                </Typography>
                {(this.props.labor.interpretationName && !this.props.labor.interpretationCode) &&
                <Tooltip title={this.props.labor.interpretationName} placement="top">
                    <Typography variant="body1" color="inherit" className="medikation-dauermedikation">
                        {"\u2731"}
                    </Typography>
                </Tooltip>
                }
                {this.props.labor.interpretationCode &&
                <Tooltip title={LaborwertInterpratation[this.props.labor.interpretationCode]} placement="top">
                    <Typography variant="body1" color="inherit"
                                className={(this.props.labor.interpretationCode === "N") ? "laborwerte" : "laborwerte severe"}>
                        {mapAuffaelligkeit[this.props.labor.interpretationCode]}
                    </Typography>
                </Tooltip>
                }
                <Typography className="pad-left" variant="body1" color="inherit">
                    {"\u2022"}
                </Typography>
                <Typography className="pad-left" variant="body1" color="inherit">
                    {new Date(this.props.labor.datum).toLocaleDateString()}
                </Typography>
                {!this.props.isViewOnly &&
                <>
                    <Tooltip title="Bearbeiten" placement="top">
                        <IconButton className="edit-diagnose" aria-label="delete" size="small"
                                    onClick={this.props.onEditClick}>
                            <Edit/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Löschen" placement="top">
                        <IconButton className="delete-diagnose" size="small"
                                    aria-label="delete laborwert"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        this.props.delete(this.props.labor);
                                    }}>
                            <Delete/>
                        </IconButton>
                    </Tooltip>
                </>
                }
            </Box>
        </Paper>;
    }
}

interface ILaborEditProps {
    labor: Labor;
    open: boolean;
    add: boolean;
    callback: (labor: Labor | null, add: boolean) => void;
    laborwertCodes: Laborwertcode[];
    dispatch_failure: (failureId: string, failureObj: Failure) => void;
}

interface IState {
    labor: Labor;
    laborwertCode: Laborwertcode;
    laborwertCodeDisplayed: string;
    laborwertBezeichnungDisplayed: string;
}

export class LaborEditComponent extends React.Component<ILaborEditProps, IState> {
    constructor(props: ILaborEditProps) {
        super(props);
        this.state = {
            labor: props.labor,
            laborwertCode: {
                code: this.props.labor.laborwertCode,
                value: this.props.labor.laborwertName,
            },
            laborwertCodeDisplayed: this.props.labor.laborwertCode || "",
            laborwertBezeichnungDisplayed: this.props.labor.laborwertName || "",
        };
    }

    componentDidUpdate(prevProps: Readonly<ILaborEditProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.labor !== this.props.labor) {
            this.setState({
                labor: this.props.labor,
                laborwertCode: {
                    code: this.props.labor.laborwertCode,
                    value: this.props.labor.laborwertName,
                },
            });
        }
        if (prevProps.laborwertCodes !== this.props.laborwertCodes) {
            this.setState({
                laborwertCodeDisplayed: this.props.labor.laborwertCode || "",
                laborwertBezeichnungDisplayed: this.props.labor.laborwertName || "",
            })
        }
    }

    handleSubmit = () => {
        if (!this.state.laborwertCode.code || this.state.laborwertCode.code === "" || !this.state.laborwertCode.value || this.state.laborwertCode.value === "") {
            const failureId: string = uuid.v4();
            const failure = new Failure();
            failure.error = "Bitte geben Sie einen Laborcode und eine Laborbezeichnung des Laborwertes ein.";
            this.props.dispatch_failure(failureId, failure);
            return;
        } else if (!this.state.labor.messwert || this.state.labor.messwert === "" || !this.isEnteredMeasurementValueValid()) {
            const failureId: string = uuid.v4();
            const failure = new Failure();
            failure.error = "Bitte geben Sie einen gültigen Messwert ein.";
            this.props.dispatch_failure(failureId, failure);
            return;
        } else if (!this.state.labor.einheit || this.state.labor.einheit === "") {
            const failureId: string = uuid.v4();
            const failure = new Failure();
            failure.error = "Bitte geben Sie eine Einheit des Laborwertes ein.";
            this.props.dispatch_failure(failureId, failure);
            return;
        } else if (!this.state.labor.datum) {
            const failureId: string = uuid.v4();
            const failure = new Failure();
            failure.error = "Bitte geben Sie ein gültiges Datum des Laborwertes ein.";
            this.props.dispatch_failure(failureId, failure);
            return;
        } else
            this.props.callback(this.state.labor, this.props.add);
    }

    isEnteredMeasurementValueValid = () => {
        const regex = /^\d+\.?\d{0,3}$/ // string corresponding to positive integer or decimal number up to 3 digits after dot
        if (this.state.labor.messwert && regex.test(this.state.labor.messwert))
            return true;
        else return false;
    }

    render() {
        return (
            <SimpleModalContainer isOpen={this.props.open} additionalClassname="modal-add-data">
                <Paper className="modal-wrapper">
                    <h2 className="modal-header">{this.props.add ? "Laborwert hinzufügen" : "Laborwert bearbeiten"}</h2>
                    <Box className="modal-content">
                        <form className="add-form lab-form" noValidate autoComplete="off">
                            <Typography variant = 'body2' color = 'error'>
                                Achtung: Werden für diesen Patienten neue Laborwerte aus dem PVS übertragen, werden manuell erfasste Laborwerte überschrieben, solange sich der Konsilauftrag “in Arbeit” befindet.
                            </Typography>
                            <Autocomplete
                                id="labor_code"
                                options={this.props.laborwertCodes}
                                getOptionLabel={(option) => option.code || ""}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Laborcode *"
                                        error={this.state.labor.laborwertCode == null || this.state.labor.laborwertCode === ""}
                                    />
                                }
                                value = {this.state.laborwertCode || null}
                                onChange={(event: any, newValue: Laborwertcode | null) => this.setState({
                                    laborwertCode: newValue || new Laborwertcode(),
                                    labor: {
                                        ...this.state.labor,
                                        laborwertCode: newValue?.code,
                                        laborwertName: newValue?.value
                                    }
                                })}
                            />

                             <Autocomplete
                                id="abor_wert"
                                options={this.props.laborwertCodes}
                                getOptionLabel={(option) => option.value || ""}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Laborbezeichnung *"
                                        error={this.state.labor.laborwertName == null || this.state.labor.laborwertName === ""}
                                    />
                                }
                                value = {this.state.laborwertCode || null}
                                onChange={(event: any, newValue: Laborwertcode | null) => this.setState({
                                    laborwertCode: newValue || new Laborwertcode(),
                                    labor: {
                                        ...this.state.labor,
                                        laborwertCode: newValue?.code,
                                        laborwertName: newValue?.value,
                                    }
                                })}
                            />
                            <Box className="combo-ipt-num-unit">
                                <TextField
                                    id="labor_messwert"
                                    className="number"
                                    placeholder="Nummer"
                                    label="Messwert *"
                                    multiline
                                    minRows = {1}
                                    maxRows={4}
                                    value={this.state.labor.messwert || ""}
                                    onChange={(event) => this.setState({
                                        labor: {
                                            ...this.state.labor,
                                            messwert: event.target.value.replace(",", ".")
                                        }
                                    })}
                                    error={this.state.labor.messwert == null || this.state.labor.messwert === "" || !this.isEnteredMeasurementValueValid()}
                                />
                                <TextField
                                    id="labor_einheit"
                                    className="unit"
                                    placeholder="z.B.: mg"
                                    label="Einheit *"
                                    multiline
                                    minRows = {1}
                                    maxRows={4}
                                    value={this.state.labor.einheit || ""}
                                    onChange={(event) => this.setState({
                                        labor: {
                                            ...this.state.labor,
                                            einheit: event.target.value
                                        }
                                    })}
                                    error={this.state.labor.einheit == null || this.state.labor.einheit === ""}
                                />
                            </Box>
                            <FormControl>
                                <FormLabel component="legend">Auffälligkeit</FormLabel>
                                <ToggleButtonGroup color="primary" className="btn-group-select pad-top"
                                                   aria-label="outlined primary button group"
                                                   exclusive
                                                   onChange={(event, value) => {
                                                       if (Object.keys(LaborwertInterpratation).includes(value || ""))
                                                           this.setState({
                                                               labor: {
                                                                   ...this.state.labor,
                                                                   interpretationCode: value,
                                                               }
                                                           })
                                                       else
                                                           this.setState({
                                                               labor: {
                                                                   ...this.state.labor,
                                                                   interpretationName: value
                                                               }
                                                           })
                                                   }}
                                >
                                    {Object.keys(LaborwertInterpratation).map(key =>
                                        <Tooltip key={key} title={LaborwertInterpratation[key]} placement="top">
                                            <ToggleButton
                                                value={key}
                                                id={"diagnoseSicherheit_" + key}
                                                className={(this.state.labor.interpretationCode === key) ? 'active' : ''}
                                            >
                                                {mapAuffaelligkeit[key]}
                                            </ToggleButton>
                                        </Tooltip>
                                    )}
                                    {this.state.labor.interpretationName &&
                                    <Tooltip key="interpret-name" title={this.state.labor.interpretationName || ""}
                                             placement="top">
                                        <ToggleButton
                                            value={this.state.labor.interpretationName}
                                            id={"diagnoseSicherheit_interpret-name"}
                                            className={Object.keys(LaborwertInterpratation).includes(this.state.labor.interpretationCode || "") ? '' : 'active'}
                                        >
                                            {"\u2731"}
                                        </ToggleButton>
                                    </Tooltip>
                                    }
                                </ToggleButtonGroup>
                            </FormControl>

                            <DatePicker
                                isRequired = {true}
                                label="Datum"
                                id="labor_date"
                                inputLabelProps={{}}
                                hasError={true}
                                initialDate={new Date(this.state.labor.datum)}
                                onChange={(date: Date) => this.setState({
                                    labor: {
                                        ...this.state.labor,
                                        datum: date?.getTime()
                                    }
                                })}
                            />
                        </form>
                    </Box>
                    <Box className="modal-footer">
                        <Button variant="text" color="primary" onClick={() => {
                            this.setState({ // set state back to previous if "Abbrechnen" is clicked
                                labor: this.props.labor
                            })
                            this.props.callback(null, this.props.add);
                        }}>
                            Abbrechen
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick = {this.handleSubmit}
                        >
                            {this.props.add ? "Hinzufügen" : "Speichern"}
                        </Button>
                    </Box>
                </Paper>
            </SimpleModalContainer>
        )
    }
}


interface ILaborViewProps {
    openView: boolean;
    labor: Labor;
    handleClose: () => void;
}

interface ILaborViewState {
    openView: boolean;
}

export class LaborViewComponent extends React.Component<ILaborViewProps, ILaborViewState> {
    constructor(props: ILaborViewProps) {
        super(props);
        this.state = {
            openView: props.openView
        };
    }

    componentDidUpdate(prevProps: Readonly<ILaborViewProps>, prevState: Readonly<ILaborViewState>) {
        if (prevState.openView !== this.props.openView) {
            this.setState({
                openView: this.props.openView
            });
        }
    }

    render() {
        return (
            <SimpleModalContainer isOpen={this.state.openView} additionalClassname="modal-add-data">
                <Paper className="modal-wrapper view-labor">
                    <h2 className="modal-header">Laborwert</h2>
                    <Box className="modal-content data-content">
                        <Box className="txt-grid">
                            <ViewText
                                labelVariant='caption'
                                label='Laborwert'
                                text={this.props.labor.laborwertName || "-"}
                            />
                            <ViewText
                                labelVariant='caption'
                                label='Messwert und Einheit'
                                text={(this.props.labor.messwert || "-") + " " + (this.props.labor.einheit || "-")}
                            />
                            <ViewText
                                labelVariant='caption'
                                label='Auffälligkeit'
                                text={this.props.labor.interpretationCode ? LaborwertInterpratation[this.props.labor.interpretationCode] : "-"}
                            />
                            <ViewText
                                labelVariant='caption'
                                label='Datum'
                                text={new Date(this.props.labor.datum).toLocaleDateString() || "-"}
                            />
                        </Box>
                    </Box>
                    <Box className="modal-footer">
                        <Button variant="text" color="primary" onClick={() => this.props.handleClose()}>
                            Schließen
                        </Button>
                    </Box>
                </Paper>
            </SimpleModalContainer>
        )
    }
}

interface ILaborTextViewProps {
    openView: boolean;
    laborSdTextHtml: string;
    handleClose: () => void;
}

export class LaborViewTextComponent extends React.Component<ILaborTextViewProps> {
    render() {
        return (
            <div>
                <Dialog
                    open={this.props.openView}
                    onClose={this.props.handleClose}
                    scroll="paper"
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description">
                    <DialogTitle id="scroll-dialog-title">Unstrukturierte Laborwerte</DialogTitle>
                    <DialogContent dividers={true}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}>
                            <div dangerouslySetInnerHTML={{__html: this.props.laborSdTextHtml}}/>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleClose} color="primary">
                            Schließen
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
