import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { Failure, GdtConfiguration, GdtService, Patient, } from "telescan-core";
import { RootState } from "../reducers";
import * as GdtTypes from '../types/gdt_types';
import { failure, setSuccessMessage } from "./general_actions";
import uuid from 'uuid';

//pure actions
export function setGdtConfig(gdtConfig: GdtConfiguration): GdtTypes.ISetGdtConfiguration {
    return {
        type: GdtTypes.EGdtActions.SET_GDT_CONFIGURATION,
        gdtConfig: gdtConfig
    }
}

export function setGdtPatients(gdtPatients: Patient[]): GdtTypes.ISetGdtPatients {
    return {
        type: GdtTypes.EGdtActions.SET_GDT_PATIENTS,
        gdtPatients: gdtPatients
    }
}


//thunk middleware functions
export function getGdtConfig(): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        new GdtService().getGdtConfig()
        .then((response: GdtConfiguration) => {
            dispatch(setGdtConfig(response));
        })
        .catch((failureObj: Failure) => {
            dispatch(failure(uuid.v4(), failureObj));
        }) 
    }
}

export function saveGdtConfig(gdtConfig: GdtConfiguration): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        new GdtService().saveGdtConfig(gdtConfig)
        .then((response: GdtConfiguration) => {
            dispatch(setGdtConfig(response));
        })
        .catch((failureObj: Failure) => {
            dispatch(failure(uuid.v4(), failureObj));
        }) 
    }
}

export function startGdtWatcher(): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        new GdtService().startGdtWatcher()
        .then((response: GdtConfiguration) => {
            dispatch(setGdtConfig(response));
        })
        .catch((failureObj: Failure) => {
            dispatch(failure(uuid.v4(), failureObj));
        }) 
    }
}

export function stopGdtWatcher(): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        new GdtService().stopGdtWatcher()
        .then((response: GdtConfiguration) => {
            dispatch(setGdtConfig(response));
        })
        .catch((failureObj: Failure) => {
            dispatch(failure(uuid.v4(), failureObj));
        }) 
    }
}

export function getAllGdtPatients(): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        new GdtService().getAllGdtPatients()
        .then((response: Patient[]) => {
            dispatch(setGdtPatients(response));
        })
        .catch((failureObj: Failure) => {
            dispatch(failure(uuid.v4(), failureObj));
        }) 
    }
}

export function acknowledgeGdtPatient(id: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        new GdtService().acknowledgeGdtPatient(id)
        .then(() => {
            dispatch(getAllGdtPatients());
        })
        .catch((failureObj: Failure) => {
            dispatch(failure(uuid.v4(), failureObj));
        }) 
    }
}

export function acknowledgeAllGdtPatients(): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        new GdtService().acknowledgeAllGdtPatients()
        .then(() => {
            dispatch(getAllGdtPatients());
        })
        .catch((failureObj: Failure) => {
            dispatch(failure(uuid.v4(), failureObj));
        }) 
    }
}

export function gdtExport(id: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return dispatch => {
        new GdtService().gdtExport(id)
        .then(() => {
            dispatch(setSuccessMessage(uuid.v4(), "Die Daten wurden erfolgreich exportiert"));
        })
        .catch((failureObj: Failure) => {
            dispatch(failure(uuid.v4(), failureObj));
        }) 
    }
}

