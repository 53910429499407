import React from 'react';

// Material components
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import Box from '@material-ui/core/Box';
import SearchIcon from "@material-ui/icons/Search";
import { Tooltip } from '@material-ui/core';


interface HeaderSearchProps {
    dispatch_searchKonsile: (searchString: string) => void,
    searchString: string,
}

export class HeaderSearch extends React.Component<HeaderSearchProps> {

    render() {
        return (
            <div className = 'search'>
                <Box className="search-container">
                    <IconButton
                        type="submit"
                        size="small"
                        className=""
                        aria-label="search"
                        onClick = {() => this.props.dispatch_searchKonsile(this.props.searchString)}
                    >
                        <Tooltip title = 'Suchen'><SearchIcon /></Tooltip>                 
                    </IconButton>
                    <InputBase
                        className="search-input"
                        placeholder="Suche"
                        value = {this.props.searchString}
                        inputProps={{ "aria-label": "Suche" }}
                        onChange = {event => this.props.dispatch_searchKonsile(event.target.value)}   
                        onKeyPress = {(event) => {
                            if (event.key === "Enter")
                                this.props.dispatch_searchKonsile(this.props.searchString)
                        }}
                    />
                </Box>
            </div>
        );
    }
}
