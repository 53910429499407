import { ENotificationActions, TNotificationActionTypes } from "../types/notification_types";
import { Notification } from 'telescan-core';

export interface INotificationState {
    allNotifications: Notification[];
    isAllNotifications: boolean;
    isNotificationsLoading: boolean;
    isCriticalNotification: boolean;
    isWarningNotification: boolean;
    isNormalNotification: boolean;
    notifications: Notification[];
    isNewsSubscribed: boolean | null;
    isSubscriptionLoading: boolean;
}

const initialState: INotificationState = {
    allNotifications: [],
    isAllNotifications: false,
    isNotificationsLoading: false,
    isCriticalNotification: false,
    isWarningNotification: false,
    isNormalNotification: false,
    notifications: [],
    isNewsSubscribed: null,
    isSubscriptionLoading: false,
}

export function notification_reducer(state = initialState, action: TNotificationActionTypes): INotificationState {
    switch (action.type) {
        case ENotificationActions.SET_ALL_NOTIFICATIONS:
            return {
                ...state,
                allNotifications: action.allNotifications
            }
            
        case ENotificationActions.SET_IS_ALL_NOTIFICATIONS:
            return {
                ...state,
                isAllNotifications: action.isAllNotifications
            }

        case ENotificationActions.SET_IS_NOTIFICATIONS_LOADING:
            return {
                ...state,
                isNotificationsLoading: action.isNotificationsLoading
            }
            
        case ENotificationActions.SET_IS_CRITICAL_NOTIFICATION:
            return {
                ...state,
                isCriticalNotification: action.isCriticalNotification
            }

        case ENotificationActions.SET_IS_WARNING_NOTIFICATION:
            return {
                ...state,
                isWarningNotification: action.isWarningNotification
            }

        case ENotificationActions.SET_IS_NORMAL_NOTIFICATION:
            return {
                ...state,
                isNormalNotification: action.isNormalNotification
            }

        case ENotificationActions.SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.notifications
            }

        case ENotificationActions.SET_IS_NOTIFICATION_CONFIRMED:
            const notificationIndex = state.notifications.findIndex(item => item.id === action.id);
            const newNotificationArray = [...state.notifications];
            newNotificationArray[notificationIndex].isNotificationConfirmed = action.isNotificationConfirmed;
            return {
                ...state,
                notifications: newNotificationArray
            }

        case ENotificationActions.SET_IS_NOTIFICATION_DISPLAYED:
            const notificationIndexForDisplayed = state.notifications.findIndex(item => item.id === action.id);
            const newNotificationArrayForDisplayed = [...state.notifications];
            newNotificationArrayForDisplayed[notificationIndexForDisplayed].isNotificationDisplayed = action.isNotificationDisplayed;
            return {
                ...state,
                notifications: newNotificationArrayForDisplayed
            }

        case ENotificationActions.REMOVE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter(notification => notification.id !== action.id)
            }

        case ENotificationActions.SET_IS_NEWS_SUBSCRIBED:
            return {
                ...state,
                isNewsSubscribed: action.isNewsSubscribed
            }
        
        case ENotificationActions.SET_IS_SUBSCRIPTION_LOADING:
            return {
                ...state,
                isSubscriptionLoading: action.isSubscriptionLoading
            }
            
        default:
            return state
    }
}
