import React from 'react';
import {Box} from '@material-ui/core';
import {RootState} from "../../../redux/reducers";
import {connect, ConnectedProps} from "react-redux";
import ViewKonsiliarberichtContainer from './view_konsiliarbericht';
import ViewFragestellungContentContainer from './view_fragestellungcontent';

const mapStateToProps = (state: RootState) => ({
    role: state.user.role
})

const mapDispatchToProps = {}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class ViewFragestellung extends React.Component<TPropsFromRedux> {

    render() {
        return (
            <Box className="splittscreen">
                <Box className="scroll-box-fragestellung">
                    <ViewFragestellungContentContainer/>
                </Box>
                <Box className="split-konsil">
                    <ViewKonsiliarberichtContainer hasSideView={true}/>
                </Box>
            </Box>
        );
    }
}

export const ViewFragestellungContainer = connector(ViewFragestellung);
export default ViewFragestellungContainer;
