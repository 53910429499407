import { Paper, Box, TextField, Button, FormControl, InputLabel, MenuItem, Select, Checkbox, FormControlLabel, InputAdornment, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import { GdtConfiguration, GDTVersion, RoleTypes } from "telescan-core";
import SimpleModalContainer from "../../elements/simple_modal";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

interface IState {
    isSelect: boolean;
}

interface IProps {
    isOpen: boolean;
    handleClose: () => void;
    gdtConfig: GdtConfiguration;
    onChangeSelect: (event: React.ChangeEvent<{ value: unknown }>) => void;
    onChangeCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSaveButtonClick: () => void;
    isGdtRunning: boolean | undefined;
    role: keyof typeof RoleTypes;
}
export default class GdtEditPopup extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            isSelect: false,
        }
    }

    handleOpenSelect = () => {
        this.setState({isSelect: true});
    }

    handleCloseSelect = () => {
        this.setState({isSelect: false});
    }

    isRegexValid = (): boolean => {
        try {
            this.props.gdtConfig.importFilename && new RegExp(this.props.gdtConfig.importFilename);
            return true;
        } catch {
            return false
        }
    }

    render() {
        return (
            <SimpleModalContainer isOpen={this.props.isOpen}>
                <Paper className="modal-wrapper settings-arzt-pop">
                    <h2 className="modal-header flex-row-center space-between">
                        GDT-Konfiguration verwalten
                    </h2>
                    <Box className="modal-content">
                        <Box className="initialisation-content popup-insert">
                            <Box className="bs-infos-card-1column">
                                {(this.props.role === "HAUSARZT" || this.props.role === "BETRIEBSSTAETTE_HAUSARZT") &&
                                    <TextField
                                        id = "directory"
                                        label = "Importordner"
                                        value = {this.props.gdtConfig.directory || ""}
                                        name = "directory"
                                        autoComplete="off"
                                        onChange = {this.props.onChangeInput}
                                        fullWidth
                                        error = {!this.props.gdtConfig.directory || this.props.gdtConfig.directory === ""}
                                        disabled = {this.props.isGdtRunning}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <Tooltip title = "Sie benötigen System-Zugriffsrechte auf den ausgewählten Ordner." placement = "top">
                                                        <InfoOutlinedIcon fontSize="small"/>
                                                    </Tooltip>
                                                    
                                                </InputAdornment>,
                                        }}
                                        required
                                    />
                                }
                               
                                <TextField
                                    required = {this.props.role === "DERMATOLOGE" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE"}
                                    id = "exportDirectory"
                                    label = "Exportordner"
                                    value = {this.props.gdtConfig.exportDirectory}
                                    name = "exportDirectory"
                                    autoComplete="off"
                                    onChange = {this.props.onChangeInput}
                                    fullWidth
                                    //error = {!this.props.gdtConfig.exportDirectory || this.props.gdtConfig.exportDirectory === ""}
                                    disabled = {this.props.isGdtRunning}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <Tooltip
                                                    title = {
                                                        (this.props.role === "DERMATOLOGE" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE") ? 
                                                        "Sie benötigen System-Zugriffsrechte auf den ausgewählten Ordner."
                                                        : 
                                                        "Wenn nicht angegeben, wird der Importordner verwendet."
                                                    }
                                                    placement = "top">
                                                    <InfoOutlinedIcon fontSize="small"/>
                                                </Tooltip>
                                                
                                            </InputAdornment>,
                                    }}
                                    error = {(this.props.role === "DERMATOLOGE" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE") ? (!this.props.gdtConfig.exportDirectory || this.props.gdtConfig.exportDirectory === "") : false}
                                />
                                 <TextField
                                    id = "attachmentDirectory"
                                    label = "Ordner für Anhänge"
                                    value = {this.props.gdtConfig.attachmentDirectory || ""}
                                    name = "attachmentDirectory"
                                    autoComplete="off"
                                    onChange = {this.props.onChangeInput}
                                    fullWidth
                                    error = {this.props.gdtConfig.attachmentDirectory ? this.props.gdtConfig.attachmentDirectory === "" : false}
                                    disabled = {this.props.isGdtRunning}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <Tooltip
                                                    title = {(this.props.role === "DERMATOLOGE" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE") ? "Wenn nicht angegeben, wird der Exportordner verwendet." : "Wenn nicht angegeben, wird der Importordner verwendet."}
                                                    placement = "top"
                                                >
                                                    <InfoOutlinedIcon fontSize="small"/>
                                                </Tooltip>
                                                
                                            </InputAdornment>,
                                    }}
                                />
                                {(this.props.role === "HAUSARZT" || this.props.role === "BETRIEBSSTAETTE_HAUSARZT") &&
                                    <Box>
                                        <TextField
                                            id = "importFilename"
                                            label = "Dateiname des GDT-Import"
                                            value = {this.props.gdtConfig.importFilename || ""}
                                            name = "importFilename"
                                            autoComplete="off"
                                            onChange = {this.props.onChangeInput}
                                            fullWidth
                                            disabled = {this.props.isGdtRunning}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <Tooltip
                                                            title = {
                                                                <Box>
                                                                    <Typography variant="inherit">
                                                                        Wenn nicht angegeben, werden alle Dateien unabhängig vom Dateinamen eingelesen, sofern es eine GDT-Datei ist.
                                                                    </Typography>
                                                                    <br/>
                                                                    <Typography variant="inherit">
                                                                        Oder Sie können einen Regex angeben, dafür mussen Sie die Checkbox unten auswählen. 
                                                                    </Typography>
                                                                    <br/>
                                                                    <Typography variant="inherit">
                                                                        Z.b.: ^telescan.*$ liest alle Dateien ein, die mit "telescan" beginnen. 
                                                                    </Typography>
                                                                </Box>                                                        
                                                            }
                                                            placement = "top"
                                                        >
                                                            <InfoOutlinedIcon fontSize="small"/>
                                                        </Tooltip>
                                                        
                                                    </InputAdornment>,
                                            }}
                                            helperText = {this.props.gdtConfig.importFileByRegex && !this.isRegexValid() && "Ungültiger Regex"}
                                            error = {this.props.gdtConfig.importFileByRegex ? (!this.props.gdtConfig.importFilename || this.props.gdtConfig.importFilename === "" || !this.isRegexValid()) : false}
                                        />
                                        <FormControlLabel
                                            disabled = {this.props.isGdtRunning}
                                            control={
                                                <Checkbox
                                                    checked={this.props.gdtConfig.importFileByRegex}
                                                    onChange={this.props.onChangeCheckbox}
                                                    name="importFileByRegex"
                                                    color="primary"
                                                />
                                            }
                                            label="REGEX verwenden"
                                        />               
                                    </Box>
                                }
                               
                                <TextField
                                    id = "exportFilename"
                                    label = "Dateiname des GDT-Export"
                                    value = {this.props.gdtConfig.exportFilename || ""}
                                    name = "exportFilename"
                                    autoComplete="off"
                                    onChange = {this.props.onChangeInput}
                                    fullWidth
                                    disabled = {this.props.isGdtRunning}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <Tooltip title = {
                                                    <Box>
                                                        <Typography variant="inherit">
                                                            {`Wenn nicht angegeben, wird <GDT-ID Telescan><GDT-ID PVS>-gdt verwendet.`} 
                                                        </Typography>
                                                        <br/>
                                                        <Typography variant="inherit">
                                                            {(this.props.role === "DERMATOLOGE" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE") ?
                                                                "Sie können auch den Namen der exportierten Dateien nach folgenden Mustern konfigurieren: $vorname, $nachname, $datum. Z.b.: $vorname_$nachname_$datum_Telekonsil."
                                                            : 
                                                                "Sie können auch den Namen der exportierten Dateien nach folgenden Mustern konfigurieren: $vorname, $nachname, $datum, $pn. Z.b.: $pn_$vorname_$nachname_$datum_Telekonsil."   
                                                            }                              
                                                        </Typography>                                                
                                                    </Box>
                                                }>
                                                    <InfoOutlinedIcon fontSize="small"/>
                                                </Tooltip>
                                                
                                            </InputAdornment>
                                    }}
                                />
                               
                                <Box className = "pad-top pad-bottom">
                                    <FormControl
                                        fullWidth
                                        error = {!this.props.gdtConfig.gdtVersion}
                                        disabled = {this.props.isGdtRunning}
                                    >
                                        <InputLabel id = "simple-select-label">GDT-Version für Export</InputLabel>
                                        <Select
                                            variant = "standard"
                                            labelId = "simple-select-label"
                                            id = "gdtVersion"
                                            value = {this.props.gdtConfig.gdtVersion ? this.props.gdtConfig.gdtVersion : ""}
                                            label="GDT-Version für Export"
                                            open = {this.state.isSelect}
                                            onOpen = {this.handleOpenSelect}
                                            onClose = {this.handleCloseSelect}
                                            onChange={this.props.onChangeSelect}                                           
                                        >
                                            <MenuItem value = {GDTVersion.GDT2_1}>GDT2.1</MenuItem>
                                            <MenuItem value = {GDTVersion.GDT3_0}>GDT3.0</MenuItem>
                                            <MenuItem value = {GDTVersion.GDT3_1}>GDT3.1</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Box>
                                <TextField
                                    id = "attachmentFilename"
                                    label = "Dateiname der Anhänge"
                                    value = {this.props.gdtConfig.attachmentFilename || ""}
                                    name = "attachmentFilename"
                                    autoComplete="off"
                                    onChange = {this.props.onChangeInput}
                                    fullWidth
                                    disabled = {this.props.isGdtRunning}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <Tooltip
                                                    title = 'Wenn nicht angegeben, wird Dateiname des GDT-Export verwendet. Wenn diese auch nicht angegeben wurde, wird "<GDT-ID PVS><GDT-ID Telescan>" verwendet.'
                                                    placement = "top"
                                                >
                                                    <InfoOutlinedIcon fontSize="small"/>
                                                </Tooltip>
                                                
                                            </InputAdornment>,
                                    }}
                                />
                                <TextField
                                    required
                                    id = "gdtIdTelescan"
                                    label = "GDT-ID TeleScan"
                                    value = {this.props.gdtConfig.gdtIdTelescan || ""}
                                    name = "gdtIdTelescan"
                                    autoComplete="off"
                                    onChange = {this.props.onChangeInput}
                                    disabled = {this.props.isGdtRunning}
                                    helperText = {this.props.gdtConfig.gdtIdTelescan !== undefined && /([ßöäü])/.test(this.props.gdtConfig.gdtIdTelescan) && "GDT-ID TeleScan darf keine Umlaute enthalten"}
                                    error = {!this.props.gdtConfig.gdtIdTelescan || this.props.gdtConfig.gdtIdTelescan === "" || (this.props.gdtConfig.gdtIdTelescan !== undefined && /([ßöäü])/.test(this.props.gdtConfig.gdtIdTelescan))}
                                />
                                <TextField
                                    required
                                    id = "gdtIdPVS"
                                    label = "	GDT-ID PVS"
                                    value = {this.props.gdtConfig.gdtIdPVS || ""}
                                    autoComplete="off"
                                    name = "gdtIdPVS"
                                    onChange = {this.props.onChangeInput}
                                    disabled = {this.props.isGdtRunning}
                                    helperText = {this.props.gdtConfig.gdtIdPVS !== undefined && /([ßöäü])/.test(this.props.gdtConfig.gdtIdPVS) && "GDT-ID PVS darf keine Umlaute enthalten"}
                                    error = {!this.props.gdtConfig.gdtIdPVS || this.props.gdtConfig.gdtIdPVS === "" || (this.props.gdtConfig.gdtIdPVS !== undefined && /([ßöäü])/.test(this.props.gdtConfig.gdtIdPVS))}
                                />
                                {/* <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.props.gdtConfig.nameSensitiveImport}
                                            onChange={this.props.onChangeCheckbox}
                                            name="nameSensitiveImport"
                                            color="primary"
                                        />
                                    }
                                    label="Name Sensitive"
                                /> */}
                                <FormControlLabel
                                    disabled = {this.props.isGdtRunning}
                                    control={
                                        <Checkbox
                                            checked={this.props.gdtConfig.includeAttachment}
                                            onChange={this.props.onChangeCheckbox}
                                            name="includeAttachment"
                                            color="primary"
                                        />
                                    }
                                    label="Anhang in GDT referenzieren"
                                />                                 
                            </Box>   
                        </Box>
                    </Box>
                    
                    <Box className="modal-footer">   
                        <Button
                            variant="contained"
                            color="primary"
                            onClick = {this.props.onSaveButtonClick}
                            disabled = {((this.props.role === "HAUSARZT" || this.props.role === "BETRIEBSSTAETTE_HAUSARZT") && (!this.props.gdtConfig.directory || this.props.gdtConfig.directory === "")) ||
                            ((this.props.role === "DERMATOLOGE" || this.props.role === "BETRIEBSSTAETTE_DERMATOLOGE") && (!this.props.gdtConfig.exportDirectory || this.props.gdtConfig.exportDirectory === "")) ||
                            this.props.isGdtRunning ||
                            !this.props.gdtConfig.gdtIdTelescan || this.props.gdtConfig.gdtIdTelescan === "" ||
                            !this.props.gdtConfig.gdtIdPVS || this.props.gdtConfig.gdtIdPVS === "" ||
                            (this.props.gdtConfig.gdtIdTelescan !== undefined && /([ßöäü])/.test(this.props.gdtConfig.gdtIdTelescan)) ||
                            (this.props.gdtConfig.gdtIdPVS !== undefined && /([ßöäü])/.test(this.props.gdtConfig.gdtIdPVS)) ||
                            (this.props.gdtConfig.importFileByRegex && (!this.props.gdtConfig.importFilename || this.props.gdtConfig.importFilename === "" || !this.isRegexValid()))} 
                        >
                            Speichern                               
                        </Button>
                            
                        <Button
                        variant="text"
                        onClick={this.props.handleClose}
                        >
                            Abbrechen                               
                        </Button>                                                                     
                    </Box>
                </Paper>
             
            </SimpleModalContainer>
        )
    }
}