import { Menu, List, MenuItem, Box, Typography } from "@material-ui/core";
import React from "react";
import { Arzt, KonsilEmpfaengerGruppe } from "telescan-core";
import uuid from 'uuid';

interface IProps {
    konsiliareList: Arzt[]; //filteredKonsiliare
    groupList: KonsilEmpfaengerGruppe[]; //filteredKonsiliare
    anchorEl: any; // element type
    handleMenuClose: () => void;
    handleMenuSelect: (konsiliar: Arzt) => void;
    handleGruppenMenuSelect: (group: KonsilEmpfaengerGruppe) => void;
    context: "INDIVIDUAL" | "GROUP";
}

export class RecieverPickerComponent extends React.Component<IProps> {
    public static defaultProps = {
        konsiliareList: [],
        groupList: [],
        handleMenuSelect: () => {
        },
        handleGruppenMenuSelect: () => {
        },
    };


    render() {
        return (
            <Menu
                anchorEl={this.props.anchorEl}
                keepMounted
                open={Boolean(this.props.anchorEl)}
                onClose={this.props.handleMenuClose}
                disableAutoFocus
                disableEnforceFocus
                autoFocus={false}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                className="reviever-picker"
            >
                {this.props.context === "INDIVIDUAL" &&
                <List style={{maxHeight: '300px', overflow: 'auto'}}>
                    {(this.props.konsiliareList.length > 0) && this.props.konsiliareList.map(konsiliar => (
                        <MenuItem key={(konsiliar.id || uuid.v4())}
                                  onClick={() => this.props.handleMenuSelect(konsiliar)}>
                            <Box className="receiver-picker-card">
                                <Typography variant="body1"
                                            color="inherit">{konsiliar.titel} {konsiliar.vorname} {konsiliar.nachname}</Typography>
                                <Typography variant="caption"
                                            color="inherit">Dermatologe</Typography>
                                <br/>
                                <Typography className="Receiver-credentials"
                                            variant="caption"
                                            color="inherit">LANR: {konsiliar.lanr}</Typography>
                                <Typography className="Receiver-credentials pad-left"
                                            variant="caption"
                                            color="inherit">BSNR: {konsiliar.betriebsstaette?.bsnr || ""}</Typography>
                                <br/>
                                <Typography variant="caption"
                                            color="inherit">{(konsiliar.betriebsstaette?.strasseHausnummer) ? konsiliar.betriebsstaette?.strasseHausnummer + ", " : ""}</Typography>
                                <Typography variant="caption"
                                            color="inherit">{(konsiliar.betriebsstaette?.plz && konsiliar.betriebsstaette?.ort) ? konsiliar.betriebsstaette?.plz + " " + konsiliar.betriebsstaette?.ort : ""}</Typography>
                            </Box>
                        </MenuItem>
                    ))}
                    {(this.props.konsiliareList.length === 0) &&
                    <Box className="receiver-picker-nomatch">
                        <Typography variant="body1" color="inherit">
                            Keine Suchtreffer gefunden.
                        </Typography>
                    </Box>
                    }
                </List>
                }
                {this.props.context === "GROUP" &&
                <List style={{maxHeight: '300px', overflow: 'auto'}}>
                    {(this.props.groupList.length > 0) && this.props.groupList.map(gruppe => (
                        <MenuItem key={gruppe.empfaengergruppeIdentifikator}
                                  onClick={() => this.props.handleGruppenMenuSelect(gruppe)}>
                            <Box className="receiver-picker-card">
                                <Typography variant="body1" color="inherit">
                                    {`${gruppe?.empfaengergruppeIdentifikator} \u2022 ${gruppe?.empfaengergruppeBezeichnung}`}
                                </Typography>
                            </Box>
                        </MenuItem>
                    ))}
                    {(this.props.groupList.length === 0) &&
                    <Box className="receiver-picker-nomatch">
                        <Typography variant="body1" color="inherit">
                            Keine Suchtreffer gefunden.
                        </Typography>
                    </Box>
                    }
                </List>
                }
            </Menu>
        )
    }
}