import { Paper, Typography, Box, MenuItem, TextField, Button, FormControl, InputLabel, Select } from "@material-ui/core";
import {DatePicker} from '../../index';
import React from "react";
import { Geschlecht, Patient, Krankenkasse, VersichertenStatus, Personengruppe, DMPZuordnung, Konsil, UploadPath } from "telescan-core";
import SimpleModalContainer from "../../../elements/simple_modal";
import { Autocomplete } from "@material-ui/lab";

interface IState {
    patient: Patient;
    strasse: string;
    hausnummer: string;
    isSelect: boolean;
    selectedInsurance: Krankenkasse;
}

interface IProps {
    isOpenEditModal: boolean;
    //handleCloseEdit: () => void;
    updateKonsilPatient: (patient: Patient) => void;
    currentKonsil: Konsil;
    currentKonsilPatient: Patient;
    konsilPatientErrors: {konsilID: string, errors: Map<string, string>}[];
    initialisedServices: (keyof typeof UploadPath)[];
    dispatch_updateTelescanPatient: (patient: Patient, previousPn: string) => void;
    dispatch_checkKonsilPatientStatus: (patient: Patient, konsil_id: string) => void;
    availabelInsuranceKim: Krankenkasse[];
    handleSaveOrCancelPatientData: (patient?: Patient) => void;
}

export default class EditPatientGeneralDataModal extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            patient: this.props.currentKonsilPatient,
            strasse: "",
            hausnummer: "",
            isSelect: false,
            selectedInsurance: new Krankenkasse(),
        };
    }

    componentDidMount() {
        this.setState({
            patient: this.props.currentKonsilPatient,
            selectedInsurance: this.props.currentKonsilPatient.krankenkasse || new Krankenkasse(),
        });
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.currentKonsilPatient !== this.props.currentKonsilPatient) {
            this.setState({
                patient: this.props.currentKonsilPatient,
                selectedInsurance: this.props.currentKonsilPatient.krankenkasse || new Krankenkasse(),
            });
        }
    }

    renderTextFieldPatient = (prop: string, label: string, id: string, error?: string) => {
        const patientError: Map<string, string> = this.props.konsilPatientErrors.find(item => item.konsilID === this.props.currentKonsil.id)?.errors || new Map<string, string>();
        return (
            <TextField id={id} label={label}
                       value={this.state.patient?.[prop] || ""}
                       onChange={(event) => {
                           let updatedPatient = {...this.state.patient} as Patient;
                           let value: string;
                           if (prop === "plz") {
                                value = event.target.value.replace(" ", "").replace("-", "").replace(/[^0-9]+$/, "");
                           } else {
                               value = event.target.value;
                           }
                           updatedPatient[prop] = value;
                           this.setState({
                               patient: updatedPatient
                           })
                       }}
                       //onBlur={() => this.props.updateKonsilPatient(this.state.patient)}
                       onBlur={() => this.props.dispatch_checkKonsilPatientStatus(this.state.patient, this.props.currentKonsil.id || "")}
                       required={error && prop !== "plz" ? true : false}
                       error={error ? prop === "plz" ? (this.state.patient.plz != null && this.state.patient.plz !== "" && this.state.patient.plz.length !== 5) : patientError.has(error) : false}
                       disabled = {prop === "externePatientenNummer"}
            />
        )
    }

    renderSelectPatient = (prop: string, defaultValue: string, id: string, labelId: string, label: string, menuItems: JSX.Element[], error?: string) => {
        const patientError: Map<string, string> = this.props.konsilPatientErrors.find(item => item.konsilID === this.props.currentKonsil.id)?.errors || new Map<string, string>();
        return (
            <FormControl
                required={error ? true : false}
                error={error ? patientError.has(error) : false}
            >
                <InputLabel shrink id="gender-select-label">
                    {label}
                </InputLabel>
                <Select required
                        labelId={labelId}
                        id={id}
                        value={this.state.patient?.[prop] || defaultValue}
                        onChange={(event) => {
                            let updatedPatient = {...this.state.patient} as Patient;
                            updatedPatient[prop] = event.target.value;
                            this.setState({
                                patient: updatedPatient
                            })
                            this.setState(
                                {patient: updatedPatient},
                                //() => this.props.updateKonsilPatient(this.state.patient)
                                () => this.props.dispatch_checkKonsilPatientStatus(this.state.patient, this.props.currentKonsil.id || "")
                            )
                        }}
                        displayEmpty
                >
                    {menuItems.map(menuItem =>
                        menuItem
                    )}
                </Select>
            </FormControl>
        )
    }

    handleChangeSelect = (event: React.ChangeEvent<{ value: string }>) => {
        this.setState({
            patient: {
                ...this.state.patient,
                krankenkasse: {
                    ...this.state.patient.krankenkasse,
                    iknr: event.target.value
                }
            }
        })
    }

    handleOpenSelect = () => {
        this.setState({isSelect: true});
    }

    handleCloseSelect = () => {
        this.setState({isSelect: false});
    }

    // handleFinishButton = () => {
    //     this.props.handleCloseEdit();
    //     if (this.state.patient.telescanCreated) {
    //         this.props.dispatch_updateTelescanPatient(this.state.patient, this.props.currentKonsil.id || "");
    //     } else {
    //         this.props.updateKonsilPatient(this.state.patient);
    //     }
    // }

    handleChangeSelectIknr = (_event: React.ChangeEvent<{ value: unknown }>, newValue: Krankenkasse | null) => {
        this.setState(
            {
                patient: {
                    ...this.state.patient,
                    krankenkasse: {
                        ...this.state.patient.krankenkasse,
                        iknr: newValue?.iknr,
                        name: newValue?.name,
                    }
                },
                selectedInsurance: newValue || new Krankenkasse(),
            },
            () => this.props.dispatch_checkKonsilPatientStatus(this.state.patient, this.props.currentKonsil.id || "")
        )
    }

    render() {
        const patientError: Map<string, string> = this.props.konsilPatientErrors.find(item => item.konsilID === this.props.currentKonsil.id)?.errors || new Map<string, string>();
        const isButtonDisabled = !this.state.patient ||
            !this.state.patient.vorname || this.state.patient.vorname === "" ||
            !this.state.patient.nachname || this.state.patient.nachname === "" ||
            !this.state.patient.geschlecht ||
            !this.state.patient.geburtsdatum ||
            (this.state.patient.plz && this.state.patient.plz !== "" && this.state.patient.plz.length !== 5) ||
            !this.state.patient.krankenkasse?.name || this.state.patient.krankenkasse?.name === "" ||
            !this.state.patient.krankenkasse?.iknr || this.state.patient.krankenkasse?.iknr === "" ||
            !this.state.patient.dmpZuordnung ||
            !this.state.patient.personengruppe ||
            !this.state.patient.versichertenStatus ||
            !this.state.patient.egkNummer || this.state.patient.egkNummer === "" ||
            !this.state.patient.externePatientenNummer || this.state.patient.externePatientenNummer === "" ||
            (this.state.patient.krankenkasse && !this.props.availabelInsuranceKim.find(item => item.iknr === this.state.patient.krankenkasse?.iknr));
        return (
            <SimpleModalContainer
                isOpen={this.props.isOpenEditModal}
                //onClose={this.props.handleCloseEdit}
                additionalClassname="modal-profile-data"
            >
            <Paper className="modal-wrapper">
                <h2 id="transition-modal-title" className="modal-header">Patienten Informationen</h2>
                <Typography variant="caption" color="inherit" className="modal-subheader">
                    Bitte vervollständigen/überprüfen Sie die folgenden Patientendaten.
                    Die Patientendaten können manuell ausgefüllt, oder per GDT aus ihrem PVS importiert werden.
                    Bitte beachten Sie, dass eine Änderung der Patientendaten keine Änderung der Daten in Ihrem PVS bewirkt.
                </Typography>
                {!this.props.initialisedServices.includes(UploadPath.HPM) &&
                    <Typography variant="caption" color="inherit" className="modal-subheader">
                        Aus vertragsrechtlichen Gründen kann die Software nur für teilnehmende Patienten des Haus- und Facharztprogramms der AOK Baden-Württemberg verwendet werden.
                    </Typography>
                }

                <Box className="modal-content">
                    <Box className="left-side">
                        { /* className="full-span" */}
                        <span className="full-span patient-additions">
                        {this.renderTextFieldPatient("title", "Titel", "patient-title")}
                            {this.renderTextFieldPatient("namenszusatz", "Namenszusatz", "patient-nb")}
                            {this.renderTextFieldPatient("vorsatzwort", "Vorsatzwort", "patient-vv")}
                    </span>
                        {this.renderTextFieldPatient("vorname", "Vorname", "patient-surname", "patient.vorname")}
                        {this.renderTextFieldPatient("nachname", "Name", "patient-name", "patient.name")}
                        {this.renderSelectPatient("geschlecht", "UNBEKANNT", "gender-select", "gender-select-label", "Geschlecht",
                            Object.keys(Geschlecht).map(key =>
                                <MenuItem key={key} value={key}>
                                    {Geschlecht[key]}
                                </MenuItem>
                            ), "patient.geschlecht")
                        }
                        <DatePicker
                            isRequired = {true}
                            label="Geburtstag"
                            id="patient-birthday"
                            inputLabelProps={null}
                            initialDate={this.state.patient.geburtsdatum ? new Date(this.state.patient.geburtsdatum) : null}
                            onChange={(date: Date) => {
                                let updatedPatient = {
                                    ...this.state.patient,
                                    geburtsdatum: date?.getTime()
                                } as Patient
                                this.setState({
                                    patient: updatedPatient
                                })
                            }}
                            onBlur={() => this.props.updateKonsilPatient(this.state.patient)}
                            hasError={true}
                        />
                        {this.renderTextFieldPatient("strasseHausnummer", "Straße und Hausnummer", "patient-address-street")}
                        {this.renderTextFieldPatient("plz", "PLZ", "patient-adress-plz", "patient.plz")}
                        {this.renderTextFieldPatient("wohnort", "Wohnort", "patient-adress-location")}
                    </Box>
                    <Box className="right-side">
                        <TextField
                            key = "name"
                            required
                            id = "patient-insurance"
                            label = "Krankenkasse"
                            value={this.state.patient?.krankenkasse?.name || ""}
                            onChange={(event) => {
                                let krankenkasse: Krankenkasse = this.state.patient?.krankenkasse ? this.state.patient.krankenkasse : new Krankenkasse();
                                krankenkasse.name = event.target.value;
                                let updatedPatient = {
                                    ...this.state.patient,
                                    krankenkasse: krankenkasse
                                } as Patient
                                this.setState({
                                    patient: updatedPatient
                                })
                            }}
                            onBlur={() => this.props.updateKonsilPatient(this.state.patient)}
                            error = {patientError.has("krankenkasse.name")}
                        />
                        {!this.props.initialisedServices.includes("HPM") ?
                            // <FormControl
                            //     fullWidth
                            //     error = {!this.state.patient.krankenkasse?.iknr || this.state.patient.krankenkasse?.iknr === "" ||
                            //     (this.state.patient.krankenkasse && !aokIknrList.includes(this.state.patient.krankenkasse.iknr))}
                            // >
                            //     <InputLabel id = "simple-select-label">Kassen-IKNr</InputLabel>
                            //     <Select
                            //         variant = "standard"
                            //         labelId = "simple-select-label"
                            //         id = "iknr"
                            //         value = {this.state.patient.krankenkasse?.iknr || ""}
                            //         label="Kassen-IKNr"
                            //         open = {this.state.isSelect}
                            //         onOpen = {this.handleOpenSelect}
                            //         onClose = {this.handleCloseSelect}
                            //         onChange={this.handleChangeSelect}
                            //     >
                            //         {aokIknrList.map(item => {
                            //             return (
                            //                 <MenuItem value = {item}>{item}</MenuItem>
                            //             )
                            //         })}
                            //     </Select>
                            // </FormControl>
                            <Autocomplete
                                id="available-insurance"
                                options = {this.props.availabelInsuranceKim}
                                autoHighlight
                                //open = {props.isDermaSearchOpen}
                                //onOpen = {() => props.handleOpenClose(true)}
                                //onClose = {() => props.handleOpenClose(false)}
                                //onInputChange={(_event: any, _newInputValue, reason) => props.handleInputChange(reason)}
                                //loading = {props.isDermaListLoading}
                                getOptionLabel={(option) => option.iknr || ""}
                                getOptionSelected = {(option, value) =>  option.iknr === value.iknr}
                                value = {this.state.selectedInsurance}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        {`${option.iknr || ""} ${option.name || ""}`}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label = "Kassen-IKNr"
                                        //placeholder = "Kassen-IKNr suchen und auswählen"
                                        error = {!this.state.patient.krankenkasse?.iknr || this.state.patient.krankenkasse?.iknr === "" ||
                                        (this.state.patient.krankenkasse && !this.props.availabelInsuranceKim.find(item => item.iknr === this.state.patient.krankenkasse?.iknr)) ||
                                        patientError.has("krankenkasse.iknr")}
                                    />
                                )}
                                onChange={this.handleChangeSelectIknr}
                                noOptionsText = "Keine verfügbaren Krankenkassen gefunden"

                            />
                            :
                            <TextField
                                key = "iknr"
                                required
                                id = "patient-insurance-iknr"
                                label = "Kassen-IKNr"
                                value={this.state.patient?.krankenkasse?.iknr || ""}
                                onChange={(event) => {
                                    let krankenkasse: Krankenkasse = this.state.patient?.krankenkasse ? this.state.patient.krankenkasse : new Krankenkasse();
                                    krankenkasse.iknr = event.target.value;
                                    let updatedPatient = {
                                        ...this.state.patient,
                                        krankenkasse: krankenkasse
                                    } as Patient
                                    this.setState({
                                        patient: updatedPatient
                                    })
                                }}
                                onBlur={() => this.props.updateKonsilPatient(this.state.patient)}
                                error = {patientError.has("krankenkasse.iknr")}
                            />
                        }

                        {/* {[{
                            prop: "name",
                            label: "Krankenkasse",
                            id: "patient-insurance",
                            error: "krankenkasse.name"
                        },
                        {
                            prop: "iknr",
                            label: "Kassen-IKNr",
                            id: "patient-insurance-iknr",
                            error: "krankenkasse.iknr"
                        }]
                            .map((item) =>
                                <TextField
                                    key={item.prop}
                                    required
                                    id={item.id}
                                    label={item.label}
                                    value={this.state.patient?.krankenkasse?.[item.prop] || ""}
                                    onChange={(event) => {
                                        let krankenkasse: Krankenkasse = this.state.patient?.krankenkasse ? this.state.patient.krankenkasse : new Krankenkasse();
                                        krankenkasse[item.prop] = event.target.value;
                                        let updatedPatient = {
                                            ...this.state.patient,
                                            krankenkasse: krankenkasse
                                        } as Patient
                                        this.setState({
                                            patient: updatedPatient
                                        })
                                    }}
                                    onBlur={() => this.props.updateKonsilPatient(this.state.patient)}
                                    error = {
                                        !this.props.initialisedServices.includes("HPM") && item.prop === "iknr" && this.state.patient.krankenkasse && this.state.patient.krankenkasse.iknr ?
                                        patientError.has(item.error) && !aokIknrList.includes(this.state.patient.krankenkasse.iknr)
                                        :
                                        patientError.has(item.error)
                                    }
                                />
                            )} */}
                        {this.renderTextFieldPatient("egkNummer", "eGK-Versichertennummer", "patient-egk-nr", "patient.egknummer")}
                        {this.renderSelectPatient("versichertenStatus", "", "status-select", "status-select-label", "Versichertenstatus",
                            Object.keys(VersichertenStatus).map(key =>
                                <MenuItem key={key} value={key}>
                                    {VersichertenStatus[key]}
                                </MenuItem>
                            ), "patient.versichertenStatus")
                        }
                        {this.renderSelectPatient("personengruppe", "", "patient-group-select", "patient-group-select-label", "Personengruppe",
                            [<MenuItem key="PG_00" value="PG_00">{Personengruppe.PG_00}</MenuItem>,
                                <MenuItem key="PG_04" value="PG_04">{Personengruppe.PG_04}</MenuItem>,
                                <MenuItem key="PG_06" value="PG_06">{Personengruppe.PG_06}</MenuItem>,
                                <MenuItem key="PG_07" value="PG_07">{Personengruppe.PG_07}</MenuItem>,
                                <MenuItem key="PG_08" value="PG_08">{Personengruppe.PG_08}</MenuItem>,
                                <MenuItem key="PG_09"
                                            value="PG_09">{Personengruppe.PG_09}</MenuItem>], "patient.personengruppe")
                        }
                        {this.renderSelectPatient("dmpZuordnung", "", "dmp-select", "dmp-select-label", "DMP-Zuordnung",
                            [<MenuItem key="DMP_00" value="DMP_00">{DMPZuordnung.DMP_00}</MenuItem>,
                                <MenuItem key="DMP_01" value="DMP_01">{DMPZuordnung.DMP_01}</MenuItem>,
                                <MenuItem key="DMP_02" value="DMP_02">{DMPZuordnung.DMP_02}</MenuItem>,
                                <MenuItem key="DMP_03" value="DMP_03">{DMPZuordnung.DMP_03}</MenuItem>,
                                <MenuItem key="DMP_04" value="DMP_04">{DMPZuordnung.DMP_04}</MenuItem>,
                                <MenuItem key="DMP_05" value="DMP_05">{DMPZuordnung.DMP_05}</MenuItem>,
                                <MenuItem key="DMP_06" value="DMP_06">{DMPZuordnung.DMP_06}</MenuItem>,
                                <MenuItem key="DMP_07" value="DMP_07">{DMPZuordnung.DMP_07}</MenuItem>,
                                <MenuItem key="DMP_08" value="DMP_08">{DMPZuordnung.DMP_08}</MenuItem>,
                                <MenuItem key="DMP_09"
                                            value="DMP_09">{DMPZuordnung.DMP_09}</MenuItem>], "patient.dmp_zuordnung")
                        }
                        {this.renderTextFieldPatient("externePatientenNummer", "Patientennummer", "patient-identifikator", "patient.externePatientenNummer")}
                    </Box>
                </Box>
                <Box className="modal-footer">
                    <Button
                        color="primary"
                        //onClick={this.props.handleCloseEdit}
                        onClick={() => this.props.handleSaveOrCancelPatientData()}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        //onClick={this.handleFinishButton}
                        onClick={() => this.props.handleSaveOrCancelPatientData(this.state.patient)}
                        disabled = {patientError.size !== 0 || isButtonDisabled}
                    >
                        Speichern
                    </Button>
                </Box>
                </Paper>
            </SimpleModalContainer>
        )
    }
}
