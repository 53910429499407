import React from "react";

import { Divider } from "@material-ui/core";
import { Betriebsstaette } from "telescan-core";
import { ViewText } from "../konsil";


interface IProps {
    basisdatenBS: Betriebsstaette;
}

export default class ViewBSDatenComponent extends React.Component<IProps> {

    render() {
        return (
            <form className="form-content label-content-grid" noValidate autoComplete="off" >
                <ViewText
                    labelVariant='caption'
                    label='BSNR'
                    text={localStorage.getItem("BSNR") || "-"}
                />
                <Divider />
                <ViewText
                    labelVariant='caption'
                    label='Betriebsstättenname'
                    text={this.props.basisdatenBS.name || ""}
                />
                <ViewText
                    labelVariant='caption'
                    label='Straße / Hausnummer'
                    text={this.props.basisdatenBS.strasseHausnummer || ""}
                />
                <ViewText
                    labelVariant='caption'
                    label='PLZ'
                    text={this.props.basisdatenBS.plz || ""}
                />
                <ViewText
                    labelVariant='caption'
                    label='Ort'
                    text={this.props.basisdatenBS.ort || ""}
                />
                <Divider />
                <ViewText
                    labelVariant='caption'
                    label='Telefon'
                    text={this.props.basisdatenBS.telefon || ""}
                />
                <ViewText
                    labelVariant='caption'
                    label='Fax'
                    text={this.props.basisdatenBS.fax || ""}
                />
                <ViewText
                    labelVariant='caption'
                    label='Mobil'
                    text={this.props.basisdatenBS.telefonMobil || ""}
                />
            </form>
        )
    }
}
