import React from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { Failure, KonsilValidationBeforeSendStatusTypes } from 'telescan-core';
import { Loop } from '@material-ui/icons';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

interface IProps {
    konsilValidationStatus: keyof typeof KonsilValidationBeforeSendStatusTypes | null;
    konsilValidationError: Failure;
}

export class KonsilValidationBadge extends React.Component<IProps> {
    render() {
        if (this.props.konsilValidationStatus === "OK")
            return (
                <Tooltip title={"ok"}>
                    <Box className="konsil-validation-badge hpm-ok">Validierung: <AssignmentTurnedInIcon/></Box>
                </Tooltip>
                )
        else if (!this.props.konsilValidationStatus || this.props.konsilValidationStatus === "LOADING")
            return (
                <Tooltip title="Unbekannt, versuche Status abzufragen">
                    <Box className="konsil-validation-badge hpm-loading">Validierung: <Loop/></Box>
                </Tooltip>
                )
        else if (this.props.konsilValidationStatus === "ERROR")
            return (
                <Tooltip
                    title = 
                    {
                        <div>
                            <Typography variant = 'body2'>{this.props.konsilValidationError.error}</Typography>
                            {
                                this.props.konsilValidationError.messages && this.props.konsilValidationError.messages.length>0 &&
                                this.props.konsilValidationError.messages.map((subitem: string) => {
                                    return (
                                        <li key = {subitem}>
                                            <Typography variant = 'body2'>{subitem}</Typography>
                                        </li>
                                    )
                                })
                            }
                        </div>
                    }
                >
                    <Box className="konsil-validation-badge hpm-error">Validierung: <ErrorOutlineIcon/></Box>
                </Tooltip>
                )
    }
}