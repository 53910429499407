import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';
import uuid from 'uuid';

import { PatientService, Patient, PatientDTO } from "telescan-core";
import {RootState} from '../reducers';
import {loadSuccess} from '.';
import { loading, failure } from './general_actions';
import { setKonsilPatient } from './konsil_actions';


export function loadPatienten(): ThunkAction<Promise<Patient[]>, RootState, unknown, Action<string>> {
    return dispatch => {
        const loadInstanceId: string = uuid.v4();
        dispatch(loading(loadInstanceId));

        const patientService: PatientService = new PatientService();
        return new Promise((resolve, reject) => {
            patientService.all().then(
                (patienten) => {
                    dispatch(loadSuccess(loadInstanceId));
                    resolve(patienten);
                }
            ).catch(
                (response) => {
                    dispatch(failure(loadInstanceId, response));
                    reject(response);
                }
            );
        });
    }
}

export function createTelescanPatient(patient: Patient): ThunkAction<Promise<Patient>, RootState, unknown, Action<string>> {
    return dispatch => {
        const loadInstanceId: string = uuid.v4();
        dispatch(loading(loadInstanceId));
        const patientDTO: PatientDTO = new PatientDTO();
        patientDTO.birthDate = patient.geburtsdatum;
        patientDTO.city = patient.wohnort;
        patientDTO.dmpAssignment = patient.dmpZuordnung;
        patientDTO.egkNumber = patient.egkNummer;
        patientDTO.firstName = patient.vorname;
        patientDTO.gender = patient.geschlecht;
        patientDTO.insuranceIkNumber = patient.krankenkasse?.iknr;
        patientDTO.insuranceStatus = patient.versichertenStatus;
        patientDTO.lastName = patient.nachname;
        patientDTO.medicalInsurance = patient.krankenkasse?.name;
        patientDTO.nameSuffix = patient.suffix;
        patientDTO.patientNumber = patient.externePatientenNummer;
        patientDTO.personGroup = patient.personengruppe;
        patientDTO.postalCode = patient.plz;
        patientDTO.prefix = patient.vorsatzwort;
        patientDTO.streetHaus = patient.strasseHausnummer;
        patientDTO.title = patient.title;
        patientDTO.nameSuffix = patient.namenszusatz;

        return new Promise((resolve, reject) => {
            new PatientService().createTelescanPatient(patientDTO)
            .then((patient: Patient) => {
                dispatch(loadSuccess(loadInstanceId));
                resolve(patient);
            })
            .catch((failureObj: any) => {
                dispatch(loadSuccess(loadInstanceId));
                dispatch(failure(loadInstanceId, failureObj));
                //reject(failureObj);
            });
        });
    }
}

export function updateTelescanPatient(patient: Patient, konsilId: string): ThunkAction<Promise<Patient>, RootState, unknown, Action<string>> {
    return dispatch => {
        const loadInstanceId: string = uuid.v4();
        dispatch(loading(loadInstanceId));
        return new Promise((resolve, reject) => {
            new PatientService().updateTelescanPatient(patient, konsilId)
            .then((patient: Patient) => {
                dispatch(loadSuccess(loadInstanceId));
                dispatch(setKonsilPatient(patient));
                //dispatch(updateKonsilPatient(konsilId, patient, new Patienteneinwilligung()))
                resolve(patient);
            })
            .catch((failureObj: any) => {
                dispatch(loadSuccess(loadInstanceId));
                dispatch(failure(loadInstanceId, failureObj));
                //reject(failureObj);
            });
        });
    }
}

/* export function checkVernetzungStatus(konsil: Konsil, patient: Patient): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const loadInstanceId: string = uuid.v4();
        dispatch(loading(loadInstanceId)); // maybe remove

        const patientService: PatientService = new PatientService();
        if (patient.egkNummer !== "" && patient.egkNummer) {
            patientService.checkTeilnehmerVernetzung(patient?.egkNummer).then(
                (response) => {
                    if (konsil.patienteneinwilligung)
                        konsil.patienteneinwilligung.einwilligungKonsil = true;
                    else {
                        konsil.patienteneinwilligung = new Patienteneinwilligung();
                        konsil.patienteneinwilligung.einwilligungKonsil = true;
                    }
                    dispatch(updateKonsil(konsil));
                    dispatch(loadSuccess(loadInstanceId));
                }
            ).catch(
                (response) => {
                    if (konsil.patienteneinwilligung)
                        konsil.patienteneinwilligung.einwilligungKonsil = false;
                    else {
                        konsil.patienteneinwilligung = new Patienteneinwilligung();
                        konsil.patienteneinwilligung.einwilligungKonsil = true;
                    }
                    dispatch(updateKonsil(konsil));
                    dispatch(failure(loadInstanceId, String(response)));
                }
            );
        } else
            dispatch(failure(loadInstanceId, "Keine eGK-Versichertennummer angegeben."));
    }
}
 */