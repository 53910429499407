import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";
import { NavigateBefore, NavigateNext } from "@material-ui/icons";
import React from "react";
import { NotificationActionType, NotificationType, Notification } from "telescan-core";

interface IProps {
    isCriticalNotifications: boolean;
    isWarningNotifications: boolean;
    criticalNotifications: Notification[],
    dispatch_logout: () => void;
    dispatch_sendNotification: (notificationId: string, notificationAction: NotificationActionType) => void;
    dispatch_removeNotification: (id: string) => void;
    dispatch_navigate: (url: string) => void;
    dispatch_setIsWarningNotification: (isWarningNotification: boolean) => void;
}
interface IState {
    notificationNumber: number;
    currentNotification: Notification;
}

export class NotificationLoginPopup extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            notificationNumber: 0,
            currentNotification: new Notification(),
        }
    }

    componentDidMount() {
        this.setState({currentNotification: this.props.criticalNotifications[this.state.notificationNumber]});
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (prevProps.criticalNotifications !== this.props.criticalNotifications) {
            this.setState({currentNotification: this.props.criticalNotifications[this.state.notificationNumber]});
        }
        if (prevProps.criticalNotifications.length !== this.props.criticalNotifications.length) {
            if (this.state.notificationNumber !== this.props.criticalNotifications.length && this.props.criticalNotifications.length !== 0 && !this.props.criticalNotifications[this.state.notificationNumber].isNotificationDisplayed)
                this.props.dispatch_sendNotification(this.props.criticalNotifications[this.state.notificationNumber].id || "", NotificationActionType.DISPLAYED);
            if (this.props.criticalNotifications.length === 0) {          
                this.props.dispatch_setIsWarningNotification(false);
            }
        }
    }

    onNextNotificationClick = () => {
        this.setState(prevState => 
            {
                return {
                    notificationNumber: prevState.notificationNumber + 1
                }            
            },
            () => this.setState(
                {currentNotification: this.props.criticalNotifications[this.state.notificationNumber]},
                () => !this.state.currentNotification.isNotificationDisplayed && this.props.dispatch_sendNotification(this.state.currentNotification.id || "", NotificationActionType.DISPLAYED)
            )
        )
    }

    onPreviousNotificationClick = () => {
        this.setState(prevState => 
            {
                return {
                    notificationNumber: prevState.notificationNumber - 1
                }            
            },
            () => this.setState({currentNotification: this.props.criticalNotifications[this.state.notificationNumber]})
        )
    }

    onDialogOpened = () => {
        this.state.currentNotification && this.props.dispatch_sendNotification(this.state.currentNotification.id || "", NotificationActionType.DISPLAYED)
    }

    onNotificationConfirm = () => {
        this.props.dispatch_sendNotification(this.state.currentNotification.id || "", NotificationActionType.CONFIRMED);
        this.props.dispatch_removeNotification(this.state.currentNotification.id || "");
        if (this.state.notificationNumber === this.props.criticalNotifications.length - 1 && this.props.criticalNotifications.length !== 1)
            this.onPreviousNotificationClick();
    }

    onLogout = () => {
        this.props.dispatch_sendNotification(this.state.currentNotification.id || "", NotificationActionType.LOGGEDOUT);
        this.props.dispatch_logout();
    }

    render() {
        let isExist: boolean;
        if (this.state.currentNotification) isExist = true;
        else isExist = false;

        return (
            <Dialog
                open = {this.props.isCriticalNotifications || this.props.isWarningNotifications}
                fullWidth = {true}
            >                    
                <Box>
                    <DialogTitle>
                        {isExist &&
                            (this.state.currentNotification.notificationVersion && parseInt(this.state.currentNotification.notificationVersion) > 1 ?
                                <Typography variant = 'h5'>
                                    {`${this.state.currentNotification.notificationType && NotificationType[this.state.currentNotification.notificationType]} (Aktualisierung)`}
                                </Typography> :
                                <Typography variant = 'h5'>
                                    {this.state.currentNotification.notificationType && NotificationType[this.state.currentNotification.notificationType]}
                                </Typography>)                  
                        }
                    </DialogTitle>
                    <DialogContent className = 'notification-content'>
                        <Typography>{this.state.currentNotification && this.state.currentNotification.publisher && this.state.currentNotification.publisher}</Typography>
                        <Typography>{this.state.currentNotification && this.state.currentNotification.affectedTime && this.state.currentNotification.affectedTime}</Typography>
                        <Typography>{this.state.currentNotification && this.state.currentNotification.affectedVersionServerAsString && this.state.currentNotification.affectedVersionServerAsString}</Typography>                     
                         <iframe
                            className = "notification-iframe"
                            title = "notification-popup"
                            srcDoc = {this.state.currentNotification && this.state.currentNotification.htmlAsString}
                            sandbox = "allow-scripts"
                            frameBorder = '0'
                        />
                    </DialogContent>
                    <DialogActions className = 'dialog-action'>
                        <Box className = 'flex-row-center justify-content-center'>
                            <IconButton
                                aria-label="previous"
                                className="img-prev"
                                disabled={this.state.notificationNumber <= 0}
                                onClick={this.onPreviousNotificationClick}
                                size = 'small'
                            >
                                <NavigateBefore fontSize="large" />
                            </IconButton>
                            <Typography>{`${this.state.notificationNumber + 1}/${this.props.criticalNotifications.length}`}</Typography>
                            <IconButton
                                aria-label="previous"
                                className="img-prev"
                                disabled={this.state.notificationNumber >= this.props.criticalNotifications.length - 1}
                                onClick={this.onNextNotificationClick}
                                size = 'small'
                            >
                                <NavigateNext fontSize="large" />
                            </IconButton>
                        </Box>
                        <Box className = 'flex-row-column-gap'>
                            {this.state.currentNotification && this.state.currentNotification.notificationType === Object.keys(NotificationType).filter(key => key === "WARNING")[0] &&
                                <Button
                                    variant = 'contained'
                                    color = 'primary'
                                    onClick = {this.onNotificationConfirm}
                                    size = 'small'
                                    disabled = {this.state.currentNotification.isNotificationConfirmed}
                                >
                                    Bestätigen
                                </Button>
                            }
                            <Button
                                variant = {this.state.currentNotification && this.state.currentNotification.notificationType === Object.keys(NotificationType).filter(key => key === "WARNING")[0] ? 'text' : 'contained'}
                                color = 'primary'
                                onClick = {this.onLogout}
                                size = 'small'
                            >
                                LOGOUT
                            </Button>
                            {this.state.currentNotification && this.state.currentNotification.notificationType === Object.keys(NotificationType).filter(key => key === "RISK")[0] &&
                                <Button
                                    variant = 'text'
                                    color = 'primary'
                                    onClick = {() => this.props.dispatch_navigate("/settings")}
                                >
                                    Einstellungen
                                </Button>
                            }                                
                        </Box>
                    </DialogActions> 
                </Box>              
            </Dialog>
        )
    }
}