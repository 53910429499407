import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import uuid from 'uuid';
import { Button, CircularProgress } from '@material-ui/core';
import { RootState } from '../../redux/reducers';
import { Send } from '@material-ui/icons';
import { changeKonsilTab, failure } from '../../redux/actions';
import { Failure } from 'telescan-core';


interface IProps {
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

interface IState {
    showId: boolean;
}

const mapStateToProps = (state: RootState, ownProps: IProps) => ({
    isDesktop: state.general.isDesktop,
    tabIndex: state.konsile.tabIndex,
    tabStatus: state.evaluation.tabStatus,
    currentKonsil: state.konsile.current_konsil,
    frageError: state.evaluation.konsilFrageErrors,
    einwilligungError: state.evaluation.einwilligungErrors,
    konsilAnamneseError: state.evaluation.konsilAnamneseErrors,
    konsilPatientError: state.evaluation.konsilPatientErrors,
    locationError: state.evaluation.locationErrors,
    konsilIdofLoadingImages: state.image_attachment.konsilIdOfImageLoadingArray,
    ...ownProps
})

const mapDispatchToProps = {
    dispatch_tabChange: (index: number) => changeKonsilTab(index),
    dispatch_failure: (loadInstanceId: string, error: Failure) => failure(loadInstanceId, error),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class VersendenButton extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            showId: false,
        };
    }

    hasLoadingImages = () => {
        return this.props.konsilIdofLoadingImages.filter(id => id === this.props.currentKonsil.id).length > 0
    }

    render() {
        const einwilligungError: Map<string, string> = this.props.einwilligungError.find(item => item.konsilID === this.props.currentKonsil.id)?.errors || new Map<string, string>();
        const patientError: Map<string, string> = this.props.konsilPatientError.find(item => item.konsilID === this.props.currentKonsil.id)?.errors || new Map<string, string>();
        const anamneseError: Map<string, string> = this.props.konsilAnamneseError.find(item => item.konsilID === this.props.currentKonsil.id)?.errors || new Map<string, string>();
        const locationError: Map<string, string> = this.props.locationError.find(item => item.konsilID === this.props.currentKonsil.id)?.errors || new Map<string, string>();
        const frageError: Map<string, string> = this.props.frageError.find(item => item.konsilID === this.props.currentKonsil.id)?.errors || new Map<string, string>();
        const errorArray: boolean[] = [
            einwilligungError.size !== 0,
            patientError.size !== 0,
            locationError.size !== 0,
            anamneseError.size !== 0,
            frageError.size !== 0
        ]

        const errors = Array.from(einwilligungError.values()).concat(Array.from(patientError.values())).concat(Array.from(locationError.values()))
                        .concat(Array.from(anamneseError.values())).concat(Array.from(frageError.values()))
        const firstErrorIndex: number = errorArray.findIndex(item => item === true);
        const loadingImages: boolean = this.hasLoadingImages()

        const disabled = errorArray.some(item => item === true)

        return (
            <Button variant={(disabled)? "outlined": "contained"} color="primary" size="small" className="btn-right" //disabled={!enabled}
                    disabled={loadingImages}
                    onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        if (!disabled)
                            this.props.onClick(event);
                        else {
                            // jump to tab with errors and display the error
                            const loadInstanceId: string = uuid.v4();
                            let failure = new Failure();
                            failure.error = errors[0];
                            (firstErrorIndex !== -1) && this.props.dispatch_tabChange(firstErrorIndex)
                            this.props.dispatch_failure(loadInstanceId, failure)
                        }
                    }}>
                {
                    loadingImages ? <CircularProgress size={12} style={{marginRight: "5px"}} /> : ""
                }
                {
                    this.props.isDesktop? "Versenden": <Send />

                }
            </Button>
        )
    }
}

export const VersendenButtonComponent = connector(VersendenButton);
export default VersendenButtonComponent;
