//// Processing "Betriebsstätte" Tab by the first installation of Telescan for dermatologist
import React from "react";
import {connect, ConnectedProps} from 'react-redux';

import { 
    Box, 
    Typography, 
    Paper, 
    FormControl, 
    TextField,  
    InputLabel 
} from "@material-ui/core";
import { Betriebsstaette } from "telescan-core";
import { RootState } from "../../redux/reducers";
import { ViewText } from "../konsil";
import { setBetriebsstaette, checkBasisdatenBSStatus } from "../../redux/actions";

const mapStateToProps = (state: RootState) => ({
    basisdatenBetriebsstaette: state.user.betriebsstaette,
    basisdatenBSErrors: state.user.basisdatenBSErrors,
    role: state.user.role
})

const mapDispatchToProps = {
    dispatch_setBetriebsstaette: (basisdatenBS: Betriebsstaette) => setBetriebsstaette(basisdatenBS),
    dispatch_checkBasisdatenBSStatus: (basisdatenBS: Betriebsstaette) => checkBasisdatenBSStatus(basisdatenBS),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>


export class BSDaten extends React.Component<TPropsFromRedux> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        // check if there are errors in the basis data
        this.props.dispatch_checkBasisdatenBSStatus(this.props.basisdatenBetriebsstaette);
    }

    render() {
        return (
            <Box className="main-content initialisation-content">
                <form className="form-content" noValidate autoComplete="off">
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                        Daten der Betriebsstätte
                    </Typography>
                    <BSDatenComponent basisdatenBSErrors={this.props.basisdatenBSErrors} basisdatenBetriebsstaette={this.props.basisdatenBetriebsstaette} setBetriebsstaette={this.props.dispatch_setBetriebsstaette} />
                </form>
            </Box>
        )
    }
}

export const BetriebsStaetteDatenComponent = connector(BSDaten);
export default BetriebsStaetteDatenComponent;

interface IBSProps {
    basisdatenBetriebsstaette: Betriebsstaette;
    basisdatenBSErrors: Map<string, string>;
    setBetriebsstaette: (betriebsstaette: Betriebsstaette) => void;
}

interface IBSState {
    basisdatenBetriebsstaette: Betriebsstaette;
}

export class BSDatenComponent extends React.Component<IBSProps, IBSState> {
    constructor(props: IBSProps) {
        super(props);
        this.state = {
            basisdatenBetriebsstaette: this.props.basisdatenBetriebsstaette,
        };

        this.renderBSTextfield = this.renderBSTextfield.bind(this);
    }

    componentDidMount() {
        let updatedBS = {...this.state.basisdatenBetriebsstaette} as Betriebsstaette
        updatedBS.bsnr = localStorage.getItem("BSNR") || ""

        this.setState({
            basisdatenBetriebsstaette: updatedBS
        })
    }

    componentDidUpdate(prevProps: Readonly<IBSProps>) {
        if (prevProps.basisdatenBetriebsstaette !== this.props.basisdatenBetriebsstaette){
            this.setState({
                basisdatenBetriebsstaette: this.props.basisdatenBetriebsstaette
            });
        }
    }

    renderBSTextfield(prop: string, label: string, error?: string) {
        if (prop === "telefon" || prop === "telefonMobil" || prop === "fax")
            return(
                <TextField
                    required={error? true: false}
                    error={error? this.props.basisdatenBSErrors.has("betriebsstaette."+error): false}
                    id={"bs-"+prop}
                    label={label}
                    placeholder={label}
                    value={this.state.basisdatenBetriebsstaette?.[prop] || ""}
                    onChange={(event) => {
                        let updatedBS = {...this.state.basisdatenBetriebsstaette} as Betriebsstaette
                        updatedBS[prop] = event.target.value

                        this.setState({
                            basisdatenBetriebsstaette: updatedBS
                        })}}
                    onBlur={() => this.props.setBetriebsstaette(this.state.basisdatenBetriebsstaette)}
                    autoComplete="off"
                    //helperText={this.props.basisdatenBSErrors.get("betriebsstaette.name")}
                    type = 'number'
                />
            )
        else
        return(
            <TextField
                required={error? true: false}
                error={error? this.props.basisdatenBSErrors.has("betriebsstaette."+error): false}
                id={"bs-"+prop}
                label={label}
                placeholder={label}
                value={this.state.basisdatenBetriebsstaette?.[prop] || ""}
                onChange={(event) => {
                    let updatedBS = {...this.state.basisdatenBetriebsstaette} as Betriebsstaette
                    updatedBS[prop] = event.target.value

                    this.setState({
                        basisdatenBetriebsstaette: updatedBS
                    })}}
                onBlur={() => this.props.setBetriebsstaette(this.state.basisdatenBetriebsstaette)}
                autoComplete="off"
                //helperText={this.props.basisdatenBSErrors.get("betriebsstaette.name")}
            />
        )
    }

    render() {
        return (
            <Paper className="bs-infos-card" elevation={0}>
                <Box className="bsnr-data">
                    <ViewText
                        labelVariant='caption'
                        label='BSNR'
                        text={localStorage.getItem("BSNR") || "-"}
                    />
                </Box>
                <Box className="bs-left">
                    {this.renderBSTextfield("name", "Betriebsstättenname", "name")}
                    {this.renderBSTextfield("strasseHausnummer", "Straße / Hausnummer", "strasseHausnummer")}
                    <Box className="adress">
                        {this.renderBSTextfield("plz", "PLZ", "plz")}
                        {this.renderBSTextfield("ort", "Ort", "ort")}
                    </Box>
                </Box>
                <Box className="bsdata-right">
                    <FormControl
                        required
                        error={this.props.basisdatenBSErrors.has("betriebsstaette.kontakt")}
                    >
                        <InputLabel shrink id="contact-data-label">Kontaktdaten</InputLabel>
                        {this.renderBSTextfield("telefon", "Telefon")}
                        {this.renderBSTextfield("fax", "Fax")}
                        {this.renderBSTextfield("telefonMobil", "Mobil")}
                    </FormControl>
                </Box>
            </Paper>
        )
    }

}

