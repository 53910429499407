import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ArrowDownward, Remove, ArrowUpward } from '@material-ui/icons';
import { TableCell } from '@material-ui/core';
import { RootState } from '../../redux/reducers';
import { loadArztKonsile } from '../../redux/actions';


interface IPropsSort { 
    title: string;
    activateCell: (propertyName: string, direction: "asc" | "desc" | "default") => void;
    active: boolean;
    propertyName: string
}

interface IState {
    direction: "asc" | "desc" | "default";
    
}


const mapStateToProps = (state: RootState, ownProps: IPropsSort) => ({
    role: state.user.role,
    ...ownProps
})

const mapDispatchToProps = {
    dispatch_loadKonsile: (propertyName?: string, direction?: "asc" | "desc" | "default") => loadArztKonsile(propertyName, direction),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>


export class SortTable extends React.Component<TPropsFromRedux, IState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            direction: "default"
        };
        this.handleSort = this.handleSort.bind(this);
    }

    handleSort() {
        
        let direction = this.state.direction;
        let directionNew;
        if (direction === "asc")
            directionNew = "desc"
        else if (direction === "desc")
            directionNew = "default"
        else 
            directionNew = "asc"
        this.setState({ // sets the direction used for backend sorting
            direction: directionNew,
        }, () => {
            this.props.activateCell(this.props.propertyName, this.state.direction);
            this.props.dispatch_loadKonsile(this.props.propertyName, this.state.direction);
        })
    }

    render() {
        if (this.state.direction === "asc")
            return <TableCell onClick={() => this.handleSort()}>{this.props.title}<ArrowUpward className={(this.props.active)? "icon-sorting active": "icon-sorting"}/></TableCell>
        else if (this.state.direction === "desc")
            return <TableCell onClick={() => this.handleSort()}>{this.props.title}<ArrowDownward className={(this.props.active)? "icon-sorting active": "icon-sorting"}/></TableCell>
        else if (this.state.direction === "default")
            return <TableCell onClick={() => this.handleSort()}>{this.props.title}<Remove className={(this.props.active)? "icon-sorting active": "icon-sorting"}/></TableCell>
        else
            return <TableCell onClick={() => this.handleSort()}>{this.props.title}<Remove className={'icon-sorting'}/></TableCell>
    }
}

export const SortTableComponent = connector(SortTable);
export default SortTableComponent;