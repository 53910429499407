import React from "react";
import {connect, ConnectedProps} from 'react-redux';

import { Box, Typography, Paper, FormControl, TextField, Button, FormLabel} from "@material-ui/core";
import { loading, loadSuccess, getInitialisationStatus, setArztZertifikatErfasst, setBSZertifikatErfasst, failure } from "../../redux/actions";
import { RootState } from "../../redux/reducers";
import uuid from 'uuid';
import { ZertifikatService, ZertifikatMetainformation, ZertifikatStatus, UserTypes, UserService, Failure } from "telescan-core";
import { ViewText } from "../konsil";
import NewPasswordContainer from "../elements/new_password";
import { withNewPasswordMethods, WithNewPasswordMethodsProps, WithIdentifikationscodeMethodsProps, withIdentifikationscodeMethods } from "../../utils";



interface IProps {
    message: string;
    arztZertifikatVorhanden: boolean;
}

interface IState {
    bsZertifikatErzeugt: boolean,
    arztZertifikatErzeugt: boolean,
    bsZertifikatMetaInfo: ZertifikatMetainformation,
    arztZertifikatMetaInfo: ZertifikatMetainformation,
    checkedZertifikate: boolean,
}


const mapStateToProps = (state: RootState, ownProps: IProps) => ({
    initialisationStatus: state.user.initialisationStatus,
    loading: state.general.loading,
    role: state.user.role,
    userArztZertifikatErfasst: state.user.zertifikatArztVorhanden,
    zertifikatBetriebsstaetteVorhanden: state.user.zertifikatBetriebsstaetteVorhanden,
    zertifikatArztVorhanden: state.user.zertifikatArztVorhanden,
    isInitialisationStatusLoading: state.general.isInitialisationStatusLoading,
    ...ownProps
})

const mapDispatchToProps = {
    dispatch_loading: (id: string) => loading(id),
    dispatch_loadSuccess: (id: string) => loadSuccess(id),
    dispatch_failure: (id: string, message: Failure) => failure(id, message),
    dispatch_getInitialisationStatus: () => getInitialisationStatus(),
    dispatch_setArztZertifikatErfasst: (zertifikatArztVorhanden: boolean) => setArztZertifikatErfasst(zertifikatArztVorhanden),
    dispatch_setBSZertifikatErfasst: (zertifikatBSVorhanden: boolean) => setBSZertifikatErfasst(zertifikatBSVorhanden)
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>
type Props = TPropsFromRedux & WithNewPasswordMethodsProps & WithIdentifikationscodeMethodsProps


export class Zertifikate extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            bsZertifikatErzeugt: false,
            arztZertifikatErzeugt: false,
            bsZertifikatMetaInfo: {},
            arztZertifikatMetaInfo: {},
            checkedZertifikate: false,
        };

        this.handleCheckZertifikatePVS = this.handleCheckZertifikatePVS.bind(this);
        this.createBSCertificate = this.createBSCertificate.bind(this);
        this.createArztCertificate = this.createArztCertificate.bind(this);
    }


    handleCheckZertifikatePVS(event) {
        const userService = new UserService();
        userService.getUserDetails(UserTypes.ARZT).then((userDetails) => {
            this.props.dispatch_setArztZertifikatErfasst(userDetails.zertifikatArztVorhanden || false);
            this.props.dispatch_setBSZertifikatErfasst(userDetails.zertifikatBetriebsstaetteVorhanden);
            this.props.dispatch_getInitialisationStatus();
            this.setState({
                checkedZertifikate: true
            })
        }).catch((error) => {
            const failureId: string = uuid.v4();
            this.props.dispatch_failure(failureId, error);
        })
    }

    createBSCertificate(passwort: string) {
        const zertifikatService = new ZertifikatService();
        zertifikatService.createBetriebsstaettenZertifikat(passwort).then((metaInfo) => {
            this.setState({
                bsZertifikatErzeugt: true,
                bsZertifikatMetaInfo: metaInfo
            })
            this.props.dispatch_getInitialisationStatus();
            this.props.dispatch_setBSZertifikatErfasst(true);
        }).catch((error) => {
            const failureId: string = uuid.v4();
            this.props.dispatch_failure(failureId, error);
        })
    }

    createArztCertificate(password: string, identifikationsCode: string) {
        const zertifikatService = new ZertifikatService();
        zertifikatService.createArztZertifikat(password, identifikationsCode).then((metaInfo) => {
            this.setState({
                arztZertifikatErzeugt: true,
                arztZertifikatMetaInfo: metaInfo
            })
            this.props.dispatch_getInitialisationStatus();
            this.props.dispatch_setArztZertifikatErfasst(true);
            //this.props.dispatch_getUser(UserTypes[this.props.role]);
        }).catch((error) => {
            const failureId: string = uuid.v4();
            this.props.dispatch_failure(failureId, error);
        })
    }


    render() {
        return (
            <Box className="main-content initialisation-content">
                <form className="form-content" noValidate autoComplete="off">
                    <Typography className="txt-header" variant="subtitle1" color="inherit">
                        Zertifikate erzeugen
                    </Typography>
                    {this.props.message !== "" &&
                    <Paper className="recovery-code-card" elevation={0}>
                        <Typography >{this.props.message}</Typography>
                    </Paper>
                    }
                    {this.props.initialisationStatus.standalone &&
                    <>
                        <Box className="bs-doctors-description" style={{marginTop: "16px", marginBottom: "16px", display: "flex", flexDirection: "column"}}>
                            <Typography variant="caption" color="inherit" >
                                Zum Versenden und Empfangen von Telekonsilen werden gültige Zertifikate für die Betriebsstätte
                                und den angemeldeten Arzt benötigt.
                            </Typography>
                            <Typography variant="caption" color="inherit" >
                                Das Betriebsstättenzertifikat muss vor dem Arztzertifikat erzeugt werden.
                            </Typography>
                        </Box>
                        <Paper className="recovery-code-card" elevation={0}>
                            <Typography variant="caption" color="inherit" >
                                Zum Erzeugen der Betriebsstätten- und Arztzertifikate wird ein Identifikationscode benötigt.
                            </Typography>
                            < IdentifikationsCode setIdentifkationsCode={this.props.setIdentifikationscode}/>
                        </Paper>
                        <Box className="certificates" >
                            <Box className="certificate-doctor">
                                <Typography className="txt-header" variant="subtitle1" color="inherit">
                                    Arzt-Zertifikat
                                </Typography>
                                {(this.props.arztZertifikatVorhanden)?
                                <Paper className="recovery-code-card" elevation={0}>
                                    <Typography >Es wurde bereits ein Arztzertifikat erzeugt.</Typography>
                                </Paper>
                                :
                                <>
                                <Paper className="certificate-doctor-content" elevation={0}>
                                    <Typography variant="caption" color="inherit">
                                        An dieser Stelle kann nur das Zertifikat für den angemeldeten Arzt mit der
                                        LANR {localStorage.getItem("LANR")} erzeugt werden. Für alle weiteren Ärzte der Betriebsstätte muss bei deren erstem Login
                                        ein entsprechendes Zertifikat erstellt werden.
                                    </Typography>
                                    <NewPasswordContainer setStrength={this.props.setStrength} newPassword={this.props.newPassword}
                                        newRepeatedPassword={this.props.newRepeatedPassword} handleNewPasswordChange={this.props.handleNewPasswordChange}
                                        handleNewRepeatedPasswordChange={this.props.handleNewRepeatedPasswordChange} context="ZERTIFIKAT" />
                                    <Box className="certificate-button-container">
                                        {!this.state.arztZertifikatErzeugt &&
                                        <Button variant="contained" color="primary" type="submit"
                                            disabled={(this.props.newPassword == null || this.props.newPassword === "" || this.props.newPassword !== this.props.newRepeatedPassword ||
                                                    this.props.identifikationscodeHA.length !== 19 ||
                                                    this.props.identifikationscodeDerma.length !== 16 ||
                                                    this.props.passwordStrength !== "strong") ||
                                                    this.props.isInitialisationStatusLoading}
                                            onClick={(event) => {

                                                event.preventDefault()
                                                if (this.props.role === "HAUSARZT" || this.props.role === "BETRIEBSSTAETTE_HAUSARZT")
                                                    this.createArztCertificate(this.props.newPassword, this.props.identifikationscodeHA)
                                                else
                                                    this.createArztCertificate(this.props.newPassword, this.props.identifikationscodeDerma)
                                        }}>
                                            Arztzertifikat erstellen
                                        </Button>
                                        }
                                        {this.state.arztZertifikatErzeugt &&
                                        <Button variant="contained" color="primary" disabled >
                                            Arztzertifikat erstellt
                                        </Button>
                                        }
                                    </Box>
                                </Paper>
                                </>
                                }
                                {this.state.arztZertifikatErzeugt &&
                                <>
                                    <Typography className="pad-top" variant="subtitle1" color="inherit">
                                        Arzt-Zertifikat erfolgreich erzeugt.
                                    </Typography>
                                    <Paper className="certificate-info" elevation={0}>
                                        <Typography variant="subtitle1" color="inherit">
                                            Zertifikat-Informationen
                                        </Typography>
                                        <Box className="bs-left">
                                            <ViewText labelVariant='subtitle1'
                                                label='BSNR'
                                                text={this.state.arztZertifikatMetaInfo?.bsnr || "-"}
                                            />
                                            <ViewText labelVariant='subtitle1'
                                                label='LANR'
                                                text={this.state.arztZertifikatMetaInfo?.lanr || "-"}
                                            />
                                            <ViewText labelVariant='subtitle1'
                                                label='Inhaber'
                                                text={this.state.arztZertifikatMetaInfo?.inhaber || "-"}
                                            />
                                            <ViewText labelVariant='subtitle1'
                                                label='Seriennummer'
                                                text={this.state.arztZertifikatMetaInfo?.seriennummer || "-"}
                                            />
                                            <ViewText labelVariant='subtitle1'
                                                label='Gültig ab'
                                                text={(this.state.arztZertifikatMetaInfo?.gueltigVon)? new Date(this.state.arztZertifikatMetaInfo?.gueltigVon).toLocaleDateString() : "-"}
                                            />
                                            <ViewText labelVariant='subtitle1'
                                                label='Gültig bis'
                                                text={(this.state.arztZertifikatMetaInfo?.gueltigBis)? new Date(this.state.arztZertifikatMetaInfo?.gueltigBis).toLocaleDateString() : "-"}
                                            />
                                            <ViewText labelVariant='subtitle1'
                                                label='Status'
                                                text={(this.state.arztZertifikatMetaInfo?.status)? ZertifikatStatus[this.state.arztZertifikatMetaInfo?.status] : "-"}
                                            />
                                        </Box>
                                    </Paper>
                                </>
                                }
                            </Box>

                            <Box className="certificate-bs">
                                <Typography className="txt-header" variant="subtitle1" color="inherit">
                                    Betriebsstätten-Zertifikat
                                </Typography>
                                {(this.props.initialisationStatus.betriebsstaetteZertifikatErzeugt)?
                                <Paper className="recovery-code-card" elevation={0}>
                                    <Typography >Es wurde bereits ein Betriebsstättenzertifikat erzeugt.</Typography>
                                </Paper>
                                :
                                <>
                                <Paper className="certificate-bs-content" elevation={0}>
                                    <Typography variant="caption" color="inherit">
                                        Das Betriebsstättenzertifikat ist für alle Ärzte der Betriebsstätte gültig.
                                    </Typography>
                                    <Box className="certificate-button-container">
                                        {!this.state.bsZertifikatErzeugt &&
                                            <Button variant="contained" color="primary" type="submit"
                                                disabled={(this.props.identifikationscodeHA.length !== 19 || this.props.identifikationscodeDerma.length !== 16 ||
                                                    (!this.state.arztZertifikatErzeugt && !this.props.zertifikatArztVorhanden)) || this.props.isInitialisationStatusLoading}
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    if (this.props.role === "HAUSARZT" || this.props.role === "BETRIEBSSTAETTE_HAUSARZT")
                                                        this.createBSCertificate(this.props.newPassword)
                                                    else
                                                        this.createBSCertificate(this.props.newPassword)
                                            }}>
                                                Betriebsstättenzertifikat erstellen
                                            </Button>
                                        }
                                        {this.state.bsZertifikatErzeugt &&
                                            <Button variant="contained" color="primary" disabled >
                                                Betriebsstättenzertifikat erstellt
                                            </Button>
                                        }
                                    </Box>
                                </Paper>
                                </>
                                }
                                {this.state.bsZertifikatErzeugt &&
                                <>
                                    <Typography className="pad-top" variant="subtitle1" color="inherit">
                                        Betriebsstättenzertifikat erfolgreich erzeugt.
                                    </Typography>
                                    <Paper className="certificate-info" elevation={0}>
                                        <Box className="bs-left">
                                            <Typography variant="subtitle1" color="inherit">
                                                Zertifikat-Informationen
                                            </Typography>
                                            <ViewText labelVariant='subtitle1'
                                                label='BSNR'
                                                text={this.state.bsZertifikatMetaInfo?.bsnr || "-"}
                                            />
                                            <ViewText labelVariant='subtitle1'
                                                label='Inhaber'
                                                text={this.state.bsZertifikatMetaInfo?.inhaber || "-"}
                                            />
                                            <ViewText labelVariant='subtitle1'
                                                label='Seriennummer'
                                                text={this.state.bsZertifikatMetaInfo?.seriennummer || "-"}
                                            />
                                            <ViewText labelVariant='subtitle1'
                                                label='Gültig ab'
                                                text={(this.state.bsZertifikatMetaInfo?.gueltigVon)? new Date(this.state.bsZertifikatMetaInfo?.gueltigVon).toLocaleDateString() : "-"}
                                            />
                                            <ViewText labelVariant='subtitle1'
                                                label='Gültig bis'
                                                text={(this.state.bsZertifikatMetaInfo?.gueltigBis)? new Date(this.state.bsZertifikatMetaInfo?.gueltigBis).toLocaleDateString() : "-"}
                                            />
                                            <ViewText labelVariant='subtitle1'
                                                label='Status'
                                                text={(this.state.bsZertifikatMetaInfo?.status)? ZertifikatStatus[this.state.bsZertifikatMetaInfo?.status] : "-"}
                                            />
                                        </Box>
                                    </Paper>
                                </>
                                }
                            </Box>

                        </Box>
                    </>
                    }
                    {!this.props.initialisationStatus.standalone &&
                    <>
                    <Paper className="bs-infos-card" elevation={0}>
                        <Typography variant="subtitle1" color="inherit" >
                            Zertifikate müssen im Praxisverwaltungssystem erstellt und dann übertragen werden.
                        </Typography>
                        <Box className="passwort-button-container">
                            <Button variant="contained" color="primary"
                                onClick={(event) => this.handleCheckZertifikatePVS(event)}>
                                Zertifikate prüfen
                            </Button>
                        </Box>
                    </Paper>
                    {this.state.checkedZertifikate &&
                    <Box className="certificates" >
                        <Box className="certificate-bs">
                            <Typography className="txt-header" variant="subtitle1" color="inherit">
                                Betriebsstätten-Zertifikat
                            </Typography>
                            {this.props.zertifikatBetriebsstaetteVorhanden &&
                            <Typography variant="subtitle1" color="inherit">
                                Betriebsstättenzertifikat erfolgreich übertragen.
                            </Typography>
                            }
                            {!this.props.zertifikatBetriebsstaetteVorhanden &&
                            <Typography variant="subtitle1" color="inherit">
                                Betriebsstättenzertifikat nicht vorhanden.
                            </Typography>
                            }
                        </Box>
                        <Box className="certificate-doctor">
                            <Typography className="txt-header" variant="subtitle1" color="inherit">
                                Arzt-Zertifikat
                            </Typography>
                            {this.props.zertifikatArztVorhanden &&
                            <Typography variant="subtitle1" color="inherit">
                                Arztzertifikat erfolgreich übertragen.
                            </Typography>
                            }
                            {!this.props.zertifikatArztVorhanden &&
                            <Typography variant="subtitle1" color="inherit">
                                Arztzertifikat nicht vorhanden.
                            </Typography>
                            }
                        </Box>
                    </Box>}
                    </>
                    }
                </form>
            </Box>
        )
    }
}

export const ZertifikateComponent = connector(withNewPasswordMethods(withIdentifikationscodeMethods(Zertifikate)));
export default ZertifikateComponent;



interface ICodeProps {
    setIdentifkationsCode: (idCodeFirst: string, idCodeSecond: string, idCodeThird: string, idCodeFourth: string,) => void;
}

interface ICodeState {
    identifikationscodeFirst: string,
    identifikationscodeSecond: string,
    identifikationscodeThird: string,
    identifikationscodeFourth: string,
}

export class IdentifikationsCode extends React.Component<ICodeProps, ICodeState> {
    constructor(props: ICodeProps) {
        super(props);
        this.state = {
            identifikationscodeFirst: "",
            identifikationscodeSecond: "",
            identifikationscodeThird: "",
            identifikationscodeFourth: "",
        };

        this.input2 = React.createRef<HTMLDivElement>();
        this.input3 = React.createRef<HTMLDivElement>();
        this.input4 = React.createRef<HTMLDivElement>();

    }

    private input2: React.RefObject<HTMLDivElement>;
    private input3: React.RefObject<HTMLDivElement>;
    private input4: React.RefObject<HTMLDivElement>;

    render() {
        return (
            <FormControl>
                <FormLabel className="code-label" component="legend">Identifikationscode</FormLabel>
                <Box className="identifikation-code">
                    <TextField
                        id="identifikation-code-1"
                        variant="outlined"
                        size="small"
                        placeholder="----"
                        value={this.state.identifikationscodeFirst || ""}
                        onChange={(event) => {
                            if (event.target.value.length <= 4){
                                this.setState({
                                    identifikationscodeFirst: event.target.value
                                }, () => this.props.setIdentifkationsCode(this.state.identifikationscodeFirst, this.state.identifikationscodeSecond, this.state.identifikationscodeThird, this.state.identifikationscodeFourth))
                            }
                            if (event.target.value.length === 4) {
                                this.input2.current!.focus();
                            }
                        }}
                        // onBlur={() => this.props.dispatch_setBetriebsstaette(this.state.basisdatenBetriebsstaette)}
                        autoComplete="off"
                    />
                    <TextField
                        inputRef={this.input2}
                        id="identifikation-code-2"
                        variant="outlined"
                        size="small"
                        placeholder="----"
                        value={this.state.identifikationscodeSecond || ""}
                        onChange={(event) => {
                            if (event.target.value.length <= 4){
                                this.setState({
                                    identifikationscodeSecond: event.target.value
                                }, () => this.props.setIdentifkationsCode(this.state.identifikationscodeFirst, this.state.identifikationscodeSecond, this.state.identifikationscodeThird, this.state.identifikationscodeFourth))
                            }
                            if (event.target.value.length === 4) {
                                this.input3.current!.focus();
                            }
                        }}
                        // onBlur={() => this.props.dispatch_setBetriebsstaette(this.state.basisdatenBetriebsstaette)}
                        autoComplete="off"
                    />
                    <TextField
                        inputRef={this.input3}
                        id="identifikation-code-3"
                        variant="outlined"
                        size="small"
                        placeholder="----"
                        value={this.state.identifikationscodeThird || ""}
                        onChange={(event) => {
                            if (event.target.value.length <= 4){
                                this.setState({
                                    identifikationscodeThird: event.target.value
                                }, () => this.props.setIdentifkationsCode(this.state.identifikationscodeFirst, this.state.identifikationscodeSecond, this.state.identifikationscodeThird, this.state.identifikationscodeFourth))
                            }
                            if (event.target.value.length === 4) {
                                this.input4.current!.focus();
                            }
                        }}
                        // onBlur={() => this.props.dispatch_setBetriebsstaette(this.state.basisdatenBetriebsstaette)}
                        autoComplete="off"
                    />
                    <TextField
                        inputRef={this.input4}
                        id="identifikation-code-4"
                        variant="outlined"
                        size="small"
                        placeholder="----"
                        value={this.state.identifikationscodeFourth || ""}
                        onChange={(event) => {
                            if (event.target.value.length <= 4){
                                this.setState({
                                    identifikationscodeFourth: event.target.value
                                }, () => this.props.setIdentifkationsCode(this.state.identifikationscodeFirst, this.state.identifikationscodeSecond, this.state.identifikationscodeThird, this.state.identifikationscodeFourth))
                            }
                        }}
                        // onBlur={() => this.props.dispatch_setBetriebsstaette(this.state.basisdatenBetriebsstaette)}
                        autoComplete="off"
                    />
                </Box>
            </FormControl>
        )
    }
}
