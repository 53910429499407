import { Paper, Typography, Box, MenuItem, TextField, Button, FormControl, InputLabel, Select } from "@material-ui/core";
import {DatePicker} from '../../index';
import React from "react";
import { Geschlecht, Patient, VersichertenStatus, Personengruppe, DMPZuordnung, Krankenkasse } from "telescan-core";
import SimpleModalContainer from "../../../elements/simple_modal";
import { Autocomplete } from "@material-ui/lab";

interface IState {
    patient: Patient;
    strasse: string;
    hausnummer: string;
    isSelect: boolean;
    selectedInsurance: Krankenkasse;
}

interface IProps {
    isOpenNewModal: boolean;
    handleCloseEdit: () => void;
    createTelescanPatientAndKonsil: (patient: Patient) => void;
    //updateTelescanPatientAndCreateKonsil: (patient: Patient, previousPn: string) => void;
    selectedPatient: Patient | null;
    availabelInsuranceKim: Krankenkasse[];
}

export default class NewPatientGeneralDataModal extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            patient: new Patient(),
            strasse: "",
            hausnummer: "",
            isSelect: false,
            selectedInsurance: new Krankenkasse(),
        };
    }

    componentDidMount() {
        if (this.props.selectedPatient != null) {
            this.setState({
                patient: this.props.selectedPatient,
                selectedInsurance: this.props.selectedPatient.krankenkasse || new Krankenkasse(),
            })
        } else {
            this.setState({
                patient: new Patient(),
                selectedInsurance: new Krankenkasse()
            });
            // this.setState({
            //     patient: {
            //         ...this.state.patient,
            //         krankenkasse: {
            //             ...this.state.patient.krankenkasse,
            //             name: InsuranceDefault.NAME,
            //         }
            //     }
            // })
        }
       
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.selectedPatient !== this.props.selectedPatient) {
            if (this.props.selectedPatient != null) {
                this.setState({
                    patient: this.props.selectedPatient,
                    selectedInsurance: this.props.selectedPatient.krankenkasse || new Krankenkasse(),
                })
            } else {
                this.setState({
                    patient: new Patient(),
                    selectedInsurance: new Krankenkasse()
                });
                // this.setState({
                //     patient: {
                //         ...this.state.patient,
                //         krankenkasse: {
                //             ...this.state.patient.krankenkasse,
                //             name: InsuranceDefault.NAME,
                //         }
                //     }
                // })
            }
         
        } 
       
    }

    renderTextFieldPatient = (prop: string, label: string, id: string, evaluated: boolean) => {
        return (
            <TextField 
                id={id}
                label={label}
                value={this.state.patient?.[prop] || ""}
                onChange={(event) => {
                    let updatedPatient = {...this.state.patient} as Patient;
                    updatedPatient[prop] = event.target.value;
                    this.setState({
                        patient: updatedPatient
                    })
                }}
                error={evaluated ? (!this.state.patient?.[prop] || this.state.patient?.[prop] === "") : false}
            />
        )
    }

    renderSelectPatient = (prop: string, defaultValue: string, id: string, labelId: string, label: string, menuItems: JSX.Element[], evaluated: boolean) => {
        return (
            <FormControl
                required={true}
                error={evaluated ? (!this.state.patient?.[prop]) : false}
            >
                <InputLabel shrink id="gender-select-label">
                    {label}
                </InputLabel>
                <Select required
                        labelId={labelId}
                        id={id}
                        value={this.state.patient?.[prop] || defaultValue}
                        onChange={(event) => {
                            let updatedPatient = {...this.state.patient} as Patient;
                            updatedPatient[prop] = event.target.value;
                            this.setState({patient: updatedPatient});
                        }}
                        displayEmpty
                >
                    {menuItems.map(menuItem =>
                        menuItem
                    )}
                </Select>
            </FormControl>
        )
    }

    handleChangeSelect = (event: React.ChangeEvent<{ value: string }>) => {
        this.setState({
            patient: {
                ...this.state.patient,
                krankenkasse: {
                    ...this.state.patient.krankenkasse,
                    iknr: event.target.value 
                }
            }
        })
    }

    handleChangeSelectIknr = (_event: React.ChangeEvent<{ value: unknown }>, newValue: Krankenkasse | null) => {
        this.setState({
            patient: {
                ...this.state.patient,
                krankenkasse: {
                    ...this.state.patient.krankenkasse,
                    iknr: newValue?.iknr,
                    name: newValue?.name, 
                }
            },
            selectedInsurance: newValue || new Krankenkasse(),
        })
    }

    handleOpenSelect = () => {
        this.setState({isSelect: true});
    }

    handleCloseSelect = () => {
        this.setState({isSelect: false});
    }

    render() {
        const isButtonDisabled = !this.state.patient ||
            !this.state.patient.vorname || this.state.patient.vorname === "" ||
            !this.state.patient.nachname || this.state.patient.nachname === "" ||
            !this.state.patient.geschlecht ||
            !this.state.patient.geburtsdatum ||
            (this.state.patient.plz && this.state.patient.plz !== "" && this.state.patient.plz.length !== 5) ||
            !this.state.patient.krankenkasse?.name || this.state.patient.krankenkasse?.name === "" ||
            !this.state.patient.krankenkasse?.iknr || this.state.patient.krankenkasse?.iknr === "" ||
            (this.state.patient.krankenkasse && !this.props.availabelInsuranceKim.find(item => item.iknr === this.state.patient.krankenkasse?.iknr)) ||
            !this.state.patient.dmpZuordnung ||
            !this.state.patient.personengruppe ||
            !this.state.patient.versichertenStatus ||
            !this.state.patient.egkNummer || this.state.patient.egkNummer === "" ||
            !this.state.patient.externePatientenNummer || this.state.patient.externePatientenNummer === "";

        return (
            <SimpleModalContainer
                isOpen={this.props.isOpenNewModal}
                onClose={this.props.handleCloseEdit}
                additionalClassname="modal-profile-data"
            >
            <Paper className="modal-wrapper">
                <h2 id="transition-modal-title" className="modal-header">Patienten Informationen</h2>
                <Typography variant="caption" color="inherit" className="modal-subheader">
                    Bitte vervollständigen/überprüfen Sie die folgenden Patientendaten.
                    Die Patientendaten können manuell ausgefüllt, oder per GDT aus ihrem PVS importiert werden.
                    Bitte beachten Sie, dass eine Änderung der Patientendaten keine Änderung der Daten in Ihrem PVS bewirkt.
                </Typography>
                <Typography variant="caption" color="inherit" className="modal-subheader">
                    Aus vertragsrechtlichen Gründen kann die Software nur für teilnehmende Patienten des Haus- und Facharztprogramms der AOK Baden-Württemberg verwendet werden.
                </Typography>
                <Box className="modal-content">
                    <Box className="left-side">
                        <span className="full-span patient-additions">
                            {this.renderTextFieldPatient("title", "Titel", "patient-title", false)}
                            {this.renderTextFieldPatient("namenszusatz", "Namenszusatz", "patient-nb", false)}
                            {this.renderTextFieldPatient("vorsatzwort", "Vorsatzwort", "patient-vv", false)}
                        </span>
                        {this.renderTextFieldPatient("vorname", "Vorname", "patient-vorname", true)}
                        {this.renderTextFieldPatient("nachname", "Nachname", "patient-name", true)}
                        {this.renderSelectPatient("geschlecht", "", "gender-select", "gender-select-label", "Geschlecht",
                            Object.keys(Geschlecht).map(key =>
                                <MenuItem key={key} value={key}>
                                    {Geschlecht[key]}
                                </MenuItem>
                                ),
                            true)
                        }
                        <DatePicker 
                            isRequired = {true}
                            label="Geburtstag"
                            id="patient-birthday"
                            inputLabelProps={null}
                            initialDate={this.state.patient.geburtsdatum ? new Date(this.state.patient.geburtsdatum) : null}
                            onChange={(date: Date) => {
                                let updatedPatient = {
                                    ...this.state.patient,
                                    geburtsdatum: date?.getTime()
                                } as Patient
                                this.setState({
                                    patient: updatedPatient
                                })
                            }}
                            hasError={true}
                        />
                        {this.renderTextFieldPatient("strasseHausnummer", "Straße und Hausnummer", "patient-address-street", false)}
                        {/* {this.renderTextFieldPatient("plz", "PLZ", "patient-adress-plz", true)} */}
                        <TextField 
                            id = "plz"
                            label = "PLZ"
                            value={this.state.patient?.plz || ""}
                            onChange={(event) => {
                                let updatedPatient = {...this.state.patient} as Patient;
                                let value: string;
                                value = event.target.value.replace(" ", "").replace("-", "").replace(/[^0-9]+$/, "");
                                updatedPatient.plz = value;
                                this.setState({
                                    patient: updatedPatient
                                })
                            }}
                            error = {this.state.patient && this.state.patient.plz != null && this.state.patient.plz !== "" && this.state.patient.plz.length !== 5 }
                        />
                        {this.renderTextFieldPatient("wohnort", "Wohnort", "patient-adress-location", false)}
                    </Box>
                    <Box className="right-side">
                        <TextField
                            id = "insuranseName"
                            label = "Krankenkasse"
                            value = {this.state.patient.krankenkasse?.name || ""}
                            onChange={(event) => {
                                this.setState({
                                    patient: {
                                        ...this.state.patient,
                                        krankenkasse: {
                                            ...this.state.patient.krankenkasse,
                                            name: event.target.value
                                        }
                                    }
                                })
                            }}
                            error = {!this.state.patient.krankenkasse?.name || this.state.patient.krankenkasse?.name === ""}
                        />
                        {/* <TextField
                            id = "iknr"
                            label = "Kassen-IKNr"
                            value = {this.state.patient.krankenkasse?.iknr || ""}
                            onChange={(event) => {
                                this.setState({
                                    patient: {
                                        ...this.state.patient,
                                        krankenkasse: {
                                            ...this.state.patient.krankenkasse,
                                            iknr: event.target.value
                                        }
                                    }
                                })
                            }}
                            error = {!this.state.patient.krankenkasse?.iknr || this.state.patient.krankenkasse?.iknr === "" || 
                            (this.state.patient.krankenkasse && !aokIknrList.includes(this.state.patient.krankenkasse.iknr))}
                        /> */}
                        {/* <FormControl
                            fullWidth
                            error = {!this.state.patient.krankenkasse?.iknr || this.state.patient.krankenkasse?.iknr === "" || 
                            (this.state.patient.krankenkasse && !aokIknrList.includes(this.state.patient.krankenkasse.iknr))}
                        >
                            <InputLabel id = "simple-select-label">Kassen-IKNr</InputLabel>
                            <Select
                                variant = "standard"
                                labelId = "simple-select-label"
                                id = "iknr"
                                value = {this.state.patient.krankenkasse?.iknr || ""}
                                label="Kassen-IKNr"
                                open = {this.state.isSelect}
                                onOpen = {this.handleOpenSelect}
                                onClose = {this.handleCloseSelect}
                                onChange={this.handleChangeSelect}                                           
                            >
                                {aokIknrList.map(item => {
                                    return (
                                        <MenuItem value = {item}>{item}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl> */}
                        <Autocomplete
                            id="available-insurance"
                            options = {this.props.availabelInsuranceKim}
                            autoHighlight
                            //open = {props.isDermaSearchOpen}
                            //onOpen = {() => props.handleOpenClose(true)}
                            //onClose = {() => props.handleOpenClose(false)}
                            //onInputChange={(_event: any, _newInputValue, reason) => props.handleInputChange(reason)}
                            //loading = {props.isDermaListLoading}
                            getOptionLabel={(option) => option.iknr || ""}
                            getOptionSelected = {(option, value) =>  option.iknr === value.iknr}
                            value = {this.state.selectedInsurance}
                            renderOption={(option) => (
                                <React.Fragment>
                                    {`${option.iknr || ""} ${option.name || ""}`}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label = "Kassen-IKNr"
                                    //placeholder = "Kassen-IKNr suchen und auswählen"
                                    error = {!this.state.patient.krankenkasse?.iknr || this.state.patient.krankenkasse?.iknr === "" || 
                                    (this.state.patient.krankenkasse && !this.props.availabelInsuranceKim.find(item => item.iknr === this.state.patient.krankenkasse?.iknr))}
                                />
                            )}
                            onChange={this.handleChangeSelectIknr}
                            noOptionsText = "Keine verfügbaren Krankenkassen gefunden"
                           
                        />
                       
                        {this.renderTextFieldPatient("egkNummer", "eGK-Versichertennummer", "patient-egk-nr", true)}
                        {this.renderSelectPatient("versichertenStatus", "", "status-select", "status-select-label", "Versichertenstatus",
                            Object.keys(VersichertenStatus).map(key =>
                                <MenuItem key={key} value={key}>
                                    {VersichertenStatus[key]}
                                </MenuItem>
                                ),
                            true)
                        }
                        {this.renderSelectPatient("personengruppe", "", "patient-group-select", "patient-group-select-label", "Personengruppe",
                            [<MenuItem key="PG_00" value="PG_00">{Personengruppe.PG_00}</MenuItem>,
                                <MenuItem key="PG_04" value="PG_04">{Personengruppe.PG_04}</MenuItem>,
                                <MenuItem key="PG_06" value="PG_06">{Personengruppe.PG_06}</MenuItem>,
                                <MenuItem key="PG_07" value="PG_07">{Personengruppe.PG_07}</MenuItem>,
                                <MenuItem key="PG_08" value="PG_08">{Personengruppe.PG_08}</MenuItem>,
                                <MenuItem key="PG_09"
                                            value="PG_09">{Personengruppe.PG_09}</MenuItem>],
                                    true)
                        }
                        {this.renderSelectPatient("dmpZuordnung", "", "dmp-select", "dmp-select-label", "DMP-Zuordnung",
                            [<MenuItem key="DMP_00" value="DMP_00">{DMPZuordnung.DMP_00}</MenuItem>,
                                <MenuItem key="DMP_01" value="DMP_01">{DMPZuordnung.DMP_01}</MenuItem>,
                                <MenuItem key="DMP_02" value="DMP_02">{DMPZuordnung.DMP_02}</MenuItem>,
                                <MenuItem key="DMP_03" value="DMP_03">{DMPZuordnung.DMP_03}</MenuItem>,
                                <MenuItem key="DMP_04" value="DMP_04">{DMPZuordnung.DMP_04}</MenuItem>,
                                <MenuItem key="DMP_05" value="DMP_05">{DMPZuordnung.DMP_05}</MenuItem>,
                                <MenuItem key="DMP_06" value="DMP_06">{DMPZuordnung.DMP_06}</MenuItem>,
                                <MenuItem key="DMP_07" value="DMP_07">{DMPZuordnung.DMP_07}</MenuItem>,
                                <MenuItem key="DMP_08" value="DMP_08">{DMPZuordnung.DMP_08}</MenuItem>,
                                <MenuItem key="DMP_09"
                                            value="DMP_09">{DMPZuordnung.DMP_09}</MenuItem>],
                                    true)
                        }
                        {this.renderTextFieldPatient("externePatientenNummer", "Patientennummer", "patient-identifikator", true)}
                    </Box>
                </Box>

                    <Box className="modal-footer">
                        <Button
                            color="primary"
                            onClick={() => {
                                this.props.handleCloseEdit();
                            }}
                        >
                            Abbrechen
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                this.props.handleCloseEdit();
                                if (!this.props.selectedPatient) {
                                    this.props.createTelescanPatientAndKonsil(this.state.patient)
                                } else {
                                    //this.props.updateTelescanPatientAndCreateKonsil(this.state.patient, this.state.previousPn)
                                }
                                
                                //this.props.updateKonsilPatient(this.state.patient);
                            }}
                            disabled = {isButtonDisabled}
                        >
                            Weiter
                        </Button>
                    </Box>
                </Paper>
            </SimpleModalContainer>
        )
    }
}
                      