import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';
import uuid from 'uuid';

import { Arzt, ArztService, UserTypes, UserService, KonsilEmpfaengerGruppe, Failure } from "telescan-core";
import * as GeneralTypes from '../types/general_types';
import {RootState} from '../reducers';
import {loadSuccess} from '.';
import { setBetriebsstaettenAerzte, setUser } from './login_actions';
import { uploadKonsilImageProgress } from './image_attachment_actions';
import { failure } from './general_actions';


export function loadKonsiliare(suchtext: string, empfaengerTyp: 'NAME' | 'STRASSE' | 'PLZ' | 'ORT' | 'BSNR'): ThunkAction<Promise<Arzt[]>, RootState, unknown, Action<string>> {
    return dispatch => {
        const id: string = uuid.v4();
        dispatch({
            type: GeneralTypes.EGeneralActions.LOADING,
            id: id
        });

        const arztService: ArztService = new ArztService();
        return new Promise((resolve, reject) => {
            arztService.searchKonsiliare(suchtext, empfaengerTyp).then(
                (konsiliare) => {
                    dispatch(loadSuccess(id));
                    resolve(konsiliare);
                }
            ).catch(
                (response) => {
                    dispatch(failure(id, response));
                    //reject(response);
                }
            );
        });
    }
}

export function loadKonsiliarGruppen(): ThunkAction<Promise<KonsilEmpfaengerGruppe[]>, RootState, unknown, Action<string>> {
    return dispatch => {
        const arztService: ArztService = new ArztService();
        return new Promise((resolve, reject) => {
            arztService.searchKonsiliarGruppen().then(
                (gruppen) => {
                    resolve(gruppen);
                    //search(gruppen, args[0]);
                }
            ).catch(
                (response) => {
                    const id: string = uuid.v4();
                    dispatch(failure(id, response));
                }
            );
        });
    }
}


export function getUpdatesInAerzte(kontext: UserTypes): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const loadInstanceId: string = uuid.v4();
        const userService: UserService = new UserService();
        userService.getUserDetails(kontext)
            .then(
                (user) => {
                    if (user.aerzte) {
                        if (user.aerzte.length > 1) // if context type bsnr
                            dispatch(setBetriebsstaettenAerzte(user.aerzte))
                        else if (user.aerzte.length === 1) {
                            //dispatch(updateArzt(user.aerzte[0]))
                            const arzt = user.aerzte[0];
                            const newBsAerzte = getState().user.betriebsstaettenAerzte.map((item) => {
                                if (item.lanr === arzt.lanr)
                                    return arzt
                                else
                                    return item
                            })
                            dispatch(setBetriebsstaettenAerzte(newBsAerzte));
                        }
                        dispatch(setUser(user.aerzte))
                    }
                }).catch(
            (response) => {
                dispatch(failure(loadInstanceId, response));
            }
        );
    };
}

export function updateCDAsHPM(): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const loadInstanceId: string = uuid.v4();
        const arztService: ArztService = new ArztService();
        arztService.getCDA(getState().webSocket.clientId)
        .catch((failureObj: Failure) => {
            dispatch(failure(loadInstanceId, failureObj));
        });
    }
}

export function uploadAvatarImage(file: File, loadInstanceId: string): ThunkAction<void, RootState, unknown, Action<string>> {
    return (dispatch, getState) => {
        const arztService: ArztService = new ArztService();
        arztService.uploadAvatarImageAsBlob(file, (event) => { // would be easier if this returned the updated arzt (I wouldnt need to call userDetails route)
            dispatch(uploadKonsilImageProgress(file.name, event.percent));
        }).then(
            () => {
                let kontext = (getState().user.role === "BETRIEBSSTAETTE_HAUSARZT" || getState().user.role === "BETRIEBSSTAETTE_DERMATOLOGE")?
                                UserTypes.BETRIEBSSTAETTE: UserTypes.ARZT;
                dispatch(getUpdatesInAerzte(kontext))
            }).catch(
            (response) => {
                dispatch(failure(loadInstanceId, response));
            }
        );
    };
}

export function loadAvatarImage(avatar: any): any {
    return "data:image/png;base64," + avatar
}
