import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import { konsil_reducer } from './konsil_reducer';
import { user_reducer } from './user_reducer';
import { zertifikat_reducer } from './zertifikat_reducer';
import { createBrowserHistory } from 'history';
import { general_reducer } from './general_reducer';
import { dermatologe_reducer } from './dermatologe_reducer';
import { update_reducer } from './update_reducer';
import { abrechnung_reducer } from './abrechnung_reducer';
import { websocket_reducer } from './websocket_reducer';
import { send_download_konsil_reducer } from './upload_download_konsil_reducer';
import { icd10_reducer } from './icd10_reducer';
import { evaluation_reducer } from './evaluation_reducer';
import { image_attachment_reducer } from './image_attachment_reducer';
import { pdf_reducer } from './pdf_reducer';
import { notification_reducer } from './notification_reducer';
import { kim_reducer } from './kim_reducer';
import { gdt_reducer } from './gdt_reducer';
import { ti_connector_reducer } from './ti_connector_reducer';


export const history = createBrowserHistory();
export const rootReducer = combineReducers({
    zertifikate: zertifikat_reducer,
    konsile: konsil_reducer,
    general: general_reducer,
    user: user_reducer,
    dermatologe: dermatologe_reducer,
    router: connectRouter(history),
    update: update_reducer,
    quartalsabrechnungenStore: abrechnung_reducer,
    webSocket: websocket_reducer,
    send_download_konsil: send_download_konsil_reducer,
    icd10: icd10_reducer,
    evaluation: evaluation_reducer,
    image_attachment: image_attachment_reducer,
    pdf: pdf_reducer,
    notifications: notification_reducer,
    kim: kim_reducer,
    gdt: gdt_reducer,
    ti_connector: ti_connector_reducer,
})

export type RootState = ReturnType<typeof rootReducer>


export const resetEnhancer = rootReducer => (state, action) => {
    
    if (action.type !== 'RESET') return rootReducer(state, action);

    const newState = rootReducer(undefined, {});
    newState.router = state.router;
    return newState;
  };
