import React from 'react';


export interface WithIdentifikationscodeMethodsProps {
    identifikationscodeHA: string;
    identifikationscodeDerma: string;
    setIdentifikationscode: (idCodeFirst: string, idCodeSecond: string, idCodeThird: string, idCodeFourth: string) => void
}

export function withIdentifikationscodeMethods(Component) {
    type Props = {}
    type State = {
        identifikationscodeHA: string;
        identifikationscodeDerma: string;
    }

    // ...and returns another component...
    return class extends React.Component<Props, State> {
        constructor(props: Props) {
            super(props);
            this.state = {
                identifikationscodeHA: "",
                identifikationscodeDerma: "",
            };

            this.setIdentifikationscode = this.setIdentifikationscode.bind(this);
        }

        setIdentifikationscode(idCodeFirst: string, idCodeSecond: string, idCodeThird: string, idCodeFourth: string) {
            this.setState({
                identifikationscodeHA: [idCodeFirst, idCodeSecond, idCodeThird, idCodeFourth].join(" "),
                identifikationscodeDerma: [idCodeFirst, idCodeSecond, idCodeThird, idCodeFourth].join("")
            })
        }
    

        render() {
            return (
                <Component 
                    identifikationscodeHA={this.state.identifikationscodeHA} 
                    identifikationscodeDerma={this.state.identifikationscodeDerma} 
                    setIdentifikationscode={this.setIdentifikationscode}
                    {...(this.props as any)}
                />
            )
        }
    };
}
