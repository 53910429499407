import { Failure, Konsil, KonsilMetaInformation, KonsilSortSearchFilterParams, KonsilUpdateConflictData, KonsilValidationBeforeSendStatusTypes, Krankenkasse} from "telescan-core";
import {EKonsilActions, TKonsilActionTypes} from '../types/konsil_types';
import { Konsiliarbericht } from "telescan-core/lib/entities/konsil_abschluss";

export interface IKonsilState {
    konsile: Konsil[];
    current_konsil: Konsil;
    tabIndex: number;
    metadaten: KonsilMetaInformation[];
    totalSize: number;
    sendFailures: {
        konsilID: string,
        failure: Failure
    }[];
    cdaSize: number;
    isKonsilDeleted: boolean;
    konsilSortSearchFilterParams: KonsilSortSearchFilterParams;
    konsilValidationStatus: keyof typeof KonsilValidationBeforeSendStatusTypes | null;
    konsilValidationError: Failure;
    isKonsilUpdatedEventShown: boolean;
    isKonsilUpdateEventSuccess: boolean;
    isKonsilUpdateConflict: boolean;
    isKonsilUpdateConflictDialog: boolean;
    konsilUpdateEventText: string;
    konsilUpdateConflictData: KonsilUpdateConflictData[];
    konsilUpdateConflictSelectedData: string[];
    availableInsuranceKim: Krankenkasse[];
}

const initialState: IKonsilState = {
    konsile: [],
    current_konsil: new Konsil(),
    tabIndex: 0,
    metadaten: [],
    totalSize: 0,
    sendFailures: [],
    cdaSize: -1,
    isKonsilDeleted: false,
    konsilSortSearchFilterParams: new KonsilSortSearchFilterParams(),
    konsilValidationStatus: null,
    konsilValidationError: new Failure(),
    isKonsilUpdatedEventShown: false,
    isKonsilUpdateEventSuccess: false,
    isKonsilUpdateConflict: false,
    isKonsilUpdateConflictDialog: false,
    konsilUpdateEventText: "",
    konsilUpdateConflictData: [],
    konsilUpdateConflictSelectedData: [],
    availableInsuranceKim: [],
}

export function konsil_reducer(state = initialState, action: TKonsilActionTypes): IKonsilState {
    switch (action.type) {
        case EKonsilActions.ADD_KONSIL:
            return {
                ...state,
                konsile: [...state.konsile, action.konsil]
            }
        case EKonsilActions.REMOVE_KONSIL:
            return {
                ...state,
                konsile: state.konsile.filter(
                    konsil => konsil.id !== action.id
                )
            }
        case EKonsilActions.SET_KONSILE:
            return {
                ...state,
                konsile: action.konsile
            }
        case EKonsilActions.SET_KONSIL:
            return {
                ...state,
                current_konsil: action.konsil
            }
        case EKonsilActions.TAB_CHANGED:
            return {
                ...state,
                tabIndex: action.tabIndex
            }
      
        case EKonsilActions.UPDATE_KONSIL_PATIENT:
            return {
                ...state,
                current_konsil: {
                    ...state.current_konsil,
                    konsilPatient: action.patient,
                    konsilImages: state.current_konsil?.konsilImages || []
                }
            }
        case EKonsilActions.SET_KONSILE_TOTAL_SIZE:
            return {
                ...state,
                totalSize: action.totalSize
            }
        case EKonsilActions.ADD_SEND_FAILURE:
            return {
                ...state,
                sendFailures: [
                    ...state.sendFailures,
                    {failure: action.failure, konsilID: action.konsilID}
                ]
            }
        case EKonsilActions.REMOVE_SEND_FAILURE:
            return {
                ...state,
                sendFailures: state.sendFailures.filter(failure => failure.konsilID !== action.konsilID)
            }
        case EKonsilActions.SET_CDA_SIZE:
            return {
                ...state,
                cdaSize: action.cdaSize
            }
        case EKonsilActions.SET_CURRENT_KONSIL_BEARBEITUNGSSTATUS:
            return {
                ...state,
                current_konsil: {
                    ...state.current_konsil,
                    bearbeitungsStatus: action.bearbeitungsStatus,
                },
            }
            
        case EKonsilActions.SET_KONSIL_BEARBEITUNGSSTATUS:
            const currentkonsilIndexBS = state.konsile.findIndex(item => item.id === action.konsilId);
            const newKonsileArrayBS = [...state.konsile];
            newKonsileArrayBS[currentkonsilIndexBS].bearbeitungsStatus = action.bearbeitungsStatus;
            return {
                ...state,
                konsile: newKonsileArrayBS
            }

        case EKonsilActions.SET_KONSIL_STATUS:
            const currentkonsilIndexKS = state.konsile.findIndex(item => item.id === action.konsilId);
            const newKonsileArrayKS = [...state.konsile];
            newKonsileArrayKS[currentkonsilIndexKS].konsilStatus = action.konsilStatus;
            return {
                ...state,
                konsile: newKonsileArrayKS
            }

        case EKonsilActions.SET_KONSIL_THERAPY_DRINGLICHKEIT:
            const konsilIndexTD = state.konsile.findIndex(item => item.id === action.konsilId);
            const newKonsileArrayTD = [...state.konsile];
            if (newKonsileArrayTD[konsilIndexTD].konsiliarbericht)
                newKonsileArrayTD[konsilIndexTD].konsiliarbericht!.therapieDringlichkeit = action.therapyDringlichkeit;
            else {
                newKonsileArrayTD[konsilIndexTD].konsiliarbericht = new Konsiliarbericht();
                newKonsileArrayTD[konsilIndexTD].konsiliarbericht!.therapieDringlichkeit = action.therapyDringlichkeit;
            }
            return {
                ...state,
                konsile: newKonsileArrayTD,
            }

        case EKonsilActions.SET_CURRENT_KONSIL_THERAPY_DRINGLICHKEIT:
            return {
                ...state,
                current_konsil: {
                    ...state.current_konsil,
                    konsiliarbericht: {
                        ...state.current_konsil.konsiliarbericht,
                        therapieDringlichkeit: action.therapyDringlichkeit,
                    }
                }
            }

        case EKonsilActions.SET_IS_KONSIL_DELETED:
            return {
                ...state,
                isKonsilDeleted: action.isKonsilDeleted
            }

        case EKonsilActions.SET_KONSIL_SORT_SEARCH_FILTER_PARAMS:
            return {
                ...state,
                konsilSortSearchFilterParams: action.konsilSortSearchFilterParams
            }

        case EKonsilActions.SET_KONSIL_STATUS_FILTER:
            return {
                ...state,
                konsilSortSearchFilterParams: {
                    ...state.konsilSortSearchFilterParams,
                    konsilStatus: action.konsilStatusFilter,
                }
            }

        case EKonsilActions.SET_KONSIL_SEARCH_STRING:
            return {
                ...state,
                konsilSortSearchFilterParams: {
                    ...state.konsilSortSearchFilterParams,
                    search: action.searchString,
                }
            }

        case EKonsilActions.SET_KONSIL_VALIDATION_STATUS: {
            return {
                ...state,
                konsilValidationStatus: action.konsilValidationStatus,
                konsilValidationError: action.konsilValidationError,
            }
        }
        
        case EKonsilActions.SET_IS_KONSIL_UPDATED_EVENT_SHOWN: {
            return {
                ...state,
                isKonsilUpdatedEventShown: action.isKonsilUpdatedEventShown
            }
        }

        case EKonsilActions.SET_IS_KONSIL_UPDATE_EVENT_SUCCESS: {
            return {
                ...state,
                isKonsilUpdateEventSuccess: action.isKonsilUpdateEventSuccess
            }
        }

        case EKonsilActions.SET_IS_KONSIL_UPDATE_CONFLICT: {
            return {
                ...state,
                isKonsilUpdateConflict: action.isKonsilUpdateConflict
            }
        }

        case EKonsilActions.SET_IS_KONSIL_UPDATE_CONFLICT_DIALOG: {
            return {
                ...state,
                isKonsilUpdateConflictDialog: action.isKonsilUpdateConflictDialog
            }
        }

        case EKonsilActions.SET_KONSIL_UPDATE_CONFLICT_DATA: {
            return {
                ...state,
                konsilUpdateConflictData: action.konsilUpdateConflictData
            }
        }

        case EKonsilActions.SET_KONSIL_UPDATE_CONFLICT_SELECTED_DATA: {
            return {
                ...state,
                konsilUpdateConflictSelectedData: action.konsilUpdateConflictSelectedData
            }
        }

        case EKonsilActions.SET_AVAILABLE_INSURANCE_KIM: {
            return {
                ...state,
                availableInsuranceKim: action.availableInsuranceKim
            }
        }

        default:
            return state
    }
}
