import { Box, Button, Checkbox, FormControlLabel, Paper, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { Failure, Konsil, KonsilValidationBeforeSendStatusTypes, TIConnectorEvent, UploadPath } from 'telescan-core';
import ConnectionBadge from '../../elements/connection_badge';
import { KonsilValidationBadge } from '../../elements/konsil_validation_badge';
import PdfInline from '../../elements/pdf_inline';
import SimpleModalContainer from '../../elements/simple_modal';
import ZertifikatPasswortComponent from '../../elements/zertifikat_passwort';
import { SignatureDialogContainer } from '../../settings/kim_configuration/signature_dialog';

interface IVersandProps {
    currentKonsil: Konsil;
    openModal: boolean;
    password: string;
    pdfPreviewData: any;
    konsilId: string;
    toggleModal: () => void;
    handleVersenden: () => void;
    handlePasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    error?: string;
    dispatch_clearPdfString: () => void;
    isComfortVersand: boolean;
    comfortVersandPassword: string;
    dispatch_setIsComfortVersand: (isComfortVersand: boolean) => void;
    dispatch_setComfortVersandPassword: (comfortVersandPassword: string) => void;
    isPdfLoading: boolean;
    konsilValidationStatus: keyof typeof KonsilValidationBeforeSendStatusTypes | null;
    konsilValidationError: Failure;
    hasPasswordChanged: boolean;
    dispatch_getKimConnectionStatus: () => Promise<any>;
    isKimConnected: boolean;
    dispatch_setIsKimConnected: (isKimConnected: boolean) => void;
    isKonsilSigned: boolean;
    tiConnectorEvent: TIConnectorEvent;
    isSignatureDialogOpen: boolean;
    handleSignatureDialogOpen: () => void;
    handleSignatureDialogClose: () => void;
}

export class KonsiliarberichtVersandPopup extends React.Component<IVersandProps> {
    isSendButtonDisabled = (): boolean => {
        if (this.props.currentKonsil.uploadPath === UploadPath.HPM || !this.props.currentKonsil.uploadPath) {
            return !this.props.pdfPreviewData || this.props.pdfPreviewData === "" 
            || (this.props.password === "" && this.props.comfortVersandPassword === "") 
            || this.props.error != null || this.props.konsilValidationStatus !== "OK";
        } else if(this.props.currentKonsil.uploadPath === UploadPath.KIM) {
            return !this.props.pdfPreviewData || this.props.pdfPreviewData === "" ||
            this.props.error != null || this.props.konsilValidationStatus !== "OK" ||
            !this.props.isKimConnected;
        }
        return true;
    }
    render() {
        return (
            <SimpleModalContainer isOpen={this.props.openModal}>
                <Paper className="modal-wrapper reciever-pop">
                    <Box className="reciever-split">
                        <Box className="form-content">
                            <KonsilValidationBadge
                                konsilValidationStatus = {this.props.konsilValidationStatus || null}
                                konsilValidationError = {this.props.konsilValidationError}
                            />
                            <Box className="modal-header">
                                {this.props.currentKonsil.uploadPath === UploadPath.KIM &&
                                    <ConnectionBadge
                                       dispatch_checkConnection = {this.props.dispatch_getKimConnectionStatus}
                                       uploadPath = 'KIM'
                                       isConnected = {this.props.isKimConnected}
                                       dispatch_setIsConnected = {this.props.dispatch_setIsKimConnected}
                                   />
                                }
                             
                                <h2>Konsiliarberichtversand</h2>   
                            </Box>
                            
                            <Box className="modal-content">
                                {this.props.error &&
                                    <Typography className="modal-content failure">{this.props.error}</Typography>
                                }
                                <Typography className="last-reciver-label" variant="body1" color="inherit">
                                    Eine Zusammenfassung des Konsilauftrags wurde erstellt. Bitte überprüfen Sie den
                                    Konsilauftrag (.pdf) auf Richtigkeit.
                                </Typography>
                                {(this.props.currentKonsil.uploadPath === UploadPath.HPM || !this.props.currentKonsil.uploadPath) &&
                                    <>
                                        <ZertifikatPasswortComponent
                                            password = {(this.props.isComfortVersand && this.props.comfortVersandPassword !== "" && !this.props.hasPasswordChanged) ? this.props.comfortVersandPassword : this.props.password}
                                            handlePasswordChange={this.props.handlePasswordChange}
                                        />
                                        <Tooltip
                                            title = "Passwort für die aktuelle Sitzung speichern"
                                            placement = 'top'
                                        >
                                            <FormControlLabel
                                                control =
                                                    {<Checkbox
                                                        name="comfort-versand-checkbox"
                                                        checked = {this.props.isComfortVersand}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            if (!event.target.checked)
                                                                this.props.dispatch_setComfortVersandPassword("");
                                                            this.props.dispatch_setIsComfortVersand(!this.props.isComfortVersand);
                                                        }}
                                                    />}
                                                label={<Typography variant="body2" color="inherit">Passwort merken</Typography>}
                                            />
                                        </Tooltip>
                                    </>
                                }
                                {this.props.currentKonsil.uploadPath === UploadPath.KIM &&
                                    <Typography className="failure last-reciver-label" variant="body1">
                                        Bereiten Sie Ihr persönliches eHBA vor. Sie werden aufgefordert, den Konsilauftrag damit zu unterschreiben.
                                    </Typography>                                          
                                }

                                <SignatureDialogContainer
                                    isOpen = {this.props.isSignatureDialogOpen}
                                    handleClose = {this.props.handleSignatureDialogClose}
                                    tiConnectorEvent = {this.props.tiConnectorEvent}
                                    forSettings = {false}
                                    triggerSendOnSignSuccess = {this.props.handleVersenden}
                                />
                            </Box>
                            <Box className="modal-footer">
                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={() => {
                                        this.props.toggleModal();
                                        this.props.dispatch_clearPdfString();
                                    }}
                                >
                                    Abbrechen
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={this.isSendButtonDisabled()}
                                    onClick={() => {
                                        if (this.props.currentKonsil.uploadPath === UploadPath.HPM || !this.props.currentKonsil.uploadPath) {
                                            this.props.handleVersenden();
                                            if (this.props.isComfortVersand && (this.props.comfortVersandPassword === "" || this.props.hasPasswordChanged))
                                                this.props.dispatch_setComfortVersandPassword(this.props.password);
                                        }
                                        if (this.props.currentKonsil.uploadPath === UploadPath.KIM) {
                                            this.props.handleSignatureDialogOpen();
                                        }
                                        //this.props.dispatch_clearPdfString();
                                        
                                    }}
                                >
                                    Versenden
                                </Button>
                            </Box>
                        </Box>
                        <PdfInline pdfData={this.props.pdfPreviewData} isPdfLoading = {this.props.isPdfLoading} header = "Zusammenfassung Konsilauftrag"/>
                    </Box>
                </Paper>
            </SimpleModalContainer>
        )
    }
}
