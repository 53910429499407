import React from 'react';
import {connect, ConnectedProps} from 'react-redux'
import uuid from 'uuid';
import {Box, Button, IconButton, InputAdornment, Link, Popover, Snackbar, TextField, Tooltip, Typography } from '@material-ui/core';
import Logo from '../../static/images/logo-telescan.svg';
import Ellipse from '../../static/images/login-ellipse.svg';
import Triangle from '../../static/images/login_triangle.svg';
import {login, failure, removeLoadFailure, navigate, getInfo, getServerStatus, getHPMInfo, setIsHpmAvailable} from '../../redux/actions';
import {Alert} from '@material-ui/lab';
import {RootState} from '../../redux/reducers';
import {TelescanConfig, UserService, Failure, TestVersion, UploadPath} from 'telescan-core';
import { RecoveryCodeComponent } from '../initalisation/recovery_code';
import NewPasswordContainer from '../elements/new_password';
import { CheckboxComponent } from '../initalisation/passwort';
import { withNewPasswordMethods, WithNewPasswordMethodsProps, removeWhiteSpaces } from '../../utils';

import HinweisIcon from '../../static/images/ic-hinweis.svg';
import CE from '../../static/images/ic_CE.svg'
import Skin from '../../static/images/graf-skin.svg'
import alertIcon from '../../static/images/ic-hinweis.svg';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import ConnectionBadge from '../elements/connection_badge';

interface IState {
    bsnr: string;
    lanr: string | null;
    password: string;
    isVisibleForgetPW: boolean;
    recoveryCode: string;
    newRecoveryCode: string;
    isReset: boolean;
    isPasswordShown: boolean;
}

const mapStateToProps = (state: RootState) => ({
    failures: state.general.failures,
    einverstandnis: state.user.einverstaendnis,
    kennzeichnung: state.user.kennzeichnung,
    fachrichtung: state.user.fachrichtung,
    hasRiskNotification: state.general.hasRiskNotification,
    isHPMconnected: state.general.isHpmAvailable,
    initialisedServices: state.general.initialisedServices,
})

const mapDispatchToProps = {
    dispatch_login: (bsnr: string, password: string, lanr: string | null) => login(bsnr, password, lanr),
    dispatch_loadFailure: (id:string, error: Failure) => failure(id, error),
    dispatch_removeFailure: (id: string) => removeLoadFailure(id),
    dispatch_navigate: (url: string) => navigate(url),
    dispatch_getInfo: () => getInfo(),
    dispatch_getServerStatus: () => getServerStatus(),
    dispatch_getHPMInfo: () => getHPMInfo(),
    dispatch_setIsHpmAvailable: (isConnected: boolean) => setIsHpmAvailable(isConnected)
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>
type Props = TPropsFromRedux & WithNewPasswordMethodsProps

export class Login extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            bsnr: localStorage.getItem('BSNR') || "",
            lanr: localStorage.getItem('LANR') || null,
            password: "",
            isVisibleForgetPW: false,
            recoveryCode: "",
            newRecoveryCode: "",
            isReset: false,
            isPasswordShown: false,
        };

        this.handleBsnrChange = this.handleBsnrChange.bind(this);
        this.handleLanrChange = this.handleLanrChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleRecoveryCodeChange = this.handleRecoveryCodeChange.bind(this);
        this.handleLoginClick = this.handleLoginClick.bind(this);
        this.handlePasswordResetClick = this.handlePasswordResetClick.bind(this);
        this.renderButtonArea = this.renderButtonArea.bind(this);
    }

    componentDidMount() {
        this.props.failures.forEach((element: {id: string, failure: Failure}) => {
            this.props.dispatch_removeFailure(element.id);
        });
        this.props.dispatch_getInfo();
        this.props.dispatch_getServerStatus();
    }

    /* handleBsnrChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        this.setState({bsnr: e.target.value});
    }

    handleLanrChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        this.setState({lanr: e.target.value});
    } */

    handleBsnrChange(e) {
        const cleanedBsnr = removeWhiteSpaces(e.target.value)
        this.setState({bsnr: cleanedBsnr});
    }

    handleLanrChange(e) {
        const cleanedLanr = removeWhiteSpaces(e.target.value)
        this.setState({lanr: cleanedLanr});
    }

    handlePasswordChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        this.setState({password: e.target.value});
        TelescanConfig.getInstance().setConstant("PASSWORD", e.target.value);
    }

    handleRecoveryCodeChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        this.setState({recoveryCode: e.target.value});
    }

    handleLoginClick(e: React.MouseEvent<HTMLElement, MouseEvent>, password: string) {
        e.preventDefault();
        const id: string = uuid.v4();

        const {bsnr, lanr} = this.state;
        if (bsnr && password) {
            this.props.dispatch_login(bsnr, password, lanr).catch( (error) => {
                //this.props.dispatch_loadFailure(id, error);
                //this.props.dispatch_navigate("/login")
            });
        } else {
            const failure = new Failure();
            failure.error = "Login fehlgeschlagen! Bitte geben Sie mindestens Ihre BSNR und Ihr Passwort ein.";
            this.props.dispatch_loadFailure(id, failure);
        }
    }

    handlePasswordResetClick(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        e.preventDefault();
        const loadInstanceId: string = uuid.v4();
        const userService = new UserService();
        userService.changePasswordRecoveryCode(this.state.bsnr, this.props.newPassword, this.state.recoveryCode).then((response) => {
            this.setState({
                newRecoveryCode: response.recoveryCode,
                isReset: true
            })
        }).catch((error) => {
            this.props.dispatch_loadFailure(loadInstanceId, error);
        })
    }


    renderButtonArea() {
        if (this.state.isVisibleForgetPW && !this.state.isReset)
            return (
                <Box className="button-container">
                    <Button variant="contained" type="submit" color="primary" className="password-btn"
                            disabled={(this.state.recoveryCode === "" || this.props.newPassword === "" || this.props.newRepeatedPassword === "" ||
                            this.props.newPassword !== this.props.newRepeatedPassword || this.props.passwordStrength !== "strong")}
                            onClick={this.handlePasswordResetClick}>
                        Passwort setzen
                    </Button>
                    <Button variant="text" type="submit" color="default" className="password-btn"
                            onClick={() => this.setState({isVisibleForgetPW: false})}>
                        Abbrechen
                    </Button>
                </Box>
            )
        else if (this.state.isReset)
            return (
            <>
                <Popover
                            id="recovery-popover"
                            open={true}
                            anchorEl={null}
                            className="recovery-popup-content"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}>
                    <RecoveryCodeComponent isPasswordReset={true} checkboxComp={<CheckboxComponent/>} recoveryCode={this.state.newRecoveryCode}/>
                    <Box className="button-container">
                        <Button variant="contained" type="submit" color="primary" className="login-btn"
                                disabled={this.props.einverstandnis === false}
                                onClick={(e) => this.handleLoginClick(e, this.props.newPassword)}>
                            Anmelden
                        </Button>
                    </Box>
                </Popover>
            </>
            )
        else // (!this.state.isVisibleForgetPW)
            return (
                <Box className="button-container">
                    <Button variant="contained" type="submit" color="primary" className="login-btn"
                            onClick={(e) => this.handleLoginClick(e, this.state.password)}>
                        Anmelden
                    </Button>
                </Box>
            )
    }

    render() {
        const uniqueFailures = Array.from(new Set(this.props.failures.map(item => item.failure.error))) // remove duplicated failure before showing them to user
        .map(item => {
            return this.props.failures.find(failureObj => failureObj.failure.error === item)
        });
        return (
            <div>
                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "right"}}
                    autoHideDuration={10000}
                    open={this.props.failures.length > 0}
                    onClose={() => {if (this.props.failures[0]) this.props.dispatch_removeFailure(this.props.failures[0].id)}}
                >
                    <div>
                        { //here all failures collected in redux store shown: main error and  messages if they exist
                            uniqueFailures && uniqueFailures.length>0 &&
                            uniqueFailures.map((item) => {
                                return(
                                    item &&
                                    <Alert severity = "error" key = {item.id} icon={<img src = {alertIcon} alt = ""/>}>
                                        <div>
                                            {item.failure.error}
                                            {
                                                item.failure.messages && item.failure.messages.length>0 &&
                                                item.failure.messages.map((subitem: string, idx: number) => {
                                                    return (
                                                        <li key = {`${subitem}_${idx}`}>
                                                            {subitem}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Alert>
                                )
                            })
                        }
                    </div>
                </Snackbar>
                <Box className="login-wrapper">
                    <Box className="login-side">
                        <img className="ce-icon" src={Skin} height="80" alt="CE-Icon"/>
                        <h1>Login</h1>
                        <Box className = 'flex-column'>
                            <Typography
                                align="center"
                                className="version"
                                variant="caption"
                                color="textSecondary"
                            >
                                Version: {this.props.kennzeichnung.software?.version ? this.props.kennzeichnung.software?.version : "unbekannt"}
                            </Typography>
                            <Typography
                                align="center"
                                className="version"
                                variant="caption"
                                color="textSecondary"
                            >
                                {this.props.kennzeichnung.software?.validityPeriod ? this.props.kennzeichnung.software?.validityPeriod : "unbekannt"}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="login-input">
                        {this.props.initialisedServices.includes(UploadPath.HPM) &&
                            <ConnectionBadge
                               dispatch_checkConnection = {this.props.dispatch_getHPMInfo}
                               uploadPath = "HPM"
                               isConnected = {this.props.isHPMconnected}
                               dispatch_setIsConnected = {this.props.dispatch_setIsHpmAvailable}
                           />
                        }                   
                        {this.props.hasRiskNotification &&
                            <Tooltip
                                title = {
                                    <>
                                        <Typography variant = 'body2'>Es liegt eine dringende Sicherheitsinformation vor.</Typography>
                                        {/* {this.props.RiskNotificationSummary.map((item: RiskNotificationSummary) => {
                                            return (
                                                <>                                                   
                                                    <Typography variant = 'body2'>{item.affectedTime}</Typography>
                                                    <Typography variant = 'body2'>{item.affectedVersionServerAsString}</Typography>
                                                </>
                                            )
                                        })} */}
                                    </>
                                }
                                placement = 'top'
                            >
                                <img className="risk-notification-sign" src={HinweisIcon} alt="" />
                            </Tooltip>                              
                        }
                        
                        <img className="login-logo" src={Logo} alt="Logo"/>
                        <form noValidate autoComplete="off">
                            <TextField name="bsnr" label="BSNR" value={this.state.bsnr}
                                       onChange={this.handleBsnrChange}/>
                            <TextField name="lanr" label="LANR (optional)" value={this.state.lanr || ""}
                                       onChange={this.handleLanrChange}/>
                            {!this.state.isVisibleForgetPW &&
                            <TextField
                                className = 'remove-autocomplete-shadow'
                                name="password"
                                label="Passwort"
                                value={this.state.password}
                                onChange={this.handlePasswordChange}
                                type = {this.state.isPasswordShown ? 'text': 'password'}
                                InputProps = {{
                                    endAdornment: (
                                        <InputAdornment position = 'end'>
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick = {() => {
                                                    if (!this.state.isPasswordShown) {
                                                        this.setState(prevState => {
                                                            return {isPasswordShown: !prevState.isPasswordShown}
                                                        });
                                                        setTimeout(() => this.setState({isPasswordShown: false}), 4000);
                                                    } else
                                                        this.setState(prevState => {
                                                            return {isPasswordShown: !prevState.isPasswordShown}
                                                        });
                                                }}
                                            >
                                                {this.state.isPasswordShown ? <Visibility fontSize = 'small'/> : <VisibilityOff fontSize = 'small'/>}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            }
                            <Box>
                                {/* <FormControlLabel control={<Checkbox name="checkedC"/>} label="Angemeldet bleiben"/> */}
                                {!this.state.isVisibleForgetPW &&
                                <Typography className="forgot-password-btn" variant="caption" onClick={() => this.setState({isVisibleForgetPW: true})}
                                        color="textSecondary">Passwort vergessen</Typography>
                                }
                                {this.state.isVisibleForgetPW && !this.state.isReset &&
                                <>
                                    <br/>
                                    <Typography variant="caption" color="textSecondary">
                                        Bitte geben Sie Ihren Recovery-Code ein.
                                    </Typography>
                                    {/* <form noValidate autoComplete="off"> */}
                                    <Box className="recovery-new-pw" >
                                        <TextField name="recoveryCode" label="Recovery-Code" value={this.state.recoveryCode}
                                            onChange={this.handleRecoveryCodeChange}/>
                                        <NewPasswordContainer setStrength={this.props.setStrength} newPassword={this.props.newPassword} context="LOGIN"
                                            newRepeatedPassword={this.props.newRepeatedPassword} handleNewPasswordChange={this.props.handleNewPasswordChange}
                                            handleNewRepeatedPasswordChange={this.props.handleNewRepeatedPasswordChange} />
                                    </Box>
                                </>
                                }
                            </Box>
                            {this.renderButtonArea()}
                        </form>
                        <Box className="legal flex-row-center">
                            <Link href="#" onClick={() => this.props.dispatch_navigate("/impressum")} variant="caption">
                                Impressum
                            </Link>
                            <Link href="#" onClick={() => this.props.dispatch_navigate("/datenschutz")} variant="caption">
                                Datenschutz
                            </Link>
                            <Link href="#" onClick={() => this.props.dispatch_navigate("/hilfe")} variant="caption">
                                Hilfe
                            </Link>
                            {!new TestVersion().getConstant() && (this.props.fachrichtung && this.props.fachrichtung === "DERMATOLOGE") &&
                                <img src={CE} height="24" alt = ""/>
                            }
                        </Box>
                    </Box>
                    <img src={Ellipse} className="ellipse" alt="TeleScan Login Logo"/>
                    <img src={Triangle} className="triangle" alt="TeleScan Login Logo"/>
                </Box>
            </div>

        );
    }
}

export const LoginContainer = connector(withNewPasswordMethods(Login));
export default LoginContainer;
