import { Box, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react"
import { DermatologistInfo } from "telescan-core";
import { RecieverCardComponent } from "./reciever_card";

interface IProps {
    availableDermatologists: DermatologistInfo[];
    isDermaSearchOpen: boolean;
    handleOpenClose: (isDermaSearchOpen: boolean) => void;
    handleInputChange: (reason: string) => void;
    selectedKimReceiver: DermatologistInfo| null;
    handleChange: (_event: React.ChangeEvent<{ value: unknown }>, newValue: DermatologistInfo | null) => void;
    isDermaListLoading: boolean;
}
export const KimTabPanel = (props: IProps) => {
    return (
        <Box>
             {/* to cheat chrome which tries to fill some none password filled with credentials*/}
             <input className = "hidden" id="username1"  type="text" name="fakeusernameremembered1"/>
            <input className = "hidden" id="password1"  type="password" name="fakepasswordremembered1"/>
            {/* //////////////////////////////////////////////////////////////////////////////*/}
            <Autocomplete
                id="derma-directory-search"
                options = {props.availableDermatologists}
                autoHighlight
                open = {props.isDermaSearchOpen}
                onOpen = {() => props.handleOpenClose(true)}
                onClose = {() => props.handleOpenClose(false)}
                onInputChange={(_event: any, _newInputValue, reason) => props.handleInputChange(reason)}
                loading = {props.isDermaListLoading}
                getOptionLabel={(option) => `${option.title || ""} ${option.firstName} ${option.lastName} \u2022 ${option.postalCode} ${option.city}`|| ""}
                value = {props.selectedKimReceiver}
                renderOption={(option) => (
                    <React.Fragment>
                        {`${option.title || ""} ${option.firstName} ${option.lastName} \u2022 ${option.postalCode} ${option.city}`}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder = "Empfänger suchen und auswählen"
                    />
                )}
                onChange={props.handleChange}
                noOptionsText = "Keine verfügbaren Dermatologen gefunden"
            />
            {props.selectedKimReceiver &&
                <RecieverCardComponent 
                    title="Konsil wird an folgenden Empfänger gesendet:"
                    isSelected={true}
                    receiverKIM={props.selectedKimReceiver}
                />
            } 
            <Typography className="failure last-reciver-label" variant="body1">
                Bereiten Sie Ihr persönliches eHBA vor. Sie werden aufgefordert, den Konsilauftrag damit zu unterschreiben.
            </Typography>
            {/* {!props.isKonsilSigned &&
                <Typography className="last-reciver-label failure" variant="body1">
                    Sie müssen den Konsilauftrag signieren, um ihn versenden zu können.
                </Typography>              
            } */}

        </Box>
    )
}