import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';

import deLocale from "date-fns/locale/de";
import { Typography } from '@material-ui/core';
import { roundDateToDay } from '../../utils';

interface IState {
    date: Date | null,
}

interface IProps {
    id: string;
    label: string;
    initialDate: Date | null;
    hasError: boolean;
    inputLabelProps: any;
    onChange: (date: Date) => void;
    onBlur?: () => void;
    isRequired: boolean;
    isMaxDateNow?: boolean
}

export class DatePicker extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            date: (isNaN(Date.parse(String(this.props.initialDate))) !== true) ? this.props.initialDate : null,
        };
    }

    render() {
        return (
            <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale = {deLocale}
            >
                <KeyboardDatePicker
                    required = {this.props.isRequired}
                    InputLabelProps={this.props.inputLabelProps}
                    error={(this.props.hasError) ?
                        (this.state.date == null || isNaN(Date.parse(String(this.state.date))) === true || (this.props.isMaxDateNow && this.state.date != null && roundDateToDay(this.state.date.getTime()) > roundDateToDay(new Date().getTime()))) :
                        (this.props.isMaxDateNow && this.state.date != null && roundDateToDay(this.state.date.getTime()) > roundDateToDay(new Date().getTime())) ? true : false}
                    id={this.props.id || ""}
                    label={this.props.label || ""}
                    format="dd.MM.yyyy"
                    value={this.state.date ? this.state.date : null}
                    onChange={(event) => {
                        this.setState({
                            date: event as Date
                        });
                        this.props.onChange(event as Date);
                    }}
                    onBlur={this.props.onBlur}
                    KeyboardButtonProps={{
                        'aria-label': 'Datum auswählen'
                    }}
                    cancelLabel = "Abbrechen"
                    invalidDateMessage = {<Typography>Ungültiges Datumsformat</Typography>}
                    disableFuture = {this.props.isMaxDateNow ? true : false}
                    maxDateMessage = "Datum darf nicht in der Zukunft liegen"
                />
            </MuiPickersUtilsProvider>
        );
    }
}

export const DatePickerContainer = DatePicker;
export default DatePickerContainer;
