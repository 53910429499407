import React from 'react';


export interface WithTypingTimeoutsProps {
    typingTimeout: any;
    timeOutMethod: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, getUserInput: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => string, evaluateUserInput: (val: string) => void, duration: number) => void;
}

export function withTypingTimeout(Component) {
    type Props = {}
    type State = {
        typingTimeout: any;
    }

    // ...and returns another component...
    return class extends React.Component<Props, State> {
      constructor(props: Props) {
        super(props);
        this.state = {
            typingTimeout: 0,
        };

        this.timeOutMethod = this.timeOutMethod.bind(this);
      }

        timeOutMethod(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, 
                getUserInput: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => string, 
                evaluateUserInput: (val: string) => void, duration: number): void {
            if (this.state.typingTimeout) {
                clearTimeout(this.state.typingTimeout);
            }
            let value: string = getUserInput(e);

            this.setState({
                typingTimeout: setTimeout(() => {
                    evaluateUserInput(value);
                }, duration)
            });
        }
    

        render() {
            return (
                <Component typingTimeout={this.state.typingTimeout} 
                    timeOutMethod={this.timeOutMethod}
                    {...(this.props as any)}
                />
            )
        }
    };
}
