import React from 'react';
import {connect, ConnectedProps} from 'react-redux'
import uuid from 'uuid';

import { Tooltip, IconButton, Paper, Box, Button} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { BasisdatenService, Betriebsstaette, Failure } from 'telescan-core';
import { RootState } from '../../redux/reducers';
import { loading, loadSuccess, setBetriebsstaette, failure } from '../../redux/actions';
import { BSDatenComponent } from '../initalisation/daten_betriebsstaette';
import ViewBSDatenComponent from './view_bsdaten';
import SimpleModalContainer from '../elements/simple_modal';


interface InfoState {
    betriebsstaette: Betriebsstaette;
    isEdit: boolean;
}

interface InfoProps {
    isOpen: boolean;
    toggleModal: (betriebsstaette: Betriebsstaette) => void;
    betriebsstaette: Betriebsstaette;
}

const mapStateToProps = (state: RootState, ownProps: InfoProps) => ({
    user: state.user.user,
    role: state.user.role,
    basisdatenBSErrors: state.user.basisdatenBSErrors,
    ...ownProps
})


const mapDispatchToProps = {
    dispatch_setBetriebsstaette: (betriebsstaette: Betriebsstaette) => setBetriebsstaette(betriebsstaette),
    dispatch_loading: (id: string) => loading(id),
    dispatch_loadSuccess: (id: string) => loadSuccess(id),
    dispatch_failure: (id: string, message: Failure) => failure(id, message),
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>

export class BSInfoModal extends React.Component<TPropsFromRedux, InfoState> {
    constructor(props: TPropsFromRedux) {
        super(props);
        this.state = {
            betriebsstaette: this.props.betriebsstaette,
            isEdit: false,
        };

        this.updateBasisdatenBS = this.updateBasisdatenBS.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<TPropsFromRedux>, prevState: Readonly<InfoState>) {
        if (prevState.betriebsstaette !== this.props.betriebsstaette) {
            this.setState({
                betriebsstaette: this.props.betriebsstaette
            });
        }
    }

    updateBasisdatenBS(betriebsstaette: Betriebsstaette) {
        const loadInstanceId = uuid.v4();
        const basisdatenService = new BasisdatenService();
        basisdatenService.setBasisdatenBetriebsstaette(betriebsstaette).then((response) => {
            this.props.toggleModal(betriebsstaette);
        }).catch((error) => {
            this.props.dispatch_failure(loadInstanceId, error);

        })
    }

    render() {
        return (
            <SimpleModalContainer isOpen={this.props.isOpen}>
                <Paper className="modal-wrapper settings-arzt-pop">
                    <h2 className="modal-header arzt-modal-header flex-row-center space-between">Betriebsstätten-Basisdaten 
                        {!this.state.isEdit &&
                        <Tooltip title="Basisdaten bearbeiten" placement="top">
                            <IconButton size="small" aria-label="delete" onClick={() => this.setState({isEdit: true})}>
                                <Edit/>
                            </IconButton>
                        </Tooltip>
                        }
                    </h2>
                    <Box className="modal-content">
                        {!(this.state.isEdit) &&
                        <ViewBSDatenComponent basisdatenBS={this.state.betriebsstaette} />
                        }
                        {(this.state.isEdit) &&
                        <Box className="initialisation-content" style={{height: "auto", paddingBottom: "0px", background: "white"}}>
                            <BSDatenComponent basisdatenBetriebsstaette={this.state.betriebsstaette} setBetriebsstaette={this.props.dispatch_setBetriebsstaette} basisdatenBSErrors={this.props.basisdatenBSErrors}/>
                        </Box>
                        }
                    </Box>
                    <Box className="modal-footer">
                        {!this.state.isEdit &&
                        <Button variant="contained" color="primary"
                            onClick={() => {this.setState({isEdit: false}); this.props.toggleModal(this.state.betriebsstaette);}}>
                            OK
                        </Button>
                        }
                        {this.state.isEdit &&
                        <>
                        <Button variant="contained" color="primary"
                            disabled={this.props.basisdatenBSErrors.size > 0}
                            onClick={() => {this.setState({isEdit: false}); this.updateBasisdatenBS(this.state.betriebsstaette);}}>
                            Aktualisieren
                        </Button>
                        <Button variant="text" onClick={() => {this.setState({isEdit: false}); this.props.toggleModal(this.state.betriebsstaette)}}>
                            Schließen
                        </Button>
                        </>}
                    </Box>
                </Paper>
            </SimpleModalContainer>
        );
    }
}

export const BSInfoModalComponent = connector(BSInfoModal);
export default BSInfoModalComponent;
