export enum ESendDownloadKonsilActions {
    SET_IS_SENDING_KONSIL = "SET_IS_SENDING_KONSIL",
    SET_EVENT_TEXT = "SET_EVENT_TEXT",
}

export interface ISetIsSendingKonsil {
    readonly type: ESendDownloadKonsilActions.SET_IS_SENDING_KONSIL;
    readonly isSendingKonsil: boolean;
}

export interface ISetEventText {
    readonly type: ESendDownloadKonsilActions.SET_EVENT_TEXT;
    readonly eventText: string;
} 

export type TSendDownloadKonsilActionTypes =
    ISetIsSendingKonsil |
    ISetEventText;