export enum EWebSocketActions {
    SET_CLIENT_ID = "SET_CLIENT_ID",
}

export interface ISetClientId {
    readonly type: EWebSocketActions.SET_CLIENT_ID;
    readonly clientId: string;
}

export type TWebSocketActionsTypes =
    ISetClientId;