import React from 'react';
import {ViewText} from './view_text';
import {Box, Divider, Typography} from '@material-ui/core'
import {RootState} from "../../../redux/reducers";
import {connect, ConnectedProps} from "react-redux";
import {DiagnoseKonfidenz, TherapieDringlichkeit} from 'telescan-core/lib/entities/konsil_abschluss';
import {KrankheitDermatologeComponent} from '../dermatologeComponents/krankheit_dermatologe';

const mapStateToProps = (state: RootState) => ({
    role: state.user.role,
    currentKonsil: state.konsile.current_konsil,
    currentKonsilPatient: state.konsile.current_konsil?.konsilPatient,
})

const mapDispatchToProps = {}

const connector = connect(mapStateToProps, mapDispatchToProps)
type TPropsFromRedux = ConnectedProps<typeof connector>


export class ViewKonsiliarBerichtContent extends React.Component<TPropsFromRedux> {

    render() {
        return (
            <Box className="bericht-data-container">
                <Box className="txt-grid">
                    <ViewText
                        labelVariant='subtitle1'
                        label='Konsilauftrag erstellt am'
                        text={(this.props.currentKonsil.konsiliarbericht?.dateCreated) ? new Date(this.props.currentKonsil.konsiliarbericht?.dateCreated).toLocaleDateString() : "-"}
                    />
                    <ViewText
                        labelVariant='subtitle1'
                        label='Konsilauftrag beantwortet am'
                        text={(this.props.currentKonsil.konsiliarbericht?.dateUpdated) ? new Date(this.props.currentKonsil.konsiliarbericht?.dateUpdated).toLocaleDateString() : "-"}
                    />
                </Box>
                <Divider/>
                <ViewText
                    labelVariant='subtitle1'
                    label='Befundbeschreibung'
                    text={this.props.currentKonsil.konsiliarbericht?.befund || "-"}
                />
                <Divider/>
                {(this.props.currentKonsil.konsiliarbericht?.diagnose && this.props.currentKonsil.konsiliarbericht?.diagnose?.length > 0) ?
                    <>
                        <Box className="konsil-txt-container" style={{marginBottom: 0}}>
                            <Typography className={"txt-label"} variant="subtitle1" color={"inherit"}>
                                Diagnose(n)
                            </Typography>
                        </Box>
                        {this.props.currentKonsil.konsiliarbericht?.diagnose?.map((diagnoseItem) =>
                            <KrankheitDermatologeComponent style={{cursor: "auto"}} key={diagnoseItem.id || ""}
                                                diagnose={diagnoseItem}
                                                onEditClick={() => {
                                                }}
                                                delete={(k) => {
                                                }}
                                                hasError={false}
                                                isViewOnly={true}
                                                role = {this.props.role}
                            />
                        )}
                    </>
                    :
                    <ViewText
                        labelVariant='subtitle1'
                        label='Diagnose(n)'
                        text={(this.props.currentKonsil.konsiliarbericht?.diagnose && this.props.currentKonsil.konsiliarbericht?.diagnose?.length > 0) ? "" : "-"}
                    />
                }
                <Divider/>
                <ViewText
                    labelVariant='subtitle1'
                    label='Diagnosekonfidenz'
                    text={(this.props.currentKonsil.konsiliarbericht?.diagnoseKonfidenz) ? DiagnoseKonfidenz[this.props.currentKonsil.konsiliarbericht?.diagnoseKonfidenz] : "-"}
                />
                <Divider/>
                <Box className="txt-grid">
                    <ViewText
                        labelVariant='subtitle1'
                        label='Therapievorschlag'
                        text={this.props.currentKonsil.konsiliarbericht?.therapievorschlag || "-"}
                    />
                    <ViewText
                        labelVariant='subtitle1'
                        label='Therapiedringlichkeit'
                        text={(this.props.currentKonsil.konsiliarbericht?.therapieDringlichkeit) ? TherapieDringlichkeit[this.props.currentKonsil.konsiliarbericht?.therapieDringlichkeit] : "-"}
                    />
                </Box>
                
                <Divider/>
                <ViewText
                    labelVariant='subtitle1'
                    label='Prognose'
                    text={this.props.currentKonsil.konsiliarbericht?.prognose || "-"}
                />
                <Divider/>
                <ViewText
                    labelVariant='subtitle1'
                    label='Vorstellung beim Facharzt notwendig'
                    text={(this.props.currentKonsil.konsiliarbericht?.vorstellungFacharzt !== null) ? (this.props.currentKonsil.konsiliarbericht?.vorstellungFacharzt ? "Ja" : "Nein") : "Keine Angabe"}
                />
            </Box>
        );
    }
}

export const ViewKonsiliarBerichtContentContainer = connector(ViewKonsiliarBerichtContent);
export default ViewKonsiliarBerichtContentContainer;
