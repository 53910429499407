import { Box, Typography } from '@material-ui/core';
import React from 'react';
import HinweisIcon from '../../static/images/ic-hinweis.svg';

export default class TestHinweis extends React.Component {
    render() {
        return(
            <Box className="hinweis-container open">
                <img className="hinweis-icon" src={HinweisIcon} alt = ""/>
                <Box className="text-container">
                    <Typography variant="subtitle1" gutterBottom><strong>Version für Testzwecke.</strong></Typography>
                    <Typography variant="body1"><strong style={{color: "red"}}>Kein Medizinprodukt</strong> im Sinne des Medizinprodukte-Durchführungsgesetzes.<br/>Nutzung für reale Patientenbehandlung nicht zulässig!</Typography>
                </Box>
            </Box>
        )
    }
}
